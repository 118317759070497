
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Row, Col, Button, Form, Input, Spin, Space, Alert } from 'antd';
import { getTokenInformation } from '../../utils';
import { useNavigate } from "react-router-dom"; 
import useActiveWeb3React from '../../../../hooks/useActiveWeb3React';
import web3 from 'web3';
// import { IdoLaunchpad } from 'config/api/idoLaunchpad';
import tokenAbi from '../../../../contracts/abi/tokenAbi.json';

declare const window: Window & typeof globalThis & { ethereum: any };

const AntiBotVerifyToken = (props) => {
    const { form } = props;
    const { account, chainId } = useActiveWeb3React();
    const navigate = useNavigate()
    const { next } = props;


    let storageAirdropTokenInfo = window.localStorage.getItem('airdropTokenInfo');
    let airdropTokenInfo;
    if (storageAirdropTokenInfo) {
        airdropTokenInfo = JSON.parse(storageAirdropTokenInfo);
    }

    const [loadingFull, setLoadingFull] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingTokenInfo, setLoadingTokenInfo] = useState(false);

    const [tokenAddress, setTokenAddress] = useState(airdropTokenInfo?.token_address);
    const [tokenSymbol, setTokenSymbol] = useState(airdropTokenInfo?.token_symbol);
    const [tokenName, setTokenName] = useState(airdropTokenInfo?.token_name);
    const [tokenDecimal, setTokenDecimal] = useState(airdropTokenInfo?.token_decimal);

    const [errorCallSC, setErrorCallSC] = useState(false);
   
    const [checkUpdate, setCheckUpdate] = useState<boolean>(false);

    useEffect(() => {
        if (!checkUpdate && chainId && airdropTokenInfo) {
            getTokenInfoByAddress(airdropTokenInfo?.token_address);
            setCheckUpdate(true);
        }
    }, [airdropTokenInfo, account, chainId]);


    const getTokenInfoByAddress = async (tokenAddressVal)=>{
        try {
            
            console.log('tokenAddressVal', tokenAddressVal);
            const tokenInfo = await getTokenInformation(tokenAbi, tokenAddressVal);
            
            if (tokenInfo.symbol && tokenInfo.name && tokenInfo.decimals) {
                console.log('tokenInfo', tokenInfo);
                setTokenSymbol(tokenInfo.symbol);
                setTokenName(tokenInfo.name);
                setTokenDecimal(tokenInfo.decimals);

                form.setFieldsValue({
                    token_address: tokenAddressVal,
                    token_name: tokenInfo.name,
                    token_symbol: tokenInfo.symbol,
                    token_decimal: tokenInfo.decimals,
                });
                setLoadingTokenInfo(false);

            } else {
                setLoadingTokenInfo(false);
                setErrorCallSC(true);
                form.resetFields();
                setTokenAddress('');
                setTokenSymbol('');
                setTokenName('');
                setTokenDecimal('');
            }
        } catch (e: any) {
            setLoadingTokenInfo(false);
            setErrorCallSC(true);
        }
    }


    const onChangeTokenAddress = async (values: any) => {
        setErrorCallSC(false);
        const tokenAddressVal: any = values.target.value;
        setLoadingTokenInfo(true);
        setTokenAddress(tokenAddressVal);

        if(tokenAddressVal){
            localStorage.removeItem("airdropTokenInfo");
            form.resetFields();
            getTokenInfoByAddress(tokenAddressVal);
        }
    };


    // const onConfirmAirdropToken = (values: any) => {
    //     if (values.token_address) {
    //         let data = JSON.stringify(values);
    //         window.localStorage.setItem('airdropTokenInfo', data);
    //         next(1)
    //     }
    // }

    const handleCancel = () => {
        localStorage.removeItem("airdropTokenInfo");
        navigate("/airdrop/list");  
    }
    
    return (
        <>
            <div className='step'>Step 1</div>
            <div className='step-text'>Verify token</div>
            
            <div className='token-select'>
                <div className='form-availabletoken'>
                    <img src="../images/check-circle.png"/>
                    <span className='title'>I have available token</span>
                    <span className='subtitle'>Please enter the token that is listed</span>
                    <Form.Item
                        label="Token Address"
                        name="token_address"
                        // initialValue={tokenAddress}
                        rules={[{ required: true, message: 'Ex: Token address invalid' }]}
                    >
                        <input className='input' value={tokenAddress} onChange={(e) => onChangeTokenAddress(e)} placeholder='Enter token address'/>
                    </Form.Item>
                    
                </div>  
            </div>
            <div className="airdrop-inputs-steps">
                {/* <Form form={form} layout="vertical" autoComplete="off"
                    onFinish={onConfirmAirdropToken}
                > */}
                    
                    {!errorCallSC ? (
                        loadingTokenInfo ? (
                            <div className="loading-info">
                                <Space size="middle">
                                    <Spin size="large" />
                                </Space>
                            </div>
                        ) : (
                            tokenAddress && tokenSymbol && tokenName && tokenDecimal && account && (
                                <div className="token-info-anti">
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                            <Form.Item name="step_verify_token" initialValue={1} hidden={true}><Input className="amount-buy" /></Form.Item>
                                            <Form.Item name="step_verify_token" initialValue={1} hidden={true}><Input className="amount-buy" /></Form.Item>
                                            <Form.Item
                                                label="Token Address"
                                                name="token_address"
                                                initialValue={tokenAddress}
                                                rules={[{ required: true, message: 'Ex: Token address invalid' }]}
                                            >
                                                <Input readOnly className="inputForm" placeholder="Ex: 0x3C39f28e368420e3dEE3838E8b1de7422EA633E4"/>
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                            <Form.Item
                                                label="Token Name"
                                                name="token_name"
                                                initialValue={tokenName}
                                                rules={[{ required: true, message: 'Token name can not be null' }]}
                                            >
                                                <Input readOnly className="inputForm" placeholder="Enter token name"/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                            <Form.Item
                                                label="Token Symbol"
                                                name="token_symbol"
                                                initialValue={tokenSymbol}
                                                rules={[{ required: true, message: 'Token symbol can not be null' }]}
                                            >
                                                <Input readOnly className="inputForm" placeholder="Enter token symbol"/>
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                            <Form.Item
                                                label="Token Decimals"
                                                name="token_decimal"
                                                initialValue={tokenDecimal}
                                                rules={[{ required: true, message: 'Token decimal can not be null' }]}
                                            >
                                                <Input readOnly className="inputForm" placeholder="Enter token decimal"/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                      
                                </div>
                            )
                        )

                    ) : (
                        <>
                            <Alert style={{ marginTop: '10px', marginBottom: '10px' }} message="Token not found." type="error" showIcon />
                        </>
                    )}
                    

                {/* </Form> */}

            </div>
     
        </>
    )
}

export default AntiBotVerifyToken
