import { useEffect, useState } from "react";  
import { useAppDispatch, useAppSelector } from "../../redux/hooks";   
import { tokenLPSelector } from "../../redux/slicers/tokenLPSlice";
import { getTokenInformByAddress, createTokenLPInform } from "../../redux/slicers/tokenInformSlice"; 
import { useActiveWeb3React } from "../../hooks";
import { Store } from 'react-notifications-component';
import addNotify from '../commons/Notify/addNotify'; 
import { CHAINID_CONVERT } from '../../constants';
import { useTokenContract, usePancakePairContract } from "../../hooks/useContract"; 
import { tokenInformation } from "../../contracts/token";
import { setTokenLP } from "../../redux/slicers/tokenLPSlice";
import { CustomButton } from "../component";
import { getLiquidityInformation} from "../../contracts/pancakePair";
import PancakePairABI from '../../contracts/abi/PancakePairABI.json';
interface ITokenCreateForm{
  setActiveKey: any,  
} 

const TokenLP : React.FC<ITokenCreateForm> =(props: ITokenCreateForm) => { 
  const [availableToken, setAvailableToken] = useState<any>('');
  const { tokenLP } = useAppSelector(tokenLPSelector);  
  const [actionStatus, setActionStatus] = useState("");
  const dispatch = useAppDispatch(); 
  const { account, library } = useActiveWeb3React();   
  const pancakePairContract = usePancakePairContract(availableToken); 
  useEffect(() => {
      setAvailableToken(tokenLP?.tokenAddress);  
  }, [tokenLP]);  

  const handleNext = () => {    
      if(!account){
        Store.addNotification(addNotify("Please connect your wallet", 'danger'));
        return
      }
      if(availableToken){ 
              // call sc to check available token
          if (pancakePairContract) {
                  (async() => { 
                    setActionStatus("CHECK_LOADING");
                    await getLiquidityInformation(PancakePairABI, availableToken, library, account)
                    .then((tokenRes:any) => { 
                      setActionStatus(""); 
                      props.setActiveKey("2");
                      dispatch(setTokenLP({ 
                        "lpTokenAddress": availableToken,  
                      }));
                      localStorage.setItem("stepLP", "2"); 
                      setAvailableToken('')
                    })
                    .catch((err:any) => {
                        setActionStatus("");
                        if(err) {
                          Store.addNotification(addNotify("Please input your LP Token", 'danger'));
                          return;
                        }
                    });
                })(); 
            }  
      }  
  }
 
  return (
    <> 
     <div className='token'> 
      <div className='step'>Step 1</div>
      <div className='step-text'>Do you want to create LP token lock</div>
      <div className='token-select'> 
      <div className='form-availabletoken alone'>
          <img src="../images/check-circle.png"/>
          <span className='title'>LP token</span>
          <span className='subtitle'>We don’t need to go through the onboarding process</span>
          <input className='input' value={availableToken} onChange={(e) => setAvailableToken(e.target.value)} placeholder='Enter token address'/>
      </div>  
      </div>  
      <div className='btn-token cancel-next'>
        <button className='cancel'>Cancel</button>
        <CustomButton 
            class='next'
            isLoading={actionStatus === 'CHECK_LOADING' ? true : false}  
            text='Next'
            onClick={()=>handleNext()}
        />
      </div>
    </div>  
    </> 
  )
}

export default TokenLP;