import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Tokens } from "../../constants";
import AuthenticateService from "../services/AuthenticateService";

// ================================================
// ==                  ACTION                    ==
// ================================================
export const authenticate = (payload : any) => {
    return async dispatch => {
        dispatch(setLoading(true));
        await AuthenticateService.authenticate(payload)
        .then((res)=>{
            dispatch(setLoading(false));
            dispatch(setToken(res.data?.id_token));
            localStorage.setItem(Tokens.AUTHUSER, res.data?.id_token);
            localStorage.setItem("account", payload?.address);
            localStorage.setItem("accountStatus", "1");
        })
        .catch((error:any)=>{
            dispatch(setErrors(error));
            dispatch(setLoading(false));
        });
    }
}

// ================================================
// ==                REDUX STORE                 ==
// ================================================
export interface AuthenticateState {
    token: object[];
    loading: boolean;
    errors: string;
}

const initialState: AuthenticateState = {
    token: [],
    loading: false,
    errors: "",
}

const authenticateSlice = createSlice({
  name: "authenticate",
  initialState,
  reducers: {
    setLoading: (state: AuthenticateState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state: AuthenticateState, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setToken: (state: AuthenticateState, { payload }: PayloadAction<object[]>) => {
      state.token = payload
    },
  },
})

export const { setLoading, setErrors, setToken } = authenticateSlice.actions

export default authenticateSlice.reducer

export const authenticateSelector = (state: { authenticateStore: AuthenticateState }) => state.authenticateStore