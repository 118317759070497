import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks"; 
import { useActiveWeb3React } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { Store } from 'react-notifications-component'; 
import addNotify from '../commons/Notify/addNotify'; 
import { useTokenContract } from "../../hooks/useContract";  
import { CustomButton } from "../component"; 
import { tokenInformation } from "../../contracts/token";
import { setTokenLock, tokenLockSelector } from "../../redux/slicers/tokenLockSlice";
import { getAppSettings, settingSelector } from "../../redux/slicers/settingSlice";
import { APP_SETTINGS, CHAINID_CONVERT } from '../../constants';
import { createTokenInform, getTokenInformByAddress, postCheckAllowLauchpad } from "../../redux/slicers/tokenInformSlice";
import CreateToken from '../../components/createtoken';

interface ITokenCreateForm {
    setActiveKey: any,
}

type SettingFee = {
    chainId: string | number,
    feeType: string,
    payToken: any,
    settingKey: any
    settingValue: any
}

const TokenLock: React.FC<ITokenCreateForm> = (props: ITokenCreateForm) => {
    const [availableToken, setAvailableToken] = useState<any>('');
    const [createToken, setCreateToken] = useState<boolean>(false); 
    const [actionStatus, setActionStatus] = useState("");
    const [initTokenFee, setInitTokenFee] = useState<SettingFee>(); 
    const { account, chainId } = useActiveWeb3React();
    const tokenContract = useTokenContract(availableToken); 
    const { tokenLock } = useAppSelector(tokenLockSelector); 
    const { settings } = useAppSelector(settingSelector);
    const navigate = useNavigate(); 
    const dispatch = useAppDispatch();

    useEffect(()=> {
        const payload:any = {
            "page": 0,
            "size": 100
        }
        dispatch(getAppSettings(payload));
    }, []);
    
    useEffect(() => {
        setAvailableToken(tokenLock?.tokenAddress);
    }, [tokenLock]);

    // useEffect(() => {
    //     if (availableToken) { 
    //         dispatch(postCheckAllowLauchpad(availableToken));  
    //     }
    // }, [availableToken]); 
   

    useEffect(() => {
        let chainFee = "";
        switch (chainId) {
            case 1:
                chainFee ="INIT_TOKEN_FEE_ETH"
                break;
            case 56:
                chainFee ="INIT_TOKEN_FEE_BSC"
                break;
            case 137:
                chainFee ="INIT_TOKEN_FEE_POLY"
                break; 
            default:
                chainFee ="INIT_TOKEN_FEE_BSC_TESTNET"
                break;
        } 
        if (settings && settings.length > 0) {
            let settingTokenFee = settings.filter((itemSetting) => chainId === Number(itemSetting?.chainId)); 
            const tokenFee = (settingTokenFee.find(({ settingKey }) => settingKey === chainFee));
            setInitTokenFee({
                chainId: tokenFee?.chainId,
                feeType: tokenFee?.feeType,
                payToken: tokenFee?.payToken,
                settingKey: tokenFee?.settingKey,
                settingValue: tokenFee?.settingValue
            });
        }
        setAvailableToken(tokenLock?.tokenAddress);
    }, [settings]); 

    const handleNext = () => { 
        if (!account) {
            Store.addNotification(addNotify("Please connect your wallet", 'danger'));
            return
        }
        
        if (availableToken) {
            // call sc to check available token
            if (tokenContract) {
                (async () => {
                    setActionStatus("CHECK_LOADING");
                    await tokenInformation(tokenContract)
                        .then((tokenRes: any) => {
                            dispatch(getTokenInformByAddress(availableToken, (res: any) => {
                                // call api to save token avalaible 
                                if (res?.tokenInform && res?.tokenInform?.id) {  
                                    dispatch(setTokenLock({
                                        "ownerAddress": account,
                                        "tokenType": "STANDARD",
                                        "tokenAddress": tokenRes?.tokenAddress,
                                        "tokenName": tokenRes?.tokenName,
                                        "symbol": tokenRes?.symbol,
                                        "decimals": tokenRes?.decimals,
                                        "totalSupply": tokenRes?.totalSupply,
                                        "type": "Available",
                                        "networkChain": chainId && CHAINID_CONVERT[chainId].toUpperCase(),
                                        "antiBotEnable": false
                                    }));
                                    setActionStatus("");
                                    props.setActiveKey("2");
                                    localStorage.setItem("stepLock", "2");
                                }
                                else {
                                    const tokenPayload: any = {
                                        "ownerAddress": account,
                                        "tokenType": "STANDARD",
                                        "tokenAddress": tokenRes?.tokenAddress,
                                        "tokenName": tokenRes?.tokenName,
                                        "symbol": tokenRes?.symbol,
                                        "decimals": tokenRes?.decimals,
                                        "totalSupply": tokenRes?.totalSupply,
                                        "type": "Available",
                                        "networkChain": chainId && CHAINID_CONVERT[chainId].toUpperCase(),
                                        "antiBotEnable": false
                                    }
                                    dispatch(createTokenInform(tokenPayload, async (res: any) => {
                                        if (res.result === "SUCCESS") {
                                            if (res?.data) {
                                                props.setActiveKey("2"); 
                                                dispatch(setTokenLock(tokenPayload))
                                                localStorage.setItem("stepLock", "2");  
                                                Store.addNotification(addNotify("Token created successfully.", 'success'));
                                            }
                                        }
                                        else {
                                            if (res?.error?.message) {
                                                Store.addNotification(addNotify(res?.error?.message, 'danger'));
                                            }
                                        }
                                        setCreateToken(false);
                                        setActionStatus("");
                                    }));
                                }
                            })) 
                        })
                        .catch((err: any) => {
                            setActionStatus("");
                            if (err) {
                                Store.addNotification(addNotify("Please input your token address", 'danger'));
                                return;
                            }
                        });
                })();
            }
        } 
    }
   
    return (
        <>
            <div className='token'>
                <div className='step'>Step 1</div>
                <div className='step-text'>Do you create new token or do you have an available token</div> 
                <div className='token-select'>
                    <div className='form-availabletoken'>
                        <img src="../images/check-circle.png" />
                        <span className='title'>I have available token</span>
                        <span className='subtitle'>We don’t need to go through the onboarding process</span>
                        <input className='input' value={availableToken} onChange={(e) => setAvailableToken(e.target.value)} placeholder='Enter token address' />
                    </div>
                    <div className='form-createtoken'>
                        <img src="../images/uncheck-circle.png" />
                        <span className='title'>Please enter your token address</span>
                        <span className='subtitle'>Not sure which token is appropriate for your situation?</span>
                        <span className='subtitle-fee'>Create token fee: {initTokenFee?.settingValue} {initTokenFee?.payToken}</span>
                        <button onClick={() => setCreateToken(true)} className='btn-create'>Create Token</button>
                    </div> 
                    {createToken &&
                        <>
                            <div className="form-createToken-bg" />
                            <CreateToken type="FormLock" setCreateToken={setCreateToken} setActiveKey={props.setActiveKey} />
                        </>} 
                </div>
                <div className='btn-token cancel-next'>
                    <button onClick={() => navigate("/")} className='cancel'>Cancel</button>
                    <CustomButton
                        class='next'
                        isLoading={actionStatus === 'CHECK_LOADING' ? true : false}
                        disabled={availableToken === undefined ? true : false}
                        text='Next'
                        onClick={() => handleNext()}
                    />
                </div>
            </div>  
        </> 
    )
}

export default TokenLock;