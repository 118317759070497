import { useState, useEffect } from "react";  
import {Form, Input, Select, Switch, Checkbox, DatePicker, Button as ButtonAntd} from 'antd';   
import { Button } from '../component';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';  
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { additionInfo, createLaunchpadSelector, lauchpadInfo, verifyToken } from "../../redux/slicers/createLauchpadSlice";
import { Store } from 'react-notifications-component';
import addNotify from '../commons/Notify/addNotify';
import moment from 'moment';
import { CHAINID_FULLNAME, DEX_EXCHANGES, MAPPING_CHAINID_DECIMAL, FEE_SETTINGS } from "../../constants";
import { useActiveWeb3React } from "../../hooks";
import { tokenInformsSelector } from "../../redux/slicers/tokenInformSlice";
import { setTokenStorage, tokenStorageSelector } from "../../redux/slicers/tokenStorageSlice";  
import { getLiquidityTokens, getPresaleTokens, getTotalFeeTokens } from "./ultils";
import { useNavigate } from "react-router-dom";
import { formatUTCDate } from "../../utils/formatDate";
import { settingSelector } from "../../redux/slicers/settingSlice";
 
interface IFormCreate{
    setActiveKey: any, 
  } 

const LauchpadInfo : React.FC<IFormCreate> =(props: IFormCreate) =>{ 
    const { lauchpadInfoStorage, verifyTokenStorage } = useAppSelector(createLaunchpadSelector);
    const [checkbox, setCheckbox] = useState<boolean>(lauchpadInfoStorage?.usingVestingContributors ? lauchpadInfoStorage?.usingVestingContributors :false);
    const [checkboxTeam, setCheckboxTeam] = useState<boolean>(lauchpadInfoStorage?.usingVestingTeamLock ? lauchpadInfoStorage?.usingVestingTeamLock : false); 
    const [closeTip, setCloseTip] = useState<boolean>(false);
    const [checkSwitch, setCheckSwitch] = useState<boolean>(lauchpadInfoStorage?.whiteListOnly ? lauchpadInfoStorage?.whiteListOnly : false);
    const [checkboxAnotherOwner, setCheckboxAnotherOwner] = useState<boolean>(lauchpadInfoStorage?.vestingTeam?.useAnotherOwner ? lauchpadInfoStorage?.vestingTeam?.useAnotherOwner :false);  
    const [swapRate, setSwapRate] = useState<number>(0);  
    const [hardcap, setHardcap] = useState<number>(0);  
    const [listingRate, setListingRate] = useState<number>(0);
    const [liquidityPercents, setLiquidityPercents] = useState<number>(0);
    const [totalToken, setTotalToken] = useState<number>(0);   
    const [dexExchanges, setDexExchanges] = useState<any>([]);
    const [startTime, setStartTime] = useState<any>("");
    const [endTime, setEndTime] = useState<any>(""); 
    const [tgeTime, setTgeTime] = useState<any>("");
    const [selectRouter, setSelectRouter] = useState<any>(""); 
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const {chainId} = useActiveWeb3React();
    const { tokenStorage } = useAppSelector(tokenStorageSelector);
    const nowTime = (new Date()).toUTCString();
    const [amountVestingTeam, setAmountVestingTeam] = useState<number>(0); 
    const { settings } = useAppSelector(settingSelector); 
    const [launchpadRaisedFee, setLaunchpadRaisedFee] = useState<any>(0);

    let netName = '';  
    switch (chainId) {
        case MAPPING_CHAINID_DECIMAL.bsc:
            netName = CHAINID_FULLNAME.bsc; 
            break;
            
        case MAPPING_CHAINID_DECIMAL.poly:
            netName = CHAINID_FULLNAME.poly; 
            break;

        case MAPPING_CHAINID_DECIMAL.eth:
            netName = CHAINID_FULLNAME.eth; 
            break;

        case MAPPING_CHAINID_DECIMAL.bsctestnet:
            netName = CHAINID_FULLNAME.bsctestnet; 
            break;

        default:
            netName = CHAINID_FULLNAME.bsc; 
    }

    useEffect(() => {
        if(chainId) {
            setDexExchanges(DEX_EXCHANGES[chainId]);
        }
    },[chainId]);  
    
    useEffect(() => {   
        const _routerSelected = dexExchanges && dexExchanges.length > 0 ? dexExchanges.filter(item => item.id === Number(lauchpadInfoStorage?.router)) : null;
        form.setFieldsValue({
            tokenAddress:tokenStorage?.tokenAddress,
            swapRate : lauchpadInfoStorage?.swapRate,
            hardcap: lauchpadInfoStorage?.hardcap,
            liquidityPercent: verifyTokenStorage?.listingOptions === "MANUAL"? null :lauchpadInfoStorage?.liquidityPercent,
            listingRate: lauchpadInfoStorage?.listingRate,
            liquidityLockup: verifyTokenStorage?.listingOptions === "MANUAL" ? null :lauchpadInfoStorage?.liquidityLockup,
            minBuy: lauchpadInfoStorage?.minBuy,
            maxBuy: lauchpadInfoStorage?.maxBuy,
            refundType: lauchpadInfoStorage?.refundType,
            usingVestingContributors:lauchpadInfoStorage?.usingVestingContributors,
            usingVestingTeamLock: lauchpadInfoStorage?.usingVestingTeamLock,
            startTime: lauchpadInfoStorage?.startTime ? formatUTCDate(lauchpadInfoStorage?.startTime) : null,
            endTime: lauchpadInfoStorage?.endTime ? formatUTCDate(lauchpadInfoStorage?.endTime) : null,
            // selecttime: [lauchpadInfoStorage?.startTime ? formatUTCDate(lauchpadInfoStorage?.startTime) : null, lauchpadInfoStorage?.endTime ? formatUTCDate(lauchpadInfoStorage?.endTime) : null],
            selecttime: lauchpadInfoStorage?.startTime && lauchpadInfoStorage?.endTime ? [formatUTCDate(lauchpadInfoStorage?.startTime), formatUTCDate(lauchpadInfoStorage?.endTime)] : [],
            router: verifyTokenStorage?.listingOptions === "MANUAL" ? null : _routerSelected && _routerSelected.length > 0 && _routerSelected[0] ? _routerSelected[0].id : "",
            softcap: lauchpadInfoStorage?.softcap,
            whiteListOnly: lauchpadInfoStorage?.whiteListOnly,
            firstReleasePercent:lauchpadInfoStorage?.vestingContributor?.firstReleasePercent,
            cliffVesting: lauchpadInfoStorage?.vestingContributor?.cliffVesting,
            lockAfterCliffVesting: lauchpadInfoStorage?.vestingContributor?.lockAfterCliffVesting, 
            vestingPeriodEachCycle: lauchpadInfoStorage?.vestingContributor?.vestingPeriodEachCycle,
            tokenReleaseEachCycle: lauchpadInfoStorage?.vestingContributor?.tokenReleaseEachCycle,
            //another owner
            title: lauchpadInfoStorage?.vestingTeam?.title,
            rememberAnotherOwner: lauchpadInfoStorage?.vestingTeam?.useAnotherOwner,
            anotherOwner: lauchpadInfoStorage?.vestingTeam?.anotherOwner, 
            tgeDate: formatUTCDate(lauchpadInfoStorage?.vestingTeam?.tgeDate ?? new Date()),
            amount: lauchpadInfoStorage?.vestingTeam?.amount,
            rememberTeamVesting:lauchpadInfoStorage?.vestingTeam?.useVesting,
            tgePercentTeam: lauchpadInfoStorage?.vestingTeam?.tgePercent,
            vestingCycleTeam: lauchpadInfoStorage?.vestingTeam?.vestingCycle ,
            cycleReleasePercentTeam: lauchpadInfoStorage?.vestingTeam?.cycleReleasePercent,
            cliffVestingTeam: lauchpadInfoStorage?.vestingTeam?.cliffVesting,
            lockAfterCliffVestingTeam: lauchpadInfoStorage?.vestingTeam?.lockAfterCliffVesting
        });
        setSwapRate(lauchpadInfoStorage?.swapRate ?? 0);
        setHardcap(lauchpadInfoStorage?.hardcap); 
        setListingRate(lauchpadInfoStorage?.listingRate);
        setStartTime(formatUTCDate(lauchpadInfoStorage?.startTime));
        setEndTime(formatUTCDate(lauchpadInfoStorage?.endTime));
        setCheckboxTeam(lauchpadInfoStorage?.usingVestingTeamLock);
        setLiquidityPercents(lauchpadInfoStorage?.liquidityPercent);
        setTgeTime(formatUTCDate(lauchpadInfoStorage?.tgeDate));
    }, [lauchpadInfoStorage, dexExchanges]);
    
    const onFinish = (values: any) => {
        if (totalToken > Number(verifyTokenStorage?.totalSupply)) {
            Store.addNotification(addNotify('Total token less or equal than Total supply', 'danger'));
            return ;
        }

        if(values?.amount > totalToken){
            Store.addNotification(addNotify('Amount < Total Token', 'danger'));
            return;
        }  

        const nowTimeUtc = formatUTCDate(new Date());
        
        if (startTime <= nowTimeUtc) {
            Store.addNotification(addNotify('Select start time > now (UTC)', 'danger'));
            return;
        } 

        if (endTime <= startTime) {
            Store.addNotification(addNotify('Select end time > start time', 'danger'));
            return;
        } 

        if (tgeTime <= nowTimeUtc){
            Store.addNotification(addNotify('First Release For Presale (UTC) > now', 'danger'));
            return;
        } 
       
        const payload: any = { 
            tokenAddress:tokenStorage?.tokenAddress,
            swapRate: values.swapRate,
            hardcap: values?.hardcap,
            liquidityPercent: values?.liquidityPercent,
            listingRate: values?.listingRate,
            liquidityLockup: values?.liquidityLockup,
            minBuy: values?.minBuy,
            maxBuy: values?.maxBuy, 
            refundType: values?.refundType,
            usingVestingContributors: true, 
            usingVestingTeamLock:checkboxTeam, 
            router: verifyTokenStorage?.listingOptions === "MANUAL" ? "" : values?.router,
            softcap: values?.softcap,
            whiteListOnly: checkSwitch,
            startTime: startTime,
            endTime: endTime,
            vestingContributor:{
                firstReleasePercent: checkbox ? Number(values?.firstReleasePercent) :100,
                cliffVesting: Number(values?.cliffVesting) ,
                lockAfterCliffVesting: Number(values?.lockAfterCliffVesting) , 
                vestingPeriodEachCycle: checkbox ? Number(values?.vestingPeriodEachCycle) : 0,
                tokenReleaseEachCycle: checkbox ? Number(values?.tokenReleaseEachCycle) : 0,
            }, 
            networkChain: netName,
            chainId:chainId,  
            vestingTeam:{
                title: values.title,
                useAnotherOwner: values.rememberAnotherOwner,
                anotherOwner: values.useAnotherOwner ,
                useVesting: checkboxTeam,
                tgeDate: values?.tgeDate ? values?.tgeDate.toISOString() : nowTimeUtc.toISOString(),
                amount: checkboxTeam ? Number(values?.amount) : 0 , 
                tgePercent: checkboxTeam ? Number(values?.tgePercentTeam) : 0,
                vestingCycle: checkboxTeam ? Number(values?.vestingCycleTeam) : 0,  
                cycleReleasePercent: checkboxTeam ? Number(values?.cycleReleasePercentTeam) : 0, 
                cliffVesting: checkboxTeam ? Number(values?.cliffVestingTeam) : 0 , 
                lockAfterCliffVesting: checkboxTeam ? Number(values?.lockAfterCliffVestingTeam) : 0 , 
            },
            totalToken: totalToken
        }
        dispatch(lauchpadInfo(payload));  
        props.setActiveKey("4"); 
        localStorage.setItem("step", "4") 
    };

    let navigate = useNavigate();
    const onChange = (e: CheckboxChangeEvent) => {
        setCheckbox(e.target.checked); 
    };
    const onChangeTeam = (e: CheckboxChangeEvent) => {
        setCheckboxTeam(e.target.checked); 
    };  
 
    const onChangeAnotherOwner = (e: CheckboxChangeEvent) => {
        setCheckboxAnotherOwner(e.target.checked); 
    };  
   
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleCloseTip = () =>{
        setCloseTip(true)
    }  

    const handleChange = (e) =>{  
        switch (e.target.name) {
            case "swapRate":
                setSwapRate(parseFloat(e.target.value));
                break;
            case "listingRate":
                setListingRate(parseFloat(e.target.value));
                break;
            case "hardcap":
                setHardcap(parseFloat(e.target.value)); 
                break;  
            case "liquidityPercent":
                setLiquidityPercents(parseFloat(e.target.value));
            break;    
            default:
                break;
        } 
    } 

    let tokensPresale:number = 0;
    let tokensLiquidity:number = 0;
    let feeTokenRaised:number = 0;
    let tokenAmountVestingTeam:number = 0;
    let totalFeeTokens:number = 0;
    const nodeEvn = process.env.REACT_APP_NODE_ENV;

    useEffect(() => {
        let _chainId = (nodeEvn === 'production' || nodeEvn === 'staging') ? 42161 : (chainId ?? 97);
        const feeSettings = FEE_SETTINGS[_chainId];
        if (settings && settings.length > 0) {
            if(verifyTokenStorage.feeOptions.settingKey === feeSettings?.LIST_LAUNCHPAD_TOKEN_2_RAISED_2_TOKEN_SOLD) {
                let launchpadAmountRaisedFee = settings.filter((itemSetting) => feeSettings?.LIST_LAUNCHPAD_TOKEN_RAISED_FEE === itemSetting.settingKey);
                if (launchpadAmountRaisedFee && launchpadAmountRaisedFee[0]) {
                    setLaunchpadRaisedFee(launchpadAmountRaisedFee[0].settingValue);
                }
            }
        }
    }, [settings]);

    useEffect(()=> {
        if(swapRate && hardcap) {
            tokensPresale = getPresaleTokens(swapRate, hardcap);
            if(launchpadRaisedFee) {
                totalFeeTokens = getTotalFeeTokens(swapRate, hardcap, launchpadRaisedFee);
            }
        }
        if (swapRate && hardcap && liquidityPercents) {
            tokensLiquidity = getLiquidityTokens(hardcap, launchpadRaisedFee, liquidityPercents, listingRate);
        }
        if (checkboxTeam === true) {
            tokenAmountVestingTeam = amountVestingTeam
        }
        setTotalToken(tokensPresale + tokensLiquidity + tokenAmountVestingTeam + totalFeeTokens);
    }, [swapRate, listingRate, hardcap, liquidityPercents, launchpadRaisedFee, checkboxTeam, amountVestingTeam]);
   
    const handleBack = () =>{
        props.setActiveKey("2"); 
        localStorage.setItem("step", "2") 
    }

    const onChangeSwitch = (checked: boolean) =>{
        setCheckSwitch(checked);
    }  

    const onOkStartTime = (dates) => {  
        setStartTime(dates?.toISOString()) 
    }
    const onOkEndTime = (dates) => {
        setEndTime(dates?.toISOString()) 
    }
    const onRangeChange = (dates) => {  
        if(dates[0]?._d){
            setStartTime(dates[0]?._d?.toISOString())
        }
        if(dates[1]?._d){
            setEndTime(dates[1]?._d?.toISOString()) 
        }
    }

    const handleCancel = () =>{
        dispatch(lauchpadInfo({}));
        dispatch(verifyToken({}));
        dispatch(setTokenStorage({}));
        dispatch(additionInfo({}))
        navigate("/launchpad/list");
        localStorage.setItem("step", "1");
        localStorage.removeItem("createType");
    }

    const handleChangeRouter = (value: string) => {
        const nameRouter = dexExchanges.find(
            (t) => t.id === value
        );
        setSelectRouter(nameRouter.name);
    };

    return (
    <div className='verifyToken'>
      <div className='step'>Step 3</div> 
      <Form 
            name="basic" 
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"  
            form={form}
            >
        <div className="verifyToken-form">
        <div className="verifyToken-left"> 
            <div>  
                <div className="verifyToken-left-item">
                    <span className="mb-10">Swap Rate</span>
                    <Form.Item name="swapRate" rules={[{ required: true, message: `Please input your swapRate`,},
                        ({ getFieldValue }) => ({
                            validator(_, value) { 
                                if (Number(value) <= 0) {
                                    return Promise.reject('Swap Rate must larger than 0')
                                } 
                                return Promise.resolve();
                            }
                        })]}> 
                        <Input onWheel={ event => event.currentTarget.blur()} className="inputForm" type="number" name="swapRate" onChange={(e) => handleChange(e)}/>
                    </Form.Item>
                    <p className="swaprate"> 1 {verifyTokenStorage?.currency} = {swapRate} {verifyTokenStorage?.symbol} </p>
                </div> 
                <div className="verifyToken-left-item">
                    <div className="verifyToken-right-text">
                    <span className="mb-10">Softcap ({verifyTokenStorage?.currency})</span>  
                        <div className="tooltip">
                            <img src="../images/question-circle.png" alt="" />
                            <span className="tooltiptext">Softcap must larger than 50% Hardcap</span>
                        </div>
                    </div>  
                   
                    <Form.Item name="softcap"
                        rules={[
                            {
                            required: true,
                            message: 'Softcap is required!'
                            }, 
                            ({ getFieldValue }) => ({
                            validator(_,value) {  
                                if (2 * Number(value) !== Number(getFieldValue(['hardcap']))){ 
                                    return Promise.reject('Softcap must be equal 50% of Hardcap')
                                }  
                                if(Number(value) <= 0){ 
                                    return Promise.reject('Softcap must larger than 0')
                                } 
                                form.setFields([
                                    {
                                      name: ['hardcap'],
                                      errors: []
                                    }
                                  ]);
                                return Promise.resolve()
                                
                        }})
                        ]}>  
                            <Input onWheel={ event => event.currentTarget.blur()} name="softcap" className="inputForm" type="number" onChange={(e) => handleChange(e)}/>
                    </Form.Item>  
                </div>
              
                <div className="verifyToken-left-item">
                    <span className="mb-10">Hardcap ({verifyTokenStorage?.currency})</span>
                   
                    <Form.Item name="hardcap"
                        rules={[
                            {
                            required: true,
                            message: 'Hardcap is required!'
                            }, 
                            ({ getFieldValue }) => ({
                            validator(_, value) {  
                                if(Number(value) !== Number(2*getFieldValue(['softcap']))){ 
                                    return Promise.reject('Hardcap must be equal 50% of Softcap')
                                }  
                                if (Number(value) <= 0){ 
                                    return Promise.reject('Hardcap must larger than 0')
                                } 
                                form.setFields([
                                {
                                    name: ['softcap'],
                                    errors: []
                                }
                                ]);
                                return Promise.resolve()
                            }
                            })
                        ]}> 
                            <Input onWheel={ event => event.currentTarget.blur()} name="hardcap" className="inputForm" type="number" onChange={(e) => handleChange(e)}/>
                    </Form.Item>
                </div>

                <div className="verifyToken-left-item">
                    <span className="mb-10">Minimum Buy ({verifyTokenStorage?.currency})</span> 
                    <Form.Item name="minBuy"
                        rules={[
                            {
                            required: true,
                            message: 'MinBuy is required!'
                            }, 
                            ({ getFieldValue }) => ({
                            validator(_,value) {  
                                if(Number(value) >= Number(getFieldValue(['maxBuy']))){ 
                                    return Promise.reject('Min buy must be less than max buy')
                                }  
                                if(Number(value) <= 0){ 
                                    return Promise.reject('Min buy must larger than 0')
                                } 
                                form.setFields([
                                    {
                                    name: ['maxBuy'],
                                    errors: []
                                    }
                                ]);
                                return Promise.resolve()
                                
                        }})
                        ]}>   
                            <Input onWheel={ event => event.currentTarget.blur()} name="minBuy" className="inputForm" type="number" onChange={(e) => handleChange(e)}/>
                    </Form.Item>  
                </div> 

                <div className="verifyToken-left-item">
                    <span className="mb-10">Maximum Buy ({verifyTokenStorage?.currency})</span> 
                    <Form.Item name="maxBuy"
                        rules={[
                            {
                            required: true,
                            message: 'MaxBuy is required!'
                            }, 
                            ({ getFieldValue }) => ({
                            validator(_,value) {  
                                if(Number(value) <= Number(getFieldValue(['minBuy']))) { 
                                    return Promise.reject('Max buy must langer than min buy')
                                }   
                                if(Number(value) <= 0){ 
                                    return Promise.reject('Max buy must larger than 0')
                                } 
                                form.setFields([
                                    {
                                    name: ['minBuy'],
                                    errors: []
                                    }
                                ]);
                                return Promise.resolve()
                                
                        }})
                        ]}>   
                            <Input onWheel={ event => event.currentTarget.blur()} name="minBuy" className="inputForm" type="number" onChange={(e) => handleChange(e)}/>
                    </Form.Item>  
                </div> 

                <div className="verifyToken-left-item">
                    <span className="mb-10">Refund type</span>
                    <Form.Item 
                        name="refundType"
                        rules={[{ required: true, message: 'Please input your decimals!' }]}
                        > 
                            <Select placeholder="Please select type">
                                <Option value="0">Burn</Option>
                                <Option value="1">Refund</Option>
                            </Select>
                    </Form.Item>
                </div>
            </div>  
            <div className="contributors">
            <span className="main-text">Contributors</span>
            <div className="contributors-form">
                {
                    ["cliffVesting", "lockAfterCliffVesting"].map((e,i)=>(
                        <div key={i} className="verifyToken-left-item">
                            {e ==="cliffVesting" &&  <span className="mb-10">Cliff Vesting (minutes)</span>} 
                            {e ==="lockAfterCliffVesting" &&  <span className="mb-10">Lock After Cliff Vesting (minutes) </span>} 
                            <Form.Item name={e} rules={[
                                { required: true, message: `Please input your ${e}` },
                                ({ getFieldValue }) => ({
                                validator(_,value) { 
                                    if (getFieldValue(['firstReleasePercent']) < 100) {
                                        if (Number(value) < 1) {
                                            return Promise.reject('First Release Percent must larger than 0')
                                        }
                                    }
                                    if (Number(value) < 0) {
                                        return Promise.reject('First Release Percent must larger than 0')
                                    }   
                                    return Promise.resolve()}
                                })
                            ]}> 
                                <Input onWheel={ event => event.currentTarget.blur()} className="inputForm" type="number" />
                            </Form.Item>
                        </div>
                    ))
                } 
                <Form.Item name="usingVestingContributors" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                    <Checkbox onChange={onChange}>Using vesting contributors?</Checkbox>
                </Form.Item>   
                {checkbox ? ["firstReleasePercent", "vestingPeriodEachCycle" ,"tokenReleaseEachCycle"].map((e,i)=>(
                            <div key={i} className="verifyToken-left-item"> 
                                {e ==="firstReleasePercent" && <>
                                <span className="mb-10">First Release For Presale (%)</span>  
                                    <Form.Item name="firstReleasePercent" rules={[{
                                            required: true,
                                            message: 'firstReleasePercent is required!'
                                            }, 
                                            ({ getFieldValue }) => ({
                                            validator(_,value) {  
                                                if((Number(value) + Number(getFieldValue(['tokenReleaseEachCycle']))) > 100) { 
                                                    return Promise.reject('First release for presale and Presale token release each cycle must be less than 100 percent')
                                                }  
                                                if (Number(value) < 0 || Number(value) > 100) {
                                                    return Promise.reject(`First release for presale must range 1-100%`)
                                                }  
                                                form.setFields([
                                                    {
                                                        name: ['tokenReleaseEachCycle'],
                                                        errors: []
                                                    }
                                                ]);
                                                form.setFields([
                                                    {
                                                        name: ['vestingPeriodEachCycle'],
                                                        errors: []
                                                    }
                                                ]);
                                                form.setFields([
                                                    {
                                                        name: ['cliffVesting'],
                                                        errors: []
                                                    }
                                                ]);
                                                form.setFields([
                                                    {
                                                        name: ['lockAfterCliffVesting'],
                                                        errors: []
                                                    }
                                                ]);   
                                            return Promise.resolve();
                                    }})
                                    ]}>
                                        <Input onWheel={ event => event.currentTarget.blur()} name="firstReleasePercent" className="inputForm" type="number" onChange={(e) => handleChange(e)}/>
                                    </Form.Item>  
                                </>
                                }  

                                {e ==="tokenReleaseEachCycle" && <>
                                <span className="mb-10">Presale Token Release Each Cycle (%)</span>  
                                <Form.Item name="tokenReleaseEachCycle" rules={[{
                                    required: true,
                                    message: 'tokenReleaseEachCycle is required!'
                                    }, 
                                    ({ getFieldValue }) => ({
                                    validator(_,value) {  
                                        if((Number(value) + Number(getFieldValue(['firstReleasePercent']))) > 100) { 
                                            return Promise.reject('First release for presale and Presale token release each cycle must be less than 100 percent')
                                        }   
                                        if (getFieldValue(['firstReleasePercent']) < 100) {
                                            if (Number(value) < 1) {
                                                return Promise.reject('Token Release Each Cycle must larger than 0')
                                            }    
                                        }
                                        if (Number(value) < 0) {
                                            return Promise.reject('Token Release Each Cycle must larger than 0')
                                        }  
                                        form.setFields([
                                            {
                                            name: ['firstReleasePercent'],
                                            errors: []
                                            }
                                        ]);
                                    return Promise.resolve();
                                    }})
                                    ]}>
                                        <Input onWheel={ event => event.currentTarget.blur()} name="tokenReleaseEachCycle" className="inputForm" type="number" onChange={(e) => handleChange(e)}/>
                                </Form.Item> 
                                </>}  

                                {e ==="vestingPeriodEachCycle" &&  <>
                                    <span className="mb-10">Vesting Period Each Cycle (minutes)</span>
                                    <Form.Item name="vestingPeriodEachCycle" rules={[
                                        {required: true, message: `Please input your Vesting Period Each Cycle `},
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (getFieldValue(['firstReleasePercent']) < 100) {
                                                    if (Number(value) < 1) {
                                                        return Promise.reject(`vestingPeriodEachCycle must larger than 0`)
                                                    }
                                                }
                                                return Promise.resolve()
                                            }
                                        })  
                                        ]}> 
                                        <Input onWheel={ event => event.currentTarget.blur()} className="inputForm" type="number" />
                                    </Form.Item>
                                </>}   
                            </div>
                )) : null}  
            </div> 
            </div> 
        </div> 
        <div className="verifyToken-right">  
                <div className="verifyToken-left-item">
                    <span className="mb-10">Whitelist</span>
                    <Form.Item name="whiteListOnly"> 
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}  
                        onChange={onChangeSwitch}
                        defaultChecked={checkSwitch}
                        />
                    <span className="switchText"> {checkSwitch? "Enable": "Disable"}</span>
                    </Form.Item>
                </div>
               
                <div className="verifyToken-left-item">
                    <span className="mb-10">Start Time (UTC)</span>
                    <Form.Item name="startTime" rules={[
                        { required: true, message: `Please input your contribution time` },
                        {
                            validator: (rule, value, cb: (msg?: string) => void) => {
                                !value || new Date(value) <= new Date(Date.now())
                                    ? cb("Start time needs to be after now")
                                    : cb();
                            }
                        }
                    ]}> 
                        <DatePicker showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onOk={onOkStartTime} />
                        {/* <RangePicker showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onChange={onRangeChange}  /> */}
                    </Form.Item>
                </div>
                <div className="verifyToken-left-item">
                    <span className="mb-10">End Time (UTC)</span>
                    <Form.Item name="endTime" rules={[
                        { required: true, message: `Please input your contribution time` },
                        {
                            validator: (rule, value, cb: (msg?: string) => void) => {

                                if (!value || new Date(value) <= new Date(Date.now())) {
                                    cb("End time needs to be after now time");
                                } else if (new Date(value) <= form.getFieldValue('startTime')) {
                                    cb("End time needs to be after start time");
                                } else {
                                    cb();
                                }
                            }
                        }
                    ]}> 
                        <DatePicker showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onOk={onOkEndTime} />
                        {/* <RangePicker showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onChange={onRangeChange}  /> */}
                    </Form.Item>
                </div>
                {verifyTokenStorage?.listingOptions !== "MANUAL" && <>
                <div className="verifyToken-left-item">
                    <span className="mb-10">Router</span>
                    <Form.Item 
                    name="router"
                    rules={[{ required: true, message: 'Please select router!' }]}
                    >  
                     <Select placeholder="Please select router" onChange={handleChangeRouter}>
                        {dexExchanges.map((e:any,i:any)=>( 
                            <Option key={i} value={e.id}>{e.name}</Option>  
                        ))}  
                      </Select> 
                    </Form.Item>
                </div> 
                </>}
                <div className="verifyToken-left-item"> 
                    <div className="verifyToken-right-text">
                    <span className="mb-10">{selectRouter !== '' ? selectRouter+' ' : ''}Listing Rate</span>
                        <div className="tooltip">
                            <img src="../images/question-circle.png" alt="" />
                            <span className="tooltiptext">Usually this amount is lower than presale rate to allow for a higher listing price on Pancakeswap.</span>
                        </div>
                    </div>  

                    <Form.Item name="listingRate" rules={[
                        { required: true, message: 'Please input your listing rate' },
                        {validator(_,value) {   
                            if(Number(value) <= 0){ 
                                return Promise.reject(`Listing rate must larger than 0`)
                            }   
                            return Promise.resolve()}
                        }]}> 
                        <Input onWheel={ event => event.currentTarget.blur()} className="inputForm" type="number" name="listingRate" onChange={(e) => handleChange(e)}/>
                    </Form.Item>
                </div>
                {verifyTokenStorage?.listingOptions === "AUTO"? <>
                    <div className="verifyToken-left-item">
                        <div className="verifyToken-right-text">
                            <span className="mb-10">{selectRouter !== '' ? selectRouter+' ' : ''}Liquidity (%)</span>
                            <div className="tooltip">
                                <img src="../images/question-circle.png" alt="" />
                                <span className="tooltiptext">The percentage of raised funds that should be allocated to Liquidity on Pancakeswap (Min 51%, Max 100%).</span>
                            </div>
                        </div>
                        <Form.Item name="liquidityPercent" rules={[
                            {
                                validator: (_, value) => {
                                    if (value > 50 && value <= 100) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject('Liquidity must be greater than 50%');
                                    }
                                }
                            }
                        ]}>
                            <Input onWheel={event => event.currentTarget.blur()} className="inputForm" type="number" name="liquidityPercent" onChange={(e) => handleChange(e)} />
                        </Form.Item>
                    </div>

                    <div className="verifyToken-left-item" >
                        <span className="mb-10">Liquidity Lockup (minutes)</span>
                        <Form.Item name="liquidityLockup" rules={[
                            { required: true, message: 'Please input your liquidity lock up' },
                            {
                                validator(_, value) {
                                    if (Number(value) <= 5) {
                                        return Promise.reject(`Liquidity lock up time must be greater than 5 minutes`)
                                    }
                                    return Promise.resolve()
                                }
                            }]}>
                            <Input onWheel={event => event.currentTarget.blur()} className="inputForm" type="number" />
                        </Form.Item>
                    </div>
                </>:null} 
                
                <div className="contributors">
                    <span className="main-text">Team Lock</span>
                <div className="contributors-form">
                <Form.Item name="usingVestingTeamLock" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                    <Checkbox onChange={onChangeTeam}>Have team lock?</Checkbox>
                </Form.Item>  
                {checkboxTeam ?
                <>
                <div className="verifyToken-left-item">
                     <span className="mb-10">Title</span>
                     <Form.Item name="title" rules= {checkboxTeam? [{ required: true, message: `Please input your title` }] : []}> 
                        <Input onWheel={ event => event.currentTarget.blur()} className="inputForm"/>
                    </Form.Item>
                 </div> 
                 <Form.Item name="rememberAnotherOwner" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                    <Checkbox onChange={onChangeAnotherOwner}>Using another owner?</Checkbox>
                </Form.Item>  
                {checkboxAnotherOwner ?
                <div className="verifyToken-left-item">
                     <span className="mb-10">Another Owner</span>
                     <Form.Item name="useAnotherOwner" rules= {checkboxAnotherOwner? [{ required: true, message: `Please input your another owner` }] : []}> 
                        <Input onWheel={ event => event.currentTarget.blur()} className="inputForm" />
                    </Form.Item>
                 </div> :null}  

                 <div className="verifyToken-left-item">
                     <span className="mb-10">Amount</span>
                     <Form.Item name="amount" rules= {checkboxTeam? 
                        [{ required: true, message: `Please input your amount` },
                        {validator(_,value) {   
                            if(Number(value) <= 0){ 
                                return Promise.reject(`Amount must larger than 0`)
                            }   
                            return Promise.resolve()}
                        }] :
                        []}> 
                        <Input onWheel={ event => event.currentTarget.blur()} onChange={(e) => setAmountVestingTeam(Number(e.target.value))} className="inputForm" type="number"  />
                    </Form.Item>
                 </div> 
                {["cliffVestingTeam", "lockAfterCliffVestingTeam"].map((e,i)=>(
                    <div key={i} className="verifyToken-left-item">
                        {e ==="cliffVestingTeam" &&  <span className="mb-10">Cliff Vesting (minutes)</span>}  
                        {e ==="lockAfterCliffVestingTeam" &&  <span className="mb-10">Lock After Cliff Vesting (minutes)</span>}
                        <Form.Item name={e} rules= {checkboxTeam? [
                            { required: true, message: `Please input your ${e}` },
                            e === "lockAfterCliffVestingTeam" ? ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (Number(value) < 1) {
                                        return Promise.reject(`${e} > 0`)
                                    }
                                    return Promise.resolve()
                                }
                            }) :
                            ({ getFieldValue }) => ({
                                validator(_, value) { 
                                    if (Number(value) < 0) {
                                        return Promise.reject(`${e} > 0`)
                                    }  
                                    return Promise.resolve()
                                }
                            })  
                            ] : []}> 
                            <Input onWheel={ event => event.currentTarget.blur()} className="inputForm" type="number"  />
                        </Form.Item>
                    </div>
                    ))
                } 
                 
                {["tgeDate", "tgePercentTeam","vestingCycleTeam","cycleReleasePercentTeam"].map((e,i)=>(
                    <div key={i}>
                    {e === "tgeDate" ?  <div className="verifyToken-left-item">
                        <span className="mb-10">First Release For Presale (UTC)</span>
                        <Form.Item name="tgeDate" rules= {checkboxTeam? [{ required: true, message: `Please input your first Release For Presale` }] : []}> 
                                <DatePicker format={'DD/MM/YYYY HH:mm:ss'} showTime disabledDate={(current) => {
                                    return moment().add(0, 'days') >= current || moment().add(1, 'month') <= current;
                                }} />
                        </Form.Item>
                    </div> :
                    <div className="verifyToken-left-item">  
                        {e ==="tgePercentTeam" && <>
                            <span className="mb-10">First Release For Presale (%)</span> 
                            <Form.Item name="tgePercentTeam" rules={[{
                                required: true,
                                message: 'tgePercentTeam is required!'
                                }, 
                                ({ getFieldValue }) => ({
                                validator(_,value) {    
                                    if((Number(value) + Number(getFieldValue(['cycleReleasePercentTeam']))) > 100) { 
                                        return Promise.reject('First release for presale and cycle release percent must be less than 100 percent')
                                    } 
                                    if (Number(value) < 1 || Number(value) > 100){ 
                                        return Promise.reject(`First release for presale must range 1-100%`)
                                    }    
                                    form.setFields([
                                        {
                                            name: ['cliffVestingTeam'],
                                            errors: []
                                        }
                                    ]);
                                    form.setFields([
                                        {
                                            name: ['lockAfterCliffVestingTeam'],
                                            errors: []
                                        }
                                    ]);
                                    form.setFields([
                                        {
                                            name: ['vestingCycleTeam'],
                                            errors: []
                                        }
                                    ]);
                                    form.setFields([
                                        {
                                            name: ['cycleReleasePercentTeam'],
                                            errors: []
                                        }
                                    ]);
                                    
                                return Promise.resolve();
                                }})
                                ]}> 
                                    <Input onWheel={ event => event.currentTarget.blur()} name="tgePercentTeam" className="inputForm" type="number" onChange={(e) => handleChange(e)}/>
                            </Form.Item>   
                        </> }  
                        {e ==="vestingCycleTeam" && <>
                            <span className="mb-10">Cycle (minutes)</span>
                            <Form.Item name={e} rules= {checkboxTeam ? 
                                [{required: true, message: `Please input your ${e}`},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (getFieldValue(['tgePercentTeam']) < 100) {
                                            if (Number(value) < 1) {
                                                return Promise.reject(`Vesting Cycle must larger than 0`)
                                            }
                                        }
                                        if (Number(value) < 1) {
                                            return Promise.reject(`Vesting Cycle must larger than 0`)
                                        }
                                        return Promise.resolve()
                                    }
                                })]
                                : []}> 
                                <Input onWheel={ event => event.currentTarget.blur()} className="inputForm" type="number" />
                            </Form.Item>
                        </> } 
                        {e ==="cycleReleasePercentTeam" && <>
                            <span className="mb-10">Cycle Release Percent (%)</span> 
                            <Form.Item name="cycleReleasePercentTeam" rules={checkboxTeam ? [{
                                required: true,
                                message: 'cycleReleasePercentTeam is required!'
                                }, 
                                ({ getFieldValue }) => ({
                                validator(_,value) {  
                                    if (getFieldValue(['tgePercentTeam']) < 100) {
                                        if (Number(value) < 1) {
                                            return Promise.reject(`Cycle Release Percent must larger than 0`)
                                        }
                                    }
                                    if((Number(value) + Number(getFieldValue(['tgePercentTeam']))) > 100) { 
                                        return Promise.reject('First release for presale and cycle release percent must be less than 100 percent')
                                    }   
                                    if(Number(value) < 1){ 
                                        return Promise.reject(`Cycle Release Percent must larger than 0`)
                                    }   
                                    form.setFields([
                                        {
                                        name: ['tgePercentTeam'],
                                        errors: []
                                        }
                                    ]);
                                return Promise.resolve();
                                }})
                                ]: []} > 
                                    <Input onWheel={ event => event.currentTarget.blur()} name="cycleReleasePercentTeam" className="inputForm" type="number" onChange={(e) => handleChange(e)}/>
                            </Form.Item>    
                        </> }  
                    </div> 
                    }
                    </div>
                 ))}
                 </>
                 :null}  
               </div>
            </div> 
        </div> 
        </div>
        <div className="footer-tab">
            <div className="fixed-bottom">
            {closeTip ? null : <div className="caution">
                <div className="caution-bg blue">
                    <img src="../images/caution.png"/>
                    <span>Need {totalToken} {tokenStorage?.symbol} to create Launchpad</span>
                    <img onClick={()=>handleCloseTip()} src="../images/close.png"/>
                </div> 
            </div> }  
            <div className='btn-verify'>
                <div className="isWidth">
                    <Button text='Cancel' type='border' onClick={() => handleCancel()}/>
                </div> 
                <div className="m-25 isWidth">
                    <ButtonAntd className="c-btn" htmlType="button" onClick={()=>handleBack()}>
                        Previous
                    </ButtonAntd>
                </div> 
                <div className="isWidth">
                    <Button text='Next' type="blue"/>
                </div> 
            </div>
            </div> 
        </div>  
        </Form>  
    </div>  
  )
}
 

export default LauchpadInfo;