import { useState, useEffect } from "react";  
import {Form, Radio, Button as ButtonAntd, Checkbox, Input} from 'antd';   
import type { RadioChangeEvent } from 'antd';
import { Button as ButtonComp } from '../component'; 
import { CustomButtonType } from '../component'; 
 
import { tokenStorageSelector } from "../../redux/slicers/tokenStorageSlice";
import { additionInfo, createLaunchpadSelector, lauchpadInfo, verifyToken} from "../../redux/slicers/createLauchpadSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useTokenContract } from "../../hooks/useContract";
import { getTokenAllowance, tokenApprove, tokenInformation } from "../../contracts/token";
import { Store } from 'react-notifications-component';
import addNotify from "../commons/Notify/addNotify";
import { useActiveWeb3React } from "../../hooks";
import { CHAINID_FULLNAME, FEE_SETTINGS, LAUNCHPAD_CONFIGS, MAIN_CURRENCIES, MAPPING_CHAINID_DECIMAL, SALE_CURRENCIES, SMART_CONTRACT_ADDRESS } from "../../constants";
import { useNavigate, useSearchParams } from "react-router-dom"; 
import { Loading } from "../component";  
import { InputChange } from "../../components/component"; 
import { getAppSettings, settingSelector } from "../../redux/slicers/settingSlice";
import { setTokenStorage } from "../../redux/slicers/tokenStorageSlice";
 
const VerifyToken  =(props) =>{   
    const [form] = Form.useForm();
    const [valueFee, setValueFee] = useState(1);
    const [valueListing, setValueListing] = useState(1); 
    const [valueAffiliate, setValueAffiliate] = useState<any>(0);
      const [checkAffiliate, setCheckAffiliate] = useState<any>();
    const [close, setClose] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState("");
    const [actionStatus, setActionStatus] = useState("");
    const [loadingPage, setLoadingPage] = useState<boolean>(false);
    const [tokenSC, setTokenSC] = useState<any>();
    const [saleCurrencies, setSaleCurrencies] = useState<any>([]);
    const [mainCurrency, setMainCurrency] = useState<any>("");
  
    const { tokenStorage } = useAppSelector(tokenStorageSelector);
    const { settings } = useAppSelector(settingSelector); 
    const { verifyTokenStorage } = useAppSelector(createLaunchpadSelector); 
    const dispatch = useAppDispatch();  
    const navigate = useNavigate()
    
    const { account, chainId } = useActiveWeb3React();  
    const [valueCurrency, setValueCurrency] = useState(chainId === 421613 ? "AGOR" : chainId === 42161 ? "ETH" : "" );
    const [searchParams, setSearchParams] = useSearchParams();
    const tokenAddress = searchParams.get('token');
    const typeLaunchpad = Number(searchParams.get('type'));
    const tokenContract = useTokenContract(tokenAddress); 
    const launchpadDeployAddress = chainId && SMART_CONTRACT_ADDRESS[chainId]?.DEPLOY_LAUNCHPAD_ADDRESS;
    let netName = ''; 
    useEffect(() => {
        if(chainId) {
            switch (chainId) {
                case MAPPING_CHAINID_DECIMAL.bsc:
                    netName = CHAINID_FULLNAME.bsc; 
                    break;
                    
                case MAPPING_CHAINID_DECIMAL.poly:
                    netName = CHAINID_FULLNAME.poly; 
                    break;
        
                case MAPPING_CHAINID_DECIMAL.eth:
                    netName = CHAINID_FULLNAME.eth; 
                    break;
        
                default:
                    netName = CHAINID_FULLNAME.bsctestnet; 
                    break;  
            } 
            setSaleCurrencies(SALE_CURRENCIES[chainId]);
            setMainCurrency(MAIN_CURRENCIES[chainId]);
        }
        
    }, [chainId]);
    
    useEffect(()=> {
        const payload:any = {
            "page": 0,
            "size": 100
        }
        dispatch(getAppSettings(payload));
    }, []);
   
    useEffect(() => {
        (async() => { 
            setLoadingPage(true);
            await tokenInformation(tokenContract)
            .then((res:any) => {   
                setTokenSC(res); 
                (async() => {
                    await getTokenAllowance(tokenContract, account, launchpadDeployAddress)
                    .then((res:any) => {
                        if(res > 0) {
                            setActionStatus("APPROVED");
                        }
                        else{
                            setActionStatus("");
                        }
                    });
                })() 
                form.setFieldsValue(res); 
                setLoadingPage(false);  
            })
            .catch((err:any) => {   
                form.resetFields();
                setLoadingPage(false)
            }) 
        })();
    }, [tokenAddress, account]); 

    useEffect(() => {
        setValueCurrency(verifyTokenStorage?.currency ? mainCurrency : (chainId === 421613 ? "AGOR" : chainId === 42161 ? "ETH" : "" ));
        let _feeSettings = chainId && FEE_SETTINGS[chainId];
        let _tokenRaisedFeeOnlyValue = settings.filter((itemSetting) => _feeSettings?.LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY === itemSetting.settingKey)?.[0];  
         setValueFee(verifyTokenStorage?.feeOptions ?? _tokenRaisedFeeOnlyValue?.settingKey);
        if(chainId === 421613) {
            form.setFieldsValue({
                "currency": verifyTokenStorage?.currency ? mainCurrency : 'AGOR',
                "feeOptions": verifyTokenStorage?.feeOptions ?? _tokenRaisedFeeOnlyValue?.settingKey,
                "listingOptions": verifyTokenStorage?.listingOptions ?? "AUTO",
                "affiliateOptions":  verifyTokenStorage?.affiliateOptions ?? "0"
            });
            
        } else if(chainId === 42161) {
            form.setFieldsValue({
                "currency": verifyTokenStorage?.currency ? mainCurrency : 'ETH',
                "feeOptions": verifyTokenStorage?.feeOptions ?? _tokenRaisedFeeOnlyValue?.settingKey,
                "listingOptions": verifyTokenStorage?.listingOptions ?? "AUTO",
                "affiliateOptions":  verifyTokenStorage?.affiliateOptions ?? "0"
            });
        }
        else {
            form.setFieldsValue({
                "currency": verifyTokenStorage?.currency ? mainCurrency : "BNB",
                "feeOptions": verifyTokenStorage?.feeOptions ?? _tokenRaisedFeeOnlyValue?.settingKey,
                "listingOptions": verifyTokenStorage?.listingOptions ?? "AUTO",
                "affiliateOptions":  verifyTokenStorage?.affiliateOptions ?? "0"
            });
        }
    }, [mainCurrency, chainId, settings]);


    const handleCancel = () => {
        dispatch(lauchpadInfo({}));
        dispatch(verifyToken({}));
        dispatch(setTokenStorage({}));
        dispatch(additionInfo({}))
        navigate("/launchpad/list");
        localStorage.setItem("step", "1");
    }

    const handleBack = () =>{
        props.setActiveKey("1"); 
        localStorage.setItem("step", "1") 
    }
    const handleClose = () =>{
        setClose(true)
    }

    const handleApprove = () =>{
        setLoadingBtn("loading");
        tokenApproval(); 
    } 
  
    const onFinish = (values: any) => {  
        if(tokenSC){
            dispatch(verifyToken({   
                ...values,
                
                "ownerAddress": account,
                "tokenType": "STANDARD",
                "tokenAddress": tokenStorage?.tokenAddress,
                "tokenName": tokenStorage?.tokenName,
                "symbol": tokenStorage?.symbol,
                "decimals": tokenStorage?.decimals,
                "totalSupply": tokenStorage?.totalSupply, 
                "networkChain": tokenStorage?.networkChain,
                "antiBotEnable": tokenStorage?.antiBotEnable, 
                "percentAffiliate": valueAffiliate
            }
            ));
            props.setActiveKey("3");
            localStorage.setItem("step", "3");
        } 
      
    };
    const onChangeCurrency = (e: RadioChangeEvent) =>{
        setValueCurrency(e.target.value);

        if (chainId === 42161) {
            form.setFieldsValue({
                "listingOptions": e.target.value === "ETH" ? "" : "MANUAL"
            });
        }
    } 
 
    const onChangeFee = (e: RadioChangeEvent) =>{
        setValueFee(e.target.value)
    }
    
    const onChangeListing = (e: RadioChangeEvent) => { 
        setValueListing(e.target.value);
    }; 
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    }; 

    const onChangeAffiliate = (e: RadioChangeEvent) => {
        setCheckAffiliate(e.target.value)
    }  
    
    const tokenApproval = async () => {
        setActionStatus("LOADING");
        await tokenApprove(tokenContract, launchpadDeployAddress)
        .then((res:any) => {  
            let resWait:any = res.wait();
            resWait.then((resTransaction:any) => {
                setActionStatus("APPROVED");
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    Store.addNotification(addNotify('Approved successfully', 'success'));
                    setLoadingBtn("done");
                } else {
                    Store.addNotification(addNotify('Approved failed', 'danger'));
                }
            })
            .catch((error:any) => {
                setActionStatus("");
                if(error.data){
                    Store.addNotification(addNotify(error.data.message, 'warning'));
                }            
            });
        })
        .catch((error:any) => {
            setActionStatus("");
            if(error.data){
                Store.addNotification(addNotify(error.data.message, 'warning'));
            } 
        });
    }

    const getActionButton = (status:string) => { 
        let actionButton:any = '';
        if(status === "LOADING") { 
            actionButton =   
             <ButtonComp isLoading={true} disabled={true} text='Approve' type="blue"></ButtonComp> 
           ;
        }
        else if(status === "APPROVED") { 
            actionButton = <ButtonComp text='Next' type="blue"></ButtonComp>
        }
        else if(status === "") { 
            actionButton = tokenSC ? <ButtonComp onClick={() => tokenApproval()} text='Approve' type="blue"/> :
            <ButtonComp disabled={true} text='Approve' type="blue"/>  
        }

        return  (<div className="isWidth">{actionButton}</div>);
    }

    const feeOptions = () => {
        let tokenRaisedFeeOnlyButton:any, radioButton:any = "";
        let feeSettings = chainId && FEE_SETTINGS[chainId];
        let tokenRaisedFeeOnlyValue = settings.filter((itemSetting) => feeSettings?.LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY === itemSetting.settingKey)?.[0];  
        
        tokenRaisedFeeOnlyButton = <Radio value={tokenRaisedFeeOnlyValue?.settingKey} key='fee-1'>
                        {tokenRaisedFeeOnlyValue?.settingValue}{tokenRaisedFeeOnlyValue && tokenRaisedFeeOnlyValue?.feeType === "PERCENT" ? '%' : '%'} {valueCurrency} raised only
                    </Radio>;
        let amountRaisedFee = settings.filter((itemSetting) => feeSettings?.LIST_LAUNCHPAD_AMOUNT_RAISED_FEE === itemSetting.settingKey)?.[0];
        let tokenRaisedFee = settings.filter((itemSetting) => feeSettings?.LIST_LAUNCHPAD_TOKEN_RAISED_FEE === itemSetting.settingKey)?.[0]; 

       
        radioButton = <Radio value={amountRaisedFee?.settingKey+"_"+tokenRaisedFee?.settingKey} key='fee-11'>
                            {amountRaisedFee?.settingValue}{amountRaisedFee?.feeType === "PERCENT" ? '%' : '%'} {valueCurrency} raised {' + '}
                            {tokenRaisedFee?.settingValue}{tokenRaisedFee?.feeType === "PERCENT" ? '%' : '%'} token sold
                        </Radio>
        return (<>
                    {tokenRaisedFeeOnlyButton} 
                    {radioButton}
                </> );
    }

    const handleSetMax = () =>{
        setValueAffiliate(5)
    }

    const defaultCurrencyChecker = () => {
        let currencyDefault = '';
        if(chainId === 421613) {
            currencyDefault = 'AGOR';
        } else if(chainId === 42161) {
            currencyDefault = 'ETH';
        }
        return currencyDefault;
    }
 
    return (
        <>
        {loadingPage ? <Loading/> :null}
        <div className='verifyToken'>
        <div className='step'>Step 2</div> 
        <Form 
            name="basic" 
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form = {form}
            initialValues={{currency: valueCurrency}} //mainCurrency
            >
        <div className="verifyToken-form">
        <div className="verifyToken-left"> 
            <div className="p-tokenCreateLock form">
                <InputChange title='Token Address' value={tokenSC?.tokenAddress} disabled={true}/>
                <InputChange title='Token Name' value={tokenSC?.tokenName} disabled={true}/>
                <InputChange title='Symbol' value={tokenSC?.symbol} disabled={true}/> 
                <InputChange title='Decimals' value={tokenSC?.decimals} disabled={true}/>
            </div>
        </div> 
        <div className="verifyToken-right"> 
            <div className="currency">
                <div className="verifyToken-right-text">
                    <span className="currency-text">Currency</span>
                    <div className="tooltip">
                        <img src="../images/question-circle.png" alt="" />
                        <span className="tooltiptext">BUSD, USDC, USDT: Do not use this currency for auto liquidity tokens, or tokens that depend on WETH pair. It will lead to error when finalizing the pool or transfering the tokens (for example Liquidity Generator Token, BabyToken, Buyback Baby Token).
                        Contact BlueSale for more information. </span>
                    </div>
                </div> 
                <div className="currency-select">
                <Form.Item name="currency" rules={[{ required: true, message: 'Please choose currency' }]}>
                    <Radio.Group onChange={onChangeCurrency} value={valueCurrency} defaultValue={defaultCurrencyChecker}>
                        {saleCurrencies?.map((currency) => <Radio value={currency}>{currency}</Radio>)}
                    </Radio.Group>
                </Form.Item>
                </div> 
            </div>
        
            <div className="fee">
                <span className="fee-text">Fee Options</span>
                <div className="fee-select">
                    <Form.Item name="feeOptions" rules={[{ required: true, message: 'Please choose fee' }]}>
                        <Radio.Group onChange={onChangeFee} value={valueFee}>
                            {feeOptions()}
                        </Radio.Group>
                    </Form.Item>
                </div> 
            </div>  
            {typeLaunchpad === 1 && 
                <div className="listing">
                    <div className="verifyToken-right-text">
                        <span className="currency-text">Listing Options</span>
                        <div className="tooltip">
                            <img src="../images/question-circle.png" alt="" />
                            <span className="tooltiptext">
                            For auto listing, after you finalize the pool your token will be auto listed on DEX.
                            For manual listing, BlueSale won't charge tokens for liquidity.
                            You may withdraw BNB after the pool ends then do DEX listing yourself. </span>
                        </div>
                    </div>   
                    <div className="listing-select">
                        <Form.Item name="listingOptions" rules={[{required: true, message: 'Please choose listing options',},]}>
                            <Radio.Group onChange={onChangeListing} value={valueListing}>
                                {(chainId === 42161) ? 
                                <><Radio value="AUTO" disabled={!(valueCurrency === "ETH")}>Auto listing</Radio>
                                </> : <><Radio value="AUTO">Auto listing</Radio></>}
                                <Radio value="MANUAL">Manual listing</Radio> 
                            </Radio.Group>
                        </Form.Item>
                    </div> 
                </div>} 

                <span className="currency-text">Affiliate Program</span>
                <div className="listing-select">
                    <Form.Item name="affiliateOptions" rules={[{required: true, message: 'Please choose affiliate program',},]}>
                        <Radio.Group onChange={onChangeAffiliate} value={checkAffiliate}>
                            <Radio value="1">Enable Affiliate</Radio>
                            <Radio value="0">Disable Affiliate</Radio> 
                        </Radio.Group>
                    </Form.Item>
                </div>  
                {checkAffiliate === "1" ? 
                <> 
                    <div className="verifyToken-right-text"> 
                        <Form.Item name="percentAffiliate" initialValue={valueAffiliate} rules= {valueAffiliate? [
                            { required: true, message: `Please input percent` },
                            {validator(_,value) {   
                            if(Number(valueAffiliate) <= 0){ 
                                return Promise.reject(`Listing rate must larger than 0`)
                            }   
                            if(Number(valueAffiliate) > 5){ 
                                return Promise.reject(`Listing rate must smaller than 5`)
                            }  
                            return Promise.resolve()}
                            }] : []
                            }
                        > 
                            <div className="c-input affiliate-form">
                                <Input
                                    onWheel={ event => event.currentTarget.blur()}
                                    value={valueAffiliate}
                                    onChange={(e) => setValueAffiliate(e.target.value)}
                                    placeholder="0.0"
                                    type="number"
                                />
                                <CustomButtonType type="button" text="Max" onClick={() => handleSetMax()} /> 
                            </div> 
                        </Form.Item>
                    </div> 
                </> 
                : null} 

        </div> 
        </div> 
        <div className="fixed-bottom">
        {close ? null : 
        <div className="caution">
            <div className="caution-bg">
                <img src="../images/caution.png"/>
                <span>Make sure the token has “Exclude transfer fee” function if it has transfer fees.</span>
                <div onClick={()=>handleClose()}>
                    <img src="../images/close.png" alt="Remove" />
                </div> 
            </div> 
        </div> } 
        <div className='btn-verify'>
            <div className="isWidth">
                <ButtonAntd className="c-btn c-btn--greyBorder" htmlType="button" onClick={()=>handleCancel()}>
                    Cancel
                </ButtonAntd>
            </div> 
            <div className="m-25 isWidth">
                <ButtonAntd className="c-btn" htmlType="button" onClick={()=>handleBack()}>
                    Previous
                </ButtonAntd>
            </div>
            {getActionButton(actionStatus)} 
        </div>
        </div> 
        </Form>  
        </div>  
        </> 
  )
}

export default VerifyToken;
