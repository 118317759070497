import http from "./http-common";
import { instance } from "../../apiBase/instance";

const DevidendDashBoardService = {
  getAuthToken(account: any) {
    return http.post("/authenticate/address", {
      address: account,
    });
  },
  saveInforReedem(
    id,
    blsOut: any,
    creTime: any,
    txh: any,
    action: any,
    status: any,
    xBlsIn: any,
    authToken: any
  ) {
    let payload: any = {
      id,
      blsOutput: blsOut,
      creTime: creTime,
      txhash: txh,
      action: action,
      status: status,
      xBlsInput: xBlsIn,
    };
    return instance.post("/dividend-histories", payload, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  },

  getInforReedem(authToken: any) {
    return instance.get("/dividend-histories", {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  },
  getInforReedemPage(authToken: any, page: any, size: any) {
    return instance.get(
      `/dividend-histories/pageable?page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  },
};

export default DevidendDashBoardService;
// {
//     "id": 0,
//     "blsOutput": 0,
//     "creTime": "2023-04-17T16:04:45.475Z",
//     "txhash": "string",
//     "action": "claim",
//     "status": "success",
//     "xBlsInput": 0
//   }
