//import React, { useState } from 'react';

export const Plus = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.41634 6.41683V1.4585H7.58301V6.41683H12.5413V7.5835H7.58301V12.5418H6.41634V7.5835H1.45801V6.41683H6.41634Z"/>
        </svg>
    )
}

export const Upload = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.95486 6.70964L6.91683 6.70964L6.91683 12.543L8.0835 12.543L8.0835 6.70964L10.0455 6.70964C10.2993 6.70964 10.432 6.40796 10.2605 6.22088L7.71517 3.44419C7.59957 3.31808 7.40076 3.31808 7.28516 3.44419L4.73986 6.22088C4.56837 6.40796 4.70108 6.70964 4.95486 6.70964ZM1.9585 2.6263L1.9585 1.45964L13.0418 1.45964L13.0418 2.6263L1.9585 2.6263Z" />
        </svg>
    )
}

export const Grid = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.5 2.5C2.94772 2.5 2.5 2.94772 2.5 3.5V10C2.5 10.5523 2.94772 11 3.5 11H10C10.5523 11 11 10.5523 11 10V3.5C11 2.94772 10.5523 2.5 10 2.5H3.5ZM4.5 9V4.5H9V9H4.5ZM2.5 14C2.5 13.4477 2.94772 13 3.5 13H10C10.5523 13 11 13.4477 11 14V20.5C11 21.0523 10.5523 21.5 10 21.5H3.5C2.94772 21.5 2.5 21.0523 2.5 20.5V14ZM4.5 15V19.5H9V15H4.5ZM14 13C13.4477 13 13 13.4477 13 14V20.5C13 21.0523 13.4477 21.5 14 21.5H20.5C21.0523 21.5 21.5 21.0523 21.5 20.5V14C21.5 13.4477 21.0523 13 20.5 13H14ZM15 19.5V15H19.5V19.5H15Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14 2.5C13.4477 2.5 13 2.94772 13 3.5V10C13 10.5523 13.4477 11 14 11H20.5C21.0523 11 21.5 10.5523 21.5 10V3.5C21.5 2.94772 21.0523 2.5 20.5 2.5H14ZM15 9V4.5H19.5V9H15Z" fillOpacity="0.4"/>
        </svg>
    )
}

export const List = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.5 13H2.5V11H4.5V13ZM21.5 13H6.5V11H21.5V13ZM4.5 19.5H2.5V17.5H4.5V19.5ZM21.5 19.5H6.5V17.5H21.5V19.5Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.5 6.5H2.5V4.5H4.5V6.5ZM21.5 6.5H6.5V4.5H21.5V6.5Z" />
        </svg>
    )
}

export const Edit = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.71615 1.10449L12.6035 3.99184L4.38931 12.206H1.50195V9.31869L9.71615 1.10449ZM2.66862 9.80194V11.0394H3.90606L9.50991 5.43552L8.27247 4.19808L2.66862 9.80194ZM9.09743 3.37313L10.3349 4.61056L10.9536 3.99184L9.71615 2.75441L9.09743 3.37313Z"/>
        </svg>
    )
}