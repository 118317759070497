import { useState, useEffect } from 'react'; 
import { Tabs } from 'antd';
import Token from '../../components/createlaunchpad/token';
import VerifyToken from '../../components/createlaunchpad/verifyToken';
import LauchpadInfo from '../../components/createlaunchpad/launchpadInfo';
import AdditionInfo from '../../components/createlaunchpad/additionInfo';
import Finish from '../../components/createlaunchpad/finish';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setTokenStorage, tokenStorageSelector } from '../../redux/slicers/tokenStorageSlice';
import { useSearchParams } from 'react-router-dom';
import { additionInfo, lauchpadInfo, verifyToken } from '../../redux/slicers/createLauchpadSlice';

const CreateLaunchpad = () => {
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState<string>('1');
    const { tokenStorage } = useAppSelector(tokenStorageSelector); 
    const [searchParams, setSearchParams] = useSearchParams();
    const launchpadType = Number(searchParams.get('type'));    
    const launchpadToken = searchParams.get('token');
    const dispatch = useAppDispatch();
    
    const onKeyChange : any = (key) =>
    {
        setActiveKey(key); 
        localStorage.setItem("step", key);  
    }

    useEffect(() => {
        if(launchpadType === 1 || launchpadType === 2) {
            localStorage.setItem("createType", String(searchParams.get('type')));
        }
    }, [searchParams]);
    
    useEffect(() => {
        setActiveKey(localStorage.getItem("step")||"1"); 
        if(localStorage.getItem("step") !== "1" && !tokenStorage?.tokenAddress){
            //setSearchParams({ token: tokenStorage?.tokenAddress });
            // navigate(`/launchpad/create-launchpad`)
        }   
        else{
            // setSearchParams({});
            // navigate(`/launchpad/create-launchpad`)
        }
    }, [localStorage.getItem("step"), tokenStorage?.tokenAddress]);

    useEffect(()=> {
        const createType = localStorage.getItem("createType");
        if(createType !== searchParams.get('type') || !launchpadToken) {
            dispatch(lauchpadInfo({}));
            dispatch(verifyToken({}));
            dispatch(setTokenStorage({}));
            dispatch(additionInfo({}));
            localStorage.setItem("step", "1");
            localStorage.setItem("createType", String(searchParams.get('type')));
        }
    }, [launchpadToken, launchpadType]);
 
    return (
    <div className='c-createLaunchpad'> 
        <Tabs
            activeKey={activeKey} onChange={onKeyChange} 
            items={["Token", "Verify Token", "DeFi Launchpad Info", "Add Addition Info", "Finish"].map((e, i) => {
            const id = String(i + 1); 
            return {
                label: ( 
                <div className='tab-header'>
                    {
                        activeKey <= id  ? 
                        <div className='tab-header-circle'>
                            <span className='tab-header-num'>{id}</span>
                        </div> :
                        <img src="../images/check.png"/>
                    } 
                    <span className='tab-header-text'>{e}</span>
                </div>
                ),
                key: id,
                disabled: activeKey < id && true,
                children:  
                    e === "Token" ? <Token status="FormLauchpad" setActiveKey={setActiveKey} type={searchParams.get('type')}/> :
                    e === "Verify Token" ? <VerifyToken setActiveKey={setActiveKey}/> :
                    e === "DeFi Launchpad Info" ? <LauchpadInfo setActiveKey={setActiveKey}/> : 
                    e === "Add Addition Info" ? <AdditionInfo setActiveKey={setActiveKey}/> : 
                    e === "Finish" ? <Finish setActiveKey={setActiveKey}/> : null,
                };
            })}
        /> 
    </div> 
   )
}
 
export default CreateLaunchpad;