/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    convertToWei
} from "../../contracts/utils";
import { Button, InputChange } from "../../components/component";
import { getTokenInformation, getBalance, isAddress } from '../airdrop/utils';
import tokenAbi from '../../contracts/abi/tokenAbi.json';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import { Radio, Modal, Spin, Pagination } from 'antd';
import { Store } from "react-notifications-component";
import addNotify from "../../components/commons/Notify/addNotify";
import { isMobile } from "react-device-detect";
import type { RadioChangeEvent } from 'antd';
import networkChange from "../../utils/networkChange";
import web3 from "web3";
import {
    formatNum,
} from "../bls/utils";
import StandardTokenABI from "../../contracts/abi/StandardTokenABI.json";
import { approveToken, getAmountApprove, multiSender, getDecimalToken } from "../../contracts/privateSaleUltil";
import { LoadingOutlined } from "@ant-design/icons";
import { MULTI_SENDER, RPC_URL_CONVERT, MAIN_CURRENCIES, TOKEN_DECIMALS_MUTILSENDER } from '../../constants/index'
import { useContract } from "../../hooks/useContract";
import MultisendABI from '../../contracts/abi/MultisendABI.json'


declare const window: Window & typeof globalThis & { ethereum: any };

const MultiSender = () => {

    const { account, chainId, library }: any = useActiveWeb3React();
    const [isStep, setIsStep] = useState<string>('1');
    const [array, setArray] = useState('');
    const [tokenAddress, setTokenAddress] = useState<string>('');
    const [bnbBalance, setBnbBalance] = useState<number>(0);

    const [tokenSymbol, setTokenSymbol] = useState<string>('');
    const [tokenName, setTokenName] = useState<string>('');
    const [tokenDecimal, setTokenDecimal] = useState<any>(0);
    const [tokenBalance, setBalance] = useState<any>(0);
    const [totalTransfer, setTotalTransfer] = useState<any>(0);
    const [isApprove, setisApprove] = useState(false)
    const [totalAddress, settotalAddress] = useState<any>(0);
    const [mainCurrency, setMainCurrency] = useState<string>("");
    // const [decimalTokenSend, setDecimalTokenSend] = useState<any>(0);
    const [amountApprove, setAmountApprove] = useState<any>(0);


    const [arrList, setarrList] = useState<any>([]);
    const [hash, setHash] = useState<any>('');
    const [sttHash, setSttHash] = useState<any>('');

    const [listAdd, setlistAdd] = useState<any>([]);
    const [listAmount, setlistAmount] = useState<any>([]);

    const [isLoading, setisLoading] = useState(false)
    let provider = "";
    if (chainId !== 1 && chainId) {
        provider = RPC_URL_CONVERT[chainId];
    }

    const multiContract = useContract(MULTI_SENDER[chainId], MultisendABI);
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    // const handleAmountApprove = (e: RadioChangeEvent) => {
    //     setAmountApprove(e.target.value);
    // };
    const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

    // console.log('amountApprove', amountApprove)

    /*Tạo hàm kiểm tra phần tử trùng trong mảng JavaScript*/
    function array_is_unique(array, size) {
        //flag =  1 =>  tồn tại phần tử trùng nhau
        //flag =  0 =>  không tồn tại phần tử trùng nhau
        let flag = -1;
        let flagSame = -1
        for (let i = 0; i < size - 2; i + 2) {
            for (let j = i + 2; j < size - 3; j + 2) {
                if (array[i] === array[j]) {
                    /*Tìm thấy 1 phần tử trùng là đủ và dừng vòng lặp*/
                    flag = i;
                    flagSame = j
                    // console.log('i,j', i, j)
                    break;
                }
            }
        }

        return flag;
    }

    // const decimalToken = async () => {
    //     await getDecimalToken(
    //         StandardTokenABI,
    //         tokenAddress,
    //         library,
    //         account,
    //         account
    //     ).then((res) => {
    //         setDecimalTokenSend(res)

    //     })
    // }

    const handleApprove = async () => {
        let arrCheck1 = array.replaceAll('\n', ' ')
        let arrChecked = arrCheck1.split(' ')
        let totalApp = 0
        for (let i = 0; i < arrChecked.length; i++) {
            if (i % 2 !== 0) {
                totalApp = totalApp + Number(arrChecked[i])
            }
        }
        setTotalTransfer(totalApp)

        try {
            setisLoading(true)
            await approveToken(
                StandardTokenABI,
                tokenAddress,
                library,
                account,
                MULTI_SENDER[chainId],
                tokenBalance
            ).then((res) => {
                res.wait().then((resTransaction) => {
                    if (
                        resTransaction &&
                        resTransaction.status &&
                        resTransaction.blockNumber
                    ) {
                        Store.addNotification(addNotify("Approve successfully", "success"));
                        setisApprove(true)
                        setisLoading(false)
                        checkApprove()
                    } else {
                        Store.addNotification(addNotify("Transaction failed", "warning"));
                        setisLoading(false)
                    }
                })
                    .catch(() => {
                        Store.addNotification(addNotify("Transaction failed", "warning"));
                        setisLoading(false)
                    })
            })
        } catch (error: any) {
            console.log('error', error)
            Store.addNotification(addNotify(error?.code || error?.data?.message || 'Invalid input', "warning"));
            setisLoading(false)
        }

    }

    useEffect(() => {
        if (tokenAddress === '') {
            setTokenDecimal(TOKEN_DECIMALS_MUTILSENDER[chainId])
        }
        // else {

        //     setDecimalTokenSend(TOKEN_DECIMALS_MUTILSENDER[chainId])
        // }
    }, [tokenAddress, chainId, isStep])

    const handleNext = async () => {
        let arrListAdd: any = []
        let arrListAmount: any = []
        let arrCheck1 = array.replaceAll('\n', ' ')
        let arrtest = array.split('\n')
        setarrList(arrtest)
        let arrChecked = arrCheck1.split(' ')
        // let x = array_is_unique(arrChecked, arrChecked.length)



        if (arrChecked.length < 2) {
            Store.addNotification(
                addNotify(`Invalid amount`, "danger")
            );
            return
        }
        if (arrChecked.length % 2 !== 0) {
            Store.addNotification(
                addNotify(`Invalid amount`, "danger")
            );
            return
        }
        let totalApp = 0

        // console.log('arrtest', arrtest)

        for (let i = 0; i < arrChecked.length; i++) {
            if (i % 2 === 0) {
                arrListAdd.push(arrChecked[i])
                // check address 
                let check = await isAddress(arrChecked[i])
                if (!check) {

                    Store.addNotification(
                        addNotify(`Invalid Address at index ${(i / 2) + 1}`, "danger")
                    );
                    return (i / 2) + 1
                }

            } else {
                if (!Number(arrChecked[i])) {

                    Store.addNotification(
                        addNotify(`Invalid amount at index ${(i + 1) / 2}`, "danger")
                    );
                    return (i - (i % 2))
                } else {

                    arrListAmount.push(convertToWei(Number(arrChecked[i]), tokenDecimal))
                    // arrListAmount.push(Number(arrChecked[i]) * (1 * 10 ** tokenDecimal))
                    totalApp = totalApp + Number(arrChecked[i])
                }
            }
        }

        if (arrListAdd.length > 200) {
            Store.addNotification(
                addNotify(`Limit 200 addresses for 1 transfer`, "danger")
            );
            return
        }

        if (amountApprove < totalApp) {
            handleBack()
        }

        // console.log('arrListAmount', arrListAmount)
        setlistAdd(arrListAdd)
        setlistAmount(arrListAmount)
        setTotalTransfer(totalApp)
        settotalAddress(arrChecked.length / 2)

        setIsStep('2');
    }

    const handleBack = () => {
        setIsStep('1');
    }

    const getTokenInfoByAddress = async (tokenAddressVal) => {
        try {

            const tokenInfo = await getTokenInformation(tokenAbi, tokenAddressVal);
            const tokenBal = await getBalance(tokenAbi, tokenAddressVal, account)


            if (tokenInfo.symbol && tokenInfo.name && tokenInfo.decimals) {
                setBalance(Number(tokenBal) / (1 * 10 ** tokenInfo.decimals))
                setTokenSymbol(tokenInfo.symbol);
                setTokenName(tokenInfo.name);
                setTokenDecimal(tokenInfo.decimals);


            } else {
                setBalance(0)
                // setTokenAddress('');
                setTokenSymbol('');
                setTokenName('');
                setTokenDecimal(TOKEN_DECIMALS_MUTILSENDER[chainId])
            }
        } catch (e: any) {
            setBalance(0)
            // setTokenAddress('');
            setTokenSymbol('');
            setTokenName('');
            setTokenDecimal(TOKEN_DECIMALS_MUTILSENDER[chainId])
        }
    }

    useEffect(() => {
        if (chainId && tokenAddress !== '') {
            getTokenInfoByAddress(tokenAddress);
        }
        else {
            getTokenInfoByAddress(tokenAddress)
            setTokenDecimal(TOKEN_DECIMALS_MUTILSENDER[chainId])
        }
    }, [tokenAddress, account, chainId, isStep]);

    const fileReader = new FileReader();

    const csvFileToArray = string => {
        // console.log('string', string)
        const csvRows = string.slice(string.indexOf("\n") + 1);
        let csvRowsFormatTemp1 = csvRows.replaceAll('"', '')
        // let csvRowsFormatTemp = csvRowsFormatTemp1.replaceAll('\n', ' ')
        let csvRowsFormat = csvRowsFormatTemp1.replaceAll(',', ' ')
        // console.log('csvRowsFormat', csvRowsFormat)
        setArray(csvRowsFormat);
        return csvRowsFormat;
    };

    const handleOnChange = (e) => {
        if (e.target.files[0]) {
            fileReader.onload = function (event: any) {
                const csvOutput = event.target.result;

                csvFileToArray(csvOutput);

            };
            fileReader.readAsText(e.target.files[0]);
        }
    };

    const checkApprove = async () => {
        if (tokenAddress === '') {
            setisApprove(true)
            setAmountApprove(10 * 1e18)
        } else {
            await getAmountApprove(
                StandardTokenABI,
                tokenAddress,
                library,
                account,
                MULTI_SENDER[chainId]
            )
                .then((res) => {
                    //  console.log('res', res)
                    if (Number(res) > 0) {
                        setisApprove(true)
                    } else {
                        setisApprove(false)
                    }
                    setAmountApprove(Number(res) / (1 * 10 ** tokenDecimal));
                })
                .catch((err) => {
                    setisApprove(false)
                    setAmountApprove(0)
                    console.log("err", err);
                });
        }

    }

    useEffect(() => {
        checkApprove()
    }, [account, tokenAddress, isApprove])


    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleProcess = async () => {
        setisLoading(true)
        try {
            if (amountApprove < totalTransfer) {
                setisLoading(false)
                Store.addNotification(addNotify('The approval is not enough, please go Back to try again', "warning"));
                return
            }
            if (tokenBalance < totalTransfer) {
                setisLoading(false)
                Store.addNotification(addNotify('The balance is not enough', "warning"));
                return
            }
            if (tokenAddress === '') {
                await multiSender(multiContract, '0x0000000000000000000000000000000000000000', listAdd, listAmount, totalTransfer, tokenDecimal).then((res) => {
                    setHash(res.hash)
                    setSttHash('Pending')
                    res.wait().then((resTransaction) => {
                        // console.log('resTransaction', resTransaction)
                        if (
                            resTransaction &&
                            resTransaction.status === 1 &&
                            resTransaction.blockNumber
                        ) {
                            Store.addNotification(addNotify("Send successfully", "success"));
                            // setIsStep('1');
                            setisLoading(false)
                            setSttHash('Success')
                        } else {
                            setSttHash('Faill')
                            Store.addNotification(addNotify("Transaction failed", "warning"));
                            setisLoading(false)
                        }
                    })
                        .catch(() => {
                            setSttHash('Faill')
                            Store.addNotification(addNotify("Transaction failed", "warning"));
                            setisLoading(false)
                        })
                })
            } else {
                await multiSender(multiContract, tokenAddress, listAdd, listAmount, totalTransfer, tokenDecimal).then((res) => {
                    setHash(res.hash)
                    setSttHash('Pending')
                    res.wait().then((resTransaction) => {
                        // console.log('resTransaction', resTransaction)
                        if (
                            resTransaction &&
                            resTransaction.status === 1 &&
                            resTransaction.blockNumber
                        ) {
                            Store.addNotification(addNotify("Send successfully", "success"));
                            // setIsStep('1');
                            setisLoading(false)
                            setSttHash('Success')
                        } else {
                            setSttHash('Faill')
                            Store.addNotification(addNotify("Transaction failed", "warning"));
                            setisLoading(false)
                        }
                    })
                        .catch(() => {
                            setSttHash('Faill')
                            Store.addNotification(addNotify("Transaction failed", "warning"));
                            setisLoading(false)
                        })
                })
            }

        } catch (error: any) {
            setisLoading(false)
            Store.addNotification(addNotify(error?.data?.message || error?.code, "warning"));
            console.log('error', error)
        }
    }

    useEffect(() => {
        if (account) {

            w3.eth.getBalance(account, (err, balance) => {
                if (balance) {
                    let _balance = web3.utils.fromWei(web3.utils.toBN(balance), "ether");
                    let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
                    setBnbBalance(bnbBl);
                }
                if (chainId) {
                    setMainCurrency(MAIN_CURRENCIES[chainId]);
                }
                return 0;
            });
        }
    }, [account]);

    const [current, setCurrent] = useState(1);
    const onChange = (page) => {
        // console.log(page);
        setCurrent(page);
    };

    const gotoHash = (hash) => {
        if (chainId === 97) {
            window.open(`https://testnet.bscscan.com/tx/${hash}`)
        } else if (chainId === 421613) {
            window.open(`https://goerli.arbiscan.io/tx/${hash}`)
        } else if (chainId === 42161) {
            window.open(`https://arbiscan.io/tx/${hash}`)
        }
    }

    useEffect(() => {
        if (chainId && account && chainId !== 42161) {
            networkChange("0xa4b1");
            // setSearchParams({chain: chainId?.toString()});
        }
    }, [chainId, account]);

    const switchArb = async () => {
        await networkChange("0xa4b1");
    };

    const handleChangeArea = (e) => {
        setArray(e.target.value)
        let arrCheck1 = e.target.value.replaceAll('\n', ' ')
        let arrChecked = arrCheck1.split(' ')
        let totalApp = 0
        for (let i = 0; i < arrChecked.length; i++) {
            if (i % 2 !== 0) {
                totalApp = totalApp + Number(arrChecked[i])
            }
        }
        setTotalTransfer(totalApp)
    }

    return (
        <div className="p-multiSender">
            {isMobile ? ('') : (
                <>
                    <ul className="p-multiSender__head">
                        <li className={isStep === '1' ? 'is-active' : ''}>
                            <span>1</span>
                            <h3>
                                Add Your Allocation
                                <p>Enter your token to be send with allocations</p>
                            </h3>
                        </li>
                        <li className={isStep === '2' ? 'is-active' : ''}>
                            <span>2</span>
                            <h3>
                                Confirmation
                                <p>Let review your information</p>
                            </h3>
                        </li>
                    </ul>
                </>
            )}

            <div className="p-multiSender__boxContent">
                {isStep === '1' ? <>
                    <h3 className="c-ttl">Token Address</h3>
                    <InputChange value={tokenAddress} placeholder='Ex: 0x...' onChange={(e) => setTokenAddress(e.target.value)} />
                    <p className="p-multiSender__note is-blue">Blue Multi-sender allows you to send ERC20 token in batch by easiest way. Limit 200 addresses for 1 transfer. You can enter token address to send specific token or leave it blank to send chain token such as BNB....</p>

                    {tokenAddress !== '' ? <>
                        <p className="p-multiSender__infoToken is-mt16">Name<span className="is-blue">{tokenName}</span></p>
                        <p className="p-multiSender__infoToken">Symbol<span>{tokenSymbol}</span></p>
                        <p className="p-multiSender__infoToken">Decimal<span>{tokenDecimal}</span></p>
                        <p className="p-multiSender__infoToken">Balance<span className="is-blue">{formatNum(tokenBalance)}</span></p>
                    </> : ''}

                    <h3 className="c-ttl is-mt16">Allocations<small>*</small></h3>
                    <div className="c-input">
                        <label>
                            <textarea
                                onChange={(e) => handleChangeArea(e)}
                                // onChange={(e) => setArray(e.target.value)}
                                value={
                                    array
                                }
                                placeholder={'Insert allocation: separate with breaks line.\nBy format: address amount\n0x0000000000000000000000000000000000001000 13.45\n0x0000000000000000000000000000000000001000 1.049 '}
                            />
                        </label>
                    </div>
                    {array === '' ? (
                        <p className="p-multiSender__note is-red">Recipients allocation is required</p>
                    ) : ('')}

                    {isMobile ? (
                        <>
                            <div className="p-multiSender__sampleFile">
                                <div className="inputfile-box">
                                    <input type="file" id="file" accept={".csv"} className="inputfile" onChange={handleOnChange} />
                                    <label htmlFor="file">
                                        <span className="file-button mobie">
                                            <i className="fa fa-upload" aria-hidden="true"></i>
                                            Choose CSV file
                                        </span>

                                    </label>
                                </div>
                                <button
                                    className="modal-multi"
                                    onClick={showModal}
                                >Sample CSV file</button>
                                {/* <Button
                                    text='Sample CSV file' type='white'
                                    onClick={showModal}
                                /> */}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="p-multiSender__sampleFile">
                                <div className="inputfile-box">
                                    <input type="file" id="file" accept={".csv"} className="inputfile" onChange={handleOnChange} />
                                    <label htmlFor="file">
                                        <span className="file-button">
                                            <i className="fa fa-upload" aria-hidden="true"></i>
                                            Or choose from CSV file
                                        </span>

                                    </label>
                                </div>
                                <Button
                                    text='Sample CSV file' type='white'
                                    onClick={showModal}
                                />
                            </div>
                        </>
                    )}


                    {/* 
                    {array !== '' ? <>
                        <h3 className="c-ttl">Amount to approve</h3>
                        <div className="p-multiSender__approve">
                            <Radio.Group className="c-radioEdit" value={amountApprove} onChange={handleAmountApprove}>
                                <Radio value='Unlimited amount'>Unlimited amount</Radio>
                                <Radio value='Exact amount'>Exact amount(8.0708 T1)</Radio>
                            </Radio.Group>
                        </div>
                    </> : ''} */}
                    <p className="p-multiSender__note is-bg">Please exclude {MULTI_SENDER[chainId]}   from fees, rewards, max tx amount to start sending tokens.</p>
                </> : <>
                    <p className="p-multiSender__infoToken">Total address<span>{totalAddress}</span></p>
                    <p className="p-multiSender__infoToken">Total amount to send
                        <span className="is-blue2">{formatNum(totalTransfer)} {tokenSymbol || mainCurrency}</span>
                    </p>
                    <p className="p-multiSender__infoToken">Number of transaction<span>1</span></p>
                    <p className="p-multiSender__infoToken">Your token balance<span className="is-blue">{formatNum(tokenBalance) || formatNum(bnbBalance)} {tokenSymbol || mainCurrency}</span></p>
                    <p className="p-multiSender__infoToken">Token to send<span className="is-blue2">{tokenSymbol || mainCurrency}</span></p>
                    <p className="p-multiSender__infoAddress is-blue">Address<span>Amount</span></p>


                    {listAdd && listAdd.map((item: any, index: any) => (
                        <>
                            {(current * 10 - 10) <= (index) && (index) < (current * 10) ? (
                                <p className="p-multiSender__infoAddress">
                                    <div className="item-multi">
                                        {item}
                                    </div>
                                    <span >{listAmount[index] / (1 * 10 ** tokenDecimal)}</span>


                                </p>
                            ) : ('')}
                        </>
                    ))}
                    {listAdd.length < 10 ? ('') : (
                        <Pagination className="pagin-multi" current={current} onChange={onChange} total={listAdd.length} />
                    )}


                    {/* <h3 className="c-ttl is-mt16">Send option</h3>
                    <Radio.Group className="c-radioEdit" >
                        <Radio value='Safe mode'>Safe mode</Radio>
                        <Radio value='Exact amount'>Unsafe mode</Radio>
                    </Radio.Group> */}

                </>}

                {account ? (
                    <>
                        <div className="p-multiSender__btn">
                            {chainId !== 42161 ? (
                                <Button text='Switch ARB network' type='blue' onClick={switchArb} />
                            ) : (
                                <>
                                    {isStep === '1' ? (
                                        <>
                                            {isApprove && (amountApprove >= totalTransfer) ? (
                                                <Button
                                                    text='Next'
                                                    type={isApprove || array !== '' ? 'blue' : 'grey'}
                                                    disabled={array === '' || isLoading ? true : false}
                                                    onClick={() => handleNext()}


                                                />
                                            ) : (
                                                <div className="approve-more">
                                                    <Button
                                                        text={
                                                            isLoading ? (
                                                                <>
                                                                    Waiting <Spin className="spin-modal" indicator={antIcon} />
                                                                </>
                                                            ) : (
                                                                'Approve'
                                                            )
                                                        }
                                                        type={tokenAddress !== '' && array !== '' ? 'blue' : 'grey'}
                                                        disabled={array === '' || isLoading ? true : false}
                                                        onClick={() => handleApprove()}

                                                    />
                                                    <br />
                                                    {isApprove && (
                                                        <div className="noti-app">
                                                            <p className="p-multiSender__note is-red">
                                                                Aproved salary is not enough. Total transfer: {totalTransfer} {tokenSymbol || mainCurrency}. Your approved: {amountApprove} {tokenSymbol || mainCurrency}
                                                            </p>

                                                        </div>

                                                    )}
                                                </div>

                                            )}


                                        </>
                                    )
                                        :
                                        <>
                                            {sttHash === 'Success' ? ('') : (
                                                <>
                                                    <Button disabled={isLoading || sttHash === 'Success'} text='Back' type='greyBorder' onClick={() => handleBack()} />
                                                    <Button
                                                        text={
                                                            isLoading ? (
                                                                <>
                                                                    Waiting <Spin className="spin-modal" indicator={antIcon} />
                                                                </>
                                                            ) : ('Process')
                                                        }
                                                        type='blue'
                                                        onClick={() => handleProcess()}
                                                        disabled={isLoading || sttHash === 'Success'}
                                                    />
                                                </>
                                            )}

                                        </>
                                    }

                                </>
                            )}

                        </div>
                    </>
                ) : (
                    <div className="p-multiSender__btn">
                        <button style={{ 'padding': '0 40px' }} className="not-connect-redeem">Not connected</button>
                    </div>

                )}


                {isStep === '2' && sttHash !== '' && (
                    <>
                        <p className="p-multiSender__infoAddress is-blue">Transactions<span>Status</span></p>
                        <p className="p-multiSender__infoAddress">
                            <div className="multi-hash" onClick={() => gotoHash(hash)}>{hash}</div>

                            <span>
                                {sttHash === 'Pending' ? (
                                    <span className="is-pending">{sttHash}</span>
                                ) : (
                                    <>
                                        {sttHash === 'Success' ? (
                                            <span className="is-Success">{sttHash}</span>
                                        ) : (
                                            <span className="is-fail">{sttHash}</span>
                                        )}
                                    </>

                                )}


                            </span>
                        </p>
                    </>
                )}

            </div>
            <p className="p-multiSender__footer">Disclaimer: The information provided shall not in any way constitute a recommendation as to whether you should invest in any product discussed. We accept no liability for any loss occasioned to any person acting or refraining from action as a result of any material provided or published.</p>
            <Modal className="modal-sample" footer={false} title="Sample CSV file" open={isModalOpen} onCancel={handleCancel}>
                <div className="line-csv">
                    <p>0x0000000000000000000000000000000000001000 13.45</p>
                    <p>0x0000000000000000000000000000000000001000 13.45</p>
                    <p>0x0000000000000000000000000000000000001000 13.45</p>
                </div>


            </Modal>
        </div>
    );
}

export default MultiSender;