import { Link, useNavigate } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import { Button } from "../../components/component";
import { useLaunchpadContract, usePrivateSaleContract } from "../../hooks/useContract";
import { useEffect, useState } from "react";
import { getInfoLaunchpad } from "../../contracts/launchpad";
import { useActiveWeb3React } from "../../hooks";
import { CURRENCIES_DECIMALS } from "../../constants";
import { formatNumber } from "../../utils/formatNumber";
import { getRaisedAmountPrivateSale, _investInfos, privateSaleGetState } from "../../contracts/privateSaleUltil";
import { ethers } from "ethers";
import { getCurrencyDecimals } from "../../components/createlaunchpad/ultils";

type ItemProps = {
  data?: any | undefined;
};

const ItemListPrivate: React.FC<ItemProps> = (props: ItemProps) => {
  const { data } = props;
  const nowTimer = new Date();
  const startTimer = new Date(data?.startTime);
  const endTimer = new Date(data?.endTime);
  const [raisedAmount, setRaisedAmount] = useState<any>();
  const [totalInvestment, setTotalInvestment] = useState(0);

  const launchpadContract = usePrivateSaleContract(data?.presaleAddress || data?.rounds[0]?.presaleAddress
  );

  const { account, chainId } = useActiveWeb3React();
  const [launchpadState, setLaunchpadState] = useState<number>(-1);
  // console.log("launchpadContract", launchpadContract)
  let navigate = useNavigate();
  const currencyDecimals = getCurrencyDecimals(
    chainId,
    data?.currency
  );

  const getStatePrivateSale = async () => {
    try {
      const stateSC = await privateSaleGetState(launchpadContract, account);
      setLaunchpadState(parseInt(stateSC));
    } catch (e) {
      setLaunchpadState(0)
      console.log(e);
    }
  };

  useEffect(() => {
    if (data?.rounds && data?.rounds[0]?.presaleAddress) {
      if (account && launchpadContract && chainId?.toString() === data?.rounds[0]?.chainId.toString()) {
        getStatePrivateSale();
      }
    } else {
      if (account && launchpadContract && chainId?.toString() === data?.chainId?.toString()) {
        getStatePrivateSale();
      }
    }
  }, [launchpadContract, account, totalInvestment, chainId]);

  useEffect(() => {
    if (data?.rounds && data?.rounds[0]?.presaleAddress) {
      if (launchpadContract && account && chainId?.toString() === data?.rounds[0]?.chainId.toString()) {
        (async () => {
          await getRaisedAmountPrivateSale(launchpadContract, account)
            .then((res: any) => {
              setRaisedAmount(
                parseFloat(
                  ethers.utils.formatUnits(res, parseInt(currencyDecimals))
                )
              );
            })
            .catch((err => console.log(err)));

          await _investInfos(launchpadContract, account).then((res) => {
            // console.log("Number(res.totalInvestment)", Number(res.totalInvestment))
            setTotalInvestment(Number(res.totalInvestment) / (1 * 10 ** currencyDecimals))
          })
            .catch((err => console.log(err)));
        })();
      }
    } else {
      if (launchpadContract && account && chainId?.toString() === data?.chainId.toString()) {
        (async () => {
          await getRaisedAmountPrivateSale(launchpadContract, account)
            .then((res: any) => {
              setRaisedAmount(
                parseFloat(
                  ethers.utils.formatUnits(res, parseInt(currencyDecimals))
                )
              );
            })
            .catch((err => console.log(err)));

          await _investInfos(launchpadContract, account).then((res) => {
            // console.log("Number(res.totalInvestment)", Number(res.totalInvestment))
            setTotalInvestment(Number(res.totalInvestment) / (1 * 10 ** currencyDecimals))
          })
            .catch((err => console.log(err)));
        })();
      }
    }

  }, [launchpadContract, account, chainId]);

  const rendererTimer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Done !</span>;
    } else {
      return (
        <div className="c-countDown">
          <span>{zeroPad(days)}d</span>:<span>{zeroPad(hours)}h</span>:
          <span>{zeroPad(minutes)}m</span>:<span>{zeroPad(seconds)}s</span>
        </div>
      );
    }
  };

  const handleClassStatus = () => {
    let classStatus: string = "";

    if (nowTimer < startTimer) {
      classStatus = "is-Upcoming";
    } else if (startTimer < nowTimer && nowTimer < endTimer) {
      classStatus = "is-Opening";
    } else if (endTimer < nowTimer) {
      classStatus = "is-Completed";
    }

    return classStatus;
  };

  // console.log('launchpadState', launchpadState)
  const handleStatus = () => {
    let txtStatus: any = "";

    if (launchpadState === 3) {
      txtStatus = (
        <>
          Completed
          <span className="is-Finished">Canceled</span>
        </>
      );
    } else {
      if (nowTimer < startTimer) {
        txtStatus = (
          <>
            Upcoming:
            <Countdown date={startTimer.getTime()} renderer={rendererTimer} />
          </>
        );
      } else if (startTimer < nowTimer && nowTimer < endTimer) {
        txtStatus = (
          <>
            Opening:
            <Countdown date={endTimer.getTime()} renderer={rendererTimer} />
          </>
        );
      } else if (endTimer < nowTimer) {
        txtStatus = (
          <>
            Completed
            <span className="is-Finished">Finished</span>
          </>
        );
      }
    }
    return txtStatus;
  };


  const handleButton = () => {
    let elmButton: any = "";
    if (nowTimer < startTimer) {
      elmButton = "";
    } else if (startTimer < nowTimer && nowTimer < endTimer) {
      elmButton = <Button text="Buy Now" type="blue" onClick={() => navigate(`/private-sale/${data?.handle}`)} />;
    } else if (endTimer < nowTimer) {
      elmButton = <Button text={`Bought (${totalInvestment} ${data?.currency})`} type="blue" disabled={true} />;
    }
    return elmButton;
  };
  const progressPercent = raisedAmount && raisedAmount > 0 && data?.hardCap && Number(data?.hardCap) !== 0 ? formatNumber(Number(raisedAmount) / parseFloat(data?.hardCap) * 100) : 0;


  return (
    <div className={`c-list__item is-private ${handleClassStatus()}`}>
      <Link className="c-list__detail" to={`/private-sale/${data?.handle}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.5684 7.26562V3.76562H16.5684V7.76562C16.5684 8.59405 17.2399 9.26562 18.0684 9.26562H22.0684V7.26562H18.5684ZM7.56836 7.76562V3.76562H5.56836V7.26562H2.06836V9.26562H6.06836C6.89679 9.26562 7.56836 8.59405 7.56836 7.76562ZM22.0684 16.2656H18.0684C17.2399 16.2656 16.5684 16.9372 16.5684 17.7656V21.7656H18.5684V18.2656H22.0684V16.2656ZM5.56836 18.2656H2.06836V16.2656H6.06836C6.89679 16.2656 7.56836 16.9372 7.56836 17.7656V21.7656H5.56836V18.2656Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.0684 3.26562H16.0684V7.76562C16.0684 8.87019 16.9638 9.76562 18.0684 9.76562H22.5684V6.76562H19.0684V3.26562ZM18.0684 4.26562V7.76562H21.5684V8.76562H18.0684C17.5161 8.76562 17.0684 8.31791 17.0684 7.76562V4.26562H18.0684ZM8.06836 3.26562H5.06836V6.76562H1.56836V9.76562H6.06836C7.17293 9.76562 8.06836 8.87019 8.06836 7.76562V3.26562ZM7.06836 4.26562V7.76562C7.06836 8.31791 6.62064 8.76562 6.06836 8.76562H2.56836V7.76562H6.06836V4.26562H7.06836ZM22.5684 15.7656H18.0684C16.9638 15.7656 16.0684 16.6611 16.0684 17.7656V22.2656H19.0684V18.7656H22.5684V15.7656ZM21.5684 16.7656V17.7656H18.0684V21.2656H17.0684V17.7656C17.0684 17.2133 17.5161 16.7656 18.0684 16.7656H21.5684ZM8.06836 17.7656C8.06836 16.6611 7.17293 15.7656 6.06836 15.7656H1.56836V18.7656H5.06836V22.2656H8.06836V17.7656ZM6.06836 16.7656C6.62064 16.7656 7.06836 17.2133 7.06836 17.7656V21.2656H6.06836V17.7656H2.56836V16.7656H6.06836Z"
          />
        </svg>
      </Link>
      <div className="c-list__head">
        <h3>
          <Link to="">{data?.name}</Link>
          {/* <span>The first release will receive 50%</span> */}
        </h3>
        <p>
          <span>Soft/Hard</span>
          <span>
            {data?.softCap} {data?.currency} - {data?.hardCap} {data?.currency}
          </span>
        </p>
      </div>
      <Link className="c-list__img" to="">
        {/* <img src="../images/img01.jpg" alt="" /> */}
        <img src={data?.bannerUrl || '../images/bgDefault.svg'} alt="" />
      </Link>
      <div className="c-list__logo">
        <img src={data?.logoUrl || '../images/logoDefault.svg'} alt="" />
        <p>
          {data?.kyc && <span>KYC</span>}
          {data?.doxx && <span>DOXX</span>}
        </p>
      </div>
      <div className="c-progress">
        <p className="c-progress__title">Progress ({progressPercent}%)</p>
        <div className="c-progress__bar">
          <span style={{ width: progressPercent ? progressPercent : 0 + "%" }}></span>
        </div>
        <p className="c-progress__txt">
          {raisedAmount ? raisedAmount : '0'} {data?.currency}
          <span>{data?.hardCap} {data?.currency}</span>
        </p>
      </div>
      <div className="c-list__info">
        <div className="c-list__text">{handleStatus()}</div>
        <div className="c-list__text is-btn">{handleButton()}</div>
      </div>
    </div>
  );
};

export default ItemListPrivate;
