import web3 from 'web3';
import { ethers } from 'ethers';
import { getContract } from '../../utils';
import { convertToWei } from '../../contracts/utils';

export const UINT256_MAX = '115792089237316195423570985008687907853269984665640564039457.584007913129639935';
export const convertWeiToToken = (value, decimal) => {
	return value.toString() / (10 ** decimal)
}

export const approveToken = async (tokenSCAbi, tokenAddressVal, library, account, spender) => {
	const args = [spender, web3.utils.toBN(2).pow(web3.utils.toBN(256)).sub(web3.utils.toBN(1)).toString()];
	const contract = getContract(tokenAddressVal, tokenSCAbi, library, account);

	const transaction = await contract.approve(...args);
	return transaction;
};

export const getTokenAllowance = async (tokenSCAbi, tokenAddressVal, account, spender) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		const allowance = await contract.allowance(account, spender);
		return allowance;
	}
};
export const _getSymbolToken = async (tokenContract) => {
	const symbol = tokenContract && await tokenContract.symbol();
	return symbol;
};

export const _getDecimalToken = async (tokenContract) => {
	const decimal = tokenContract && await tokenContract.decimals();
	return decimal;
};

export const _tokenBalance = async (tokenContract, account) => {
	const res = tokenContract && await tokenContract.balanceOf(account);
	return res;
}


export const _claimTokensPublicSale = async (claimContract, claimItem, roundDetail) => { 
 
  const amountTemp = convertToWei(claimItem.tokenAmount, 18);  
  const args = [amountTemp, roundDetail.id, claimItem.signClaim];  

  const gas = await claimContract.estimateGas.claimTokens(...args);
  return claimContract.claimTokens(...args, { gasLimit: gas });
}

export const _isClaimedSC = async (claimContract, account) => {
  return claimContract && await claimContract.isClaimed(account);
}
export const _totalClaimed = async (claimContract, account) => {
  return claimContract && await claimContract.totalClaimed(account);
}
