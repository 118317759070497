
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, useParams, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Button, Form, Steps, Alert, Spin } from 'antd';
import web3 from 'web3';
import { ethers } from 'ethers';
import { useActiveWeb3React } from '../../../hooks';
import AirdropAdditionInfo from './airdrop-addition-info';
import { Store } from 'react-notifications-component';
import addNotify from '../../../components/commons/Notify/addNotify';
import AirdropService from '../../../redux/services/AirdropService';

const { Content } = Layout;
const { Step } = Steps;

declare const window: Window & typeof globalThis & { ethereum: any };

const AirdropUpdate = () => {
    const { chainId, account } = useActiveWeb3React();
    const param = useParams();
	let handle: any = param?.handle;

    const [loading, setLoading] = useState<any>(false);
    const [authToken, setAuthToken] = useState<any>();
    const [dataAirdrop, setDataAirdrop] = useState<any>();
    const [form] = Form.useForm();
    const navigate = useNavigate()
    let initStep = 0;

    const [current, setCurrent] = React.useState(initStep);

    const getAuthToken = async (account) => {
        await AirdropService.getAuthToken(account)
        .then((res)=>{
            setAuthToken(res.data.id_token);
        })
        .catch((error:any)=>{});
    }

    const getAirDrop = async (_handle) => {
		setLoading(true);

		try {
			let res = await AirdropService.getAirdropDetail(_handle);
			if (res.status === 200) {
				setDataAirdrop(res.data);
			}
			setLoading(false);
		} catch (error) {
			console.log('Error: ', error);
		} finally {
			setLoading(false);
		}
	};

    useEffect(() => {
		if (handle) {
			getAirDrop(handle);
		}
	}, [handle]);

    useEffect(() => {
        if(account){
            getAuthToken(account);
        }
    }, [account]);

    const steps = [
        {
            title: 'Add Addition Info',
            content: <AirdropAdditionInfo form={form} dataAirdrop={dataAirdrop}/>,
            description: ''
        }
    ];

    const handleCancel = () => {
        navigate(`/airdrop/detail/${dataAirdrop?.handle}`);
    }

    const confirmUpdate = async (value:any) => {
        console.log('value', value);
      
        setLoading(true);
        try {                       
            const payload = {
                "id": dataAirdrop?.id,
                "airdropName": value?.name,
                "airdropInfo": value?.info,
                "bannerUrl": value?.banner,
                "socialNetwork": {
                    "facebook": value?.facebook,
                    "twitter": value?.twitter,
                    "medium": value?.medium,
                    "discord": value?.discord,
                    "github": value?.github,
                    "telegram": value?.telegram,
                    "instagram": value?.instagram,
                    "reddit": value?.reddit,
                    "website": value?.website,
                    "youtube": value?.youtube,
                }
            }

            console.log('payload', payload);
            
            await AirdropService.updateAirDrop(dataAirdrop?.id, payload, authToken).then((res:any)=>{
                if (res && res.status == 200 || res.status == 201) {
                    
                    setLoading(false);
                    Store.addNotification(addNotify('Create Airdrop Success', 'success'));
                    navigate(`/airdrop/detail/${res.data.handle}`);
                }
            }).catch((error:any)=>{
                setLoading(false);
                Store.addNotification(addNotify(error.message, 'danger'));
            });
                               
        
        } catch (error:any) {
            console.log('Error: ', error);
            Store.addNotification(addNotify(error.message, 'error'));
        }
    };
    

    return (
        <>
            <div className="p-airdropCreate">    
               
                <Form form={form} layout="vertical" autoComplete="off" onFinish={confirmUpdate}>
                    <div className="airdrop-create-content">
                        {steps[current]?.content}
                    </div>
                    
                    
                    <div className="airdrop-create-action">
                        <Button className="c-btn c-btn--greyBorder btn-p50" htmlType="button" onClick={()=>handleCancel()} disabled={loading}>
                            Cancel
                        </Button>
  
                        <button type="submit" disabled={!account || loading} className="c-btn ml-15 btn-p50">
                            {account ? 'Update' : 'Connect Wallet'} 
                            {loading && (
                                <Spin className="style-loading" size="small" />
                            )}
                        </button>
                       
                    </div>
                </Form> 
            </div>
        </>
    )
}

export default AirdropUpdate
