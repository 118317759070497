import React, { useRef, useEffect, useState } from 'react';
import { InputChange, Button, Loading } from '../../components/component';
import {Form, Select} from 'antd'; 
import { Plus } from '../../components/assets/icon';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getLaunchpadDetail, editLaunchpadDetail, launchPadSelector } from "../../redux/slicers/launchpadSlice";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Tokens } from '../../constants';

const LaunchPadEdit: React.FC = () => {
    const [imgBanner, setImgBanner] = useState("");
    const [bannerThumbnail, setBannerThumbnail] = useState(""); 
    const [imgLogo, setLogo] = useState(""); 
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [form] = Form.useForm();
    const { Option } = Select;
    const dispatch = useAppDispatch();
    const { handle } = useParams<{ handle: any }>();
    const navigate = useNavigate();
    const inputImage = useRef<HTMLInputElement>(null);
    const inputbannerThumbnail = useRef<HTMLInputElement>(null);
    const inputLogo = useRef<HTMLInputElement>(null);
    const { launchpadDetail, launchpadLoading, launchpadErrors } = useAppSelector(launchPadSelector);

    useEffect(() => {
        if(handle) {
            dispatch(getLaunchpadDetail(handle));
        }
    }, [dispatch, handle]);

    useEffect(() => {
        if(launchpadDetail) {
            form.setFieldsValue(launchpadDetail);
            form.setFieldsValue({  
                launchpadInfo: launchpadDetail?.info,
                launchpadName: launchpadDetail?.name,
                // banner:additionInfoStorage?.banner,
                // logo: additionInfoStorage?.logo,
                website: launchpadDetail?.socialNetwork?.website,
                twitter: launchpadDetail?.socialNetwork?.twitter,
                telegram: launchpadDetail?.socialNetwork?.telegram,
                medium: launchpadDetail?.socialNetwork?.medium,
                youtube: launchpadDetail?.socialNetwork?.youtube,
                discord: launchpadDetail?.socialNetwork?.discord,
                facebook: launchpadDetail?.socialNetwork?.facebook,
            });
            setImgBanner(launchpadDetail?.bannerUrl);
            setLogo(launchpadDetail?.logoUrl);
            setBannerThumbnail(launchpadDetail?.bannerThumbnail);
        }
    }, [launchpadDetail]);

    const onChangeImage = (e, value) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.AUTHUSER)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            if(value == "banner"){
                setImgBanner(res.data);
            } else if(value == "logo"){
                setLogo(res.data)
            } else if(value == "bannerThumbnail"){
                setBannerThumbnail(res.data)
            }
        }).catch(err => {
            console.log(err);
        });
    }

    console.log('launchpadDetail',launchpadDetail);

    const onFinish = (values: any) => {
        setIsLoader(true);
        values.handle = handle;
        values.launchpadName = values.launchpadName ? values.launchpadName : null;
        values.launchpadInfo = values.launchpadInfo ? values.launchpadInfo : null;
        values.bannerUrl = imgBanner;
        values.bannerThumbnail = bannerThumbnail;
        values.logoUrl = imgLogo;
        values.facebook = values.facebook ? values.facebook : null;
        values.twitter = values.twitter ? values.twitter : null;
        values.medium = values.medium ? values.medium : null;
        values.discord = values.discord ? values.discord : null;
        values.github = values.github ? values.github : null;
        values.telegram = values.telegram ? values.telegram : null;
        values.instagram = values.instagram ? values.instagram : null;
        values.reddit = values.reddit ? values.reddit : null;
        values.website = values.website ? values.website : null;

        dispatch(editLaunchpadDetail(values));

        setTimeout(() => {
            setIsLoader(false);
            if(launchpadDetail?.type === 'ICO') {
                navigate(`/launchpad/${handle}`);
            } else if(launchpadDetail?.type === 'FAIR') {
                navigate(`/fairlaunch/${handle}`);
            }
        }, 3000);
    };

    const handleCancel = () => {
        if(launchpadDetail?.type === 'ICO') {
            navigate(`/launchpad/${handle}`);
        } else if(launchpadDetail?.type === 'FAIR') {
            navigate(`/fairlaunch/${handle}`);
        }
    }

    return(
        <>
        {launchpadLoading && <Loading />}
        {isLoader && <Loading />}
        <div className="p-launchpadEdit">
            <h3>Edit {launchpadDetail?.name}</h3>
            <Form name="basic" autoComplete="off" form={form} onFinish={onFinish}>
                <div className="p-launchpadEdit__box">                
                    <div className="p-launchpadEdit__images">
                        <p className="c-ttl">Upload Banner</p>
                        <Form.Item name="bannerUrl">
                            <div className="c-upBanner">
                                <img src={imgBanner} alt="" />
                                <div className="c-upBanner__edit">
                                    <span onClick={()=>inputImage.current?.click()}><img src="../images/icon-editBanner.png" alt="" /></span>
                                </div>
                                <input ref={inputImage} onChange={(e) =>onChangeImage(e,"banner")} type="file" /> 
                            </div>
                        </Form.Item>
                        <p className="c-ttl">Upload Banner Thumbnail</p>
                        <Form.Item name="bannerThumbnail">
                            <div className="c-upBanner is-thumbnail">
                                <img src={bannerThumbnail} alt="" />
                                <div className="c-upBanner__edit">
                                    <span onClick={()=>inputbannerThumbnail.current?.click()}><img src="../images/icon-editBanner.png" alt="" /></span>
                                </div>
                                <input ref={inputbannerThumbnail} onChange={(e) =>onChangeImage(e,"bannerThumbnail")} type="file" /> 
                            </div>
                        </Form.Item>
                        <p className="c-ttl">Upload Logo</p>
                        <Form.Item name="logoUrl">
                            <div className="c-upLogo">
                                <div className="c-upLogo__img">
                                    <img src={imgLogo} alt="" />
                                </div>
                                <div className="c-upLogo__edit" onClick={()=>inputLogo.current?.click()}>
                                    <span><img src="../images/icon-editBanner.png" alt="" /></span>
                                </div>
                                <input ref={inputLogo} onChange={(e) =>onChangeImage(e,"logo")} type="file" />
                            </div>
                        </Form.Item>
                    </div>
                    <div className="p-launchpadEdit__info">
                        <Form.Item name="launchpadName"> 
                            <InputChange title='LaunchPad’s Name' />
                        </Form.Item>                        
                        <div className="c-input">
                            <p className="c-input__title">LaunchPad’s Info</p>
                            <Form.Item name="launchpadInfo"> 
                                <textarea></textarea>
                            </Form.Item>
                        </div>
                        <div className="c-social">
                            <p className="c-ttl">Channel Media</p>
                            <div className="c-social__head">
                                <p>Channel name</p>
                                <p>URL <span>(Required)</span></p>
                            </div>
                            <div className="c-social__row">
                                <span><img src="../images/icon-editWebsite.svg" alt="" />Website</span>
                                <Form.Item name="website"> 
                                    <InputChange placeholder="Enter URL" />
                                </Form.Item>
                            </div>
                            <div className="c-social__row">
                                <span><img src="../images/icon-editTwitter.svg" alt="" />Twitter</span>
                                <Form.Item name="twitter"> 
                                    <InputChange placeholder="Enter URL" />
                                </Form.Item>
                            </div>
                            <div className="c-social__row">
                                <span><img src="../images/icon-editTelegram.svg" alt="" />Telegram</span>
                                <Form.Item name="telegram"> 
                                    <InputChange placeholder="Enter URL" />
                                </Form.Item>
                            </div>
                            <div className="c-social__row">
                                <span><img src="../images/icon-editMedium.svg" alt="" />Medium</span>
                                <Form.Item name="medium"> 
                                    <InputChange placeholder="Enter URL" />
                                </Form.Item>
                            </div>
                            <div className="c-social__row">
                                <span><img src="../images/icon-editYoutube.svg" alt="" />Youtube</span>
                                <Form.Item name="youtube"> 
                                    <InputChange placeholder="Enter URL" />
                                </Form.Item>
                            </div>
                            <div className="c-social__row">
                                <span><img src="../images/icon-editDiscord.svg" alt="" />Discord</span>
                                <Form.Item name="discord"> 
                                    <InputChange placeholder="Enter URL" />
                                </Form.Item>
                            </div>
                            <div className="c-social__row">
                                <span><img src="../images/icon-editFacebook.svg" alt="" />Facebook</span>
                                <Form.Item name="facebook"> 
                                    <InputChange placeholder="Enter URL" />
                                </Form.Item>
                            </div>
                        </div>
                    </div>                
                </div>
                <div className="p-launchpadEdit__btn">
                    <div className="c-btn c-btn--greyBorder" onClick={() => handleCancel()}><span><span>Cancel</span></span></div>
                    <Button text='Update' />
                </div>
            </Form>
        </div>
        </>
    );
}

export default LaunchPadEdit;