import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import useWindowSize from '../../hooks/useWindowSize';
import Countdown, { zeroPad } from 'react-countdown';
import { formatNumber } from '../../utils/formatNumber';
import { useFairlaunchContract, useLaunchpadContract } from '../../hooks/useContract';
import { useActiveWeb3React } from '../../hooks';
import { getInfoLaunchpad } from '../../contracts/launchpad';
import { useAppDispatch } from '../../redux/store';
import { getTokenInformByAddress } from '../../redux/slicers/tokenInformSlice';
import { CHAINID_CONVERT } from '../../constants';
import { getLaunchpadInfo } from '../../contracts/fairlaunch';

interface Size {
    width: number;
    height: number;
}

type ItemProps = {
    item?: any | undefined;
    display?: boolean;
}

const ItemLaunchpad: React.FC<ItemProps> = (props: ItemProps) => {
    const { account, chainId, library } = useActiveWeb3React();
    const [isShow, setIsShow] = useState<boolean>(false);
    const size: Size = useWindowSize();
    const dataItem = props.item;
    const rounds = dataItem?.rounds[0];   
    const nowTimer = new Date();
    const startTimer = new Date(dataItem?.startTime);
    const endTimer = new Date(dataItem?.endTime);
    const [raisedAmount, setRaisedAmount] = useState<any>(); 
    const launchpadContract = useLaunchpadContract(rounds?.presaleAddress);  
    const fairlaunchContract = useFairlaunchContract(rounds?.presaleAddress);
    const dispatch = useAppDispatch(); 
    const [progressPercent, setProgressPercent] = useState<any>(0);
    const [progressCap, setProgressCap] = useState<any>(0);
    const [swapRate, setSwapRate] = useState<any>(0);
    const [listingRate, setListingRate] = useState<any>(0);
    

    useEffect(()=> {
        if(dataItem?.type === "ICO"){
            if(launchpadContract && account && chainId === rounds?.chainId) {
                dispatch(getTokenInformByAddress(rounds?.tokenAddress, ((res:any) => {  
                    (async () => {
                        await getInfoLaunchpad(launchpadContract, res?.decimals, rounds?.currencyPay)
                        .then((res:any) => {   
                            setRaisedAmount(res?.globalTotalInvestment);  
                        });
                    })();   
                }))); 
            }
            setProgressPercent(raisedAmount && raisedAmount > 0 && dataItem?.hardCap && Number(dataItem?.hardCap) !== 0 ? Number(raisedAmount) / parseFloat(dataItem?.hardCap)*100 : 0);
            setProgressCap(dataItem?.hardCap);
            setSwapRate(rounds?.swapRate);
            setListingRate(rounds?.listingRate);
        }
    }, [launchpadContract, chainId, raisedAmount, dataItem?.hardCap, rounds?.swapRate]);  

    useEffect(()=> {
        if (dataItem?.type === "FAIR"){
            if(fairlaunchContract && account && chainId === rounds?.chainId) {
                dispatch(getTokenInformByAddress(rounds?.tokenAddress, ((res:any) => {  
                    (async () => {
                        await getLaunchpadInfo(fairlaunchContract, res?.decimals, rounds?.currencyPay)
                        .then((res:any) => { 
                            setRaisedAmount(res?.raisedAmount);  
                            setSwapRate(Number(res?.totalIcoToken/res?.softCap));
                            setListingRate(Number(res?.totalIcoToken/res?.softCap));
                        });
                    })();   
                }))); 
            }
            setProgressPercent(raisedAmount && raisedAmount > 0 && dataItem?.softCap && Number(dataItem?.softCap) !== 0 ? Number(raisedAmount) / parseFloat(dataItem?.softCap)*100 : 0);
            setProgressCap(dataItem?.softCap);
        }
        
    }, [fairlaunchContract, chainId, raisedAmount, dataItem?.softCap]);  
    
    const imgNetwork = (value: any) => {
        let img = '';
        if(value === 'BSCTESTNET') {
            img = 'icon-bnb.svg';
        } else if(value === 'BSC') {
            img = 'icon-bnb.svg';
        } else if(value === 'ETH') {
            img = 'icon-eth.svg';
        } else if(value === 'POLY') {
            img = 'icon-polygon.svg';
        } else if(value === 'ARBTESTNET') {
            img = 'arb-icon.svg';
        } else if(value === 'ARB') {
            img = 'arb-icon.svg';;
        }
        return img;
    }

    const rendererTimer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return <span>Done !</span>;
        } else {
          // Render a countdown
          return <div className="c-countDown"><span>{zeroPad(days)}d</span>:<span>{zeroPad(hours)}h</span>:<span>{zeroPad(minutes)}m</span>:<span>{zeroPad(seconds)}s</span></div>;
        }
    };

    const handleClassStatus = () => {
        let classStatus:string = '';

        if(nowTimer < startTimer) {
            classStatus= 'is-Upcoming';
        } else if(startTimer < nowTimer && nowTimer < endTimer) {
            classStatus= 'is-Opening';
        } else if(endTimer < nowTimer) {
            classStatus= 'is-Completed';
        }

        return classStatus;
    }

    const handleStatus = () => {
        let txtStatus:any = '';

        if(nowTimer < startTimer) {
            txtStatus= <>
                Upcoming:
                <Countdown date={startTimer.getTime()} renderer={rendererTimer} />
            </>;
        } else if(startTimer < nowTimer && nowTimer < endTimer) {
            txtStatus= <>
                Opening:
                <Countdown date={endTimer.getTime()} renderer={rendererTimer} />
            </>;
        } else if(endTimer < nowTimer) {
            txtStatus= <>
                Completed
                <span className="is-Finished">Finished</span>
            </>;
        }

        return txtStatus;
    }

    const handleNetwork = (network:any) => {
        let networkName:any = '';
        if(network === 'BSCTESTNET') {
            networkName = <span className="is-network"><img src="../images/icon-bnb.svg" alt="" />BSC TESTNET</span>;
        } else if(network === 'BSC') {
            networkName = <span className="is-network"><img src="../images/icon-bnb.svg" alt="" />Binance Smart Chain</span>;
        } else if(network === 'ETH') {
            networkName = <span className="is-network"><img src="../images/icon-eth.svg" alt="" />Ethereum</span>;
        } else if(network === 'POLY') {
            networkName = <span className="is-network"><img src="../images/icon-polygon.svg" alt="" />Polygon</span>;
        } else if(network === 'ARBTESTNET') {
            networkName = <span className="is-network"><img src="../images/arb-icon.svg" alt="" />ARB TESTNET</span>;
        } else if(network === 'ARB') {
            networkName = <span className="is-network"><img src="../images/arb-icon.svg" alt="" />Arbitrum</span>;
        }

        return networkName;
    }   

    if(props.display === true) {
        return(
            <div className={`c-list__itemDisplay ${isShow ? 'is-open' : ''}`}>
                <p className={`c-list__rowDisplay ${size.width >= 991 ? '' : 'is-full'}`}>
                    <span className={`c-list__more ${isShow ? 'is-open' : ''}`} onClick={() => setIsShow(!isShow)}></span>
                    {dataItem?.name}
                </p>                
                {size.width >= 991 ? <>
                    <p className="c-list__rowDisplay">{dataItem?.type ? dataItem?.type : '--'}</p>
                    <p className="c-list__rowDisplay">{dataItem?.tokenInform?.symbol}</p>
                    <p className="c-list__rowDisplay">{handleNetwork(dataItem?.tokenInform?.networkChain)}</p>
                    <p className="c-list__rowDisplay">${formatNumber((1/listingRate)*dataItem?.tokenInform?.totalSupply)}</p>
                    <div className="c-list__rowDisplay">
                        <div className="c-list__progress">
                            <span>{raisedAmount ? raisedAmount : '0'} | {progressCap}</span>
                            <span style={{width: progressPercent +'%'}}></span>
                        </div>
                    </div>
                    <p className="c-list__rowDisplay">{rounds?.roundStatus}</p>
                    <p className="c-list__rowDisplay">
                        {dataItem?.type === "ICO" ?
                        <Link to={account ? `/launchpad/${dataItem?.handle}?refID=${account}` : `/launchpad/${dataItem?.handle}`} >View</Link> :
                        <Link to={`/fairlaunch/${dataItem?.handle}?refID=${account}`}>View</Link>}
                        </p>
                </> : <div className={`c-list__box ${isShow ? 'is-open' : ''}`}>
                    <p><span>HC:</span>{dataItem?.type ? dataItem?.type : '--'}</p>
                    <p><span>Token:</span>{dataItem?.tokenInform?.symbol}</p>
                    <p><span>Network:</span>{handleNetwork(dataItem?.tokenInform?.networkChain)}</p>
                    <p><span>Initial Cap:</span>{dataItem?.listingOptions === 'MANUAL' ? '--' : '$'+formatNumber((1/listingRate)*dataItem?.tokenInform?.totalSupply)}</p>
                    <p><span>Progress:</span>{raisedAmount ? raisedAmount : '0'} | {progressCap}</p>
                    <p><span>Status:</span>{rounds?.roundStatus}</p>
                    <p>{dataItem?.type === "ICO" ?
                        <Link to={account ? `/launchpad/${dataItem?.handle}?refID=${account}` : `/launchpad/${dataItem?.handle}`}>View</Link> :
                        <Link to={`/fairlaunch/${dataItem?.handle}?refID=${account}`}>View</Link>}</p>
                </div>}
            </div>
        )
    }
    
    return(
        <> 
         <div className={`c-list__item ${handleClassStatus()}`}>
            <Link className="c-list__detail" to={dataItem?.type === "ICO" ? 
            account ? `/launchpad/${dataItem?.handle}?refID=${account}` : `/launchpad/${dataItem?.handle}`
             : `/fairlaunch/${dataItem?.handle}?refID=${account}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M18.5684 7.26562V3.76562H16.5684V7.76562C16.5684 8.59405 17.2399 9.26562 18.0684 9.26562H22.0684V7.26562H18.5684ZM7.56836 7.76562V3.76562H5.56836V7.26562H2.06836V9.26562H6.06836C6.89679 9.26562 7.56836 8.59405 7.56836 7.76562ZM22.0684 16.2656H18.0684C17.2399 16.2656 16.5684 16.9372 16.5684 17.7656V21.7656H18.5684V18.2656H22.0684V16.2656ZM5.56836 18.2656H2.06836V16.2656H6.06836C6.89679 16.2656 7.56836 16.9372 7.56836 17.7656V21.7656H5.56836V18.2656Z" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M19.0684 3.26562H16.0684V7.76562C16.0684 8.87019 16.9638 9.76562 18.0684 9.76562H22.5684V6.76562H19.0684V3.26562ZM18.0684 4.26562V7.76562H21.5684V8.76562H18.0684C17.5161 8.76562 17.0684 8.31791 17.0684 7.76562V4.26562H18.0684ZM8.06836 3.26562H5.06836V6.76562H1.56836V9.76562H6.06836C7.17293 9.76562 8.06836 8.87019 8.06836 7.76562V3.26562ZM7.06836 4.26562V7.76562C7.06836 8.31791 6.62064 8.76562 6.06836 8.76562H2.56836V7.76562H6.06836V4.26562H7.06836ZM22.5684 15.7656H18.0684C16.9638 15.7656 16.0684 16.6611 16.0684 17.7656V22.2656H19.0684V18.7656H22.5684V15.7656ZM21.5684 16.7656V17.7656H18.0684V21.2656H17.0684V17.7656C17.0684 17.2133 17.5161 16.7656 18.0684 16.7656H21.5684ZM8.06836 17.7656C8.06836 16.6611 7.17293 15.7656 6.06836 15.7656H1.56836V18.7656H5.06836V22.2656H8.06836V17.7656ZM6.06836 16.7656C6.62064 16.7656 7.06836 17.2133 7.06836 17.7656V21.2656H6.06836V17.7656H2.56836V16.7656H6.06836Z" />
                </svg>
            </Link>
            <div className="c-list__head">
                <h3>
                    {dataItem?.type === "ICO" ?
                    <Link to={account ? `/launchpad/${dataItem?.handle}?refID=${account}` : `/launchpad/${dataItem?.handle}`}>{dataItem?.name}</Link> : 
                    <Link to={`/fairlaunch/${dataItem?.handle}?refID=${account}`}>{dataItem?.name}</Link>}
                    <span>{dataItem?.type}</span>
                </h3>
                <p>
                    {dataItem?.type === "ICO" ?
                    <>
                        <span>Soft/Hard Cap</span>
                        <span>{dataItem?.softCap} {rounds?.currencyPay} - {progressCap} {rounds?.currencyPay}</span> 
                    </>
                    :
                    <>
                        <span>Soft Cap</span>
                        <span>{dataItem?.softCap} {rounds?.currencyPay}</span> 
                    </>
                    }
                </p>
            </div>
            <Link className="c-list__img" to={dataItem?.type === "ICO" ? 
            account ? `/launchpad/${dataItem?.handle}?refID=${account}` :
            `/launchpad/${dataItem?.handle}` : `/fairlaunch/${dataItem?.handle}?refID=${account}`}>
                <img src={dataItem?.bannerUrl ? dataItem?.bannerUrl : '../images/img01.jpg'} alt={dataItem?.name} />
            </Link>
            <div className="c-list__logo">
                <img src={dataItem?.logoUrl} alt={dataItem?.name} />
                <p>
                    {dataItem?.audit ? <span>Audit</span> : ''}
                    {dataItem?.kyc ? <span>KYC</span> : ''}
                    {dataItem?.safu ? <span>SAFU</span> : ''}
                </p>
            </div>
            <div className="c-progress">
                <p className="c-progress__title">Progress ({formatNumber(progressPercent)}%)</p>
                <div className="c-progress__bar"><span style={{width: progressPercent +'%'}}></span></div>
                <p className="c-progress__txt">{raisedAmount ? raisedAmount : '0'} {rounds?.currencyPay}<span>{progressCap} {rounds?.currencyPay}</span></p>
            </div>
            <div className="c-list__info">
                <p className="c-list__text">Network:<span>
                    <img src={`../images/${imgNetwork(rounds?.networkChain)}`} alt="" />
                    {rounds?.networkChain}</span>
                </p>
                <p className="c-list__text">Swap Rate:<span>1 {rounds?.currencyPay} = {formatNumber(swapRate)} {dataItem?.tokenInform?.symbol}</span></p>
                <p className="c-list__text">Liquidity:<span>{rounds?.liquidityPercent ? `${rounds?.liquidityPercent}%` : '--'}</span></p>
                <p className="c-list__text">Lockup Time:<span>{rounds?.liquidityLockup ? `${rounds?.liquidityLockup} Minutes` : '--'}</span></p>
                <div className="c-list__text">
                    {handleStatus()}                    
                </div>
            </div>
        </div> 
        </> 
    )}

export default ItemLaunchpad;
