import React, { useCallback, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, useParams, useNavigate } from 'react-router-dom';
import 'antd/dist/antd.css';
import {
	Layout, Row, Col, Form, Select, Button, Input, Spin, Alert, Table, DatePicker, Card, Modal, Radio, Space
} from 'antd';
import { CopyOutlined, DoubleRightOutlined, FacebookFilled, GithubOutlined, GlobalOutlined, InstagramOutlined, LeftOutlined, RedditCircleFilled, TwitterCircleFilled } from '@ant-design/icons';

import { useActiveWeb3React } from '../../../hooks';

import {
	cancelAirdrop,
	getEnsureExactAmount,
	getAirdropIsWLAdmin,
	removeAllAirdropAllocations,
	setAirdropAllocations,
	setAirdropEnableExactAmount,
	setAirdropVesting,
	startAirdrop,
	getAirdropTGEDate,
	getAirdropTGEBps,
	getAirdropCycle,
	getAirdropCycleBps,
	getAirdropState,
	getAirdropFundPercent,
	getAirdropTotalAllocationTokens,
	getAirdropUserClaimedTokens,
	getAirdropUserAllocationInfos,
	getAllAirdropAllocationCount,
	getAirdropAllocations,
	getTokenInformation,
	getAirdropClaimable, claimAirdrop, getAirdropOwner, getTokenAllowance, approveToken
} from '../utils';
import airdropAbi from '../../../contracts/abi/AirdropInfoAbi.json';
import web3 from 'web3';
import { ethers } from 'ethers';
import AirdropService from '../../../redux/services/AirdropService';
import tokenInfoAbi from '../../../contracts/abi/tokenAbi.json';
import { Store } from 'react-notifications-component';
import addNotify from '../../../components/commons/Notify/addNotify';
import { convertDateTime, formatDate } from '../../../utils/formatDate';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RPC_URL_CONVERT } from '../../../constants';
import Countdown from 'react-countdown';
import { SearchChange } from '../../../components/component';

const { Content } = Layout;
const { TextArea } = Input;
declare const window: Window & typeof globalThis & { ethereum: any };

const AirdropDetail = (props) => {
	const { chainId, account, library } = useActiveWeb3React();
	const [authToken, setAuthToken] = useState<any>();

	const param = useParams();
	let handle: any = param?.handle;
	
	const [loading, setLoading] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	const [allocations, setAllocations] = useState<any>([]);
	const [isFormAllocation, setIsFormAllocation] = useState<boolean>(false);
	const [isFormVesting, setIsFormVesting] = useState<boolean>(false);
	const [isStartForm, setIsStartForm] = useState<boolean>(false);
	const [keyLoading, setKeyLoading] = useState(0);
	const [ensureExactAmount, setEnsureExactAmount] = useState<any>(false);
	const [tgeDate, setTgeDate] = useState<any>('');
	const [tgeBps, setTgeBps] = useState<any>(0);
	const [cycle, setCycle] = useState<any>(0);
	const [cycleBps, setCycleBps] = useState<any>(0);
	const [state, setState] = useState<any>(0);
	const [fundPercent, setFundPercent] = useState<any>(0);
	const [totalTokens, setTotalTokens] = useState<any>(0);
	const [claimedTokens, setClaimedTokens] = useState<any>(0);
	const [userAllocations, setUserAllocations] = useState<any>();
	const [isAdmin, setIsAdmin] = useState<any>(false);
	const [specificTime, setSpecificTime] = useState<any>(false);
	const [decimals, setDecimals] = useState<any>(18);
	const [dataAirdrop, setDataAirdrop] = useState<any>();
	const [totalAllocations, setTotalAllocations] = useState<any>(0);
	const [tokenAddress, setTokenAddress] = useState<any>();
	const [claimable, setClaimable] = useState<any>();
	const [isOwner, setIsOwner] = useState<any>(false);
	const [allowance, setAllowance] = useState<any>(0);
	const [progressPercent, setProgressPercent] = useState<any>(0);
	const [totalToStartTokens, setTotalToStartTokens] = useState<any>(0);


	const [addWLForm] = Form.useForm();
	const [vestingForm] = Form.useForm();
	const [startForm] = Form.useForm();
	let chainString:any = chainId;

	const provider = RPC_URL_CONVERT[chainString];

	const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

	let timeClaimReward = new Date(dataAirdrop?.startClaim);
	let startJoin:any = new Date(dataAirdrop?.startJoin);
	let endJoin:any = new Date(dataAirdrop?.endJoin);
	let now = new Date();

	let airdropAddress:any = dataAirdrop?.airdropAddress;

	const getAirdropInfos = async () => {
		setLoadingData(true);
		try {
			
			const tokenInfoSC = await getTokenInformation(tokenInfoAbi, tokenAddress);
			
			setDecimals(tokenInfoSC.decimals);
			const claimable = await getAirdropClaimable(airdropAbi, airdropAddress, library, account);
			
			setClaimable(parseFloat(ethers.utils.formatUnits(claimable, tokenInfoSC.decimals)))

			// console.log(parseInt(claimable));
			let allowance = await getTokenAllowance(tokenInfoAbi, tokenAddress, account, airdropAddress)
			setAllowance(parseFloat(ethers.utils.formatUnits(allowance, decimals)))


			const exactAmountSC = await getEnsureExactAmount(airdropAbi, airdropAddress, library, account);
			const wlAdminSC = await getAirdropIsWLAdmin(airdropAbi, airdropAddress, library, account);
			const tgeDateSC = await getAirdropTGEDate(airdropAbi, airdropAddress, library, account);
			const tgeBpsSC = await getAirdropTGEBps(airdropAbi, airdropAddress, library, account);
			const cycleSC = await getAirdropCycle(airdropAbi, airdropAddress, library, account);
			const cycleBpsSC = await getAirdropCycleBps(airdropAbi, airdropAddress, library, account);
			const stateSC = await getAirdropState(airdropAbi, airdropAddress, library, account);
			const fundPercentSc = await getAirdropFundPercent(airdropAbi, airdropAddress, library, account);
			const totalTokensSC = await getAirdropTotalAllocationTokens(airdropAbi, airdropAddress, library, account);
			const claimedTokensSC = await getAirdropUserClaimedTokens(airdropAbi, airdropAddress, library, account);
			const userAllocationsSC = await getAirdropUserAllocationInfos(airdropAbi, airdropAddress, library, account);
			const totalAllocationsSC = await getAllAirdropAllocationCount(airdropAbi, airdropAddress, library, account);
			const owner = await getAirdropOwner(airdropAbi, airdropAddress, library, account);


			const allocationsSC = await getAirdropAllocations(airdropAbi, airdropAddress, library, account, 0, parseInt(totalAllocationsSC));
			setAllocations(allocationsSC);

			setUserAllocations(userAllocationsSC);
			setTotalAllocations(parseInt(totalAllocationsSC));
			setIsOwner(account?.toLowerCase() === owner?.toLowerCase());
			setEnsureExactAmount(exactAmountSC);
			setIsAdmin(wlAdminSC);
			setTgeDate(tgeDateSC);
			setTgeBps(parseInt(tgeBpsSC) / 100);
			setCycle(parseInt(cycleSC) / 60);
			setCycleBps(parseInt(cycleBpsSC) / 100);
			setState(parseInt(stateSC));
			setFundPercent(parseInt(fundPercentSc) / 10000);
			setTotalTokens(parseFloat(ethers.utils.formatUnits(totalTokensSC, tokenInfoSC.decimals)));
			setClaimedTokens(parseFloat(ethers.utils.formatUnits(claimedTokensSC, tokenInfoSC.decimals)));

			setLoadingData(false);
		} catch (e) {
			console.log(e);
			setLoadingData(false);
		}

	}


	const getAirDrop = async (_handle) => {
		setLoading(true);

		try {
			let res = await AirdropService.getAirdropDetail(_handle);
			if (res.status === 200) {
				setDataAirdrop(res.data);
				setTokenAddress(res.data?.airdropTokenInform?.tokenAddress)
			}
			setLoading(false);
		} catch (error) {
			console.log('Error: ', error);
		} finally {
			setLoading(false);
		}
	};

	const getAuthToken = async (account) => {
        await AirdropService.getAuthToken(account)
        .then((res)=>{
            setAuthToken(res.data.id_token);
        })
        .catch((error:any)=>{});
    }
	useEffect(() => {
        if(account){
            getAuthToken(account);
        }
    }, [account]);

	useEffect(() => {
		if (handle) {
			getAirDrop(handle);
		}
	}, [handle]);


	useEffect(() => {
		if (totalTokens !== 0) {
			setProgressPercent((claimedTokens / totalTokens) * 100)
		}
	}, [totalTokens, claimedTokens]);

	useEffect(() => {
		setTotalToStartTokens(totalTokens + (totalTokens * fundPercent))
	}, [totalTokens, fundPercent]);

	useEffect(() => {
		if (tokenAddress && account && airdropAddress) {
			getAirdropInfos()
		}
	}, [tokenAddress, account, dataAirdrop, airdropAddress]);



	const closeBox = (e) => {
		e.preventDefault();
		setIsFormAllocation(false);
		setIsFormVesting(false);
		setIsStartForm(false)

		startForm.resetFields();
		vestingForm.resetFields();
		addWLForm.resetFields();
		setSpecificTime(0)
	};
	const showFormSetAllocation = () => {
		setIsFormAllocation(true);
	};

	const onShowStartForm = () => {
		setIsStartForm(true);
	};
	const showFormSetVesting = () => {
		vestingForm.setFieldsValue({
			tge_release_percent: tgeBps,
			cycle: cycle,
			cycle_release_percent: cycleBps
		});
		setIsFormVesting(true);
	};
	const removeAllocation = () => {
		try {
			setLoading(true);
			setKeyLoading(6);
			removeAllAirdropAllocations(airdropAbi, airdropAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											Store.addNotification(addNotify('Remove Successfully', 'success'));
										}
									} else {
										Store.addNotification(addNotify('Remove All Airdrop Allocations Failed', 'danger'));
									}
									setLoading(false);
									window.location.reload();
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	};
	//onClaimTokens

	const onClaimTokens = async () => {
		try {
			setLoading(true);
			setKeyLoading(12);
			await claimAirdrop(airdropAbi, airdropAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
										
											Store.addNotification(addNotify("Claim Successfully", 'success'));
										}
									} else {
										Store.addNotification(addNotify("Claim Failed", 'danger'));
									}
									setLoading(false);
									window.location.reload();
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	};
	const disableExactAmount = async (status) => {
		try {
			setLoading(true);
			setKeyLoading(7);
			await setAirdropEnableExactAmount(airdropAbi, airdropAddress, library, account, status)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											setEnsureExactAmount(status)
											Store.addNotification(addNotify(`${status ? 'Enable' : 'Disable'} Exact Amount Successfully`, 'success'));
										}
									} else {
										Store.addNotification(addNotify(`${status ? 'Enable' : 'Disable'} Exact Amount Failed`, 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	};

	const onCancelAirdrop = () => {
		try {
			setLoading(true);
			setKeyLoading(2);
			cancelAirdrop(airdropAbi, airdropAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;

											AirdropService.cancelAirDrop(dataAirdrop.id, authToken);
											
											Store.addNotification(addNotify('Cancel Airdrop Successfully', 'success'));
											setState(2);
										}
									} else {
										Store.addNotification(addNotify('Cancel Airdrop Failed', 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	};

	const onSubmitAddAllocation = async (values: any) => {

		//console.log('payload', values);
		try {
			setLoading(true);
			setKeyLoading(4);
			setAirdropAllocations(airdropAbi, airdropAddress, library, account, JSON.parse(values.addressList),
				JSON.parse(values.amountList))
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											Store.addNotification(addNotify('Set Airdrop Allocations Successfully', 'success'));
										}
									} else {
										Store.addNotification(addNotify('Set Airdrop Allocations Failed', 'danger'));
									}
									setLoading(false);
									window.location.reload();
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}



	};
	const onSubmitVesting = async (values: any) => {
		try {
			setLoading(true);
			setKeyLoading(4);
			setAirdropVesting(airdropAbi, airdropAddress, library, account, parseFloat(values.tge_release_percent) * 100, parseFloat(values.cycle) * 60, parseFloat(values.cycle_release_percent) * 100)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											
											Store.addNotification(addNotify('Set Airdrop Vesting Successfully', 'success'));
											window.location.reload();
										}
									} else {
										Store.addNotification(addNotify('Set Airdrop Vesting Failed', 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	};

	const handleApproveToken = async (e) => {
		try {
			e.preventDefault()
			setLoading(true);
			setKeyLoading(2);
			await approveToken(tokenInfoAbi, tokenAddress, library, account, airdropAddress)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										!countNoti && Store.addNotification(addNotify(`Approve ${dataAirdrop?.airdropTokenInform?.symbol} Successfully`, 'success'));
										countNoti++;
										setAllowance(Math.pow(2, 256));

									} else {
										Store.addNotification(addNotify(`Approve ${dataAirdrop?.airdropTokenInform?.symbol} Failed`, 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (error: any) {
			console.log(error);
			setLoading(false);
		}
	};

	const onStartAirdrop = async (values: any) => {
		try {
			setLoading(true);
			setKeyLoading(1);

			// let startTime = Math.floor((new Date().getTime()) / 1000);
			// if (values.specType == 1) {
				let startTime:any = Math.floor((new Date(values.start_time).getTime()) / 1000)
			// }

			console.log('startTime', startTime);
			console.log('startTime sc', new Date(startTime * 1000));

			startAirdrop(airdropAbi, airdropAddress, library, account, startTime)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;

											const payload = {
												airdropId: dataAirdrop.id,
												startTime: new Date(startTime * 1000).toISOString()
											};
											AirdropService.startAirDrop(dataAirdrop.id, payload, authToken);
											setState(1);
											Store.addNotification(addNotify('Start Airdrop Successfully', 'success'));
											setIsStartForm(false);

											setTimeout(() => {
												window.location.reload();
											}, 2500);
										}
									} else {
										Store.addNotification(addNotify('Start Airdrop Failed', 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e: any) {
			console.log(e);
			setLoading(false);
		}
	};


	const columns = [
		{
			title: 'Id',
			dataIndex: 'Id',
			key: 'Id',
			render: (text, record, index) => {
				return (
					<span >
						{index + 1}
					</span>
				)
			}
		},
		{
			title: 'Wallet',
			dataIndex: 'user',
			key: 'user',
			render: (text, record) => {
				return (
					<span >
						{record.user?.substring(0, 6)}...{record.user?.substring(record.user.length - 6)}
					</span>
				)
			}
		},

		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (text, record) => {
				return (
					<span >
						{parseFloat(ethers.utils.formatUnits(record.amount, decimals)).toLocaleString()} {dataAirdrop?.airdropTokenInform?.symbol}
					</span>
				)
			}
		}
	];
	const onCopy = () => {
		Store.addNotification(addNotify('Copied', 'success'));
	};

	const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            window.location.reload();
            return;
        }
        return (
            <div className='aird-detail-countdown-block'>
                <span>{days}d</span> : <span>{hours}h</span> : <span>{minutes}m</span> : <span>{seconds}s</span>
            </div>
        );
    };
    const getProgressTime = useCallback((startTime) => {

        const now = new Date();
        const utcDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), 0);
        const startDate = new Date(startTime);
        const startTS = Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            startDate.getHours(),
            startDate.getMinutes(),
            startDate.getSeconds(),
            0
        );
        let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600);
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60);

        if (days >= 1) {
            return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        } else {
            return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        }
    }, []);

	let nextTimeClaim:any = null;
	if(dataAirdrop?.startClaim && tgeBps > 0 && cycle > 0 && cycleBps > 0 && parseFloat(ethers.utils.formatUnits(userAllocations?.amount || '0', decimals)) > parseFloat(ethers.utils.formatUnits(userAllocations?.unlockedAmount || '0', decimals))){

		let startClaimTime:any = Math.floor((new Date(dataAirdrop?.startClaim).getTime()) / 1000);

		let vestingExist:any = 100 - tgeBps;
		let vestingExistTime:any = vestingExist/cycleBps;

		for (let i = 1; i <= vestingExistTime; i++) {
			let nextCycle:any  = (cycle * i) * 60;
			let timeClaimByCycle:any = new Date(1000*(startClaimTime + (nextCycle + 0))); // + delay 10s
			if(timeClaimByCycle > now){
				nextTimeClaim = timeClaimByCycle;
				break;
			}
			
		}
	}

	// const searchAirdrop = (key: any) => {
    //     console.log('asdad', 1231);
    // };

	return (
		<>
			<div className='airdrop-detail'>
				<div className='airdrop-breadcrum'>
					<p>Aidrop/ Airdrop list</p>
					<Link className='font-20' to={`/airdrop/list`}><LeftOutlined /> {dataAirdrop?.airdropName}</Link>
				</div>
				<div className='airdrop-page-content'>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col className='gutter-row' xs={24} xl={12}>
							{!account && (
								<>
									<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
										<Col className='gutter-row' xs={24}>
											<p>
												<Alert message="Please connect wallet" type="error" showIcon />
											</p>
										</Col>
										
									</Row>
								</>
							)}
							{dataAirdrop?.bannerUrl.length > 0 && (
								<div className='airdrop-detail-banner'>
									<img src={dataAirdrop?.bannerUrl} />
								</div>
							)}
							{dataAirdrop?.airdropInfo.length > 0 && (
								<div className='airdrop-detail-des'>
									{dataAirdrop?.airdropInfo}
								</div>
							)}
							
							<div className="airdrop-detail-social">
								{dataAirdrop?.socialNetwork?.website.length > 0 && (
									<span className="detail-social"><a target="_blank" href={dataAirdrop?.socialNetwork?.website}><img src="../../images/icon-editWebsite.svg" alt="" /></a></span>
								)}
								{dataAirdrop?.socialNetwork?.telegram.length > 0 && (
									<span className="detail-social"><a target="_blank" href={dataAirdrop?.socialNetwork?.telegram}><img src="../../images/icon-editTelegram.svg" alt="" /></a></span>
								)}
								{dataAirdrop?.socialNetwork?.facebook.length > 0 && (
									<span className="detail-social"><a target="_blank" href={dataAirdrop?.socialNetwork?.facebook}><img src="../../images/icon-editFacebook.svg" alt="" /></a></span>
								)}
								{dataAirdrop?.socialNetwork?.discord.length > 0 && (
									<span className="detail-social"><a target="_blank" href={dataAirdrop?.socialNetwork?.discord}><img src="../../images/icon-editDiscord.svg" alt="" /></a></span>
								)}
								{dataAirdrop?.socialNetwork?.medium.length > 0 && (
									<span className="detail-social"><a target="_blank" href={dataAirdrop?.socialNetwork?.medium}><img src="../../images/icon-editMedium.svg" alt="" /></a></span>
								)}
								{dataAirdrop?.socialNetwork?.youtube.length > 0 && (
									<span className="detail-social"><a target="_blank" href={dataAirdrop?.socialNetwork?.youtube}><img src="../../images/icon-editYoutube.svg" alt="" /></a></span>
								)}
								
								{dataAirdrop?.socialNetwork?.length > 0 && (
									<span className="detail-social"><a target="_blank" href={dataAirdrop?.socialNetwork?.twitter}><img src="../../images/icon-editTwitter.svg" alt="" /></a></span>
								)}
								
							</div>
							
							<div className='airdrop-line'>
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
									<Col className='gutter-row' xs={12}><span>Airdrop Address</span></Col>
									<Col className='gutter-row text-right' xs={12}>
										<div className='airdrop-token-address'>
											<span className='text-info'>{airdropAddress?.substring(0, 7)}...{airdropAddress?.substring(airdropAddress?.length - 7)}</span> {' '}
											<CopyToClipboard onCopy={onCopy} text={airdropAddress}>
												<img src="../../images/icon-copy.svg" alt="" />
											</CopyToClipboard>
										</div>
									</Col>
								</Row>
							</div>
							<div className='airdrop-line'>
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
									<Col className='gutter-row' xs={12}><span>Token Address</span></Col>
									<Col className='gutter-row text-right' xs={12}>
										<div className='airdrop-token-address'>
											<span className='text-info'>{dataAirdrop?.airdropTokenInform?.tokenAddress?.substring(0, 7)}...{dataAirdrop?.airdropTokenInform?.tokenAddress?.substring(dataAirdrop?.airdropTokenInform?.tokenAddress.length - 7)}</span> {' '}
											<CopyToClipboard onCopy={onCopy} text={dataAirdrop?.airdropTokenInform?.tokenAddress}>
												<img src="../../images/icon-copy.svg" alt="" />
											</CopyToClipboard>
										</div>
									</Col>
								</Row>
							</div>
							<div className='airdrop-line'>
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
									<Col className='gutter-row' xs={12}><span>Name</span></Col>
									<Col className='gutter-row text-right' xs={12}><span>{dataAirdrop?.airdropTokenInform?.name}</span></Col>
								</Row>
							</div>
							<div className='airdrop-line'>
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
									<Col className='gutter-row'
										xs={12}><span>Symbol</span></Col>
									<Col className='gutter-row text-right'
										xs={12}><span>{dataAirdrop?.airdropTokenInform?.symbol}</span></Col>
								</Row>
							</div>
							<div className='airdrop-line'>
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
									<Col className='gutter-row' xs={12}><span>Total reward</span></Col>
									<Col className='gutter-row text-right' xs={12}>
										<span><span className='text-info'>{dataAirdrop?.reward}</span> {dataAirdrop?.airdropTokenInform?.symbol}</span>
									</Col>
								</Row>
							</div>
							<div className='airdrop-line'>
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
									<Col className='gutter-row' xs={24} sm={10}><span>Duration Time (UTC)</span></Col>
									<Col className='gutter-row text-left-xs' xs={24} sm={14}>
										{formatDate(startJoin)} {' - '} {formatDate(endJoin)}
									</Col>
								</Row>
							</div>

							{tgeBps > 0 && (
								<>
									<div className='airdrop-line'>
										<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
											<Col className='gutter-row' xs={12}><span>TGE Release Percent</span></Col>
											<Col className='gutter-row text-right' xs={12}><span>{tgeBps.toLocaleString()}%</span></Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
											<Col className='gutter-row' xs={12}><span>Cycle</span></Col>
											<Col className='gutter-row text-right' xs={12}><span>{cycle.toLocaleString()} Minutes</span></Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
											<Col className='gutter-row' xs={12}><span>Cycle Release Percent</span></Col>
											<Col className='gutter-row text-right' xs={12}><span>{cycleBps.toLocaleString()}%</span></Col>
										</Row>
									</div>
								</>
							)}
							
							
						</Col>
						<Col className='gutter-row' xs={24} xl={12}>
							<div className='airdrop-detail-right'>
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
									<Col className='gutter-row' xs={24} sm={12}>
										<h3 className='airdrop-name'>
											{dataAirdrop?.airdropName} 
											{isOwner && startJoin > now && (<Link className='air-edit' to={`/airdrop/update/${dataAirdrop?.handle}`}><img src='../../images/icon-edit.svg' /></Link>)}
										</h3>
									</Col>
									<Col className='gutter-row text-right' xs={24} sm={12}>
										{dataAirdrop?.joinLink.length > 0 && startJoin < now && endJoin > now && (
											<>
												<Button htmlType="button" className="c-btn mb-15">
													<a target="_blank" href={dataAirdrop?.joinLink}>Join now <DoubleRightOutlined /></a>
												</Button>
											</>
										)}
									</Col>
								</Row>
								
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
									<Col className='gutter-row' xs={24} sm={12}>
										<div className='airdrop-detail-coundown'>
											{loadingData ? (
												<>
													<div className="mb-15 mt-15">
														<Space size="middle"><Spin className='loading-black' size="large" /></Space>
													</div>
												</>
											):(
												<>
													{state === 2 || !dataAirdrop?.activated ? (
														<>
															<Alert message="Airdrop canceled" type="error" />
														</>
													):(
														<>
															{startJoin > now && (
																<>
																	<p>Join airdrop start in</p>
																	<Countdown
																		date={getProgressTime(startJoin)}
																		renderer={rendererCountDown}
																	/>
																</>
															)}
															{startJoin < now && endJoin > now && (
																<>
																	<p>Airdrop end in</p>
																	<Countdown
																		date={getProgressTime(endJoin)}
																		renderer={rendererCountDown}
																	/>
																</>
															)}
															{startJoin < now && endJoin < now && (
																<>
																	{timeClaimReward > now ? (
																		<>
																			<div className='airdrop-detail-coundown'>
																				
																				<p>Claim airdrop start in</p>
																				<Countdown
																					date={getProgressTime(timeClaimReward)}
																					renderer={rendererCountDown}
																				/>
											
																			</div>
																		</>
																	):(
																		<>
																			{parseFloat(ethers.utils.formatUnits(userAllocations?.amount || '0', decimals)) > 0 ? (
																				<>
																					{parseFloat(ethers.utils.formatUnits(userAllocations?.amount || '0', decimals)) === parseFloat(ethers.utils.formatUnits(userAllocations?.unlockedAmount || '0', decimals)) ? (
																						<Alert message="Claim airdrop ended" type="success" />
																					):(
																						<>
																							{tgeBps > 0 && nextTimeClaim && parseFloat(ethers.utils.formatUnits(userAllocations?.unlockedAmount || '0', decimals)) > 0 ? (
																								<>
																									<p>Next claim airdrop start in</p>
																									<Countdown
																										date={getProgressTime(nextTimeClaim)}
																										renderer={rendererCountDown}
																									/>
																								</>
																							):(
																								<>
																									<Alert message="Airdrop is live" type="warning" />
																								</>
																							)}
																						</>
																					)}
																				</>
																			):(
																				<>
																					{isOwner ? (
																						<>
																							{claimedTokens >= totalTokens && parseFloat(ethers.utils.formatUnits(userAllocations?.amount || '0', decimals)) == 0 ? ( 
																								<>
																									{totalAllocations === 0 ? (
																										<Alert message="Claim airdrop upcoming!" type="warning" />
																									):(
																										<Alert message="Claim airdrop opening!" type="warning" />
																									)}
																								</>
																							):(
																								<Alert message="Claim airdrop opening" type="warning" />
																							)}
																							
																						</>
																					):(
																						<>
																							<Alert message="You're not whitelist" type="warning" />
																						</>
																					)}
																				</>
																			)}
																			
																		</>
																	)}
																
																</>
															)}
														</>
													)}
												</>
											)}
											
											
										</div>
									</Col>
									<Col className='gutter-row' xs={24} sm={12}>
										<div className='airdrop-detail-coundown text-right'>
											<p>Reward</p>
											<div className='text-blue font-18 font-medium'>{dataAirdrop?.reward.toLocaleString()} {' '} {dataAirdrop?.airdropTokenInform?.symbol}</div>
										</div>
										
									</Col>
								</Row>
								{/* <Card title=''> */}
									{/* {state === 0 && (
										<Alert message="Waiting for airdrop start!" type="info" />
									)} */}
									{state === 1 && (
										<></>
										// claimedTokens >= totalTokens && parseFloat(ethers.utils.formatUnits(userAllocations?.amount || '0', decimals)) == 0 && ( <Alert message="Airdrop has been ended!" type="error" />)
									)}
									{state === 2 || !dataAirdrop?.activated && (
										<></>
										// <Alert message="Airdrop canceled" type="error" />
									)}

									<div className='airdrop-your-allocation'>
										<div className='airdrop-line p-lr-15'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={12}><span className='text-gray font-16'>Your Allocation</span></Col>
												<Col className='gutter-row text-right' xs={12}>
													<span className='text-white font-16'> {parseFloat(ethers.utils.formatUnits(userAllocations?.amount || '0', decimals)).toLocaleString()} {' '} {dataAirdrop?.airdropTokenInform?.symbol}</span>
												</Col>
											</Row>
										</div>
										<div className='airdrop-line p-lr-15'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row'
													xs={12}><span className='text-gray font-16'>Your Claimed</span></Col>
												<Col className='gutter-row text-right' xs={12}>
													<span className='text-white font-16'>{parseFloat(ethers.utils.formatUnits(userAllocations?.unlockedAmount || '0', decimals)).toLocaleString()} {' '} {dataAirdrop?.airdropTokenInform?.symbol}</span>
												</Col>
											</Row>
										</div>
										{parseFloat(ethers.utils.formatUnits(userAllocations?.unlockedAmount || '0', decimals)) > 0 && tgeBps > 0 && (
											<div className='airdrop-line p-lr-15'>
												<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
													<Col className='gutter-row' xs={12}><span className='text-gray font-16'>Remainning</span></Col>
													<Col className='gutter-row text-right' xs={12}>
														<span className='text-white font-16'>
															{(parseFloat(ethers.utils.formatUnits(userAllocations?.amount || '0', decimals)) - parseFloat(ethers.utils.formatUnits(userAllocations?.unlockedAmount || '0', decimals))).toLocaleString()} {' '} {dataAirdrop?.airdropTokenInform?.symbol}
														</span>
													</Col>
												</Row>
											</div>
										)}
									</div>

									{parseFloat(ethers.utils.formatUnits(userAllocations?.amount || '0', decimals)) > 0 && parseFloat(claimable) > 0 && (
										<div className='airdrop-line'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={12}>
													<Button htmlType='button' className='c-btn btn-p50' disabled={loading} onClick={onClaimTokens}>
														Claim {' '} {claimable > 0 ?  `${claimable} ${dataAirdrop?.airdropTokenInform?.symbol}` : ''} {(loading && keyLoading === 12) && <Spin className="ml-10" size="small" />}
													</Button>
												</Col>

											</Row>
										</div>
									)}
								{/* </Card> */}
								{loadingData ? (
									<>
										<div className="loading-center text-center mb-15 mt-15">
											<Space size="middle"><Spin className='loading-black' size="large" /></Space>
										</div>
									</>
								) : (
									<>
										{isOwner && state === 0 && (
											<Card title={
													<>
														<div className='card-title-custom font-16'>Administration</div>
														<p className='card-title-p'>You can cancel airdrop; add addresses and amounts to your “Users allocation” list, manage its.</p>
													</>
												} className="text-center card-box-custom" style={{ marginTop: '15px' }}
											>
												

												{dataAirdrop?.activated ? (
													<>
														<div className='mb-15'>
															{startJoin < now && endJoin < now && (
																<Button disabled={totalAllocations > 0 ? false : true} className='c-btn' htmlType='button' onClick={onShowStartForm}>Start Claim Airdrop</Button>
															)}
															{startJoin > now && (
																<Button className='c-btn ml-15' htmlType='button'
																	disabled={loading && keyLoading === 2}
																	onClick={onCancelAirdrop}
																>
																	Cancel Airdrop {(loading && keyLoading === 2) && <Spin className="ml-10" size="small" />}
																</Button>
															)}

														</div>

														<div className='airdrop-btn-grp'>
															<Button className='c-btn mb-15' onClick={showFormSetAllocation} htmlType='button'>Set Allocations</Button>
															<Button className='c-btn mb-15 ml-15' onClick={showFormSetVesting} htmlType='button'>Set Vesting</Button>
															<Button className='c-btn mb-15 ml-15' 
																onClick={removeAllocation} disabled={loading && keyLoading === 6}
																htmlType='button'
															>
																Remove All Allocations {(loading && keyLoading === 6) && <Spin className="ml-10" size="small" />}
															</Button>
															{ensureExactAmount ?
																(
																	<Button className='c-btn mb-15 ml-15' onClick={() => disableExactAmount(false)} disabled={loading && keyLoading === 7}
																		htmlType='button'>Disable Exact Amount {(loading && keyLoading === 7) && <Spin className="ml-10" size="small" />}</Button>
																) : (
																	<Button className='c-btn mb-15 ml-15' onClick={() => disableExactAmount(true)} disabled={loading && keyLoading === 7}
																		htmlType='button'>Enable Exact Amount {(loading && keyLoading === 7) && <Spin className="ml-10" size="small" />}</Button>
																)
															}
														</div>
													</>
												):(
													<>
														<Alert message="Airdrop canceled" type="error" />
													</>
												)}
												
											</Card>
										)}
									</>
								)}
								<Card className='card-box-custom' title={<div className='text-center card-title-custom font-16'>Allocation ({totalAllocations})</div>} style={{ marginTop: '15px' }}>
									{loadingData ? (
										<>
											<div className="loading-center text-center">
												<Space size="middle"><Spin className='loading-black' size="large" /></Space>
											</div>
										</>
									) : (
										<>
											{totalAllocations === 0 ? (
												<>
													<div className='airdrop-allocation-empty'>
														<img src="../../images/empty-allocation-air.svg" alt="" />
													</div>
												</>
											) : (
												<>
													<div className='table-allocation-airdrop'>
														 {/* <SearchChange placeholder='Search airdrop' onChange={searchAirdrop} /> */}
														<Table className='table-allocation' dataSource={allocations} columns={columns} />
													</div>
												</>
											)}
										</>
									)}

								</Card>
							</div>
						</Col>
					</Row>
				</div>
				
			</div>
		
			<Modal
				title={<div className='text-md custom-modal-title'>Add Allocations</div>}
				visible={isFormAllocation}
				onCancel={()=> setIsFormAllocation(false)}
				className='custom-modal create-token-modal'
				footer={false}
			>
				<div className='transer-box-content'>
					<Form
						form={addWLForm}
						name='basic'
						autoComplete='off'
						onFinish={onSubmitAddAllocation}
						layout='vertical'
					>

						<Form.Item name='addressList' label='TGE release percent (%)' hidden={true}>
							<Input className='amount-buy' placeholder='Ex: 0' />
						</Form.Item>
						<Form.Item name='amountList' label='TGE release percent (%)' hidden={true}>
							<Input className='amount-buy' placeholder='Ex: 0' />
						</Form.Item>

						<Form.Item name='account_address' label='Allocations'
							rules={[
								{
									validator: (rule, value, cb: (msg?: string) => void) => {
										if (!value) {
											return cb('Allocations can not be blank');
										}
										if (value) {
											const wallets = value.trim().split('\n');
											let totalTokens = 0;

											let addressList: any = [];
											let allocationList: any = [];
											let amountList: any = [];
											for (let i = 0; i < wallets.length; i++) {
												const lineValues = wallets[i].trim().split(',');
												const address = (lineValues[0] || '').trim();
												const amount = (lineValues[1] || '0').trim();

												addressList.push(address);
												amountList.push(ethers.utils.parseUnits(`${amount}`, decimals).toHexString())
												allocationList.push({ address, amount })

												totalTokens = totalTokens + parseFloat(amount);

												if (!ethers.utils.isAddress(address) || !parseFloat(amount)) {
													return cb(`Incorrect value at line ${i + 1}`);
												}
											}
											addWLForm.setFieldsValue({
												'addressList': JSON.stringify(addressList),
												'amountList': JSON.stringify(amountList),
											});
											cb();
										}

									}
								}]}>
							<TextArea rows={8} className='amount-buy' placeholder='Insert address: separate with breaks line. The amount of each user separate by comma(,) symbol. The format just like CSV file
																					Ex:
																					0x34E7f6A4d0BB1fa7aFe548582c47Df337FC337E6,1000
																					0xd8Ebc66f0E3D638156D6F5eFAe9f43B1eBc113B1,500
																					0x968136BB860D9534aF1563a7c7BdDa02B1A979C2,1234' />
						</Form.Item>
						<Form.Item className='modal-btn-group'>
							<button className='c-btn' disabled={loading} type='submit'>Add
								Allocations {loading && <Spin className='ml-10' size='small' />}</button>
							<button className='c-btn c-btn--grey ml-15' disabled={loading} onClick={closeBox}>Close</button>
						</Form.Item>

					</Form>

				</div>
			</Modal>
			<Modal
				title={<div className='text-md custom-modal-title'>Set Vesting</div>}
				visible={isFormVesting}
				onCancel={()=> setIsFormVesting(false)}
				className='custom-modal create-token-modal'
				footer={false}
			>
				<div className='transer-box-content'>
					<Form
						form={vestingForm}
						name='basic'
						autoComplete='off'
						onFinish={onSubmitVesting}
						layout='vertical'
					>
						<Form.Item name='tge_release_percent' label='TGE release percent (%)'
							rules={[
								{
									validator: (rule, value, cb: (msg?: string) => void) => {
										!value || parseFloat(value) <= 0 || parseFloat(value) > 100
											? cb('TGE percent must be a positive number')
											: cb();
									}
								}
							]}>
							<Input className="inputForm" placeholder='Ex: 0' />
						</Form.Item>
						<Form.Item name='cycle_release_percent' label='Cycle release percent (%)'
							rules={[
								{
									validator: (rule, value, cb: (msg?: string) => void) => {
										!value || parseFloat(value) <= 0 || parseFloat(value) > 100
											? cb('Cycle release percent must be a positive number')
											: cb();
									}
								}]}>
							<Input className="inputForm" placeholder='Ex: 0' />
						</Form.Item>
						<Form.Item name='cycle' label='Cycle (Minutes)'
							rules={[
								{
									validator: (rule, value, cb: (msg?: string) => void) => {
										!value || parseFloat(value) <= 0
											? cb('Cycle must be a positive number')
											: cb();
									}
								}]}>
							<Input className="inputForm" placeholder='Ex: 0' />
						</Form.Item>
						<Form.Item className='modal-btn-group'>
							<button className='c-btn' disabled={loading} type='submit'>
								Set vesting {loading && <Spin className='ml-10' size='small' />}
							</button>
							<button className='c-btn c-btn--grey ml-15' disabled={loading} onClick={closeBox}>Close</button>
						</Form.Item>
					</Form>

				</div>
			</Modal>

			<Modal
				title={<div className='text-md custom-modal-title'>Setting time to start</div>}
				visible={isStartForm}
				onCancel={()=> setIsStartForm(false)}
				className='custom-modal create-token-modal'
				footer={false}
			>
				<div className='transer-box-content'>
					<Form
						form={startForm}
						name='basic'
						autoComplete='off'
						onFinish={onStartAirdrop}
						layout='vertical'
					>
						{/* <Form.Item name="specType" label="Set time to start airdrop" initialValue={0}>
							<Radio.Group className="radio-gr-create" onChange={e => setSpecificTime(!(!e.target.value))}>
								<Radio value={0}>Start now</Radio>
								<Radio value={1}>Start with specific time</Radio>
							</Radio.Group>
						</Form.Item>
						{specificTime ? ( */}
							{/* <Form.Item name="start_time" label="Start time"
								rules={[
									{ required: true, message: 'Please input Start time!' },
								]}>
								<Input className="inputForm" type="datetime-local" />
							</Form.Item> */}
							<Form.Item name="start_time" label="Start Time (UTC)" rules={[
								{ required: true, message: `Please input your contribution time` },
								{
									validator: (rule, value, cb: (msg?: string) => void) => {
										!value || new Date(value) <= new Date(Date.now())
											? cb("Start time needs to be after now")
											: cb();
									}
								}
							]}> 
								<DatePicker showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')}/>
							</Form.Item>
						{/* ) : (<></>)} */}

						<div className="start-info">
							<span className="start-info-detail"> You need at least {totalToStartTokens} {dataAirdrop?.airdropTokenInform?.symbol} to start airdrop</span>
						</div>


						<Form.Item className='mt-15'>

							{allowance < totalTokens ? (
								<button className='c-btn' disabled={loading} onClick={handleApproveToken}>Approve {loading &&
									<Spin className='ml-10' size='small' />}
								</button>
							) : (
								<button className='c-btn' disabled={loading} type='submit'>Start Airdrop {loading &&
									<Spin className='ml-10' size='small' />}</button>
							)}
							<button className='c-btn c-btn--grey ml-15' disabled={loading} onClick={closeBox}>Close</button>
						</Form.Item>
					</Form>

				</div>
			</Modal>
		</>
	);
};

export default AirdropDetail;
