import Wallet from './wallet';
import Network from './network';
import Create from './create';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getHeader, headerSelector } from "../../redux/slicers/headerSlice";
const Header =(props) => { 
  const { header } = useAppSelector(headerSelector);
  const dispatch =useAppDispatch() 
  const handleClose = () => { 
    dispatch(getHeader(""));
  }
 
  return ( 
    <>  
      {props?.tabMobile ? 
        <div className="mobile" style={{display: !props?.toggle ? "none": "flex"}}>  
          <Network/>
          <Wallet/>
        </div> :
        <div className="header"> 
          <div className="header-bg">
            <Create/>
            <Network/>
            <Wallet/>
          </div> 
        </div> }
    </>
    );
  } 
  export default Header;