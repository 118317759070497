import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RadioChangeEvent, DatePickerProps, Spin, Form, Input } from "antd";
import { Radio, Tabs, DatePicker } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  Button,
  InputChange,
  SearchChange,
  Loading,
} from "../../components/component";
import useWindowSize from "../../hooks/useWindowSize";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  finalizeLaunchpadAPI,
  getLaunchpadDetail,
  joinedLaunchpadAPI,
  launchPadSelector,
} from "../../redux/slicers/launchpadSlice";
import { formatDate, formatUTCDate } from "../../utils/formatDate";
import { formatNumber, formatNumberAmount } from "../../utils/formatNumber";
import Countdown, { zeroPad } from "react-countdown";
import { useActiveWeb3React } from "../../hooks";
import { formatData } from "../../utils/formatData";
import {
  useLaunchpadContract,
  useTokenContract
} from "../../hooks/useContract";
import networkChange from "../../utils/networkChange";
import { createBrowserHistory } from 'history';
import {
  // getLaunchpadInfo,
  getOwnerZoneInfo,
  getUserClaimAble,
  getJoinInfos,
  getListOfWhiteListUsers,
  getTeamCliffVesting,
  claimTokens,
  contribute,
  emergencyWithdrawContribute,
  finalizeLaunchpad,
  cancelLaunchpad,
  setWhitelistPoolLaunchpad,
  addWhiteListUsers,
  removeWhiteListUsers,
  setLaunchpadAllocations,
  getGsLock,
  getLpLockId,
  getTeamFirstReleasePercent,
  getTeamLockId,
  getRouterAddress,
  withdrawContribute,
  setClaimTime,
  getJoinedUsers,
  getInfoLaunchpad,
  getAffiliateReward,
  getPercertAffiliate,
  enableAffiliateLaunchpad,
  getAffiliateExist,
  getAffiliateListUser,
  getAmountsAffiliate,
  withdrawContributeAffiliate,
  claimCanceledTokens,
  getFinalizeExist,
  getHoldingTokenUser,
} from "../../contracts/launchpad";
import {
  getTokenAllowance,
  getTokenAllowanceNoHook,
  tokenAmountApprove,
  tokenApprove,
  tokenApproveNoHook,
  tokenHoldingInformation,
  tokenInformation,
} from "../../contracts/token";
import { Store } from "react-notifications-component";
import addNotify from "../../components/commons/Notify/addNotify";
import {
  getCurrencyDecimals,
  getLiquidityTokens,
  getPresaleTokens,
} from "../../components/createlaunchpad/ultils";
import { ethers } from "ethers";
import {
  CURRENCIES_ADDRESS,
  LAUNCHPAD_CONFIGS,
  SALETYPE_WHITELIST_POOL,
} from "../../constants";
import axios from "axios";
import { convertFromWei, convertToWei } from "../../contracts/utils";
import { getDexInfo } from "../../utils/dex";
import {
  getTokenInformByAddress,
  tokenInformsSelector,
} from "../../redux/slicers/tokenInformSlice";
import StandardTokenABI from "../../contracts/abi/StandardTokenABI.json";
import LaunchpadABI from "../../contracts/abi/LaunchpadABI.json";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import dayjs from 'dayjs';

ChartJS.register(ArcElement, Tooltip, Legend);

interface Size {
  width: number;
  height: number;
}
const chartOptions = {
  plugins: {
    tooltip: {
      callbacks: {
        label: function (tooltipItem: any) {
          return " " + tooltipItem.parsed + "% " + tooltipItem.label;
        },
      },
    },
  },
};
type launchpadInfoObject = {
  softCap: number | any;
  hardCap: number | any;
  startTime: Date | any;
  endTime: Date | any;
  state: number | any;
  raisedAmount: number | any;
  balance: number | any;
  feeToken: string | any;
  listingTime: Date | any;
  whitelistPool: number | any;
  holdingToken: string | any;
  holdingTokenAmount: number | any;
};
type DexInfoObject = {
  factoryAddress: any;
  id: any;
  name: any;
  routerAddress: any;
};

const LaunchpadDetail: React.FC = () => {
  const { account, chainId, library } = useActiveWeb3React();
  const [notification, setNotification] = useState<boolean>(true);
  const [saleType, setSaleType] = useState<number>(0);
  const [isAddedWhitelist, setIsAddedWhitelist] = useState<boolean>(false);
  const [isRemovedWhitelist, setIsRemovedWhitelist] = useState<boolean>(false);
  const [isListedWhitelist, setIsListedWhitelist] = useState<boolean>(false);
  const [disableWhitelist, setDisableWhitelist] = useState<boolean>(false);
  const [valueAmount, setValueAmount] = useState<number>(0);
  const [softCap, setSoftCap] = useState<any>();
  const [hardCap, setHardCap] = useState<any>();
  const [raisedAmount, setRaisedAmount] = useState<any>();
  const [listingTime, setListingTime] = useState<any>();
  const [feeTokenAddress, setFeeTokenAddress] = useState<any>();
  const [actionStatus, setActionStatus] = useState("");
  const [actionWLPStatus, setActionWLPStatus] = useState("");
  const [canCancel, setCanCancel] = useState<any>();
  const [canFinalize, setCanFinalize] = useState<any>();
  const [isOwner, setIsOwner] = useState<any>();
  const [whitelistPoolOwner, setWhitelistPoolOwner] = useState<any>();
  const [publicAntibotTokenAddress, setPublicAntibotTokenAddress] =
    useState("");
  const [publicAntibotMinHoldingAmount, setPublicAntibotMinHoldingAmount] =
    useState("");
  const [priceBNB, setPriceBNB] = useState<any>();
  const [teamCliffVesting, setTeamCliffVesting] = useState<number>();
  const [listOfWhiteListUsers, setListOfWhiteListUsers] = useState<any>([]);
  const [addListOfWhiteListUsers, setAddListOfWhiteListUsers] = useState<any>(
    []
  );
  const [removeListOfWhiteListUsers, setRemoveListOfWhiteListUsers] =
    useState<any>([]);
  const [isListedOfTopRewards, setIsListedOfTopRewards] = useState<any>(false);
  const [isSwitch, setIsSwitch] = useState<any>();
  const [isUserWhitelist, setIsUserWhitelist] = useState<boolean>(false);
  const [totalClaimable, setTotalClaimable] = useState(0);

  const { TabPane } = Tabs;
  const size: Size = useWindowSize();
  const { handle } = useParams<{ handle: any }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { launchpadDetail, launchpadLoading, launchpadErrors } =
    useAppSelector(launchPadSelector);
  const { tokenInformsByAddress } = useAppSelector(tokenInformsSelector);
  const launchpadRound =
    launchpadDetail?.rounds &&
    launchpadDetail?.rounds.length > 0 &&
    launchpadDetail?.rounds[0];
  const tokenInform = launchpadDetail?.tokenInform;
  // const vestingTeam = launchpadDetail?.vestingTeam;
  const vestingContributor = launchpadDetail?.vestingContributor;
  const nowTimer = new Date();
  const startTimer = new Date(launchpadDetail?.startTime);
  const endTimer = new Date(launchpadDetail?.endTime);
  const tokenForPreasle = getPresaleTokens(
    launchpadRound?.swapRate,
    launchpadDetail?.hardCap
  );
  const tokenForLiquidity = getLiquidityTokens(
    launchpadDetail?.hardCap,
    launchpadRound?.raisedTokenFee,
    launchpadRound?.liquidityPercent,
    launchpadRound?.listingRate
  );
  const launchpadContract = useLaunchpadContract(
    launchpadRound?.presaleAddress
  ); 
 
  const chartPresale = (tokenForPreasle * 100) / tokenInform?.totalSupply;
  const chartLiquidity = (tokenForLiquidity * 100) / tokenInform?.totalSupply;
  const chartUnlocked = 100 - (chartPresale + chartLiquidity);
  const [isApprovedToken, setIsApprovedToken] = useState<boolean>(false);
  const [launchpadState, setLaunchpadState] = useState<number>(0);
  const [totalContributors, setTotalContributors] = useState<number>(0);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [claimedTokens, setClaimedTokens] = useState(0);
  const [totalUserTokens, setTotalUserTokens] = useState(0);
  const [routerAddress, setRouterAddress] = useState("");
  const [dexInfo, setDexInfo] = useState<DexInfoObject>();
  const [vestingTeam, setVestingTeam] = useState([]);
  const [needApproveToken, setNeedApproveToken] = useState(false);
  const [refund, setRefund] = useState<boolean>();
  const [whiteListPool, setWhiteListPool] = useState<any>();
  const [holdingTokenForm] = Form.useForm();
  const [holdingToken, setHoldingToken] = useState<any>("");
  const [holdingTokenInput, setHoldingTokenInput] = useState<any>("");
  const [infoToken, setInfoToken] = useState<any>([]);
  const [holdingAmount, setHoldingAmount] = useState<any>("");
  const [holdingName, setHoldingName] = useState<any>("");
  const [holdingSymbol, setHoldingSymbol] = useState<any>("");
  const [holdingDecimals, setHoldingDecimals] = useState<any>(18);
  const [launchpadBalance, setLaunchpadBalance] = useState<any>();
  const [affiliateValue, setAffiliateValue] = useState<any>("");
  const [percertAffiliate, setPercertAffiliate] = useState<any>(0);
  const [checkAffiliate, setCheckAffiliate] = useState<boolean>(false);
  const [checkAffiliateBtn, setCheckAffiliateBtn] = useState<boolean>(false);
  const [listUserAffiliate, setListUserAffiliate] = useState<any>({});
  const [listAmountAffiliate, setListAmountAffiliate] = useState<any>({});
  const [percentAffiliateInput, setPercentAffiliateInput] = useState(0);
  const [listingTimeDate, setListingTimeDate] = useState<any>();
  const [disableListingTime, setDisableListingTime] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [checkHoldingToken, setCheckHoldingToken] = useState<boolean>(false);
  const tokenHoldingContract = useTokenContract(holdingToken);
  
  
 const [form] = Form.useForm();
  const currencyDecimals = getCurrencyDecimals(
    chainId,
    launchpadDetail?.currency
  );
  const [searchParams, setSearchParams] = useSearchParams();

  let refID = searchParams.get("refID"); 

  function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;
  
  var hour = date.getHours().toString();
  hour = hour.length > 1 ? hour : '0' + hour;

  var minutes = date.getMinutes().toString(); 
  minutes = minutes.length > 1 ? minutes : '0' + minutes;
  
  return  month + '/' + day + '/' + year +"  "+ hour+ ':' + minutes;
}
 
  
  let progressPercent: any | number = 0;
  let statusText: any = "";
  let progressTime: any = "";
  let contributeInput: any = null;
  let actionButton: any = null;

  const data = {
    labels: ["Presale", "Liquidity", "Unlocked"],
    datasets: [
      {
        label: "",
        data: [chartPresale, chartLiquidity, chartUnlocked],
        backgroundColor: ["#01058A", "#012CB5", "#1BA9EA"],
        borderColor: ["#01058A", "#012CB5", "#1BA9EA"],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    
    if (handle) {
      dispatch(getLaunchpadDetail(handle));
    }
  }, [handle]); 

    useEffect(() => {
        if ((!refID || refID && !ethers.utils.isAddress(refID?.toString())) && account && checkAffiliate) {
            refID = account;
            setSearchParams({refID: account});
        }
    }, [account, checkAffiliate]); 

  useEffect(() => {
    if (tokenInform?.tokenAddress) {
      dispatch(
        getTokenInformByAddress(tokenInform?.tokenAddress, (res: any) => {
          setVestingTeam(res.tokenInform?.vestingTeams);
        })
      );
    }
  }, [tokenInform]);

  useEffect(() => {
      if(launchpadDetail) { 
          form.setFieldsValue({  
              selectTime: moment(new Date()).utc(),
          }); 
      }
  }, [launchpadDetail]);

  useEffect(() => {
    if (launchpadContract && account) {
      (async () => {
        await getInfoLaunchpad(
          launchpadContract,
          tokenInform?.decimals,
          currencyDecimals
        ).then((res: any) => { 
          console.log("res",res);
          setSoftCap(res?.softCap);
          setRaisedAmount(res?.globalTotalInvestment);
          setListingTime(res?.listingTime);
          setHardCap(res?.hardCap);
          setFeeTokenAddress(res?.feeToken);
          setWhiteListPool(Number(res.whitelistPool));
          setLaunchpadState(res.state);
          if (parseInt(res.feeToken) !== 0) {
            setNeedApproveToken(true);

            (async () => {
              if (launchpadDetail?.currency && launchpadRound?.presaleAddress) {
                const allowance = await getTokenAllowanceNoHook(
                  StandardTokenABI,
                  res.feeToken,
                  account,
                  launchpadRound?.presaleAddress
                );
                setIsApprovedToken(allowance > 0 ? true : false);
              }
            })();
          } else {
            setNeedApproveToken(false);
          }
          // console.log("holdingToken", parseInt(res.holdingToken))
          
      if (parseInt(res.holdingToken) !== 0) {   
           (async () => {
              await tokenInformation(res.holdingToken).then(
                (tokenInfoSC: any) => {
                  console.log("tokenInfoSC", tokenInfoSC)
                  setHoldingName(tokenInfoSC.name);
                  // setHoldingSymbol(tokenInfoSC.symbol);
                  setHoldingDecimals(tokenInfoSC.decimals);
                  setHoldingAmount(
                    convertFromWei(res.holdingTokenAmount, tokenInfoSC.decimals)
                  );
                  setHoldingToken(res.holdingToken);
                }
              );
            })(); 
          }
        });  
        // await getLaunchpadInfo(launchpadContract, tokenInform?.decimals, currencyDecimals)
        // .then((launchpadInfoSC:any) => {
        //     setLaunchpadBalance(launchpadInfoSC.balance);
        // });
      })();
      
      setIsOwner(
        launchpadDetail?.ownerAddress.toLocaleLowerCase() ===
          account?.toLocaleLowerCase()
          ? true
          : false
      );
    }
  }, [launchpadContract]); 
  
  useEffect(() => {
    if (launchpadContract && account) {
      (async () => {
        await getTeamCliffVesting(
          launchpadContract,
          tokenInform?.decimals
        ).then((res: any) => {
          setTeamCliffVesting(Number(res));
        }); 
        await getPercertAffiliate(launchpadContract).then((res: any) => { 
          setPercertAffiliate(Number(res) / 100);
        });

        await getAffiliateReward(
          launchpadContract,
          account,
          currencyDecimals
        ).then((res: any) => { 
            console.log("🚀 ~ file: index.tsx:391 ~ ).then ~ res:", res)
            
          setAffiliateValue(res);
        });

        await getOwnerZoneInfo(
          launchpadContract,
          account,
          tokenInform?.decimals
        ).then((res: any) => {
          setCanCancel(res?.canCancel);
          setCanFinalize(res?.canFinalize);
          setIsOwner(res?.isOwner);
          setWhitelistPoolOwner(Number(res?.whitelistPool));
          setSaleType(Number(res?.whitelistPool));
        });

        await getJoinInfos(
          launchpadContract,
          account,
          tokenInform?.decimals
        ).then((res: any) => {
          console.log("🚀 ~ file: index.tsx:385 ~ ).then ~ res:", res)
          setClaimedTokens(
            Number(
              convertFromWei(res?.claimedTokens, tokenInform?.decimals)
            )
          );
          setTotalInvestment(
            Number(
              convertFromWei(res?.totalInvestment, currencyDecimals)
            )
          );
          setTotalUserTokens(
            Number(
              convertFromWei(res?.totalTokens, tokenInform?.decimals)
            )
          );
          setRefund(res.refund);
        });

        await getUserClaimAble(
          launchpadContract,
          account,
          tokenInform?.decimals
        ).then((res: any) => {
          setTotalClaimable(Number(res));
        });

        // await getListOfWhiteListUsers(launchpadContract).then((res: any) => {
        //   setListOfWhiteListUsers(res);
        //   if (res && res.length === 0) {
        //     setIsUserWhitelist(false);
        //   } else if (res && res.length > 0 && res.includes(account)) {
        //     setIsUserWhitelist(true);
        //   }
        // });  

        await getJoinedUsers(launchpadContract).then((res: any) => {
          setTotalContributors(res?.length);
        });
          
        await getAffiliateListUser(launchpadContract, account).then(
          (res: any) => {
            setListUserAffiliate(res);
          }
        );

        await getAmountsAffiliate(launchpadContract, account).then(
          (res: any) => {
            
            setListAmountAffiliate(res);
          }
        );

        await getHoldingTokenUser(launchpadContract, account).then(
          (res: any) => {
            (async() => {  
              // console.log("res?.holdingToken", res?.holdingToken);
              // console.log(Number(convertFromWei(res?.holdingTokenAmount,tokenInform?.decimals))); 
              // console.log("tokenInform?.decimals", tokenInform);
              setHoldingToken(res?.holdingToken) 
              await tokenHoldingInformation(tokenHoldingContract, account).then((res2)=>{ 
                if(res2){
                   if(Number(res2.balance) > Number(convertFromWei(res?.holdingTokenAmount,tokenInform?.decimals))){
                      setCheckHoldingToken(true)
                   }
                } 
              })
            })()
           
          }
        );

        // await getLpLockId(launchpadContract)
        // .then((res:any) => {
        //     console.log("getLpLockId", Number(res));
        // });

        // await getTeamFirstReleasePercent(launchpadContract)
        // .then((res:any) => {
        //     console.log("getTeamFirstReleasePercent", Number(res));
        // });

        await getTeamLockId(launchpadContract)
        .then((res:any) => {
            console.log("getTeamLockId", Number(res));
        });

        await getRouterAddress(launchpadContract).then((res: any) => {
          setRouterAddress(res);
        });
      })();
          
    }
  }, [account, launchpadContract, whiteListPool]);


  useEffect(() => {
     (async () => {
      if(isOwner){
        await getListOfWhiteListUsers(launchpadContract).then((res: any) => {
          setListOfWhiteListUsers(res);
          if (res && res.length === 0) {
            setIsUserWhitelist(false);
          } else if (res && res.length > 0 && res.includes(account)) {
            setIsUserWhitelist(true);
          }
          }) 
        }  
      })(); 
  }, [isListedWhitelist ,disableWhitelist, launchpadContract, account])
  

  useEffect(() => {
    (async () => {
      await getAffiliateExist(launchpadContract).then((res: any) => {
        setCheckAffiliate(res);
      });
    })();
  }, [checkAffiliate, account, launchpadContract]);

  useEffect(() => {
    if (Number(launchpadRound?.maxBuy) - Number(totalInvestment) === 0) {
      setActionStatus("BOUGHT");
    }
  }, [totalInvestment]);

  useEffect(() => {
    let _dexInfo = getDexInfo(chainId, routerAddress);
    setDexInfo(_dexInfo);
  }, [chainId, routerAddress]); 

  let availableAmount: number = Number(launchpadDetail?.hardCap - raisedAmount);
 
  useEffect(() => {
    if (launchpadRound?.chainId && chainId) {
      if (launchpadRound?.chainId !== chainId) {
        setIsSwitch(true);
        if (launchpadRound?.chainId === 42161) {
          networkChange("0xa4b1");
        } else if (launchpadRound?.chainId === 421613) {
          networkChange("0x66eed");
        } else if (launchpadRound?.chainId === 97) {
          networkChange("0x61");
        }
      } else {
        setIsSwitch(false);
      }
    }
  }, [chainId, account, launchpadRound?.chainId]);

  const handleSwitchNetwork = async () => {
    if (launchpadRound?.chainId === 42161) {
      await networkChange("0xa4b1");
    } else if (launchpadRound?.chainId === 421613) {
      await networkChange("0x66eed");
    } else if (launchpadRound?.chainId === 97) {
      await networkChange("0x61");
    }
  };

const handleApproveToken = async () => {
    if (!launchpadRound?.presaleAddress) {
      Store.addNotification(addNotify("Presale Address is invalid!", "danger"));
      return;
    }

    setActionStatus("APPROVE_LOADING");

    await tokenApproveNoHook(
      StandardTokenABI,
      feeTokenAddress,
      library,
      account,
      launchpadRound?.presaleAddress
    )
    .then((res: any) => {
        let resWait = res.wait();
        resWait.then((resTransaction: any) => {
            if (resTransaction && resTransaction.status && resTransaction.blockNumber
            ) {
              Store.addNotification(addNotify("Tokens approved successfully", "success"));
              setIsApprovedToken(true);
              setActionStatus("");
            } else {
              setActionStatus("");
              Store.addNotification(addNotify("Transaction failed", "warning"));
              return;
            }
		})
		.catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, "danger"));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, "danger"));
                }
            }
        });
    })
    .catch((error: any) => {
        setActionStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
            if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
            }
        }
    });
};

const handleClaimTokens = async () => {
    setActionStatus("CLAIM_LOADING");

    await claimTokens(launchpadContract)
	.then((res: any) => {
        let resWait = res.wait();
        resWait.then((resTransaction: any) => {
            if (resTransaction && resTransaction.status && resTransaction.blockNumber
            ) {
				Store.addNotification(addNotify("Tokens claimed successfully", "success"));
				setActionStatus("CLAIMED");
				setClaimedTokens(claimedTokens + totalClaimable);
				setTotalClaimable(0);
            } else {
				setActionStatus("");
				Store.addNotification(addNotify("Transaction failed", "warning"));
				return;
            }
		})
		.catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, "danger"));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, "danger"));
                }
            }
		});
	})
    .catch((error: any) => {
        setActionStatus("");
        if (error.code == 4001 && error.message) {
            Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
            Store.addNotification(addNotify(error.reason, "danger"));
        } else {
            if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
            }
        }
    });
};


const handleContribute = async () => {
    availableAmount = Number(launchpadDetail?.hardCap - raisedAmount);
    console.log("availableAmount", availableAmount);
    if (
      !valueAmount ||
      valueAmount < launchpadRound?.minBuy ||
      valueAmount > launchpadRound?.maxBuy ||
      valueAmount > availableAmount
    ) {
        Store.addNotification(addNotify("Amount is invalid!", "warning"));
        return;
    }
    setActionStatus("BUY_LOADING");
    let presenter; 

    if (checkAffiliate && refID && account && refID !== account) {
      presenter = refID;
    } else {
      presenter = "0x0000000000000000000000000000000000000000";
    }

    await contribute(
      launchpadContract,
      valueAmount,
      presenter,
      currencyDecimals
    )
    .then((res: any) => {
        let resWait = res.wait();
        resWait.then((resTransaction: any) => {
            if (resTransaction && resTransaction.status && resTransaction.blockNumber) {  
                dispatch(joinedLaunchpadAPI(launchpadDetail?.id, async (res:any) => { 
                    if(res.result === "SUCCESS") {
                     (async () => {
                        await getJoinedUsers(launchpadContract).then((res1: any) => {
                          setTotalContributors(res1?.length);
                        });
                        Store.addNotification(addNotify("Bought successfully", "success")); 
                     })();
                        
                    }
                    else {
                        setActionStatus("");
                        if (res?.error?.message) {
                            Store.addNotification(addNotify(res?.error?.message, 'danger'));
                        }
                    }   
                })); 
                if (Number(launchpadRound?.maxBuy) - Number(totalInvestment) === 0) {
                    setActionStatus("BOUGHT");
                } else {
                    setActionStatus("");
                }
                setTotalInvestment(Number(totalInvestment) + Number(valueAmount));
                setRaisedAmount(Number(raisedAmount) + Number(valueAmount));
                setValueAmount(0);
                if (raisedAmount >= hardCap) {
                    setCanFinalize(true);
                }
            }
            else {
				setActionStatus("");
				Store.addNotification(addNotify("Transaction failed", "warning"));
				return;
            }
		})
		.catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
              	Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              	Store.addNotification(addNotify(error.reason, "danger"));
            } else {
				if (error.data && error.data.message) {
					Store.addNotification(addNotify(error.data.message, "danger"));
				}
            }
		});
	})
    .catch((error: any) => {
        setActionStatus("");
        if (error.code == 4001 && error.message) {
            Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
            Store.addNotification(addNotify(error.reason, "danger"));
        } else {
            if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
            }
        }
    });
};

const handleEmergencyWithdraw = async () => {
    setActionStatus("EMERGENCY_WITHDRAW_LOADING");
    await emergencyWithdrawContribute(launchpadContract)
    .then((res: any) => {
        let resWait = res.wait();
        resWait.then((resTransaction: any) => {
            if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
				Store.addNotification(
					addNotify("Emergency withdraw successfully", "success")
				);
				setActionStatus("EMERGENCY_WITHDRAW");
				window.location.reload();
            } else {
				setActionStatus("");
				Store.addNotification(addNotify("Transaction failed", "danger"));
				return;
            }
		})
		.catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
              	Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              	Store.addNotification(addNotify(error.reason, "danger"));
            } else {
				if (error.data && error.data.message) {
					Store.addNotification(addNotify(error.data.message, "danger"));
				}
            }
		});
    })
    .catch((error: any) => {
        setActionStatus("");
        if (error.code == 4001 && error.message) {
            Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
            Store.addNotification(addNotify(error.reason, "danger"));
        } else {
            if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
            }
        }
    });
};
  
  const handleFinalize = async () => {
    setActionStatus("FINALIZE_LOADING");

    await finalizeLaunchpad(launchpadContract)
      .then((res: any) => {
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) { 
               dispatch(finalizeLaunchpadAPI(launchpadDetail?.id, async (res:any) => { 
                  if(res.result === "SUCCESS") {
                      if(res?.data) { 
                          Store.addNotification(addNotify("Finalized launchpad successfully", "success"));
                          setActionStatus("FINALIZED");
                          window.location.reload()
                      }
                  }
                  else {
                      setActionStatus("");
                      if (res?.error?.message) {
                          Store.addNotification(addNotify(res?.error?.message, 'danger'));
                      }
                  }
              }));  
            } else {
              setActionStatus("");
              Store.addNotification(addNotify("Transaction failed", "danger"));
              return;
            }
             setActionStatus("FINALIZED"); 
          })
          .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        setActionStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };

  const handleAffiliate = async () => {
    if (percentAffiliateInput > 5 || percentAffiliateInput < 0) {
      Store.addNotification(
        addNotify("0 < Percent Affiliate <= 5] ", "danger")
      );
      return;
    }
    setActionStatus("AFFILIATE_LOADING");
    await enableAffiliateLaunchpad(
      launchpadContract,
      !checkAffiliate,
      percentAffiliateInput * 100
    )
      .then((res: any) => {
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              if (percentAffiliateInput !== 0) {
                Store.addNotification(
                  addNotify("Enable Affiliate successfully", "success")
                );
                setCheckAffiliate(false);
              } else {
                Store.addNotification(
                  addNotify("Disable Affiliate successfully", "success")
                );
                setCheckAffiliate(true);
              }
              setActionStatus("");
              window.location.reload();
            } else {
              setActionStatus("");
              Store.addNotification(addNotify("Transaction failed", "danger"));
              return;
            }
          })
          .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        setActionStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };

  const handleCancelLaunchpad = async () => {
    setActionStatus("CANCEL_LAUNCHPAD_LOADING");
    await cancelLaunchpad(launchpadContract)
      .then((res: any) => {
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              //tra token 
              (async () => {
                await claimCanceledTokens(launchpadContract).then(
                  (res: any) => {
                    let resWait = res.wait();
                    resWait.then((resTransaction: any) => {
                      if (
                        resTransaction &&
                        resTransaction.status &&
                        resTransaction.blockNumber
                      ) {
                        dispatch(finalizeLaunchpadAPI(launchpadDetail?.id, async (res:any) => { 
                            if(res.result === "SUCCESS") {
                                if(res?.data) { 
                                    Store.addNotification(addNotify("Cancel launchpad successfully", "success"));
                                    setActionStatus("CANCEL_LAUNCHPAD_DONE");
                                    window.location.reload()
                                }
                            }
                            else {
                                setActionStatus("CANCEL_LAUNCHPAD_DONE");
                                if (res?.error?.message) {
                                    Store.addNotification(addNotify(res?.error?.message, 'danger'));
                                }
                            }
                        })); 
                      } else {
                        setActionStatus("CANCEL_LAUNCHPAD_DONE");
                        Store.addNotification(
                          addNotify("Transaction failed", "danger")
                        );
                        return;
                      }
                    });
                  }
                );
              })();
              setActionStatus("CANCEL_LAUNCHPAD_DONE");
            } else {
              setActionStatus("");
              Store.addNotification(addNotify("Transaction failed", "danger"));
              return;
            }
          })
          .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        setActionStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };

  const handleWhitelistPool = async (
    wlPool: number,
    isModal: boolean = false
  ) => {
    let _holdingTokenAddress: any;
    let _amount: any = 0;

    if (wlPool == SALETYPE_WHITELIST_POOL.PUBLIC_ANTIBOT) {
      if (!holdingTokenInput) {
        Store.addNotification(
          addNotify("Please setting: Antibot token address", "danger")
        );
        return;
      }
      if (!ethers.utils.isAddress(holdingTokenInput)) {
        Store.addNotification(
          addNotify("Please enter valid antibot token address", "danger")
        );
        return;
      }

      if (!holdingAmount) {
        Store.addNotification(
          addNotify("Please setting: Antibot min holding amount", "danger")
        );
        return;
      }

      _holdingTokenAddress = holdingTokenInput;
      _amount = holdingAmount;
    } else {
      _holdingTokenAddress = ethers.constants.AddressZero.toLocaleLowerCase();
      _amount = 0;
    }
    setActionWLPStatus("LOADING_" + wlPool);

    await setWhitelistPoolLaunchpad(
      launchpadContract,
      wlPool,
      _holdingTokenAddress,
      _amount,
      tokenInform?.decimals
    )
      .then((res: any) => {
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              (async () => {
                await getOwnerZoneInfo(
                  launchpadContract,
                  account,
                  tokenInform?.decimals
                ).then((res: any) => {
                  setCanCancel(res?.canCancel);
                  setCanFinalize(res?.canFinalize);
                  setIsOwner(res?.isOwner);
                  setWhitelistPoolOwner(Number(res?.whitelistPool));
                  setActionWLPStatus("");
                  setSaleType(wlPool);

                  setHoldingTokenInput("");
                  setHoldingName("");
                  setHoldingSymbol("");
                  setHoldingDecimals("");
                  setHoldingAmount(0);

                  Store.addNotification(
                    addNotify("Whitelist Pool is set successfully", "success")
                  );
                  if (isModal) {
                    setDisableWhitelist(false);
                  }
                });
              })();
            } else {
              setSaleType(Number(whitelistPoolOwner));
              setActionWLPStatus("");
              Store.addNotification(addNotify("Transaction failed", "danger"));
              return;
            }
          })
          .catch((error: any) => {
            setSaleType(Number(whitelistPoolOwner));
            setActionWLPStatus("");
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        setSaleType(Number(whitelistPoolOwner));
        setActionWLPStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };

  const handleAddWhitelist = async () => {
    const _addUsers = addListOfWhiteListUsers.split(/\r?\n/);
    setActionStatus("ADD_WL_LOADING");

    await addWhiteListUsers(launchpadContract, _addUsers)
      .then((res: any) => {
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              setListOfWhiteListUsers(res);
              setActionStatus("");
              setIsAddedWhitelist(false);
              Store.addNotification(
                addNotify("Whitelist is added successfully", "success")
              );
            } else {
              setActionStatus("");
              setIsAddedWhitelist(false);
              Store.addNotification(addNotify("Transaction failed", "danger"));
              return;
            }
          })
          .catch((error: any) => {
            setActionStatus("");
            setIsAddedWhitelist(false);
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        setActionStatus("");
        setIsAddedWhitelist(false);
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };

  const handleRemoveWhitelist = async () => {
    const _removeUsers = removeListOfWhiteListUsers.split(/\r?\n/);

    if ((_removeUsers && _removeUsers.length === 0) || !_removeUsers) {
      Store.addNotification(
        addNotify("Whitelist remove list is empty. Please insert!", "danger")
      );
      return;
    }
    setActionStatus("REMOVE_WL_LOADING");

    await removeWhiteListUsers(launchpadContract, _removeUsers)
      .then((res: any) => {
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              (async () => {
                await getListOfWhiteListUsers(launchpadContract).then(
                  (res: any) => {
                    setListOfWhiteListUsers(res);
                    setActionStatus("");
                    setIsRemovedWhitelist(false);
                    Store.addNotification(
                      addNotify("Whitelist is removed successfully", "success")
                    );
                  }
                );
              })();
            } else {
              setActionStatus("");
              setIsRemovedWhitelist(false);
              Store.addNotification(addNotify("Transaction failed", "danger"));
              return;
            }
          })
          .catch((error: any) => {
            setIsRemovedWhitelist(false);
            setActionStatus("");
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        setIsRemovedWhitelist(false);
        setActionStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };

  const handleWithdrawContribute = async () => {
    setActionStatus("WITHDRAW_CONTRIBUTE_LOADING");
    await withdrawContribute(launchpadContract)
      .then((res: any) => {
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              setTotalInvestment(0);
              setActionStatus("WITHDRAW_CONTRIBUTED");
              Store.addNotification(
                addNotify("Withdraw contribute successfully", "success")
              );
            }
          })
          .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        setActionStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };

  const handleClaimRewardAffiliate = async () => {
    setActionStatus("REWARD_LOADING");
    await withdrawContributeAffiliate(launchpadContract)
      .then((res: any) => {
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              Store.addNotification(
                addNotify("Withdraw successfully", "success")
              );
              setActionStatus("");
              window.location.reload();
            } else {
              setActionStatus("");
              Store.addNotification(addNotify("Transaction failed", "danger"));
              return;
            }
          })
          .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        setActionStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };

  const infoDetail = (
    <>
      <h3>
        <p>
          {launchpadDetail?.name}
          <span>{launchpadDetail?.type}</span>
        </p>
        <p>
          {launchpadDetail?.audit ? <span>Audit</span> : ""}
          {launchpadDetail?.kyc ? <span>KYC</span> : ""}
          {launchpadDetail?.safu ? <span>SAFU</span> : ""}
        </p>
      </h3>
      <ul>
        {launchpadDetail?.socialNetwork?.website ? (
          <li>
            <a href={launchpadDetail?.socialNetwork?.website}>
              <img src="../images/detail-icon-website.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {launchpadDetail?.socialNetwork?.telegram ? (
          <li>
            <a href={launchpadDetail?.socialNetwork?.telegram}>
              <img src="../images/detail-icon-tele.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {launchpadDetail?.socialNetwork?.twitter ? (
          <li>
            <a href={launchpadDetail?.socialNetwork?.twitter}>
              <img src="../images/detail-icon-tw.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {launchpadDetail?.socialNetwork?.medium ? (
          <li>
            <a href={launchpadDetail?.socialNetwork?.medium}>
              <img src="../images/detail-icon-medium.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {launchpadDetail?.socialNetwork?.facebook ? (
          <li>
            <a href={launchpadDetail?.socialNetwork?.facebook}>
              <img src="../images/detail-icon-facebook.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {launchpadDetail?.socialNetwork?.discord ? (
          <li>
            <a href={launchpadDetail?.socialNetwork?.discord}>
              <img src="../images/detail-icon-discord.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {launchpadDetail?.socialNetwork?.youtube ? (
          <li>
            <a href={launchpadDetail?.socialNetwork?.youtube}>
              <img src="../images/detail-icon-youtube.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
      </ul>
    </>
  );

  const onChangeRadio = (e: RadioChangeEvent) => {
    if (e.target.value === 1 && whitelistPoolOwner !== 1) {
      setSaleType(-1);
      handleWhitelistPool(e.target.value);
    } else if (e.target.value === 2 && whitelistPoolOwner !== 2) {
      setSaleType(2);
    } else if (e.target.value === 0) {
      setSaleType(0);
    }
  };

  const changeHoldingToken = (e: any) => {
    setHoldingTokenInput(e.target.value);
  };

  const changeHoldingAmount = (e: any) => {
    setHoldingAmount(e.target.value);
  };

  const handleChangeAddWhitelists = (e: any) => {
    setAddListOfWhiteListUsers(e.target.value);
  };

  const handleChangeRemoveWhitelists = (e: any) => {
    setRemoveListOfWhiteListUsers(e.target.value);
  };

  const handleChangeAffiliate = (e: any) => {
    setPercentAffiliateInput(e.target.value);
  };

  const elmSaleType = () => {
    let elm: any = "";
    if (saleType === SALETYPE_WHITELIST_POOL.PUBLIC) {
      elm = (
        <div className="p-launchpadDetail__selectRadio">
          <Button
            text="Public"
            type="border"
            isLoading={
              actionWLPStatus === "LOADING_" + SALETYPE_WHITELIST_POOL.PUBLIC
                ? true
                : false
            }
            disabled={
              whitelistPoolOwner === SALETYPE_WHITELIST_POOL.PUBLIC
                ? true
                : false
            }
            onClick={() => handleWhitelistPool(SALETYPE_WHITELIST_POOL.PUBLIC)}
          />
        </div>
      );
    } else if (saleType === SALETYPE_WHITELIST_POOL.WHITELIST) {
      elm = (
        <div className="p-launchpadDetail__selectRadio">
          <p className="p-launchpadDetail__txt">
            The whitelist function can be both enabled/disabled during the
            creation of a presale, prior to the beginning of the presale
          </p>
          <div className="p-launchpadDetail__twoBtn">
            <Button
              text="Add Whitelist"
              type="blue"
              onClick={() => setIsAddedWhitelist(true)}
            />
            <Button
              text="Remove Whitelist"
              type="blue"
              onClick={() => setIsRemovedWhitelist(true)}
            />
          </div>
          <Button
            text="List of Whitelist"
            onClick={() => setIsListedWhitelist(true)}
          />
          <Button
            text="Disable Whitelist"
            type="greyBorder"
            onClick={() => setDisableWhitelist(true)}
          />
        </div>
      );
    } else if (saleType === SALETYPE_WHITELIST_POOL.PUBLIC_ANTIBOT) {
      elm = (
        <div className="p-launchpadDetail__selectRadio">
          <p className="p-launchpadDetail__txt">
            With this option you can control who can contribute to the pool.
            Only user who hold a minimum amount of token you suggest would be
            able to contribute
          </p>
          <InputChange
            title="Token Address"
            value={parseInt(holdingToken) === 0 ? "" :holdingToken}
            onChange={(e: any) => changeHoldingToken(e)}
            placeholder="Enter token address"
          />
          {parseInt(holdingToken)? (
            <>
              <div className="infoToken mt-16">
                <p className="p-launchpadDetail__txt">Name</p>
                <p className="c-input__title">
                  {holdingToken?.substring(0, 6) +
                    "..." +
                    holdingToken?.substr(
						holdingToken?.length - 6
                    )}
                </p>
              </div>
              <div className="infoToken">
                <p className="p-launchpadDetail__txt">Symbol</p>
                <p className="c-input__title">{holdingSymbol}</p>
              </div>
              <div className="infoToken">
                <p className="p-launchpadDetail__txt">Decimals</p>
                <p className="c-input__title">{holdingDecimals}</p>
              </div>
            </>
          ) : null}
          <InputChange
            title="Min Holding Amount"
            type="number"
            value={holdingAmount}
            onChange={(e: any) => changeHoldingAmount(e)}
            placeholder="0"
          />
          <Button
            text="Save Setting"
            type="blue"
            isLoading={
              actionWLPStatus ===
              "LOADING_" + SALETYPE_WHITELIST_POOL.PUBLIC_ANTIBOT
                ? true
                : false
            }
            // disabled={
            //   whitelistPoolOwner === SALETYPE_WHITELIST_POOL.PUBLIC_ANTIBOT
            //     ? true
            //     : false
            // }
            onClick={() =>
              handleWhitelistPool(SALETYPE_WHITELIST_POOL.PUBLIC_ANTIBOT)
            }
          />
        </div>
      );
    }
    return elm;
  };

  const onAmount = (e: any) => {
    setValueAmount(e.target.value);
  };

  const rendererTimer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      if (claimTimeEnd > new Date()) {
        window.location.reload();
      }
    } else {
      return (
        <div className="c-countDown">
          <span>{days}d</span>:<span>{zeroPad(hours)}h</span>:
          <span>{zeroPad(minutes)}m</span>:<span>{zeroPad(seconds)}s</span>
        </div>
      );
    }
  };

  const handleClassStatus = () => {
    let classStatus: string = "";

    if (startTimer && endTimer) {
      if (nowTimer < startTimer) {
        classStatus = "is-Upcoming";
      } else if (startTimer < nowTimer && nowTimer < endTimer) {
        classStatus = "is-Opening";
      } else if (endTimer < nowTimer) {
        classStatus = "is-Completed";
      }
    }

    return classStatus;
  };
 
  const getUserWhitelist = () => {
    console.log("whiteListPool", whiteListPool);
    console.log("checkHoldingToken", checkHoldingToken);
    if (whiteListPool === 0) { 
         return true;
    }
    else {
      if(whiteListPool === 2 && checkHoldingToken){ 
        return true; 
      } 
      if (isUserWhitelist) return true;
      else return false;
    }
  };
  const getActionButton = () => {
    let _button: any = "",
      _emergencyWithdrawbutton: any = "";
    let _userWhitelist = getUserWhitelist();

    if (!_userWhitelist) {
      _button = (
        <Button text="You're not whitelist" type="blue" disabled={true} />
      );
    } else {
      if (needApproveToken) {
        if (isApprovedToken) {
          _button = (
            <Button
              text={`${actionStatus === "BOUGHT" ? "Bought" : "Buy Now"}`}
              type="blue"
              onClick={() => handleContribute()}
              isLoading={actionStatus === "BUY_LOADING" ? true : false}
              disabled={
                actionStatus === "BUY_LOADING" || actionStatus === "BOUGHT"
                  ? true
                  : false
              }
            />
          );
        } else {
          _button = (
            <Button
              text={`Approve ${launchpadRound?.currencyPay}`}
              type="blue"
              onClick={() => handleApproveToken()}
              isLoading={actionStatus === "APPROVE_LOADING" ? true : false}
              disabled={actionStatus === "APPROVE_LOADING" ? true : false}
            />
          );
        }
      } else {
        _button = (
          <Button
            text={`${actionStatus === "BOUGHT" ? "Bought" : "Buy Now"}`}
            type="blue"
            onClick={() => handleContribute()}
            isLoading={actionStatus === "BUY_LOADING" ? true : false}
            disabled={
              actionStatus === "BUY_LOADING" || actionStatus === "BOUGHT"
                ? true
                : false
            }
          />
        );
      }

      if (totalInvestment > 0) {
        _emergencyWithdrawbutton = (
          <Button
            text="Urgent Withdrawal"
            type="red"
            onClick={() => handleEmergencyWithdraw()}
            isLoading={
              actionStatus === "EMERGENCY_WITHDRAW_LOADING" ? true : false
            }
            disabled={
              actionStatus === "EMERGENCY_WITHDRAW_LOADING" ||
              actionStatus === "EMERGENCY_WITHDRAW"
                ? true
                : false
            }
          />
        );
      }
    }

    return (
      <>
        {_button} {_emergencyWithdrawbutton}
      </>
    );
  };

  let claimTimeEnd: any; 
  
  const launchpadAction = () => {
    let _userWhitelist = getUserWhitelist();
    if (launchpadState === 1) {
      if (nowTimer < startTimer) {
        progressPercent = 0;
        statusText = "Upcoming";
        progressTime = startTimer;
      } else if (startTimer <= nowTimer && nowTimer <= endTimer) {
        if (raisedAmount >= launchpadDetail?.hardCap) {
          statusText = "This pool has filled";
          progressTime = null;
          if (!_userWhitelist) {
            actionButton = (
              <Button text="You're not whitelist" type="blue" disabled={true} />
            );
          }
        } else {
          statusText = "Opening";
          progressTime = endTimer;
          contributeInput = (
            <>
              <div className="c-input">
                <p className="c-input__title">
                  Amount (Max:{" "}
                  {formatNumber(
                     launchpadRound?.maxBuy
                  )}{" "}
                  {launchpadRound?.currencyPay})
                </p>
                <div className="c-input__max">
                  <input
                    type="text"
                    disabled={
                      Number(
                        formatNumber(
                          launchpadRound?.maxBuy - Number(totalInvestment)
                        )
                      ) < Number(launchpadRound?.minBuy)
                        ? true
                        : false
                    }
                    onWheel={(event) => event.currentTarget.blur()}
                    value={valueAmount}
                    onChange={(e) => onAmount(e)}
                  />
                  <span
                    onClick={() =>
                      setValueAmount(
                        Number(
                          formatNumber(launchpadRound?.maxBuy - Number(totalInvestment))
                        )
                      )
                    }
                  >
                    Max
                  </span>
                </div>
              </div>
              <p className="c-txt">
                Minimum of ICO: { launchpadRound?.minBuy ? formatNumber(launchpadRound?.minBuy) : 0}{" "}
                {launchpadRound?.currencyPay}
              </p>
              <p className="c-txt">
                Available: {availableAmount ? formatNumber(availableAmount) : 0}{" "}
                {launchpadRound?.currencyPay}
              </p>
            </>
          );
          actionButton = getActionButton();
        }
        progressPercent = launchpadDetail?.hardCap && Number(launchpadDetail?.hardCap) !== 0 ? formatNumber((Number(raisedAmount) / parseFloat(launchpadDetail?.hardCap)) * 100) : 0;
      } else if (endTimer < nowTimer) {
        progressPercent = launchpadDetail?.hardCap && Number(launchpadDetail?.hardCap) !== 0 ? formatNumber((Number(raisedAmount) / parseFloat(launchpadDetail?.hardCap)) * 100) : 0;
        statusText = "Ended";
        progressTime = null;

        if (!_userWhitelist) {
          actionButton = (
            <Button text="You're not whitelist" type="blue" disabled={true} />
          );
        } else {
          if (totalInvestment === 0) {
            actionButton = (
              <Button text="You're not join" type="blue" disabled={true} />
            );
          } else {
            actionButton = totalInvestment > 0 &&
              !refund &&
              Number(raisedAmount) < Number(softCap) && (
                <Button
                  text="Withdraw Contribute"
                  type="blue"
                  onClick={() => handleWithdrawContribute()}
                  isLoading={
                    actionStatus === "WITHDRAW_CONTRIBUTE_LOADING"
                      ? true
                      : false
                  }
                  disabled={
                    refund ||
                    actionStatus === "WITHDRAW_CONTRIBUTE_LOADING" ||
                    actionStatus === "WITHDRAW_CONTRIBUTED"
                      ? true
                      : false
                  }
                />
              );
          }
        }
      }
    } else if (launchpadState === 2) {
        progressPercent = launchpadDetail?.hardCap && Number(launchpadDetail?.hardCap) !== 0 ? formatNumber((Number(raisedAmount) / parseFloat(launchpadDetail?.hardCap)) * 100): 0;
        if (listingTime) {
            let _claimTimeTge: any = new Date(listingTime);
            let _firstReleasePercent = Number(launchpadDetail?.vestingContributor?.firstReleasePercent);
            let _tokenReleaseEachCycle = Number(launchpadDetail?.vestingContributor?.tokenReleaseEachCycle);
            
            // _cliffVesting: khoang tg tu luc listing den luc claim TGE
            let _cliffVesting = Number(launchpadDetail?.vestingContributor?.cliffVesting);

            // _lockAfterCliffVesting : khoang tg tu luc TGE cho den claim lan 2
            let _lockAfterCliffVesting = Number(launchpadDetail?.vestingContributor?.lockAfterCliffVesting);

            // _vestingPeriodEachCycle: khoang tg giua cac lan claim ke tu lan thu 2 tro di
            let _vestingPeriodEachCycle:any = Number(launchpadDetail?.vestingContributor?.vestingPeriodEachCycle);

            if (_cliffVesting > 0) {
                _claimTimeTge.setMinutes(_claimTimeTge.getMinutes() + _cliffVesting);
            }
            claimTimeEnd = new Date(_claimTimeTge);
            let _claimCount: any = (100 - _firstReleasePercent) / _tokenReleaseEachCycle;
            claimTimeEnd.setMinutes(claimTimeEnd.getMinutes() + _lockAfterCliffVesting + _claimCount * _vestingPeriodEachCycle);

            if (nowTimer <= _claimTimeTge) {
                statusText = "Claim starts in";
                progressTime = _claimTimeTge;
            } else {
                if (_firstReleasePercent !== 100) {
                    let _nextClaimAfterCliffVesting: any = new Date(_claimTimeTge);
                    _nextClaimAfterCliffVesting.setMinutes(_nextClaimAfterCliffVesting.getMinutes() + _lockAfterCliffVesting);
                    let tgeTokens: any =(_firstReleasePercent * totalUserTokens) / 100;
                
                    if (_vestingPeriodEachCycle > 0) {
                        if ((claimedTokens + totalClaimable) <= tgeTokens && _nextClaimAfterCliffVesting >= new Date()) {
                            statusText = "Next claim starts in";
                            progressTime = _nextClaimAfterCliffVesting;
                        } 
                        else if (claimedTokens + totalClaimable <= tgeTokens && _nextClaimAfterCliffVesting < new Date()) {
                            statusText = "Finished";
                            progressTime = null;
                        } 
                        else if (tgeTokens < +(claimedTokens + totalClaimable) && +(claimedTokens + totalClaimable) <= totalUserTokens) {
                            let _totalClaimedpercent: any = _firstReleasePercent;
                            let _nextClaimTime: any;
                            _nextClaimTime = new Date(_nextClaimAfterCliffVesting);

                            while (_totalClaimedpercent < 100) {
                                _totalClaimedpercent += _tokenReleaseEachCycle;
                                _nextClaimTime.setMinutes(_nextClaimTime.getMinutes() + _vestingPeriodEachCycle);
                            }
                            progressTime = _nextClaimTime;

                            if (claimedTokens + totalClaimable === totalUserTokens) {
                                statusText = "Finished";
                            } else {
                                statusText = "Next claim starts in";
                            }
                        } else {
                            statusText = "Finished";
                            progressTime = null;
                        }
                    } else {
                        statusText = "Data invalid";
                        progressTime = null;
                    }
                } else {
                    statusText = "Finished";
                    progressTime = null;
                }
        }
    } else {
        statusText = "Finalized";
        progressTime = null;
    }

    if (!_userWhitelist) {
        actionButton = (<Button text="You're not whitelist" type="blue" disabled={true} />);
    } else {
        if (totalInvestment === 0) {
            actionButton = (
                <Button text="You're not join" type="blue" disabled={true} />
            );
        } else {
            if (raisedAmount >= softCap) {
                actionButton = (
                <Button
                    text={`${
                        totalClaimable > 0
                        ? `Claim Token (${totalClaimable.toLocaleString()} ${
                            tokenInform?.symbol
                        })`
                        : `Claimed (${claimedTokens.toLocaleString()}/${totalUserTokens.toLocaleString()} ${
                            tokenInform?.symbol
                        })`
                    }`}
                    type="blue"
                    onClick={() => handleClaimTokens()}
                    isLoading={actionStatus === "CLAIM_LOADING" ? true : false}
                    disabled={!totalClaimable || actionStatus === "CLAIM_LOADING" || actionStatus === "CLAIMED" ? true : false}
                />
                );
        } else {
            actionButton = totalInvestment > 0 && !refund && (
              <Button
                text="Withdraw Contribute"
                type="blue"
                onClick={() => handleWithdrawContribute()}
                isLoading={
                  actionStatus === "WITHDRAW_CONTRIBUTE_LOADING" ? true : false
                }
                disabled={
                  refund ||
                  actionStatus === "WITHDRAW_CONTRIBUTE_LOADING" ||
                  actionStatus === "WITHDRAW_CONTRIBUTED"
                    ? true
                    : false
                }
              />
            );
          }
        }
      }
    } else if (launchpadState === 3) {
      progressPercent = 0;
      statusText = "Canceled";
      progressTime = null;
      actionButton =
        totalInvestment > 0 && !refund ? (
          <>
            <Button
              text="Withdraw Contribute"
              type="blue"
              onClick={() => handleWithdrawContribute()}
              isLoading={
                actionStatus === "WITHDRAW_CONTRIBUTE_LOADING" ? true : false
              }
              disabled={
                refund ||
                actionStatus === "WITHDRAW_CONTRIBUTE_LOADING" ||
                actionStatus === "WITHDRAW_CONTRIBUTED"
                  ? true
                  : false
              }
            />
          </>
        ) : (
          ""
        );
    }

    return (
      <>
        <h3>
          <span>{statusText}</span>
          {progressTime && (
            <Countdown date={progressTime} renderer={rendererTimer} />
          )}
        </h3>
        {account ? (
          <>
            {isSwitch ? (
              <div className="switch-network">
                <button
                  className="btn-switch-network"
                  onClick={handleSwitchNetwork}
                >
                  Switch Network
                </button>
              </div>
            ) : (
              <>
                <div className="c-progress">
                  <p className="c-progress__title">
                    Progress ({progressPercent}%)
                  </p>
                  <div className="c-progress__bar">
                    <span style={{ width: progressPercent + "%" }}></span>
                  </div>
                  <p className="c-progress__txt">
                    {formatNumber(raisedAmount)} {launchpadRound?.currencyPay}
                    <span>
                      {formatNumber(launchpadDetail?.hardCap)}{" "}
                      {launchpadRound?.currencyPay}
                    </span>
                  </p>
                </div>
                {contributeInput}
                <div
                  className="p-launchpadDetail__btnStatus"
                  style={{ marginTop: !(nowTimer <= endTimer) ? "15px" : 0 }}
                >
                  {actionButton}
                </div>
              </>
            )}
          </>
        ) : (
          <div className="no-connect">
              Not Connect Wallet
          </div>
        )}
      </>
    );
  };
 
  const handleCopy = () => {
    Store.addNotification(addNotify("Copied !", "success"));
  };

  useEffect(() => {
    axios
      .get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=binancecoin"
      )
      .then((res) => {
        setPriceBNB(res.data[0].current_price);
      });
  }, []);

  const marketcap =
    launchpadDetail?.currency === "BNB"
      ? formatNumber(
          (1 / (launchpadRound?.listingRate / priceBNB)) *
            tokenInform?.totalSupply
        )
      : formatNumber(
          (1 / launchpadRound?.listingRate) * tokenInform?.totalSupply
        );

  const _listOfWhiteListUsers =
    listOfWhiteListUsers &&
    listOfWhiteListUsers.length > 0 &&
    listOfWhiteListUsers.map((item: any) => {
      return <p>{item}</p>;
    });

  const _listUserAffiliate =
    listUserAffiliate &&
    listUserAffiliate.length > 0 &&
    listUserAffiliate.map((item: any) => {
      return <p>{item}</p>;
    });

  const _listAmountAffiliate =
    listAmountAffiliate &&
    listAmountAffiliate.length > 0 &&
    listAmountAffiliate.map((item: any) => {
      return <p>{formatNumberAmount(Number(convertFromWei(item, tokenInform?.decimals))* percertAffiliate /100, 0 ,9) }</p>;
    });

  const handleNetwork = (network: any) => {
    let networkName: any = "";
    if (network === "BSCTESTNET") {
      networkName = (
        <span className="is-network">
          <img src="../images/icon-bnb.svg" alt="" />
          BSC TESTNET
        </span>
      );
    } else if (network === "BSC") {
      networkName = (
        <span className="is-network">
          <img src="../images/icon-bnb.svg" alt="" />
          Binance Smart Chain
        </span>
      );
    } else if (network === "ETH") {
      networkName = (
        <span className="is-network">
          <img src="../images/icon-eth.svg" alt="" />
          Ethereum
        </span>
      );
    } else if (network === "POLY") {
      networkName = (
        <span className="is-network">
          <img src="../images/icon-polygon.svg" alt="" />
          Polygon
        </span>
      );
    } else if (network === "ARBTESTNET") {
      networkName = (
        <span className="is-network">
          <img src="../images/arb-icon.svg" alt="" />
          ARB TESTNET
        </span>
      );
    } else if (network === "ARB") {
      networkName = (
        <span className="is-network">
          <img src="../images/arb-icon.svg" alt="" />
          Arbitrum
        </span>
      );
    }

    return networkName;
  };

  const handleLinkNetwork = (network: any) => {
    let link: any = "";
    if (network === "BSCTESTNET") {
      link = "https://testnet.bscscan.com/address/";
    } else if (network === "BSC") {
      link = "https://bscscan.com/address/";
    } else if (network === "ETH") {
      link = "";
    } else if (network === "POLY") {
      link = "";
    } else if (network === "ARBTESTNET") {
      link = "https://goerli.arbiscan.io/address/";
    } else if (network === "ARB") {
      link = "https://arbiscan.io/address/";
    }
    return link;
  };
  
  const handleSetListingTime = async () => {
    if (launchpadState !== 2) {
      Store.addNotification(
        addNotify(
          "Please finalize launchpad before set listing time!",
          "warning"
        )
      );
      return;
    }
    setActionStatus("SET_LISTING_TIME_LOADING");
    const listingTime = Math.floor(
      new Date(listingTimeDate?._d.toISOString()).getTime() / 1000
    );

    await setClaimTime(launchpadContract, listingTime)
      .then((res: any) => {
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              Store.addNotification(
                addNotify("Set claim time successfully", "success")
              );
              setActionStatus("SET_LISTING_TIME_DONE");
              window.location.reload();
            } else {
              setActionStatus("");
              Store.addNotification(addNotify("Transaction failed", "danger"));
              return;
            }
          })
          .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        setActionStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };

  useEffect(() => {
    if(launchpadLoading === false) {
      setTimeout(() => {
        setPageLoading(false);
      }, 4000);
    }
  }, [launchpadLoading]);
 
  return (
    <>
      {pageLoading && <Loading />}
      <Form name="basic" autoComplete="off" form={form}>
      <div className="p-launchpadDetail">
        <div className="c-breadcrumb">
          <ul>
            <li>
              <span>LaunchPads</span>
            </li>
            <li>
              <Link to={`/launchpad/list`}>LaunchPads List</Link>
            </li>
            <li>
              <span>{launchpadDetail?.name}</span>
            </li>
          </ul>
        </div>
        <div className="p-launchpadDetail__banner">
          <img
            src={
              launchpadDetail?.bannerUrl
                ? launchpadDetail?.bannerUrl
                : "../images/bgDefault.svg"
            }
            alt={launchpadDetail?.name}
          />
          <h2 className="p-launchpadDetail__logo">
            <img
              src={
                launchpadDetail?.logoUrl
                  ? launchpadDetail?.logoUrl
                  : "../images/logoDefault.svg"
              }
              alt={launchpadDetail?.name}
            />
            {launchpadDetail?.name}
          </h2>
          {isOwner && (
            <Button
              text="Edit Launchpad Info"
              iconLeft="Edit"
              onClick={() => navigate(`/launchpad-edit/${handle}`)}
            />
          )}
        </div>
        <div className="p-launchpadDetail__detail">
          <div className="p-launchpadDetail__infoSC">
            {size.width <= 767 ? (
              <div className="p-launchpadDetail__info">{infoDetail}</div>
            ) : (
              ""
            )}
            <div className={`p-launchpadDetail__status ${handleClassStatus()}`}>
              {launchpadAction()}
            </div>
            <div className={`p-launchpadDetail__boxFixed`}>
              <p>
                Sale Type
                <span>
                  {(whiteListPool == 0 || whiteListPool == '')
                    ? "Public"
                    : whiteListPool == 1
                    ? "Whitelist"
                    : whiteListPool == 2
                    ? "Public Anti-Bot"
                    : ""}
                </span>
              </p>
              <p>
                Soft Cap
                <span>
                  {launchpadDetail?.softCap ? formatNumber(launchpadDetail?.softCap) : ''} {launchpadRound?.currencyPay}
                </span>
              </p>
              <p>
                Hard Cap
                <span>
                  {launchpadDetail?.hardCap ? formatNumber(launchpadDetail?.hardCap) : ''}{" "}
                  {launchpadRound?.currencyPay}
                </span>
              </p>
              {vestingContributor?.firstReleasePercent === 100 ? (
                <p>
                  Vesting For Presale<span>First Release 100%</span>
                </p>
              ) : (
                <p>
                  Vesting For Presale
                  <span>
                    {vestingContributor?.tokenReleaseEachCycle}% each{" "}
                    {vestingContributor?.vestingPeriodEachCycle} minutes
                  </span>
                </p>
              )}

              <p>
                Total Contributors<span>{formatNumber(totalContributors)}</span>
              </p>
              <p>
                Your Purchases
                <span>
                  {formatNumber(totalInvestment)} {launchpadRound?.currencyPay}
                </span>
              </p>
              <p>
                Total Claimed
                <span>
                  {formatNumber(claimedTokens)} {tokenInform?.symbol}
                </span>
              </p>
            </div>
          </div>
          <div className="p-launchpadDetail__content">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Pool Information" key="1">
                <div className="p-launchpadDetail__info">
                  {size.width <= 767 ? "" : infoDetail}
                  <div className="p-launchpadDetail__boxInfo">
                    <p className="p-launchpadDetail__row">
                      Start time
                      <span>{formatDate(launchpadDetail?.startTime)}</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      End time
                      <span>{formatDate(launchpadDetail?.endTime)}</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Presale address
                      {launchpadRound?.presaleAddress ? (
                        <span>
                          <a
                            target="_blank"
                            href={`${handleLinkNetwork(
                              launchpadDetail?.networkChain
                            )}${launchpadRound?.presaleAddress}`}
                          >
                            {launchpadRound?.presaleAddress?.substring(0, 4) +
                              "..." +
                              launchpadRound?.presaleAddress?.substr(
                                launchpadRound?.presaleAddress?.length - 4
                              )}
                          </a>
                          <CopyToClipboard
                            text={launchpadRound?.presaleAddress}
                            onCopy={() => handleCopy()}
                          >
                            <button>
                              <img src="../images/icon-copy.svg" alt="" />
                            </button>
                          </CopyToClipboard>
                        </span>
                      ) : (
                        <span>--</span>
                      )}
                    </p>
                    <p className="p-launchpadDetail__row">
                      Network{handleNetwork(launchpadRound?.networkChain)}
                    </p>
                    <p className="p-launchpadDetail__row">
                      Token for presale
                      <span>
                        {formatNumber(tokenForPreasle)} {tokenInform?.symbol}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Token for liquidity
                      <span>
                        {formatNumber(tokenForLiquidity)} {tokenInform?.symbol}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Presale rate
                      <span>
                        1 {launchpadRound?.currencyPay} ={" "}
                        {launchpadRound?.swapRate} {tokenInform?.symbol}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      First release for presale
                      <span>
                        {formatData(
                          vestingContributor?.firstReleasePercent,
                          "%"
                        )}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Cliff vesting (Minutes)
                      <span>
                        {formatData(vestingContributor?.cliffVesting, "")}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Lock after cliff vesting (Minutes)
                      <span>
                        {formatData(
                          vestingContributor?.lockAfterCliffVesting,
                          ""
                        )}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Vesting period each cycle (Minutes)
                      <span>
                        {formatData(
                          vestingContributor?.vestingPeriodEachCycle,
                          ""
                        )}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Presale token release each cycle
                      <span>
                        {formatData(
                          vestingContributor?.tokenReleaseEachCycle,
                          "%"
                        )}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Listing rate
                      <span>
                        1 {launchpadRound?.currencyPay} ={" "}
                        {launchpadRound?.listingRate} {tokenInform?.symbol}
                      </span>
                    </p>
                    {/* <p className="p-launchpadDetail__row">
                      Initial marketcap (Estimate)<span>${marketcap}</span>
                    </p> */}
                    <p className="p-launchpadDetail__row">
                      Soft cap
                      <span>
                        {launchpadDetail?.softCap ? formatNumber(launchpadDetail?.softCap) : ''} {launchpadRound?.currencyPay}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Hard cap
                      <span>
                        {launchpadDetail?.hardCap ? formatNumber(launchpadDetail?.hardCap) : ''}{" "}
                        {launchpadRound?.currencyPay}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Unsold tokens<span>{launchpadRound?.refundType}</span>
                    </p>
					{launchpadDetail?.listingOptions !== "MANUAL" && <>
                    <p className="p-launchpadDetail__row">
                      Listing on<span>{dexInfo?.name}</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Liquidity percent
                      <span>{launchpadRound?.liquidityPercent || "--"}%</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Liquidity lockup time
                      <span>
                        {launchpadRound?.liquidityLockup || "--"} minutes after
                        pool ends
                      </span>
                    </p>
					</>}
                  </div>
                  {checkAffiliate && (
                    <div className="p-launchpadDetail__chart">
                      <h3>Affiliate Program</h3>
                      <p className="p-launchpadDetail__row">
                        Affiliate Link
                        <span>
                          {account &&
                            `bluesale.finance...${ 
                              account?.substring(account?.length - 4)
                            }`} 
                          <CopyToClipboard
                            text={`${process.env.REACT_APP_URL}launchpad/${launchpadDetail?.handle}?refID=${account}`}
                            onCopy={() => handleCopy()}
                          >
                            <button>
                              <img src="../images/icon-copy.svg" alt="" />
                            </button>
                          </CopyToClipboard>
                        </span>
                      </p>
                      <div className="your-rewards">
                        <p className="p-launchpadDetail__row">Your Rewards </p>
                        <Button
                          text={ affiliateValue?.totalRefAmount === 0 && launchpadState === 2 && affiliateValue?.yourReward ? "Claimed" :`Claim ${affiliateValue?.yourReward ?? 0} ${launchpadRound?.currencyPay}`}
                          type="blue"
                          isLoading={
                            actionStatus === "REWARD_LOADING" ? true : false
                          }
                          disabled={
                            (affiliateValue?.totalRefAmount === 0 || launchpadState !== 2) ||
                            actionStatus === "REWARD_LOADING"
                              ? true
                              : false
                          }
                          onClick={() => handleClaimRewardAffiliate()}
                        />
                      </div>
                      <p className="p-launchpadDetail__row">
                        Pool Referrer Count{" "}
                        <span>{Number(affiliateValue?.poolReferrerCount ?? 0)}</span>
                      </p>
                      <p className="p-launchpadDetail__row">
                        Reward Percentage<span>{percertAffiliate ?? 0}%</span>
                      </p>
                      <p className="p-launchpadDetail__row">
                        Total Rewards<span>{formatNumberAmount(Number(affiliateValue?.totalRefAmount * percertAffiliate) / 100, 0 ,9)  ?? 0} {launchpadRound?.currencyPay}</span>
                      </p>
                      <div className="verifyToken affiliate-row"> 
                        <p className="p-launchpadDetail__row">
                          Max Rewards<span>{formatNumberAmount(Number(hardCap * percertAffiliate / 100), 0 ,9) ?? 0} {launchpadRound?.currencyPay}</span>  
                        </p>
                         <span className="swaprate assume">assume hardcap reached</span> 
                      </div> 
                      <p className="p-launchpadDetail__row">
                        Total Ref Amount
                        <span>{affiliateValue?.totalRefAmount ?? 0} {launchpadRound?.currencyPay}</span>
                      </p>
                      <div className="p-launchpadDetail__selectRadio">
                        <Button
                          text="List of Top Rewards"
                          onClick={() => setIsListedOfTopRewards(true)}
                        />
                      </div>
                    </div>
                  )}

                  <div className="p-launchpadDetail__chart">
                    <h3>Token Metrics</h3>
                    <Doughnut data={data} options={chartOptions} />
                  </div>
                </div>
              </TabPane>
              <TabPane tab="About the Project" key="2">
                <div className="p-launchpadDetail__info">
                  {size.width <= 767 ? "" : infoDetail}
                  <p className="p-launchpadDetail__txt">
                    {launchpadDetail?.info}
                  </p>
                </div>
              </TabPane>
              <TabPane tab="Token Information" key="3">
                <div className="p-launchpadDetail__info">
                  {size.width <= 767 ? "" : infoDetail}
                  <div className="p-launchpadDetail__boxInfo">
                    <p className="p-launchpadDetail__row">
                      Name<span>{tokenInform?.name}</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Symbol<span>{tokenInform?.symbol}</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Decimals<span>{tokenInform?.decimals}</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Address
                      {tokenInform?.tokenAddress ? (
                        <span>
                          <a
                            target="_blank"
                            href={`${handleLinkNetwork(
                              tokenInform?.networkChain
                            )}${tokenInform?.tokenAddress}`}
                          >
                            {tokenInform?.tokenAddress?.substring(0, 4) +
                              "..." +
                              tokenInform?.tokenAddress?.substr(
                                tokenInform?.tokenAddress?.length - 4
                              )}
                          </a>
                          <CopyToClipboard
                            text={tokenInform?.tokenAddress}
                            onCopy={() => handleCopy()}
                          >
                            <button>
                              <img src="../images/icon-copy.svg" alt="" />
                            </button>
                          </CopyToClipboard>
                        </span>
                      ) : (
                        <span>--</span>
                      )}
                    </p>
                    <p className="p-launchpadDetail__row">
                      Total Supply
                      <span>{formatNumber(tokenInform?.totalSupply)}</span>
                    </p>
                  </div>
                  {vestingTeam && vestingTeam.length > 0 && (
                    <div className="p-launchpadDetail__team">
                      <h3>
                        Lock Records
                        <Link
                          to={`/lock-records/${tokenInform?.tokenAddress}?type=token&launchpad=${launchpadRound?.presaleAddress}`}
                        >
                          View
                          <img src="../images/arrow-link.png" alt="" />
                        </Link>
                      </h3>
                      <table>
                        <thead>
                          <tr>
                            {/* <th>Name</th> */}
                            <th>Amount</th>
                            <th>Cycle</th>
                            <th>TGE</th>
                            <th>
                              Unlocked
                              <br />
                              Time (UTC)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {vestingTeam.length > 0 &&
                            vestingTeam.map((item: any) => (
                              <tr>
                                <td>{formatNumber(item?.amount)}</td>
                                <td>
                                  {item?.cycleReleasePercent}% each{" "}
                                  {item?.vestingCycle} minutes
                                </td>
                                <td>{item?.tgePercent}%</td>
                                <td>{formatDate(item?.tgeDate)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </TabPane>
              {isOwner && (
                <TabPane tab="Owner Zone" key="4">
                  <div className="p-launchpadDetail__info">
                    {size.width <= 767 ? "" : infoDetail}
                    {notification && (
                      <p className="c-notification">
                        <button onClick={() => setNotification(false)}>
                          <img src="../images/icon-close-12px.svg" alt="" />
                        </button>
                        To make sure there will be no issues during the presale
                        time, please do not send tokens to wallets before you
                        finalize the presale pool.
                        <span>Pool fee: 5% {launchpadRound?.currencyPay} raised only</span>
                      </p>
                    )}
                    <div className="p-launchpadDetail__boxInfo">
                      <h3>Sale Type</h3>
                      <div className="p-launchpadDetail__radio">
                        <Radio.Group
                          className="c-radioEdit"
                          onChange={onChangeRadio}
                          value={saleType}
                          // disabled={startTimer <= nowTimer ? true : false}
                        >
                          <Radio value={0}>Public</Radio>
                          {saleType === -1 ? (
                            <Radio disabled={true} value={1}>
                              Whitelist{" "}
                            </Radio>
                          ) : (
                            <Radio value={1}>
                              Whitelist{" "}
                              {actionWLPStatus ===
                              "LOADING_" + SALETYPE_WHITELIST_POOL.WHITELIST ? (
                                <Spin
                                  size="small"
                                  style={{ position: "absolute" }}
                                />
                              ) : (
                                ""
                              )}{" "}
                            </Radio>
                          )}

                          <Radio value={2}>Public Anti-Bot</Radio>
                        </Radio.Group>
                      </div>
                      {elmSaleType()}
                    </div>
                    <div className="p-launchpadDetail__boxInfo mt-16">
                      <h3>
                        Pool Actions
                        <span>
                          To Finalize presale, you have to exclude token
                          transfer fee for presale contract
                        </span>
                      </h3>
                      <div className="p-launchpadDetail__btn">
                        <Button
                          text="Cancel"
                          type="greyBorder"
                          isLoading={
                            actionStatus === "CANCEL_LAUNCHPAD_LOADING"
                              ? true
                              : false
                          }
                          disabled={
                            (isOwner && !canCancel) ||
                            actionStatus === "CANCEL_LAUNCHPAD_LOADING" ||
                            actionStatus === "CANCEL_LAUNCHPAD_DONE"
                              ? true
                              : false
                          }
                          onClick={() => handleCancelLaunchpad()}
                        />
                        <Button
                          isLoading={
                            actionStatus === "AFFILIATE_LOADING" ? true : false
                          }
                          disabled={
                            (isOwner && launchpadState !== 1) || 
                            actionStatus === "AFFILIATE_LOADING" ? true : false
                          }
                          text={`${
                            checkAffiliate ? "Disable" : "Enable"
                          }  Affiliate Program`}
                          onClick={() =>
                            checkAffiliate
                              ? handleAffiliate()
                              : setCheckAffiliateBtn(true)
                          }
                        />
                        {!checkAffiliateBtn ? null : (
                          <div className="p-launchpadDetail__selectRadio affiliate-value">
                            <Input
                              status={
                                percentAffiliateInput < 0 || percentAffiliateInput > 5
                                  ? "error"
                                  : ""
                              }
                              className="c-dateEdit"
                              placeholder="Precent Affiliate"
                              onChange={(e) => handleChangeAffiliate(e)}
                            />
                            <Button
                              disabled={
                                actionStatus === "AFFILIATE_LOADING"
                                  ? true
                                  : false
                              }
                              text="OK"
                              type="blue"
                              onClick={() => handleAffiliate()}
                            />
                          </div>
                        )}

                        <Button
                          text="Finalize"
                          type="blue"
                          isLoading={
                            actionStatus === "FINALIZE_LOADING" ? true : false
                          }
                          disabled={
                            (isOwner && !canFinalize) ||
                            (isOwner && launchpadState !== 1) || 
                            (isOwner &&
                              canFinalize &&
                              (actionStatus === "FINALIZED" ||
                                actionStatus === "FINALIZE_LOADING"))
                              ? true
                              : false
                          }
                          onClick={() => handleFinalize()}
                        />
                      </div>
                    </div>
                    {launchpadDetail?.listingOptions === "MANUAL" && (
                      <div className="p-launchpadDetail__boxInfo mt-16">
                        <h3>
                          Set Listing Time
                          <span>For manual listing</span>
                        </h3>
                        <div className="p-launchpadDetail__selectRadio">
                          <p className="c-ttl">Set Listing Time</p>
                          {listingTime ? <InputChange value={getFormattedDate(listingTime)} disabled={true}/> :  
                           <Form.Item name="selectTime" rules={[
                                { required: true, message: `Please input your contribution time` },
                                {
                                    validator: (rule, value, cb: (msg?: string) => void) => {
                                        !value || new Date(value) <= new Date(Date.now())
                                            ? cb("Start time needs to be after now")
                                            : cb();
                                    }
                                }
                            ]}> 
                                <DatePicker
                                  className="c-dateEdit"
                                  showTime={true}
                                  placeholder="Select time"
                                  onChange={(e: any) => setListingTimeDate(e)} 
                                />
                            </Form.Item>
                      }
                          <Button
                            text="Set Listing Time"
                            type="blue"
                            onClick={() => handleSetListingTime()}
                            isLoading={actionStatus === "SET_LISTING_TIME_LOADING" ? true : false}
                            disabled={actionStatus === "SET_LISTING_TIME_LOADING" || actionStatus === "SET_LISTING_TIME_DONE" || listingTime}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </TabPane>
              )}
            </Tabs>
          </div>
        </div>
      </div>

      {isAddedWhitelist && (
        <div className="c-popup">
          <div className="c-popup__content is-small">
            <h3 className="c-popup__title">Add Users To Whitelist</h3>
            <div className="c-popup__box">
              <div className="c-input">
                <p className="c-input__title">User wallet address</p>
                <textarea
                  placeholder="0x"
                  onChange={(e) => handleChangeAddWhitelists(e)}
                ></textarea>
              </div>
            </div>
            <div className="c-popup__btn">
              <Button
                text="Cancel"
                type="greyBorder"
                onClick={() => setIsAddedWhitelist(false)}
              />
              <Button
                text="Add Address"
                type="blue"
                onClick={() => handleAddWhitelist()}
                isLoading={actionStatus === "ADD_WL_LOADING" ? true : false}
                disabled={actionStatus === "ADD_WL_LOADING" ? true : false}
              />
            </div>
          </div>
        </div>
      )}

      {isRemovedWhitelist && (
        <div className="c-popup">
          <div className="c-popup__content is-small">
            <h3 className="c-popup__title">Remove Users To Whitelist</h3>
            <div className="c-popup__box">
              <div className="c-input">
                <p className="c-input__title">User wallet address</p>
                <textarea
                  placeholder="0x"
                  onChange={(e) => handleChangeRemoveWhitelists(e)}
                ></textarea>
              </div>
            </div>
            <div className="c-popup__btn">
              <Button
                text="Cancel"
                type="greyBorder"
                onClick={() => setIsRemovedWhitelist(false)}
              />
              <Button
                text="Remove Address"
                type="blue"
                onClick={() => handleRemoveWhitelist()}
                isLoading={actionStatus === "REMOVE_WL_LOADING" ? true : false}
                disabled={actionStatus === "REMOVE_WL_LOADING" ? true : false}
              />
            </div>
          </div>
        </div>
      )}

      {isListedWhitelist && (
        <div className="c-popup">
          <div className="c-popup__content is-btnAbsolute">
            <h3 className="c-popup__title">List Of Whitelist</h3>
            <SearchChange placeholder="Search wallet address" />
            <p className="c-ttl">Wallet address</p>
            <div className="c-popup__whitelist">{_listOfWhiteListUsers}</div>
            <div className="c-popup__btn button-100">
              <Button
                text="Cancel"
                type="greyBorder"
                onClick={() => setIsListedWhitelist(false)}
              />
            </div>
          </div>
        </div>
      )}

      {isListedOfTopRewards && (
        <div className="c-popup">
          <div className="c-popup__content is-btnAbsolute">
            <h3 className="c-popup__title">List Of Top Rewards</h3>
            {/* <SearchChange placeholder='Search wallet address'/> */}
            <div className="flex-sp mb-0">
              <p className="c-ttl">Wallet address</p>
              <p className="c-ttl">Rewards</p>
            </div>
            <div className="flex-sp mb-0">
              <div className="c-popup__whitelist affiliate-list">
                {_listUserAffiliate}
              </div>
              <div className="c-popup__whitelist affiliate-list">
                {_listAmountAffiliate }
              </div>
            </div>

            <div className="c-popup__btn button-100">
              <Button
                text="Cancel"
                type="greyBorder"
                onClick={() => setIsListedOfTopRewards(false)}
              />
            </div>
          </div>
        </div>
      )}

      {disableWhitelist && (
        <div className="c-popup">
          <div className="c-popup__content is-w400">
            <div className="c-popup__icon">
              <img src="../images/popup-icon-delete.svg" alt="" />
            </div>
            <h3 className="c-popup__title">
              Disable Whitelist
              <span>
                Do you want to disable whitelist? You can't restore this
                whitelist
              </span>
            </h3>
            <div className="c-popup__btn">
              <Button
                text="Cancel"
                type="greyBorder"
                onClick={() => setDisableWhitelist(false)}
                disabled={
                  actionWLPStatus ===
                  "LOADING_" + SALETYPE_WHITELIST_POOL.PUBLIC
                    ? true
                    : false
                }
              />
              <Button
                text="Disable"
                type="blue"
                isLoading={
                  actionWLPStatus ===
                  "LOADING_" + SALETYPE_WHITELIST_POOL.PUBLIC
                    ? true
                    : false
                }
                disabled={
                  actionWLPStatus ===
                  "LOADING_" + SALETYPE_WHITELIST_POOL.PUBLIC
                    ? true
                    : false
                }
                onClick={() =>
                  handleWhitelistPool(SALETYPE_WHITELIST_POOL.PUBLIC, true)
                }
              />
            </div>
          </div>
        </div>
      )}
      </Form>
    </>
  );
};

export default LaunchpadDetail;
