import React, { useEffect, useState } from 'react';

import { Row, Col } from 'antd';


const AirdropRight = (props:any) => { 
   const { widthItem, position } = props;

    return(
        <>
            <div className={`airdrop-right airdrop-${position}`}>
                <div className='airdrop-right-title'>
                    Statistics
                </div>

                <Row gutter={8}>
                    <Col className="gutter-row" xs={12} sm={24}>
                        <div className={`airdrop-right-item`}>
                            <Row gutter={8}>
                                <Col className="gutter-row" span={7}>
                                    <div className='airdrop-right-item-img'>
                                        <img src="../images/air-right-1.png"/>  
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={17}>
                                    <div className='airdrop-right-item-content'>
                                        <p className='bread-mobile'>Airdrop Launched</p>
                                        <p><span>N/A</span></p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={24}>
                        <div className={`airdrop-right-item`}>
                            <Row gutter={8}>
                                <Col className="gutter-row" span={7}>
                                    <div className='airdrop-right-item-img'>
                                        <img src="../images/air-right-2.png"/>  
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={17}>
                                    <div className='airdrop-right-item-content'>
                                        <p className='bread-mobile'>Total Participants</p>
                                        <p><span>N/A</span></p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={24}>
                        <div className={`airdrop-right-item`}>
                            <Row gutter={8}>
                                <Col className="gutter-row" span={7}>
                                    <div className='airdrop-right-item-img'>
                                        <img src="../images/air-right-3.png"/>  
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={17}>
                                    <div className='airdrop-right-item-content'>
                                        <p className='bread-mobile'>Joined Airdrops</p>
                                        <p><span>N/A</span></p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={24}>
                        <div className={`airdrop-right-item`}>
                            <Row gutter={8}>
                                <Col className="gutter-row" span={7}>
                                    <div className='airdrop-right-item-img'>
                                        <img src="../images/air-right-4.png"/>  
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={17}>
                                    <div className='airdrop-right-item-content'>
                                        <p className='bread-mobile'>My Airdrops</p>
                                        <p><span>N/A</span></p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                
                <div className='airdrop-right-item bg-transparent'>
                    <div className='airdrop-banner d-desktop'>
                        <img src="../images/airdrop-banner-right.png"/>
                    </div>
                    <div className='airdrop-banner d-mobile'>
                        <img src="../images/airdrop-banner-right-mobile.png"/>
                    </div>
                </div>
            </div>
        
        </>
    )
}

export default AirdropRight;