
import 'antd/dist/antd.css';
import { Menu, Dropdown, Button, Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './header.css';


import { CloseOutlined, HomeFilled, MenuOutlined } from '@ant-design/icons';


declare const window: Window & typeof globalThis & { ethereum: any };

const HeaderPublicSale = () => {

	const location = useLocation();

	let defaultMenu;
	switch (location.pathname) {
		case '#bls-token':
			defaultMenu = 'bls-token';
			break;
		case '#blueSale-token':
			defaultMenu = 'blueSale-token';
			break;
		case '#token-usecase':
			defaultMenu = 'token-usecase';
			break;
		case '#fee-structure':
			defaultMenu = 'fee-structure';
			break;
		case '#tokenomics':
			defaultMenu = 'tokenomics';
			break;
		default:
			defaultMenu = 'bls-token';
	}
	const [current, setCurrent] = useState(defaultMenu);

	const activeMenu = (e: { key: React.SetStateAction<string> }) => {
		if (e.key === 'logo') {
			setCurrent('bls-token');
			return;
		}
		setCurrent(e.key);
	};
	

	const [isActive, setActive] = useState(false);
	const handleToggle = () => {
		var element = document.getElementsByTagName("BODY")[0];
		setActive(!isActive);
		if(!isActive){ 
			element.classList.add("overflow");
		} 
		else{
			element.classList.remove("overflow")
		}
	};
	const handleHideMenuMobile = () => {
		var element = document.getElementsByTagName("BODY")[0];
		element.classList.remove("overflow")
		setActive(false);
	};

	const handleClickScroll = (idDiv:any) => {
		const element = document.getElementById(idDiv);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	};


	return (
		<>
			<div className={`header-public-sale`}>
				<Link to="/sale" className='logo-bluesale'>
					<img src="../images/bluesale-icon.svg" alt="logo"/>
				</Link>
				<Menu
					selectedKeys={[current]}
					defaultOpenKeys={[current]}
					onClick={activeMenu}
					className="header-desktop"
				>

					<Menu.Item key="bls-token" title="BLS Token">
						<a onClick={() => handleClickScroll('bls-token')}>
							<span>BLS Token</span>
						</a>
					</Menu.Item>
					<Menu.Item key="blueSale-token" title="BlueSale Token">
						<a onClick={() => handleClickScroll('blueSale-token-section')}>
							<span>BlueSale Token</span>
						</a>
					</Menu.Item>
					<Menu.Item key="token-usecase" title="Token Usecase">
						<a onClick={() => handleClickScroll('token-usecase-section')}>
							<span>Token Usecase</span>
						</a>
					</Menu.Item>
					<Menu.Item key="fee-structure" title="Fee Structure">
						<a onClick={() => handleClickScroll('fee-structure-section')}>
							<span>Fee Structure</span>
						</a>
					</Menu.Item>
					<Menu.Item key="tokenomics" title="Tokenomics">
						<a onClick={() => handleClickScroll('tokenomic-section')}>
							<span>Tokenomics</span>
						</a>
					</Menu.Item>
					<Menu.Item key="vesting-schedule" title="Vesting schedule">
						<a onClick={() => handleClickScroll('vestingschedule-section')}>
							<span>Vesting schedule</span>
						</a>
					</Menu.Item>
					<Menu.Item key="backer-partner" title="Backer & Partner">
						<a onClick={() => handleClickScroll('backer-partner')}>
							<span>Backer & Partner</span>
						</a>
					</Menu.Item>
				</Menu>

				<div>
					<div className={`header-mobile`}>
						<div className="header-left">
							<Link to="/sale">
								<img src="../images/bluesale-icon.svg" alt="logo" className='logo-mobile' />
							</Link>
						</div>
						
						<div className="header-right">
							<Button type="primary" onClick={handleToggle} className="btn-menu-mobile">
								<img src="../images/menumobile.svg" alt="logo" className='icon-mobile' /> 
							</Button>
						</div>
					</div>
					<div className="menu-mobile">
						<Menu
							defaultSelectedKeys={[current]}
							defaultOpenKeys={[current]}
							mode="inline"
							theme="dark"
							className={!isActive ? 'hide-menu menu-mobile-ul' : 'show-menu menu-mobile-ul'}
						>
							<Menu.Item key="item-menu-clo-mobile" className='item-menu-clo'>
								<div className="header-left">
									<Link to="/sale">
										<img src="../images/bluesale-icon.svg" alt="logo" className='logo-mobile' />
									</Link>
								</div>
								
								<div className="header-right">
									<Button type="primary" onClick={handleToggle} className="btn-menu-mobile">
										<img src="../images/closemobile.svg" alt="logo" className='icon-mobile' /> 
									</Button>
								</div>
							</Menu.Item>
					
							<Menu.Item key="bls-token-mobile" className='mt-20 item-menu-height' title="BLS Token" onClick={handleHideMenuMobile}>
								<a onClick={() => handleClickScroll('bls-token')}>
									<span>BLS Token</span>
								</a>
							</Menu.Item>
							<Menu.Item key="blueSale-token-mobile" className='item-menu-height' title="BlueSale Token" onClick={handleHideMenuMobile}>
								<a onClick={() => handleClickScroll('blueSale-token-section')}>
									<span>BlueSale Token</span>
								</a>
							</Menu.Item>
							<Menu.Item key="token-usecase-mobile" className='item-menu-height' title="Token Usecase" onClick={handleHideMenuMobile}>
								<a onClick={() => handleClickScroll('token-usecase-section')}>
									<span>Token Usecase</span>
								</a>
							</Menu.Item>
							<Menu.Item key="fee-structure-mobile" className='item-menu-height' title="Fee Structure" onClick={handleHideMenuMobile}>
								<a onClick={() => handleClickScroll('fee-structure-section')}>
									<span>Fee Structure</span>
								</a>
							</Menu.Item>
							<Menu.Item key="tokenomics-mobile" className='item-menu-height' title="Tokenomics" onClick={handleHideMenuMobile}>
								<a onClick={() => handleClickScroll('tokenomic-section')}>
									<span>Tokenomics</span>
								</a>
							</Menu.Item>
							<Menu.Item key="vesting-schedule-mobile" className='item-menu-height' title="Tokenomics" onClick={handleHideMenuMobile}>
								<a onClick={() => handleClickScroll('vestingschedule-section')}>
									<span>Vesting schedule</span>
								</a>
							</Menu.Item>
							<Menu.Item key="backer-partner-mobile" className='item-menu-height' title="Backer & partner" onClick={handleHideMenuMobile}>
								<a onClick={() => handleClickScroll('backer-partner')}>
									<span>Backer & Partner</span>
								</a>
							</Menu.Item>
							<Menu.Item key="email" className='item-menu-height' title="Tokenomics" onClick={handleHideMenuMobile}>
								<ul className='header-social-mobile'>
									<li>contact@bluesale.finance</li>
									<li><a className='footer-social-item' target="_blank" href='https://t.me/BlueSaleFinance'><img className="img-1" src="../images/telegram-active.svg" /></a></li>
									<li><a className='footer-social-item' target="_blank" href='https://discord.io/BlueSale'><img className="img-1" src="../images/discord-active.svg" /></a></li>
									<li><a className='footer-social-item' target="_blank" href='https://twitter.com/BluesaleBls'><img className="img-1" src="../images/twitter-active.svg" /></a></li>
								</ul>
							</Menu.Item>
							
							<Menu.Item key="footer-menu-mobile" className='header-footer-mobile' title="" onClick={handleHideMenuMobile}>
								<ul className='header-social-mobile'>
									<li><a className='footer-social-item' target="_blank" href='/terms-of-service'>Terms of Service</a></li>
									<li><a className='footer-social-item' target="_blank" href='/privacy-policy'>Privacy Policy</a></li>
									<li><a className='footer-social-item' target="_blank" href='https://docs.bluesale.finance/'>Whitepaper</a></li>
								</ul>
								<p>© 2023 BlueSale</p>
							</Menu.Item>
						</Menu>
					</div>
				</div>

			</div>

		</>

	);
};

export default HeaderPublicSale;
