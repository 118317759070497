import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import LaunchPadService from "../services/LaunchPadService";

// ================================================
// ==                  ACTION                    ==
// ================================================
 

export const cexLauchpads = () => {
    return async dispatch => {
        dispatch(setLoading(true)); 
        await LaunchPadService.getCexLauchpad().then((res)=>{
            dispatch(setLoading(false));
            dispatch(setCex(res.data));
        })
        .catch((error:any)=>{
            dispatch(setErrors(error));
            dispatch(setLoading(false));
        });
    }
  }
  
// ================================================
// ==                REDUX STORE                 ==
// ================================================


type TokenObject = {
    [key: string]: void | string | number | Date | any;
};


export interface CexState { 
    cexLauchpad: TokenObject;
    loading: boolean;
    errors: string;
}

const initialState: CexState = { 
    cexLauchpad:{},
    loading: false,
    errors: "",
}

const cexSlice = createSlice({
  name: "cexLauchpad",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setCex: (state, { payload }:  PayloadAction<TokenObject>) => {  
        state.cexLauchpad = payload
    },
  },
})

export const { setLoading, setErrors, setCex } = cexSlice.actions

export default cexSlice.reducer

export const cexSelector = (state: { cexStore: CexState }) => state.cexStore