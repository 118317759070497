import { ArrowRightOutlined, DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Progress, Row } from 'antd';
import { useState, useEffect } from 'react'; 
import AOS from "aos";

const FeeStructure = () => { 
    
    return (
        <div className='container'>  
            <div className='token-usecase-block'>
                <h3>Fee Structure</h3>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className='fee-structure'>
                            <div className='d-desktop'>
                                <img src="../images/fee-structure-new.svg" />
                            </div>
                            <div className='d-mobile'>
                                <img src="../images/fee-structure1.svg" />
                            </div>
                        </div>
                    </Col>
                    
                </Row>
            </div>
        </div>
   )
}
 
export default FeeStructure;