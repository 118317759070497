/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Spin,
  Button as ButtonAtd,
  Collapse,
  Drawer,
  Pagination,
  Tooltip,
} from "antd";
import { Store } from "react-notifications-component";
import { LoadingOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import Countdown from "react-countdown";
import { Button } from "../../components/component";
import SwapBLSToXBLSABI from "../../contracts/abi/SwapBLSToXBLSABI.json";
import BLSTokenABI from "../../contracts/abi/BLSTokenABI.json";
import StakeXBLS from "../../contracts/abi/StakeXBLSABI.json";
import XBLSTokenABI from "../../contracts/abi/XBLSTokenABI.json";
import RedeemBLS from "../../contracts/abi/RedeemBLS.json";
import { useContract } from "../../hooks/useContract";
import addNotify from "../../components/commons/Notify/addNotify";
import DevidendDashBoardService from "../../redux/services/dividendDashboard";
import {
  _approveBLS,
  _convertBLS_xBLS,
  _approveXBLS,
  _saveChanges,
  _userRedeemIndex,
  _userRedeemIds,
  _redeemDetails,
  _unRedeem,
  _claim,
  _totalUserStaking,
  _calcRewards,
  formatNum,
} from "./utils";
import { useActiveWeb3React } from "../../hooks";
import { BLS_DIVIDENDS_V2 } from "../../constants";
import "./style.css";
import networkChange from "../../utils/networkChange";
import { useAppSelector } from "../../redux/hooks";
import { priceSelector } from "../../redux/slicers/priceSlice";

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
const chain = 42161;

const BlsDashboard = () => {
  let navigate = useNavigate();

  const [authToken, setAuthToken] = useState<any>();
  const [modalRedeem, setModalRedeem] = useState(false);
  const [isOpenModalHis, setOpenModalHis] = useState(false);
  const [listHistory, setListHistory] = useState([]);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingConvert, setIsLoadingConvert] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [totalStakeXBLS, setTotalStakeXBLS] = useState(0);
  const [amountApprove, setAmountApprove] = useState(0);
  const [valueDefault, setvalueDefault]: any = useState();
  const [valueDefaultRedeem, setvalueDefaultRedeem]: any = useState();
  const [balanceBLS, setbalanceBLS] = useState(0);
  const [balanceXBLS, setbalanceXBLS] = useState(0);
  const [lockDay, setLockDay] = useState(15);
  const [redeemRatio, setRedeemRatio] = useState(0);
  const [blsOut, setBlsOut] = useState(0);
  const [totalXblsStake, setTotalXblsStake] = useState(0);
  const [check, setCheck] = useState(false);

  const [isApproveModal, setIsApproveModal] = useState(false);
  const [isApprovePool, setIsApprovePool] = useState(false);

  const [isLoadingApproveModal, setIsLoadingApproveModal] = useState(false);
  const [isLoadingApprovePool, setIsLoadingApprovePool] = useState(false);

  const [isLoadingSaveChanges, setIsLoadingSaveChanges] = useState(false);
  const [amountApproveModal, setAmountApproveModal] = useState(0);
  const [amountApprovePool, setAmountApprovePool] = useState(0);

  const { account, chainId } = useActiveWeb3React();
  const { BLSPriceMexc } = useAppSelector(priceSelector);

  const swapBLSToXBLSContract = useContract(
    BLS_DIVIDENDS_V2[chain]?.SWAP_BLS_TO_XBLS_ADDRESS,
    SwapBLSToXBLSABI
  );
  const stakeContract = useContract(
    BLS_DIVIDENDS_V2[chain]?.STAKE_BLS_EARN_XBLS_ADDRESS,
    StakeXBLS
  );

  const blsContract = useContract(
    BLS_DIVIDENDS_V2[chain]?.BLS_ADDRESS,
    BLSTokenABI
  );
  const xblsContract = useContract(
    BLS_DIVIDENDS_V2[chain]?.XBLS_ADDRESS,
    XBLSTokenABI
  );

  const redeemBLSContract = useContract(
    BLS_DIVIDENDS_V2[chain]?.REDEEM_BLS,
    RedeemBLS
  );


  const handleCancel = () => { };
  const handleCloseModal = () => {
    setModalRedeem(false);
    setvalueDefaultRedeem("");
    setLockDay(15);
  };
  useEffect(() => {
    handleCloseModal();
  }, [account]);
  const handleOpenModal = () => {
    setModalRedeem(true);
  };
  const handleOpenModalHis = () => {
    setOpenModalHis(true);
  };
  const handlezCloseModalHis = () => {
    setOpenModalHis(false);
  };

  const handleChangeInputApprove = (evt) => {
    const value =
      evt.target.validity.valid && evt.target.value <= balanceBLS
        ? evt.target.value
        : valueDefault;

    setvalueDefault(value);
  };

  const btnApprove = () => {
    const obj = {
      className: `c-btn-approved`,
      disabled: isLoadingApprove || isApprove,
    };
    return obj;
  };

  const btnConvert = () => {
    const obj = {
      className: `c-btn-convert`,
      disabled: isLoadingConvert,
    };
    return obj;
  };

  // handle approve
  const handleApprove = async (amount) => {
    try {
      setIsLoadingApprove(true);
      await _approveBLS(
        blsContract,
        BLS_DIVIDENDS_V2[chain]?.SWAP_BLS_TO_XBLS_ADDRESS,
        amount
      ).then((res: any) => {
        if (res.hash !== null) {
          res.wait().then((res1: any) => {
            if (res1?.status === 1) {
              setIsApprove(true);
              setIsLoadingApprove(false);
              setCheck(!check);
              Store.addNotification(
                addNotify("Approved successfully", "success")
              );
            }
          });
        }
      });
    } catch (error: any) {
      setIsLoadingApprove(false);
      if (error?.error?.data) {
        Store.addNotification(addNotify(error?.error?.data?.message, "danger"));
      } else {
        if (error?.code === -32603) {
          Store.addNotification(
            addNotify("Cannot set properties of undefined", "danger")
          );
        } else Store.addNotification(addNotify(error?.code, "danger"));
      }
    }
  };

  // handle convert
  const handleConvert = async (amount) => {

    try {
      if (
        valueDefault <= 0 ||
        valueDefault === "" ||
        valueDefault === undefined
      ) {
        Store.addNotification(addNotify("Invalid input amount", "danger"));

        return;
      }
      setIsLoadingConvert(true);
      await _convertBLS_xBLS(swapBLSToXBLSContract, amount).then((res) => {
        if (res.hash !== null) {
          res.wait().then((res1: any) => {
            if (res1?.status === 1) {
              setIsLoadingConvert(false);
              setCheck(!check);
              setvalueDefault("");
              Store.addNotification(
                addNotify("Convert successfully", "success")
              );
            }
          });
        }
      });
    } catch (error: any) {
      setIsLoadingConvert(false);
      if (error?.error?.data) {
        Store.addNotification(addNotify(error?.error?.data?.message, "danger"));
      } else {
        if (error?.code === -32603) {
          Store.addNotification(
            addNotify("Cannot set properties of undefined", "danger")
          );
        } else Store.addNotification(addNotify(error?.code, "danger"));
      }
    }
  };

  // handle Max
  const handleSetMax = async () => {
    setvalueDefault(formatNum(balanceBLS));
  };

  const handleChangeInputRedeem = (evt) => {
    const value =
      evt.target.validity.valid && evt.target.value <= balanceXBLS
        ? evt.target.value
        : valueDefaultRedeem;

    setvalueDefaultRedeem(value);
  };

  const setMaxRedeem = async (amount) => {
    setvalueDefaultRedeem(amount);
  };

  const handleChangeInputLockDay = async (evt) => {
    const value =
      evt.target.validity.valid && evt.target.value <= 180
        ? evt.target.value
        : lockDay;

    setLockDay(parseInt(value) || 0);
  };

  useEffect(() => {
    if (stakeContract) {
      stakeContract.userInfo(account).then((res) => {
        setTotalStakeXBLS(Number(res.amount) / 1e18);
      });
    }
  }, [stakeContract]);

  // check approve when reload page
  useEffect(() => {
    if (account && blsContract && xblsContract) {
      xblsContract.balanceOf(account).then((res) => {
        setbalanceXBLS(Number(res) / 1e18);
      });

      blsContract.balanceOf(account).then((res) => {
        setbalanceBLS(Number(res) / 1e18);
      });

      blsContract
        .allowance(account, BLS_DIVIDENDS_V2[chain]?.SWAP_BLS_TO_XBLS_ADDRESS)
        .then((res: any) => {
          setAmountApprove(Number(res) / 1e18);

          if (amountApprove > 0) {
            if (valueDefault !== undefined && valueDefault !== "") {
              if (valueDefault <= amountApprove) {
                setIsApprove(true);
              } else {
                setIsApprove(false);
              }
            } else if (valueDefault === undefined || valueDefault === "") {
              if (amountApprove > 0) {
                setIsApprove(true);
              } else {
                setIsApprove(false);
              }
            }
          } else {
            setIsApprove(false);
          }
        });
    }
  }, [
    xblsContract,
    account,
    blsContract,
    isApprove,
    valueDefault,
    amountApprove,
    check,
    modalRedeem,
  ]);

  useEffect(() => {
    setRedeemRatio(100 - (180 - lockDay) * (50 / 165));
  }, [lockDay]);

  useEffect(() => {
    setBlsOut(
      (1 - ((180 - lockDay) * (50 / 165)) / 100) * valueDefaultRedeem -
      (valueDefaultRedeem * 0.005) / 2 || 0
    );
  }, [blsOut, valueDefaultRedeem, redeemRatio]);

  const handleApprovePoolDividend = async (amount) => {
    try {
      setIsLoadingApprovePool(true);
      await _approveXBLS(
        xblsContract,
        BLS_DIVIDENDS_V2[chain]?.STAKE_BLS_EARN_XBLS_ADDRESS,
        amount
      ).then((res) => {
        if (res.hash !== null) {
          res.wait().then((res1: any) => {
            if (res1?.status === 1) {
              setIsLoadingApprovePool(true);
              setIsLoadingApprovePool(false);
              setvalueDefaultRedeem("");
              setCheck(!check);
              Store.addNotification(
                addNotify("Approved successfully", "success")
              );
            }
          });
        }
      });
    } catch (error: any) {
      setIsLoadingApprovePool(false);
      if (error?.error?.data) {
        Store.addNotification(addNotify(error?.error?.data?.message, "danger"));
      } else {
        if (error?.code === -32603) {
          Store.addNotification(
            addNotify("Cannot set properties of undefined", "danger")
          );
        } else Store.addNotification(addNotify(error?.code, "danger"));
      }
    }
  };

  const handleApproveRedeem = async (amount) => {
    try {
      setIsLoadingApproveModal(true);
      await _approveXBLS(
        xblsContract,
        BLS_DIVIDENDS_V2[chain]?.REDEEM_BLS,
        amount
      ).then((res) => {
        if (res.hash !== null) {
          res.wait().then((res1: any) => {
            if (res1?.status === 1) {
              setIsApproveModal(true);
              setIsLoadingApproveModal(false);
              Store.addNotification(
                addNotify("Approved successfully", "success")
              );
            }
          });
        }
      });
    } catch (error: any) {
      setIsLoadingApproveModal(false);
      if (error?.error?.data) {
        Store.addNotification(addNotify(error?.error?.data?.message, "danger"));
      } else {
        if (error?.code === -32603) {
          Store.addNotification(
            addNotify("Cannot set properties of undefined", "danger")
          );
        } else if (error?.code === 4001) {
          Store.addNotification(
            addNotify("User denied transaction signature.", "danger")
          );
        } else Store.addNotification(addNotify(error?.code, "danger"));
      }
    }
  };

  const handleSaveChanges = async (amount, day) => {
    try {
      if (
        valueDefaultRedeem <= 0 ||
        valueDefaultRedeem === "" ||
        valueDefaultRedeem === undefined
      ) {
        Store.addNotification(
          addNotify("Invalid input amount reedem", "danger")
        );

        return;
      }
      if (lockDay < 15) {
        Store.addNotification(addNotify("Lock min 15 days", "danger"));

        return;
      }
      setIsLoadingSaveChanges(true);
      await _saveChanges(redeemBLSContract, amount, day * 86400).then((res) => {
        // await _saveChanges(redeemBLSContract, amount, day).then((res) => {
        if (res.hash !== null) {
          res.wait().then((res1) => {
            if (res1?.status === 1) {
              setIsLoadingSaveChanges(false);
              setCheck(!check);
              handleCloseModal();
              Store.addNotification(
                addNotify("Save Changes successfully", "success")
              );
            } else {
              setIsLoadingSaveChanges(false);
              Store.addNotification(
                addNotify("Transaction reverted", "danger")
              );
            }
          });
        }
      });
    } catch (error: any) {
      setIsLoadingSaveChanges(false);

      if (error?.error?.data) {
        Store.addNotification(addNotify(error?.error?.data?.message, "danger"));
      } else {
        if (error?.code === -32603) {
          Store.addNotification(
            addNotify("Cannot set properties of undefined", "danger")
          );
        } else Store.addNotification(addNotify(error?.code, "danger"));
      }
    }
  };

  useEffect(() => {
    if (xblsContract) {
      xblsContract
        .allowance(account, BLS_DIVIDENDS_V2[chain]?.REDEEM_BLS)
        .then((res: any) => {
          setAmountApproveModal(Number(res) / 1e18);
          if (amountApproveModal > 0) {
            if (valueDefaultRedeem !== undefined && valueDefaultRedeem !== "") {
              if (valueDefaultRedeem <= amountApproveModal) {
                setIsApproveModal(true);
              } else {
                setIsApproveModal(false);
              }
            } else if (
              valueDefaultRedeem === undefined ||
              valueDefaultRedeem === ""
            ) {
              if (amountApproveModal > 0) {
                setIsApproveModal(true);
              } else {
                setIsApproveModal(false);
              }
            }
          } else {
            setIsApproveModal(false);
          }
        });
    }
  }, [
    isApproveModal,
    amountApproveModal,
    valueDefaultRedeem,
    modalRedeem,
    check,
  ]);

  useEffect(() => {
    if (xblsContract) {
      xblsContract
        .allowance(account, BLS_DIVIDENDS_V2[chain]?.STAKE_BLS_EARN_XBLS_ADDRESS)
        .then((res: any) => {
          setAmountApprovePool(Number(res) / 1e18);
          if (amountApprovePool > 0) {
            if (valueDefaultRedeem !== undefined && valueDefaultRedeem !== "") {
              if (valueDefaultRedeem <= amountApprovePool) {
                setIsApprovePool(true);
              } else {
                setIsApprovePool(false);
              }
            } else if (
              valueDefaultRedeem === undefined ||
              valueDefaultRedeem === ""
            ) {
              if (amountApprovePool > 0) {
                setIsApprovePool(true);
              } else {
                setIsApprovePool(false);
              }
            }
          } else {
            setIsApprovePool(false);
          }
        });
    }
  }, [
    isApprovePool,

    amountApprovePool,
    valueDefaultRedeem,
    modalRedeem,
    check,
  ]);

  const [totalRowUserReedem, setTotalRowUserReedem] = useState(0);
  const [userReedemIdsList, setUserReedemIdsList] = useState([]);

  const getTotalRowUserReedem = async () => {
    await _userRedeemIndex(redeemBLSContract, account).then((res) => {
      setTotalRowUserReedem(Number(res));
    });
  };

  let arrIds: any = [];
  const getRowUserReedemIds = async () => {
    for (let i = 0; i < totalRowUserReedem; i++) {
      await _userRedeemIds(redeemBLSContract, account, i).then(async (res) => {

        arrIds.push(Number(res));
      });
    }
    setUserReedemIdsList(arrIds);
  };



  useEffect(() => {
    if (redeemBLSContract) {
      getTotalRowUserReedem();
      getRowUserReedemIds();
    }
  }, [redeemBLSContract, totalRowUserReedem, check]);

  const gotoDividends = () => {
    navigate(`/bls/dividends`);
  };
  const total = async () => {
    await _totalUserStaking(redeemBLSContract, account).then((res) => {
      setTotalXblsStake(Number(res) / 1e18);
    });
  };

  useEffect(() => {
    if (redeemBLSContract) {
      total();
    }
  }, [redeemBLSContract, check]);
  // console.log("redeemBLSContract", redeemBLSContract);

  const { Panel } = Collapse;

  const textHeader = (item: any) => (
    <>
      <td className="his-content time">{item.creTime}</td>
      <td
        className={`his-content ${item.action === "cancel" ? "status" : "statusClaim"
          }`}
      >
        {item.action}
      </td>
    </>
  );
  const textContent = (item: any) => (
    <>
      <td className="his-content put">
        xBLS output: {formatNum(item.xBlsInput)}
      </td>
      <td className="his-content put">
        BLS input: {formatNum(item.blsOutput)}
      </td>
      <td className="his-content txh" onClick={() => gotoHash(item.txhash)}>
        Txh:{" "}
        {item.txhash
          ? `${item.txhash?.substring(0, 3)}...${item.txhash?.substring(
            item.txhash.length - 3
          )}`
          : ""}
      </td>
    </>
  );

  // const getHistory = async () => {
  //   await DevidendDashBoardService.getInforReedem(authToken)
  //     .then((res) => {
  //       setTotalItem(res.data?.length);
  //       // setListHistory(res.data);
  //       console.log("res res.data?.length-----", res.data?.length);
  //     })
  //     .catch((error: any) => {});
  // };

  const getToken = async () => {
    await DevidendDashBoardService.getAuthToken(account)
      .then((res) => {
        setAuthToken(res.data.id_token);
      })
      .catch((error: any) => { });
  };

  useEffect(() => {
    getToken();
  }, [account]);

  // const [current, setCurrent] = useState(1);
  // const [listRedeemPage, setListRedeemPage] = useState([]);

  const [currentHis, setCurrentHis] = useState(1);
  const [totalItem, setTotalItem] = useState(0);

  useEffect(() => {
    if (authToken) {
      getHistoryPage();
    }
  }, [authToken, check, currentHis]);
  // useEffect(() => {
  //   if (authToken) {
  //     getHistory();
  //   }
  // }, [authToken, check]);

  const gotoHash = (txh) => {
    window.open(`https://testnet.bscscan.com/tx/${txh}`);
  };

  const text = (
    <span>
      <span className="notice">Notice</span>: Please be advised that by taking
      this action, you will receive all of your related outstanding dividend
      rewards.
    </span>
  );
  let pageSize = 6;
  // let pageSizeVesting = 4;
  const getHistoryPage = async () => {
    await DevidendDashBoardService.getInforReedemPage(
      authToken,
      currentHis - 1,
      pageSize
    )
      .then((res) => {
        setListHistory(res.data);

        setTotalItem(Number(res.headers["x-total-count"]));
        console.log("res history-----", res.data);
      })
      .catch((error: any) => { });
  };

  // useEffect(() => {
  //   setListRedeemPage([
  //     userReedemIdsList[0],
  //     userReedemIdsList[1],
  //     userReedemIdsList[2],
  //     userReedemIdsList[3],
  //   ]);
  // }, [userReedemIdsList]);

  // const onChangePage = (page) => {
  //   let index = page - 1;
  //   console.log(page);
  //   setListRedeemPage([
  //     userReedemIdsList[index * 4],
  //     userReedemIdsList[index * 4 + 1],
  //     userReedemIdsList[index * 4 + 2],
  //     userReedemIdsList[index * 4 + 3],
  //   ]);
  //   setCurrent(page);
  // };
  const onChangePageHis = (page) => {
    console.log(page);
    // setListRedeemPage(userReedemIdsList[1])

    setCurrentHis(page);
  };

  // const [totalItemVestingReal, setTotalItemVestingReal] = useState(0);

  // useEffect(() => {
  //   for (let i = 0; i <= userReedemIdsList.length; i++) {
  //     if (userReedemIdsList[i] !== 0) {
  //       setTotalItemVestingReal(1);
  //       break;
  //     }
  //   }

  // }, [userReedemIdsList]);

  useEffect(() => {
    if (chainId && account && chainId !== 42161) {
      networkChange("0xa4b1");
      // setSearchParams({chain: chainId?.toString()});
    }
  }, [chainId, account]);

  const switchArb = async () => {
    await networkChange("0xa4b1");
  };

  return (
    <div className="p-blsDividends">
      <div className="p-blsDividends__head">
        <h2>
          Dashboard
          <span>
            Convert your xBLS, redeem your xBLS and manage your xBLS plugins
            allocations.
          </span>
        </h2>
        <p>{formatNum(totalStakeXBLS * +BLSPriceMexc)}$ TVL</p>
      </div>
      <ul className="c-statistic">
        <li>
          <div className="c-statistic__img">
            <img src="../images/dashboard_wallet.svg" alt="" />
          </div>
          <h2 className="over-view">Over view</h2>
          <p className="c-statistic__title">
            <span>Total xBLS</span>
            <strong>{formatNum(totalStakeXBLS + balanceXBLS)}</strong>
          </p>
        </li>
        <li>
          <div className="c-statistic__img">
            <img src="../images/dashboard_unlock.svg" alt="" />
          </div>
          <p className="c-statistic__title">
            <span>Available xBLS</span>
            <strong>{formatNum(balanceXBLS)}</strong>
          </p>
        </li>
        <li className="is-mt8">
          <div className="c-statistic__img">
            <img src="../images/dashboard_lock.svg" alt="" />
          </div>
          <p className="c-statistic__title">
            <span>Allocated xBLS</span>
            <strong>{formatNum(totalStakeXBLS)}</strong>
          </p>
        </li>
        <li className="is-mt8">
          <div className="c-statistic__img">
            <img src="../images/dashboard_time.svg" alt="" />
          </div>
          <p className="c-statistic__title">
            <span>Redeeming xBLS</span>
            <strong>{formatNum(totalXblsStake)}</strong>
          </p>
        </li>
      </ul>

      <div className="p-blsDashboard-dividends">
        <div className="p-blsDashboard-wrap w-31">
          <div className="p-blsDashboard">
            <h2>Dividends</h2>
            <div>
              <span className="p-blsDashboard-subtext">
                Allocate your xBLS here in order to get your share of the
                platform generated earnings.
              </span>
              <div className="p-blsDashboard-detail">
                <div className="p-blsDashboard-allocation">
                  <span className="value-myallocation">My allocation</span>
                  <span className="value-allocation">
                    {formatNum(totalStakeXBLS)} xBLS
                  </span>
                </div>
                <div
                  className="p-blsDashboard-manage"
                  onClick={() => gotoDividends()}
                >
                  <img src="../images/dashboard_chart.svg" alt="" />
                  <span>Manage</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CommingSoon />
        <CommingSoon />
      </div>
      <div className="p-blsDashboard-dividends">
        <div className="p-blsDashboard-wrap w-30">
          <div className="p-blsDashboard w-100">
            <h2>Get xBLS</h2>
            <div className="c-input">
              <p className="c-input__title">Amount</p>
              <div className="c-input-form">
                <div className="c-input__max">
                  <input
                    onInput={handleChangeInputApprove}
                    value={valueDefault}
                    disabled={
                      isLoadingApprove || isLoadingConvert || balanceBLS <= 0
                    }
                    pattern="^-?[0-9]\d*\.?\d*$"
                    placeholder="0"
                  />
                </div>
                <button
                  className="c-btn__max"
                  onClick={() => handleSetMax()}
                  disabled={isLoadingApprove || isLoadingConvert}
                >
                  <span>Max</span>
                </button>
              </div>
              <p className="c-balance">Balance: {formatNum(balanceBLS)} BLS</p>
              {!isApprove && balanceBLS > 0 ? (
                <p className="c-balance">
                  Your approved: {formatNum(amountApprove)} BLS
                </p>
              ) : (
                ""
              )}
            </div>
            {balanceBLS <= 0 ? (
              ""
            ) : (
              <div className="c-receive">
                <span className="c-receive-text">You will receive:</span>
                <span className="c-receive-token">
                  {formatNum(valueDefault) || 0} xBLS
                </span>
              </div>
            )}
            {!account ? (
              <>
                <button className="not-connect-redeem">Not connected</button>
              </>
            ) : (
              <>
                {chainId !== 42161 ? (
                  <button className="switch-arb" onClick={switchArb}>
                    Switch network
                  </button>
                ) : (
                  <>
                    {balanceBLS <= 0 ? (
                      <div className="c-warning">
                        <span>Error: not enough balance</span>
                      </div>
                    ) : (
                      <>
                        {isApprove ? (
                          <>
                            <ButtonAtd
                              {...btnConvert()}
                              onClick={() => handleConvert(valueDefault)}
                            >
                              <>
                                {isLoadingConvert ? (
                                  <>
                                    Processing <Spin indicator={antIcon} />
                                  </>
                                ) : (
                                  <span>Convert</span>
                                )}
                              </>
                            </ButtonAtd>
                          </>
                        ) : (
                          <ButtonAtd
                            {...btnApprove()}
                            onClick={() => handleApprove(valueDefault)}
                          >
                            <>
                              {isLoadingApprove ? (
                                <>
                                  Processing <Spin indicator={antIcon} />
                                </>
                              ) : (
                                <span>Approve</span>
                              )}
                            </>
                          </ButtonAtd>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className="p-blsDashboard-wrap w-70">
          <div className="p-blsDashboard-vesting">
            <div className="flex-sp">
              <div className="vesting-text">
                <div className="vesting">Vesting</div>
                {isMobile ? (
                  <Tooltip
                    placement="topLeft"
                    title={text}
                    trigger={"click"}
                    overlayClassName="tooltip-mobie"
                  >
                    <img className="info-img" src="../images/Info.svg" alt="" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    placement="topLeft"
                    title={text}
                    // trigger={"click"}
                    overlayClassName="tooltip-vesting"
                  >
                    <img className="info-img" src="../images/Info.svg" alt="" />
                  </Tooltip>
                )}
              </div>

              {!account ? (
                <button className="not-connect-redeem">Not connected</button>
              ) : (
                <>
                  {chainId !== 42161 ? (
                    <button className="switch-arb-redeem" onClick={switchArb}>
                      Switch network
                    </button>
                  ) : (
                    <div className="ree-his">
                      <div
                        onClick={() => handleOpenModal()}
                        className="p-blsDashboard-redeem"
                      >
                        <img src="../images/dashboard_redeem.svg" alt="" />
                        <span>Redeem xBLS</span>
                      </div>

                      {isMobile ? (
                        <div
                          onClick={() => handleOpenModalHis()}
                          className="p-blsDashboard-cancel mobie"
                        >
                          <img src="../images/his-reedem.svg" alt="" />
                        </div>
                      ) : (
                        <div
                          onClick={() => handleOpenModalHis()}
                          className="p-blsDashboard-cancel"
                        >
                          <img src="../images/his-reedem.svg" alt="" />
                          <span>History</span>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="p-blsDashboard-header">
              <div className="p-blsDashboard-header-item">
                <span className="p-blsDashboard-header-text">Min. vesting</span>
                <span className="p-blsDashboard-header-time">15 days</span>
              </div>
              <div className="p-blsDashboard-header-item">
                <span className="p-blsDashboard-header-text">Max. vesting</span>
                <span className="p-blsDashboard-header-time">6 months</span>
              </div>
              <div className="p-blsDashboard-header-item">
                <span className="p-blsDashboard-header-text">Min. ratio</span>
                <span className="p-blsDashboard-header-time">1:0.5</span>
              </div>
              <div className="p-blsDashboard-header-item">
                <span className="p-blsDashboard-header-text">Max. ratio</span>
                <span className="p-blsDashboard-header-time">1:1</span>
              </div>
            </div>
            {!isMobile ? (
              <div className="p-blsDashboard-subheader">
                <span className="maintext">xBLS input</span>
                <span className="maintext">BLS output</span>
                <span className="maintext">Time left</span>
              </div>
            ) : (
              <br />
            )}
            {userReedemIdsList
              // listRedeemPage[0] &&
              //   listRedeemPage
              .filter((id) => id !== 0 && id !== undefined)
              .map((id: any, index: any) => (
                <ItemVesting
                  setCheck={setCheck}
                  check={check}
                  ids={id}
                  handleCancel={handleCancel}
                  redeemBLSContract={redeemBLSContract}
                  authToken={authToken}
                  index
                />
              ))}
            {/* <Pagination
              className="pag-redeem-his"
              onChange={onChangePage}
              defaultCurrent={current}
              total={userReedemIdsList.length}
              pageSize={pageSizeVesting}
            /> */}
          </div>
        </div>
      </div>
      <div className="p-blsDividends__version">
        <Link to='/bls/dashboard-v1' className="c-btn">Go to V1</Link>
      </div>
      {modalRedeem ? (
        <div className="c-popup is-xbls">
          <div className="c-popup__content">
            <h3 className="c-popup__title">Redeem xBLS</h3>
            <div className="c-popup__box">
              <div className="c-input">
                <p className="c-input__title">Redeem amount</p>
                <input
                  onInput={handleChangeInputRedeem}
                  value={valueDefaultRedeem}
                  pattern="^-?[0-9]\d*\.?\d*$"
                  placeholder="0"
                />
                <Button text="Max" onClick={() => setMaxRedeem(balanceXBLS)} />
              </div>
              <p>Balance: {formatNum(balanceXBLS)} xBLS</p>
              {/* {isApproveModal ? (
                ""
              ) : (
                <p>
                  Your approved Redeem: {formatNum(amountApproveModal)} xBLS
                </p>
              )}
              {isApprovePool ? (
                ""
              ) : (
                <p>Your approved Pool: {formatNum(amountApprovePool)} xBLS</p>
              )} */}
              <p>Your approved Redeem: {formatNum(amountApproveModal)} xBLS</p>
              <p>Your approved Pool: {formatNum(amountApprovePool)} xBLS</p>

              <div className="redeem-duration">
                <div>
                  <h4 className="c-ttl">Redeem duration</h4>

                  <p className="max-lock-day" onClick={() => setLockDay(180)}>
                    Set max
                  </p>
                </div>
                <div className="count-tab">
                  <Button
                    text="-"
                    type="greyBorder"
                    disabled={lockDay <= 15}
                    onClick={() => setLockDay(lockDay - 1)}
                  />
                  {/* <div className="count">
                    <span className="count-text">Months</span>
                    <span className="count-number">0</span>
                  </div> */}
                  <div className="count">
                    <span className="count-text">Days</span>
                    <input
                      className="count-inp-lock-day"
                      value={lockDay}
                      onInput={handleChangeInputLockDay}
                      pattern="^-?[0-9]\d*\.?\d*$"
                    />
                  </div>
                  <Button
                    text="+"
                    type="greyBorder"
                    disabled={lockDay >= 180}
                    onClick={() => setLockDay(lockDay + 1)}
                  />
                </div>
              </div>
              <div>
                <div className="info">
                  <span className="info-text">Redeem Ratio</span>
                  <span className="info-number">{formatNum(redeemRatio)}%</span>
                </div>
                <div className="info">
                  <span className="info-text">BLS output</span>
                  <span className="info-number">{blsOut || 0} BLS</span>
                </div>
              </div>
            </div>
            <div className="c-popup__btn is-one">
              <Button
                onClick={() => handleCloseModal()}
                text="Close"
                type="greyBorder"
              />

              {isApproveModal ? (
                <>
                  {isApprovePool ? (
                    <Button
                      text={
                        isLoadingSaveChanges ? (
                          <>
                            Processing{" "}
                            <Spin className="spin-modal" indicator={antIcon} />
                          </>
                        ) : (
                          "Save Changes"
                        )
                      }
                      type="blue"
                      disabled={isLoadingSaveChanges}
                      onClick={() =>
                        handleSaveChanges(valueDefaultRedeem, lockDay)
                      }
                    />
                  ) : (
                    <Button
                      text={
                        isLoadingApprovePool ? (
                          <>
                            Processing{" "}
                            <Spin className="spin-modal" indicator={antIcon} />
                          </>
                        ) : (
                          "Approve Pool"
                        )
                      }
                      type="blue"
                      onClick={() =>
                        handleApprovePoolDividend(valueDefaultRedeem)
                      }
                      disabled={isLoadingApprovePool}
                    />
                  )}
                </>
              ) : (
                <>
                  <Button
                    text={
                      isLoadingApproveModal ? (
                        <>
                          Processing{" "}
                          <Spin className="spin-modal" indicator={antIcon} />
                        </>
                      ) : (
                        "Approve Redeem"
                      )
                    }
                    type="blue"
                    onClick={() => handleApproveRedeem(valueDefaultRedeem)}
                    disabled={isLoadingApproveModal}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      ) : null}

      {isMobile ? (
        <Drawer
          // title="History Reedem"
          className="drawer-his"
          placement="right"
          onClose={handlezCloseModalHis}
          open={isOpenModalHis}
          width={"100%"}
        >
          <div className="title">History Reedem</div>
          {listHistory.length === 0 ? (
            <div className="nodata">
              <img src="../images/nohis.svg" alt=""></img>
            </div>
          ) : (
            <>
              <Collapse
                // defaultActiveKey={[index]}
                // onChange={onChange}
                expandIconPosition="end"
              >
                {listHistory?.map((item: any, i: any) => (
                  <Panel header={textHeader(item)} key={i + 1}>
                    <p>{textContent(item)}</p>
                  </Panel>
                ))}
              </Collapse>
              <Pagination
                className="pag-redeem-his"
                onChange={onChangePageHis}
                defaultCurrent={currentHis}
                pageSize={pageSize}
                total={totalItem}
              />
            </>
          )}
        </Drawer>
      ) : (
        <Drawer
          // title="History Reedem"
          className="drawer-his"
          placement="right"
          onClose={handlezCloseModalHis}
          open={isOpenModalHis}
          width={"50%"}
        >
          <div className="title">History Reedem</div>
          {listHistory.length === 0 ? (
            <>
              <table className="his-header">
                <tr className="his-title">
                  <th className="his-row put">Input</th>
                  <th className="his-row put">Output</th>
                  <th className="his-row time">Time</th>
                  <th className="his-row btn-his">Status</th>
                  <th className="his-row hash">TxHash</th>
                </tr>
              </table>
              <div className="nodata">
                <img src="../images/nohis.svg" alt=""></img>
              </div>
            </>
          ) : (
            <>
              <table className="his-header">
                <tr className="his-title">
                  <th className="his-row put">Input</th>
                  <th className="his-row put">Output</th>
                  <th className="his-row time">Time</th>
                  <th className="his-row btn-his">Status</th>
                  <th className="his-row hash">TxHash</th>
                </tr>

                {listHistory?.map((item: any, i: any) => (
                  <tr>
                    <td className="his-content put">
                      {formatNum(item.xBlsInput)} xBLS
                    </td>
                    <td className="his-content put">
                      {formatNum(item.blsOutput)} {""}
                      {item.action === "cancel" ? "xBLS" : "BLS"}
                    </td>
                    <td className="his-content time">{item.creTime}</td>
                    <td
                      className={`his-content ${item.action === "cancel" ? "status" : "statusClaim"
                        }`}
                    >
                      {item.action}
                    </td>
                    {/* <td className="his-content status">{item.action}</td> */}
                    <td
                      className="his-content txh"
                      onClick={() => gotoHash(item.txhash)}
                    >
                      {item.txhash
                        ? `${item.txhash?.substring(
                          0,
                          3
                        )}...${item.txhash?.substring(
                          item.txhash.length - 3
                        )}`
                        : ""}
                    </td>
                  </tr>
                ))}
              </table>
              <Pagination
                className="pag-redeem-his"
                onChange={onChangePageHis}
                defaultCurrent={currentHis}
                pageSize={pageSize}
                total={totalItem}
              />
            </>
          )}
        </Drawer>
      )}
      <div className="reserved">© BlueSale. All rights reserved.</div>
    </div>
  );
};

const ItemVesting = (props) => {
  const { Panel } = Collapse;
  const [userReedemIdsDetails, setUserReedemIdsDetails] = useState([]);

  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoadingClaim, setisLoadingClaim] = useState(false);

  const {
    handleCancel,
    ids,
    redeemBLSContract,
    setCheck,
    check,
    index,
    authToken,
  } = props;

  const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
    // Render a countdown
    if (completed) {
      // Render a completed state()
      setIsCompleted(true);
    } else if (days > 1) {
      return (
        <span>
          {days}d : {hours}h : {minutes}m : {seconds}s
        </span>
      );
    } else if (days === 1) {
      return (
        <span>
          {days}d : {hours}h : {minutes}m : {seconds}s
        </span>
      );
    } else {
      return (
        <span>
          {hours}h : {minutes}m : {seconds}s
        </span>
      );
    }
  };

  const getReedemDetails = async (ids) => {
    await _redeemDetails(redeemBLSContract, ids).then((res) => {
      setUserReedemIdsDetails(res);
    });
  };

  useEffect(() => {
    getReedemDetails(ids);
  }, [ids]);

  let xBLS = Number(userReedemIdsDetails[1]) / 1e18;
  let redeemPeriod = Number(userReedemIdsDetails[3]);

  let startRedeemDate = Number(userReedemIdsDetails[2]);
  // let timeMillis = redeemPeriod * 86400000 + startRedeemDate * 1000;
  let timeMillis = startRedeemDate * 1000 + redeemPeriod * 1000;

  let BLS =
    (1 - ((180 - redeemPeriod / 86400) * (50 / 165)) / 100) * xBLS -
    (xBLS * 0.005) / 2;
  // let BLS =
  //   (1 - ((180 - redeemPeriod) * (50 / 165)) / 100) * xBLS - (xBLS * 0.005) / 2;

  const handleUnReedem = async (ids) => {
    try {
      setIsLoadingCancel(true);
      await _unRedeem(redeemBLSContract, ids).then((res) => {
        if (res.hash !== null) {
          res.wait().then((res1) => {
            if (res1?.status === 1) {
              setIsLoadingCancel(false);
              // setCheck(!check);
              saveInfo(1, BLS, now, res.hash, "cancel", "success", xBLS);
              Store.addNotification(
                addNotify("Cancel successfully", "success")
              );
            }
          });
        }
      });
    } catch (error: any) {
      setIsLoadingCancel(false);
      if (error?.error?.data) {
        Store.addNotification(addNotify(error?.error?.data?.message, "danger"));
      } else {
        if (error?.code === -32603) {
          Store.addNotification(
            addNotify("Cannot set properties of undefined", "danger")
          );
        } else Store.addNotification(addNotify(error?.code, "danger"));
      }
    }
  };

  let now = new Date().toISOString();
  const handleClaim = async (id) => {
    try {
      setisLoadingClaim(true);
      await _claim(redeemBLSContract, id).then((res) => {
        if (res.hash !== null) {
          res.wait().then((res1) => {
            if (res1?.status === 1) {
              setisLoadingClaim(false);
              // setCheck(!check);
              Store.addNotification(addNotify("Claim successfully", "success"));
              saveInfo(1, BLS, "", res.hash, "claim", "success", xBLS);
            }
          });
        }
      });
    } catch (error: any) {
      setisLoadingClaim(false);
      if (error?.error?.data) {
        Store.addNotification(addNotify(error?.error?.data?.message, "danger"));
      } else {
        if (error?.code === -32603) {
          Store.addNotification(
            addNotify("Cannot set properties of undefined", "danger")
          );
        } else Store.addNotification(addNotify(error?.code, "danger"));
      }
    }
  };

  // const calcRewards = async (id) => {
  //   await _calcRewards(redeemBLSContract, id).then((res) => {
  //     console.log("res calcRewards", Number(res) / 1e18);
  //   });
  // };

  const saveInfo = async (
    id: 1,
    blsOut: any,
    creTime: any,
    txh: any,
    action: any,
    status: any,
    xBlsIn: any
  ) => {
    await DevidendDashBoardService.saveInforReedem(
      id,
      blsOut,
      creTime,
      txh,
      action,
      status,
      xBlsIn,
      authToken
    )
      .then((res) => {
        setCheck(!check);
        // console.log("res save claim-----", res);
      })
      .catch((error: any) => { });
  };

  useEffect(() => {
    // getToken();
    // calcRewards(ids);
    if (Date.now() > timeMillis) {
      setIsCompleted(true);
    }
  }, [timeMillis, isCompleted]);

  const onChange = (key: string | string[]) => {
    // console.log(key);
  };

  const textHeader = (
    <div className="mobie">
      <div className="row1">
        <div className="in">
          <span>xBLS input: </span>
          <span className="text">{formatNum(xBLS)}</span>
        </div>
        <div className="btn">
          {isCompleted ? (
            <>
              <button
                className="p-blsDashboard-cancel"
                onClick={() => handleClaim(ids)}
                disabled={isLoadingClaim}
              >
                {isLoadingClaim ? (
                  <>
                    <span>Waiting</span> {""}
                    <Spin className="spin-modal" indicator={antIcon} />
                  </>
                ) : (
                  <>
                    {/* <img src="../images/dashboard_cancel.svg" alt="" /> */}
                    <span>Claim</span>
                  </>
                )}
              </button>
            </>
          ) : (
            <button
              className="p-blsDashboard-cancel"
              onClick={() => handleUnReedem([ids])}
              disabled={isLoadingCancel}
            >
              {isLoadingCancel ? (
                <>
                  <span>Waiting</span> {""}
                  <Spin className="spin-modal" indicator={antIcon} />
                </>
              ) : (
                <>
                  {/* <img src="../images/dashboard_cancel.svg" alt="" /> */}
                  <span>Cancel</span>
                </>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  const textContent = (
    <>
      <div className="in">
        <span>BLS output: </span>
        <span className="text">{formatNum(BLS)}</span>
      </div>
      <div className="time">
        <span className="time-txt">Time left: </span>
        <span className="text">
          {timeMillis ? (
            <Countdown date={timeMillis} renderer={rendererCountDown} />
          ) : (
            // <Countdown date={Date.now() + 5000} renderer={rendererCountDown} />
            "TBA"
          )}
        </span>
      </div>
    </>
  );

  return (
    <>
      {isMobile ? (
        <Collapse
          defaultActiveKey={[index]}
          onChange={onChange}
          expandIconPosition="end"
          className="list-reedem-col"
        >
          <Panel header={textHeader} key="0">
            <p>{textContent}</p>
          </Panel>
        </Collapse>
      ) : (
        <div onClick={handleCancel} className="p-blsDashboard-subheader">
          <span className="text">{formatNum(xBLS)} xBLS</span>
          <span className="text">{formatNum(BLS)} BLS</span>
          <span className="text">
            {timeMillis ? (
              <Countdown date={timeMillis} renderer={rendererCountDown} />
            ) : (
              "TBA"
            )}
          </span>

          {isCompleted ? (
            <>
              <button
                className="p-blsDashboard-cancel"
                onClick={() => handleClaim(ids)}
                disabled={isLoadingClaim}
              >
                {isLoadingClaim ? (
                  <>
                    <span>Waiting</span> {""}
                    <Spin className="spin-modal" indicator={antIcon} />
                  </>
                ) : (
                  <>
                    <span>Claim</span>
                  </>
                )}
              </button>
            </>
          ) : (
            <button
              className="p-blsDashboard-cancel"
              onClick={() => handleUnReedem([ids])}
              disabled={isLoadingCancel}
            >
              {isLoadingCancel ? (
                <>
                  <span>Waiting</span> {""}
                  <Spin className="spin-modal" indicator={antIcon} />
                </>
              ) : (
                <>
                  <span>Cancel</span>
                </>
              )}
            </button>
          )}
        </div>
      )}
    </>
  );
};

const CommingSoon = () => {
  return (
    <div className="p-blsDashboard-wrap w-35">
      <div className="p-blsDashboard">
        <p className="blur-coming">Coming soon</p>
        <div className="blur">
          <h2>Dividends</h2>
          <div>
            <span className="p-blsDashboard-subtext">
              Allocate your xBLS here in order to get your share of the platform
              generated earnings.
            </span>
            <div className="p-blsDashboard-detail">
              <div className="p-blsDashboard-allocation">
                <span className="value-myallocation">My allocation</span>
                <span className="value-allocation">0 xBLS</span>
              </div>
              <div className="p-blsDashboard-manage">
                <img src="../images/dashboard_chart.svg" alt="" />
                <span>Manage</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlsDashboard;
