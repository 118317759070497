import { iNotification } from "react-notifications-component"

const addNotify = (_meg:any, _type:any): iNotification => {
  return {
    message: _meg,
    type: _type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
      pauseOnHover: true,
      click: true,
      touch: true,
    }
  }
}

export default addNotify