import React, { useEffect, useState } from 'react';
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import { SearchChange, Loading } from "../../../components/component";
import { Tabs, Select, Pagination, Row, Col } from 'antd';
import type { PaginationProps } from 'antd';
import ItemAirdrop from './item';
import { Grid, List } from '../../../components/assets/icon';
import useWindowSize from '../../../hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getLaunchpadList, getLaunchpadListJoined, launchPadSelector } from "../../../redux/slicers/launchpadSlice";

import { useActiveWeb3React } from '../../../hooks';
import { CHAINID_CONVERT } from '../../../constants';
import AirdropRight from './AirdropRight';
import AirdropService from '../../../redux/services/AirdropService';

interface Size {
    width: number;
    height: number;
}

const AirdropList: React.FC = () => { 
    const { account , chainId} = useActiveWeb3React();
    const [isSort, setIsSort] = useState<boolean>(false);
    const size: Size = useWindowSize();
    const { Option } = Select;
    const { TabPane } = Tabs;
    const dispatch = useAppDispatch();
    const [dataLoading, setDataLoading] = useState<any>(false)
    const [airdropList, setAirdropList] = useState<any>([]);
    const [totalRow, setTotalRow] = useState(0);
    const [activeTab, setActiveTab] = useState('all');

    const [param, setParam] = useState(
        {
            page: 0,
            size: 9,
            sort: 'id,desc',
            ownerAddress: "",
            status: "ALL",
            networkChain: "ALL",
            keySearch: "",
            joined: false
        }
    );

    const getAirdropList = async (type) => {
        setDataLoading(true);
        let dataFilter: any;

        if(type === "ALL"){
            dataFilter = {
                ...param
            }
        }
        if(type === "MYAIRDROP"){
            dataFilter = {
                ...param,
                ownerAddress: account
            }
        }

        try {
            let res:any = await AirdropService.getAirdropList(dataFilter);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setAirdropList(res.data);

                } else {
                    setDataLoading(false);
                    setAirdropList([]);
                }


            }
        } catch (error) { 
            console.log('Error: ', error); 
        }
        finally {
            setDataLoading(false);
        }
    };
   
    
    useEffect(() => {

        if (activeTab) {
            switch (activeTab) {
                case 'all':
                    getAirdropList('ALL');
                    break;
                case 'my-airdrop':
                    getAirdropList('MYAIRDROP');
                    break;
                default:
                    getAirdropList('ALL');
                    break;
            }
        }

    }, [activeTab, param]);

    const onChange_Pagi = (pageNumber: any) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
    };

    const onChangeTabs = (key: string) => {
        setActiveTab(key);
    };

    const searchAirdrop = (key: any) => {
        setParam({
            ...param,
            keySearch: key.target.value
        });
    };

    const onChangeFilterStatus = (key: any) => {
        setParam({
            ...param,
            keySearch: key.target.value
        });
    };
    
    const operations = <>
        
        <div className="p-airdropList__sort">
            <Select className="c-selectEdit" defaultValue="all" style={{ width: 150, marginRight:'10px' }}>
                <Option value="all">All Status</Option>
                <Option value="Upcoming">Upcoming</Option>
                <Option value="Opening">Opening</Option>
                <Option value="Completed">Completed</Option>
            </Select>
        </div>
    </>;

    return(
        <>
        {dataLoading && <Loading />}
        
            <Row gutter={0}>
                <Col className="gutter-row" xs={24} sm={19} xl={19} md={19}>
                    <div className="p-airdropList">
                        <div className='d-mobile'>
                            <AirdropRight widthItem="50" position="relative"/>
                        </div>
                        <div className="p-airdropList__head ">
                            <Row gutter={16}>
                                <Col className="gutter-row" xs={24} sm={8} xl={8} md={8}>
                                    <h2>Airdrop</h2>
                                </Col>
                                <Col className="gutter-row text-right" xs={24} sm={16} xl={16} md={16}>
                                    <SearchChange placeholder='Search airdrop' onChange={searchAirdrop} />
                                </Col>
                            </Row>
                            <div className='p-airdropList__banner_airdrop'>
                                <img src="../images/airdrop-banner.png"/>
                            </div>
                        </div>
                        <div className="p-airdropList__box">
                            <Tabs className="c-tabEdit" defaultActiveKey="1" onChange={onChangeTabs} tabBarExtraContent={operations}>
                                <TabPane tab="All Airdrops" key="all">
                                    <Row gutter={16}>
                                        
                                        {airdropList && airdropList.length > 0 ? (
                                            <>
                                                {airdropList.map((item: object, key: number) =>  (   
                                                    <ItemAirdrop item={item} key={key}/>
                                                ))}
                                                <Col className="gutter-row" xs={24}>
                                                    <Pagination 
                                                        className="c-pagingEdit mt-15"
                                                        current={param.page + 1}
                                                        defaultCurrent={1}
                                                        total={totalRow}
                                                        pageSize={param.size}
                                                        onChange={onChange_Pagi}
                                                        showSizeChanger={false}
                                                    />
                                                </Col>
                                            </>
                                        ):(
                                            <div className='empty-airdrop'>
                                                <img src="../images/empty-airdrop.png" />
                                                <p>No Airdrop yet</p>
                                            </div>
                                        ) }
                                    </Row>
                                </TabPane>
                                
                                {account ? <TabPane tab="My Airdrops" key="my-airdrop">
                                    <Row gutter={16}>
                                        {airdropList && airdropList.length > 0 ? <>
                                        
                                            {airdropList && airdropList.map((item: object, key: number) => (
                                                <ItemAirdrop item={item} key={key} />
                                            ))}
                                        
                                            <Col className="gutter-row" xs={24}>
                                                <Pagination 
                                                    className="c-pagingEdit mt-15"
                                                    current={param.page + 1}
                                                    defaultCurrent={1}
                                                    total={totalRow}
                                                    pageSize={param.size}
                                                    onChange={onChange_Pagi}
                                                    showSizeChanger={false}
                                                />
                                            </Col>
                                        </> : <>
                                            <div className='empty-airdrop'>
                                                <img src="../images/empty-airdrop.png" />
                                                <p>No My Airdrop</p>
                                            </div>
                                        </>} 
                                    </Row>
                                                           
                                </TabPane> : ''}
                            </Tabs>
                        </div>
                    </div>
                </Col>
                <Col className="gutter-row d-desktop" xs={24} sm={5} xl={5} md={5}>
                    <AirdropRight widthItem="100" position="fixed"/>
                </Col>
            </Row>
            
        
        </>
    )
}

export default AirdropList;