import web3 from "web3";
import BigNumber from "bignumber.js";

const UINT256_MAX =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

export const convertTokenToWei = (value, decimal) => {
  let amount = web3.utils.toWei(value.toString(), "ether");
  if (decimal !== 18)
    amount = new BigNumber(value)
      .times(new BigNumber(10).pow(decimal))
      .toString();
  return amount;
};

export const _approveBLS = async (blsContract, swapBLSToXBLAddress, amount) => {
  const amountTemp = convertTokenToWei(10 * 1e6, 18);
  const result = await blsContract.approve(swapBLSToXBLAddress, amountTemp);
  return result;
};

export const _approveXBLS = async (
  xblsContract,
  swapXBLSToBLAddress,
  amount
) => {
  const amountTemp = convertTokenToWei(10 * 1e6, 18);
  const result = await xblsContract.approve(swapXBLSToBLAddress, amountTemp);
  return result;
};

export const _convertBLS_xBLS = async (swapBLSToXBLSContract, amount) => {
  const amountTemp = convertTokenToWei(amount, 18);
  const result = await swapBLSToXBLSContract.swap(amountTemp);
  return result;
};

export const _saveChanges = async (redeemBLSContract, amount, day) => {
  const amountTemp = convertTokenToWei(amount, 18);
  // console.log('redeemBLSContract', redeemBLSContract)
  // console.log('amountTemp', amountTemp)
  // console.log('day', day)
  const result = await redeemBLSContract.redeem(amountTemp, day);
  return result;
};

export const _userRedeemIndex = async (redeemBLSContract, account) => {
  const result = await redeemBLSContract.userRedeemIndex(account);
  return result;
};

export const _userRedeemIds = async (redeemBLSContract, account, count) => {
  const result = await redeemBLSContract.userRedeemIds(account, count);
  return result;
};

export const _redeemDetails = async (redeemBLSContract, ids) => {
  const result = await redeemBLSContract.redeemDetails(ids);
  return result;
};

export const _unRedeem = async (redeemBLSContract, ids) => {
  console.log('ids', ids)
  const result = await redeemBLSContract.unRedeem(ids);
  return result;
};

export const _claim = async (redeemBLSContract, ids) => {
  console.log('ids', ids)
  const result = await redeemBLSContract.claim(ids);
  return result;
};

export const _calcRewards = async (redeemBLSContract, ids) => {
  const result = await redeemBLSContract.calcRewards(ids);
  return result;
};

export const _totalUserStaking = async (redeemBLSContract, account) => {
  const result = await redeemBLSContract.totalUserStaking(account);
  return result;
};

export function formatNum(num) {
  let scale = 8;
  if (!("" + num).includes("e")) {
    return +(Math.round(num + "e+" + scale) + "e-" + scale);
  } else {
    var arr = ("" + num).split("e");
    var sig = "";
    if (+arr[1] + scale > 0) {
      sig = "+";
    }
    return +(
      Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) +
      "e-" +
      scale
    );
  }
}
