import {
  convertFromWei,
  formatLaunchpadInfo,
  convertToWei,
  formatPrivateSaleInfo,
} from "./utils";
import web3 from "web3";
import { getContract } from "../utils";
const ethers = require("ethers");

export const launchpadCreate = async (
  launchpadDeployContract: any,
  launchpadInfo: any,
  claimInfo: any,
  teamVestingInfo: any,
  dexInfo: any,
  feeInfo: any,
  percertAffiliate: any
) => {
  console.log(
    launchpadInfo,
    claimInfo,
    teamVestingInfo,
    dexInfo,
    feeInfo,
    percertAffiliate,
    { value: feeInfo && feeInfo[0] }
  );
  return await launchpadDeployContract.deployLaunchpad(
    launchpadInfo,
    claimInfo,
    teamVestingInfo,
    dexInfo,
    feeInfo,
    percertAffiliate,
    { value: feeInfo && feeInfo[0] }
  );
};

export const privateSaleCreate = async (
  privateSaleDeployContract: any,
  privateSaleInfo: any,
  vestingInfo: any,
  feeInfo: any,
  fundReleaseToWei: any
) => {
  return await privateSaleDeployContract.deployPrivateSale(
    privateSaleInfo,
    vestingInfo,
    feeInfo,
    fundReleaseToWei,
    { value: feeInfo }
  );
};

export const getPrivateSaleInfo = async (
  launchpadContract: any,
  tokenDecimals: any,
  feeTokenDecimals: any,
  privateSaleAddress: any
) => {
  const transaction = await launchpadContract.getInfo();

  const data: any = formatPrivateSaleInfo(
    transaction,
    tokenDecimals,
    feeTokenDecimals
  );

  return data;
};

export const privateSaleGetState = async (launchpadContract, account) => {
  const transaction = await launchpadContract.privateSaleState();
  return transaction;
};

export const getInvestors = async (launchpadContract) => {
  const transaction = await launchpadContract.getInvestors();
  return transaction;
};
export const getAmountsInvest = async (launchpadContract) => {
  const transaction = await launchpadContract.getAmountsInvest();
  return transaction;
};

export const getListOfWhiteListUsersPS = async (launchpadContract: any) => {
  const listWL: any = await launchpadContract.getWhiteListBuyers();
  return listWL;
};

export const isWhiteListBuyer = async (launchpadContract, account) => {
  const transaction = await launchpadContract.isWhiteListBuyer(account);
  return transaction;
};

export const getPrivateSaleType = async (launchpadContract, account) => {
  const transaction = await launchpadContract.privateSaleType();
  return transaction;
};
export const getRaisedAmountPrivateSale = async (
  launchpadContract,
  account
) => {
  const transaction = await launchpadContract.raisedAmount();
  return transaction;
};
export const getPrivateSaleOwner = async (launchpadContract, account) => {
  if (account) {
    const transaction = await launchpadContract.privateSaleState();
    return transaction;
  }
};

export const approveToken = async (
  tokenSCAbi,
  tokenAddressVal,
  library,
  account,
  spender,
  valueAmount
) => {
  const args = [
    spender,
    web3.utils
      .toBN(2)
      .pow(web3.utils.toBN(256))
      .sub(web3.utils.toBN(1))
      .toString(),
  ];

  const contract = getContract(tokenAddressVal, tokenSCAbi, library, account);

  const transaction = await contract.approve(...args);
  return transaction;
};
export const getDecimalToken = async (
  tokenSCAbi,
  tokenAddressVal,
  library,
  account,
  spender
) => {
  const contract = getContract(tokenAddressVal, tokenSCAbi, library, account);

  const transaction = await contract.decimals();
  return transaction;
};

export const multiSender = async (
  contracts,
  addressToken,
  listAdd,
  listAmount,
  totalAmount,
  tokenDecimal
) => {
  if (addressToken === "0x0000000000000000000000000000000000000000") {
    // console.log("totalAmount", totalAmount);
    // console.log("listAmount", listAmount);
    // console.log("tokenDecimal", tokenDecimal);
    // const amountConvert = totalAmount * 1 * 10 ** tokenDecimal;
    const amountConvert = convertToWei(totalAmount, tokenDecimal);
    // console.log("amountConvert", amountConvert);
    const transaction = await contracts.multiTransferToken(
      addressToken,
      listAdd,
      listAmount,
      amountConvert,
      {
        value: amountConvert,
        // gasLimit: 210000,
      }
    );
    return transaction;
  } else {
    const amountConvert = convertToWei(totalAmount, tokenDecimal);
    const transaction = await contracts.multiTransferToken(
      addressToken,
      listAdd,
      listAmount,
      amountConvert,
      { gasLimit: 10000000 }
    );

    return transaction;
  }
};

export const getAmountApprove = async (
  tokenSCAbi,
  tokenAddressVal,
  library,
  account,
  spender
) => {
  const contract = getContract(tokenAddressVal, tokenSCAbi, library, account);
  const transaction = await contract.allowance(account, spender);
  return transaction;
};

export const getTokenAllowance = async (
  tokenSCAbi,
  tokenAddressVal,
  library,
  account,
  spender
) => {
  const contract = getContract(tokenAddressVal, tokenSCAbi, library, account);

  const allowance = await contract.allowance(account, spender);

  return allowance;
};

export const getOwnerZoneInfo = async (
  launchpadContract: any,
  account: any,
  decimals: any
) => {
  return await launchpadContract.getOwnerZoneInfo(...[account]);
};

export const getJoinInfos = async (
  launchpadContract: any,
  account: any,
  decimals: any
) => {
  return await launchpadContract.joinInfos(...[account]);
};

export const getUserClaimAble = async (
  launchpadContract: any,
  account: any,
  decimals: any
) => {
  const data = await launchpadContract.getUserClaimAble(...[account]);
  return Number(convertFromWei(data, decimals));
};

export const getTeamCliffVesting = async (
  launchpadContract: any,
  decimals: any
) => {
  const data = await launchpadContract.teamCliffVesting();
  return Number(convertFromWei(data, decimals));
};

export const claimTokens = async (launchpadContract: any) => {
  // const args = [];

  // const estimatedGas = await launchpadContract.estimateGas.claimFund(...args);
  // return await launchpadContract.claimTokens(...args, {
  //   gasLimit: estimated,
  // });
  return await launchpadContract.claimFund();
};

export const contribute = async (
  launchpadContract: any,
  amount: any,
  decimals: any,
  currencyPay: any
) => {
  const amountConvert = convertToWei(amount, decimals);
  if (currencyPay === "ETH") {
    return await launchpadContract.contribute(amountConvert, {
      // gasLimit: 210000,
      value: amountConvert,
      // gasLimit: 5000000,
    });
  } else {
    return await launchpadContract.contribute(amountConvert, {
      // gasLimit: 210000,
      // value: amountConvert,
      // gasLimit: 5000000,
    });
  }
};

export const _investInfos = async (launchpadContract: any, account: any) => {
  return await launchpadContract.investInfos(account);
};

export const _totalClaimed = async (launchpadContract: any) => {
  return await launchpadContract.unlockedAmount();
};

export const withdrawableTokens = async (launchpadContract: any) => {
  return await launchpadContract.withdrawableTokens();
};

export const allInvestorCount = async (launchpadContract: any) => {
  return await launchpadContract.allInvestorCount();
};

export const emergencyWithdrawContribute = async (launchpadContract: any) => {
  return await launchpadContract.emergencyWithdrawContribute();
};

export const withdrawContribute = async (launchpadContract: any) => {
  return await launchpadContract.withdrawContribute();
};

export const finalizeLaunchpad = async (launchpadContract: any) => {
  return await launchpadContract.finalize();
};

export const cancelLaunchpad = async (launchpadContract: any) => {
  return await launchpadContract.cancel();
};

export const setWhitelistPoolPrivateSale = async (
  launchpadContract: any,
  wlPool: any,
  holdingTokenAddress: any,
  amount: any,
  decimals: any
) => {
  const args = [wlPool, holdingTokenAddress, convertToWei(amount, decimals)];
  return await launchpadContract.setWhitelistPool(...args);
};

export const addWhiteListUsers = async (
  launchpadContract: any,
  listAddress: any
) => {
  return await launchpadContract.setWhitelistBuyers(listAddress);
};

export const removeWhiteListUsers = async (
  launchpadContract: any,
  listAddress: any
) => {
  return await launchpadContract.removeWhitelistBuyers(listAddress);
};

export const getListOfWhiteListUsers = async (launchpadContract: any) => {
  const _allAllocationCount: any = await launchpadContract.allAllocationCount();
  console.log(
    "🚀 ~ file: launchpad.ts:95 ~ getListOfWhiteListUsers ~ _allAllocationCount:",
    Number(_allAllocationCount)
  );

  let listWL;
  if (_allAllocationCount == 0) {
    listWL = [];
  } else {
    listWL = launchpadContract.getAllocations(0, Number(_allAllocationCount));
  }

  return listWL;
};

export const setLaunchpadAllocations = async (
  launchpadContract: any,
  listAddress: any,
  listAmounts: any
) => {
  return await launchpadContract.setAllocations(listAddress, listAmounts);
};

export const getJoinedUsers = async (launchpadContract: any) => {
  return await launchpadContract;
};

export const getGsLock = async (launchpadContract: any) => {
  return await launchpadContract.gsLock();
};

export const getLpLockId = async (launchpadContract: any) => {
  return await launchpadContract.lpLockId();
};

export const getTeamLockId = async (launchpadContract: any) => {
  return await launchpadContract.teamLockId();
};

export const getRouterAddress = async (launchpadContract: any) => {
  return await launchpadContract.routerAddress();
};

export const setClaimTime = async (launchpadContract: any, claimTime: any) => {
  return await launchpadContract.setClaimTime(claimTime);
};

export const getWhitelistPool = async (launchpadContract: any) => {
  return await launchpadContract.whitelistPool();
};

export const getInfoLaunchpad = async (
  launchpadContract: any,
  tokenDecimals: any,
  feeTokenDecimals: any
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const infoLaunchpad = {
        softCap: await launchpadContract?.softCap(),
        hardCap: await launchpadContract?.hardCap(),
        startTime: await launchpadContract?.startTime(), // uint256
        endTime: await launchpadContract?.endTime(), // uint256
        state: await launchpadContract?.state(),
        raisedAmount: await launchpadContract?.raisedAmount(),
        feeToken: await launchpadContract?.feeToken(),
        listingTime: await launchpadContract?.listingTime(),
        whitelistPool: await launchpadContract?.whitelistPool(),
        holdingToken: await launchpadContract?.holdingToken(),
        holdingTokenAmount: await launchpadContract?.holdingTokenAmount(),
        maxInvest: await launchpadContract?.maxInvest(),
        minInvest: await launchpadContract?.minInvest(),
      };
      const data: any = formatLaunchpadInfo(
        infoLaunchpad,
        tokenDecimals,
        feeTokenDecimals
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
