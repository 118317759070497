import IconMetamask from '../../assets/images/icon-metamask.svg';
import IconBinance from '../../assets/images/icon-binanceChain.svg';
import IconSafepal from '../../assets/images/icon-safepal.svg';
import IconWalletConnect from '../../assets/images/icon-walletConnect.svg';
import IconTrust from '../../assets/images/icon-trust.svg';

const ListWallet:Array<object> = [
    {title: 'Meta Mask', icon: IconMetamask, connectorId: 'injected'},
    {title: 'Binance Chain Wallet', icon: IconBinance, connectorId: 'bsc'},
    {title: 'SafePal Wallet', icon: IconSafepal, connectorId: 'injected', status:"dark"},
    {title: 'Wallet Connect', icon: IconWalletConnect, connectorId: 'walletconnect'},
    {title: 'Trust Wallet', icon: IconTrust, connectorId: 'injected'},
];


export default ListWallet;