import React, { useState } from "react";
import ConnectNetWork from './connectNetwork';
import {useSelector, useDispatch} from 'react-redux'; 
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getHeader, headerSelector } from "../../../redux/slicers/headerSlice";
import { CHAINID_FULLNAME, MAPPING_CHAINID_DECIMAL } from "../../../constants";
import netLogoBSC from '../../assets/images/BSC.png';
import netLogoPOLY from '../../assets/images/POLY.png';
import netLogoETH from '../../assets/images/ETH.png';
import { useActiveWeb3React } from "../../../hooks";
import netLogoArb from '../../assets/images/arbitrum.svg';

interface INetwork {
     header: string
}

const Network = () => {
    const { account, chainId } = useActiveWeb3React(); 
    const [isPopup, setIspopup] = useState<boolean>(false);
    const [connectNetwork, setConnectNetwork] = useState<object>();
    const dispatch = useAppDispatch();
    const { header } = useAppSelector(headerSelector);  
    const handleToggle = () => {
        setIspopup(!isPopup); 
        dispatch(getHeader("connectNetwork"));
    } 

    let netName = '';
    let netLogo = '';
    switch (chainId) {
        case MAPPING_CHAINID_DECIMAL.bsc:
            netName = CHAINID_FULLNAME.bsc;
            netLogo = netLogoBSC;
            break;
            
        case MAPPING_CHAINID_DECIMAL.poly:
            netName = CHAINID_FULLNAME.poly;
            netLogo = netLogoPOLY;
            break;

        case MAPPING_CHAINID_DECIMAL.eth:
            netName = CHAINID_FULLNAME.eth;
            netLogo = netLogoETH;
            break;

        case MAPPING_CHAINID_DECIMAL.bsctestnet:
            netName = CHAINID_FULLNAME.bsctestnet;
            netLogo = netLogoBSC;
            break;

        case MAPPING_CHAINID_DECIMAL.arb:
            netName = CHAINID_FULLNAME.arb;
            netLogo = netLogoArb;
            break;

        case MAPPING_CHAINID_DECIMAL.arbtestnet:
            netName = CHAINID_FULLNAME.arbtestnet;
            netLogo = netLogoArb;
            break;

        default:
            netName = CHAINID_FULLNAME.arb;
            netLogo = netLogoArb;
    }
    
    return (
        <div className="btn_header"> 
            <button onClick={()=>handleToggle()} className='btn_header_mainet'>
                <img src={netLogo} alt={netName} />
                <span>{netName}</span>
            </button>  
            {header === "connectNetwork"? 
                <ConnectNetWork setConnectNetwork={setConnectNetwork}/> 
            :null} 
        </div>
    );
}

export default Network;
