import React, { useEffect, useState } from "react"; 
import IconLaunchpad from '../../assets/images/sidebar-icon-launchpad.svg';
import IconPrivateSale from '../../assets/images/sideBar-icon-privateSale.svg';
import { useAppDispatch } from "../../../redux/hooks";
import { getHeader } from "../../../redux/slicers/headerSlice";
import { useConnectWallet, useActiveWeb3React } from "../../../hooks";
import { useWeb3React } from "@web3-react/core";
import { Button } from "../../component";
import {useNavigate, useSearchParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Store } from "react-notifications-component";
import addNotify from "../../commons/Notify/addNotify";

const ListMyTool:Array<object> = [
    {title: 'My Token', icon: IconPrivateSale, connectorId: 'injected'},
    {title: 'My Lauchpads', icon: IconLaunchpad, connectorId: 'bsc'},
    // {title: 'My Private Sale', icon: IconPrivateSale, connectorId: 'injected', status:"dark"},
    // {title: 'My Airdrop', icon: IconAirdrop, connectorId: 'walletconnect'},
];

interface IConnectWallet {
    setConnectWallet: any, 
}

const InfoWallet : React.FC<IConnectWallet> =(props: IConnectWallet)=> {
    const [balanceOfToken, setBalanceOfToken] = useState<number>(0);
    const dispatch = useAppDispatch();
    const { walletLogout } = useConnectWallet();
    const { account } = useActiveWeb3React();
    const [searchParams, setSearchParams] = useSearchParams();
	const context = useWeb3React();
	const { active } = context;
    const accountEllipsis = active ? `${account?.substring(0, 4)}...${account?.substring(account.length - 4)}` : 'Connect Wallet';
    let navigate = useNavigate();
    const handleLogout = ()=>{
        props.setConnectWallet(false);
        dispatch(getHeader("logout"));
        walletLogout();
        setSearchParams({chain: ''});
    }

    const handleClick= (value:string) => {
        dispatch(getHeader(""))
        switch (value) {
            case "My Token":
                navigate("/token/my-token")
                break;
            case "My Lauchpads":
                navigate("/launchpad/my-launchpad")
                break; 
            default:
                break;
        } 
    }
    const handleCopy = () => {
        Store.addNotification(addNotify("Copied !", "success"));
    };
    const elmInfoWallet = ListMyTool.map((item: any,key :number) => (
        <div onClick={() => handleClick(item?.title)} className="tabwallet" key={key} >
            <span className="img"><img src={item.icon} alt={item.title}/></span>
            <span className="title">{item.title}</span>
        </div>
    ));

    // async function getBalanceToken(account, token) {
    //     let minABI:any = [
    //         // balanceOf
    //         {
    //             "constant":true,
    //             "inputs":[{"name":"_owner","type":"address"}],
    //             "name":"balanceOf",
    //             "outputs":[{"name":"balance","type":"uint256"}],
    //             "type":"function"
    //         },
    //     ];
    //     let contract = new w3.eth.Contract(minABI,token);
    //     return await contract.methods.balanceOf(account).call().then(function (result) {
    //         setBalanceOfToken(result);
    //     });
    // }

    // useEffect(() => { 
    //     if(account && tokenSC?.tokenAddress) {
    //         getBalanceToken(account, tokenSC?.tokenAddress)
    //     }
    // }, [account, tokenSC?.tokenAddress]);
    
    return (
        <div className="popupSP">
        <ul className='popupwallet'>
            <div className="smalltriangle"/>
            <div className="infowallet">
                <img src="../images/wallet-icon.svg" alt=""/>
                <div className="wallet">
                    <span className="wallet_address">
                        {accountEllipsis}

                        <CopyToClipboard
                            text={account}
                            onCopy={() => handleCopy()}
                          >
                            <button className="copy-btn">
                              <img src="../images/icon-copy.svg" alt="" />
                            </button>
                        </CopyToClipboard>
                    </span>
                    {/* <span className="wallet_amount">-- BLS</span> */}
                </div>
            </div>
            {elmInfoWallet}
            <div className="logout">
                <Button type="grey" text="Disconnect" onClick={()=>handleLogout()}/>
            </div>
        </ul>
        </div>
    )
}
export default InfoWallet;