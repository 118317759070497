//import React, { useState } from 'react';
import { Button, InputChange, SearchChange } from './component';

const PageComponent = () => {
    return(
        <div className="p-pageComponent">
            <div className="p-pageComponent__item">
                <h3>Default</h3>
                <Button text='Button Text' />
            </div>
            <div className="p-pageComponent__item">
                <h3>Default | disabled=true</h3>
                <Button text='Button Text' disabled={true} />
            </div>
            <div className="p-pageComponent__item">
                <h3>Default | iconLeft=Plus</h3>
                <Button text='Button Text' iconLeft='Plus' />
            </div>
            <span></span>
            <div className="p-pageComponent__item">
                <h3>type=blue</h3>
                <Button text='Button Text' type='blue' />
            </div>
            <div className="p-pageComponent__item">
                <h3>type=blue | disabled=true</h3>
                <Button text='Button Text' type='blue' disabled={true} />
            </div>
            <div className="p-pageComponent__item">
                <h3>type=blue | iconRight=Plus</h3>
                <Button text='Button Text' type='blue' iconRight='Plus' />
            </div>
            <span></span>
            <div className="p-pageComponent__item">
                <h3>type=grey</h3>
                <Button text='Button Text' type='grey' />
            </div>
            <div className="p-pageComponent__item">
                <h3>type=grey | disabled=true</h3>
                <Button text='Button Text' type='grey' disabled={true} />
            </div>
            <span></span>
            <div className="p-pageComponent__item">
                <h3>type=border</h3>
                <Button text='Button Text' type='border' />
            </div>
            <div className="p-pageComponent__item">
                <h3>type=border | disabled=true</h3>
                <Button text='Button Text' type='border' disabled={true} />
            </div>
            <span></span>
            <div className="p-pageComponent__item">
                <h3>type=white</h3>
                <Button text='Button Text' type='white' />
            </div>
            <div className="p-pageComponent__item">
                <h3>type=white | disabled=true</h3>
                <Button text='Button Text' type='white' disabled={true} />
            </div>
            <span></span>
            <div className="p-pageComponent__item">
                <h3>type=greyBorder</h3>
                <Button text='Cancel' type='greyBorder' />
            </div>
            <div className="p-pageComponent__item">
                <h3>type=greyBorder | disabled=true</h3>
                <Button text='Cancel' type='greyBorder' disabled={true} />
            </div>
            <span></span>
            <div className="p-pageComponent__item">
                <h3>Default</h3>
                <InputChange title='Name' />
            </div>
            <div className="p-pageComponent__item">
                <h3>Default</h3>
                <InputChange title='Name' value='aaa' />
            </div>
            <div className="p-pageComponent__item">
                <h3>Default | disabled=true</h3>
                <InputChange title='Name' disabled={true} />
            </div>
            <span></span>
            <div className="p-pageComponent__item">
                <h3>Default</h3>
                <SearchChange />
            </div>
        </div>
    )
}

export default PageComponent;