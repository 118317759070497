import axios from "axios";
import { Tokens } from "../../constants"; 
const refreshToken = async () => {
    let account = localStorage.getItem("account");
    try {
        const response = await axios.post(process.env.REACT_APP_API_URL + "/authenticate/address", {
            address: account,
        });
    
        const { id_token } = response.data;
    
        if (!id_token) {
            localStorage.removeItem(Tokens.AUTHUSER);
        }
    
        localStorage.setItem(Tokens.AUTHUSER, id_token);
    
        return id_token;
    } catch (error) {
        localStorage.removeItem(Tokens.AUTHUSER);
    }
};

export default refreshToken;