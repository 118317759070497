import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CampaignService from "../services/CampaignService";

// ================================================
// ==                  ACTION                    ==
// ================================================
export const getCampaigns = () => {
    return async dispatch => {
        dispatch(setLoading(true));
        dispatch(setCampaigns([{"aaa": "aaa"}, {"bbb": "bbb"}]));
        // await CampaignService.getCampaignsByStatus(payload)
        // .then((res)=>{
        //     dispatch(setLoading(false));
        //     dispatch(setCampaigns(res.data));
        // })
        // .catch((error:any)=>{
        //     dispatch(setErrors(error));
        //     dispatch(setLoading(false));
        // });
    }
}

// ================================================
// ==                REDUX STORE                 ==
// ================================================
export interface CampaignState {
    campaigns: object[];
    loading: boolean;
    errors: string;
}

const initialState: CampaignState = {
    campaigns: [],
    loading: false,
    errors: "",
}

const campaignSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setCampaigns: (state, { payload }: PayloadAction<object[]>) => {
      state.campaigns = payload
    },
  },
})

export const { setLoading, setErrors, setCampaigns } = campaignSlice.actions

export default campaignSlice.reducer

export const campaignsSelector = (state: { campaignsStore: CampaignState }) => state.campaignsStore