import { createSlice, PayloadAction } from "@reduxjs/toolkit"; 
import LaunchPadService from "../services/LaunchPadService";

// ================================================
// ==                  ACTION                    ==
// ================================================
 

export const createLauchpad = (payload:PayloadAction, onCallback:Function) => {
  return async dispatch => {
      dispatch(setLoading(true)); 
      await LaunchPadService.createLauchpad(payload)
      .then((res:any)=>{
        console.log(res);
        onCallback({result: "SUCCESS", data: res?.data});
      })
      .catch((err:Error)=>{
        console.log(err);
        onCallback({result: "ERROR", error: err});
      });
  }
}


export const verifyToken = (payload:any) => {
  return async dispatch => {
      dispatch(setLoading(true)); 
      dispatch(setVerifyTokenStorage(payload));  
  }
}

export const lauchpadInfo = (payload:any) => {
  return async dispatch => {
      dispatch(setLoading(true)); 
      dispatch(setLauchpadInfo(payload));  
  }
}

export const additionInfo = (payload:any) => {
  return async dispatch => {
      dispatch(setLoading(true)); 
      dispatch(setAdditionInfo(payload));  
  }
} 


// ================================================
// ==                REDUX STORE                 ==
// ================================================

type StoreObject = {
  [key: string]: void | string | number | Date | any;
};

export interface createLauchpadState { 
    createLauchpad:StoreObject;
    tokenStorage: StoreObject;
    verifyTokenStorage: StoreObject;
    lauchpadInfoStorage: StoreObject;
    additionInfoStorage: StoreObject; 
    loading: boolean;
    errors: string;
}

const initialState: createLauchpadState = { 
    createLauchpad:{},
    tokenStorage: {},
    verifyTokenStorage: {},
    lauchpadInfoStorage: {},
    additionInfoStorage: {}, 
    loading: false,
    errors: "",
}

const createLauchpadSlice = createSlice({
  name: "createLauchpads",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    // setErrors: (state, { payload }: PayloadAction<string>) => {
    //   state.errors = payload
    // },
    setCreateLauchpad: (state, { payload }: PayloadAction<StoreObject>) => {
      state.createLauchpad = payload
    },

    // setTokenStorage: (state, { payload }: PayloadAction<StoreObject>) => {
    //   state.tokenStorage = payload
    // },

    setVerifyTokenStorage: (state, { payload }: PayloadAction<StoreObject>) => {
      state.verifyTokenStorage = payload
    },

    setLauchpadInfo: (state, { payload }: PayloadAction<any>) => {
      state.lauchpadInfoStorage = payload
    },

    setAdditionInfo: (state, { payload }: PayloadAction<StoreObject>) => {
      state.additionInfoStorage = payload
    }, 
  },
})

export const { setLoading, setVerifyTokenStorage, setLauchpadInfo, setAdditionInfo, setCreateLauchpad } = createLauchpadSlice.actions

export default createLauchpadSlice.reducer

export const createLaunchpadSelector = (state: { createLaunchpadStore: createLauchpadState }) => state.createLaunchpadStore; 
 