import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Store } from 'react-notifications-component';
import addNotify from "../commons/Notify/addNotify";
import { formatNumber } from "../../utils/formatNumber";
import useWindowSize from "../../hooks/useWindowSize";
import { convertFromWei } from "../../contracts/utils";
import { useGSLockContract } from "../../hooks/useContract";
import { SMART_CONTRACT_ADDRESS } from "../../constants";
import { cumulativeLockInfo } from "../../contracts/goldSaleLock";
import { formatDate } from "../../utils/formatDate";
import { getBalanceOf } from "../../contracts/pancakePair";
import { usePancakePairContract } from "../../hooks/useContract";
import { useActiveWeb3React } from "../../hooks";
interface Props {
    data: any;
    type: string;
}

interface Size {
    width: number;
    height: number;
}

const ItemTable: React.FC<Props> = ({ data, type }) => {
    const size: Size = useWindowSize();
    const { chainId, account } = useActiveWeb3React(); 
    const [toggle, setToggle] = useState<boolean>(false); 
    const lockAddress = chainId && SMART_CONTRACT_ADDRESS[chainId]?.GS_LOCK_ADDRESS;
    const gsLockContract = useGSLockContract(lockAddress); 
     
    const [lockedAmount, setLockedAmount] = useState<number>(0);
    const [totolLocked, setTotolLocked] = useState<number>(0);
    const pancakePairContract = usePancakePairContract(data && data?.lpTokenAddress);   
    
    useEffect(()=>{
        if(type === 'token') {
            (async () => {
                await cumulativeLockInfo(gsLockContract, data?.tokenAddress)
                .then((res:any) => {
                    setLockedAmount(Number(convertFromWei(res?.amount, data?.decimals)));
                });
            })();
        }
        else {
            (async () => {
                await cumulativeLockInfo(gsLockContract, data?.lpTokenAddress)
                .then((res:any) => {
                    setLockedAmount(Number(convertFromWei(res?.amount, data?.decimals)));
                });
            })();
            (async () => {
                await getBalanceOf(pancakePairContract, account)
                    .then((res: any) => {
                        setTotolLocked(Number(convertFromWei(res, data?.decimals)));
                    });
            })();
        }        
    }, [gsLockContract, data?.tokenAddress, data?.lpTokenAddress, pancakePairContract, chainId]);
    

    const handleCopy = () => {
        Store.addNotification(addNotify('Copied !', 'success'));
    }

    const handleNetwork = (network:any) => {
        let networkName:any = '';
        if(network === 'BSCTESTNET') {
            networkName = <span className="is-network"><img src="../images/icon-bnb.svg" alt="" />BSC TESTNET</span>;
        } else if(network === 'BSC') {
            networkName = <span className="is-network"><img src="../images/icon-bnb.svg" alt="" />Binance Smart Chain</span>;
        } else if(network === 'ETH') {
            networkName = <span className="is-network"><img src="../images/icon-eth.svg" alt="" />Ethereum</span>;
        } else if(network === 'POLY') {
            networkName = <span className="is-network"><img src="../images/icon-polygon.svg" alt="" />Polygon</span>;
        } else if(network === 'ARBTESTNET') {
            networkName = <span className="is-network"><img src="../images/arbitrum.svg" alt="" />ARB TESTNET</span>;
        } else if(network === 'ARB') {
            networkName = <span className="is-network"><img src="../images/arbitrum.svg" alt="" />Arbtrum</span>;
        }

        return networkName;
    }

    if(type === 'lp') {
        return(
            <div className={`c-tableToken__row ${toggle ? 'is-toggle' : ''}`}>
                <button className={`c-tableToken__toggle ${toggle ? 'is-toggle' : ''}`} onClick={() => setToggle(!toggle)}><span></span>{data?.lpTokenName ? data?.lpTokenName : '--'}</button>
                <div className={`c-tableToken__content ${toggle ? 'is-toggle' : ''}`}>
                    <p className="is-ellipsis"><span>LP Token Name:</span><Link to={`/lock-records/${data?.lpTokenAddress}?type=${type}`}>{data?.lpTokenName ? data?.lpTokenName : '--'}</Link></p>
                    <p><span>Pair:</span>{data?.pair ? data?.pair : '--'}</p>
                    <p>
                        <span>LP Token Address:</span>
                        <a href={`https://testnet.bscscan.com/address/${data?.lpTokenAddress}`}>
                            {data?.lpTokenAddress?.substring(0,4) + "..." + data?.lpTokenAddress?.substr(data?.lpTokenAddress?.length - 4)}
                        </a>
                    </p>
                    <p><span>Dex:</span>{data?.dex ? data?.dex : '--'}</p>
                    <p><span>Total LP:</span>{totolLocked ? formatNumber(Number(totolLocked) + Number(lockedAmount)) : '--'}</p>
                    <p><span>Total LP Lock:</span>{formatNumber(lockedAmount)}</p> 
                    <p><span>Lock Date (UTC):</span>{data?.lockDate ? formatDate(data?.lockDate) : '--'}</p>
                    <p><span>Unlock Date (UTC):</span>{data?.unlockDate ? formatDate(data?.unlockDate) : '--'}</p>
                </div>
            </div>
        )
    }
   
    return(
        <div className={`c-tableToken__row ${toggle ? 'is-toggle' : ''}`}>
            <button className={`c-tableToken__toggle ${toggle ? 'is-toggle' : ''}`} onClick={() => setToggle(!toggle)}><span></span>{data?.name}</button>
            <div className={`c-tableToken__content ${toggle ? 'is-toggle' : ''}`}>
                <p className="is-ellipsis"><span>Token name:</span><Link to={`/lock-records/${data?.tokenAddress}?type=${type}`}>{data?.name}</Link></p>
                <p>
                    <span>Token Address:</span>
                    <CopyToClipboard text={data?.tokenAddress} onCopy={() => handleCopy()}>
                        <button>{data?.tokenAddress?.substring(0,4) + "..." + data?.tokenAddress?.substr(data?.tokenAddress?.length - 4)}</button>
                    </CopyToClipboard>
                </p>
                <p><span>Anti-bot:</span>{data?.antiBotEnable ? <Link to={data?.antiBotId ? `/anti-bot/${data?.antiBotId}` : `/anti-bot?token=${data?.tokenAddress}` }>Anti-bot</Link> : '--'}</p>
                <p className="is-ellipsis"><span>Network:</span>{handleNetwork(data?.networkChain)}</p>
                <p><span>Total Supply:</span>{data?.totalSupply ? formatNumber(data?.totalSupply) : '--'}</p>
                <p><span>Total Token Lock:</span>{formatNumber(lockedAmount)}</p> 
                <p><span>Type:</span>{data?.type ? data?.type : '--'}</p>
                <p className="is-ellipsis">
                    <span>Launchpad:</span>
                    {data?.launchpadHandle ? (
                        <Link to={`/launchpad/${data?.launchpadHandle}`}>{data?.launchpadName ? data?.launchpadName : '--'}</Link>
                    ):(
                        <>--</>
                    )}
                    
                </p>
            </div>
        </div>
    )
}

export default ItemTable;
