import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Loading, SearchChange } from "../../components/component";
import { Pagination } from "antd";
import type { PaginationProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { formatNumber } from "../../utils/formatNumber";
import useWindowSize from "../../hooks/useWindowSize";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getLiquidities, liquiditySelector } from "../../redux/slicers/liquiditySlice";
import { formatDate } from "../../utils/formatDate";
import ItemTable from "../../components/tokenLpTable/itemTable"; 

// interface LiquidityObject {
//     id?: number;
//     ownerAddress?: string;
//     lpTokenName?: string;
//     pair?: string;
//     lpTokenAddress?: string;
//     lockAmount?: number;
//     type?: string;
//     lockDate?: string;
//     unlockDate?: string;
//     linkLiquidity?: string;
//     linkWebScan?: string;
//     dex?: string;
// }

interface Size {
    width: number;
    height: number;
}

// const columns: ColumnsType<LiquidityObject> = [
//     {
//         title: 'LP Token Name',
//         dataIndex: 'lpTokenName',
//         key: 'lpTokenName',
//         render: (_, record) => <Link to={`/lock-records/${record?.lpTokenAddress}?type=lp`}>{record?.lpTokenName}</Link>,
//     }, {
//         title: 'Pair',
//         dataIndex: 'pair',
//         key: 'pair',
//         ellipsis: true,
//     }, {
//         title: 'LP Token Address',
//         dataIndex: 'lpTokenAddress',
//         key: 'lpTokenAddress',
//         ellipsis: true,
//         render: text => text ? <a href={`${text}`}>{text?.substring(0,4) + "..." + text?.substr(text?.length - 4)}</a> : '--',
//     }, {
//         title: 'Dex',
//         dataIndex: 'dex',
//         key: 'dex',
//         ellipsis: true,
//         render: text => text ? text : '--',
//     }, {
//         title: 'Locked Amount',
//         dataIndex: 'lockAmount',
//         key: 'lockAmount',
//         ellipsis: true,
//         render: text => formatNumber(text),
//     }, {
//         title: 'Values Lock ($)',
//         dataIndex: 'type',
//         key: 'type',
//         ellipsis: true,
//     }, {
//         title: 'Lock Date (UTC)',
//         dataIndex: 'lockDate',
//         key: 'lockDate',
//         ellipsis: true,
//         render: text => formatDate(text),
//     }, {
//         title: 'Unlock Date (UTC)',
//         dataIndex: 'unlockDate',
//         key: 'unlockDate',
//         ellipsis: true,
//         render: text => formatDate(text),
//     },
// ];

// const columnsSP: ColumnsType<LiquidityObject> = [
//     {
//         title: 'LP Token Name',
//         dataIndex: 'lpTokenName',
//         key: 'lpTokenName',
//         ellipsis: true,
//     }
// ];

const MyLiquidity: React.FC = () => {
    const [isPager, setIsPager] = useState<any>(1);
    const size: Size = useWindowSize();
    const dispatch = useAppDispatch();
    const { liquidities, liquidityLoading, liquiditiesCount, liquidityErrors } = useAppSelector(liquiditySelector); 
    
    useEffect(() => {
        const payload:any = {
            "page": 0,
            "size": 10,
            "sort": '',
            "keySearch": ''
        }
        dispatch(getLiquidities(payload));
    }, [dispatch]);

    useEffect(() => {
        const payload:any = {
            "page": isPager-1,
            "size": 10,
            "sort": '',
            "keySearch": ''
        }
        dispatch(getLiquidities(payload));
    }, [dispatch, isPager]); 

    const onChangePager: PaginationProps['onChange'] = (pageNumber) => {
        setIsPager(pageNumber);
    };

    return(
        <>
        {liquidityLoading && <Loading />}
        <div className="p-myToken">
            <div className="p-myToken__head">
                <h3>My Liquidity Lock</h3>
                <Link to={'/liquidity/create-liquidity'}>Create Liquidity Lock</Link>
            </div>
            <div className="p-myToken__box">
                {/* {liquidities && liquidities.length > 0 ? <>
                    <SearchChange placeholder='Search token address' />
                    {size.width >= 991 ? <Table columns={columns} dataSource={liquidities} /> : <Table
                        columns={columnsSP}
                        dataSource={liquidities}
                        expandedRowRender={record => <div className="p-myToken__tableSP">
                            <p>Pair:<span>{record?.pair}</span></p>
                            <p>LP Token Address:<span>{record?.lpTokenAddress ? <a href={`aaa`}>{record?.lpTokenAddress?.substring(0,4) + "..." + record?.lpTokenAddress?.substr(record?.lpTokenAddress?.length - 4)}</a> : '--'}</span></p>
                            <p>Dex:<span>{record?.dex}</span></p>
                            <p>Locked Amount:<span>{formatNumber(record?.lockAmount)}</span></p>
                            <p>Values Lock ($):<span>{record?.type}</span></p>
                            <p>Lock Date (UTC):<span>{formatDate(record?.lockDate)}</span></p>
                            <p>Unlock Date (UTC):<span>{formatDate(record?.unlockDate)}</span></p>
                        </div>}
                    />}
                    
                </> : <div className="c-nodata">
                    <img src="../images/nodata-lp.svg" alt="No data"/>
                    <p>
                        My Liquidity Lock
                        <span>This is where you will manage your tokens that you created on the BlueSale</span>
                    </p>
                </div>} */}
                {liquidities && liquidities.length > 0 ? <>
                    <SearchChange placeholder='Search token address' />
                    <div className="c-tableToken">
                        <div className="c-tableToken__head">
                            {size.width >= 767 ? 
                            <>
                                <p>LP Token Name</p>
                                <p>Pair</p>
                                <p>LP Token<br/>Address</p>
                                <p>Dex</p>
                                <p>Total LP</p>
                                <p>Total LP Lock</p>
                                <p>Lock Date<br/>(UTC)</p>
                                <p>Unlock Date<br/>(UTC)</p>
                            </> : <>
                                <p>Name</p>
                            </>}                            
                        </div>
                        {liquidities && liquidities.length > 0 && liquidities.map((item:any, key: number) => (
                            <ItemTable data={item} key={key} type='lp' />
                        ))}
                    </div>
                </> : <div className="c-nodata">
                    <img src="../images/img-nodata.svg" alt="No data"/>
                    <p>
                        My Liquidity
                        <span>This is where you will manage your tokens that you created on the BlueSale</span>
                    </p>
                </div>}
                {Number(liquiditiesCount) > 0 ? <Pagination
                    className="c-pagingEdit"
                    defaultCurrent={1}
                    total={Number(liquiditiesCount)}
                    pageSize={10}
                    current={isPager}
                    onChange={onChangePager}
                /> : ''}
            </div>
        </div>
        </>
    )
}

export default MyLiquidity;