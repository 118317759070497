import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import { SearchChange } from "../../components/component";
import { Tabs, Select, Pagination } from 'antd';
import type { PaginationProps } from 'antd';
import ItemListPrivate from "./itemList";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getPrivateList, getPrivateListJoined, launchPadSelector } from "../../redux/slicers/launchpadSlice";
import { useActiveWeb3React } from "../../hooks";
import { CHAINID_CONVERT } from "../../constants";
import BluesaleRoundsService from '../../redux/services/bluesaleRoundsService';

const PrivateSaleList = () => {
    const { account, chainId } = useActiveWeb3React();
    const [isSort, setIsSort] = useState<boolean>(false);
    const [isPager, setIsPager] = useState<any>(1);
    const [filterSearch, setFilterSearch] = useState<any>("");
    const [filterStatus, setFilterStatus] = useState<string>('ALL');
    const [filterChain, setFilterChain] = useState<string>('ARB');
    const [filterSort, setFilterSort] = useState<string>('');
    const [isTabs, setIsTabs] = useState<number>(1);
    const { TabPane } = Tabs;
    const { Option } = Select;
    const dispatch = useAppDispatch();
    const { privateSale, privateSaleCount, privateJoined } = useAppSelector(launchPadSelector);

    useEffect(() => {
        const payloadList: any = {
            ownerAddress: '',
            status: filterStatus,
            networkChain: filterChain,
            page: isPager - 1,
            size: 10,
            sort: 'id',
            keySearch: filterSearch,
            extend: filterSort,
        }
        const payloadJoined: any = {
            "ownerAddress": account,
            "status": filterStatus,
            "networkChain": filterChain,
            "keySearch": filterSearch,
            "extend": filterSort
        }
        const pagerJoined: number = 0;
        const sizeJoined: number = 100;
        const sortJoined: string = 'id';

        if (isTabs === 1) {
            dispatch(getPrivateList(payloadList));
        } else if (isTabs === 2) {
            if (account) {
                dispatch(getPrivateListJoined(payloadJoined, pagerJoined, sizeJoined, sortJoined));
            }
        }
    }, [dispatch, account, filterStatus, filterChain, filterSearch, filterSort, isTabs]);

    const [listTrending, setListTrending]: any = useState([])
    const getTrending = async () => {
        await BluesaleRoundsService.getPrivateSaleTrending().then((res) => {
            setListTrending(res.data)
        })
    }
    useEffect(() => {
        getTrending()
    }, [])


    const onChangePager: PaginationProps['onChange'] = (pageNumber) => {
        setIsPager(pageNumber);
    };

    const onChangeStatus = (value: string) => {
        setFilterStatus(value);
    };

    const onChangeChain = (value: string) => {
        setFilterChain(value);
    };

    const onChangeTabs = (key: string) => {
        setIsTabs(Number(key));
        setFilterStatus('ALL');
        setFilterChain('ALL');
        setFilterSearch('');
        setIsPager(1);
        setFilterSort('');
    };

    const operations = <>
        <div className="p-launchpadList__sort">
            <Select className="c-selectEdit" value={filterStatus} onChange={onChangeStatus} style={{ width: 135 }}>
                <Option value="ALL">All Status</Option>
                <Option value="UPCOMING">Upcoming</Option>
                <Option value="OPENING">Opening</Option>
                <Option value="COMPLETED">Completed</Option>
            </Select>
            <Select className="c-selectEdit is-ml24" value={filterChain} onChange={onChangeChain} style={{ width: 135 }}>
                {process.env.REACT_APP_NODE_ENV === "development" ?
                    <>
                        <Option value="ALL">All Chain</Option>
                        <Option value="BSCTESTNET">BSC Testnet</Option>
                        <Option value="ARBTESTNET">ARB Tesnet</Option>
                        <Option value="ARB">ARB Mainnet</Option>
                    </> :
                    <Option value="ARB">ARB Mainnet</Option>}

            </Select>
            <div className="c-sortBy">
                <span onClick={() => setIsSort(!isSort)}><img src={`../images/${isSort ? 'icon-sortby-active.svg' : 'icon-sortby.svg'}`} alt="" /></span>
                <div className={`c-sortBy__box ${isSort ? 'is-open' : ''}`}>
                    <ul onClick={() => setIsSort(!isSort)}>
                        <li className={filterSort === 'kyc' ? 'is-active' : ''} onClick={() => setFilterSort('kyc')}>KYC</li>
                        <li className={filterSort === 'audit' ? 'is-active' : ''} onClick={() => setFilterSort('audit')}>Audit</li>
                        <li className={filterSort === 'doxx' ? 'is-active' : ''} onClick={() => setFilterSort('doxx')}>DOXX</li>
                        <li className={filterSort === 'softCap' ? 'is-active' : ''} onClick={() => setFilterSort('softCap')}>Soft Cap</li>
                        <li className={filterSort === 'hardCap' ? 'is-active' : ''} onClick={() => setFilterSort('hardCap')}>Hard Cap</li>
                        <li className={filterSort === 'startTime' ? 'is-active' : ''} onClick={() => setFilterSort('startTime')}>Start Time</li>
                    </ul>
                </div>
            </div>
        </div>
    </>;
    return (
        <div className="p-launchpadList">
            <div className="p-launchpadList__top">
                <p>TRENDING </p>
                <Marquee gradient={false} speed={50} pauseOnHover={true}>
                    {listTrending && listTrending.length > 0 && listTrending.map((item, index) => (
                        <Link to={`/private-sale/${item?.launchpadHandle}`} key={index}>#{index + 1} {item?.name}</Link>
                    ))}
                    {/* <Link to={''} >#1 League of Legends Wiki</Link> */}
                </Marquee>
            </div>
            <div className="p-launchpadList__head">
                <h2>Private Sale List</h2>
                <p>We bring new technologies, new function to our community<br />and create a variety of investment packages</p>
                <SearchChange value={filterSearch} onChange={(e) => setFilterSearch(e.target.value)} placeholder='Search launchpads name, token name or token symbol' />
            </div>
            <div className="p-launchpadList__box is-private">
                <Tabs className="c-tabEdit" defaultActiveKey="1" tabBarExtraContent={operations} onChange={onChangeTabs}>
                    <TabPane tab="All Private Sale" key="1">
                        <div className="c-list">
                            {privateSale && privateSale.map((item: any, key: any) => (
                                <ItemListPrivate data={item} key={key} />
                            ))}
                            {/* <ItemListPrivate />
                            <ItemListPrivate />
                            <ItemListPrivate /> */}
                        </div>
                        <Pagination
                            className="c-pagingEdit"
                            pageSize={10}
                            defaultCurrent={1}
                            current={isPager}
                            onChange={onChangePager}
                            total={Number(privateSaleCount)}
                        />
                    </TabPane>
                    <TabPane tab="Joined Private Sale" key="2">
                        {privateJoined && privateJoined.length > 0 ? <>
                            <div className="c-list">
                                {privateJoined && privateJoined.map((item: any, key: any) => (
                                    <ItemListPrivate data={item} key={key} />
                                ))}
                            </div>
                        </> : <div className="c-nodata">
                            <img src="../images/no-data-private.svg" alt="No data" />
                            <p>
                                Joined Private Sale
                                <span>This is where you will manage your private sale that you created</span>
                            </p>
                        </div>}
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default PrivateSaleList;