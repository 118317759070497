import { useMemo } from "react";
import { getContract } from "../utils";
import { useActiveWeb3React } from "./index";
import StandardTokenABI from "../contracts/abi/StandardTokenABI.json";
import LaunchpadDeployABI from "../contracts/abi/LaunchpadDeployABI.json";
import DeployFairLaunchpadABI from '../contracts/abi/DeployFairLaunchpadABI.json';
import DeployLaunchpadV2ABI from "../contracts/abi/DeployLaunchpadV2ABI.json";
import LaunchpadABI from "../contracts/abi/LaunchpadABI.json";
import GSLockABI from "../contracts/abi/GSLockABI.json";
import PancakePairABI from "../contracts/abi/PancakePairABI.json";
import AntibotABI from "../contracts/abi/GSAntiBot.json";
import DeployPrivateSaleABI from "../contracts/abi/DeployPrivateSale.json";

import PrivateSaleABI from "../contracts/abi/PrivateSale.json";
import XBLSTokenABI from "../contracts/abi/XBLSTokenABI.json";
import ARBTokenABI from "../contracts/abi/ARBTESTTokenABI.json";
import StakeXBLSABI from "../contracts/abi/StakeXBLSABI.json";
import ClaimPublicSaleABI from "../contracts/abi/TokenClaimPublicSale.json";
import FairlaunchABI from "../contracts/abi/FairLaunchABI.json";
import AntibotStandardTokenABI from "../contracts/abi/AntibotStandardTokenABI.json"; 
// returns null on errors
export function useContract(
  address: any,
  ABI: any,
  withSignerIfPossible: any = true
) {
  const { library, account } = useActiveWeb3React();

  return useMemo(() => {
    if (!address || !ABI || !library) return null;
    try {
      return getContract(
        address,
        ABI,
        library,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible, account]);
}

export function useTokenContract(address: any) {
  return useContract(address, StandardTokenABI);
}

export function useTokenAntibotContract(address: any) {
  return useContract(address, AntibotStandardTokenABI);
}

export function useLaunchpadDeployContract(address: any) {
  return useContract(address, DeployLaunchpadV2ABI);
}

export function useLaunchpadContract(address: any) {
  return useContract(address, LaunchpadABI);
}

export function useGSLockContract(address: any) {
  return useContract(address, GSLockABI);
}

export function usePancakePairContract(address: any) {
  return useContract(address, PancakePairABI);
}

export function useAntiBotContract(address: any) {
  return useContract(address, AntibotABI);
}

export function usePrivateSaleDeployContract(address: any) {
  return useContract(address, DeployPrivateSaleABI);
}

export function usePrivateSaleContract(address: any) {
  return useContract(address, PrivateSaleABI);
}

export function useTokenXBLSContract(address: any) {
  return useContract(address, XBLSTokenABI);
}

export function useStakeXBLSContract(address: any) {
  return useContract(address, StakeXBLSABI);
}

export function useTokenARBContract(address: any) {
  return useContract(address, ARBTokenABI);
}

export function useClaimPublicContract(address: any) {
  return useContract(address, ClaimPublicSaleABI);
}

export function useFairlaunchDeployContract(address: any) {
  return useContract(address, DeployFairLaunchpadABI);
}

export function useFairlaunchContract(address: any) {
  return useContract(address, FairlaunchABI);
}