import {ReactNode} from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { Provider } from 'react-redux';
import store from './redux/store';
import { Web3ProviderNetwork, getLibrary } from './context/web3provider';
import { RefreshContextProvider } from './context/RefreshContext';
import { WrapperProvider } from './context/WrapperContext';
import { PersistGate } from 'redux-persist/integration/react';
import persistStore from 'redux-persist/lib/persistStore';

interface Props {
  children?: ReactNode
}

const Providers = ({ children }: Props)  => {
  
    let persistor = persistStore(store);
  
    return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <RefreshContextProvider>
                <WrapperProvider>
                  {children}
                </WrapperProvider>
            </RefreshContextProvider>
          </PersistGate>
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  )
}

export default Providers
