import React, {  } from "react";
import IconBSC from '../../assets/images/BSC-2.png';
import IconETH from '../../assets/images/ETH.png';
import IconPoly from '../../assets/images/POLY.png';
import IconArb from '../../assets/images/arbitrum.svg';
import { useAppDispatch} from "../../../redux/hooks";
import { getHeader } from "../../../redux/slicers/headerSlice";
import networkChange from "../../../utils/networkChange";
import { CHAINID_FULLNAME, MAPPING_CHAINID_DECIMAL } from "../../../constants";
import { additionInfo, lauchpadInfo, verifyToken} from "../../../redux/slicers/createLauchpadSlice";
import { setTokenStorage } from "../../../redux/slicers/tokenStorageSlice";

interface IConnectNetwork {
    setConnectNetwork: any,
}
const ListNetwork : Array<object> = [
    {title: CHAINID_FULLNAME.arb, icon: IconArb, connectorId: 'injected', chainId: MAPPING_CHAINID_DECIMAL.arb, env: "production"},
    {title: CHAINID_FULLNAME.bsc, icon: IconBSC, connectorId: 'injected', chainId: MAPPING_CHAINID_DECIMAL.bsc, env: "development"},
    {title: CHAINID_FULLNAME.arbtestnet, icon: IconArb, connectorId: 'injected', chainId: MAPPING_CHAINID_DECIMAL.arbtestnet, env: "development"},
    {title: CHAINID_FULLNAME.bsctestnet, icon: IconBSC, connectorId: 'injected', chainId: MAPPING_CHAINID_DECIMAL.bsctestnet, env: "development"}
]; 

const ConnectNetwork : React.FC<IConnectNetwork> =(props: IConnectNetwork) => {
    const dispatch = useAppDispatch();     
    const handleClick = (value:any) => { 
        dispatch(getHeader(""));
        props.setConnectNetwork(value);
        dispatch(lauchpadInfo({}));
        dispatch(verifyToken({}));
        dispatch(setTokenStorage({}));
        dispatch(additionInfo({}));
        networkChange(`0x${value?.chainId.toString(16).toUpperCase()}`);
    }

    const elmNetwork = ListNetwork.map((item: any,key :number) => {
        if(process.env.REACT_APP_NODE_ENV === 'production') {
            if(item?.env === process.env.REACT_APP_NODE_ENV) {
                return (<li className="listwallet" key={key} onClick={() => handleClick(item)} >
                    <div className="listwallet_left">
                        <span className="img"><img src={item.icon} alt={item.title}/></span>
                        <span className="title">{item.title}</span>
                    </div> 
                    <img className="listwallet_right" src={window.location.origin + '/images/arrow-right.png'} alt=""/>
                </li>)
            }
        }
        else {
            return (<li className="listwallet" key={key} onClick={() => handleClick(item)} >
                <div className="listwallet_left">
                    <span className="img"><img src={item.icon} alt={item.title}/></span>
                    <span className="title">{item.title}</span>
                </div> 
                <img className="listwallet_right" src={window.location.origin + '/images/arrow-right.png'} alt=""/>
            </li>)
        }
    });
    
    return (
        <>
            <ul className='popupwallet network'>
                <div className="smalltriangle network" /> 
                {elmNetwork}
            </ul> 
        </> 
    )
}
export default ConnectNetwork;