import React from "react";
import ListWallet from "./listWallet"; 
import { useAppDispatch } from "../../../redux/hooks";
import { getHeader } from "../../../redux/slicers/headerSlice";
import { useConnectWallet } from '../../../hooks';

declare const window: Window & typeof globalThis & { ethereum: any };
interface IConnectWallet {
    setConnectWallet: any, 
}
const ConnectWallet : React.FC<IConnectWallet> =(props: IConnectWallet) => {

    const dispatch = useAppDispatch();
    
    const { walletLogin } = useConnectWallet();

    const handleConnect = async (connectorId: string) => {
        await walletLogin(connectorId);
        props.setConnectWallet(true);
        dispatch(getHeader(""));

        
    }

    const elmWallet = ListWallet.map((item: any,key :number) => (
        <li className="listwallet" key={key} onClick={() => handleConnect(item.connectorId)} >
            <div className="listwallet_left">
                <span className="img"><img src={item.icon} alt={item.title}/></span>
                <span className="title">{item.title}</span>
            </div> 
            <img className="listwallet_right" src="../images/arrow-right.png" alt=""/>
        </li>
    ));
    return (
        <div className="popupSP">
        <ul className='popupwallet wallet'>
            <div className="smalltriangle"></div>
            {elmWallet}
        </ul>
        </div>
    )
}
export default ConnectWallet;