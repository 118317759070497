import React, { useEffect, useState } from "react"; 
import {Form, Select, Checkbox, Button as ButtonAntd} from 'antd'; 
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Button } from "../component";
// import BuyBackBabyToken from "./buyBackBabyToken";
// import BabyToken from "./babyToken";
// import LiquidityToken from "./liquidityForm";
import StandardToken from "../createlaunchpad/standardToken";
import { useActiveWeb3React, useConnectWallet } from "../../hooks";
import web3 from 'web3';
import { APP_SETTINGS, CHAINID_CONVERT, CHAINID_CONVERT_UPCASE, FEE_SETTINGS, LAUNCHPAD_CONFIGS, RPC_URL_CONVERT, SMART_CONTRACT_ADDRESS, TESTNET_BSC_URL } from "../../constants";
import { TOKEN_STANDARD_BYTECODE } from "../../contracts/bytecodeStandardToken";
import { createAntiBotStandardToken, createStandardToken } from "../../contracts/token";
import standardTokenAbi from "../../contracts/abi/StandardTokenABI.json";
import antibotStandardTokenAbi from "../../contracts/abi/AntibotStandardTokenABI.json"; 
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { createTokenInform } from "../../redux/slicers/tokenInformSlice";
import { CHAINID_FULLNAME, MAPPING_CHAINID_DECIMAL } from "../../constants";  
import { setTokenStorage } from "../../redux/slicers/tokenStorageSlice";
import { setTokenLock } from "../../redux/slicers/tokenLockSlice";
import { useNavigate, Link, useSearchParams } from "react-router-dom"; 
import { Store } from 'react-notifications-component';
import addNotify from '../commons/Notify/addNotify';
import { Loading } from "../component";
import { getAppSettings, settingSelector } from "../../redux/slicers/settingSlice";
import { convertToWei } from "../../contracts/utils";
import { getCurrencyDecimals } from "../createlaunchpad/ultils";
import { TOKEN_STANDARD_ANTIBOT_BYTECODE } from "../../contracts/bytecodeAntibotStandardToken";
 
 
declare const window: Window & typeof globalThis & { ethereum: any }; 
 
interface IType{
    type: any, 
    setCreateToken: any;
    setActiveKey: any
}

type SettingFee = {
  chainId: string | number,
  feeType: string,
  payToken: any,
  settingKey: any
  settingValue: any
}

const TokenCreateForm : React.FC<IType> =(props: IType) => {  
    const [tokenType, setTokenType] = useState<string>("Standard");
    const [implementAntibot, setImplementAntibot] = useState<boolean>(false); 
    const [loadingPage, setLoadingPage] = useState(false);
    const { Option } = Select;
    
    const [createTokenForm] = Form.useForm();
    const { account, library , chainId} = useActiveWeb3React();
  
    const [initTokenFee, setInitTokenFee] = useState<SettingFee>(); 
    const [standardFeeWallet, setStandardFeeWallet] = useState<any>('');
    const [chainDecimals, setChainDecimals] = useState<any>(0);
    const { settings } = useAppSelector(settingSelector);  
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate(); 
    const [searchParams, setSearchParams] = useSearchParams();
    const launchpadType:any = searchParams.get('type');  
     
    let netName = ''; 
    switch (chainId) {
        case MAPPING_CHAINID_DECIMAL.bsc:
            netName = CHAINID_CONVERT_UPCASE[56]; 
            break;
            
        case MAPPING_CHAINID_DECIMAL.poly:
            netName = CHAINID_CONVERT_UPCASE[137]; 
            break;

        case MAPPING_CHAINID_DECIMAL.eth:
            netName = CHAINID_CONVERT_UPCASE[1]; 
            break; 

        case MAPPING_CHAINID_DECIMAL.bsctestnet:
            netName = CHAINID_CONVERT_UPCASE[97]; 
            break; 

        case MAPPING_CHAINID_DECIMAL.arb:
            netName = CHAINID_CONVERT_UPCASE[42161]; 
            break; 

        case MAPPING_CHAINID_DECIMAL.arbtestnet:
            netName = CHAINID_CONVERT_UPCASE[421613]; 
            break; 
    }
    const antibotAddress = chainId && SMART_CONTRACT_ADDRESS[chainId]?.ANTI_BOT_ADDRESS;

    useEffect(()=> {
        const payload:any = {
            "page": 0,
            "size": 100
        }
        dispatch(getAppSettings(payload));
    }, []);
    
    useEffect(() => {
        setChainDecimals(getCurrencyDecimals(chainId, chainId === 56 ? "BNB" : ( chainId === 1 ? "ETH" : "MATIC")));
    }, [chainId]); 
  
    useEffect(() => {
        if(chainId) {
            const appSetting = FEE_SETTINGS[chainId];

            if (settings && settings.length > 0) {   
                let settingTokenFee = settings.filter((itemSetting) => chainId === Number(itemSetting?.chainId)); 
                const tokenFee = (settingTokenFee.find(({ settingKey }) => settingKey === appSetting?.INIT_TOKEN_FEE)); 
                setInitTokenFee({
                    chainId: tokenFee?.chainId,
                    feeType: tokenFee?.feeType,
                    payToken: tokenFee?.payToken,
                    settingKey: tokenFee?.settingKey,
                    settingValue: tokenFee?.settingValue
                });
      
                let standardWallet = settings.filter((itemSetting) => APP_SETTINGS.IN_COME_FEE_WALLET === itemSetting.settingKey);
                if(standardWallet && standardWallet[0]) {
                  setStandardFeeWallet(standardWallet[0].settingValue);
                }
            }
        }
    }, [settings, chainId]); 
    
    const handleChange = (value: string) => {
        setTokenType(value);
    };

    const onChange = (e: CheckboxChangeEvent) => {
        setImplementAntibot(e.target.checked);
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };  

    const handleCancel = () =>{   
        if(props.type === "FormLauchpad"){ 
            localStorage.setItem("step", "1"); 
            props.setCreateToken(false) 
        }
        if(props.type === "FormFairlauch"){ 
            localStorage.setItem("step", "1"); 
            props.setCreateToken(false) 
        }
        if(props.type === "FormLock"){
            localStorage.setItem("stepLock", "1"); 
            props.setCreateToken(false)
        } 
        if (props.type === "FormAntibot") {
            localStorage.setItem("stepAntibot", "1");
            props.setCreateToken(false)
        } 
        if(props.type === null){
            navigate("/token/my-token")
        }
    }

    const onSubmitCreateTokenForm = async (values: any) => {
        switch (values.tokenType) {
            case 'Standard':
                if (values.implementAntibot) {
                    return createAntiStandardTokenForm(values);
                } else {
                    return createStandardTokenForm(values);
                }

            default:
                break;
        }
    };

    const createStandardTokenForm = async (values: any) => {   
        if(!account){
            Store.addNotification(addNotify("Please connect your wallet", 'danger'));
            return
        } 
        setLoadingPage(true); 
        if (!initTokenFee?.settingValue) {
            Store.addNotification(addNotify("Init token fee setting is invalid. Please reload!", 'danger'));
            return;
        }

        const _decimals = parseInt(values.decimals);
        const _totalSupply = convertToWei(values.totalSupply, _decimals);
        const _fundAmount = convertToWei(initTokenFee?.settingValue, chainDecimals);

        await createStandardToken(
            standardTokenAbi,           // ContractInterface
            `0x${TOKEN_STANDARD_BYTECODE}`,      // bytecode
            library,                    // library
            account,                    // deployer
            values.tokenName,           // token name
            values.symbol,              // token symbol
            _decimals,                  // token decimal
            _totalSupply,               // totalSupply
            standardFeeWallet,         //  serviceFeeReceiver
            _fundAmount                 // fundAmount
        ).then((res:any) => { 
            setLoadingPage(true)
            let resWait:any = res.wait();  
            resWait.then((resTransaction:any) => { 
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {   
                    form.resetFields();
                    const payloadAPI :any= {
                        "ownerAddress": account,
                        "tokenType": "STANDARD",
                        "tokenAddress": resTransaction?.contractAddress,
                        "tokenName": values?.tokenName,
                        "symbol": values?.symbol,
                        "decimals": values?.decimals,
                        "totalSupply": values?.totalSupply,
                        "type": "Created",
                        "networkChain": netName,
                        "antiBotEnable": false
                    }  
                    dispatch(createTokenInform(payloadAPI, async (res:any) => {
                        setLoadingPage(false); 
                        if(res.result === "SUCCESS") {
                            if(res?.data) { 
                                Store.addNotification(addNotify("Token created successfully.", 'success'));
                                if(props.type === "FormLauchpad"){ 
                                    setSearchParams({token: resTransaction?.contractAddress, type: launchpadType});
                                    localStorage.setItem("step", "2");
                                    props.setCreateToken(false);
                                    props.setActiveKey("2");
                                }
                                if(props.type === "FormFairlauch"){ 
                                    setSearchParams({token: resTransaction?.contractAddress});
                                    localStorage.setItem("step", "2");
                                    props.setCreateToken(false);
                                    props.setActiveKey("2");
                                }
                                if(props.type === "FormLock"){
                                    navigate('/token/my-token'); 
                                    props.setCreateToken(false)
                                } 
                                if(props.type === null){ 
                                    navigate('/token/my-token');  
                                    props.setCreateToken(false)
                                } 
                            }
                        }
                        else {  
                            if (res?.error?.message) {
                                Store.addNotification(addNotify(res?.error?.message, 'danger'));
                            }
                        }
                    }));
                    if(props.type === "FormLauchpad"){
                        dispatch(setTokenStorage(payloadAPI)); 
                    }
                    if(props.type === "FormFairlauch"){
                        dispatch(setTokenStorage(payloadAPI)); 
                    }
                    if(props.type === "FormLock"){
                        dispatch(setTokenLock(payloadAPI)); 
                    } 
                }
            })
            .catch((error:any) => {
                setLoadingPage(false); 
                if (error) {
                    if (error.code === 4001 && error.message) {
                        Store.addNotification(addNotify(error.message, 'danger'));
                        return;
                    } else if (error.reason) {
                        Store.addNotification(addNotify(error.reason, 'danger'));
                        return;
                    } else {
                        if (error.data && error.data.message) {
                            Store.addNotification(addNotify(error.data.message, 'danger'));
                            return;
                        }
                    }
                }
            }); 
        })
        .catch((error) => {
            setLoadingPage(false); 
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                    return;
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                    return;
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                        return;
                    }
                }
            }
        }); 
    };
 
    const createAntiStandardTokenForm = async (values: any) => {
        if (!initTokenFee?.settingValue) {
            Store.addNotification(addNotify("Init token fee setting is invalid. Please reload!", 'danger'));
            return;
        }

        const _decimals = parseInt(values.decimals);
        const _totalSupply = convertToWei(values.totalSupply, _decimals);
        const _fundAmount = convertToWei(initTokenFee?.settingValue, chainDecimals);
        setLoadingPage(true);
       
        await createAntiBotStandardToken(
            antibotStandardTokenAbi,           // ContractInterface
            `0x${TOKEN_STANDARD_ANTIBOT_BYTECODE}`,      // bytecode
            library,                    // library
            account,                    // deployer
            values.tokenName,           // token name
            values.symbol,              // token symbol
            _decimals,                  // token decimal
            _totalSupply,               // totalSupply
            antibotAddress,
            standardFeeWallet,         //  serviceFeeReceiver
            _fundAmount                 // fundAmount
        ).then((res:any) => { 
            setLoadingPage(true)
            let resWait:any = res.wait();  
            resWait.then((resTransaction:any) => { 
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {   
                    const payloadAPI :any= {
                        "ownerAddress": account,
                        "tokenType": "STANDARD",
                        "tokenAddress": resTransaction?.contractAddress,
                        "tokenName": values?.tokenName,
                        "symbol": values?.symbol,
                        "decimals": values?.decimals,
                        "totalSupply": values?.totalSupply,
                        "type": "Created",
                        "networkChain": netName,
                        "antiBotEnable": implementAntibot
                    }  
                    dispatch(createTokenInform(payloadAPI, async (res:any) => {
                        setLoadingPage(false); 
                        if(res.result === "SUCCESS") {
                            if(res?.data) { 
                                Store.addNotification(addNotify("Antibot token created successfully.", 'success')); 
                                if(props.type === "FormLauchpad"){ 
                                    localStorage.setItem("step", "2");
                                    props.setCreateToken(false);
                                    props.setActiveKey("2");  
                                }
                                if(props.type === "FormLock"){
                                    navigate('/token/my-token'); 
                                    props.setCreateToken(false)
                                } 
                                if (props.type === "FormAntibot") {
                                    localStorage.setItem("stepAntibot", "2");
                                    props.setCreateToken(false);
                                    props.setActiveKey("2");  
                                    navigate(`/anti-bot?token=${resTransaction?.contractAddress}`);
                                } 
                                if(props.type === null){ 
                                    navigate('/token/my-token'); 
                                     props.setCreateToken(false) 
                                } 
                            }
                        }
                        else {  
                            if (res?.error?.message) {
                                Store.addNotification(addNotify(res?.error?.message, 'danger'));
                            }
                        }
                    }));
                    if(props.type === "FormLauchpad"){
                        dispatch(setTokenStorage(payloadAPI)); 
                    }
                    if(props.type === "FormLock"){
                        dispatch(setTokenLock(payloadAPI)); 
                    } 
                }
            })
            .catch((error:any) => {
                console.log(error);
                setLoadingPage(false); 
                if (error) {
                    if (error.code === 4001 && error.message) {
                        Store.addNotification(addNotify(error.message, 'danger'));
                        return;
                    } else if (error.reason) {
                        Store.addNotification(addNotify(error.reason, 'danger'));
                        return;
                    } else {
                        if (error.data && error.data.message) {
                            Store.addNotification(addNotify(error.data.message, 'danger'));
                            return;
                        }
                    }
                }
            }); 
        })
        .catch((error) => {
            console.log(error);
            setLoadingPage(false); 
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                    return;
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                    return;
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                        return;
                    }
                }
            }
        }); 
    };

    return (
        <>
        {loadingPage ? <Loading/> : <div className="p-createToken">
            <h3>
                Create Token
               <span>Fee: {initTokenFee?.settingValue} {initTokenFee?.payToken}</span>
            </h3>
            <div className="p-createToken__box">
                <Form
                    //className="form-createToken"
                    name="basic" 
                    form={createTokenForm}
                    initialValues={{ tokenType: 'Standard', implementAntibot: false }}
                    onFinish={onSubmitCreateTokenForm}
                    layout="vertical"
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                <div className="form-createToken-main">
                    <div className="form-createToken-item">
                        <span className="form-createToken-text">Token Type</span>
                        <Form.Item name="tokenType" > 
                            <Select defaultValue={tokenType} onChange={handleChange}>
                                <Option value="Standard">Standard Token</Option>
                                {/* <Option value="Liquidity">Liquidity Generator Token</Option> 
                                <Option value="Baby">Baby Token</Option>
                                <Option value="Buyback">Buyback Baby Token</Option>  */}
                            </Select>
                        </Form.Item>
                    </div>
                    {tokenType ==="Standard" &&  <StandardToken/>}
                    {/* {tokenType ==="Liquidity" &&  <LiquidityToken/>}
                    {tokenType ==="Baby" && <BabyToken/>}
                    {tokenType ==="Buyback" && <BuyBackBabyToken/>} */}
                </div> 
                <Form.Item name="implementAntibot" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                    <Checkbox onChange={onChange}>Implement Anti - Bot System?</Checkbox>
                </Form.Item>
                {process.env.REACT_APP_NODE_ENV === 'development' &&
                implementAntibot &&
                <span>
                    Please visit <Link to={`/anti-bot`} >https://www.bluesale.finance/#/antibot</Link> to active Anti-Bot after creating the token. 
                    Check out the tutorial here: <a href="https://docs.bluesale.finance/project-launching/anti-bot">https://docs.bluesale.finance/project-launching/anti-bot</a>
                </span>}

                <div className='p-createToken__btn'>
                    <div className="c-btn c-btn--greyBorder" onClick={()=>handleCancel()}><span><span>Cancel</span></span></div>
                    <Button type="blue" text="Create token" />
                </div> 
                </Form>
            </div>
        </div>
        } 
        </>
    )
}

export default TokenCreateForm;