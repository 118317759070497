import { useState, useEffect } from "react";
import { Form, Input, Select, Switch, Button as ButtonAntd, Radio } from 'antd';
import { InputChange } from "../component";
import type { RadioChangeEvent } from 'antd';
import { Button as ButtonComp } from '../component'; 
import { useAntiBotContract, useTokenAntibotContract, useTokenContract } from "../../hooks/useContract";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { tokenInformsSelector, getTokenInformByAddress } from "../../redux/slicers/tokenInformSlice"; 
import { tokenDecimals, tokenInformation } from "../../contracts/token";
import { DEX_EXCHANGES, SALE_CURRENCIES, SMART_CONTRACT_ADDRESS } from "../../constants";
import { useActiveWeb3React } from "../../hooks";
import { Loading } from "../component"; 
import { useNavigate, useParams, useSearchParams } from "react-router-dom";  
import { postAntiBot } from "../../redux/slicers/antibotSlice"; 
import { antibotStorageSelector, setAntibotStorage } from "../../redux/slicers/antibotStorageSlice"; 
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { configAntiBot, getStatusContractTransfer, setContractTransfer } from "../../contracts/antibot";
import { Store } from 'react-notifications-component';
import addNotify from '../commons/Notify/addNotify';
import { convertToWei } from "../../contracts/utils";
import { getCurrencyAddress } from "../createlaunchpad/ultils";
interface IFormCreate {
    setActiveKey: any,
}

const FormAntibot: React.FC<IFormCreate> = (props: IFormCreate) => {
    const [form] = Form.useForm(); 
     const { account, chainId } = useActiveWeb3React(); 
    const [loadingPage, setLoadingPage] = useState<boolean>(false); 
    const [tokenSC, setTokenSC] = useState<any>();
    const [actionStatus, setActionStatus] = useState(""); 
    const [checkSwitch, setCheckSwitch] = useState<boolean>(false);
    const [checkSwitchUI, setCheckSwitchUI] = useState<boolean>();
    const [dexExchanges, setDexExchanges] = useState<any>([]);
    const [tokenInfo, setTokenInfo] = useState<any>([]); 
    const [changeStatusContractTransfer, setChangeStatusContractTransfer] = useState<any>();
    const [valueCurrency, setValueCurrency] = useState(chainId === 421613 ? "AGOR" : chainId === 42161 ? "ETH" : "" );
    const [saleCurrencies, setSaleCurrencies] = useState<any>([]);
    const { tokenInformsByAddress } = useAppSelector(tokenInformsSelector);
    const { antibotStorage } = useAppSelector(antibotStorageSelector);
    const [searchParams, setSearchParams] = useSearchParams();  
    const { handle } = useParams<{ handle: any }>();  
    const { Option } = Select;  
    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const antibotAddress = chainId && SMART_CONTRACT_ADDRESS[chainId]?.ANTI_BOT_ADDRESS; 
    const antibotContract = useAntiBotContract(antibotAddress);   
    let urlTokenAddress = searchParams.get("token");
    const tokenContract = useTokenContract(urlTokenAddress); 
    const tokenAntiBotContract = useTokenAntibotContract(urlTokenAddress); 

    useEffect(() => {
       if(!urlTokenAddress){
         localStorage.setItem("stepAntibot", "1");  
       } 
    }, [urlTokenAddress]);


    useEffect(() => {  
        if(urlTokenAddress && account){ 
        (async () => {
            await getStatusContractTransfer(tokenAntiBotContract).then((res)=>{ 
                console.log("res", res);
                console.log("setCheckSwitchUI", !res)
                setCheckSwitch(res);
                setCheckSwitchUI(!res)
            }).catch((error) =>{
                console.log(error);
            })
        })() 
        } 
    }, [urlTokenAddress, account, changeStatusContractTransfer]);  
 
    useEffect(() => { 
       (async () => {
         await tokenInformation(tokenContract)
       })() 
    }, [antibotStorage]); 

    useEffect(() => {
        if (chainId) {
            setDexExchanges(DEX_EXCHANGES[chainId]); 
            setSaleCurrencies(SALE_CURRENCIES[chainId]);
        } 
    }, [chainId]);


    useEffect(() => {
        (async () => {
            setLoadingPage(true);
            await tokenInformation(tokenContract)
                .then((res: any) => {
                    setTokenSC(res);
                    setLoadingPage(false);
                })
                .catch((err: any) => {
                    form.resetFields();
                    setLoadingPage(false);
                })
        })()
    }, [tokenContract, handle]); 

    useEffect(() => { 
        if(urlTokenAddress) {
            (async () => {
                await dispatch(getTokenInformByAddress(urlTokenAddress, (res:any) => {
                    if(res?.tokenInform) {
                        setTokenInfo(res?.tokenInform)
                    }
                }));
            })();
        }
    }, [urlTokenAddress]); 

    const onFinish = async (values: any) => {  
        setActionStatus("LOADINGLOCK"); 

        const _routerSelected = dexExchanges && dexExchanges.length > 0 ? dexExchanges.filter(item => item.id === values?.routerExchange) : null;
        const routerOjbect = _routerSelected && _routerSelected.length > 0 && _routerSelected[0];
         
        const payload = { 
            "routerExchange": values.routerExchange,
            "pairToken": values?.pairToken,
            "amountLimitPerTrade": Number(values?.amountLimitPerTrade),
            "amountToBeAddedPerBlock": Number(values?.amountToBeAddedPerBlock),
            "timeLimitPerTrade": Number(values?.timeLimitPerTrade),
            "blockNumberToDisableAntiBot": Number(values?.blockNumberToDisableAntiBot),
            "disableContractTransfer": checkSwitch,
            "tokenInformId": tokenInfo?.id 
        } 
        const currencyDecimals = getCurrencyAddress(chainId, values?.pairToken); 
        //call SC 
        
        await configAntiBot(
            antibotContract,
            tokenInfo?.tokenAddress,
            currencyDecimals,
            routerOjbect?.routerAddress,
            routerOjbect?.factoryAddress,  //address factoryExchange,
            convertToWei(values?.amountLimitPerTrade,tokenInfo?.decimals),
            convertToWei(values?.amountToBeAddedPerBlock, tokenInfo?.decimals),
            values?.timeLimitPerTrade,
            convertToWei(values?.blockNumberToDisableAntiBot, tokenInfo?.decimals)).then((res: any) => { 
                setLoadingPage(true)
                let resWait: any = res.wait();
                resWait.then((resTransaction: any) => {
                    if (resTransaction && resTransaction.status && resTransaction.blockNumber) { 
                        //call API
                        dispatch(postAntiBot(payload, async (res: any) => {
                            if (res?.data) {  
                                setLoadingPage(false);
                                // navigate to launchpad detail
                                dispatch(setAntibotStorage({}))
                                navigate(`/anti-bot/${res?.data?.id}`);
                                localStorage.setItem("stepAntibot", "1")
                                Store.addNotification(addNotify("Anti-bot successfully.", 'success'));
                                setActionStatus("")
                            }
                            else {
                                setActionStatus("");
                                if (res?.error?.message) {
                                    Store.addNotification(addNotify(res?.error?.message, 'danger'));
                                }
                            }
                        })); 
                    }
                })
                    .catch((error: any) => {
                        setLoadingPage(false);
                        setActionStatus("")
                        if (error.data) {
                            Store.addNotification(addNotify('Transaction error', 'danger'));
                            return;
                        }
                        if (error) {
                            Store.addNotification(addNotify(error?.reason, 'danger'));
                            return;
                        }
                    });
            })
            .catch((error) => {
                setLoadingPage(false);
                if (error.data) {
                    Store.addNotification(addNotify('Transaction error', 'danger'));
                    return;
                }
                if (error) {
                    Store.addNotification(addNotify(error?.reason, 'danger'));
                    return;
                }
            });  
    };
 
    const handleCancel = () => {
        props.setActiveKey("1");
        localStorage.setItem("stepAntibot", "1");
    } 
    const onChangeSwitch = async(checked: boolean) => {
        setCheckSwitch(!checked); 
        setCheckSwitchUI(checked)
        console.log("tokenAntiBotContract",!checked)
        setActionStatus("LOADING_SWITCH");
        await setContractTransfer(tokenAntiBotContract, !checked)
        .then((res:any)=> {
            
            let resWait = res.wait();
            resWait.then((resTransaction:any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    if(!checked) {
                        Store.addNotification(addNotify('Enable contract transfer successfully', 'success'));
                        setChangeStatusContractTransfer("Enable")
                    }
                    else {
                        Store.addNotification(addNotify('Disable contract transfer successfully', 'success'));
                        setChangeStatusContractTransfer("Disable")
                    }
                    setActionStatus("");
                    // window.location.reload();
                }
                else {
                    setActionStatus("");
                    Store.addNotification(addNotify('Transaction failed', 'danger'));
                    return;
                }
            })
            .catch ((error:any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            });
        })
        .catch((error:any)=>{
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, 'danger'));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, 'danger'));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, 'danger'));
                }
            }
        });
    } 

    const onChangeCurrency = (e: RadioChangeEvent) =>{
        setValueCurrency(e.target.value);

        if (chainId === 42161) {
            form.setFieldsValue({
                "listingOptions": e.target.value === "ETH" ? "" : "MANUAL"
            });
        }
    } 

     const defaultCurrencyChecker = () => {
        let currencyDefault = '';
        if(chainId === 421613) {
            currencyDefault = 'AGOR';
        } else if(chainId === 42161) {
            currencyDefault = 'ETH';
        }
        return currencyDefault;
    }
 
  
    return (
        <>  {loadingPage ? <Loading /> : null}
            <div className="p-tokenCreateLock formlock">
                <h3>Step 2</h3>
                <Form name="basic" form={form} onFinish={onFinish} initialValues={{ remember: true }} >
                    <div className="p-tokenCreateLock__box">
                        <div className="p-tokenCreateLock__left">
                            <InputChange title='Token Address' value={tokenSC?.tokenAddress} disabled={true} />
                            <InputChange title='Name' value={tokenSC?.tokenName} disabled={true} />
                            <InputChange title='Symbol' value={tokenSC?.symbol} disabled={true} />
                            <InputChange title='Decimals' value={tokenSC?.decimals} disabled={true} /> 
                        </div>
                        <div className="p-tokenCreateLock__right antibot_form">
                            <span className="mb-10">Select Router Exchange</span>
                            <Form.Item
                                name="routerExchange"
                                rules={[{ required: true, message: 'Please select router exchange !' }]}
                            >
                                <Select placeholder="Select Router Exchange">
                                    {dexExchanges.map((e: any, i: any) => (
                                        <Option key={i} value={e.id}>{e.name}</Option>
                                    ))}   
                                </Select> 
                            </Form.Item> 

                            <span className="mb-10">Select Pair Token</span>  
                            <Form.Item
                                name="pairToken"
                                rules={[{ required: true, message: 'Please select pair token !' }]}
                            >
                                <Radio.Group onChange={onChangeCurrency} value={valueCurrency} defaultValue={defaultCurrencyChecker}>
                                    {saleCurrencies?.map((currency) => <Radio value={currency}>{currency}</Radio>)}
                                </Radio.Group>
                            </Form.Item>

                            <div className="verifyToken-right-text">
                                <span className="mb-10">Amount Limit Per Trade</span>
                                <div className="tooltip antibot">
                                    <img src="../images/question-circle.png" alt="" />
                                    <span className="tooltiptext">This is the initial limited amount of tradable tokens at listing time. Traders will not be able to trade any amount higher than this number.</span>
                                </div>
                            </div> 
                            <Form.Item name="amountLimitPerTrade" rules={[
                                { required: true, message: 'Amount Limit Per Trade is required!' },
                                {
                                    validator(_, value) {
                                        if (Number(value) <= 0 ) {
                                            return Promise.reject(`Amount Limit Per Trade is greater than or equal to 0`)
                                        }
                                        return Promise.resolve()
                                    }
                                }]}>
                                <Input onWheel={event => event.currentTarget.blur()} name="amountLimitPerTrade" className="inputForm" type='number' placeholder='0' />  
                            </Form.Item> 

                            <div className="verifyToken-right-text">
                                <span className="mb-10">Amount To Be Added Per Block</span>
                                <div className="tooltip antibot">
                                    <img src="../images/question-circle.png" alt="" />
                                    <span className="tooltiptext">This number will continuously be added to the “Amount Limit Per Trade” from block 1 (block after listing) until the “Block Number to Disable Anti-Bot” (see below). For example, if you set the “Amount Limit Per Trade” to 1 tokens, and the “Amount to be Added Per Block” to 1 tokens, after 100 blocks the maximum tradable token amount will be 100 tokens.</span>
                                </div>
                            </div>
                            <Form.Item name="amountToBeAddedPerBlock" rules={[
                                { required: true, message: 'Amount To Be Added Per Block is required!' }
                                , {
                                    validator(_, value) {
                                        if (Number(value) <= 0) {
                                            return Promise.reject(`Amount To Be Added Per Block is greater than or equal to 0`)
                                        }
                                        return Promise.resolve()
                                    }
                                }]}>
                                <Input name="amountToBeAddedPerBlock"  onWheel={event => event.currentTarget.blur()} className="inputForm" type='number' placeholder='0' />  
                            </Form.Item> 

                            <div className="verifyToken-right-text">
                                <span className="mb-10">Time Limit Per Trade (seconds)</span>
                                <div className="tooltip antibot">
                                    <img src="../images/question-circle.png" alt="" />
                                    <span className="tooltiptext">The time between transactions that traders will have to wait to prevent multiple swaps.When “Block Number to Disable Anti-Bot” is reached, “Time Limit Per Trade” will automatically be set to zero.</span>
                                </div>
                            </div>
                            <Form.Item name="timeLimitPerTrade" rules={[
                                { required: true, message: 'Time Limit Per Trade is required!' },
                                {
                                    validator(_, value) {
                                        if (Number(value) <= 0) {
                                            return Promise.reject(`Amount Limit Per Trade is greater than or equal to 0`)
                                        }
                                        return Promise.resolve()
                                    }
                                }]}>
                                <Input name="timeLimitPerTrade" onWheel={event => event.currentTarget.blur()} className="inputForm" type='number' placeholder='0' /> 
                            </Form.Item> 

                            <div className="verifyToken-right-text">
                                <span className="mb-10">Block Number To Disable Anti-Bot</span>
                                <div className="tooltip antibot">
                                    <img src="../images/question-circle.png" alt="" />
                                    <span className="tooltiptext">Anti-bot will work from the listing time until this block. After this block, it will automatically disable and traders will be able to trade normally again. It is also possible to manually disable Anti-Bot at any given time by clicking on the “Disable Anti-Bot” button. Keep in mind this number must be greater than or equal to 150.</span>
                                </div>
                            </div>
                            <Form.Item name="blockNumberToDisableAntiBot" rules={[
                                {
                                    required: true,
                                    message: 'blockNumber is required!'
                                }, {
                                    validator(_, value) {
                                        if (Number(value) < 20) {
                                            return Promise.reject(`Block Number to Disable Anti-Bot must be greater than min limit block(20)`)
                                        }
                                        return Promise.resolve()
                                    }
                                }]}>
                                <Input name="blockNumberToDisableAntiBot" onWheel={event => event.currentTarget.blur()} className="inputForm" type='number' placeholder='0' />  
                            </Form.Item> 

                            <Form.Item name="disableContractTransfer">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={onChangeSwitch}
                                    checked={checkSwitchUI}
                                    loading={actionStatus === "LOADING_SWITCH"? true :false}
                                />
                                <span className="switchText"> {checkSwitchUI ? "Disable contract transfer" :"Enable contract transfer"}</span>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="p-tokenCreateLock__footer"> 
                        <div className="p-tokenCreateLock__btn">
                            <div className="c-btn c-btn--greyBorder mr-20" onClick={() => handleCancel()}><span><span>Cancel</span></span></div>
                           <div className="isWidth">
                               <ButtonComp text='Save Config' type="blue"></ButtonComp>
                           </div>
                        </div>
                    </div>
                </Form>
            </div>
        </>

    )
}

export default FormAntibot;