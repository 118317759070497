import { useState } from "react";
import CreateItem from './createItem';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getHeader, headerSelector } from "../../../redux/slicers/headerSlice";
 
const Create = () => { 
    const [connectNetwork, setConnectNetwork] = useState<boolean>(false);
    const dispatch = useAppDispatch(); 
    const { header } = useAppSelector(headerSelector); 
    const handleToggle = () => {  
        dispatch(getHeader("create"));
    }  
    
    return (
        <div className="btn_header">  
            <div onClick={()=>handleToggle()} className='btn_header_create'>
                <img src='../images/plus.png' alt=""/>
                <span>Create</span>
            </div> 
            {header === "create"?  
              <CreateItem setConnectNetwork={setConnectNetwork}/> 
            :null}   
        </div>
    );
}

export default Create;
