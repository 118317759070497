import { createSlice, PayloadAction } from "@reduxjs/toolkit"; 
import LaunchPadService from "../services/LaunchPadService";

// ================================================
// ==                  ACTION                    ==
// ================================================
 

export const createPrivateSale = (payload:PayloadAction, onCallback:Function) => {
  return async dispatch => {
      dispatch(setLoading(true)); 
      await LaunchPadService.createPrivateSale(payload)
      .then((res:any)=>{
        console.log(res);
        onCallback({result: "SUCCESS", data: res?.data});
      })
      .catch((err:Error)=>{
        console.log(err);
        onCallback({result: "ERROR", error: err});
      });
  }
}
 

export const privateSaleInfo = (payload:any) => {
  return async dispatch => {
      dispatch(setLoading(true)); 
      dispatch(setPrivateSaleInfo(payload));  
  }
}

export const additionInfo = (payload:any) => {
  return async dispatch => {
      dispatch(setLoading(true)); 
      dispatch(setAdditionInfo(payload));  
  }
} 


// ================================================
// ==                REDUX STORE                 ==
// ================================================

type TokenObject = {
  [key: string]: void | string | number | Date | any;
};

export interface createPrivateSaleState {  
    createPrivateSale:TokenObject;
    verifyTokenStorage: TokenObject;
    privateSaleInfoStorage: TokenObject;
    additionInfoStorage: TokenObject; 
    loading: boolean;
    errors: string;
}

const initialState: createPrivateSaleState = {  
    createPrivateSale:{},
    verifyTokenStorage: {},
    privateSaleInfoStorage: {},
    additionInfoStorage: {}, 
    loading: false,
    errors: "",
}

const createLauchpadSlice = createSlice({
  name: "createLauchpads",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    }, 
    setCreatePrivateSale: (state, { payload }: PayloadAction<TokenObject>) => {
     
      state.createPrivateSale = payload
    }, 

    setPrivateSaleInfo: (state, { payload }: PayloadAction<any>) => {
      state.privateSaleInfoStorage = payload
    },

    setAdditionInfo: (state, { payload }: PayloadAction<TokenObject>) => {
      state.additionInfoStorage = payload
    }, 
    
  },
})

export const { setLoading, setPrivateSaleInfo, setAdditionInfo, setCreatePrivateSale } = createLauchpadSlice.actions

export default createLauchpadSlice.reducer

export const createPrivateSaleSelector = (state: { createPrivateSaleStore: createPrivateSaleState }) => state.createPrivateSaleStore; 
 