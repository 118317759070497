import axios from "axios";
import http from "./http-common";
import { instance } from "../../apiBase/instance";

const BluesaleRoundsService = {
  getPrivateSaleTrending() {
    return http.get("launchpads/trending/PRIVATE");
  },
  getAuthToken(account: any) {
    return http.post("/authenticate/address", {
      address: account,
    });
  },
  getSignBuyToken(whiteListId: any, roundId: any, authToken: any) {
    let payload: any = {
      whiteListId: whiteListId,
      roundId: roundId,
    };
    return instance.post("/public-launchpad/whitelist/get-sign", payload, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  },
  updateHashBuyToken(payloadJoin: any, authToken: any) {
    return instance.post("/public-launchpad/whitelist/joined", payloadJoin, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  },
  getRoundList(authToken: any) {
    return instance.get(`/public-launchpad/bls-token`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  },
  updateClaim(payload: any, authToken: any) {
    return instance.post("/public-launchpad/allocation/claimed", payload, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  },

  updateRemaining(payload: any, authToken: any) {
    return instance.post(
      "/public-launchpad/whitelist/remaining-refunded",
      payload,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  },
};
export default BluesaleRoundsService;
