import { useState, useEffect } from "react";  
import {Form, Input, Select, Checkbox, DatePicker, Button as ButtonAntd} from 'antd';   
import { Button } from '../component';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';  
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { additionInfo, createFairlaunchSelector, fairlaunchInfo, verifyToken } from "../../redux/slicers/createFairlaunchSlice";
import { Store } from 'react-notifications-component';
import addNotify from '../commons/Notify/addNotify';
import { CHAINID_FULLNAME, DEX_EXCHANGES, FEE_SETTINGS, MAPPING_CHAINID_DECIMAL } from "../../constants";
import { useActiveWeb3React } from "../../hooks";
import { setTokenStorage, tokenStorageSelector } from "../../redux/slicers/tokenStorageSlice";  
import { useNavigate } from "react-router-dom";
import { formatUTCDate } from "../../utils/formatDate";
import { settingSelector } from '../../redux/slicers/settingSlice';
import { formatNumberAmount } from "../../utils/formatNumber";

interface IFormCreate{
    setActiveKey: any, 
} 

const FairLaunchInfo : React.FC<IFormCreate> =(props: IFormCreate) =>{ 
    const { fairlaunchInfoStorage, verifyTokenStorage } = useAppSelector(createFairlaunchSelector);
    const [isSettingMaxContribution, setIsSettingMaxContribution] = useState<boolean>(fairlaunchInfoStorage?.settingMaxContribution ? fairlaunchInfoStorage?.settingMaxContribution :false);
    const [closeTip, setCloseTip] = useState<boolean>(false);
    const [liquidityPercents, setLiquidityPercents] = useState<number>(0);
    const [liquidityLockup, setLiquidityLockup] = useState<number>(0);
    const [totalToken, setTotalToken] = useState<number>(0);   
    const [dexExchanges, setDexExchanges] = useState<any>([]);
    const [startTime, setStartTime] = useState<any>("");
    const [endTime, setEndTime] = useState<any>(""); 
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const {chainId} = useActiveWeb3React();
    const { tokenStorage } = useAppSelector(tokenStorageSelector);
    const { settings } = useAppSelector(settingSelector); 
    const nowTime = (new Date()).toUTCString();
    const [totalTokenSellingAmount, setTotalTokenSellingAmount] = useState<number>(0);
    const [softCap, setSoftCap] = useState<number>(0);
    const [router, setRouter] = useState<any>(""); 
    const [launchpadTokenRaisedFee, setLaunchpadTokenRaisedFee] = useState<any>(0);
    const [launchpadRaisedFee, setLaunchpadRaisedFee] = useState<any>(0);
    const [totalLiquidity, setTotalLiquidity] = useState<number>(0);  
    const [totalTokenFee, setTotalTokenFee] = useState<number>(0);  

    let navigate = useNavigate();
    let netName = '';  
    switch (chainId) {
        case MAPPING_CHAINID_DECIMAL.bsc:
            netName = CHAINID_FULLNAME.bsc; 
            break;
            
        case MAPPING_CHAINID_DECIMAL.poly:
            netName = CHAINID_FULLNAME.poly; 
            break;

        case MAPPING_CHAINID_DECIMAL.eth:
            netName = CHAINID_FULLNAME.eth; 
            break;

        case MAPPING_CHAINID_DECIMAL.bsctestnet:
            netName = CHAINID_FULLNAME.bsctestnet; 
            break;

        default:
            netName = CHAINID_FULLNAME.bsc; 
    }

    useEffect(() => {
        if(chainId) {
            setDexExchanges(DEX_EXCHANGES[chainId]);
        }
    },[chainId]);  
    
    useEffect(() => {   
        const _routerSelected = dexExchanges && dexExchanges.length > 0 ? dexExchanges.filter(item => item.id === Number(fairlaunchInfoStorage?.router)) : null;
        form.setFieldsValue({
            tokenAddress:tokenStorage?.tokenAddress,
            liquidityPercent: fairlaunchInfoStorage?.liquidityPercent,
            liquidityLockup: fairlaunchInfoStorage?.liquidityLockup,
            settingMaxContribution:fairlaunchInfoStorage?.settingMaxContribution ?? false,
            selecttime: [formatUTCDate(fairlaunchInfoStorage?.startTime ?? new Date()), formatUTCDate(fairlaunchInfoStorage?.endTime ?? new Date())],
            router: _routerSelected && _routerSelected.length > 0 && _routerSelected[0] ? _routerSelected[0].id : "",
            softcap: fairlaunchInfoStorage?.softcap
        });
        setStartTime(formatUTCDate(fairlaunchInfoStorage?.startTime));
        setEndTime(formatUTCDate(fairlaunchInfoStorage?.endTime));
        setLiquidityPercents(fairlaunchInfoStorage?.liquidityPercent);
    }, [fairlaunchInfoStorage, dexExchanges]);
    
    useEffect(() => {
        if(chainId) {
            const feeSettings = FEE_SETTINGS[chainId];
            if (settings && settings.length > 0) {
                
                if(verifyTokenStorage.feeOptions === feeSettings?.LIST_FAIRLAUNCH_TOKEN_RAISED_FEE_ONLY)
                {
                    let _launchpadRaisedFeeOnly = settings.filter((itemSetting) => feeSettings?.LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY === itemSetting.settingKey);
                    
                    if(_launchpadRaisedFeeOnly && _launchpadRaisedFeeOnly[0]) {
                        setLaunchpadTokenRaisedFee(0);
                        setLaunchpadRaisedFee(_launchpadRaisedFeeOnly[0].settingValue);
                    }
                }
                else {
                    let _launchpadTokenRaisedFee = settings.filter((itemSetting) => feeSettings?.LIST_LAUNCHPAD_AMOUNT_RAISED_FEE === itemSetting.settingKey);
                    if (_launchpadTokenRaisedFee && _launchpadTokenRaisedFee[0]) {
                        setLaunchpadTokenRaisedFee(_launchpadTokenRaisedFee[0].settingValue);
                    }

                    let launchpadAmountRaisedFee = settings.filter((itemSetting) => feeSettings?.LIST_LAUNCHPAD_TOKEN_RAISED_FEE === itemSetting.settingKey);
                    if (launchpadAmountRaisedFee && launchpadAmountRaisedFee[0]) {
                        setLaunchpadRaisedFee(launchpadAmountRaisedFee[0].settingValue);
                    }
                }
            }
        }
    }, [settings, chainId, verifyTokenStorage?.feeOptions]);

    useEffect(()=> {
        setTotalLiquidity(totalTokenSellingAmount * liquidityPercents/100 * (1 - launchpadRaisedFee/100));
        setTotalTokenFee(totalTokenSellingAmount*launchpadTokenRaisedFee/100);
    }, [liquidityPercents, launchpadTokenRaisedFee, launchpadRaisedFee, totalTokenSellingAmount]);  
    
    const onFinish = (values: any) => {
        if (totalTokenSellingAmount > Number(verifyTokenStorage?.totalSupply)) {
            Store.addNotification(addNotify('Total Token Selling Amount less or equal than Total supply', 'danger'));
            return ;
        }

        const nowTimeUtc = formatUTCDate(new Date());
        
        if (startTime <= nowTimeUtc) {
            Store.addNotification(addNotify('Select start time > now (UTC)', 'danger'));
            return;
        } 

        if (endTime <= startTime) {
            Store.addNotification(addNotify('Select end time > start time', 'danger'));
            return;
        } 
       
        const payload: any = { 
            tokenAddress:tokenStorage?.tokenAddress,
            softCap: Number(values?.softCap),
            liquidityPercent: Number(values?.liquidityPercent),
            liquidityLockup: Number(values?.liquidityLockup),
            settingMaxContribution: Number(values.maxContribution), 
            startTime: startTime,
            endTime: endTime,
            networkChain: netName,
            chainId: chainId,  
            totalToken: Number(values?.totalTokenSellingAmount),
            router: values?.router
        }  
        dispatch(fairlaunchInfo(payload));  
        props.setActiveKey("4"); 
        localStorage.setItem("stepFairLaunch", "4") 
    };

    
    const onChangeSettingMaxContribution = (e: CheckboxChangeEvent) => {
        setIsSettingMaxContribution(e.target.checked); 
    };
   
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleCloseTip = () =>{
        setCloseTip(true)
    }  

    const handleChange = (e) =>{  
        switch (e.target.name) {
            case "totalTokenSellingAmount":
                setTotalTokenSellingAmount(Number(e.target.value));
                break;
            case "softCap":
                setSoftCap(Number(e.target.value));
                break;
            case "liquidityPercent":
                setLiquidityPercents(Number(e.target.value)); 
                break;  
            case "liquidityLockup":
                setLiquidityLockup(Number(e.target.value)); 
                break; 
            default:
                break;
        } 
    } 
   
    const handleBack = () =>{
        props.setActiveKey("2"); 
        localStorage.setItem("stepFairLaunch", "2") 
    }

    const onRangeChange = (dates) => {  
        setStartTime(dates[0]?._d?.toISOString())
        setEndTime(dates[1]?._d?.toISOString())
    }

    const handleCancel = () =>{
        dispatch(fairlaunchInfo({}));
        dispatch(verifyToken({}));
        dispatch(setTokenStorage({}));
        dispatch(additionInfo({}))
        navigate("/launchpad/list");
        localStorage.setItem("stepFairLaunch", "1");
        localStorage.removeItem("createType");
    }
    
    const handleRouterChange = (value:any) => {
        const nameRouter = dexExchanges.find(
            (t) => t.id === value
        );
        setRouter(nameRouter.name);
    };

    const onOkStartTime = (dates) => { 
        setStartTime(dates?.toISOString()) 
    }

    const onOkEndTime = (dates) => { 
        setEndTime(dates?.toISOString()) 
    }

    return (
    <div className='verifyToken'>
      <div className='step'>Step 3</div> 
      <Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" form={form}>
        <div className="verifyToken-form">
            <div className="verifyToken-left"> 
                <div className="verifyToken-left-item">
                    <span className="mb-10">Total Token Selling Amount</span>
                    <Form.Item name="totalTokenSellingAmount"
                        rules={[{
                            required: true,
                            message: 'Total Token Selling Amount is required!'
                        }]}>  
                            <Input onWheel={ event => event.currentTarget.blur()} name="totalTokenSellingAmount" className="inputForm" type="number" onChange={(e) => handleChange(e)}/>
                    </Form.Item>  
                </div>
                <div className="verifyToken-left-item">
                    <span className="mb-10">Softcap ({verifyTokenStorage?.currency})</span>
                    <Form.Item name="softCap" rules={[{ required: true, message: 'Softcap is required!' }]}> 
                        <Input onWheel={ event => event.currentTarget.blur()} name="softCap" className="inputForm" type="number" onChange={(e) => handleChange(e)}/>
                    </Form.Item>
                </div>
                {/* <div className="verifyToken-left-item">
                    <span className="mb-10">Contribution Time (UTC)</span>
                    <Form.Item name="selecttime" rules={[{ required: true, message: `Please input your contribution time` }]}> 
                        <RangePicker showTime format={'DD/MM/YYYY HH:mm:ss'} onChange={onRangeChange}  />
                    </Form.Item>
                </div> */}


                <div className="verifyToken-left-item">
                    <span className="mb-10">Start Time (UTC)</span>
                    <Form.Item name="startTime" rules={[
                        { required: true, message: `Please input your contribution time` },
                        {
                            validator: (rule, value, cb: (msg?: string) => void) => {
                                !value || new Date(value) <= new Date(Date.now())
                                    ? cb("Start time needs to be after now")
                                    : cb();
                            }
                        }
                    ]}> 
                        <DatePicker showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onOk={onOkStartTime} />
                        {/* <RangePicker showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onChange={onRangeChange}  /> */}
                    </Form.Item>
                </div>
                <div className="verifyToken-left-item">
                    <span className="mb-10">End Time (UTC)</span>
                    <Form.Item name="endTime" rules={[
                        { required: true, message: `Please input your contribution time` },
                        {
                            validator: (rule, value, cb: (msg?: string) => void) => {

                                if (!value || new Date(value) <= new Date(Date.now())) {
                                    cb("End time needs to be after now time");
                                } else if (new Date(value) <= form.getFieldValue('startTime')) {
                                    cb("End time needs to be after start time");
                                } else {
                                    cb();
                                }
                            }
                        }
                    ]}> 
                        <DatePicker showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onOk={onOkEndTime} />
                        {/* <RangePicker showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onChange={onRangeChange}  /> */}
                    </Form.Item>
                </div>

                <Form.Item name="isSettingMaxContribution" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                    <Checkbox onChange={onChangeSettingMaxContribution}>Setting max contribution?</Checkbox>
                </Form.Item>   
                {isSettingMaxContribution && <>
                <div className="verifyToken-left-item"> 
                    <span className="mb-10">Max Contribution ({verifyTokenStorage?.currency})</span>  
                    <Form.Item name="maxContribution" rules={[{required: true,message: 'Max Contribution is required!'}]}>
                        <Input onWheel={ event => event.currentTarget.blur()} name="maxContribution" className="inputForm" type="number" onChange={(e) => handleChange(e)}/>
                    </Form.Item>
                </div></>}
            </div> 
            <div className="verifyToken-right">
                <div className="verifyToken-left-item">
                    <span className="mb-10">Router</span>
                    <Form.Item 
                    name="router"
                    rules={[{ required: true, message: 'Please select router!' }]}
                    >  
                        <Select placeholder="Please select router"
                        onChange={handleRouterChange}>
                        {dexExchanges.map((e:any,i:any)=>( 
                            <Option key={i} value={e.id}>{e.name}</Option>  
                        ))}  
                        </Select>
                    </Form.Item>
                </div>
                <div className="verifyToken-left-item">
                    <div className="verifyToken-right-text">
                        <span className="mb-10">{router} Liquidity (%)</span>
                        <div className="tooltip">
                            <img src="../images/question-circle.png" alt="" />
                            <span className="tooltiptext">The percentage of raised funds that should be allocated to Liquidity on Pancakeswap (Min 51%, Max 100%).</span>
                        </div>
                    </div>
                    <Form.Item name="liquidityPercent" rules={[{
                        validator: (_, value) => {
                            if (value > 50 && value <= 100) {
                                return Promise.resolve();
                            } else {
                                return Promise.reject('Liquidity must be greater than 50%');
                            }
                        }
                    }]}>
                        <Input onWheel={event => event.currentTarget.blur()} className="inputForm" type="number" name="liquidityPercent" onChange={(e) => handleChange(e)} />
                    </Form.Item>
                </div>
                <div className="verifyToken-left-item" >
                    <span className="mb-10">Liquidity lock up (minutes)</span>
                    <Form.Item name="liquidityLockup" rules={[
                        { required: true, message: 'Please input your liquidity lock up' },
                        {
                            validator(_, value) {
                                if (Number(value) <= 5) {
                                    return Promise.reject(`Liquidity lock up time must be greater than 5 minutes`)
                                }
                                return Promise.resolve()
                            }
                        }]}>
                        <Input onWheel={event => event.currentTarget.blur()} className="inputForm" type="number" />
                    </Form.Item>
                </div>
            </div> 
        </div>
        <div className="footer-tab">
            <div className="fixed-bottom">
            {closeTip ? null : <div className="caution">
                <div className="caution-bg blue">
                    <img src="../images/caution.png"/>
                    <span>Need {formatNumberAmount(!isNaN(totalTokenSellingAmount+totalLiquidity+totalTokenFee) ? totalTokenSellingAmount+totalLiquidity+totalTokenFee : 0)} {tokenStorage?.symbol} to create Fairlaunch</span>
                    <img onClick={()=>handleCloseTip()} src="../images/close.png"/>
                </div> 
            </div> }  
            <div className='btn-verify'>
                <div className="isWidth">
                    <Button text='Cancel' type='border' onClick={() => handleCancel()}/>
                </div> 
                <div className="m-25 isWidth">
                    <ButtonAntd className="c-btn" htmlType="button" onClick={()=>handleBack()}>
                        Previous
                    </ButtonAntd>
                </div> 
                <div className="isWidth">
                    <Button text='Next' type="blue"/>
                </div> 
            </div>
            </div> 
        </div>  
        </Form>  
    </div>  
  )
}
 

export default FairLaunchInfo;