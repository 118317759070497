import { Spin } from "antd";
import React from "react";
import { Edit, Plus, Upload } from "./assets/icon";

type ButtonProps = {
  text: any;
  type?: string;
  disabled?: boolean;
  iconLeft?: string;
  iconRight?: string;
  onClick?: any;
  isLoading?: boolean;
};

type CustomButtonProps = {
  text: string;
  class?: string;
  disabled?: boolean;
  iconLeft?: string;
  iconRight?: string;
  onClick?: any;
  isLoading?: boolean;
};
type CustomButtonTypeProps = {
  type: any,
  text: string;
  class?: string;
  disabled?: boolean;
  iconLeft?: string;
  iconRight?: string;
  onClick?: any;
  isLoading?: boolean;
};

type InputProps = {
  title?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  onChange?: any;
};

type SearchProps = {
  placeholder?: string;
  value?: string;
  onChange?: any;
  onClick?: any;
};

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const handleIcon = (value: string) => {
    let icon: JSX.Element | string = "";
    if (value === "Plus") {
      icon = <Plus />;
    } else if (value === "Upload") {
      icon = <Upload />;
    } else if (value === "Loading") {
      icon = <Spin size="small" />;
    } else if (value === "Edit") {
      icon = <Edit />;
    }
    return icon;
  };
  return (
    <button
      className={`c-btn ${props.type ? "c-btn--" + props.type : ""}`}
      disabled={props.disabled ? props.disabled : false}
      onClick={props.onClick}
    >
      <span>
        {props.iconLeft ? handleIcon(props.iconLeft) : ""}
        <span>{props.text}</span>
        {props.iconRight ? handleIcon(props.iconRight) : ""}
        {props.isLoading ? handleIcon("Loading") : ""}
      </span>
    </button>
  );
};

export const CustomButton: React.FC<CustomButtonProps> = (
  props: CustomButtonProps
) => {
  const handleIcon = (value: string) => {
    let icon: JSX.Element | string = "";
    if (value === "Plus") {
      icon = <Plus />;
    } else if (value === "Upload") {
      icon = <Upload />;
    } else if (value === "Loading") {
      icon = (
        <Spin
          style={{ marginLeft: props.class === "next" ? "10px" : 0 }}
          size="small"
        />
      );
    } else if (value === "Edit") {
      icon = <Edit />;
    }
    return icon;
  };
  return (
    <button
      className={`${props.class}`}
      disabled={props.disabled ? props.disabled : false}
      onClick={props.onClick}
    >
      <span>
        {props.iconLeft ? handleIcon(props.iconLeft) : ""}
        <span>{props.text}</span>
        {props.iconRight ? handleIcon(props.iconRight) : ""}
        {props.isLoading ? handleIcon("Loading") : ""}
      </span>
    </button>
  );
};

export const CustomButtonType: React.FC<CustomButtonTypeProps> = (
  props: CustomButtonTypeProps
) => {
  const handleIcon = (value: string) => {
    let icon: JSX.Element | string = "";
    if (value === "Plus") {
      icon = <Plus />;
    } else if (value === "Upload") {
      icon = <Upload />;
    } else if (value === "Loading") {
      icon = (
        <Spin
          style={{ marginLeft: props.class === "next" ? "10px" : 0 }}
          size="small"
        />
      );
    } else if (value === "Edit") {
      icon = <Edit />;
    }
    return icon;
  };
  return (
    <button
      type={props.type}
      className={`c-btn ${props.type ? "c-btn--" + props.type : ""}`}
      disabled={props.disabled ? props.disabled : false}
      onClick={props.onClick}
    >
      <span>
        {props.iconLeft ? handleIcon(props.iconLeft) : ""}
        <span>{props.text}</span>
        {props.iconRight ? handleIcon(props.iconRight) : ""}
        {props.isLoading ? handleIcon("Loading") : ""}
      </span>
    </button>
  );
};

export const InputChange: React.FC<InputProps> = (props: InputProps) => {
  return (
    <div className="c-input">
      {props.title ? <p className="c-input__title">{props.title}</p> : ""}
      <input
        onWheel={(event) => event.currentTarget.blur()}
        type={props.type ? props.type : "text"}
        placeholder={props.placeholder ? props.placeholder : "Please enter"}
        defaultValue={props.value}
        disabled={props.disabled}
        onChange={props.onChange}
      />
    </div>
  );
};

export const SearchChange: React.FC<SearchProps> = (props: SearchProps) => {
  return (
    <div className="c-search">
      <input
        placeholder={props.placeholder ? props.placeholder : "Please enter"}
        onWheel={(event) => event.currentTarget.blur()}
        onChange={props.onChange}
        value={props.value}
      />
      <button onClick={props.onClick}>
        <img src="../images/icon-search.svg" alt="" />
      </button>
    </div>
  );
};

export const Loading = () => {
  return (
    <div className="c-loading">
      <img src="../images/Loading.gif" alt="" />
    </div>
  );
};
