import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import BigNumber from "bignumber.js";
import { isMobile } from "react-device-detect";
import React, { useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import addNotify from "../../components/commons/Notify/addNotify";
import { Button, CustomButton } from "../../components/component";
import { BLS_DIVIDENDS, BLS_DIVIDENDS_V2, BSC_BLOCK_TIME } from "../../constants";
import { depositPool, getPendingReward, getPoolCap, getAllRewardPerBlock, getStakedToken, getUnStakingFee, getUserInfo, withdrawPool, getTotalRewardPaid, getStakingBlock } from "../../contracts/staking";
import { convertFromWei } from "../../contracts/utils";
import { getTokenAllowance, getTokenBalanceOf, tokenApprove, tokenInformation } from "../../contracts/xblsToken";
import { useActiveWeb3React } from "../../hooks";
import {
  useTokenXBLSContract,
  useStakeXBLSContract,
  useTokenARBContract
} from "../../hooks/useContract";
import { useAppDispatch } from "../../redux/store";
import { getArbitrumPrice, getBLSPrice, getXBLSPrice, getBLSPriceMexc, priceSelector } from "../../redux/slicers/priceSlice";
import { getPoolApr } from "../../utils/apr";
import { formatNumber, formatNumberAmount, formatNumberUnit } from "../../utils/formatNumber";
import { useAppSelector } from "../../redux/hooks";
import web3NoAccount from "../../utils/web3";
import Countdown, { zeroPad } from "react-countdown";
import networkChange from "../../utils/networkChange";
import { getAppSettings, settingSelector } from "../../redux/slicers/settingSlice";
import { Link, useSearchParams } from "react-router-dom";
const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

const BlsDividendsV1 = () => {
  const { account, chainId } = useActiveWeb3React();
  const dispatch = useAppDispatch();
  const [isCalculator, setIsCalculator] = useState<boolean>(false);
  const [amountCalculator, setAmountCalculator] = useState<string>("");
  const [isAllocate, setIsAllocate] = useState<boolean>(false);
  const [allocateLoading, setAllocateLoading] = useState<boolean>(false);
  const [claimAllLoading, setClaimAllLoading] = useState<boolean>(false);
  const [amountAllocate, setAmountAllocate] = useState<string>("");
  const [isDeallocate, setIsDeallocate] = useState<boolean>(false);
  const [amountDeallocate, setAmountDeallocate] = useState<string>("");
  const [totalYourAllocation, setTotalYourAllocation] = useState<number>(0);
  const [manualAllocation, setManualAllocation] = useState<number>(0);
  const [tokenXBLS, setTokenXBLS] = useState<any>(null);
  const [tokenARB, setTokenARB] = useState<any>(null);
  const [totalStaked, setTotalStaked] = useState<any>(0);
  const [xblsBalance, setXblsBalance] = useState<any>(0);
  const [amountApprove, setAmountApprove] = useState(0);
  const [pendingXBLSReward, setPendingXBLSReward] = useState(0);
  const [pendingARBReward, setPendingARBReward] = useState(0);
  const [isApprove, setIsApprove] = useState(false);
  const [isRefresh, setIsRefresh] = useState(true);
  const [totalRewardPaid, setTotalRewardPaid] = useState(0);
  const [totalRewardTokenUSDC, setTotalRewardTokenUSDC] = useState(0);
  const [totalRewardTokenBLS, setTotalRewardTokenBLS] = useState(0);
  const [rewardPerBlock, setRewardPerBlock] = useState(0);
  const [rewardPerBlockArb, setRewardPerBlockArb] = useState(0);
  const [rewardPerBlockXbls, setRewardPerBlockXbls] = useState(0);
  const [rewardPerWeekXbls, setRewardPerWeekXbls] = useState(0);
  const [rewardPerWeekArb, setRewardPerWeekArb] = useState(0);
  const [nextEpochRemainingTimeInSeconds, setNextEpochRemainingTimeInSeconds] = useState(0);
  const [tokenXBLSContractAddress, setTokenXBLSContractAddress] = useState('');
  const [tokenBLSContractAddress, setTokenBLSContractAddress] = useState('');
  const [tokenARBContractAddress, setTokenARBContractAddress] = useState('');
  const [tokenARBLogo, setTokenARBLogo] = useState();
  const [stakeXBLSContractAddress, setStakeXBLSContractAddress] = useState('');
  const [blocksRewardPerWeek, setBlocksRewardPerWeek] = useState(0);
  const [currentBlock, setCurrentBlock] = useState(0);
  const [deallocateFeePercent, setDeallocateFeePercent] = useState(0);
  const tokenXBLSContract = useTokenXBLSContract(tokenXBLSContractAddress);
  const tokenARBContract = useTokenARBContract(tokenARBContractAddress);
  const stakeXBLSContract = useStakeXBLSContract(stakeXBLSContractAddress);
  const { ARBPrice, BLSPriceMexc } = useAppSelector(priceSelector);
  const { settings } = useAppSelector(settingSelector);
  const stakingTokenPrice = tokenBLSContractAddress ? (BLSPriceMexc ? Number(BLSPriceMexc) : 0) : 0;
  const earningTokenPrice = tokenXBLSContractAddress ? (Object.keys(BLSPriceMexc).length !== 0 ? Number(BLSPriceMexc) : 0) : 0;
  const earningArbTokenPrice = +ARBPrice;
  const [poolApr, setPoolApr] = useState(0);

  useEffect(() => {
    dispatch(getBLSPriceMexc());
  }, []);

  useEffect(() => {
    let dividendRewardTokenId: any = settings.filter((itemSetting) => chainId === +itemSetting?.chainId && itemSetting.settingKey === "DIVIDEND_REWARD_TOKEN_NAME")?.[0];
    if (dividendRewardTokenId?.settingValue) {
      dispatch(getArbitrumPrice(dividendRewardTokenId?.settingValue));
    }
  }, [chainId, settings]);

  useEffect(() => {
    if (chainId) {
      let dividendRewardTokenLogo: any = settings.filter((itemSetting) => chainId === +itemSetting?.chainId && itemSetting.settingKey === "DIVIDEND_REWARD_TOKEN_LOGO")?.[0];
      let dividendRewardTokenAddress: any = settings.filter((itemSetting) => chainId === +itemSetting?.chainId && itemSetting.settingKey === "DIVIDEND_REWARD_TOKEN_ADDRESS")?.[0];

      setTokenXBLSContractAddress(BLS_DIVIDENDS[chainId]?.XBLS_ADDRESS);
      setTokenBLSContractAddress(BLS_DIVIDENDS[chainId]?.BLS_ADDRESS);
      setStakeXBLSContractAddress(BLS_DIVIDENDS[chainId]?.STAKE_BLS_EARN_XBLS_ADDRESS);
      setBlocksRewardPerWeek(BLS_DIVIDENDS[chainId]?.BLOCKS_REWARD_PER_WEEK);
      setDeallocateFeePercent(BLS_DIVIDENDS[chainId]?.DEALLOCATION_FEE_PERCENT);
      
      setTokenARBContractAddress(dividendRewardTokenAddress?.settingValue);
      setTokenARBLogo(dividendRewardTokenLogo?.settingValue);
    }
  }, [chainId, settings]);

  useEffect(() => {
    (async () => {
      const web3 = web3NoAccount;
      const blockNumber = await web3.eth.getBlockNumber();
      setCurrentBlock(blockNumber);
    })();
  }, [chainId]);

  useEffect(() => {
    (async () => {
      await callXBLSContract(chainId);
      setPoolApr(getPoolApr(
        stakingTokenPrice,
        earningTokenPrice,
        totalStaked,
        rewardPerBlock,
        18,
      ));
    })();
  }, [tokenXBLSContract, account, chainId, stakeXBLSContractAddress]);

  useEffect(() => {
    if (isRefresh) {
      (async () => {
        await callXBLSContract(chainId);
      })();
      setIsRefresh(false);
    }
  }, [tokenXBLSContract, account, isRefresh, chainId, stakeXBLSContractAddress]);

  useEffect(() => {
      setPoolApr(getPoolApr(
        stakingTokenPrice,
        earningTokenPrice,
        totalStaked,
        rewardPerBlock,
        18,
      ));
  }, [account, chainId, stakeXBLSContractAddress, stakingTokenPrice, earningTokenPrice, totalStaked, rewardPerBlock]);

  const callXBLSContract = async (chainId: any) => {
    if (tokenXBLSContract) {
      await tokenInformation(tokenXBLSContract)
        .then((res: any) => {
          setTokenXBLS(res);
        })
        .catch((err: any) => { });

      await getTokenBalanceOf(tokenXBLSContract, stakeXBLSContractAddress)
        .then((res: any) => {
          setTotalStaked(res);
        })
        .catch((err: any) => { });

      await getTokenBalanceOf(tokenXBLSContract, account)
        .then((res: any) => {
          setXblsBalance(res);
        })
        .catch((err: any) => { });

      await getTokenAllowance(tokenXBLSContract, account, stakeXBLSContractAddress)
        .then((res: any) => {
          setAmountApprove(res);
          setIsApprove(res > 0 ? true : false);
        });
    }
  }

  useEffect(() => {
    (async () => {
      await tokenInformation(tokenARBContract)
        .then((res: any) => {
          setTokenARB(res);
        })
        .catch((err: any) => { });
    })();
  }, [tokenARBContract, tokenARBContractAddress, account, chainId]);

  useEffect(() => {
    (async () => {
      await callStakeXBLSContract(chainId);
    })();
  }, [stakeXBLSContract, account, chainId, tokenARB, tokenARBContractAddress]);

  useEffect(() => {
    if (isRefresh) {
      (async () => {
        await callStakeXBLSContract(chainId);
      })();
      setIsRefresh(false);
    }
  }, [stakeXBLSContract, account, isRefresh, chainId, tokenARB, tokenARBContractAddress]);

  const callStakeXBLSContract = async (chainId: any) => {
    await getAllRewardPerBlock(stakeXBLSContract, [tokenBLSContractAddress, tokenARB?.tokenAddress])
      .then((res: any) => {
        setRewardPerBlockXbls(+convertFromWei(res[0], 18));
        setRewardPerBlockArb(+convertFromWei(res[1], tokenARB?.decimals));
        setRewardPerWeekXbls(+convertFromWei(res[0], 18) * blocksRewardPerWeek);
        setRewardPerWeekArb(+convertFromWei(res[1], tokenARB?.decimals) * blocksRewardPerWeek);
        setRewardPerBlock(Number(res[0]) + Number(res[1]));
      })
      .catch((err: any) => { });

    await getTotalRewardPaid(stakeXBLSContract)
      .then((res: any) => {
        setTotalRewardPaid(Number(res));
        setTotalRewardTokenUSDC(Number(res) / 2000000000001);
        setTotalRewardTokenBLS(Number(res) - (Number(res) / 2000000000001));
      })
      .catch((err: any) => { });

    await getStakingBlock(stakeXBLSContract)
      .then((res) => {
        setNextEpochRemainingTimeInSeconds(+((res - currentBlock) / 3));
      })
      .catch((err: any) => { });

    if (account) {
      await getUserInfo(stakeXBLSContract, account)
        .then((res: any) => {
          setTotalYourAllocation(+convertFromWei(res?.amount, 18));
          setManualAllocation(+convertFromWei(res?.amount, 18) - +convertFromWei(res?.autoStakedAmount, 18));
        })
        .catch((err: any) => { });

      await getPendingReward(stakeXBLSContract, account)
        .then((res: any) => {

          if (res?.[1][0].toLowerCase() == tokenBLSContractAddress.toLowerCase()) {
            setPendingXBLSReward(res?.[0][0] ? +convertFromWei(res?.[0][0], 18) : 0);
          }
          if (res?.[1][1].toLowerCase() == tokenARB?.tokenAddress.toLowerCase()) {
            setPendingARBReward(res?.[0][1] ? +convertFromWei(res?.[0][1], tokenARB?.decimals) : 0);
          }
        })
        .catch((err: any) => { });
    }
  }

  const handleAllocate = async () => {

    if (+amountAllocate === 0) {
      Store.addNotification(addNotify('Please enter amount allocate', 'danger'));
      return;
    }

    setAllocateLoading(true);
    await depositPool(stakeXBLSContract, +amountAllocate).then((res) => {
      let resWait: any = res.wait();
      resWait.then((resTransaction: any) => {
        if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
          setAllocateLoading(false);
          setIsAllocate(false);
          setIsRefresh(true);
          setAmountAllocate("");
          Store.addNotification(addNotify("Allocate successfully.", 'success'));
        }
      }).catch((error: any) => {
        setAllocateLoading(false);
        setIsAllocate(false);
        setAmountAllocate("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, 'danger'));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, 'danger'));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, 'danger'));
          }
        }
      });
    }).catch((error: any) => {
      setAllocateLoading(false);
      setIsAllocate(false);
      setAmountAllocate("");
      if (error.code == 4001 && error.message) {
        Store.addNotification(addNotify(error.message, 'danger'));
      } else if (error.reason) {
        Store.addNotification(addNotify(error.reason, 'danger'));
      } else {
        if (error.data && error.data.message) {
          Store.addNotification(addNotify(error.data.message, 'danger'));
        }
      }
    });
  }

  const handleDeallocate = async () => {

    if (!amountDeallocate || +amountDeallocate === 0) {
      Store.addNotification(addNotify('Please enter amount deallocate', 'danger'));
      return;
    }

    if (+amountDeallocate > +totalYourAllocation) {
      Store.addNotification(addNotify(`Please enter valid amount. Amount <=${totalYourAllocation}`, 'danger'));
      return;
    }

    setAllocateLoading(true);
    await withdrawPool(stakeXBLSContract, +amountDeallocate, false, true).then((res) => {
      let resWait: any = res.wait();
      resWait.then((resTransaction: any) => {
        if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
          setAllocateLoading(false);
          setIsDeallocate(false);
          setIsRefresh(true);
          setAmountDeallocate("");
          Store.addNotification(addNotify("Deallocate successfully.", 'success'));
        }
      }).catch((error: any) => {
        setAllocateLoading(false);
        setIsDeallocate(false);
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, 'danger'));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, 'danger'));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, 'danger'));
          }
        }
      });
    }).catch((error: any) => {
      setAllocateLoading(false);
      setIsDeallocate(false);
      if (error.code == 4001 && error.message) {
        Store.addNotification(addNotify(error.message, 'danger'));
      } else if (error.reason) {
        Store.addNotification(addNotify(error.reason, 'danger'));
      } else {
        if (error.data && error.data.message) {
          Store.addNotification(addNotify(error.data.message, 'danger'));
        }
      }
    });
  }

  const handleClaimAll = async () => {

    setClaimAllLoading(true);
    await withdrawPool(stakeXBLSContract, 0, true, true).then((res) => {
      let resWait: any = res.wait();
      resWait.then((resTransaction: any) => {
        if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
          setClaimAllLoading(false);
          setIsRefresh(true);
          Store.addNotification(addNotify("Claim all successfully.", 'success'));
        }
      }).catch((error: any) => {
        setClaimAllLoading(false);
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, 'danger'));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, 'danger'));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, 'danger'));
          }
        }
      });
    }).catch((error: any) => {
      setClaimAllLoading(false);
      if (error.code == 4001 && error.message) {
        Store.addNotification(addNotify(error.message, 'danger'));
      } else if (error.reason) {
        Store.addNotification(addNotify(error.reason, 'danger'));
      } else {
        if (error.data && error.data.message) {
          Store.addNotification(addNotify(error.data.message, 'danger'));
        }
      }
    });
  }

  const handleSetMax = async (type: any) => {
    if (type === 1) {
      setAmountCalculator(xblsBalance);
    }
    else if (type === 2) {
      setAmountAllocate(xblsBalance);
    }
    else if (type === 3) {
      setAmountDeallocate(manualAllocation.toString());
    }
  };

  const handleSetDeallocate = (e) => {

    if (+manualAllocation === 0) {
      Store.addNotification(addNotify(`You cannot deallocate. Your manual allocation amount is 0`, 'danger'));
      return;
    }

    if (+e.target.value > +manualAllocation) {
      Store.addNotification(addNotify(`Please enter valid manual amount. Amount <=${formatNumberAmount(manualAllocation)}`, 'danger'));
      return;
    }
    setAmountDeallocate(e.target.value);
  }

  const handleSetAllocate = (e) => {
    setAmountAllocate(e.target.value);
  }

  useEffect(() => {
    if (+amountApprove < +amountAllocate) {
      setIsApprove(false);
    }
    else {
      setIsApprove(true);
    }
  }, [amountAllocate]);

  useEffect(() => {
    if (+amountApprove < +amountDeallocate) {
      setIsApprove(false);
    }
    else {
      setIsApprove(true);
    }
  }, [amountDeallocate]);

  const handleApproveModal = async (type: any) => {
    try {
      setAllocateLoading(true);
      await tokenApprove(
        tokenXBLSContract,
        stakeXBLSContractAddress
      ).then((res) => {
        let resWait: any = res.wait();
        resWait.then((resTransaction: any) => {
          if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
            setAllocateLoading(false);
            setIsApprove(true);
            setIsRefresh(true);
            Store.addNotification(addNotify(`${type} successfully.`, 'success'));
          }
        }).catch((error: any) => {
          setAllocateLoading(false);
          setIsApprove(false);
          if (error.code == 4001 && error.message) {
            Store.addNotification(addNotify(error.message, 'danger'));
          } else if (error.reason) {
            Store.addNotification(addNotify(error.reason, 'danger'));
          } else {
            if (error.data && error.data.message) {
              Store.addNotification(addNotify(error.data.message, 'danger'));
            }
          }
        });
      });
    } catch (error: any) {
      setAllocateLoading(false);
      setIsApprove(false);
      if (error.code == 4001 && error.message) {
        Store.addNotification(addNotify(error.message, 'danger'));
      } else if (error.reason) {
        Store.addNotification(addNotify(error.reason, 'danger'));
      } else {
        if (error.data && error.data.message) {
          Store.addNotification(addNotify(error.data.message, 'danger'));
        }
      }
    }
  };

  const handleSetAllocateCancel = () => {
    setIsAllocate(false);
    setAmountAllocate("");
  }

  const handleSetDeallocateCancel = () => {
    setIsDeallocate(false);
    setAmountDeallocate("");
  }

  const handleSetCalculateCancel = () => {
    setIsCalculator(false);
    setAmountCalculator("");
  }

  const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
    // Render a countdown
    if (completed) {
      return (
        <span>
          0D : 0h : 0min
        </span>
      );
    } else {
      return (
        <span>
          {zeroPad(days)}D : {zeroPad(hours)}h : {zeroPad(minutes)}min
        </span>
      );
    }
  };

  const nextEspoTimer = () => {
    // let progressTime = new Date(nextEpochRemainingTimeInSeconds * BSC_BLOCK_TIME + Date.now());
    var nextEpochDate = new Date();
    nextEpochDate.setDate(nextEpochDate.getDate() + (((1 + 7 - nextEpochDate.getDay()) % 7) || 7));
    nextEpochDate.setUTCHours(0);
    nextEpochDate.setUTCMinutes(0);
    nextEpochDate.setUTCSeconds(0);

    return <Countdown date={nextEpochDate} renderer={rendererCountDown} />;
  }

  const switchArb = async () => {
    await networkChange("0xa4b1");
  };

  const currentDistribution = () => {
    return Number(convertFromWei(parseInt(totalRewardTokenUSDC.toString()), 6)) * Number(ARBPrice) + Number(convertFromWei(parseInt(totalRewardTokenBLS.toString()), 18)) * Number(BLSPriceMexc);
  };

  const _totalShared: any = (+amountCalculator + +totalYourAllocation) / (+amountCalculator + +totalStaked) * 100;
  const _totalSharedAll: any = (+amountAllocate + +totalYourAllocation) / (+amountAllocate + +totalStaked) * 100;
  const _totalSharedDeAll: any = (+totalYourAllocation - +amountDeallocate) / (+totalStaked - +amountDeallocate) * 100;

  return (
    <>
      <div className="p-blsDividends">
        <div className="p-blsDividends__head">
          <h2>
            Dividends (v1 - inactive)
            <span>
              Allocate xBLS here to earn a share of protocol earnings in the
              form of real yield.
            </span>
          </h2>
          <p>${earningTokenPrice && totalYourAllocation ? formatNumberUnit(+earningTokenPrice * +totalYourAllocation) : 0} TVL</p>
        </div>
        <ul className="c-statistic">
          {isMobile ? <span style={{ fontWeight: 700, fontSize: 20 }}>Overview</span> : ''}
          <li>
            <div className="c-statistic__img">
              <img src="../images/devidends-icon1.svg" alt="" />
            </div>
            <p className="c-statistic__title">
              <span>Current distribution</span>
              <strong>${totalRewardTokenUSDC && totalRewardTokenBLS ? formatNumberAmount(currentDistribution()) : 0}</strong>
            </p>
          </li>
          <li>
            <div className="c-statistic__img">
              <img src="../images/devidends-icon2.svg" alt="" />
            </div>
            <p className="c-statistic__title">
              <span>Current APY</span>
              <strong>{poolApr ? formatNumberAmount(poolApr) : 0}%</strong>
            </p>
          </li>
          <li className="is-mt8">
            <div className="c-statistic__img">
              <img src="../images/devidends-icon3.svg" alt="" />
            </div>
            <p className="c-statistic__title">
              <span>Total xBLS allocations</span>
              <strong>{totalStaked ? formatNumberAmount(totalStaked) : 0}</strong>
            </p>
          </li>
          <li className="is-mt8">
            <div className="c-statistic__img">
              <img src="../images/devidends-icon4.svg" alt="" />
            </div>
            <p className="c-statistic__title">
              <span>Deallocation fee</span>
              <strong>{deallocateFeePercent}%</strong>
            </p>
          </li>
        </ul>
        <div className="p-blsDividends__content">
          <div className="p-blsDividends__pool is-box">
            <ul>
              <li>
                <h3>
                  Current epoch
                  <button disabled onClick={() => setIsCalculator(true)} style={{cursor: "not-allowed"}}>
                    <img src="../images/calculator-icon.svg" alt="" />
                    Calculator
                  </button>
                </h3>
                <div className="c-poolItem">
                  <div className="c-poolItem__img">
                    <img src={tokenARBLogo} alt="" />
                  </div>
                  <div className="c-poolItem__conts">
                    <p>{tokenARB?.symbol}</p>
                    <h4>
                      {formatNumberAmount(rewardPerWeekArb)} <span>(${formatNumberAmount(+earningArbTokenPrice * rewardPerWeekArb)})</span>
                    </h4>
                  </div>
                </div>
                <div className="c-poolItem">
                  <div className="c-poolItem__img">
                    <img src="../images/bls-icon.svg" alt="" />
                  </div>
                  <div className="c-poolItem__conts">
                    <p>BLS</p>
                    <h4>
                      {formatNumberAmount(rewardPerWeekXbls)} <span>(${formatNumberAmount(+earningTokenPrice * rewardPerWeekXbls)})</span>
                    </h4>
                  </div>
                </div>
              </li>
              <li>
                <h3>Next epoch</h3>
                <div className="c-poolItem is-noImg">
                  <p>Min. estimated value</p>
                  <h4>$ {formatNumberAmount(+earningArbTokenPrice * rewardPerWeekArb + +earningTokenPrice * rewardPerWeekXbls)}</h4>
                </div>
                <div className="c-poolItem is-noImg">
                  <p>APY</p>
                  <h4>{formatNumberAmount(poolApr / 52)} %</h4>
                </div>
                <div className="c-poolItem is-noImg">
                  <p>Remaning time</p>
                  <h4>N/A</h4>
                </div>
              </li>
            </ul>
          </div>
          <div className="p-blsDividends__info is-box">
            <ul>
              <li>
                <h3>
                  Your allocation
                  {account && chainId === 42161 ? (
                    <p>
                      <Button
                        text="-"
                        type="greyBorder"
                        onClick={() => setIsDeallocate(true)}
                      />
                      <Button
                        disabled
                        text="+"
                        type="blue"
                        onClick={() => setIsAllocate(true)}
                      />
                    </p>
                  ) : (
                    ""
                  )}
                </h3>
                {account ? (<>
                  {chainId !== 42161 ? (
                    <button className="btn-white btn-danger is-notChain" onClick={() => switchArb()}>
                      Switch to Arbitrum
                    </button>
                  ) : (
                    <>
                      <p>
                        <span>Total allocation</span>
                        <strong>{totalYourAllocation ? formatNumberAmount(totalYourAllocation) : 0} xBLS</strong>
                      </p>
                      <p>
                        <span>Total share</span>
                        {totalYourAllocation && totalStaked !== 0 ? formatNumberAmount((totalYourAllocation / totalStaked) * 100) : 0}%
                      </p>
                      <p>
                        <span>Manual allocation</span>{manualAllocation ? formatNumberAmount(manualAllocation) : 0} xBLS
                      </p>
                      <p>
                        <span>Redeem allocation</span>
                        {totalYourAllocation && manualAllocation && totalYourAllocation && (totalYourAllocation - manualAllocation > 0) ? formatNumberAmount(((totalYourAllocation - manualAllocation) / totalYourAllocation) * 100) : 0}%
                      </p>
                    </>
                  )} </>
                ) : (
                  <button className="is-notConnect">Not connected</button>
                )}
              </li>
              <li>
                <h3>
                  Your Dividends
                  {account && chainId === 42161 ? <Button
                    text="Claim All"
                    type="blue"
                    onClick={() => handleClaimAll()}
                    isLoading={claimAllLoading}
                    disabled
                  /> : ""}
                </h3>
                {account ? (<>
                  {chainId !== 42161 ? (
                    <button className="btn-white btn-danger is-notChain" onClick={() => switchArb()}>
                      Switch to Arbitrum
                    </button>
                  ) : (
                    <>
                      <div className="c-poolItem">
                        <div className="c-poolItem__img">
                          <img src={tokenARBLogo} alt={tokenARB?.symbol} />
                        </div>
                        <div className="c-poolItem__conts">
                          <p>{tokenARB?.symbol}</p>
                          <h4>
                            {pendingARBReward ? formatNumberAmount(pendingARBReward, 0, 4) : 0} <span>(${earningArbTokenPrice && pendingARBReward ? formatNumberAmount(earningArbTokenPrice * pendingARBReward) : 0})</span>
                          </h4>
                        </div>
                      </div>
                      <div className="c-poolItem">
                        <div className="c-poolItem__img">
                          <img src="../images/bls-icon.svg" alt="" />
                        </div>
                        <div className="c-poolItem__conts">
                          <p>BLS</p>
                          <h4>
                            {pendingXBLSReward && formatNumberAmount(pendingXBLSReward, 0, 4)} <span>(${earningTokenPrice && pendingXBLSReward ? formatNumberAmount(earningTokenPrice * pendingXBLSReward) : 0})</span>
                          </h4>
                        </div>
                      </div>
                    </>
                  )}
                </>) : (
                  <button className="is-notConnect">Not connected</button>
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className="p-blsDividends__version">
          <Link to='/bls/dividends' className="c-btn">Back to V2</Link>
        </div>
        <div className="p-blsDividends__footer">© BlueSale. All rights reserved.</div>
      </div>

      {isCalculator && (
        <div className="c-popup is-xbls">
          <div className="c-popup__content">
            <h3 className="c-popup__title">Dividends calculator</h3>
            <div className="c-popup__box">
              <div className="c-input">
                <p className="c-input__title">Amount</p>
                <input
                  value={amountCalculator}
                  onChange={(e) => setAmountCalculator(e.target.value)}
                  placeholder="0.0"
                />
                <Button text="Max" onClick={() => handleSetMax(1)} />
              </div>
              <p>Allocated balance: {formatNumberAmount(totalYourAllocation)} xBLS</p>
              {!isApprove && <p>Your approved: {formatNumberAmount(amountApprove)} xBLS</p>}
              {amountCalculator !== "" ? (
                <>
                  <h4 className="c-ttl">Estimates</h4>
                  <p>
                    Total allocated amount<span>{amountCalculator && totalStaked ? formatNumberAmount(+amountCalculator + +totalYourAllocation) : 0} xBLS</span>
                  </p>
                  <p>
                    Total allocated share<span>{amountCalculator && totalYourAllocation && totalStaked ? formatNumberAmount(_totalShared) : 0}%</span>
                  </p>
                  <p>
                    Daily returns / BLS<span>{formatNumberAmount((rewardPerWeekXbls / 7) * Number(_totalShared) / 100)}</span>
                  </p>
                  <p>
                    Daily returns / USDC<span>{formatNumberAmount((rewardPerWeekArb / 7) * Number(_totalShared) / 100)}</span>
                  </p>
                  <p>
                    Total daily returns<span>${formatNumberAmount((rewardPerWeekArb / 7 + earningTokenPrice * rewardPerWeekXbls / 7) * Number(_totalShared) / 100)}</span>
                  </p>
                  <div className="is-note">
                    Those numbers are estimates based on the current daily
                    dividends distribution.
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="c-popup__btn is-one">
              <Button
                text="Close"
                type="greyBorder"
                onClick={() => handleSetCalculateCancel()}
              />
            </div>
          </div>
        </div>
      )}

      {isAllocate && (
        <div className="c-popup is-xbls">
          <div className="c-popup__content">
            <h3 className="c-popup__title">Allocate xBLS</h3>
            <div className="c-popup__box">
              <div className="c-input">
                <p className="c-input__title">Amount</p>
                <input
                  value={amountAllocate}
                  onChange={(e) => handleSetAllocate(e)}
                  placeholder="0.0"
                />
                <Button text="Max" onClick={() => handleSetMax(2)} />
              </div>
              <p>Balance: {formatNumberAmount(xblsBalance)} xBLS</p>
              {!isApprove && <p>Your approved: {formatNumberAmount(amountApprove)} xBLS</p>}
              {amountAllocate !== "" ? (
                <>
                  <h4 className="c-ttl">Estimates</h4>
                  <p>
                    Total allocated amount<span>{amountAllocate && totalStaked ? formatNumberAmount(+amountAllocate + +totalYourAllocation) : 0} xBLS</span>
                  </p>
                  <p>
                    Total allocated share<span>{amountAllocate && totalYourAllocation && totalStaked ? formatNumberAmount(_totalSharedAll) : 0}%</span>
                  </p>
                  <p>
                    Daily returns / BLS<span>{formatNumberAmount((rewardPerWeekXbls / 7) * Number(_totalSharedAll) / 100)}</span>
                  </p>
                  <p>
                    Daily returns / USDC<span>{formatNumberAmount((rewardPerWeekArb / 7) * Number(_totalSharedAll) / 100)}</span>
                  </p>
                  <p>
                    Total daily returns<span>${formatNumberAmount((rewardPerWeekArb / 7 + +earningTokenPrice * rewardPerWeekXbls / 7) * Number(_totalSharedAll) / 100)}</span>
                  </p>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="c-popup__btn">
              <Button
                text="Cancel"
                type="greyBorder"
                onClick={() => handleSetAllocateCancel()}
              />
              {isApprove ?
                <Button
                  text={"Allocate"}
                  type="blue"
                  isLoading={allocateLoading}
                  disabled={allocateLoading || +amountAllocate === 0 || +xblsBalance === 0}
                  onClick={() => handleAllocate()}
                /> :
                <Button
                  text={"Approve"}
                  type="blue"
                  isLoading={allocateLoading}
                  disabled={allocateLoading}
                  onClick={() => handleApproveModal("Allocate")}
                />}
            </div>
          </div>
        </div>
      )}

      {isDeallocate && (
        <div className="c-popup is-xbls">
          <div className="c-popup__content">
            <h3 className="c-popup__title">Deallocate xBLS</h3>
            <div className="c-popup__box">
              <div className="c-input">
                <p className="c-input__title">Amount</p>
                <input
                  value={amountDeallocate}
                  onChange={(e) => handleSetDeallocate(e)}
                  placeholder="0.0"
                  disabled={+manualAllocation === 0 ? true : false}
                />
                <Button text="Max" onClick={() => handleSetMax(3)} disabled={+manualAllocation === 0 ? true : false} />
              </div>
              <p>Balance: {formatNumberAmount(manualAllocation)} xBLS</p>
              {amountDeallocate !== "" ? (
                <>
                  <h4 className="c-ttl">Estimates</h4>
                  <p>
                    Total allocated amount<span>{amountDeallocate && totalStaked ? formatNumberAmount(+totalYourAllocation - +amountDeallocate) : 0} xBLS</span>
                  </p>
                  <p>
                    Total allocated share<span>{amountDeallocate && totalYourAllocation && totalStaked ? formatNumberAmount(_totalSharedDeAll) : 0}%</span>
                  </p>
                  <p>
                    Daily returns / BLS<span>{formatNumberAmount((+rewardPerWeekXbls / 7) * Number(_totalSharedDeAll) / 100)}</span>
                  </p>
                  <p>
                    Daily returns / USDC<span>{formatNumberAmount((rewardPerWeekArb / 7) * Number(_totalSharedDeAll) / 100)}</span>
                  </p>
                  <p>
                    Total daily returns<span>${formatNumberAmount((rewardPerWeekArb / 7 + +earningTokenPrice * rewardPerWeekXbls / 7) * Number(_totalSharedDeAll) / 100)}</span>
                  </p>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="c-popup__btn">
              <Button
                text="Cancel"
                type="greyBorder"
                onClick={() => handleSetDeallocateCancel()}
              />
              <Button
                text={"Deallocate"}
                type="blue"
                isLoading={allocateLoading}
                disabled={allocateLoading || +amountDeallocate === 0 || +manualAllocation === 0 || +totalYourAllocation === 0 || (+amountDeallocate > +manualAllocation)}
                onClick={() => handleDeallocate()}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BlsDividendsV1;
