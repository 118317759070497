import {
  convertFromWei,
  formatLaunchpadInfo,
  convertToWei,
  formatPrivateSaleInfo,
} from "./utils";
import { ethers } from "ethers";

declare const window: Window & typeof globalThis & { ethereum: any };

export const launchpadCreate = async (
  launchpadDeployContract: any,
  launchpadInfo: any,
  claimInfo: any,
  teamVestingInfo: any,
  dexInfo: any,
  feeInfo: any,
  percertAffiliate: any
) => {
  return await launchpadDeployContract.deployLaunchpad(
    launchpadInfo,
    claimInfo,
    teamVestingInfo,
    dexInfo,
    feeInfo,
    percertAffiliate,
    { value: feeInfo && feeInfo[0] }
  );
};

export const privateSaleCreate = async (
  privateSaleDeployContract: any,
  privateSaleInfo: any,
  vestingInfo: any,
  feeInfo: any,
  fundReleaseToWei: any
) => {
  //   console.log("feeInfo", feeInfo);
  return await privateSaleDeployContract.deployPrivateSale(
    privateSaleInfo,
    vestingInfo,
    feeInfo,
    fundReleaseToWei,
    { value: feeInfo }
  );
};

// export const getLaunchpadInfo = async (launchpadContract:any, tokenDecimals:any, feeTokenDecimals:any) => {
//     return new Promise(async (resolve, reject) => {
//         console.log(launchpadContract);
//         await launchpadContract.balanceOf()
//         .then((res:any) => {
//             const data:any = formatLaunchpadInfo({balance: res}, tokenDecimals, feeTokenDecimals);
//             resolve(data);
//         })
//         .catch((err:any)=>{
//             reject(err);
//         });
//     });
// }

export const getPrivateSaleInfo1 = async (
  launchpadContract: any,
  tokenDecimals: any,
  feeTokenDecimals: any
) => {
  return new Promise(async (resolve, reject) => {
    await launchpadContract
      .getPrivateSaleInfo()
      .then((res: any) => {
        const data: any = formatLaunchpadInfo(
          res,
          tokenDecimals,
          feeTokenDecimals
        );
        resolve(data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const getPrivateSaleInfo = async (
  launchpadContract: any,
  tokenDecimals: any,
  feeTokenDecimals: any,
  privateSaleAddress: any
) => {
  const transaction = await launchpadContract.getInfo();
  const data: any = formatPrivateSaleInfo(
    transaction,
    tokenDecimals,
    feeTokenDecimals
  );
  return data;
};

export const privateSaleGetState = async (launchpadContract, account) => {
  const transaction = await launchpadContract.privateSaleState();
  return transaction;
};
export const getPrivateSaleOwner = async (launchpadContract, account) => {
  if (account) {
    const transaction = await launchpadContract.privateSaleState();
    return transaction;
  }
};

export const getOwnerZoneInfo = async (
  launchpadContract: any,
  account: any,
  decimals: any
) => {
  return await launchpadContract.getOwnerZoneInfo(...[account]);
};

export const getJoinInfos = async (
  launchpadContract: any,
  account: any,
  decimals: any
) => {
  return await launchpadContract.joinInfos(...[account]);
};

export const getUserClaimAble = async (
  launchpadContract: any,
  account: any,
  decimals: any
) => {
  const data = await launchpadContract.getUserClaimAble(...[account]);
  return Number(convertFromWei(data, decimals));
};

export const getTeamCliffVesting = async (
  launchpadContract: any,
  decimals: any
) => {
  const data = await launchpadContract.teamCliffVesting();
  return Number(convertFromWei(data, decimals));
};

export const getAffiliateReward = async (
  launchpadContract: any,
  account: any,
  decimals: any
) => {
  const data = await launchpadContract.award(account);
  const dataReward = {
    yourReward: Number(convertFromWei(data[0], decimals)),
    poolReferrerCount: Number(data[1]),
    maxReward: Number(convertFromWei(data[2], decimals)),
    totalRefAmount: Number(convertFromWei(data[3], decimals)),
    totalReward: Number(convertFromWei(data[4], decimals)),
  };
  return dataReward;
};

export const getAffiliateListUser = async (
  launchpadContract: any,
  address: any
) => {
  const data = await launchpadContract.getUsersAffiliate(address);
  return data;
};

export const getAmountsAffiliate = async (
  launchpadContract: any,
  address: any
) => {
  const data = await launchpadContract.getAmountsAffiliate(address);
  return data;
};

export const getHoldingTokenUser = async (
  launchpadContract: any,
  decimals: any
) => {
  const data = {
    holdingToken: await launchpadContract.holdingToken(),
    holdingTokenAmount: await launchpadContract.holdingTokenAmount()
  };
  return data;
};

export const getPercertAffiliate = async (launchpadContract: any) => {
  const data = await launchpadContract.percentAffiliate();
  return Number(data);
};

export const claimTokens = async (launchpadContract: any) => {
  const args = [];

  const estimatedGas = await launchpadContract.estimateGas.claimTokens(...args);
  return await launchpadContract.claimTokens(...args, {
    gasLimit: estimatedGas,
  });
};

export const contribute = async (
  launchpadContract: any,
  amount: any,
  presenter: any,
  decimals: any
) => {
  const args = convertToWei(amount, decimals);
  const feeToken = await launchpadContract.feeToken();
  let options: any = { value: 0 };
  if (!parseInt(feeToken)) {
    options["value"] = convertToWei(amount, decimals);
  }
  console.log(args, presenter, options);
  return await launchpadContract.contribute(args, presenter, options);
};

export const emergencyWithdrawContribute = async (launchpadContract: any) => {
  return await launchpadContract.emergencyWithdrawContribute();
};

export const withdrawContribute = async (launchpadContract: any) => {
  return await launchpadContract.withdrawContribute();
};

export const withdrawContributeAffiliate = async (launchpadContract: any) => {
  return await launchpadContract.claimCommission();
};

export const finalizeLaunchpad = async (launchpadContract: any) => {
  return await launchpadContract.finalizeLaunchpad();
};

export const enableAffiliateLaunchpad = async (
  launchpadContract: any,
  status: any,
  affiliatePrecent: any
) => {
  return await launchpadContract.setAffiliate(status, affiliatePrecent);
};

export const cancelLaunchpad = async (launchpadContract: any) => {
  return await launchpadContract.cancelLaunchpad();
};

export const claimCanceledTokens = async (launchpadContract: any) => {
  return await launchpadContract.claimCanceledTokens();
};

export const setWhitelistPoolLaunchpad = async (
  launchpadContract: any,
  wlPool: any,
  holdingTokenAddress: any,
  amount: any,
  decimals: any
) => {
  const args = [wlPool, holdingTokenAddress, convertToWei(amount, decimals)];
  console.log(args)
  return await launchpadContract.setWhitelistPool(...args);
};

export const addWhiteListUsers = async (
  launchpadContract: any,
  listAddress: any
) => {
  return await launchpadContract.setWhitelistBuyers(listAddress);
};

export const removeWhiteListUsers = async (
  launchpadContract: any,
  listAddress: any
) => {
  return await launchpadContract.removeWhitelistBuyers(listAddress);
};

export const getListOfWhiteListUsers = async (launchpadContract: any) => {
  console.log("launchpadContract", launchpadContract);
  const _allAllocationCount: any = await launchpadContract.allAllocationCount();
  let listWL;
  if (_allAllocationCount == 0) {
    listWL = [];
  } else {
    listWL = await launchpadContract.getAllocations();
  }

  return listWL;
};

export const setLaunchpadAllocations = async (
  launchpadContract: any,
  listAddress: any,
  listAmounts: any
) => {
  return await launchpadContract.setAllocations(listAddress, listAmounts);
};

export const getJoinedUsers = async (launchpadContract: any) => {
  return await launchpadContract.getJoinedUsers();
};

export const getAffiliateExist = async (launchpadContract: any) => {
  return await launchpadContract.affiliate();
};

export const getFinalizeExist = async (launchpadContract: any) => {
  return await launchpadContract.state();
};

export const getGsLock = async (launchpadContract: any) => {
  return await launchpadContract.gsLock();
};

export const getLpLockId = async (launchpadContract: any) => {
  return await launchpadContract.lpLockId();
};

export const getTeamFirstReleasePercent = async (launchpadContract: any) => {
  return await launchpadContract.teamFirstReleasePercent();
};

export const getTeamLockId = async (launchpadContract: any) => {
  return await launchpadContract.teamLockId();
};

export const getRouterAddress = async (launchpadContract: any) => {
  return await launchpadContract.routerAddress();
};

export const setClaimTime = async (launchpadContract: any, claimTime: any) => {
  return await launchpadContract.setClaimTime(claimTime);
};

export const getWhitelistPool = async (launchpadContract: any) => {
  return await launchpadContract.whitelistPool();
};

export const getInfoLaunchpad = async (
  launchpadContract: any,
  tokenDecimals: any,
  feeTokenDecimals: any
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const infoLaunchpad = {
        softCap: await launchpadContract?.softCap(),
        hardCap: await launchpadContract?.hardCap(),
        startTime: await launchpadContract?.startTime(), // uint256
        endTime: await launchpadContract?.endTime(), // uint256
        state: await launchpadContract?.state(),
        raisedAmount: await launchpadContract?.raisedAmount(),
        feeToken: await launchpadContract?.feeToken(),
        listingTime: await launchpadContract?.listingTime(),
        whitelistPool: await launchpadContract?.whitelistPool(),
        holdingToken: await launchpadContract?.holdingToken(),
        holdingTokenAmount: await launchpadContract?.holdingTokenAmount(),
        maxInvest: await launchpadContract?.maxInvest(),
        minInvest: await launchpadContract?.minInvest(),
        globalTotalInvestment: await launchpadContract?.globalTotalInvestment(),
      };

      const data: any = formatLaunchpadInfo(
        infoLaunchpad,
        tokenDecimals,
        feeTokenDecimals
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getTeamLockIdNoHook = async (
  launchpadSCAbi: any,
  launchpadContractAdress: any
) => {
  if (typeof window.ethereum !== "undefined") {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const launchpadContract = new ethers.Contract(
      launchpadContractAdress,
      launchpadSCAbi,
      provider
    );
    return await launchpadContract.teamLockId();
  }
};
