import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import VestingTeamService from "../services/VestingTeamService";

// ================================================
// ==                  ACTION                    ==
// ================================================
export const createVestingTeam = (payload:PayloadAction, onCallBack: Function) => {
    return async dispatch => {  
      dispatch(setLoading(true));
        await VestingTeamService.createVestingTeam(payload)
        .then((res)=>{  
            onCallBack({result: "SUCCESS", data: res?.data});
            dispatch(setLoading(false));  
        })
        .catch((error:any)=>{
            onCallBack({result: "ERROR", error: error});
            dispatch(setErrors(error));
            dispatch(setLoading(false));  
        });
    }
  }

// ================================================
// ==                REDUX STORE                 ==
// ================================================
type VestingTeamObject = {
  [key: string]: void | string | number | any;
};

export interface VestingTeamState {
    // launchpads: VestingTeamObject[];
    // launchpadDetail: VestingTeamObject;
    // launchPadTrending: VestingTeamObject;
    // vestingTeam: VestingTeamObject;
    vestingTeamLoading: boolean;
    vestingTeamErrors: string;
}

const initialState: VestingTeamState = {
    // launchpads: [],
    // launchpadDetail: {},
    // launchPadTrending: [],
    // vestingTeam: {},
    vestingTeamLoading: false,
    vestingTeamErrors: "",
}

const vestingTeamSlice = createSlice({
  name: "vestingTeam",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.vestingTeamLoading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.vestingTeamErrors = payload
    },
  },
})

export const { setLoading, setErrors } = vestingTeamSlice.actions

export default vestingTeamSlice.reducer

export const vestingTeamSelector = (state: { vestingTeamStore: VestingTeamState }) => state.vestingTeamStore