
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Button, Form, Steps, Alert, Spin } from 'antd';
import web3 from 'web3';
import { ethers } from 'ethers';
import { useActiveWeb3React } from '../../../hooks';
import AirdropVerifyToken from './airdrop-verify-token';
import AirdropInfo from './airdrop-info';
import { useNavigate } from "react-router-dom"; 
import { useAppSelector } from "../../../redux/hooks";
import { settingSelector } from "../../../redux/slicers/settingSlice";
import { CHAINID_SYMBOL, FEE_SETTINGS, MAIN_CURRENCIES, RPC_URL_CONVERT, SMART_CONTRACT_ADDRESS } from '../../../constants';
import AirdropAdditionInfo from './airdrop-addition-info';
import deployAirdropAbi from '../../../contracts/abi/DeployAirdropAbi.json';
import { useContract } from '../../../hooks/useContract';
import { Store } from 'react-notifications-component';
import addNotify from '../../../components/commons/Notify/addNotify';
import { deployAirdrop } from '../utils'
import AirdropService from '../../../redux/services/AirdropService';
import AirdropFinish from './airdrop-finish';

const { Content } = Layout;
const { Step } = Steps;

declare const window: Window & typeof globalThis & { ethereum: any };

const AirdropCreate = () => {
    const { chainId, account } = useActiveWeb3React();
    const { settings } = useAppSelector(settingSelector); 
    const [initAirdropFeeOpj, setInitAirdropFee0pj] = useState<any>();
    const [initAirdropFee, setInitAirdropFee] = useState<any>();
    const [loading, setLoading] = useState<any>(false);
    const [authToken, setAuthToken] = useState<any>();
    const [ethBalance, setEthBalance] = useState(0);
    const [form] = Form.useForm();
    const navigate = useNavigate()
    let initStep = 0;

    let chainString:any = chainId;

    const mainCurrency = MAIN_CURRENCIES[chainString];

    const networkByChain = CHAINID_SYMBOL[chainString];

    const deployAirdropAddress = SMART_CONTRACT_ADDRESS[chainString]?.["DEPLOY_AIRDROP_ADDRESS"];

    const deployAirdropContract = useContract(deployAirdropAddress, deployAirdropAbi);

    const provider = RPC_URL_CONVERT[chainString];

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    
    let storageTokenInfo = window.localStorage.getItem('airdropTokenInfo');

    let storageAirdropInfo = window.localStorage.getItem('airdropInfo');
    let storageAdditionalInfo = window.localStorage.getItem('airdropAdditionalInfo');

    if (storageTokenInfo && !storageAirdropInfo) {
        initStep = 0
    }
    if (storageAirdropInfo) {
        initStep = 1
    }
    if (storageAdditionalInfo) {
        initStep = 2
    }
    const [current, setCurrent] = React.useState(initStep);

    let airdropTokenInfo: any;
    let airdropInfo: any;
    let additionalInfo: any;
    

    if (storageTokenInfo) {
        airdropTokenInfo = JSON.parse(storageTokenInfo);
    }

    if (storageAdditionalInfo) {
        additionalInfo = JSON.parse(storageAdditionalInfo);
    }
    if (storageAirdropInfo) {
        airdropInfo = JSON.parse(storageAirdropInfo);
    }

    const getAuthToken = async (account) => {
        await AirdropService.getAuthToken(account)
        .then((res)=>{
            setAuthToken(res.data.id_token);
        })
        .catch((error:any)=>{});
    }

    const getSettings = async () => {
        const nodeEvn = process.env.REACT_APP_NODE_ENV;
        let _chainId = (nodeEvn === 'production' || nodeEvn === 'staging') ? 42161 : (chainId ?? 97);
        const feeSettings = FEE_SETTINGS[_chainId];
        if (settings && settings.length > 0) {
            let airdropFee = settings.filter((itemSetting) => feeSettings?.INIT_AIRDROP_FEE_ARB === itemSetting.settingKey);
            
            if (airdropFee) {
                setInitAirdropFee(airdropFee[0]?.settingValue);
                setInitAirdropFee0pj(airdropFee[0]);
            }
        }
    }

    useEffect(() => {
        if(account){
            getAuthToken(account);
        }
    }, [account]);

    useEffect(() => {
        getSettings();
    }, [settings, account]);

    useEffect(() => {
        if (account) {
            w3.eth.getBalance(account, (err, balance) => {
                if (balance) {
                    let _balance: any = web3.utils.fromWei(web3.utils.toBN(balance),"ether");
                    let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
                    setEthBalance(bnbBl);
                }
            });
        }
    }, [account]);

    const steps = [
        {
            title: 'Verify Token',
            content: <AirdropVerifyToken form={form} next={setCurrent} />,
            description: ''
        },
        {
            title: 'Airdrop Info',
            content: <AirdropInfo form={form}/>,
            description: ''
        },
        {
            title: 'Add Addition Info',
            content: <AirdropAdditionInfo form={form}/>,
            description: ''
        },
        {
            title: 'Finish',
            content: <AirdropFinish form={form} initAirdropFeeOpj={initAirdropFeeOpj}/>,
            description: ''
        }
    ];

    const onConfirmSteps = (values: any) => {
        if (values.step_verify_token && values.step_verify_token === 1) {
            let data = JSON.stringify(values);
            
            window.localStorage.setItem('airdropTokenInfo', data);
            // window.location.reload();
        }
        if (airdropTokenInfo && values.step_airdrop_info && values.step_airdrop_info === 2) {
            let data = JSON.stringify(values);
            window.localStorage.setItem('airdropInfo', data);
            // window.location.reload();
        }
        if (airdropTokenInfo && values.step_additional_info && values.step_additional_info === 3) {
            let data = JSON.stringify(values);
            console.log('airdropAdditionalInfo airdropAdditionalInfo airdropAdditionalInfo', data);
            window.localStorage.setItem('airdropAdditionalInfo', data);
            // window.location.reload();
        }
        setCurrent(current + 1);
    }

    const prev = () => {
        setCurrent(current - 1);
    };

    const handleCancel = () => {
        localStorage.removeItem("airdropTokenInfo");
        localStorage.removeItem("airdropAdditionalInfo");
        localStorage.removeItem("airdropInfo");
        setCurrent(0);
        navigate("/airdrop/create");  
    }

    console.log('eth balance', ethBalance);
    console.log('initAirdropFee', initAirdropFee);
    // console.log('airdropInfo', airdropInfo);
    // console.log('additionalInfo', additionalInfo);
    // console.log('initAirdropFee', initAirdropFee);

    const confirmCreateIdo = async () => {
        setLoading(true);
        try {
            

            if(initAirdropFee === 0){
                setLoading(false);
                Store.addNotification(addNotify('Invalid init fee airdrop create', 'error'));
                return;
            }

            const fundPercent = 100;
            const feeAmount = ethers.utils.parseUnits(`${initAirdropFee}`, 18)

            await deployAirdrop(deployAirdropContract, airdropTokenInfo.token_address, fundPercent, feeAmount).then((txn) => {
                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {

                                    if (!countNoti) {
                                        countNoti++;
                                        let newSCAddress;

                                        for (let i = 0; i < res.logs.length; i++) {
                                            if (res.logs[i].topics && res.logs[i].topics.length == 2 && res.logs[i].topics[0] === '0xb5f54381f1b62853b1376e6d5833ba01af971a761c4efef72944cb6e64d591f1') {
                                                newSCAddress = res.logs[i].topics[1];
                                            }
                                        }
                                        console.log(newSCAddress);
                                        if (newSCAddress) {
                                            newSCAddress = w3.eth.abi.decodeParameter('address', newSCAddress)
                                            
                                            const payload = {
                                                
                                                "startJoin": airdropInfo?.start_time,
                                                "endJoin": airdropInfo?.end_time,
                                                "startClaim": "",
                                                "endClaim": "",
                                                "joinLink": airdropInfo?.join_link,
                                                "currency": mainCurrency,
                                                "reward": airdropInfo?.reward ?? 0,
                                                "airdropName": additionalInfo?.name ?? "",
                                                "airdropInfo": additionalInfo?.info ?? "",
                                                "bannerUrl": additionalInfo?.banner ?? "",
                                                "tgePercent": 0,
                                                "cyclePercent": 0,
                                                "cycleMinutes": 0,
                                                "airdropAddress": newSCAddress,
                                                "network": networkByChain,
                                                "socialNetwork": {
                                                    "id": 0,
                                                    "facebook": additionalInfo?.facebook ?? "",
                                                    "twitter": additionalInfo?.twitter ?? "",
                                                    "medium": additionalInfo?.medium ?? "",
                                                    "discord": additionalInfo?.discord ?? "",
                                                    "github": additionalInfo?.github ?? "",
                                                    "telegram": additionalInfo?.telegram ?? "",
                                                    "instagram": additionalInfo?.instagram ?? "",
                                                    "reddit": additionalInfo?.reddit ?? "",
                                                    "website": additionalInfo?.website ?? "",
                                                    "youtube": additionalInfo?.youtube ?? "",
                                                },
                                                "airdropTokenInform": {
                                                    "id": 0,
                                                    "tokenAddress": airdropTokenInfo?.token_address ?? "",
                                                    "name": airdropTokenInfo?.token_name ?? "",
                                                    "symbol": airdropTokenInfo?.token_symbol ?? "",
                                                    "logoURL": airdropTokenInfo?.logoURL ?? "",
                                                    "decimals": airdropTokenInfo?.token_decimal ?? 0
                                                }
                                            }

                                            console.log('payload', payload);
                                            
                                            await AirdropService.createAirDrop(payload, authToken).then((res:any)=>{
                                                if (res && res.status == 200 || res.status == 201) {
                                                    window.localStorage.removeItem('airdropTokenInfo');
                                                    window.localStorage.removeItem('airdropInfo');
                                                    window.localStorage.removeItem('airdropAdditionalInfo');
                                                    setLoading(false);
                                                    Store.addNotification(addNotify('Create Airdrop Success', 'success'));
                                                    navigate(`/airdrop/detail/${res.data.handle}`);
                                                }
                                            }).catch((error:any)=>{
                                                setLoading(false);
                                                Store.addNotification(addNotify(error.message, 'danger'));
                                            });
                               
                                        }
                                    }
                                } else {
                                    setLoading(false);
                                    Store.addNotification(addNotify('Create Airdrop Failed', 'danger'));
                                }
                                setLoading(false);

                            }
                        })();
                    }, 1000);
                }
            }).catch((error) => {
                console.log(error);
                setLoading(false);
                if (error) {
                    if (error.code == 4001 && error.message) {
                        Store.addNotification(addNotify(error.message, 'danger'));
                    } else if (error.reason) {
                        Store.addNotification(addNotify(error.reason, 'danger'));
                    } else {
                        if (error.data && error.data.message) {
                            Store.addNotification(addNotify(error.data.message, 'danger'));
                        }
                    }
                }
            });
        
        } catch (error:any) {
            console.log('Error: ', error);
            Store.addNotification(addNotify(error.message, 'error'));
        }
    };
    

    return (
        <>
            <div className="p-airdropCreate">    
                <Steps current={current} className="airdrop-create-step">
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} description={item.description} />
                    ))}
                </Steps>    
                <Form form={form} layout="vertical" autoComplete="off" onFinish={onConfirmSteps}>
                    <div className="airdrop-create-content">
                        {steps[current]?.content}
                    </div>
                    {current == 1 && (
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row text-center mb-15 mt-15" xs={24}>
                                <Alert className='w-auto m-auto' message={`Create airdrop fee: ${initAirdropFeeOpj ? initAirdropFeeOpj?.settingValue : 0} ${initAirdropFeeOpj ? initAirdropFeeOpj?.payToken : ''}`} type="info" showIcon />
                            </Col>
                        </Row>
                    )}
                    {current === steps.length - 1 && (
                        <p className='text-center'><span className='text-info'>Your ETH balance</span>: <strong className='text-danger'>{ethBalance}</strong> ETH</p>
                    )}
                    
                    <div className="airdrop-create-action mt-15">
                        <Button className="c-btn c-btn--greyBorder btn-p50 mb-15" htmlType="button" onClick={()=>handleCancel()} disabled={loading}>
                            Cancel
                        </Button>
                        {current > 0 && (
                            <button className="c-btn ml-15 btn-p50 mb-15" type="button" onClick={() => prev()} disabled={loading}>
                                Previous
                            </button>
                        )}
                        {current < steps.length - 1 && (
                            <>
                                {current == 0 && (
                                    <button disabled={!account || loading} className="c-btn c-btn--blue ml-15 btn-p50 mb-15" type="submit">
                                        {account ? 'Next' : 'Connect Wallet'}
                                    </button>
                                )}
                                {current == 1 && (
                                    <button disabled={!account || loading} className="c-btn c-btn--blue ml-15 btn-p50 mb-15" type="submit">
                                        {account ? 'Next' : 'Connect Wallet'}
                                    </button>
                                )}
                                {current == 2 && (
                                    <button disabled={!account || initAirdropFee == 0} className="c-btn ml-15 btn-p50 mb-15 c-btn--blue" type="submit">
                                        {account ? 'Next' : 'Connect Wallet'}
                                    </button>
                                )}
                            </>
                        )}
                        {current === steps.length - 1 && (
                            <>
                                {initAirdropFee === 0 || ethBalance < initAirdropFee ? (
                                    <>
                                        <button type="button" disabled className="c-btn ml-15 btn-p50 mb-15">
                                            {account ? 'Confirm' : 'Connect Wallet'} 
                                        </button>
                                    </>
                                ):(
                                    <>
                                        <button type="button" disabled={!account || loading} className="c-btn c-btn--blue ml-15 btn-p50 mb-15" onClick={confirmCreateIdo}>
                                            {account ? 'Confirm' : 'Connect Wallet'} 
                                            {loading && (
                                                <Spin className="style-loading" size="small" />
                                            )}
                                        </button>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </Form> 
            </div>
        </>
    )
}

export default AirdropCreate
