import { RPC_URL_CONVERT } from "../constants";

declare const window: Window & typeof globalThis & { ethereum: any };

const networkChange = async (chainId: any) => {
  
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainId }],
    });
  } catch (error: any) {
    try {
      if (chainId === "0x89") {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Polygon Mainnet",
              chainId: `0x${Number(137).toString(16)}`,
              rpcUrls: [
                "https://polygon-rpc.com",
                "https://rpc-mainnet.matic.network",
                "https://rpc-mainnet.maticvigil.com",
                "https://rpc-mainnet.matic.quiknode.pro",
                "https://matic-mainnet.chainstacklabs.com",
                "https://matic-mainnet-full-rpc.bwarelabs.com",
                "https://matic-mainnet-archive-rpc.bwarelabs.com",
                "https://poly-rpc.gateway.pokt.network/",
                "https://rpc.ankr.com/polygon",
                "https://rpc-mainnet.maticvigil.com/",
              ],
              blockExplorerUrls: ["https://polygonscan.com/"],
              nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC",
                decimals: 18,
              },
            },
          ],
        });
      } else if (chainId === "0x38") {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Binance Smart Chain",
              chainId: `0x${Number(56).toString(16)}`,
              nativeCurrency: {
                name: "Binance Chain Native Token",
                symbol: "BNB",
                decimals: 18,
              },
              rpcUrls: [
                "https://bsc-dataseed1.binance.org",
                "https://bsc-dataseed2.binance.org",
                "https://bsc-dataseed3.binance.org",
                "https://bsc-dataseed4.binance.org",
                "https://bsc-dataseed1.defibit.io",
                "https://bsc-dataseed2.defibit.io",
                "https://bsc-dataseed3.defibit.io",
                "https://bsc-dataseed4.defibit.io",
                "https://bsc-dataseed1.ninicoin.io",
                "https://bsc-dataseed2.ninicoin.io",
                "https://bsc-dataseed3.ninicoin.io",
                "https://bsc-dataseed4.ninicoin.io",
                "wss://bsc-ws-node.nariox.org",
              ],
              blockExplorerUrls: ["https://bscscan.com"],
            },
          ],
        });
      } else if (chainId === "0x61") {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "BSC TestNet",
              chainId: `0x${Number(97).toString(16)}`,
              nativeCurrency: {
                name: "Binance Chain Native Token",
                symbol: "BNB",
                decimals: 18,
              },
              rpcUrls: [
                "https://data-seed-prebsc-1-s1.binance.org:8545",
                "https://data-seed-prebsc-2-s1.binance.org:8545",
                "http://data-seed-prebsc-1-s2.binance.org:8545",
                "http://data-seed-prebsc-2-s2.binance.org:8545",
                "https://data-seed-prebsc-1-s3.binance.org:8545",
                "https://data-seed-prebsc-2-s3.binance.org:8545",
              ],
              blockExplorerUrls: ["https://testnet.bscscan.com"],
            },
          ],
        });
      } else if (chainId === "0xa4b1" || chainId === "0xA4B1") {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Arbitrum One",
              chainId: "0xa4b1",
              rpcUrls: [RPC_URL_CONVERT[42161]],

              nativeCurrency: {
                name: "Arbitrum One",
                symbol: "ETH",
                decimals: 18,
              },
            },
          ],
        });
      } else if (chainId === "0x66EED") {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: "Arbitrum Goerli",
              chainId: "0x66EED",
              rpcUrls: [RPC_URL_CONVERT[421613]],
              nativeCurrency: {
                name: "Arbitrum Goerli",
                symbol: "ETH",
                decimals: 18,
              },
            },
          ],
        });
      }
    } catch (err) {
      console.log(err);
    }
    // }
    console.error(error);
  }
};

export default networkChange;
