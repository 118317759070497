import BigNumber from "bignumber.js";

export const NetworkContextName = "NETWORK";
export const Tokens = {
  AUTHUSER: "AUTHUSER",
};
export const MAINNET_BSC_URL = "https://bsc-dataseed.binance.org";
export const MAINNET_CHAIN_ID = 56;
export const TESTNET_BSC_URL = "https://bsc-testnet.public.blastapi.io";
export const TESTNET_CHAIN_ID = 97;
// MAINNET NETWORK
export const MAPPING_CHAINID = {
  bsc: "0x38",
  eth: "0x1",
  poly: "0x89",
  ftom: "0xFA",
  bsctestnet: "0x61",
  arb: "0xa4b1",
  arbtestnet: "0x66EED",
};
export const CHAIN_INFO = {
  bsc: {
    decimal: "ether",
    token: {
      id: "wbnb",
      symbol: "BNB",
      contract: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    },
    stableToken: {
      id: "binance-usd",
      symbol: "busd",
      contract: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    },
  },
  eth: {
    decimal: "picoether",
    token: {
      id: "ethereum",
      symbol: "ETH",
      contract: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    },
    stableToken: {
      id: "tether",
      symbol: "USDT",
      contract: "0x55d398326f99059fF775485246999027B3197955",
    },
  },
  poly: {
    decimal: "picoether",
    token: {
      id: "polygon",
      symbol: "MATIC",
      contract: "",
    },
    stableToken: {
      id: "usd-coin",
      symbol: "USDC",
      contract: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    },
  },
  ftom: {},
};
export const CHAIN_STABLECOIN = {
  busd: "",
};
export const MAPPING_CHAINID_DECIMAL = {
  bsc: 56,
  eth: 1,
  poly: 137,
  ftom: 250,
  bsctestnet: 97,
  arb: 42161,
  arbtestnet: 421613,
};
export const CHAINID_CONVERT = {
  56: "bsc",
  1: "eth",
  137: "poly",
  250: "ftom",
  97: "bsctestnet",
  42161: "arb",
  421613: "arbtestnet",
};

export const CHAINID_CONVERT_UPCASE = {
  56: "BSC",
  1: "ETH",
  137: "POLY",
  250: "FTOM",
  97: "BSCTESTNET",
  42161: "ARB",
  421613: "ARBTESTNET",
};

export const RPC_URL_CONVERT = {
  56: "https://bsc-dataseed1.binance.org",
  137: "https://polygon-rpc.com",
  250: "https://rpc.ftm.tools/",
  97: "https://bsc-testnet.public.blastapi.io",
  42161: "https://arb1.arbitrum.io/rpc",
  421613: "https://goerli-rollup.arbitrum.io/rpc",
};

export const CHAINID_FULLNAME = {
  bsc: "Binance Smart Chain",
  eth: "Ethereum",
  poly: "Polygon",
  ftom: "Phantom",
  bsctestnet: "BSCTESTNET",
  arb: "Arbitrum",
  arbtestnet: "Arbitrum Testnet",
};

export const CHAINID_SYMBOL = {
  BSC: "BSC",
  ETH: "ETH",
  POLY: "POLY",
  FTOM: "FTOM",
  BSCTESTNET: "BSCT",
  ARB: "ARB",
  ARBTESTNET: "ARBT",
};

export const ADDRESS_USD = {
  56: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
  1: "0xdac17f958d2ee523a2206206994597c13d831ec7",
  137: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
  250: "0xAd84341756Bf337f5a0164515b1f6F993D194E1f",
  42161: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
  421613: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
};
export const DECIMALS_STRING = {
  18: "ether",
  12: "microether",
  9: "nanoether",
  6: "picoether",
};
export const LAUNCHPAD_CONFIGS = {
  SERVICE_FEE_RECEIVER: "0x283a6420627cbDeF9e93395904f3d1f1FaC84C57",
  SERVICE_FEE_AMOUNT: 0.001,
  ROUTER_ADDRESS: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
  FACTORY_ADDRESS: "0x6725F303b657a9451d8BA641348b6761A6CC7a17",
};

export const SMART_CONTRACT_ADDRESS = {
  97: {
    ANTI_BOT_ADDRESS: "0x5b0b37e50623B91CCFED1885b53E779d32941294",
    DEPLOY_LAUNCHPAD_ADDRESS: "0xd2f0f7f0f4a384bf3c8f94b2b7cb35391ba88ae6", // Launchpad v2 include affiliate,
    DEPLOY_FAIRLAUNCH_ADDRESS: "0x62c8dCe714949912ceC3ed8d21354001D0Af67fA",
    DEPLOY_PRIVATESALE_ADDRESS: "0xe0039CDd5602F59FeCA80B5B6389E3d6Ee9e254c",
    GS_LOCK_ADDRESS: "0x6A440D839Dd526A16858Dff73e56Da71e0258D9B",
  },
  80001: {
    ANTI_BOT_ADDRESS: "",
    DEPLOY_LAUNCHPAD_ADDRESS: "",
    DEPLOY_FAIRLAUNCH_ADDRESS: "",
    DEPLOY_PRIVATESALE_ADDRESS: "",
    GS_LOCK_ADDRESS: "",
  },
  5: {
    ANTI_BOT_ADDRESS: "",
    DEPLOY_LAUNCHPAD_ADDRESS: "",
    DEPLOY_FAIRLAUNCH_ADDRESS: "",
    DEPLOY_PRIVATESALE_ADDRESS: "",
    GS_LOCK_ADDRESS: "",
  },
  56: {
    ANTI_BOT_ADDRESS: "0x1a8790c445cfAF93CC5425dAd83b0d67D2C8b381",
    DEPLOY_LAUNCHPAD_ADDRESS: "0xc53868B5aeFd750f06f9e7e475FC6007d25D48B1",
    DEPLOY_FAIRLAUNCH_ADDRESS: "",
    DEPLOY_PRIVATESALE_ADDRESS: "",
    GS_LOCK_ADDRESS: "0x29757E2f12A7b668D459D93d189A433f1021bFA0",
  },
  1: {
    ANTI_BOT_ADDRESS: "",
    DEPLOY_LAUNCHPAD_ADDRESS: "",
    DEPLOY_FAIRLAUNCH_ADDRESS: "",
    DEPLOY_PRIVATESALE_ADDRESS: "",
    GS_LOCK_ADDRESS: "",
  },
  250: {
    ANTI_BOT_ADDRESS: "",
    DEPLOY_LAUNCHPAD_ADDRESS: "",
    DEPLOY_FAIRLAUNCH_ADDRESS: "",
    DEPLOY_PRIVATESALE_ADDRESS: "",
    GS_LOCK_ADDRESS: "",
  },
  137: {
    ANTI_BOT_ADDRESS: "",
    DEPLOY_LAUNCHPAD_ADDRESS: "",
    DEPLOY_FAIRLAUNCH_ADDRESS: "",
    DEPLOY_PRIVATESALE_ADDRESS: "",
    GS_LOCK_ADDRESS: "",
  },
  43114: {
    ANTI_BOT_ADDRESS: "",
    DEPLOY_LAUNCHPAD_ADDRESS: "",
    DEPLOY_FAIRLAUNCH_ADDRESS: "",
    DEPLOY_PRIVATESALE_ADDRESS: "",
    GS_LOCK_ADDRESS: "",
  },
  66: {
    ANTI_BOT_ADDRESS: "",
    DEPLOY_LAUNCHPAD_ADDRESS: "",
    DEPLOY_FAIRLAUNCH_ADDRESS: "",
    DEPLOY_PRIVATESALE_ADDRESS: "",
    GS_LOCK_ADDRESS: "",
  },
  42161: {
    ANTI_BOT_ADDRESS: "0x2e97f359A0AD0d14c98144784cfa6938b3C5F7B2",
    DEPLOY_LAUNCHPAD_ADDRESS: "0xa66874755E570A44c5cf0D064700DeA15A90D599",
    DEPLOY_FAIRLAUNCH_ADDRESS: "0x338472b26c158cdc24339921F0084138f3c19A89",
    DEPLOY_PRIVATESALE_ADDRESS: "0x733C5E18459bA5DFd43762BD8095d9173b8eD1d2",
    GS_LOCK_ADDRESS: "0x523bC96e502913718c3B255513Ed5c543cBE782D",
    DEPLOY_AIRDROP_ADDRESS: "0xd155809D59d777f55430E4566e7eA1cE0c3557f5",
  },
  421613: {
    ANTI_BOT_ADDRESS: "0xeF7e5984d2B425DE27063C05992F9E63Ec004bE4",
    DEPLOY_LAUNCHPAD_ADDRESS: "0x642D08E5A20d5A09D6A3395cF999b6b710a3DdF6",
    DEPLOY_FAIRLAUNCH_ADDRESS: "0x05f132e76fe7782Be99a363ec62FC127B583364B",
    DEPLOY_PRIVATESALE_ADDRESS: "0x6030F1bd944EFA390463B292dA0f7582B102c684",
    GS_LOCK_ADDRESS: "0x02803B07F77E1F97E9b8eC9Db11551038eB71aC2",
    DEPLOY_AIRDROP_ADDRESS: "",
  },
};

export const SALETYPE_WHITELIST_POOL = {
  PUBLIC: 0,
  WHITELIST: 1,
  PUBLIC_ANTIBOT: 2,
};

export const FEE_SETTINGS = {
  97: {
    INIT_TOKEN_FEE: "INIT_TOKEN_FEE_BSC_TESTNET",
    INIT_LAUNCHPAD_FEE: "INIT_LAUNCHPAD_FEE_BSC_TESTNET",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE: "LIST_LAUNCHPAD_TOKEN_RAISED_FEE_BSC",
    LIST_LAUNCHPAD_AMOUNT_RAISED_FEE: "LIST_LAUNCHPAD_AMOUNT_RAISED_FEE_BSC",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY: "LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY_BSC",
    INIT_FAIRLAUNCH_FEE: "INIT_FAIRLAUNCH_FEE_BSC_TESTNET",
    LIST_FAIRLAUNCH_TOKEN_RAISED_FEE: "LIST_FAIRLAUNCH_TOKEN_RAISED_FEE_BSC_TESTNET",
    LIST_FAIRLAUNCH_AMOUNT_RAISED_FEE: "LIST_FAIRLAUNCH_AMOUNT_RAISED_FEE_BSC_TESTNET",
    LIST_FAIRLAUNCH_TOKEN_RAISED_FEE_ONLY: "LIST_FAIRLAUNCH_TOKEN_RAISED_FEE_ONLY_BSC_TESTNET",
    INIT_PRIVATE_SALE_FEE: "INIT_PRIVATE_SALE_FEE_BSC_TESTNET"
  },
  80001: {
    INIT_TOKEN_FEE: "",
    INIT_LAUNCHPAD_FEE: "",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE: "",
    LIST_LAUNCHPAD_AMOUNT_RAISED_FEE: "",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY: "",
  },
  5: {
    INIT_TOKEN_FEE: "",
    INIT_LAUNCHPAD_FEE: "",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE: "",
    LIST_LAUNCHPAD_AMOUNT_RAISED_FEE: "",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY: "",
  },
  56: {
    INIT_TOKEN_FEE: "INIT_TOKEN_FEE_BSC_TESTNET",
    INIT_LAUNCHPAD_FEE: "INIT_LAUNCHPAD_FEE_BSC_TESTNET",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE: "LIST_LAUNCHPAD_TOKEN_RAISED_FEE_BSC",
    LIST_LAUNCHPAD_AMOUNT_RAISED_FEE: "LIST_LAUNCHPAD_AMOUNT_RAISED_FEE_BSC",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY: "LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY_BSC",
    INIT_FAIRLAUNCH_FEE: "INIT_FAIRLAUNCH_FEE_BSC_TESTNET",
    LIST_FAIRLAUNCH_TOKEN_RAISED_FEE: "LIST_FAIRLAUNCH_TOKEN_RAISED_FEE_BSC_TESTNET",
    LIST_FAIRLAUNCH_AMOUNT_RAISED_FEE: "LIST_FAIRLAUNCH_AMOUNT_RAISED_FEE_BSC_TESTNET",
    LIST_FAIRLAUNCH_TOKEN_RAISED_FEE_ONLY: "LIST_FAIRLAUNCH_TOKEN_RAISED_FEE_ONLY_BSC_TESTNET",
    INIT_PRIVATE_SALE_FEE: "INIT_PRIVATE_SALE_FEE_BSC_TESTNET"
  },
  1: {
    INIT_TOKEN_FEE: "INIT_TOKEN_FEE_ETH",
    INIT_LAUNCHPAD_FEE: "INIT_LAUNCHPAD_FEE_ETH",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE: "LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ETH",
    LIST_LAUNCHPAD_AMOUNT_RAISED_FEE: "LIST_LAUNCHPAD_AMOUNT_RAISED_FEE_ETH",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY:
      "LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY_ETH",
  },
  250: {
    INIT_TOKEN_FEE: "",
    INIT_LAUNCHPAD_FEE: "",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE: "",
    LIST_LAUNCHPAD_AMOUNT_RAISED_FEE: "",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY: "",
    INIT_AIRDROP_FEE_ARB: "",
  },
  137: {
    INIT_TOKEN_FEE: "",
    INIT_LAUNCHPAD_FEE: "",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE: "",
    LIST_LAUNCHPAD_AMOUNT_RAISED_FEE: "",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY: "",
    INIT_AIRDROP_FEE_ARB: "",
  },
  43114: {
    INIT_TOKEN_FEE: "",
    INIT_LAUNCHPAD_FEE: "",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE: "",
    LIST_LAUNCHPAD_AMOUNT_RAISED_FEE: "",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY: "",
    INIT_AIRDROP_FEE_ARB: "",
  },
  66: {
    INIT_TOKEN_FEE: "",
    INIT_LAUNCHPAD_FEE: "",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE: "",
    LIST_LAUNCHPAD_AMOUNT_RAISED_FEE: "",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY: "",
    INIT_AIRDROP_FEE_ARB: "",
  },
  42161: {
    INIT_TOKEN_FEE: "INIT_TOKEN_FEE_ARB",
    INIT_LAUNCHPAD_FEE: "INIT_LAUNCHPAD_FEE_ARB",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE: "LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ARB",
    LIST_LAUNCHPAD_AMOUNT_RAISED_FEE: "LIST_LAUNCHPAD_AMOUNT_RAISED_FEE_ARB",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY: "LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY_ARB",
    INIT_AIRDROP_FEE_ARB: "INIT_AIRDROP_FEE_ARB",
    INIT_FAIRLAUNCH_FEE: "INIT_FAIRLAUNCH_FEE_ARB",
    LIST_FAIRLAUNCH_TOKEN_RAISED_FEE: "LIST_FAIRLAUNCH_TOKEN_RAISED_FEE_ARB",
    LIST_FAIRLAUNCH_AMOUNT_RAISED_FEE: "LIST_FAIRLAUNCH_AMOUNT_RAISED_FEE_ARB",
    LIST_FAIRLAUNCH_TOKEN_RAISED_FEE_ONLY: "LIST_FAIRLAUNCH_TOKEN_RAISED_FEE_ONLY_ARB",
    INIT_PRIVATE_SALE_FEE: "INIT_PRIVATE_SALE_FEE_ARB"
  },
  421613: {
    INIT_TOKEN_FEE: "INIT_TOKEN_FEE_ARB_TESTNET",
    INIT_LAUNCHPAD_FEE: "INIT_LAUNCHPAD_FEE_ARB_TESTNET",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE:
      "LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ARB_TESTNET",
    LIST_LAUNCHPAD_AMOUNT_RAISED_FEE:
      "LIST_LAUNCHPAD_AMOUNT_RAISED_FEE_ARB_TESTNET",
    LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY:
      "LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY_ARB_TESTNET",
    INIT_AIRDROP_FEE_ARB: "INIT_AIRDROP_FEE_ARB",
    INIT_FAIRLAUNCH_FEE: "INIT_FAIRLAUNCH_FEE_ARB",
    LIST_FAIRLAUNCH_TOKEN_RAISED_FEE: "LIST_FAIRLAUNCH_TOKEN_RAISED_FEE_ARB",
    LIST_FAIRLAUNCH_AMOUNT_RAISED_FEE: "LIST_FAIRLAUNCH_AMOUNT_RAISED_FEE_ARB",
    LIST_FAIRLAUNCH_TOKEN_RAISED_FEE_ONLY: "LIST_FAIRLAUNCH_TOKEN_RAISED_FEE_ONLY_ARB",
    INIT_PRIVATE_SALE_FEE: "INIT_PRIVATE_SALE_FEE_ARB"
  },
};
export const APP_SETTINGS = {
  LIST_LAUNCHPAD_TOKEN_RAISED_FEE: "LIST_LAUNCHPAD_TOKEN_RAISED_FEE",
  LIST_LAUNCHPAD_AMOUNT_RAISED_FEE: "LIST_LAUNCHPAD_AMOUNT_RAISED_FEE",
  LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY: "LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY",
  JWT_EXPIRATION: "JWT_EXPIRATION",
  LIST_LAUNCHPAD_TOKEN_5_RAISED_ONLY: "_5_RAISED_ONLY",
  LIST_LAUNCHPAD_TOKEN_2_RAISED_2_TOKEN_SOLD: "_2_RAISED_2_TOKEN_SOLD",
  IN_COME_FEE_WALLET: "IN_COME_FEE_WALLET",
};

export const CURRENCY = {
  97: {
    USDT: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
    BNB: "0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F",
  },
};
export const DEX_EXCHANGES = {
  97: [
    {
      id: 1,
      routerAddress: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
      factoryAddress: "0x6725F303b657a9451d8BA641348b6761A6CC7a17",
      name: "PancakeSwap",
    },
  ],
  56: [
    {
      id: 1,
      routerAddress: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
      factoryAddress: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
      name: "PancakeSwap",
    },
  ],
  137: [
    {
      id: 1,
      routerAddress: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
      factoryAddress: "0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32",
      name: "QuickSwap",
    },
  ],
  43114: [
    {
      id: 1,
      routerAddress: "0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106",
      factoryAddress: "0xefa94DE7a4656D787667C749f7E1223D71E9FD88",
      name: "Pangolin",
    },
  ],
  66: [
    {
      id: 1,
      routerAddress: "0x865bfde337C8aFBffF144Ff4C29f9404EBb22b15",
      factoryAddress: "0x709102921812B3276A65092Fe79eDfc76c4D4AFe",
      name: "CherrySwap",
    },
  ],
  5: [
    {
      id: 1,
      routerAddress: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
      factoryAddress: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
      name: "UniSwap",
    },
  ],
  42161: [
    {
      id: 1,
      routerAddress: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
      factoryAddress: "0xc35DADB65012eC5796536bD9864eD8773aBc74C4",
      name: "SushiSwap",
    },
  ],
  421613: [
    {
      id: 1,
      routerAddress: "0x0000000000000000000000000000000000000000",
      factoryAddress: "0x0000000000000000000000000000000000000000",
      name: "SushiSwap",
    },
  ],
};

export const CURRENCIES_ADDRESS = {
  97: {
    BNB: "0x0000000000000000000000000000000000000000", // BSC TESTNET
    BUSD: "0xdCF6A27CCf00888714aF41A2A7F2C2416a643BA6",
    USDT: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
    USDC: "0x64544969ed7EBf5f083679233325356EbE738930",
  },
  80001: {
    MATIC: "0x0000000000000000000000000000000000000000", // POLYGON MUMBAI TESTNET
    USDC: "0xd715EF702eA8e0708bC469277f0aB5d2f1d5ea39",
  },
  5: {
    ETH: "0x0000000000000000000000000000000000000000", // ETH GOERLI TESTNET
    USDT: "0x59b05006dd3729C11a62Eb65562e7758cd3458E4",
  },
  56: {
    BNB: "0x0000000000000000000000000000000000000000",
    BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    USDT: "0x55d398326f99059ff775485246999027b3197955",
    USDC: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
  },
  1: {
    ETH: "0x0000000000000000000000000000000000000000",
    BUSD: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
    USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  },
  250: {
    FTM: "0x0000000000000000000000000000000000000000",
    USDT: "0x049d68029688eabf473097a2fc38ef61633a3c7a",
    USDC: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
  },
  137: {
    MATIC: "0x0000000000000000000000000000000000000000",
    BUSD: "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
    USDT: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    USDC: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
  },
  43114: {
    AVAX: "0x0000000000000000000000000000000000000000",
    BUSD: "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
    USDT: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
    USDC: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
  },
  66: {
    OKT: "0x0000000000000000000000000000000000000000",
    USDT: "0x382bb369d343125bfb2117af9c149795c6c65c50",
    USDC: "0xc946daf81b08146b1c7a8da2a851ddf2b3eaaf85",
  },
  42161: {
    ETH: "0x0000000000000000000000000000000000000000",
    USDC: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
    USDT: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
  },
  421613: {
    ETH: "0x0000000000000000000000000000000000000000", // ARB GOERLI TESTNET
    USDC: "",
    USDT: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    AGOR: "0x5AB32546ca32442De752987Af6C08cFe1EF29A14", // token testnet
  },
};

export const TOKEN_DECIMALS_MUTILSENDER = {
  97: 18,
  56: 18,
  1: 18,
  42161: 18,
  421613: 18
}

export const CURRENCIES_DECIMALS = {
  97: {
    BNB: 18,
    BUSD: 18,
    USDT: 18,
    USDC: 18,
  },
  56: {
    BNB: 18,
    BUSD: 18,
    USDT: 18,
    USDC: 18,
  },
  1: {
    ETH: 18,
    BUSD: 18,
    USDT: 6,
  },
  250: {
    FTM: 18,
    USDT: 6,
    USDC: 6,
  },
  137: {
    MATIC: 18,
    BUSD: 18,
    USDT: 6,
    USDC: 6,
  },
  43114: {
    AVAX: 18,
    BUSD: 18,
    USDT: 6,
    USDC: 6,
  },
  66: {
    OKT: 18,
    USDT: 18,
    USDC: 18,
  },
  5: {
    ETH: 18,
    USDT: 6,
  },
  42161: {
    ETH: 18,
    USDC: 6,
    USDT: 6,
  },
  421613: {
    ETH: 18,
    BUSD: 18,
    USDT: 18,
    AGOR: 18,
  },
};

export const MAIN_CURRENCIES = {
  97: "BNB",
  56: "BNB",
  1: "ETH",
  250: "FTM",
  137: "MATIC",
  43114: "AVAX",
  66: "OKT",
  5: "ETH",
  42161: "ETH",
  421613: "ETH",
};

export const ZOOM = 10000;

export const SALE_CURRENCIES = {
  97: ["BNB", "BUSD", "USDC", "USDT"],
  56: ["BNB", "BUSD", "USDC", "USDT"],
  1: ["ETH", "USDT"],
  250: ["FTM"],
  137: ["MATIC"],
  43114: ["AVAX"],
  66: ["OKT"],
  5: ["ETH"],
  42161: ["ETH", "USDT", "USDC"],
  421613: ["AGOR", "USDT", "USDC"],
};

export const MULTI_SENDER = {
  97: '0x52A224A2AeFCe218fe8fA3EB902464819901C5A4',
  42161: '0x6ae4223Ea172C9e1256e318383A0B221213c8bE0'
}

export const BLS_DIVIDENDS = {
  97: {
    BLS_ADDRESS: "0x5CD379609b4b810ad78358c9aA818A0AEd4EFEB3",
    XBLS_ADDRESS: "0x4219aa9d484EbB18f4444263d964765BfA2fc0a6",
    SWAP_BLS_TO_XBLS_ADDRESS: "0x26Cb14A96463a0f01DdFCcF259391aBF0CEA001f",
    ARB_ADDRESS: "0xfc2EBd532cDbEC22AC1a51C920A0975C8cee4A66",
    STAKE_BLS_EARN_XBLS_ADDRESS: "0xB8FF7fbcb22197561f424d436a0827361E1738e8",
    REDEEM_BLS: "0xfE08E6cB40D6159998a27F3A6BBdcb6dAd329770",
    DEALLOCATION_FEE_PERCENT: 0.5,
    BLOCKS_REWARD_PER_WEEK: 50400,
  },
  56: {
    BLS_ADDRESS: "",
    XBLS_ADDRESS: "",
    SWAP_BLS_TO_XBLS_ADDRESS: "",
    ARB_ADDRESS: "",
    STAKE_BLS_EARN_XBLS_ADDRESS: "",
    REDEEM_BLS: "",
    DEALLOCATION_FEE_PERCENT: 0.5,
    BLOCKS_REWARD_PER_WEEK: 50400,
  },
  42161: {
    BLS_ADDRESS: "0x8A66794E1aaEBC7018A7B75F61f384e30ae3b159",
    XBLS_ADDRESS: "0x93ec766f5944F7375bc3347fC2863c35e735F256",
    SWAP_BLS_TO_XBLS_ADDRESS: "0xf4402F253d308CD8A4367638C5D4b0D85A66AE13",
    ARB_ADDRESS: "",
    STAKE_BLS_EARN_XBLS_ADDRESS: "0xa77c08016C603352988eD1D9e7aF0161e5f07BDf",
    REDEEM_BLS: "0x053e4C03924e8412Ab83594A1aA6091A0dCF8128",
    DEALLOCATION_FEE_PERCENT: 0.5,
    BLOCKS_REWARD_PER_WEEK: 50400,
  },
  421613: {
    BLS_ADDRESS: "0x53d9f575A778DACB28A10D4D9A79a1AC1b7a3aa2",
    XBLS_ADDRESS: "0xB6052a4DE88604799c75fdb9D443AcD882b92d55",
    SWAP_BLS_TO_XBLS_ADDRESS: "0xa63c2F4f775370Aa2adA4e0038fff25989fbd777",
    ARB_ADDRESS: "0xfc2EBd532cDbEC22AC1a51C920A0975C8cee4A66",
    STAKE_BLS_EARN_XBLS_ADDRESS: "0xF1527309230eEA2d626a0c7f01B5CDc22D8F2FD6",
    REDEEM_BLS: "0x8C9F9e1DB097326B9630efEc2068061870B8E0f7",
    DEALLOCATION_FEE_PERCENT: 0.5,
    BLOCKS_REWARD_PER_WEEK: 50400,
  },
};

export const BLS_DIVIDENDS_V2 = {
  97: {
    BLS_ADDRESS: "",
    XBLS_ADDRESS: "",
    SWAP_BLS_TO_XBLS_ADDRESS: "",
    ARB_ADDRESS: "",
    STAKE_BLS_EARN_XBLS_ADDRESS: "",
    REDEEM_BLS: "",
    DEALLOCATION_FEE_PERCENT: 0.5,
    BLOCKS_REWARD_PER_WEEK: 50400,
  },
  56: {
    BLS_ADDRESS: "",
    XBLS_ADDRESS: "",
    SWAP_BLS_TO_XBLS_ADDRESS: "",
    ARB_ADDRESS: "",
    STAKE_BLS_EARN_XBLS_ADDRESS: "",
    REDEEM_BLS: "",
    DEALLOCATION_FEE_PERCENT: 0.5,
    BLOCKS_REWARD_PER_WEEK: 50400,
  },
  42161: {
    BLS_ADDRESS: "0x8A66794E1aaEBC7018A7B75F61f384e30ae3b159",
    XBLS_ADDRESS: "0x93ec766f5944F7375bc3347fC2863c35e735F256",
    SWAP_BLS_TO_XBLS_ADDRESS: "0xf4402F253d308CD8A4367638C5D4b0D85A66AE13",
    ARB_ADDRESS: "",
    STAKE_BLS_EARN_XBLS_ADDRESS: "0x8076a82a88C05eE98407aB655543086d2763b6Eb",
    REDEEM_BLS: "0x9a75e0Fe7ace7ce136B3B0E557A4962da7752bbC",
    DEALLOCATION_FEE_PERCENT: 0.5,
    BLOCKS_REWARD_PER_WEEK: 50400,
  },
  421613: {
    BLS_ADDRESS: "",
    XBLS_ADDRESS: "",
    SWAP_BLS_TO_XBLS_ADDRESS: "",
    ARB_ADDRESS: "",
    STAKE_BLS_EARN_XBLS_ADDRESS: "",
    REDEEM_BLS: "",
    DEALLOCATION_FEE_PERCENT: 0.5,
    BLOCKS_REWARD_PER_WEEK: 50400,
  },
};

export const BLS_PUBLICSALE = {
  97: {
    CLAIM_ADDRESS: "0xef64402d7E42bE17cBb1b0C380c3E71aA6b5018A",
    TOKEN_ADDRESS: "0x5CD379609b4b810ad78358c9aA818A0AEd4EFEB3",
    REMAINING_ADDRESS: "0x86F046f38fF2dd5F6499ceDebb62a21425ae78dC",
  },
};

export const BSC_BLOCK_TIME = 3;
export const BLOCKS_PER_YEAR = new BigNumber(
  (60 / BSC_BLOCK_TIME) * 60 * 24 * 365
); // 10512000
