import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import campaignsSliceReducer from "./slicers/campaignSlice";
import headerSliceReducer from './slicers/headerSlice';
import tokenInformsSelector from './slicers/tokenInformSlice';
import tokenStorageSelector from './slicers/tokenStorageSlice';
import launchPadSelector from './slicers/launchpadSlice';
import createLaunchpadSelector from './slicers/createLauchpadSlice';
import tokenLockSelector from './slicers/tokenLockSlice'; 
import tokenLPSelector from './slicers/tokenLPSlice';  
import liquiditySelector from './slicers/liquiditySlice';  
import antibotStorageSelector from './slicers/antibotStorageSlice';
import antibotInfoSelector from './slicers/antibotSlice';
import createPrivateSaleSelector from "./slicers/createPrivateSaleSlice";
import cexStore from './slicers/cexSlice'; 
import { persistReducer, persistStore, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import settingSelector from './slicers/settingSlice';
import priceSelector from './slicers/priceSlice';
import bannerHomeSelector from './slicers/bannerHomeSlice';
import createFairlaunchSelector from './slicers/createFairlaunchSlice';

const persistConfig = {
  key: 'root',
  storage,
  timeout: 100,
  whitelist: ['tokenStorageStore', "tokenLockStore", "tokenLPStore", "createLaunchpadStore", "antibotStorageStore", "createPrivateSaleStore", "priceStore", "createFairlaunchStore"],
  blacklist: ['tokenInformsStore', "liquidityStore", "antibotInfoStore"]
}

const rootReducer:any = combineReducers({
  campaignsStore: campaignsSliceReducer, 
  tokenInformsStore: tokenInformsSelector,
  tokenStorageStore : tokenStorageSelector,
  tokenLockStore :tokenLockSelector,
  tokenLPStore :tokenLPSelector,
  headerStore: headerSliceReducer,
  launchPadStore: launchPadSelector,
  createLaunchpadStore: createLaunchpadSelector,
  createFairlaunchStore: createFairlaunchSelector,
  cexStore: cexStore,
  settingStore: settingSelector, 
  liquidityStore: liquiditySelector, 
  antibotStorageStore: antibotStorageSelector,
  antibotInfoStore: antibotInfoSelector,
  createPrivateSaleStore: createPrivateSaleSelector,
  priceStore: priceSelector,
  bannerHomeStore: bannerHomeSelector
});

const persistedReducer = persistReducer<ReturnType<typeof store.getState>, any>(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "development" ? false : true,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
       serializableCheck: false
    })
});

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export const useAppDispatch: () => AppDispatch = useDispatch // Export a hook that can be reused to resolve types
export const persistor = persistStore(store);

export default store