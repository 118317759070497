import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useActiveWeb3React } from "../../hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Store } from 'react-notifications-component';
import addNotify from '../commons/Notify/addNotify';
import { useTokenContract } from "../../hooks/useContract";
import { CustomButton } from "../component";
import { tokenInformation } from "../../contracts/token"; 
import { getAppSettings, settingSelector } from "../../redux/slicers/settingSlice";
import { APP_SETTINGS, CHAINID_CONVERT } from '../../constants';
import { createTokenInform, getTokenInformByAddress } from "../../redux/slicers/tokenInformSlice";
import CreateToken from '../../components/createtoken';
import { antibotInfoSelector, checkAllowAntibot, checkConfigAntiBotByAdress } from "../../redux/slicers/antibotSlice";
import { antibotStorageSelector, setAntibotStorage } from "../../redux/slicers/antibotStorageSlice";
 import { ethers } from 'ethers';
interface ITokenCreateForm {
    setActiveKey: any,
}

type SettingFee = {
    chainId: string | number,
    feeType: string,
    payToken: any,
    settingKey: any
    settingValue: any
}

const TokenAntiBot: React.FC<ITokenCreateForm> = (props: ITokenCreateForm) => {
    const [availableToken, setAvailableToken] = useState<any>('');
    const [createToken, setCreateToken] = useState<boolean>(false);
    const [actionStatus, setActionStatus] = useState("");
    const [initTokenFee, setInitTokenFee] = useState<SettingFee>(); 
    const [checkAnti, setCheckAnti] = useState<boolean>(false);
    const { account, chainId } = useActiveWeb3React();
    const tokenContract = useTokenContract(availableToken); 
    const { antibotStorage} = useAppSelector(antibotStorageSelector); 
    const { settings } = useAppSelector(settingSelector);
    const [searchParams, setSearchParams] = useSearchParams();
    
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    useEffect(()=> {
        const payload:any = {
            "page": 0,
            "size": 100
        }
        dispatch(getAppSettings(payload));
    }, []);   

    useEffect(() => {
      if(searchParams.get("token")){ 
        setActionStatus("");
        props.setActiveKey("2");
        localStorage.setItem("stepAntibot", "2");  
      }
      else{
        localStorage.setItem("stepAntibot", "1");  
      }
    }, [searchParams.get("token")])
    
   
    const handleNext = () => {  
        if(availableToken && ethers.utils.isAddress(availableToken)){
            (async () => {
                await dispatch(getTokenInformByAddress(availableToken, (res:any) => {
                    if(res?.tokenInform?.antiBotEnable) { 
                        if(res.tokenInform?.antiBotId){
                            navigate(`/anti-bot/${res.tokenInform?.antiBotId}`);
                        }
                        else{
                        (async () => {
                            setActionStatus("CHECK_LOADING");
                            await tokenInformation(tokenContract)
                            .then((tokenRes: any) => {  
                                if (res?.tokenInform && res?.tokenInform?.id) {
                                    setSearchParams({token: tokenRes?.tokenAddress});  
                                    setActionStatus("");
                                    props.setActiveKey("2");
                                    localStorage.setItem("stepAntibot", "2");  
                                }   
                                }) 
                        })(); 
                        } 
                    }
                    else{
                        Store.addNotification(addNotify('This token is not implement BlueSale contract', 'danger'));
                        return;
                    }
                }));
            })(); 
        } 
        if (!account) {
            Store.addNotification(addNotify("Please connect your wallet", 'danger'));
            return
        } 
        
    }

    return (
        <>
            <div className='token'>
                <div className='step'>Step 1</div>
                <div className='step-text'>Do you create new token or do you have an available token</div> 
                <div className="step-text-antibot">
                    <span>Choose a token to integrate with Anti-Bot.</span>
                    <span>Check out the guide how to integrate Anti-Bot for custom contract here: </span>
                    <a href="https://docs.bluesale.finance/project-launching/anti-bot">https://docs.bluesale.finance/project-launching/anti-bot</a>
                </div>
                <div className='token-select'>
                    <div className='form-availabletoken'>
                        <img src="../images/check-circle.png" />
                        <span className='title'>I have available token</span>
                        <span className='subtitle'>We don’t need to go through the onboarding process</span>
                        <input className='input' value={availableToken} onChange={(e) => setAvailableToken(e.target.value)} placeholder='Enter token address' />
                    </div>
                    <div className='form-createtoken'>
                        <img src="../images/uncheck-circle.png" />
                        <span className='title'>Please enter your token address</span>
                        <span className='subtitle'>Not sure which token is appropriate for your situation?</span>
                        <span className='subtitle-fee'>Create token fee: {initTokenFee?.settingValue} {initTokenFee?.payToken}</span>
                        <button onClick={() => setCreateToken(true)} className='btn-create'>Create Token</button>
                    </div>

                    {createToken &&
                        <>
                            <div className="form-createToken-bg" />
                            <CreateToken type="FormAntibot" setCreateToken={setCreateToken} setActiveKey={props.setActiveKey} />
                        </>}

                </div>
                <div className='btn-token cancel-next'>
                    <button onClick={() => navigate("/")} className='cancel'>Cancel</button>
                    <CustomButton
                        class='next'
                        isLoading={actionStatus === 'CHECK_LOADING' ? true : false}
                        disabled={availableToken === undefined ? true : false}
                        text='Next'
                        onClick={() => handleNext()}
                    />
                </div>
            </div>
        </>
    )
}

export default TokenAntiBot;