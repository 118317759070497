import React, { useEffect } from "react";
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Button } from "../../components/component";
import useWindowSize from "../../hooks/useWindowSize";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";
import { useNavigate, useParams } from "react-router-dom";
interface Size {
    width: number;
    height: number;
}

interface Props {
    tokenAddress:any;
    records: any;
    symbol: string;
    type: any;
    teamLockId: any;
}

interface DataType {
    id: number;
    key: string;
    title: string;
    amount: string;
    vestingCycle: string;
    tgePercent: string;
    tgeDate: string;
    cycleReleasePercent: string;
}

const columnsSP: ColumnsType<DataType> = [
    {
        title: 'Name',
        dataIndex: 'title',
        key: 'title',
        // ellipsis: true,
        //width: 400
    }
];

const LockRecordTable: React.FC<Props> = ({tokenAddress, records, symbol, type, teamLockId}) => {
    const size: Size = useWindowSize();
    const { handle } = useParams<{ handle: any }>();
    const columns: ColumnsType<DataType> = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
            render: (_, record) => <Link to={`/record/${record?.id}?token=${tokenAddress}&type=${type}&lockId=${teamLockId}`}>{record?.title ? record?.title : '--'}</Link>,
        }, {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (_, record) => <>{formatNumber(record?.amount)}</>,
        }, {
            title: 'Cycle',
            dataIndex: 'vestingCycle',
            key: 'vestingCycle',
            render: (_, record) => <>{record?.cycleReleasePercent}% each {record?.vestingCycle} minutes</>,
        }, {
            title: 'TGE',
            dataIndex: 'tgePercent',
            key: 'tgePercent',
            ellipsis: true,
        }, {
            title: 'Unlocked Time (UTC)',
            dataIndex: 'tgeDate',
            key: 'tgeDate',
            render: (text) => text ? formatDate(text) : '--',
        }
    ];
    
    return(
        <div className="c-lockRecords">
            <h3>Lock Records ({records?.length > 0 ? records?.length : '0'})</h3>
            {size.width >= 767 ? <Table columns={columns} dataSource={records} pagination={false} /> : <Table
                columns={columnsSP}
                dataSource={records}
                pagination={false}
                expandedRowRender={record => <div className="c-lockRecords__table">
                    <p>Title:<span><Link to={`/record/${record?.id}?type=${type}`}>{record?.title}</Link></span></p>
                    <p>Amount:<span>{formatNumber(record?.amount)} {symbol}</span></p>
                    <p>Cycle:<span>{record?.cycleReleasePercent}% each {record?.vestingCycle} minutes</span></p>
                    <p>TGE:<span>{record?.tgePercent}%</span></p>
                    <p>Unlocked Time (UTC):<span>{formatDate(record?.tgeDate)}</span></p>
                </div>}
            />}
            {type === "lp" ? <div className="c-lockRecords__btn">
                <Link to={`/liquidity/create-liquidity/${handle}`}>
                    <Button text='Create Token LP Lock' iconLeft='Plus' />
                </Link>
            </div> : <div className="c-lockRecords__btn">
                <Link to={`/token/create-lock/${handle}`}>
                    <Button text='Create Token Lock' iconLeft='Plus' />
                </Link>
            </div>} 
        </div>
    )
}

export default LockRecordTable;