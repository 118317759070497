import { useState, useRef, useEffect } from "react";
import { Form, Input, Select, Button as ButtonAntd } from 'antd';
import { InputChange } from '../../components/component';
import { Button } from '../component';
import axios from 'axios';
import { additionInfo, createPrivateSaleSelector, privateSaleInfo } from "../../redux/slicers/createPrivateSaleSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Tokens } from "../../constants";
import { useNavigate } from "react-router-dom";
interface IFormCreate {
    setActiveKey: any,
}

const AdditionInfo: React.FC<IFormCreate> = (props: IFormCreate) => {
    const [form] = Form.useForm();
    const [imgBanner, setImgBanner] = useState("");
    const [imgLogo, setLogo] = useState("");
    const inputImage = useRef<HTMLInputElement>(null);
    const inputLogo = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    let navigate = useNavigate();

    useEffect(() => {
        form.setFieldsValue(additionInfo);
    }, [additionInfo, form]);

    const onChangeImage = (e, value) => {
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.AUTHUSER)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            if (value == "banner") {
                setImgBanner(res.data);
            }
            else {
                setLogo(res.data)
            }
        }).catch(err => {
            console.log(err);
        });
    }


    const onFinish = (values: any) => { 
        const payload: any = {
            ...values,
            banner: imgBanner,
            logo: imgLogo
        }
        dispatch(additionInfo(payload));
        props.setActiveKey("3");
        localStorage.setItem("stepPrivateSale", "3")
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleCancel = () => {
        dispatch(additionInfo({}));
        dispatch(privateSaleInfo({}));
        navigate("/private-sale/list");
        localStorage.setItem("stepPrivateSale", "1");
    }

    const handleBack = () => {
        props.setActiveKey("1");
        localStorage.setItem("stepPrivateSale", "1")
    }
    return (
        <div className='verifyToken'>
            <div className='step'>Step 2</div>
            <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
            >
                <div className="verifyToken-form">
                    <div className="verifyToken-left">
                        <div className="c-input">
                            <p className="c-input__title">Private Sale’s Info</p>
                            <Form.Item rules={[{ required: true, message: 'Please input your info!' }]} name="info">
                                <textarea></textarea>
                            </Form.Item>
                        </div>
                        <div className="c-social">
                            <p className="c-ttl">Channel Media</p>
                            <div className="c-social__head">
                                <p>Channel name</p>
                                <p>URL <span>(Required)</span></p>
                            </div>
                            <div className="c-social__row">
                                <span><img src="../images/icon-editWebsite.svg" alt="" />Website</span>
                                <Form.Item name="website">
                                    <InputChange placeholder="Enter URL" />
                                </Form.Item>
                            </div>
                            <div className="c-social__row">
                                <span><img src="../images/icon-editTwitter.svg" alt="" />Twitter</span>
                                <Form.Item name="twitter">
                                    <InputChange placeholder="Enter URL" />
                                </Form.Item>
                            </div>
                            <div className="c-social__row">
                                <span><img src="../images/icon-editTelegram.svg" alt="" />Telegram</span>
                                <Form.Item name="telegram">
                                    <InputChange placeholder="Enter URL" />
                                </Form.Item>
                            </div>
                            <div className="c-social__row">
                                <span><img src="../images/icon-editMedium.svg" alt="" />Medium</span>
                                <Form.Item name="medium">
                                    <InputChange placeholder="Enter URL" />
                                </Form.Item>
                            </div>
                            <div className="c-social__row">
                                <span><img src="../images/icon-editYoutube.svg" alt="" />Youtube</span>
                                <Form.Item name="youtube">
                                    <InputChange placeholder="Enter URL" />
                                </Form.Item>
                            </div>
                            <div className="c-social__row">
                                <span><img src="../images/icon-editDiscord.svg" alt="" />Discord</span>
                                <Form.Item name="discord">
                                    <InputChange placeholder="Enter URL" />
                                </Form.Item>
                            </div>
                            <div className="c-social__row">
                                <span><img src="../images/icon-editFacebook.svg" alt="" />Facebook</span>
                                <Form.Item name="facebook">
                                    <InputChange placeholder="Enter URL" />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className="verifyToken-right">
                        <div className="verifyToken-left-item">
                            <div className="c-upload">
                                <span className="c-upload__title">Upload Banner</span>
                                <div className={`c-upload__box${imgBanner === '' ? ' is-add' : ''}`}>
                                    {imgBanner === '' ? <div className='btn-click' onClick={() => inputImage?.current?.click()}>
                                        <p className="title">Click or drag files to upload here</p>
                                        <p className="subtitle">Only png, jpg can be uploaded, and the size does not exceed 3MB. Recommended size is 1920x985px</p>
                                    </div> : <div className="c-upBanner">
                                        <img src={imgBanner} alt="" />
                                        <div className="c-upBanner__edit">
                                            <span onClick={() => inputImage.current?.click()}><img src="../images/icon-editBanner.png" alt="" /></span>
                                        </div>
                                    </div>}
                                    <input ref={inputImage} onChange={(e) => onChangeImage(e, "banner")} type="file" />
                                </div>
                            </div>
                        </div>
                        <div className="c-upload">
                            <span className="c-upload__title">Upload Logo</span>
                            {imgLogo === '' ? <div className="btn-upload" onClick={() => inputLogo?.current?.click()}>
                                <img src="../images/upload.png" />
                                <p className="mb-10">Upload</p>
                            </div> : <div className="c-upLogo">
                                <div className="c-upLogo__img">
                                    <img src={imgLogo} alt="" />
                                </div>
                                <div className="c-upLogo__edit" onClick={() => inputLogo.current?.click()}>
                                    <span><img src="../images/icon-editBanner.png" alt="" /></span>
                                </div>
                            </div>}
                            <input ref={inputLogo} onChange={(e) => onChangeImage(e, "logo")} type="file" />
                        </div>
                    </div>
                </div>
                <div className="footer-tab">
                    <div className="fixed-bottom">
                        <div className='btn-verify'>
                            <div className="isWidth">
                                <Button text='Cancel' type='border' onClick={() => handleCancel()} />
                            </div>
                            <div className="m-25 isWidth">
                                <ButtonAntd className="c-btn" htmlType="button" onClick={() => handleBack()}>
                                    Previous
                                </ButtonAntd>
                            </div>
                            <div className="isWidth">
                                <Button text='Next' type="blue" />
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default AdditionInfo;