import React, { useEffect, useRef, useState } from "react";
import { Select, Popover, Switch, Form,Input, Radio } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import type { RadioChangeEvent } from 'antd';
import { Button, InputChange, Loading } from "../../components/component";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getAntiBotById, editAntiBotById, antibotInfoSelector } from "../../redux/slicers/antibotSlice";
import { antibotStorageSelector } from "../../redux/slicers/antibotStorageSlice";
import { CHAINID_FULLNAME, DEX_EXCHANGES, MAPPING_CHAINID_DECIMAL, SALE_CURRENCIES } from "../../constants";
import { useActiveWeb3React } from "../../hooks";
import { configAntiBot, getStatusContractTransfer, setContractTransfer } from "../../contracts/antibot";
import { useAntiBotContract, useTokenAntibotContract, useTokenContract } from "../../hooks/useContract";
import { SMART_CONTRACT_ADDRESS } from '../../constants';
import { getCurrencyAddress } from "../../components/createlaunchpad/ultils";
import { Store } from 'react-notifications-component';
import addNotify from '../../components/commons/Notify/addNotify';
import { convertToWei } from "../../contracts/utils";
import { tokenDecimals } from "../../contracts/token";
import { getTokenInform, tokenInformsSelector } from '../../redux/slicers/tokenInformSlice';


const AntiBotEdit: React.FC = () => {
    const {chainId,account} = useActiveWeb3React();
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [checkSwitch, setCheckSwitch] = useState<boolean>(false);
    const [checkSwitchUI, setCheckSwitchUI] = useState<boolean>();
    const { Option } = Select;
    const [searchParams] = useSearchParams();
    const [idHandle] = useState<any>(searchParams.get("id")); 
    const [isChecked, setIsChecked] = useState<boolean>();
    const [dexExchanges, setDexExchanges] = useState<any>([]);
    const [actionStatus, setActionStatus] = useState(""); 
    const [valueCurrency, setValueCurrency] = useState(chainId === 421613 ? "AGOR" : chainId === 42161 ? "ETH" : "" );
    const [saleCurrencies, setSaleCurrencies] = useState<any>([]);
    const [changeStatusContractTransfer, setChangeStatusContractTransfer] = useState<any>();
    const dispatch = useAppDispatch(); 
    const [form] = Form.useForm();
    let navigate = useNavigate(); 
    const { antiBotById } = useAppSelector(antibotInfoSelector); 
    const { tokenInform } = useAppSelector(tokenInformsSelector);
    const urlTokenAddress = searchParams.get("token"); 
    const tokenAntiBotContract = useTokenAntibotContract(urlTokenAddress);  
    // const {antibotStorage } =useAppSelector(antibotStorageSelector); 
    const antibotAddress = chainId && SMART_CONTRACT_ADDRESS[chainId]?.ANTI_BOT_ADDRESS;
   
    const antibotContract = useAntiBotContract(antibotAddress);
    // console.log("tokenInform", tokenInform)
    let netName = '';  
    switch (chainId) {
        case MAPPING_CHAINID_DECIMAL.bsc:
            netName = CHAINID_FULLNAME.bsc; 
            break;
            
        case MAPPING_CHAINID_DECIMAL.poly:
            netName = CHAINID_FULLNAME.poly; 
            break;

        case MAPPING_CHAINID_DECIMAL.eth:
            netName = CHAINID_FULLNAME.eth; 
            break;

        case MAPPING_CHAINID_DECIMAL.bsctestnet:
            netName = CHAINID_FULLNAME.bsctestnet; 
            break;

        default:
            netName = CHAINID_FULLNAME.bsc; 
    }

     useEffect(() => {  
        if(urlTokenAddress && account){ 
        (async () => {
            await getStatusContractTransfer(tokenAntiBotContract).then((res)=>{ 
                console.log("res", res);
                console.log("setCheckSwitchUI", !res)
                setCheckSwitch(res);
                setCheckSwitchUI(!res)
            }).catch((error) =>{
                console.log(error);
            })
        })() 
        } 
    }, [urlTokenAddress, account, changeStatusContractTransfer])

    useEffect(() => {
        if(chainId) {
            setDexExchanges(DEX_EXCHANGES[chainId]);
            setSaleCurrencies(SALE_CURRENCIES[chainId]);
        }
    },[chainId]); 

    useEffect(() => {
        if(idHandle) {       
            dispatch(getAntiBotById(idHandle));
        }
    }, [dispatch]);
   
    useEffect(() => {        
        if(antiBotById?.tokenInformId) {
            dispatch(getTokenInform(antiBotById?.tokenInformId));
        }
    }, [dispatch, antiBotById?.tokenInformId]);
    console.log("antiBotById", antiBotById);
    useEffect(() => {
        if(antiBotById) {
            form.setFieldsValue(antiBotById);
            setIsChecked(antiBotById?.disableContractTransfer);
        }
    }, [antiBotById]);  
    
    const onChangeSwitch = async(checked: boolean) => {
        setCheckSwitch(!checked); 
        setCheckSwitchUI(checked)
        console.log("tokenAntiBotContract",!checked)
        setActionStatus("LOADING_SWITCH");
        await setContractTransfer(tokenAntiBotContract, !checked)
        .then((res:any)=> {
            let resWait = res.wait();
            resWait.then((resTransaction:any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    if(!checked) {
                        Store.addNotification(addNotify('Enable contract transfer successfully', 'success'));
                        setChangeStatusContractTransfer("Enable")
                    }
                    else {
                        Store.addNotification(addNotify('Disable contract transfer successfully', 'success'));
                        setChangeStatusContractTransfer("Disable")
                    }
                    setActionStatus("");
                    // window.location.reload();
                }
                else {
                    setActionStatus("");
                    Store.addNotification(addNotify('Transaction failed', 'danger'));
                    return;
                }
            })
            .catch ((error:any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            });
        })
        .catch((error:any)=>{
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, 'danger'));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, 'danger'));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, 'danger'));
                }
            }
        });
    } 

     const onChangeCurrency = (e: RadioChangeEvent) =>{
        setValueCurrency(e.target.value);

        if (chainId === 42161) {
            form.setFieldsValue({
                "listingOptions": e.target.value === "ETH" ? "" : "MANUAL"
            });
        }
    } 

     const defaultCurrencyChecker = () => {
        let currencyDefault = '';
        if(chainId === 421613) {
            currencyDefault = 'AGOR';
        } else if(chainId === 42161) {
            currencyDefault = 'ETH';
        }
        return currencyDefault;
    }
   

    const onFinish = async(value) => {
        const currencyDecimals = getCurrencyAddress(chainId, value?.pairToken);
        const _routerSelected = dexExchanges && dexExchanges.length > 0 ? dexExchanges.filter(item => item.id === value?.routerExchange) : null;
        const routerOjbect = _routerSelected && _routerSelected.length > 0 && _routerSelected[0];
        
        setIsLoader(true);
        value.id = Number(idHandle);
        value.routerExchange = value.routerExchange ? Number(value.routerExchange) : null;
        value.pairToken = value.pairToken ? value.pairToken : null;
        value.amountLimitPerTrade = value.amountLimitPerTrade ? Number(value.amountLimitPerTrade) : null;
        value.amountToBeAddedPerBlock = value.amountToBeAddedPerBlock ? Number(value.amountToBeAddedPerBlock) : null;
        value.timeLimitPerTrade = value.timeLimitPerTrade ? Number(value.timeLimitPerTrade) : null;
        value.blockNumberToDisableAntiBot = value.blockNumberToDisableAntiBot ? Number(value.blockNumberToDisableAntiBot) : null;
        value.disableContractTransfer = isChecked;
        value.tokenInformId = antiBotById?.tokenInformId; 
        
        if(urlTokenAddress){
        await configAntiBot(
            antibotContract,
            urlTokenAddress,
            currencyDecimals,
            routerOjbect?.routerAddress,
            routerOjbect?.factoryAddress,  //address factoryExchange,
            convertToWei(value?.amountLimitPerTrade, tokenInform?.decimals),
            convertToWei(value?.amountToBeAddedPerBlock, tokenInform?.decimals),
            value?.timeLimitPerTrade,
            convertToWei(value?.blockNumberToDisableAntiBot, tokenInform?.decimals)).then((res: any) => {
                setIsLoader(true)
                let resWait: any = res.wait();
                resWait.then((resTransaction: any) => {
                    if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                        //call API
                          dispatch(editAntiBotById(idHandle, value, async (res:any) => {
                                if(res.result === 'SUCCESS') {
                                    navigate(`/anti-bot/${idHandle}`)
                                } 
                            }));
                        }
                    })
                    .catch((error: any) => {
                        setIsLoader(false);
                        setActionStatus("")
                        if (error.data) {
                            Store.addNotification(addNotify('Transaction error', 'danger'));
                            return;
                        }
                        if (error) {
                            Store.addNotification(addNotify(error?.reason, 'danger'));
                            return;
                        }
                    });
            })
            .catch((error) => {
                setIsLoader(false);
                if (error.data) {
                    Store.addNotification(addNotify('Transaction error', 'danger'));
                    return;
                }
                if (error) {
                    Store.addNotification(addNotify(error?.reason, 'danger'));
                    return;
                }
            });  
        }
        
      
    }

    return(
        <>
        {isLoader && <Loading />}
        <div className="p-antiBotEdit">
            <h3>Edit Anti-Bot</h3>
            <Form name="basic" autoComplete="off" form={form} onFinish={onFinish}>
                <div className="p-antiBotEdit__box">
                    <div className="p-antiBotEdit__row">
                        <p className="c-ttl">Select Router Exchange</p>
                        <Form.Item name="routerExchange"> 
                            <Select className="c-selectEdit">
                                {/* <Option value={1}>PancakeSwap</Option> */}
                                {dexExchanges && dexExchanges.map((e:any,i:any)=>( 
                                    <Option key={i} value={e.id}>{e.name}</Option>  
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="p-antiBotEdit__row">
                        <p className="c-ttl">Select Pair Token</p>
                        <Form.Item name="pairToken"> 
                            <Radio.Group onChange={onChangeCurrency} value={valueCurrency} defaultValue={defaultCurrencyChecker}>
                                {saleCurrencies?.map((currency) => <Radio value={currency}>{currency}</Radio>)}
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="p-antiBotEdit__row">
                        <div className="c-ttl">
                            Amount Limit Per Trade
                            <Popover placement="top" trigger="click" content={<p>This is the initial limited amount of tradable tokens at listing time. Traders will not be able to trade any amount higher than this number.</p>}>
                                <span><img src="../images/question-circle.png" alt="" /></span>
                            </Popover>
                        </div>
                        <Form.Item name="amountLimitPerTrade"  rules={[{ required: true, message: 'Amount Limit Per Trade is required!' },
                            {validator(_,value) {   
                            if(Number(value) <= 0){ 
                                return Promise.reject(`Listing rate must larger than 0`)
                            }   
                            return Promise.resolve()}
                            }]}> 
                            <Input type='number' className="inputForm"/>
                        </Form.Item>
                    </div>
                    <div className="p-antiBotEdit__row">
                        <div className="c-ttl">
                            Amount To Be Added Per Block
                            <Popover placement="top" trigger="click" content={<p>This number will continuously be added to the “Amount Limit Per Trade” from block 1 (block after listing) until the “Block Number to Disable Anti-Bot” (see below). For example, if you set the “Amount Limit Per Trade” to 1 tokens, and the “Amount to be Added Per Block” to 1 tokens, after 100 blocks the maximum tradable token amount will be 100 tokens.</p>}>
                                <span><img src="../images/question-circle.png" alt="" /></span>
                            </Popover>
                        </div>
                        <Form.Item name="amountToBeAddedPerBlock" rules={[{ required: true, message: 'Amount To Be Added Per Block is required!' },
                            {validator(_,value) {   
                            if(Number(value) <= 0){ 
                                return Promise.reject(`Listing rate must larger than 0`)
                            }   
                            return Promise.resolve()}
                            }]}> 
                            <Input type='number' className="inputForm"/>
                        </Form.Item>
                    </div>
                    <div className="p-antiBotEdit__row">
                        <div className="c-ttl">
                            Time Limit Per Trade (seconds)
                            <Popover placement="top" trigger="click" content={<p>The time between transactions that traders will have to wait to prevent multiple swaps. When “Block Number to Disable Anti-Bot” is reached, “Time Limit Per Trade” will automatically be set to zero.</p>}>
                                <span><img src="../images/question-circle.png" alt="" /></span>
                            </Popover>
                        </div>
                        <Form.Item name="timeLimitPerTrade" rules={[{ required: true, message: 'Time Limit Per Trade is required!' },
                            {validator(_,value) {   
                            if(Number(value) <= 0){ 
                                return Promise.reject(`Listing rate must larger than 0`)
                            }   
                            return Promise.resolve()}
                            }]}> 
                            <Input type='number' className="inputForm"/>
                        </Form.Item>
                    </div>
                    <div className="p-antiBotEdit__row">
                        <div className="c-ttl">
                            Block Number To Disable Anti-Bot
                            <Popover placement="top" trigger="click" content={<p>Anti-bot will work from the listing time until this block. After this block, it will automatically disable and traders will be able to trade normally again. It is also possible to manually disable Anti-Bot at any given time by clicking on the “Disable Anti-Bot” button. Keep in mind this number must be greater than or equal to 150.</p>}>
                                <span><img src="../images/question-circle.png" alt="" /></span>
                            </Popover>
                        </div>
                        <Form.Item name="blockNumberToDisableAntiBot"rules={[{ required: true, message: 'Time Limit Per Trade is required!' },
                            {validator(_,value) {   
                            if(Number(value) <= 0){ 
                                return Promise.reject(`Block Number must larger than 0`)
                            }   
                            return Promise.resolve()}
                            }]} > 
                            <Input type='number' className="inputForm"/>
                        </Form.Item>
                    </div>
                    <div className="p-antiBotEdit__row">
                          <Form.Item name="disableContractTransfer">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={onChangeSwitch}
                                    checked={checkSwitchUI}
                                    loading={actionStatus === "LOADING_SWITCH"? true :false}
                                />
                                <span className="switchText"> {checkSwitchUI ? "Disable contract transfer" :"Enable contract transfer"}</span>
                            </Form.Item>                   
                    </div>
                </div>
                <div className="p-antiBotEdit__btn">
                    <div className="c-btn c-btn--greyBorder" onClick={() => navigate(`/anti-bot/${idHandle}`)}><span><span>Cancel</span></span></div>
                    <Button text='Edit Anti-Bot' type='blue' />
                </div>
            </Form>
        </div>
        </>
    )
}

export default AntiBotEdit;