import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Button as ButtonAntd } from "antd";
import { Button, Loading, SearchChange } from "../component";
import useWindowSize from "../../hooks/useWindowSize";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getTokenInformByAddress } from "../../redux/slicers/tokenInformSlice";
import { setTokenStorage } from "../../redux/slicers/tokenStorageSlice";
import { privateSaleCreate } from "../../contracts/launchpad";
import { usePrivateSaleDeployContract } from "../../hooks/useContract";
import {
  APP_SETTINGS,
  CURRENCIES_ADDRESS,
  DECIMALS_STRING,
  DEX_EXCHANGES,
  FEE_SETTINGS,
  LAUNCHPAD_CONFIGS,
  RPC_URL_CONVERT,
  SMART_CONTRACT_ADDRESS,
  ZOOM,
} from "../../constants";
import { useActiveWeb3React } from "../../hooks";
import {
  additionInfo,
  createPrivateSale,
  createPrivateSaleSelector,
  privateSaleInfo
} from "../../redux/slicers/createPrivateSaleSlice";
import { convertToWei } from "../../contracts/utils";
import { Store } from "react-notifications-component";
import addNotify from "../commons/Notify/addNotify";
import { formatDate } from "../../utils/formatDate";
import Countdown, { zeroPad } from "react-countdown";
import { getCurrencyDecimals, getMainCurrencyDecimals } from "./ultils";
import { settingSelector } from "../../redux/slicers/settingSlice";
import { formatNumber } from "../../utils/formatNumber";
import { useSearchParams } from "react-router-dom";
import web3 from "web3";

interface Size {
  width: number;
  height: number;
}

interface ITokenCreateForm {
  setActiveKey: any;
}

declare const window: Window & typeof globalThis & { ethereum: any };

const Finish: React.FC<ITokenCreateForm> = (props: ITokenCreateForm) => {
  const { chainId, account } = useActiveWeb3React();
  let provider = "";
  if (chainId !== 1 && chainId) {
    provider = RPC_URL_CONVERT[chainId];
  }
  const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

  const [copied, setCopied] = useState<boolean>(false);
  const [addWhitelist, setAddWhitelist] = useState<boolean>(false);
  const [listWhitelist, setListWhitelist] = useState<boolean>(false);
  const [disableWhitelist, setDisableWhitelist] = useState<boolean>(false);
  const [removeWhitelist, setRemoveWhitelist] = useState<boolean>(false);
  const [actionStatus, setActionStatus] = useState("");

  const [chainDecimals, setChainDecimals] = useState<number>(0);
  //INIT_LAUNCHPAD_FEE
  const [createPoolFee, setCreatePoolFee] = useState<any>(0);
  const [loadingPage, setLoadingPage] = useState<boolean>(false);

  // LAUNCHPAD_TOKEN_RAISED_FEE
  const { TabPane } = Tabs;
  const size: Size = useWindowSize();
  const dispatch = useAppDispatch();
  const { additionInfoStorage, privateSaleInfoStorage } = useAppSelector(
    createPrivateSaleSelector
  );

  console.log('privateSaleInfoStorage', privateSaleInfoStorage)
  const { settings } = useAppSelector(settingSelector);
  const privateSaleDeployContract = usePrivateSaleDeployContract(
    chainId && SMART_CONTRACT_ADDRESS[chainId]?.DEPLOY_PRIVATESALE_ADDRESS
  );

  let navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  });

  useEffect(() => {
    const feeSettings = chainId && FEE_SETTINGS[chainId];
    //  console.log("feeSettings", feeSettings);
    if (settings && settings.length > 0) {
      let _initLaunchpadFee = settings.filter(
        (itemSetting) =>
          feeSettings?.INIT_PRIVATE_SALE_FEE === itemSetting?.settingKey
      )?.[0];
      setCreatePoolFee(_initLaunchpadFee?.settingValue);
    }
  }, [settings]);

  useEffect(() => {
    if (chainId) {
      setChainDecimals(getMainCurrencyDecimals(chainId));
    }
  }, [chainId]);

  const infoDetail = (
    <>
      <h3>
        <p>
          {" "}
          {privateSaleInfoStorage?.title}
          <span>Presale</span>
        </p>
        <p>
          <span>Audit</span>
          <span>KYC</span>
          <span>SAFU</span>
        </p>
      </h3>
      <ul>
        {additionInfoStorage?.website ? (
          <li>
            <a href={additionInfoStorage?.website}>
              <img src="../images/detail-icon-website.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {additionInfoStorage?.telegram ? (
          <li>
            <a href={additionInfoStorage?.telegram}>
              <img src="../images/detail-icon-tele.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {additionInfoStorage.twitter ? (
          <li>
            <a href={additionInfoStorage.twitter}>
              <img src="../images/detail-icon-tw.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {additionInfoStorage.medium ? (
          <li>
            <a href={additionInfoStorage.medium}>
              <img src="../images/detail-icon-medium.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {additionInfoStorage.facebook ? (
          <li>
            <a href={additionInfoStorage.facebook}>
              <img src="../images/detail-icon-facebook.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {additionInfoStorage.discord ? (
          <li>
            <a href={additionInfoStorage.discord}>
              <img src="../images/detail-icon-discord.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {additionInfoStorage.youtube ? (
          <li>
            <a href={additionInfoStorage.youtube}>
              <img src="../images/detail-icon-youtube.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
      </ul>
    </>
  );

  const handleBack = () => {
    props.setActiveKey("2");
    localStorage.setItem("stepPrivateSale", "2");
  };

  const handleCancel = () => {
    dispatch(setTokenStorage({}));
    dispatch(privateSaleInfo({}));
    dispatch(additionInfo({}));
    navigate("/launchpad/list");
    localStorage.setItem("stepPrivateSale", "1");
  };

  const rendererTimer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Done !</span>;
    } else {
      // Render a countdown
      return (
        <div className="c-countDown">
          <span>{zeroPad(days)}d</span>:<span>{zeroPad(hours)}h</span>:
          <span>{zeroPad(minutes)}m</span>:<span>{zeroPad(seconds)}s</span>
        </div>
      );
    }
  };
  console.log("additionInfoStorage", additionInfoStorage);
  const dispatchCreateLauchpad = async (launchpadAddress: string) => {
    const payload: any = {
      ownerAddress: account?.toLocaleLowerCase(),
      presaleAddress: launchpadAddress.toLowerCase(),
      title: privateSaleInfoStorage?.title,
      softCap: privateSaleInfoStorage?.softcap,
      hardCap: privateSaleInfoStorage?.hardcap,
      minBuy: privateSaleInfoStorage?.minBuy,
      maxBuy: privateSaleInfoStorage?.maxBuy,
      whiteListOnly: privateSaleInfoStorage?.whiteListOnly,
      startTime: privateSaleInfoStorage?.startTime,
      endTime: privateSaleInfoStorage?.endTime,
      currency: privateSaleInfoStorage?.currency,
      firstReleasePercent: privateSaleInfoStorage?.firstFundRelease,
      fundVestingPeriodEachCycle:
        privateSaleInfoStorage?.fundVestingPeriodEachCycle,
      fundReleaseEachCycle: privateSaleInfoStorage?.fundRelease,
      saleInfo: additionInfoStorage?.info,
      bannerUrl: additionInfoStorage?.banner,
      logoUrl: additionInfoStorage?.logo,
      networkChain: privateSaleInfoStorage?.networkChain,
      chainId: chainId,
      socialNetworks: {
        facebook: additionInfoStorage?.facebook,
        twitter: additionInfoStorage?.twitter,
        medium: additionInfoStorage?.medium,
        discord: additionInfoStorage?.discord,
        telegram: additionInfoStorage?.telegram,
        website: additionInfoStorage?.website,
        youtube: additionInfoStorage?.youtube,
      },
    };
    dispatch(
      createPrivateSale(payload, async (res: any) => {
        if (res.result === "SUCCESS") {
          if (res?.data) {
            // delete all local storage (react persist)
            dispatch(setTokenStorage({}));
            dispatch(privateSaleInfo({}));
            dispatch(additionInfo({}));
            localStorage.setItem("stepPrivateSale", "1");

            // navigate to launchpad detail
            navigate("/private-sale/list");
            setActionStatus("SUCCESS");
            Store.addNotification(
              addNotify("Private sale created successfully.", "success")
            );
          }
        } else {
          setActionStatus("");
          if (res?.error?.message) {
            Store.addNotification(addNotify(res?.error?.message, "danger"));
          }
        }
      })
    );
  };
  
  const handleLaunchpadCreate = async () => {
    // console.log("11111", 11111);
    const currencyDecimals = getCurrencyDecimals(
      chainId,
      privateSaleInfoStorage?.currency
    );

    const softCap = convertToWei(
      privateSaleInfoStorage?.softcap,
      currencyDecimals
    );
    const hardCap = convertToWei(
      privateSaleInfoStorage?.hardcap,
      currencyDecimals
    );

    if (Number(softCap) > Number(hardCap)) {
      Store.addNotification(addNotify("Invalid Cap Setting!", "danger"));
      return;
    }

    const minInvest = convertToWei(
      privateSaleInfoStorage?.minBuy,
      currencyDecimals
    );
    const maxInvest = convertToWei(
      privateSaleInfoStorage?.maxBuy,
      currencyDecimals
    );

    if (Number(minInvest) > Number(maxInvest)) {
      Store.addNotification(addNotify("Invalid Investment Setting!", "danger"));
      return;
    }

    const startTime = Math.floor(
      new Date(privateSaleInfoStorage?.startTime).getTime() / 1000
    );
    const endTime = Math.floor(
      new Date(privateSaleInfoStorage?.endTime).getTime() / 1000
    );

    if (startTime > endTime) {
      Store.addNotification(addNotify("Invalid Time Setting!", "danger"));
      return;
    }

    const whitelistPool = privateSaleInfoStorage?.whiteListOnly == true ? 1 : 0;

    // claimInfo
    const firstReleasePercent =
      Number(privateSaleInfoStorage?.firstFundRelease) * 100;
    const vestingPeriodEachCycle =
      Number(privateSaleInfoStorage?.fundVestingPeriodEachCycle) * 60;
    const fundRelease = Number(privateSaleInfoStorage?.fundRelease) * 100;
    const penaltyFee = Math.floor(10 * 100);
    // feeInfo
    console.log('createPoolFee', createPoolFee)
    const initFee = convertToWei(createPoolFee, DECIMALS_STRING[chainDecimals]);
    const addressToken =
      chainId && CURRENCIES_ADDRESS[chainId][privateSaleInfoStorage?.currency];

    const privateSaleInfo = [
      addressToken,
      whitelistPool, //isWhitelist
      softCap,
      hardCap,
      minInvest,
      maxInvest,
      startTime,
      endTime,
    ];

    const vestingInfo = [
      firstReleasePercent,
      vestingPeriodEachCycle,
      fundRelease,
    ];

    // console.log(privateSaleInfo, vestingInfo);
    setActionStatus("LOADING");

    await privateSaleCreate(
      privateSaleDeployContract,
      privateSaleInfo,
      vestingInfo,
      initFee,
      penaltyFee
    )
      .then((res) => {
        let resWait: any = res.wait();
        resWait
          .then((resTransaction: any) => {
            // console.log("resTransaction privateSale ", resTransaction);
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              if (
                resTransaction.logs &&
                resTransaction.logs.length > 0 &&
                resTransaction.logs[0].address
              ) {
                // Call API to save data from SC
                dispatchCreateLauchpad(resTransaction.logs[0].address);
              }
            }
          })
          .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        // console.log("error", error);
        setActionStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };
  return (
    <div className="verifyToken">
      <div className="step">Step 3</div>
      {copied && <span className="c-noti is-success">Copied</span>}
      <div className="p-launchpadDetail">
        <div className="p-launchpadDetail__banner">
          <img
            src={
              additionInfoStorage?.banner
                ? additionInfoStorage?.banner
                : "../images/bgDefault.svg"
            }
            alt=""
          />
          <h2 className="p-launchpadDetail__logo">
            <img
              src={
                additionInfoStorage?.logo
                  ? additionInfoStorage?.banner
                  : "../images/logoDefault.svg"
              }
              alt=""
            />
            {privateSaleInfoStorage?.title}
          </h2>
        </div>

        <div className="p-launchpadDetail__detail">
          <div className="p-launchpadDetail__infoSC">
            {size.width <= 767 ? (
              <div className="p-launchpadDetail__info">{infoDetail}</div>
            ) : (
              ""
            )}
            <div className={`p-launchpadDetail__status is-Upcoming`}>
              <h3>
                <span>Upcoming</span>
                <Countdown
                  date={privateSaleInfoStorage?.startTime}
                  renderer={rendererTimer}
                />
              </h3>
              <div className="c-progress">
                <p className="c-progress__title">Progress (0%)</p>
                <div className="c-progress__bar">
                  <span style={{ width: 0 + "%" }}></span>
                </div>
                <p className="c-progress__txt">
                  0 {privateSaleInfoStorage?.currency}
                  <span>
                    {" "}
                    {privateSaleInfoStorage?.hardcap}{" "}
                    {privateSaleInfoStorage?.currency}
                  </span>
                </p>
              </div>
            </div>
            <div className={`p-launchpadDetail__boxFixed createLauchpad`}>
              <p>
                Sale Type
                <span>
                  {privateSaleInfoStorage?.whiteListOnly
                    ? "Whitelist"
                    : "Not Whitelist"}
                </span>
              </p>
              <p>
                Soft Cap
                <span>
                  {formatNumber(privateSaleInfoStorage?.softcap)}{" "}
                  {privateSaleInfoStorage?.currency}
                </span>
              </p>
              <p>
                Hard Cap
                <span>
                  {formatNumber(privateSaleInfoStorage?.hardcap)}{" "}
                  {privateSaleInfoStorage?.currency}
                </span>
              </p>
              {privateSaleInfoStorage?.vestingContributor
                ?.firstReleasePercent === 100 ? (
                <p>
                  Vesting For Presale<span>First Release 100%</span>
                </p>
              ) : (
                <p>
                  Vesting For Presale
                  <span>
                    {privateSaleInfoStorage?.fundRelease}% each{" "}
                    {privateSaleInfoStorage?.fundVestingPeriodEachCycle} minutes
                  </span>
                </p>
              )}
            </div>
          </div>
          <div className="p-launchpadDetail__content">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Pool Information" key="1">
                <div className="p-launchpadDetail__info">
                  {size.width <= 767 ? "" : infoDetail}
                  <div className="p-launchpadDetail__boxInfo">
                    <p className="p-launchpadDetail__row">
                      Start time
                      <span>
                        {formatDate(privateSaleInfoStorage?.startTime)}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      End time
                      <span>{formatDate(privateSaleInfoStorage?.endTime)}</span>
                    </p>

                    <p className="p-launchpadDetail__row">
                      Soft cap
                      <span>
                        {privateSaleInfoStorage?.softcap}{" "}
                        {privateSaleInfoStorage?.currency}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Hard cap
                      <span>
                        {privateSaleInfoStorage?.hardcap}{" "}
                        {privateSaleInfoStorage?.currency}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      First release for presale
                      <span>{privateSaleInfoStorage?.firstFundRelease}%</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Vesting for project{" "}
                      <span>
                        {privateSaleInfoStorage?.fundRelease}% each{" "}
                        {privateSaleInfoStorage?.fundVestingPeriodEachCycle}{" "}
                        minutes
                      </span>
                    </p>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="About the Project" key="2">
                <div className="p-launchpadDetail__info">
                  {size.width <= 767 ? "" : infoDetail}
                  <p className="p-launchpadDetail__txt">
                    {additionInfoStorage?.info}
                  </p>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>

      <div className="btn-verify fixed">
        <div className="isWidth">
          <Button text="Cancel" type="border" onClick={() => handleCancel()} />
        </div>
        <div className="m-25 isWidth">
          <ButtonAntd
            className="c-btn"
            htmlType="button"
            onClick={() => handleBack()}
          >
            Previous
          </ButtonAntd>
        </div>
        <div className="isWidth">
          <Button
            text="Public"
            type="blue"
            isLoading={actionStatus === "LOADING" ? true : false}
            disabled={
              actionStatus === "SUCCESS" || actionStatus === "LOADING"
                ? true
                : false
            }
            onClick={() => handleLaunchpadCreate()}
          />
        </div>
      </div>
      {loadingPage ? <Loading /> : null}
    </div>
  );
};

export default Finish;
