import React, { useEffect, useState } from 'react';
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import { SearchChange, Loading } from "../../components/component";
import { Tabs, Select, Pagination } from 'antd';
import type { PaginationProps } from 'antd';
import ItemLaunchpad from './item';
import { Grid, List } from '../../components/assets/icon';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getTokenInformList, tokenInformsSelector } from "../../redux/slicers/tokenInformSlice";
import { getLaunchpadFilter, getLaunchpadTrending, getLaunchpadListJoined, launchPadSelector } from "../../redux/slicers/launchpadSlice";
import ItemLaunchpadJoined from './itemJoined';
import { useActiveWeb3React } from '../../hooks';
import { CHAINID_CONVERT } from '../../constants';

interface Size {
    width: number;
    height: number;
}

const LaunchpadList: React.FC = () => { 
    const { account , chainId} = useActiveWeb3React();
    const [isSort, setIsSort] = useState<boolean>(false);
    const [isDisplay, setIsDisplay] = useState<boolean>(false);
    const [isPager, setIsPager] = useState<any>(1);
    const [filterStatus, setFilterStatus] = useState<string>('ALL');
    const [filterChain, setFilterChain] = useState<string>('ALL');
    const [filterSearch, setFilterSearch] = useState<string>('');
    const [filterSort, setFilterSort] = useState<string>('');
    const [isTabs, setIsTabs] = useState<number>(1);
    const size: Size = useWindowSize();
    const { Option } = Select;
    const { TabPane } = Tabs;
    const dispatch = useAppDispatch();
    //const { tokenInforms, loading, errors } = useAppSelector(tokenInformsSelector);

    const { launchpadFilter, launchPadTrending, launchpadsJoined, launchpadLoading, launchpadErrors, launchPadFilterCount, launchPadsJoinedCount } = useAppSelector(launchPadSelector);
    //console.log("🚀 ~ file: launchpadList.tsx ~ line 116 ~ tokenInforms", tokenInforms)
    
    useEffect(() => {
        const payload:any = {
            "page": 0,
            "size": 20,
            "eagerload": true
        }
        
        dispatch(getTokenInformList(payload));
        dispatch(getLaunchpadTrending());       
    }, [dispatch, account]);

    useEffect(() => {
        const payloadLaunchpad:any = {
            ownerAddress: '',
            status: filterStatus,
            networkChain: filterChain,
            keySearch: filterSearch,
            extend: filterSort,
            page: isPager-1,
            size: 10,
            sort: 'id,desc'
        }
        const payloadJoined: any = {
            "ownerAddress": account,
            "status": filterStatus,
            "networkChain": filterChain,
            "keySearch": filterSearch,
            "extend": filterSort,
        }
        const pagerJoined:number = isPager-1;
        const sortJoined:string = 'id,desc';
        if(isTabs === 1) {
            dispatch(getLaunchpadFilter(payloadLaunchpad));
        } else if(isTabs === 2) {
            if(account) {
                dispatch(getLaunchpadListJoined(payloadJoined, pagerJoined, sortJoined));
            }
        }
        
    }, [dispatch, account, isPager, filterStatus, filterChain, filterSearch, filterSort, isTabs]);

    const onChangeTabs = (key: string) => {
        setIsTabs(Number(key));
        setFilterStatus('ALL');
        setFilterChain('ALL');
        setFilterSearch('');
        setIsPager(1);
        setFilterSort('');
    };

    const onChangePager: PaginationProps['onChange'] = (pageNumber) => {
        setIsPager(pageNumber);
    };

    const onChangeStatus = (value: string) => {
        setFilterStatus(value);
    };
    const onChangeChain = (value: string) => {
        setFilterChain(value);
    };

    const handleSearch = () => {
        const payloadLaunchpad:any = {
            ownerAddress: '',
            status: filterStatus,
            networkChain: filterChain,
            keySearch: filterSearch,
            extend: filterSort,
            page: isPager-1,
            size: 10,
            sort: 'id,desc'
        }
        dispatch(getLaunchpadFilter(payloadLaunchpad));
    }
    
    const operations = <>
        <p>
            <span className={isDisplay === false ? 'is-active' : ''} onClick={() => setIsDisplay(false)}><Grid /></span>
            <span className={isDisplay === true ? 'is-active' : ''} onClick={() => setIsDisplay(true)}><List /></span>
        </p>
        <div className="p-launchpadList__sort">
            <Select className="c-selectEdit" value={filterStatus} onChange={onChangeStatus} style={{ width: 140 }}>
                <Option value="ALL">All Status</Option>
                <Option value="UPCOMING">Upcoming</Option>
                <Option value="OPENING">Opening</Option>
                <Option value="COMPLETED">Completed</Option>
            </Select>
            {/* <Select className="c-selectEdit is-ml24" value={filterChain} onChange={onChangeChain} style={{ width: 140 }}>
                <Option value="ALL">All Chain</Option>
                <Option value="BSCTESTNET">BSC Testnet</Option>
                <Option value="ARBTESTNET">ARB Tesnet</Option>
                <Option value="ARB">ARB Mainnet</Option>
            </Select> */}
            <div className="c-sortBy">
                <span onClick={() => setIsSort(!isSort)}><img src={`../images/${isSort ? 'icon-sortby-active.svg' : 'icon-sortby.svg'}`} alt="" /></span>
                <div className={`c-sortBy__box ${isSort ? 'is-open' : ''}`}>
                    <ul onClick={() => setIsSort(!isSort)}>
                        {/* <li className={filterSort === 'kyc' ? 'is-active' : ''} onClick={() => setFilterSort('kyc')}>KYC</li>
                        <li className={filterSort === 'audit' ? 'is-active' : ''} onClick={() => setFilterSort('audit')}>Audit</li>
                        <li className={filterSort === 'safu' ? 'is-active' : ''} onClick={() => setFilterSort('safu')}>SAFU</li>
                        <li className={filterSort === 'fair' ? 'is-active' : ''} onClick={() => setFilterSort('fair')}>Fair Launch</li> */}
                        <li className={filterSort === 'softCap' ? 'is-active' : ''} onClick={() => setFilterSort('softCap')}>Soft Cap</li>
                        <li className={filterSort === 'hardCap' ? 'is-active' : ''} onClick={() => setFilterSort('hardCap')}>Hard Cap</li>
                        {/* <li className={filterSort === 'auction' ? 'is-active' : ''} onClick={() => setFilterSort('auction')}>Auction</li>
                        <li className={filterSort === 'subscription' ? 'is-active' : ''} onClick={() => setFilterSort('subscription')}>Subscription</li>
                        <li className={filterSort === 'lpPerCent' ? 'is-active' : ''} onClick={() => setFilterSort('lpPerCent')}>LP Percent</li> */}
                        <li className={filterSort === 'startTime' ? 'is-active' : ''} onClick={() => setFilterSort('startTime')}>Start Time</li>
                    </ul>
                </div>
            </div>
        </div>
    </>;

    return(
        <>
        {launchpadLoading && <Loading />}
        <div className="p-launchpadList">
            <div className="p-launchpadList__top">
                <p>TRENDING </p>
                <Marquee gradient={false} speed={50} pauseOnHover={true}>
                    {launchPadTrending && launchPadTrending.length > 0 && launchPadTrending.map((item, index) => (
                        <Link to={`/launchpad/${item?.launchpadHandle}`} key={index}>#{index+1} {item?.name}</Link>
                    ))}
                </Marquee>
            </div>
            <div className="p-launchpadList__head">
                <h2>Launchpads List</h2>
                <p>We bring you many interesting Launchpad projects,<br/>moreover you have the opportunity to share your Launchpads to the community</p>
                <SearchChange value={filterSearch} onChange={(e) => setFilterSearch(e.target.value)} onClick={handleSearch} placeholder='Search launchpads name, token name or token symbol' />
            </div>
            <div className="p-launchpadList__box">
                <Tabs className="c-tabEdit" defaultActiveKey="1" tabBarExtraContent={operations} onChange={onChangeTabs}>
                    <TabPane tab="All Launchpads" key="1">
                        {isDisplay ? <div className="p-launchpadList__listHead">
                            <p>Name</p>
                            {size.width >= 991 ? <>
                                <p>HC</p>
                                <p>Token</p>
                                <p>Network</p>
                                <p>Initial Cap</p>
                                <p>Progress</p>
                                <p>Status</p>
                                <p></p>
                            </> : ''}                            
                        </div> : ''}
                        {launchpadFilter && launchpadFilter.length > 0 ? <>
                            <div className={`c-list ${isDisplay ? 'is-list' : ''}`}>
                                {launchpadFilter && launchpadFilter.map((item: object, key: number) =>  (   
                                    <ItemLaunchpad item={item} key={key} display={isDisplay} />
                                ))}
                            </div>
                            {Number(launchPadFilterCount) > 10 ? <Pagination 
                                className="c-pagingEdit"
                                pageSize={10}
                                defaultCurrent={1}
                                current={isPager}
                                onChange={onChangePager}
                                total={Number(launchPadFilterCount)}
                            /> : ''}
                        </> : <div className="c-nodata">
                            <img src="../images/img-nodata-launchpad.svg" alt="No data"/>
                            <p>
                                Launchpads
                                <span>This is where you will manage your launchpads that you created</span>
                            </p>
                        </div>}                        
                    </TabPane>
                    {account ? <TabPane tab="Joined Launchpads" key="2">
                        {isDisplay ? <div className="p-launchpadList__listHead">
                            <p>Name</p>
                            {size.width >= 991 ? <>
                                <p>HC</p>
                                <p>Token</p>
                                <p>Network</p>
                                <p>Initial Cap</p>
                                <p>Progress</p>
                                <p>Status</p>
                                <p></p>
                            </> : ''}                            
                        </div> : ''}
                        {launchpadsJoined && launchpadsJoined.length > 0 ? <>
                            <div className={`c-list ${isDisplay ? 'is-list' : ''}`}>
                                {launchpadsJoined && launchpadsJoined.map((item: object, key: number) => (
                                    <ItemLaunchpad item={item} key={key} display={isDisplay} />
                                ))}
                            </div>
                            {Number(launchPadsJoinedCount) > 10 ? <Pagination 
                                className="c-pagingEdit"
                                pageSize={10}
                                defaultCurrent={1}
                                current={isPager}
                                onChange={onChangePager}
                                total={Number(launchPadsJoinedCount)}
                            /> : ''}                            
                        </> : <div className="c-nodata">
                            <img src="../images/img-nodata-launchpad.svg" alt="No data"/>
                            <p>
                                Joined Launchpads
                                <span>This is where you will manage your launchpads that you created</span>
                            </p>
                        </div>}                        
                    </TabPane> : ''}
                </Tabs>
            </div>
        </div>
        </>
    )
}

export default LaunchpadList;
