import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getLaunchpadFilter, launchPadSelector } from "../../redux/slicers/launchpadSlice";
import { getBannerHome, bannerHomeSelector } from "../../redux/slicers/bannerHomeSlice";
import networkChange from "../../utils/networkChange";
import { MAPPING_CHAINID, MAPPING_CHAINID_DECIMAL } from "../../constants";
import { useActiveWeb3React } from "../../hooks";

type Props = {
    isBanner?: boolean;
}

const HomePage:React.FC<Props>  = (props:Props) => {
    const [isTokenUse, setIsTokenUse] = useState<string>('1');
    const [isOffsetTop, setIsOffsetTop] = useState<number>(10);
    const dispatch = useAppDispatch();
    const { launchpadFilter } = useAppSelector(launchPadSelector);
    const { bannersHome } = useAppSelector(bannerHomeSelector);
    const {isBanner} = props;
    const settings = {
        className: "c-sliderCenter",
        dots: true,
        arrows: false,
        centerMode: launchpadFilter && launchpadFilter.length >= 5 ? true : false,
        infinite: launchpadFilter && launchpadFilter.length >= 5 ? true : false,
        centerPadding: "100px",
        slidesToShow: launchpadFilter && launchpadFilter.length >= 5 ? 3 : 4,
        speed: 300,
        autoplay: false,
        autoplaySpeed: 2500,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: launchpadFilter && launchpadFilter.length >= 5 ? 1 : 2,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: "50px",
                }
            }
        ]
    };

    const settingsBanner = {
        className: "c-sliderBanner",
        dots: true,
        arrows: false,
        infinite: bannersHome && bannersHome.length >= 4 ? true : false,
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 300,
        autoplay: true,
        autoplaySpeed: 2500,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    infinite: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 767,
                settings: {
                    infinite: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "50px",
                }
            }
        ]
    }

    useEffect(() => {
        const payloadLaunchpad:any = {
            ownerAddress: '',
            status: 'OPENING',
            networkChain: 'ALL',
            keySearch: '',
            extend: '',
            page: 0,
            size: 10,
            sort: 'id,desc'
        }
        dispatch(getLaunchpadFilter(payloadLaunchpad));
        dispatch(getBannerHome());    
    }, [dispatch]);

    const handleTokenUsecase = () => {
        let img:any = '';
        if(isTokenUse === '1') {
            img = <img src="../images/home/token-img1.svg" alt="" />
        } else if(isTokenUse === '2') {
            img = <img src="../images/home/token-img2-2.png" alt="" />
        } else if(isTokenUse === '3') {
            img = <img src="../images/home/token-img3.png" alt="" />
        }
        return img;
    }

    const handleToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
    }
    useEffect(() => {
        const onScroll = () => setIsOffsetTop(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const handelLink = (value) => {
        let link = '';
        if(value === 'ICO') {
            link = 'launchpad'
        } else if(value === 'FAIR') {
            link = 'fairlaunch'
        } else if(value === 'PRIVATE') {
            link = 'private-sale'
        }
        return link;
    }

    return(
        <div className={`p-homePage ${isBanner ? 'is-bannerHeader' : ''}`}>
            <div className="p-homePage__bg"></div>
            <div className="p-top">
                <div className="p-top__content">
                    <span>Audited by <img src="../images/home/auditSC.svg" alt="" /></span>
                    <h2>The new standard for future revolution.</h2>
                    <p>Experience a seamless ownership process from token creation to launchpad management with BlueSale.</p>
                    <div className="p-top__btn">
                        <Link to='/token/create-token' className="c-btn c-btn--border">Create Token</Link>
                        <Link to='/launchpad/create-launchpad?type=1' className="c-btn c-btn--blue">Create Launchpad</Link>
                    </div>
                </div>
                <div className="p-top__img"><img src="../images/home/top-img.svg" alt="" /></div>
            </div>
            {bannersHome && bannersHome.length > 0 ? <div className="p-banner">
                <Slider {...settingsBanner} >
                    {bannersHome.map((item:any) => (
                        <div key={item.id} className="c-sliderBanner__item"><img src={item.bannerUrl} alt="" /></div>
                    ))}                    
                </Slider>
            </div> : ''}            
            <div className="p-opening">
                <h3 className="p-titleHome is-center">Opening launchpads.</h3>
                <p>We bring new technologies to our community.</p>
                {launchpadFilter && launchpadFilter.length > 0 ? <Slider {...settings} >
                    {launchpadFilter.map((item:any, key:any) => (
                        <div className="c-sliderCenter__item" key={key}>
                            <p>{item?.name}</p>
                            <img src={`../images/slider-center01.png`} alt="" />
                            <span style={{background:`url(${item?.bannerThumbnail !== null ? item?.bannerThumbnail : item?.bannerUrl}) center`, backgroundSize: 'auto 100%'}}></span>
                            <Link to={`/${handelLink(item?.type)}/${item?.handle}`}>View Project</Link>
                        </div>
                    ))}
                </Slider> : <div className="c-nodata is-home">
                    <img src="../images/home/empty.png" alt="" />
                    <p>Coming Soon</p>
                </div>}
            </div>
            <div className="p-blueSale">
                <div className="p-blueSale__img">
                    <span><img src="../images/home/bls-token.svg" alt="" /></span>
                </div>
                <div className="p-blueSale__content">
                    <h3 className="p-titleHome">BlueSale Token</h3>
                    <p>The DeFi market currently has millions of tokens, each with its unique mechanics and incentives. This complexity can make it difficult for users to make informed decisions. To address this issue, the BlueSale token will be used across various products within the BlueSale ecosystem. This approach will simplify the decision-making process for users. Furthermore, the platform will share a significant portion of its earnings with its users to support their growth and the development of new products. This process will generate several positive cycles within the ecosystem.</p>
                    <div className="p-blueSale__btn">
                        <a href="https://www.mexc.com/exchange/BLS_USDT" target="_blank" className="c-btn c-btn--blue">Buy BLS</a>
                        <a href="https://docs.bluesale.finance/" target="_blank" className="c-btn c-btn--white">Whitepaper<img src="../images/home/icon-arrow-right.svg" alt="" /></a>
                    </div>
                </div>
            </div>
            <div className="p-token">
                <h3 className="p-titleHome is-center">Token Usecase</h3>
                <ul className="p-token__content">
                    <li className={isTokenUse === '1' ? 'is-active' : ''} onClick={() => setIsTokenUse('1')}>
                        <div className="p-token__imgSP"><img src="../images/home/token-img1.svg" alt="" /></div>
                        <h4>Platform Revenue Sharing</h4>
                        <p>Most of the earnings generated by the platform are shared among users who allocate xBLS tokens to the Dividends plugin.</p>
                        <div className="p-token__btn">
                            <Link to='/bls/dividends' className="c-btn c-btn--blue">Dividends</Link>
                            <a href="https://docs.bluesale.finance/plugins/dividends" target="_blank" className="c-btn c-btn--white">Learn more<img src="../images/home/icon-arrow-right.svg" alt="" /></a>
                        </div>
                    </li>
                    <li className={isTokenUse === '2' ? 'is-active' : ''} onClick={() => setIsTokenUse('2')}>
                        <div className="p-token__imgSP"><img src="../images/home/token-img2-2.png" alt="" /></div>
                        <h4>BlueSale Elite Club</h4>
                        <p>BlueSale Elite Club is a members-only group that provides exceptional value to both clients and members of BlueSale.</p>
                        <div className="p-token__btn">
                            <a href="https://docs.bluesale.finance/plugins/bluesale-elite-club" target="_blank" className="c-btn c-btn--white">Learn more<img src="../images/home/icon-arrow-right.svg" alt="" /></a>
                        </div>
                    </li>
                    <li className={isTokenUse === '3' ? 'is-active' : ''} onClick={() => setIsTokenUse('3')}>
                        <div className="p-token__imgSP"><img src="../images/home/token-img3.svg" alt="" /></div>
                        <h4>DAO Governance</h4>
                        <p>Community members can vote on official proposals by using their xBLS balance, which acts as a governance token. The more xBLS a person has, the more votes they have.</p>
                        <div className="p-token__btn">
                            <a href="https://docs.bluesale.finance/plugins/community-plugins" target="_blank" className="c-btn c-btn--white">Learn more<img src="../images/home/icon-arrow-right.svg" alt="" /></a>
                        </div>
                    </li>
                </ul>
                <div className="p-token__img">{handleTokenUsecase()}</div>
            </div>
            <div className="p-fee">
                <h3 className="p-titleHome is-center">Fee Structure</h3>
                <div className="p-fee__img"><img src="../images/home/fee-img.png" alt="" /></div>
            </div>
            <div className="p-tokennomic">
                <h3 className="p-titleHome is-center">Tokenomic</h3>
                <div className="p-tokennomic__box">
                    <ul className="p-tokennomic__info">
                        <li>Max supply: <span>10,000,000 BLS</span></li>
                        <li>Name: <span>BlueSale Token</span></li>
                        <li>Ticker: <span>BLS<img src="../images/bls-icon.svg" alt="" /></span></li>
                        <li>Chain: <span>Arbitrum<img src="../images/arb-icon.svg" alt="" /></span></li>
                    </ul>
                    {/* <Chart
                        options={chartData.options}
                        series={chartData.series}
                        type="polarArea"
                        width="900"
                    /> */}
                    {/* <PolarArea data={data} options={chartOptions} /> */}
                    <div className="p-tokennomic__chart">
                        <img src="../images/home/chart-img.png" alt="" />
                        <ul>
                            <li className="is-01">Private<span>18%</span></li>
                            <li className="is-02">Public<span>8%</span></li>
                            <li className="is-03">Protocol Grow Incentives<span>17,5%</span></li>
                            <li className="is-04">Reserves<span>8%</span></li>
                            <li className="is-05">Marketing + Airdrop<span>7%</span></li>
                            <li className="is-06">Development<span>6,5%</span></li>
                            <li className="is-07">Liquidity<span>10%</span></li>
                            <li className="is-08">Boost Pool<span>10%</span></li>
                            <li className="is-09">Core contributors<span>15%</span></li>
                        </ul>
                    </div>                    
                </div>
            </div>
            <div className="p-vesting">
                <h3 className="p-titleHome is-center">Vesting Schedule</h3>
                <img src="../images/home/vesting-img.svg" alt="" />
                <ul>
                    <li>Private</li>
                    <li>Private (xBLS)</li>
                    <li>Public</li>
                    <li>Public (xBLS)</li>
                    <li>Protocol Grow</li>
                    <li>Reserves</li>
                    <li>Marketing+ Airdrop</li>
                    <li>Liquidity</li>
                    <li>Development</li>
                    <li>Boost Pool</li>
                    <li>Core contributors</li>
                </ul>
            </div>
            <div className="p-parter">
                <h3 className="p-titleHome is-center">Backer & Partner</h3>
                <ul>
                    <li><img src="../images/home/BitValue Capital.png" alt="BitValue Capital" /></li>
                    <li><img src="../images/home/BSCStation.png" alt="BSCStation" /></li>
                    <li><img src="../images/home/Lossless.png" alt="Lossless" /></li>
                    <li><img src="../images/home/TCVN.png" alt="TCVN" /></li>
                    <li><img src="../images/home/KingdomStarter.png" alt="KingdomStarter" /></li>
                    <li><img src="../images/home/Trustrank.png" alt="Trustrank" /></li>
                    <li><img src="../images/home/Solidproof.png" alt="Solidproof" /></li>
                    <li><img src="../images/home/Arbitrum Insider.png" alt="Arbitrum Insider" /></li>
                    <li><img src="../images/home/Arbitrum Space.png" alt="Arbitrum Space" /></li>
                    <li><img src="../images/home/Arbitrum Daily.png" alt="Arbitrum Daily" /></li>
                    <li><img src="../images/home/Arbitrum Calls.png" alt="Arbitrum Calls" /></li>
                    <li><img src="../images/home/Misses.png" alt="Misses" /></li>                    
                    <li><img src="../images/home/A2M VENTURES LOGO.png" alt="A2M VENTURES" /></li> 
                </ul>
            </div>
            <div className="p-bottom">
                <div className="p-bottom__box">
                    <div className="p-bottom__img"><img src="../images/home/bottom-img.svg" alt="" /></div>
                    <div className="p-bottom__content">
                        <h3>Spread the words!</h3>
                        <p>Tweet and share your thoughts on BlueSale or give us your ideas. Every feedback will be read and curate by our team to help shape us get closer to our mission.</p>
                        <div className="p-bottom__btn">
                            <a href="" className="c-btn c-btn--white">Let talk about ideas</a>
                        </div>
                    </div>
                </div>
                <div className="p-bottom__footer">
                    <ul>
                        <li><a href="https://www.bluesale.finance/terms-of-service" target="_blank">Terms of Service</a></li>
                        <li><a href="https://www.bluesale.finance/privacy-policy" target="_blank">Privacy Policy</a></li>
                        <li><a href="https://docs.bluesale.finance/" target="_blank">Docs</a></li>
                    </ul>
                    <p>© 2023 BlueSale</p>
                </div>
            </div>
            {isOffsetTop >= 100 ? <div className="toTop" onClick={() => handleToTop()}><img src="../images/home/toTop.svg" alt="" /></div> : ''}
            
        </div>
    )
}

export default HomePage;