import { useEffect, useState } from "react";
import ConnectWallet from "./connectWallet";
import InfoWallet from "./infoWallet";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getHeader, headerSelector } from "../../../redux/slicers/headerSlice";
import { useActiveWeb3React } from "../../../hooks";
import { useWeb3React } from "@web3-react/core";
import networkChange from "../../../utils/networkChange";
import web3 from "web3";
import { authenticate } from "../../../redux/slicers/authenticateSlice";
import { RPC_URL_CONVERT, MAIN_CURRENCIES } from "../../../constants";

const SID = require("@siddomains/sidjs").default;
const SIDfunctions = require("@siddomains/sidjs");

declare const window: Window & typeof globalThis & { ethereum: any };

const Wallet = () => {
  const [connectWallet, setConnectWallet] = useState<boolean>(false);
  const [bnbBalance, setBnbBalance] = useState<number>(0);
  const [mainCurrency, setMainCurrency] = useState<string>("");
  const [domand, setDomand] = useState<any>("");
  const dispatch = useAppDispatch();
  const { header } = useAppSelector(headerSelector);
  const { account, chainId } = useActiveWeb3React();
  const context = useWeb3React();
  let provider = "";
  if (chainId !== 1 && chainId) {
    provider = RPC_URL_CONVERT[chainId];
  }
  const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

  // const account = "0x517e608e63fC547110F11543411f94A596dB5579";
  const { active } = context;
  const accountEllipsis = active
    ? `${account?.substring(0, 4)}...${account?.substring(account.length - 4)}`
    : "Connect Wallet";
  if (window.ethereum) {
    window.ethereum.on("chainChanged", () => {
      window.location.reload();
    });

    window.ethereum.on("accountsChanged", (account) => {
      console.log("handler", account);
      //window.location.reload();
      if (account.length === 0) {
        window.localStorage.removeItem("accountStatus");
        window.localStorage.removeItem("connectorId");
        window.localStorage.removeItem("account");
        window.localStorage.removeItem("AUTHUSER");
        localStorage.removeItem("persist:root");
        window.localStorage.setItem("step", "1");
      }
    });
  }

  useEffect(() => {
    if(account && chainId){
    if (chainId == 97) {
      getDomainBNB(account);
    }
    if (chainId == 421613) {
      getDomainARB(account);
    }
    if (chainId == 1) {
      getDomainETH(account);
    }
    }
   
  }, [account, chainId]);

  let sid;
  const getDomainBNB = async (address: any) => { 
    const rpc =
      "https://bsc-mainnet.nodereal.io/v1/d0c3ef1cdb0247f4b6fae228aa76c8b8";
    const provider = new web3.providers.HttpProvider(rpc);
    const chainId = "56";
    sid = new SID({
      provider,
      sidAddress: SIDfunctions.getSidAddress(chainId),
    });

    await sid
      .getName(address)
      .then((res: any) => {
        if (res) {
          //   setIsWaiting(false);
          setDomand(res.name);
        }
      })
      .catch((err: any) => {
        // setIsWaiting(false);
      });
  };
  const getDomainARB = async (address: any) => {
    const rpc = "https://arb1.arbitrum.io/rpc";
    const provider = new web3.providers.HttpProvider(rpc);
    const chainId = 42161; //Arbitrum One chain id
    sid = new SID({
      provider,
      sidAddress: SIDfunctions.getSidAddress(chainId),
    });

    await sid
      .getName(address)
      .then((res: any) => {
        if (res) {
          //   setIsWaiting(false);
          setDomand(res.name);
        }
      })
      .catch((err: any) => {
        // setIsWaiting(false);
        setDomand(null);
      });
  };
  const getDomainETH = async (address: any) => {
    const rpc = "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
    const provider = new web3.providers.HttpProvider(rpc);
    const chainId = "1";
    sid = new SID({
      provider,
      sidAddress: SIDfunctions.getSidAddress(chainId),
    });

    await sid
      .getName(address)
      .then((res: any) => {
        if (res) {
          //   setIsWaiting(false);
          setDomand(res.name);
        }
      })
      .catch((err: any) => {
        // setIsWaiting(false);
      });
  };

  const handleToggle = (value: any) => {
    dispatch(getHeader(value));
  };

  useEffect(() => {
    const payload: any = {
      address: account,
    };
    if (account) {
      dispatch(authenticate(payload));
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      if (window.ethereum) {
        if (chainId && chainId != window.ethereum.networkVersion) {
          networkChange(`0x${chainId?.toString(16)}`);
        }
        if (chainId) {
          setMainCurrency(MAIN_CURRENCIES[chainId]);
        }
      }
    }
  }, [account, chainId]);

  useEffect(() => {
    if (account && active) {
      setConnectWallet(active);
      w3.eth.getBalance(account, (err, balance) => {
        if (balance) {
          let _balance = web3.utils.fromWei(web3.utils.toBN(balance), "ether");
          let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
          setBnbBalance(bnbBl);
        }
        return 0;
      });
    }
  }, [account]);

  return (
    <div className="btn_header">
      {connectWallet && account ? (
        <button
          onClick={() => handleToggle("infoWallet")}
          className="btn_header_connectedWallet"
        >
          <span className="balance">
            {bnbBalance} {mainCurrency}
          </span>
          <div className="btn_address">
            <span className="address">{domand || accountEllipsis}</span>
          </div>
        </button>
      ) : (
        <button
          onClick={() => handleToggle("connectWallet")}
          className="btn_header_wallet"
        >
          <span>Connect Wallet</span>
        </button>
      )}
      {header === "connectWallet" ? (
        <ConnectWallet setConnectWallet={setConnectWallet} />
      ) : null}
      {header === "infoWallet" ? (
        <InfoWallet setConnectWallet={setConnectWallet} />
      ) : null}
    </div>
  );
};

export default Wallet;
