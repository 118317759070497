import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import LaunchPadService from "../services/LaunchPadService";

// ================================================
// ==                  ACTION                    ==
// ================================================


export const getLaunchpadList = (payload: PayloadAction) => {
    return async dispatch => {
        dispatch(setLoading(true));

        await LaunchPadService.getLaunchpadList(payload)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setLaunchPads(res.data));
                dispatch(setLaunchPadsCount(Number(res.headers['x-total-count'])));
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false));
            });
    }
}

export const getLaunchpadFilter = (payload: PayloadAction) => {
    return async dispatch => {
        dispatch(setLoading(true));

        await LaunchPadService.getLaunchpadsFilter(payload)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setLaunchPadFilter(res.data));
                dispatch(setLaunchPadFilterCount(Number(res.headers['x-total-count'])));
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false));
            });
    }
}

export const getLaunchpadListJoined = (payload: PayloadAction, pager: number, sort: string) => {
    return async dispatch => {
        dispatch(setLoading(true));

        await LaunchPadService.getLaunchpadListJoined(payload, pager, sort)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setLaunchPadsJoined(res.data));
                dispatch(setLaunchPadsJoinedCount(Number(res.headers['x-total-count'])));
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false));
            });
    }
}

export const getMyLaunchpads = (payload: PayloadAction) => {
    return async dispatch => {
        dispatch(setLoading(true));

        await LaunchPadService.getMyLaunchpads(payload)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setMyLaunchpads(res.data));
                dispatch(setMyLaunchPadsCount(Number(res.headers['x-total-count'])));
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false));
            });
    }
}

export const getLaunchpadDetail = (handle) => {
    return async dispatch => {
        dispatch(setLoading(true));
        await LaunchPadService.getLaunchpadDetail(handle)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setLaunchPadDetail(res.data));
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false));
            });
    }
}

export const editLaunchpadDetail = (payload: PayloadAction) => {
    return async dispatch => {
        dispatch(setLoading(true));
        await LaunchPadService.editLaunchpadDetail(payload)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setLaunchPadDetail(res.data));
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false));
            });
    }
}

export const getLaunchpadTrending = () => {
    return async dispatch => {
        dispatch(setLoading(true));
        await LaunchPadService.getLaunchpadTrending()
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setLaunchPadTrending(res.data));
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false));
            });
    }
}


export const getLaunchpadVestingTeam = (id: any, onCallback: any) => {
    return async dispatch => {
        dispatch(setLoading(true));
        await LaunchPadService.getLaunchpadVestingTeam(id)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setLaunchPadVestingTeam(res.data));
                onCallback({ result: "SUCCESS" });
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false));
                onCallback({ result: "FAILED" });
            });
    }
}

export const editLaunchpadVestingTeam = (payload: PayloadAction, onCallback: any) => {
    return async dispatch => {
        dispatch(setLoading(true));
        await LaunchPadService.editLaunchpadVestingTeam(payload)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setLaunchPadVestingTeam(res.data));
                onCallback({ result: "SUCCESS" });
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false));
                onCallback({ result: "FAILED" });
            });
    }
}

export const getLaunchpadByStatus = (ownerAddress: any, status: any, page: any, size: any, sort: any) => {
    return async dispatch => {
        dispatch(setLoading(true));

        await LaunchPadService.getLaunchpadByStatus(ownerAddress, status, page, size, sort)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setLaunchPadsByStatus(res.data));
                //dispatch(setLaunchPadsCount(Number(res.headers['x-total-count'])));
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false));
            });
    }
}

export const finalizeLaunchpadAPI = (launchpadId: any, onCallback: any) => {
    return async dispatch => {
        dispatch(setLoading(true));

        await LaunchPadService.finalizeLaunchpad(launchpadId)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setLaunchPadsByStatus(res.data));
                onCallback({ result: "SUCCESS" });
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                onCallback({ result: "FAILED" });
            });
    }
}

export const joinedLaunchpadAPI = (launchpadId: any, onCallback: any) => {
    return async dispatch => {
        dispatch(setLoading(true));

        await LaunchPadService.joinedLaunchpad(launchpadId)
            .then((res) => {
                dispatch(setLoading(false));
                onCallback({ result: res.data });
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                onCallback({ result: "FAILED" });
            });
    }
}


export const getPrivateList = (payload: PayloadAction) => {
    return async dispatch => {
        dispatch(setLoading(true));

        await LaunchPadService.getPrivateList(payload)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setPrivateSale(res.data));
                dispatch(setPrivateSaleCount(Number(res.headers['x-total-count'])));
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false));
            });
    }
}

export const getPrivateListJoined = (payload: PayloadAction, pager: number, size: number, sort: string) => {
    return async dispatch => {
        dispatch(setLoading(true));

        await LaunchPadService.getPrivateListJoined(payload, pager, size, sort)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setPrivateJoined(res.data));
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false));
            });
    }
}

export const setEndTimePool = (payload: PayloadAction, onCallback: any) => {
    return async dispatch => {
        dispatch(setLoading(true));
        await LaunchPadService.setEndTimePool(payload)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setEndTimeLaunchPad(res.data));
                onCallback({ result: "SUCCESS" });
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false));
                onCallback({ result: "FAILED" });
            });
    }
}

// ================================================
// ==                REDUX STORE                 ==
// ================================================
type LaunchpadObject = {
    [key: string]: void | string | number | any;
};

export interface LaunchPadState {
    launchpads: LaunchpadObject[];
    launchpadFilter: LaunchpadObject[];
    launchPadFilterCount: number | string | undefined;
    launchpadsJoined: LaunchpadObject[];
    myLaunchpads: LaunchpadObject[];
    launchpadDetail: LaunchpadObject;
    launchPadTrending: LaunchpadObject;
    vestingTeam: LaunchpadObject;
    launchpadLoading: boolean;
    launchpadErrors: string;
    launchPadsCount: number | string | undefined;
    launchPadsJoinedCount: number | string | undefined;
    myLaunchpadsCount: number | string | undefined;
    launchpadsByStatus: LaunchpadObject[];
    privateSale: LaunchpadObject[];
    privateSaleCount: number | string | undefined;
    privateJoined: LaunchpadObject[];
    endTimePool: LaunchpadObject;
}

const initialState: LaunchPadState = {
    launchpads: [],
    launchpadFilter: [],
    launchPadFilterCount: 0,
    launchpadsJoined: [],
    myLaunchpads: [],
    launchpadDetail: {},
    launchPadTrending: [],
    vestingTeam: {},
    launchpadLoading: false,
    launchpadErrors: "",
    launchPadsCount: 0,
    launchPadsJoinedCount: 0,
    myLaunchpadsCount: 0,
    launchpadsByStatus: [],
    privateSale: [],
    privateSaleCount: 0,
    privateJoined: [],
    endTimePool: {}
}

const launchPadSlice = createSlice({
    name: "launchpad",
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.launchpadLoading = payload
        },

        setErrors: (state, { payload }: PayloadAction<string>) => {
            state.launchpadErrors = payload
        },

        setLaunchPads: (state, { payload }: PayloadAction<LaunchpadObject[]>) => {
            state.launchpads = payload
        },

        setLaunchPadFilter: (state, { payload }: PayloadAction<LaunchpadObject[]>) => {
            state.launchpadFilter = payload
        },

        setLaunchPadFilterCount: (state, { payload }: PayloadAction<number | string | undefined>) => {
            state.launchPadFilterCount = payload
        },

        setLaunchPadsJoined: (state, { payload }: PayloadAction<LaunchpadObject[]>) => {
            state.launchpadsJoined = payload
        },

        setMyLaunchpads: (state, { payload }: PayloadAction<LaunchpadObject[]>) => {
            state.myLaunchpads = payload
        },

        setLaunchPadDetail: (state, { payload }: PayloadAction<LaunchpadObject>) => {
            state.launchpadDetail = payload
        },
        setLaunchPadTrending: (state, { payload }: PayloadAction<LaunchpadObject>) => {
            state.launchPadTrending = payload
        },
        setLaunchPadVestingTeam: (state, { payload }: PayloadAction<LaunchpadObject>) => {
            state.vestingTeam = payload
        },
        setLaunchPadsCount: (state, { payload }: PayloadAction<number | string | undefined>) => {
            state.launchPadsCount = payload
        },
        setLaunchPadsJoinedCount: (state, { payload }: PayloadAction<number | string | undefined>) => {
            state.launchPadsJoinedCount = payload
        },
        setMyLaunchPadsCount: (state, { payload }: PayloadAction<number | string | undefined>) => {
            state.myLaunchpadsCount = payload
        },

        setLaunchPadsByStatus: (state, { payload }: PayloadAction<LaunchpadObject[]>) => {
            state.launchpadsByStatus = payload
        },

        setPrivateSale: (state, { payload }: PayloadAction<LaunchpadObject[]>) => {
            state.privateSale = payload
        },
        setPrivateSaleCount: (state, { payload }: PayloadAction<number | string | undefined>) => {
            state.privateSaleCount = payload
        },
        setPrivateJoined: (state, { payload }: PayloadAction<LaunchpadObject[]>) => {
            state.privateJoined = payload
        },

        setEndTimeLaunchPad: (state, { payload }: PayloadAction<LaunchpadObject[]>) => {
            state.endTimePool = payload
        },
    },
})

export const { setLoading, setErrors, setLaunchPads, setLaunchPadFilter,
    setLaunchPadFilterCount, setLaunchPadsJoined, setMyLaunchpads, setLaunchPadDetail,
    setLaunchPadVestingTeam, setLaunchPadTrending,
    setLaunchPadsCount, setLaunchPadsJoinedCount, setMyLaunchPadsCount,
    setLaunchPadsByStatus, setPrivateSale, setPrivateSaleCount, setPrivateJoined,
    setEndTimeLaunchPad } = launchPadSlice.actions

export default launchPadSlice.reducer

export const launchPadSelector = (state: { launchPadStore: LaunchPadState }) => state.launchPadStore