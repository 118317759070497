import { useEffect, useState } from "react";  
import { tokenInformsSelector, getTokenInformByAddress, createTokenInform } from "../../redux/slicers/tokenInformSlice";
import { setTokenStorage, tokenStorageSelector } from "../../redux/slicers/tokenStorageSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";  
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomButton } from "../component";
import { getAppSettings, settingSelector } from "../../redux/slicers/settingSlice"; 
import { CHAINID_CONVERT, FEE_SETTINGS } from '../../constants';
import { Store } from 'react-notifications-component';
import addNotify from '../commons/Notify/addNotify';
import { tokenInformation } from "../../contracts/token";
import { useTokenContract } from "../../hooks/useContract"; 
import { useActiveWeb3React } from "../../hooks";
import CreateToken from '../createtoken';  
import { additionInfo, fairlaunchInfo, verifyToken } from "../../redux/slicers/createFairlaunchSlice";

interface ITokenCreateForm{
  setActiveKey: any, 
  status: any,
  type: any
} 

type SettingFee = {
  chainId: string | number,
  feeType: string,
  payToken: any,
  settingKey: any
  settingValue: any
}

const Token : React.FC<ITokenCreateForm> =(props: ITokenCreateForm) => {
  const [createToken, setCreateToken] = useState<boolean>(false);
  const [availableToken, setAvailableToken] = useState<any>('');
  const [initTokenFee, setInitTokenFee] = useState<SettingFee>(); 
  const { settings } = useAppSelector(settingSelector); 
  const { tokenStorage } = useAppSelector(tokenStorageSelector);
  const dispatch = useAppDispatch(); 
  const navigate = useNavigate();
  const [actionStatus, setActionStatus] = useState("");
  const { account, chainId } = useActiveWeb3React();   
  const tokenContract = useTokenContract(availableToken); 
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(()=> {
    const payload:any = {
        "page": 0,
        "size": 100
    }
    dispatch(getAppSettings(payload));
  },[]);

  useEffect(()=> {
    if(!localStorage.getItem("stepFairLaunch") || Number(localStorage.getItem("stepFairLaunch")) === 1) {
        dispatch(fairlaunchInfo({}));
        dispatch(verifyToken({}));
        dispatch(setTokenStorage({}));
        dispatch(additionInfo({}));
        localStorage.setItem("stepFairLaunch", "1");
        localStorage.setItem("createType", "FairLaunch");
        setSearchParams({});
    }
  }, []);

  useEffect(() => {
    if(chainId) {
      const feeSettings = FEE_SETTINGS[chainId];
      if (settings && settings.length > 0) {  
         let tokenFee = settings.filter((itemSetting) => itemSetting?.settingKey === feeSettings?.INIT_TOKEN_FEE)?.[0];  
          setInitTokenFee({
            chainId: tokenFee?.chainId,
            feeType: tokenFee?.feeType,
            payToken: tokenFee?.payToken,
            settingKey: tokenFee?.settingKey,
            settingValue: tokenFee?.settingValue
          });
      }
    }
    
  }, [settings, chainId]);  
   
  const handleNext = () => {  
    setActionStatus("CHECK_LOADING");
    if(!account){
      Store.addNotification(addNotify("Please connect your wallet", 'danger'));
      setActionStatus("");
      return
    } 

    if (availableToken) {
      // call sc to check available token 
      
      if (tokenContract) {
        (async () => {
          setActionStatus("CHECK_LOADING");
          await tokenInformation(tokenContract)
            .then((tokenRes: any) => {
              setActionStatus("CHECKED");   
              TokenApi(tokenRes);
            })
            .catch((error: any) => {
              setActionStatus("");
              if(error.data){
                Store.addNotification(addNotify(error?.data, 'danger')); 
              }
              if (error) {   
                  Store.addNotification(addNotify(error?.reason, 'danger'));  
              }
            });
        })();
      }
      else {
        setActionStatus("");
        Store.addNotification(addNotify("Token address is invalid!", 'danger'));
        return;
      }
    } 
    else {
      setActionStatus("");
      Store.addNotification(addNotify("Please enter token address!", 'warning'));
      return;
    } 
  } 
 
  const TokenApi = (tokenRes) =>{
    dispatch(getTokenInformByAddress(availableToken, (res:any) => {
      // call api to save token avalaible 
      if(res?.tokenInform && res?.tokenInform?.id) {  
        setCreateToken(false);
        setActionStatus(""); 
        props.setActiveKey("2"); 
        TokenLauchpadForm(tokenRes);
        setSearchParams({token: availableToken});
      }
      else { 
        const tokenPayload :any= {
          "ownerAddress": account,
          "tokenType": "STANDARD",
          "tokenAddress": tokenRes?.tokenAddress,
          "tokenName": tokenRes?.tokenName,
          "symbol": tokenRes?.symbol,
          "decimals": tokenRes?.decimals,
          "totalSupply": tokenRes?.totalSupply,
          "type":"Available",
          "networkChain": chainId && CHAINID_CONVERT[chainId].toUpperCase(),
          "antiBotEnable": false
        } 
        dispatch(createTokenInform(tokenPayload, async (res:any) =>{
          if(res.result === "SUCCESS") {
              if(res?.data) {  
                props.setActiveKey("2");  
                dispatch(setTokenStorage(tokenPayload))
                localStorage.setItem("stepFairLaunch", "2"); 
                setSearchParams({token: tokenRes?.tokenAddress});
                Store.addNotification(addNotify("Token created successfully.", 'success'));
              }
          }
          else {  
              if (res?.error?.message) {
                  Store.addNotification(addNotify(res?.error?.message, 'danger'));
              }
          }
          setCreateToken(false);
          setActionStatus(""); 
        }));
      }
  }));
  } 
 
  const TokenLauchpadForm = (tokenRes) =>{
    dispatch(setTokenStorage({
      "ownerAddress": account,
      "tokenType": "STANDARD",
      "tokenAddress": tokenRes?.tokenAddress,
      "tokenName": tokenRes?.tokenName,
      "symbol": tokenRes?.symbol,
      "decimals": tokenRes?.decimals,
      "totalSupply": tokenRes?.totalSupply,
      "type": "Available",
      "networkChain": chainId && CHAINID_CONVERT[chainId].toUpperCase(),
      "antiBotEnable": false
    }));
    localStorage.setItem("stepFairLaunch", "2");
    
  } 
 
  return (
    <>
     <div className='token'> 
      <div className='step'>Step 1</div>
      <div className='step-text'>Do you create new token or do you have an available token</div>
     
      <div className='token-select'>
      <div className='form-availabletoken'>
          <img src="../images/check-circle.png"/>
          <span className='title'>I have available token</span>
          <span className='subtitle'>We don’t need to go through the onboarding process</span>
          <input className='input' value={availableToken} onChange={(e) => setAvailableToken(e.target.value)} placeholder='Enter token address'/>
      </div>  
      <div className='form-createtoken'>
          <img src="../images/uncheck-circle.png"/>
            <span className='title'>Please enter your token address</span> 
            <span className='subtitle'>Not sure which token is appropriate for your situation?</span>  
            <span className='subtitle-fee'>Create token fee: {initTokenFee?.settingValue} {initTokenFee?.payToken}</span>
          <button onClick={()=> setCreateToken(true)} className='btn-create'>Create Token</button>
      </div> 
      
      {createToken && 
      <> 
        <div className="form-createToken-bg"/>
        <CreateToken type={props.status} setCreateToken={setCreateToken} setActiveKey ={props.setActiveKey}/> 
      </>}
      
      </div>  
      <div className='btn-token cancel-next'>
        <button onClick={()=>navigate("/")} className='cancel'>Cancel</button>
        <CustomButton 
            class='next'
            isLoading={actionStatus === 'CHECK_LOADING' ? true : false}  
            text='Next'
            onClick={()=>handleNext()}
        />
      </div>
    </div>  
    </> 
  )
}

export default Token;