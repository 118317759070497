import { ArrowRightOutlined, DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Progress, Row } from 'antd';
import { useState, useEffect } from 'react'; 


const TokenUsecase = () => {
    const dataImg = {
        1:{
            img: '../images/platform-revenue-sharing.svg'
        },
        2:{
            img: '../images/bluesale-elite-club.png'
        },
        3:{
            img: '../images/dao-governance.svg'
        }
    }
    const [img, setImg] = useState<any>(dataImg[1].img);
    const [keyActive, setKeyActive] = useState<any>(1);


    const onSetImg = (index)=>{
        setImg(dataImg[index].img);
        setKeyActive(index);
    }
    
    return (
        <div className='container'>
            <div className='token-usecase-block'>
                <h3>Token <span>Usecase</span></h3>
                <Row>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <div className='token-usecase-img d-desktop'>
                            <img src={img} />
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <div onClick={()=>onSetImg(1)} className={`token-usecase-item ${keyActive === 1 ? 'active' : ''}`} >
                            <div className='token-usecase-img d-mobile'>
                                <img src="../images/platform-revenue-sharing.svg" />
                            </div>
                            <div className='token-usecase-title'>
                                Platform Revenue Sharing
                            </div>
                            <div className='token-usecase-description mt-15'>
                                Most of the earnings generated by the platform are shared among users who allocate xBLS tokens to the <strong>Dividends</strong> plugin.
                            </div>
                            <div className='token-usecase-link mt-15'>
                                <button disabled type='button' className='btn-main fill'>Launch App</button> 
                                <a className='ml-10 purple-text' href="https://docs.bluesale.finance/plugins/dividends" target="_blank">Learn more <ArrowRightOutlined /></a>
                            </div>
                        </div>
                        <div onClick={()=>onSetImg(2)} className={`token-usecase-item ${keyActive === 2 ? 'active' : ''} mt-15`}>
                            <div className='token-usecase-img d-mobile'>
                                <img src="../images/bluesale-elite-club.png" />
                            </div>
                            <div className='token-usecase-title'>
                                BlueSale Elite Club
                            </div>
                            <div className='token-usecase-description mt-15'>
                                BlueSale Elite Club is a members-only group that provides exceptional value to both clients and members of BlueSale.
                            </div>
                            <div className='token-usecase-link mt-15'>
                                 <a className="purple-text" href="https://docs.bluesale.finance/plugins/bluesale-elite-club" target="_blank">Learn more <ArrowRightOutlined /></a>
                            </div>
                        </div>
                        <div onClick={()=>onSetImg(3)} className={`token-usecase-item ${keyActive === 3 ? 'active' : ''} mt-15`}>
                            <div className='token-usecase-img d-mobile'>
                                <img src="../images/dao-governance.svg" />
                            </div>
                            <div className='token-usecase-title'>
                                DAO Governance
                            </div>
                            <div className='token-usecase-description mt-15'>
                                Community members can vote on official proposals by using their xBLS balance, which acts as a governance token. The more xBLS a person has, the more votes they have.
                            </div>
                            <div className='token-usecase-link mt-15'>
                                <a className="purple-text" href="https://docs.bluesale.finance/plugins/community-plugins" target="_blank">Learn more <ArrowRightOutlined /></a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
   )
}
 
export default TokenUsecase;