import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ItemListPrivate from "./itemList";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getPrivateListJoined, launchPadSelector } from "../../redux/slicers/launchpadSlice";
import { useActiveWeb3React } from "../../hooks";
import { SearchChange } from "../../components/component";
import { Select } from 'antd';

const MyPrivateSale = () => {
    const { account , chainId} = useActiveWeb3React();
    const [isSort, setIsSort] = useState<boolean>(false);
    const { Option } = Select;
    const dispatch = useAppDispatch();
    const { privateJoined } = useAppSelector(launchPadSelector);

    useEffect(() => {
        const payloadJoined:any = {
            "ownerAddress": account,
            "status": "ALL",
            // "networkChain": "BSC",
            // "keySearch": "",
            // "extend": "string"
        }
        const pagerJoined:number = 0;
        const sizeJoined:number = 100;
        const sortJoined:string = 'id';
        if(account) {
            dispatch(getPrivateListJoined(payloadJoined, pagerJoined, sizeJoined, sortJoined));
        }        
    }, [dispatch, account]);
    
    console.log('privateJoined',privateJoined)
    return(
        <div className="p-myLaunchpad">
            <div className="p-myLaunchpad__head">
                <h3>My Private Sale</h3>
                <Link to={''}>Create Private Sale</Link>
            </div>
            <div className="p-myLaunchpad__box">
                {privateJoined && privateJoined.length > 0 ? <>
                    <div className="is-displayFlex">
                    <SearchChange placeholder="Search private sale name, token name or token symbol" />
                    <div className="p-launchpadList__sort">
                        <Select className="c-selectEdit" defaultValue="all" style={{ width: 130 }}>
                            <Option value="all">All Status</Option>
                            <Option value="Upcoming">Upcoming</Option>
                            <Option value="Opening">Opening</Option>
                            <Option value="Completed">Completed</Option>
                        </Select>
                        <Select className="c-selectEdit is-ml24" defaultValue="ALL" style={{ width: 130 }}>
                            <Option value="ALL">All Chain</Option>
                            <Option value="BSC Testnet">BSC Testnet</Option>
                            <Option value="ARB Tesnet">ARB Tesnet</Option>
                            <Option value="ARB Mainnet">ARB Mainnet</Option>
                        </Select>
                        <div className="c-sortBy">
                            <span onClick={() => setIsSort(!isSort)}><img src={`../images/${isSort ? 'icon-sortby-active.svg' : 'icon-sortby.svg'}`} alt="" /></span>
                            <div className={`c-sortBy__box ${isSort ? 'is-open' : ''}`}>
                                <ul onClick={() => setIsSort(!isSort)}>
                                    <li className="is-active">KYC</li>
                                    <li>Audit</li>
                                    <li>DOXX</li>
                                    <li>Soft Cap</li>
                                    <li>Hard Cap</li>
                                    <li>Start Time</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="c-list">
                    {privateJoined && privateJoined.map((item:any, key:any) => (
                        <ItemListPrivate data={item} key={key} />
                    ))}
                </div>
                </> : <div className="c-nodata w-auto">
                    <img src="../images/no-data-private.svg" alt="No data"/>
                    <p>
                        My Private Sale
                        <span>This is where you will manage your private sale<br/>that you created</span>
                    </p>
                </div>}
                
                {/* <div className="c-nodata w-auto">
                    <img src="../images/no-data-private.svg" alt="No data"/>
                    <p>
                        My Private Sale
                        <span>This is where you will manage your private sale<br/>that you created</span>
                    </p>
                </div> */}
            </div>
        </div>
    )
}

export default MyPrivateSale;