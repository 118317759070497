import { useState, useEffect } from 'react'; 
import { Tabs } from 'antd';
import Token from '../../components/create-fairlaunch/token';
import VerifyToken from '../../components/create-fairlaunch/verifyToken';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setTokenStorage, tokenStorageSelector } from '../../redux/slicers/tokenStorageSlice';
import { useSearchParams } from 'react-router-dom';
import { additionInfo, fairlaunchInfo, verifyToken } from '../../redux/slicers/createFairlaunchSlice';
import FairLaunchInfo from '../../components/create-fairlaunch/fairlaunchInfo';
import FairLaunchAdditionInfo from '../../components/create-fairlaunch/fairLaunchAdditionInfo';
import FairLaunchFinish from '../../components/create-fairlaunch/fairLaunchFinish';

const CreateFairLaunch = () => {
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState<string>('1');
    const { tokenStorage } = useAppSelector(tokenStorageSelector); 
    const dispatch = useAppDispatch();
    
    const onKeyChange : any = (key) =>
    {
        setActiveKey(key); 
        localStorage.setItem("step", key);  
    }

    return (
    <div className='c-createLaunchpad'> 
        <Tabs
            activeKey={activeKey} onChange={onKeyChange} 
            items={["Token", "Verify Token", "DeFi Launchpad Info", "Add Addition Info", "Finish"].map((e, i) => {
            const id = String(i + 1); 
            return {
                label: ( 
                <div className='tab-header'>
                    {
                        activeKey <= id  ? 
                        <div className='tab-header-circle'>
                            <span className='tab-header-num'>{id}</span>
                        </div> :
                        <img src="../images/check.png"/>
                    } 
                    <span className='tab-header-text'>{e}</span>
                </div>
                ),
                key: id,
                disabled: activeKey < id && true,
                children:  
                    e === "Token" ? <Token status="FormFairlauch" type='' setActiveKey={setActiveKey} /> :
                    e === "Verify Token" ? <VerifyToken setActiveKey={setActiveKey}/> :
                    e === "DeFi Launchpad Info" ? <FairLaunchInfo setActiveKey={setActiveKey}/> :
                    e === "Add Addition Info" ? <FairLaunchAdditionInfo setActiveKey={setActiveKey}/> :
                    e === "Finish" ? <FairLaunchFinish setActiveKey={setActiveKey}/> : null,
                };
            })}
        /> 
    </div> 
   )
}
 
export default CreateFairLaunch;