import { Contract } from "ethers";
import { convertFromWei, convertToWei } from "./utils";

export const getPoolCap = async (contract:Contract | any) => {
    let _poolCap = await contract.poolCap();
    return Number(_poolCap);
}

export const getUserInfo = async (contract:Contract | any, address:string | null | undefined) => {
    return await contract.userInfo(address);
}

export const getStakedToken = async (contract:Contract | any) => {
    let stakedToken = await contract.stakedToken();
    return convertFromWei(stakedToken, 18);
}

export const getUnStakingFee = async (contract:Contract | any) => {
    let _unStakingFee = await contract.unStakingFee();
    return Number(_unStakingFee);
}

export const depositPool = async (contract:Contract | any, amount: number) => {
    let amountToWei = convertToWei(amount, 18);
    return await contract.deposit(amountToWei);
}

export const getPendingReward = async (contract:Contract | any, address:string | null | undefined) => {
    return await contract.pendingReward(address);
}

export const withdrawPool = async (contract:Contract | any, amount: number, onlyHarvest:boolean, isReward:boolean) => {
    let amountToWei = convertToWei(amount, 18);
    return await contract.withdraw(amountToWei, onlyHarvest, isReward);
}

export const getAllRewardPerBlock = async (contract:Contract | any, token: string[]) => {
    return await contract.getAllRewardPerBlock(token);
}

export const getTotalRewardPaid = async (contract:Contract | any) => {
    return await contract.totalRewardPaid();
}

export const getStakingBlock = async (contract:Contract | any) => {
    return await contract.stakingBlock();
}