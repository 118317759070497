import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import AntiBotService from "../services/antiBotService";

// ================================================
// ==                  ACTION                    ==
// ================================================ 
export const postAntiBot = (payload, onCallback) => {
    return async dispatch => {
        dispatch(setLoading(true));
        await AntiBotService.postAntiBot(payload)
            .then((res) => {
                dispatch(setLoading(false)); 
                onCallback(res)
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false)); 
                onCallback(error)
            });
    }
}
 
export const getAntiBotById = (id) => {
    return async dispatch => {
        dispatch(setLoading(true));
        await AntiBotService.getAntiBotById(id)
        .then((res)=>{
            dispatch(setLoading(false));
            dispatch(setAntibotById(res.data));
        })
        .catch((error:any)=>{
            dispatch(setErrors(error));
            dispatch(setLoading(false));
        });
    }
}

export const editAntiBotById = (id, payload:PayloadAction, onCallback:any) => {
    return async dispatch => {
        dispatch(setLoading(true));
        await AntiBotService.editAntiBotById(id, payload)
        .then((res)=>{
            dispatch(setLoading(false));
            dispatch(setAntibotById(res.data));
            onCallback({result: "SUCCESS"});
        })
        .catch((error:any)=>{
            dispatch(setErrors(error));
            dispatch(setLoading(false));
            onCallback({result: "FAILED"});
        });
    }
}

export const checkAllowAntibot = (address:string, onCallback:any) => {
    return async dispatch => {
        dispatch(setLoading(true));
        await AntiBotService.checkAntiBotByAdress(address)
            .then((res) => {
                dispatch(setLoading(false));
                //  console.log("resxx", res)
                // dispatch(setCheckAllowAntibot(res.data)); 
                onCallback({result: res.data}); 
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false)); 
                onCallback({result: "FAILED"}); 
            });
    }
}

export const checkConfigAntiBotByAdress = (address: string, onCallback:any) => {
    return async dispatch => {
        dispatch(setLoading(true));
        await AntiBotService.checkConfigAntiBotByAdress(address)
            .then((res) => {
                dispatch(setLoading(false));
                console.log("res", res)
                dispatch(setCheckConfigAntiBot(res.data));
                onCallback({result: "SUCCESS"});
            })
            .catch((error: any) => {
                dispatch(setErrors(error));
                dispatch(setLoading(false));
                 onCallback({result: "FAILED"});
            });
    }
}



// ================================================
// ==                REDUX STORE                 ==
// ================================================

type TokenObject = {
    [key: string]: void | string | number | any;
};

export interface AntibotState { 
    antiBotById: TokenObject;
    checkAllowAntibotByAddress: TokenObject;
    checkConfigAntiBot:TokenObject;
    loading: boolean;
    errors: string;
}

const initialState: AntibotState = { 
    antiBotById: {},
    checkAllowAntibotByAddress:{},
    checkConfigAntiBot:{},
    loading: false,
    errors: "",
}

const antibotSlice = createSlice({
    name: "Antibots",
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload
        },

        setErrors: (state, { payload }: PayloadAction<string>) => {
            state.errors = payload
        },  

        setAntibotById: (state, { payload }: PayloadAction<TokenObject>) => {
            state.antiBotById = payload
        },

        setCheckAllowAntibot: (state, { payload }: PayloadAction<TokenObject[]>) => {
            state.checkAllowAntibotByAddress = payload
        },

        setCheckConfigAntiBot: (state, { payload }: PayloadAction<TokenObject[]>) => {
            state.checkConfigAntiBot = payload
        }, 
    },
})

export const { setLoading, setErrors, setAntibotById, setCheckAllowAntibot, setCheckConfigAntiBot } = antibotSlice.actions

export default antibotSlice.reducer

export const antibotInfoSelector = (state: { antibotInfoStore: AntibotState }) => state.antibotInfoStore;
