import { PayloadAction } from "@reduxjs/toolkit";
import http from "./http-common";

const TokenInformService = {
    getTokenInformList (payload:PayloadAction) {
        return http.get("/token-informs", { params: payload });
    },
    getTokenInform ($tokenId:number) {
        return http.get(`/token-informs/${$tokenId}`);
    },
    getTokenInformListFull () {
        return http.get(`/token-informs`);
    }, 
    createTokenInform (payload:PayloadAction) {
        return http.post(`/token-informs/create`, payload);
    },
    createTokenLPInform (payload:PayloadAction) {
        return http.post(`/token-informs/create-lp`, payload);
    }, 
    updateTokenInform ($tokenId:number, payload:PayloadAction) {
        return http.put(`/token-informs/${$tokenId}`, payload);
    },
    deleteTokenInform ($tokenId:number) {
        return http.delete(`/token-informs/${$tokenId}`);
    },
    getTokenInformMyToken (payload:PayloadAction) {
        return http.get("/token-informs/my-token", { params: payload });
    },
    getTokenInformByAddress (handle:any) {
        return http.get(`/token-informs/by-token/${handle}`);
    },
    postCheckAllowLauchpad (payload:PayloadAction) {
        return http.post(`/token-informs/check-allow-lauchpad?tokenAddress=${payload}`);
    }, 
};
export default TokenInformService;