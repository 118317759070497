import { useState, useRef, useEffect } from "react";  
import {Form, Input, Select, Button as ButtonAntd} from 'antd';  
import { InputChange } from '../component';
import { Button } from '../component'; 
import type { CheckboxChangeEvent } from 'antd/es/checkbox';  
import axios from 'axios';  
import { createFairlaunchSelector, additionInfo, fairlaunchInfo, verifyToken } from "../../redux/slicers/createFairlaunchSlice";
import { tokenInformsSelector } from '../../redux/slicers/tokenInformSlice';
import { setTokenStorage } from "../../redux/slicers/tokenStorageSlice"; 
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Tokens } from "../../constants";
import { useNavigate } from "react-router-dom";
interface IFormCreate{
    setActiveKey: any, 
  } 
 
const FairLaunchAdditionInfo : React.FC<IFormCreate> =(props: IFormCreate) =>{ 
    const { additionInfoStorage } = useAppSelector(createFairlaunchSelector); 
    const [form] = Form.useForm();  
    const [imgBanner, setImgBanner] = useState(additionInfoStorage?.banner ?? "");
    const [bannerThumbnail, setBannerThumbnail] = useState(additionInfoStorage?.bannerThumbnail ?? "");
    const [imgLogo, setLogo] = useState(additionInfoStorage?.logo ?? "");  
    const inputImage = useRef<HTMLInputElement>(null);
    const inputbannerThumbnail = useRef<HTMLInputElement>(null);
    const inputLogo = useRef<HTMLInputElement>(null); 
    const dispatch = useAppDispatch(); 
    let navigate = useNavigate();
    
    const onChangeImage = (e, value) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.AUTHUSER)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            if(value == "banner"){
                setImgBanner(res.data);
            } else if(value == "bannerThumbnail"){
                setBannerThumbnail(res.data)
            } else{
                setLogo(res.data)
            }
        }).catch(err => {
            console.log(err);
        });
    } 

    useEffect(() => {
        form.setFieldsValue(additionInfoStorage);
    }, [additionInfoStorage, form]); 
 
    const onFinish = (values: any) => {   
        const payload: any = {
            ...values,
            banner: imgBanner,
            bannerThumbnail: bannerThumbnail,
            logo: imgLogo
        }
        dispatch(additionInfo(payload));
        props.setActiveKey("5"); 
        localStorage.setItem("stepFairLaunch", "5") 
    };
 
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    
    const handleCancel = () => {
        dispatch(fairlaunchInfo({}));
        dispatch(verifyToken({}));
        dispatch(setTokenStorage({}));
        dispatch(additionInfo({}))
        navigate("/launchpad/list");
        localStorage.setItem("stepFairLaunch", "1");
    }

    const handleBack= () =>{
        props.setActiveKey("3");
        localStorage.setItem("stepFairLaunch", "3") 
    }
    return (
    <div className='verifyToken'>
      <div className='step'>Step 4</div> 
      <Form 
            name="basic" 
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
            >
        <div className="verifyToken-form"> 
            <div className="verifyToken-left">
                <Form.Item name="name" rules={[{ required: true, message: 'Please input your name!' }]}> 
                    <InputChange title='LaunchPad’s Name' />
                </Form.Item>                        
                <div className="c-input">
                    <p className="c-input__title">LaunchPad’s Info</p>
                    <Form.Item name="info"> 
                        <textarea></textarea>
                    </Form.Item>
                </div>
                <div className="c-social">
                    <p className="c-ttl">Channel Media</p>
                    <div className="c-social__head">
                        <p>Channel name</p>
                        <p>URL <span>(Required)</span></p>
                    </div>
                    <div className="c-social__row">
                        <span><img src="../images/icon-editWebsite.svg" alt="" />Website</span>
                        <Form.Item name="website"> 
                            <InputChange placeholder="Enter URL" />
                        </Form.Item>
                    </div>
                    <div className="c-social__row">
                        <span><img src="../images/icon-editTwitter.svg" alt="" />Twitter</span>
                        <Form.Item name="twitter"> 
                            <InputChange placeholder="Enter URL" />
                        </Form.Item>
                    </div>
                    <div className="c-social__row">
                        <span><img src="../images/icon-editTelegram.svg" alt="" />Telegram</span>
                        <Form.Item name="telegram"> 
                            <InputChange placeholder="Enter URL" />
                        </Form.Item>
                    </div>
                    <div className="c-social__row">
                        <span><img src="../images/icon-editMedium.svg" alt="" />Medium</span>
                        <Form.Item name="medium"> 
                            <InputChange placeholder="Enter URL" />
                        </Form.Item>
                    </div>
                    <div className="c-social__row">
                        <span><img src="../images/icon-editYoutube.svg" alt="" />Youtube</span>
                        <Form.Item name="youtube"> 
                            <InputChange placeholder="Enter URL" />
                        </Form.Item>
                    </div>
                    <div className="c-social__row">
                        <span><img src="../images/icon-editDiscord.svg" alt="" />Discord</span>
                        <Form.Item name="discord"> 
                            <InputChange placeholder="Enter URL" />
                        </Form.Item>
                    </div>
                    <div className="c-social__row">
                        <span><img src="../images/icon-editFacebook.svg" alt="" />Facebook</span>
                        <Form.Item name="facebook"> 
                            <InputChange placeholder="Enter URL" />
                        </Form.Item>
                    </div> 
            </div> 
        </div> 
        <div className="verifyToken-right">  
            <div className="verifyToken-left-item"> 
                <div className="c-upload">
                    <span className="c-upload__title">Upload Banner</span>
                    <div className={`c-upload__box${imgBanner === '' ? ' is-add' : ''}`}>
                        {imgBanner === '' ? <div className='btn-click' onClick={() => inputImage?.current?.click()}> 
                            <p className="title">Click or drag files to upload here</p>
                            <p className="subtitle">Only png, jpg can be uploaded, and the size does not exceed 3MB. Recommended size is 1920x985px</p>
                        </div> : <div className="c-upBanner">
                            <img src={imgBanner} alt="" />
                            <div className="c-upBanner__edit">
                                <span onClick={()=>inputImage.current?.click()}><img src="../images/icon-editBanner.png" alt="" /></span>
                            </div>
                        </div>} 
                    <input ref={inputImage} onChange={(e) => onChangeImage(e, "banner")} type="file" />
                    </div>  
                </div>
            </div>
            <div className="verifyToken-left-item"> 
                <div className="c-upload">
                    <span className="c-upload__title">Upload Banner Thumbnail</span>
                    <div className={`c-upload__box${bannerThumbnail === '' ? ' is-add' : ''}`}>
                        {bannerThumbnail === '' ? <div className='btn-click' onClick={() => inputbannerThumbnail?.current?.click()}> 
                            <p className="title">Click or drag files to upload here</p>
                            <p className="subtitle">Only png, jpg can be uploaded, and the size does not exceed 3MB. Recommended size is 600x857px</p>
                        </div> : <div className="c-upBanner is-thumbnail">
                            <img src={bannerThumbnail} alt="" />
                            <div className="c-upBanner__edit">
                                <span onClick={()=>inputbannerThumbnail.current?.click()}><img src="../images/icon-editBanner.png" alt="" /></span>
                            </div>
                        </div>} 
                    <input ref={inputbannerThumbnail} onChange={(e) => onChangeImage(e, "bannerThumbnail")} type="file" />
                    </div>  
                </div>
            </div>
            <div className="c-upload">
            <span className="c-upload__title">Upload Logo</span>
                    {imgLogo === '' ? <div className="btn-upload" onClick={() => inputLogo?.current?.click()}> 
                        <img src="../images/upload.png"/>
                        <p className="mb-10">Upload</p>  
                    </div> :  <div className="c-upLogo">
                                <div className="c-upLogo__img">
                                    <img src={imgLogo} alt="" />
                                </div>
                                <div className="c-upLogo__edit" onClick={()=>inputLogo.current?.click()}>
                                    <span><img src="../images/icon-editBanner.png" alt="" /></span>
                                </div>
                                {/* <input ref={inputLogo} onChange={(e) =>onChangeImage(e,"logo")} type="file" /> */}
                            </div>} 
                    <input ref={inputLogo} onChange={(e)=> onChangeImage(e, "logo")} type="file" />
            </div> 
            </div> 
        </div> 
        <div className="footer-tab">
            <div className="fixed-bottom"> 
            <div className='btn-verify'>
                <div className="isWidth">
                    <Button text='Cancel' type='border' onClick={() => handleCancel()}/>
                </div> 
                <div className="m-25 isWidth">
                    <ButtonAntd className="c-btn" htmlType="button" onClick={()=>handleBack()}>
                        Previous
                    </ButtonAntd>
                </div> 
                <div className="isWidth">
                    <Button text='Next' type="blue"/>
                </div> 
            </div>
            </div> 
        </div>  
        </Form>  
    </div>  
  )
}

export default FairLaunchAdditionInfo;