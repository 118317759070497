import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { DatePicker, Form } from 'antd';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getLaunchpadVestingTeam, editLaunchpadVestingTeam, launchPadSelector } from "../../redux/slicers/launchpadSlice";
import { Button as ButtonComp } from '../../components/component';
import ImgCopy from '../../components/assets/images/icon-copy.svg';
import { Button, InputChange, Loading } from '../../components/component';
import { formatNumber, formatNumberAmount } from '../../utils/formatNumber';
import { formatDate } from '../../utils/formatDate';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment';
import { Store } from 'react-notifications-component';
import addNotify from '../../components/commons/Notify/addNotify';
import { getTokenAllowance, tokenApprove, tokenInformation } from '../../contracts/token';
import { useGSLockContract, useTokenContract } from '../../hooks/useContract';
import { SMART_CONTRACT_ADDRESS } from '../../constants';
import { convertFromWei, convertToWei } from '../../contracts/utils';
import { claimTokenLock, cumulativeLockInfo, editLockDescription, getWithdrawableTokens } from '../../contracts/goldSaleLock';
import { editLock, getLockById, postRenounceLockOwnership } from '../../contracts/goldSaleLock';
import { useActiveWeb3React } from '../../hooks';
import { getLiquidityInformation } from '../../contracts/pancakePair';
import PancakePairABI from '../../contracts/abi/PancakePairABI.json';
import EditPencilImg from '../../components/assets/images/pencil.svg';
import { ethers } from 'ethers';

declare const window: Window & typeof globalThis & { ethereum: any };

const VestingTeam: React.FC = () => {
    const [searchParams] = useSearchParams(); 
    const [canEditLock, setCanEditLock] = useState<boolean>(false);
    const [valueAmount, setValueAmount] = useState<number>(0);
    const [renounceLockOwnership, setRenounceLockOwnership] = useState<string>(); 
    const [loadingPage, setLoadingPage] = useState<boolean>(false);
    const [timer, setTimer] = useState<string>("");
    const { handle } = useParams<{ handle: any }>();
    const { account, chainId, library } = useActiveWeb3React(); ;
    const [teamLockId, setTeamLockId] = useState(searchParams.get("lockId"));
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [formTitle] = Form.useForm();
    const { vestingTeam, launchpadDetail, launchpadLoading, launchpadErrors } = useAppSelector(launchPadSelector); 
    const [actionStatus, setActionStatus] = useState("");
    const [ownerLock, setOwnerLock] = useState<any>("");
    const tokenInform = vestingTeam?.tokenInform;
    const lockAddress = chainId && SMART_CONTRACT_ADDRESS[chainId]?.GS_LOCK_ADDRESS;
    const gsLockContract = useGSLockContract(lockAddress); 
    const [tgeBps, setTgeBps] = useState<any>(0); 
    const [tgeDate, setTgeDate] = useState<any>(0); 
    const [tokenInformSC, setTokenInformSC] = useState<any>();    
    const [tokenLP, setTokenLP] = useState<any>();    
    const [tokenInformAPI, setTokenInformAPI] = useState<any>();
    const typeHandle = searchParams.get("type");
    const tokenAddress = searchParams.get("token");
    let navigate = useNavigate();
    const tokenContract = useTokenContract(tokenInform?.tokenAddress ?? tokenAddress); 
    
    const [isApprovedToken, setIsApprovedToken] = useState<boolean>(false);
    const [unlockedAmount, setUnlockedAmount] = useState<number>(0);
    const [withdrawableTokens, setWithdrawableTokens]  = useState<number>(0);
    const [titleEnable, setTitleEnable] = useState<boolean>(false);
    const [titleValue, setTitleValue] = useState<any>();
    
    useEffect(() => {
        if(handle) {
            // setLoadingPage(true);
            dispatch(getLaunchpadVestingTeam(Number(handle), (res:any) => {
                if(res){
                    // setLoadingPage(false);
                }
            }));
        }
    }, [dispatch, handle]);

    useEffect(() => {
        if(vestingTeam) {
            form.setFieldsValue(vestingTeam);
            form.setFieldsValue({
                amount: setValueAmount(vestingTeam?.amount),
                tgeDate: vestingTeam?.tgeDate ? moment(vestingTeam?.tgeDate).utc() : null,
            });
            formTitle.setFieldsValue({
                title: vestingTeam?.title
            });
            setTitleValue(vestingTeam?.title);
            setTimer(vestingTeam?.tgeDate);
            setOwnerLock(vestingTeam?.anotherOwner ? vestingTeam?.anotherOwner : vestingTeam?.owner);
            setTokenInformAPI({
                "tokenName": vestingTeam?.tokenInform?.name,
                "symbol": vestingTeam?.tokenInform?.symbol,
                "decimals": vestingTeam?.tokenInform?.decimals,
                "tokenAddress": vestingTeam?.tokenInform?.tokenAddress
            })
        }
    },[vestingTeam]);

    useEffect(() => {
        getLockRecordDetail();
        (async () => {
            const allowance = await getTokenAllowance(tokenContract, account, lockAddress);
            setIsApprovedToken(allowance > 0 ? true : false);
        })();        
    }, [account, tokenInformSC]);

    useEffect(() => {
        if(tokenAddress && typeHandle === 'lp') {
            (async() => { 
                setLoadingPage(true);
                await getLiquidityInformation(PancakePairABI, tokenAddress, library, account)
                .then((res:any) => {
                    setTokenLP(res);
                    setLoadingPage(false);                    
                })
                .catch((err:any) => {   
                    console.log(err);
                })
            })();
        }
        else if(tokenAddress && typeHandle === 'token') {
            (async() => { 
                // setLoadingPage(true);
                await tokenInformation(tokenContract)
                .then((res:any) => {
                    setTokenInformSC(res);
                    // setLoadingPage(false);                    
                })
                .catch((err:any) => {   
                    console.log(err);
                })
            })();
        }
    }, [tokenAddress, account]); 

    console.log("tokenLP", tokenLP)

    const handleClaimTokenLock = async () =>{ 
        let _teamLockId = vestingTeam?.lockId ? vestingTeam?.lockId : teamLockId;
        console.log("🚀 ~ file: vestingTeam.tsx:125 ~ handleClaimTokenLock ~ _teamLockId:", _teamLockId)
        if (!_teamLockId || _teamLockId == 0) { 
            Store.addNotification(addNotify('LockId is invalid. Please check again!', "danger"));
            return;
        }
        else {
            setActionStatus("CLAIM_TOKEN_LOCK_LOADING");
            await claimTokenLock(gsLockContract, _teamLockId)
            .then(res => {
            let resWait:any = res.wait();
            resWait.then((resTransaction:any) => { 
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) { 
                    Store.addNotification(addNotify('Claim successfully', "success"));
                    setActionStatus("CLAIM_TOKEN_LOCK_DONE");
                    getLockRecordDetail();
                }
            }) 
            }).catch((error:any) => {
                setActionStatus("");
                if (error.data) { 
                    Store.addNotification(addNotify('Transaction error', 'danger'));
                    return;
                }
                if (error) {   
                    Store.addNotification(addNotify(error?.reason, 'danger'));
                    return;  
                }
            }); 
        }
    }
    const handleLockOwnership = () =>{
        setRenounceLockOwnership("LOADING");
        let _teamLockId = vestingTeam?.lockId ? vestingTeam?.lockId : teamLockId;
        if(_teamLockId){
            postRenounceLockOwnership(gsLockContract, _teamLockId)
            .then(res => {  
                let resWait:any = res.wait();
                resWait.then((resTransaction:any) => { 
                    if (resTransaction && resTransaction.status && resTransaction.blockNumber) { 
                        setRenounceLockOwnership("DONE");
                        Store.addNotification(addNotify("Renounce Lock Ownership successfully.", 'success')); 
                    }
                }) 
                }).catch((error:any) => {
                    setActionStatus("");
                    if (error.data) {
                        setRenounceLockOwnership("DONE");
                        Store.addNotification(addNotify('Transaction error', 'danger'));
                        return;
                    }
                    if (error) {
                        setRenounceLockOwnership("DONE");
                        Store.addNotification(addNotify(error?.reason, 'danger'));
                        return;  
                    }
                }); 
        } 
    }
    
    const getLockRecordDetail = async () => {
        try {
            if (account && tokenInformSC) {
                let _teamLockId = vestingTeam?.lockId ? vestingTeam?.lockId : teamLockId;
                const lock = await getLockById(gsLockContract, _teamLockId);
                const info = await cumulativeLockInfo(gsLockContract, lock.token);
                const _withdrawableTokens:any = await getWithdrawableTokens(gsLockContract, _teamLockId);
                setWithdrawableTokens(Number(convertFromWei(_withdrawableTokens, tokenInformSC?.decimals)));
                // const balance = await getBalance(pairAbi, lock.token, account)
                // let allowance = await getTokenAllowance(tokenInfoAbi, lock.token, account, waveLockAddress)

                if (info.factory.toLowerCase() !== ethers.constants.AddressZero) {
                //     const lpInfo = await getLP(info.token, pairAbi, library, account);
                //     setIsLP(true);
                //     setPairName(`${lpInfo.token0Info.symbol}/${lpInfo.token1Info.symbol}`)
                //     setToken0Name(lpInfo.token0Info.name)
                //     setToken1Name(lpInfo.token1Info.name)
                //     setDex(lpInfo.name)
                //     setAmountLocked(parseFloat(ethers.utils.formatUnits(lock.amount, tokenDecimal)));
                //     setBalance(parseFloat(ethers.utils.formatUnits(balance, tokenDecimal)));
                    setUnlockedAmount(Number(convertFromWei(lock.unlockedAmount, tokenInformSC?.decimals)));
                //     createTokenForm.setFieldsValue({
                //         amount: parseFloat(ethers.utils.formatUnits(lock.amount, tokenDecimal)),
                //     })
                } else {
                //     const tokenInfo = await getTokenInformation(pairAbi, info.token);
                //     setTokenName(tokenInfo.name);
                //     setTokenSymbol(tokenInfo.symbol);
                //     setTokenDecimal(tokenInfo.decimals);
                //     setAmountLocked(parseFloat(ethers.utils.formatUnits(lock.amount, tokenInfo.decimals)));
                //     setBalance(parseFloat(ethers.utils.formatUnits(balance, tokenInfo.decimals)));
                    setUnlockedAmount(Number(convertFromWei(lock.unlockedAmount, tokenInformSC?.decimals)));
                //     createTokenForm.setFieldsValue({
                //         amount: parseFloat(ethers.utils.formatUnits(lock.amount, tokenInfo.decimals)),
                //     })
                }
                // setDescription(lock.description);
                // setOwnerLock(lock.owner);
                // setCycle(parseInt(lock.cycle) / 60);
                // setCycleBps(parseInt(lock.cycleBps) / 100);
                // setLockDate(parseInt(lock.lockDate) * 1000);
                setTgeBps(parseInt(lock.tgeBps) / 100);
                setTgeDate(new Date(parseInt(lock.tgeDate) * 1000));
                // setTokenAddress(info.token);
                // setTokenAllowance(parseInt(allowance));


                // createTokenForm.setFieldsValue({
                //     tge_date: (new Date(parseInt(lock.tgeDate) * 1000)).toISOString().slice(0, 16),
                //     cycle: parseInt(lock.cycle) / 60,
                //     cycle_release_percent: parseInt(lock.cycleBps) / 100,
                //     tge_percent: parseInt(lock.cycleBps) / 100,
                //     lock_until: (new Date(parseInt(lock.tgeDate) * 1000)).toISOString().slice(0, 16),
                //     title: lock.description
                // })
            }
        } catch (e) {
            console.log(e);
        }
    }

    const rendererTimer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
            return <span>Done !</span>;
        } else {
            // Render a countdown
            return <>
                <p>{zeroPad(days)}<span>Days</span></p>
                <p>{zeroPad(hours)}<span>HOURS</span></p>
                <p>{zeroPad(minutes)}<span>MINUTES</span></p>
                <p>{zeroPad(seconds)}<span>Seconds</span></p>
            </>;
        }
    };

    const onAmount = (e: any) => {
        setValueAmount(e.target.value);
    }

    const onFinishTitle = async (values: any) => {

        
    }
    const onFinish = async (values: any) => {
        const newTgeDate = values.tgeDate;

        if(values.amount > vestingTeam?.amount && values.tgeDate > new Date()){
            await editLock(gsLockContract,
                Number(vestingTeam?.lockId),
                convertToWei(Number(valueAmount), tokenInformSC.decimals),
                Math.floor((new Date(newTgeDate).getTime()) / 1000)
            ).then((res:any) => {
                let resWait:any = res.wait();
                resWait.then((resTransaction:any) => { 
                    if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                        // Call API to save data from SC
                        const _payloadAmount:any = {
                            "id": Number(vestingTeam?.id),
                            "amount": Number(valueAmount),
                            "tgeDate": values.tgeDate._d.toISOString()
                        };
                        
                        dispatch(editLaunchpadVestingTeam(_payloadAmount, async (res:any) => { 
                            if(res.result === "SUCCESS") {
                                setActionStatus("");
                                setCanEditLock(false);
                                Store.addNotification(addNotify("Token lock updated successfully.", 'success'));
                            }
                            else { 
                                setActionStatus("");
                                Store.addNotification(addNotify(res?.error?.response?.data?.title, 'danger'));
                            }
                        })); 
                    }
                }).catch((error:any) => {
                    setActionStatus("");
                    if (error.data) {
                        Store.addNotification(addNotify('Transaction error', 'danger'));
                        return;
                    }
                    if (error) {
                        Store.addNotification(addNotify(error?.reason, 'danger'));
                        return;  
                    }
                });
            })
            .catch((error:any) => {
                console.log(error);
                setActionStatus("");
                if (error.data) {
                    Store.addNotification(addNotify('Transaction error', 'danger'));
                    return;
                }
                if (error) {
                    Store.addNotification(addNotify(error?.reason, 'danger'));
                    return;  
                }
            });
        }
        else {
            Store.addNotification(addNotify('Input data is invalid.', 'danger'));
            return;
        }
    };

    const handleCopy = () => {
        Store.addNotification(addNotify('Copied !', 'success'));
    }

    const tokenApproval = async () => {
        setActionStatus("LOADING");
        await tokenApprove(tokenContract, lockAddress)
        .then((res:any) => {  
            let resWait:any = res.wait();
            resWait.then((resTransaction:any) => {
                setActionStatus("APPROVED");
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    Store.addNotification(addNotify('Approved successfully', 'success'));
                } else {
                    Store.addNotification(addNotify('Approved failed', 'danger'));
                }
            })
            .catch((error:any) => {
                setActionStatus("");
                if(error.data){
                    Store.addNotification(addNotify(error.data.message, 'warning'));
                }            
            });
        })
        .catch((error:any) => {
            setActionStatus("");
            if(error.data){
                Store.addNotification(addNotify(error.data.message, 'warning'));
            } 
        });
    } 

    const getActionButton = (status:string) => { 
        let actionButton:any = '';
        
        if(status === "APPROVED" || isApprovedToken) { 
            actionButton = <ButtonComp text='Edit' type="blue"></ButtonComp>;
        }
        else { 
            actionButton = tokenInform?.tokenAddress ? <ButtonComp onClick={() => tokenApproval()} text='Approve' type="blue"/> :
            <ButtonComp disabled={true} text='Approve' type="blue"/>  
        }

        return  actionButton;
    }

    const handleChangeTitleEnable = (titleEnable:boolean) => {
        setTitleEnable(!titleEnable);
    }
    const handleChangeTitle = (e:any) => {
        setTitleValue(e.target.value);
    }

    const handleChangeTitleValue = async () => {
        if(titleValue && titleValue !== vestingTeam?.title){
            await editLockDescription(gsLockContract, Number(vestingTeam?.lockId), titleValue)
            .then((res:any) => {
                let resWait:any = res.wait();
                resWait.then((resTransaction:any) => { 
                    if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                        // Call API to save data from SC
                        const _payloadTitle:any = {
                            "id": Number(vestingTeam?.id),
                            "title": titleValue
                        };
                        dispatch(editLaunchpadVestingTeam(_payloadTitle, async (res:any) => { 
                            if(res.result === "SUCCESS") {
                                setTitleEnable(false);
                                Store.addNotification(addNotify("Title token lock updated successfully.", 'success'));
                            }
                            else { 
                                setActionStatus("");
                                Store.addNotification(addNotify(res?.error?.response?.data?.title, 'danger'));
                            }
                        })); 
                    }
                }).catch((error:any) => {
                    setActionStatus("");
                    setTitleEnable(false);
                    if (error.data) {
                        Store.addNotification(addNotify('Transaction error', 'danger'));
                        return;
                    }
                    if (error) {
                        Store.addNotification(addNotify(error?.reason, 'danger'));
                        return;  
                    }
                });
            });
        }
    }
   
    console.log("tgeDate", tgeDate);
    return (<>
        {launchpadLoading || loadingPage && <Loading />}
        <div className="p-vestingTeam">
            <div className="c-breadcrumb">
                <ul>
                    <li><span>Token</span></li>
                    <li><Link to={typeHandle === 'token' ? '/token/my-token' : '/liquidity'}>{typeHandle === 'token' ? 'My Token List' : 'My Liquidity Lock'}</Link></li>
                    <li><Link to={`/lock-records/${tokenAddress}?type=${searchParams.get('type')}`}>{typeHandle === 'token' ? vestingTeam?.launchpadName : tokenInform?.name}</Link></li>
                    <li onClick={() => window.location.replace(`/lock-records/${tokenAddress}?type=${searchParams.get('type')}`)}><span>{vestingTeam?.title}</span></li>
                </ul>
            </div>
            <div className="p-vestingTeam__unlockTimer">
                <h3>Unlock in:</h3>
                {timer ?<Countdown date={timer} renderer={rendererTimer} /> : null}                
            </div>
            <div className="p-vestingTeam__box">
                <div className="p-vestingTeam__left">
                    <h3 className="p-vestingTeam__title">Lock Infor</h3>
                    <div className="p-vestingTeam__lock">
                        <p>
                            Title
                            <span>{vestingTeam?.title}</span>
                        </p>
                        <p>
                            Total Amount Locked
                            <span>{formatNumber(vestingTeam?.amount)} {typeHandle === 'lp' ? '' : tokenInform?.symbol}</span>
                        </p>
                        <p>
                            Total Value Locked
                            <span>${formatNumber(vestingTeam?.valueAmount)}</span>
                        </p>
                        <p>
                            Owner
                            {ownerLock ? <span className="c-copy">
                                {ownerLock?.substring(0,6) + "..." + ownerLock?.substr(ownerLock?.length - 6)}
                                <CopyToClipboard text={ownerLock} onCopy={() => handleCopy()}>
                                    <button><img src={ImgCopy} alt="" /></button>
                                </CopyToClipboard>
                            </span> : <span>--</span>}
                        </p>
                        <p>
                            Lock Date (UTC)
                            <span>{formatDate(vestingTeam?.lockDate)}</span>
                        </p>
                        <p>
                            TGE Date (UTC)
                            <span>{formatDate(vestingTeam?.tgeDate)}</span>
                        </p>
                        <p>
                            TGE Percent
                            <span>{vestingTeam?.tgePercent}%</span>
                        </p>
                        {Number(vestingTeam?.tgePercent) !== 100 ? <>
                        <p>
                            Cycle
                            <span>{vestingTeam?.vestingCycle} minutes</span>
                        </p>
                        <p>
                            Cycle Release Percent
                            <span>{vestingTeam?.cycleReleasePercent}%</span>
                        </p>
                        </> : null}
                        <p>
                            Unlocked Amount
                            <span>{formatNumber(unlockedAmount)} {typeHandle === 'lp' ? '' : tokenInform?.symbol}</span>
                        </p>
                    </div>
                </div>
                <div className="p-vestingTeam__right">
                    <h3 className="p-vestingTeam__title">Token Infor</h3>
                    <div className="p-vestingTeam__token">
                        {typeHandle === 'token' ? <>
                            <p>
                                <img src="../../../images/icon-address.svg" alt="" />
                                Address:
                                {tokenInformSC?.tokenAddress ? <span className="c-copy">
                                    {tokenInformSC?.tokenAddress?.substring(0,6) + "..." + tokenInformSC?.tokenAddress?.substr(tokenInformSC?.tokenAddress?.length - 6)}
                                    <CopyToClipboard text={tokenInformSC?.tokenAddress} onCopy={() => handleCopy()}>
                                        <button><img src={ImgCopy} alt="" /></button>
                                    </CopyToClipboard>
                                </span> : <span>--</span>}                            
                            </p>
                            <p>
                                <img src="../../../images/icon-name.svg" alt="" />
                                Name:<span>{tokenInformSC?.tokenName}</span>
                            </p>
                            <p>
                                <img src="../../../images/icon-sympol.svg" alt="" />
                                Sympol:<span>{tokenInformSC?.symbol}</span>
                            </p>
                            <p>
                                <img src="../../../images/icon-decimal.svg" alt="" />
                                Decimal:<span>{tokenInformSC?.decimals}</span>
                            </p>
                        </> : <>
                            <p>
                                <img src="../../../images/icon-address.svg" alt="" />
                                LP Token Address:
                                {tokenLP?.lpAddress ? <span className="c-copy">
                                    {tokenLP?.lpAddress?.substring(0,6) + "..." + tokenLP?.lpAddress?.substr(tokenLP?.lpAddress?.length - 6)}
                                    <CopyToClipboard text={tokenLP?.lpAddress} onCopy={() => handleCopy()}>
                                        <button><img src={ImgCopy} alt="" /></button>
                                    </CopyToClipboard>
                                </span> : <span>--</span>}
                            </p>
                            <p>
                                <img src="../../../images/icon-name.svg" alt="" />
                                LP Token Name:<span>{tokenLP?.lpTokenname}</span>
                            </p>
                            <p>
                                <img src="../../../images/icon-sympol.svg" alt="" />
                                Pair:<span>{tokenLP?.pair}</span>
                            </p>
                            <p>
                                <img src="../../../images/icon-decimal.svg" alt="" />
                                Dex:<span>{tokenLP?.dex}</span>
                            </p>
                        </>}
                        
                    </div>
                    {vestingTeam?.vestingInfos && vestingTeam?.vestingInfos.length > 0 && <>
                        <h3 className="p-vestingTeam__title">Vesting Info</h3>
                        <div className="p-vestingTeam__vesting">
                            <table>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Time (UTC)</th>
                                        <th>Unlocked Tokens</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {vestingTeam?.vestingInfos.map((item, index) => {
                                        return(
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{formatDate(item?.time)}</td>
                                                <td>{formatNumber(item?.token)} ({item?.tokenPercent}%)</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>}
                    
                </div>
                {typeHandle === 'lp' ? <div className="p-vestingTeam__btn">
                    <Button text='Edit' type='greyBorder' onClick={() => setCanEditLock(true)} 
                    disabled={(tgeDate > new Date()) ? true : false} 
                    />                    
                    <Button 
                        text={'Claim LP Token Lock'} 
                        type='blue' 
                        onClick={() => handleClaimTokenLock()} 
                        disabled={actionStatus === "CLAIM_TOKEN_LOCK_LOADING" || actionStatus === "CLAIM_TOKEN_LOCK_DONE" ? true : false}
                        isLoading={actionStatus === "CLAIM_TOKEN_LOCK_LOADING"}
                    /> 
                    <Button disabled={renounceLockOwnership === "LOADING" || renounceLockOwnership === "DONE" ? true : false} 
                        isLoading={renounceLockOwnership === "LOADING" ? true : false}
                        onClick={() => handleLockOwnership()} text='Renounce Lock Ownership' type='blue' />
                </div> : 
                <div className="p-vestingTeam__btn">
                    <Button text='Edit' type='greyBorder' onClick={() => setCanEditLock(true)} 
                    disabled={ (ownerLock?.toLowerCase() !==  account?.toLowerCase()) || (tgeDate <= new Date()) ? true : false} 
                    />                    
                    <Button 
                        text={'Claim Token Lock'} 
                        type='blue' 
                        onClick={() => handleClaimTokenLock()} 
                        disabled={(ownerLock?.toLowerCase() !==  account?.toLowerCase()) || actionStatus === "CLAIM_TOKEN_LOCK_LOADING" || actionStatus === "CLAIM_TOKEN_LOCK_DONE" ? true : false}
                        isLoading={actionStatus === "CLAIM_TOKEN_LOCK_LOADING"}
                    /> 
                    <Button disabled={ (ownerLock?.toLowerCase() !==  account?.toLowerCase()) || renounceLockOwnership === "LOADING" || renounceLockOwnership === "DONE" ? true : false} 
                        isLoading={renounceLockOwnership === "LOADING" ? true : false}
                        onClick={() => handleLockOwnership()} text='Renounce Lock Ownership' type='blue' />
                </div>}
                
            </div>
        </div>

        {canEditLock &&
        <div className="c-popup">
            <div className="c-popup__content is-small">
                <h3 className="c-popup__title">Edit Your Lock</h3>
                <div className="c-popup__box is-editVesting">
                    <Form name="basic" form={formTitle} onFinish={onFinishTitle}>
                        <Form.Item name="title">
                            <div className="c-input">
                                <p className="c-input__title">Title</p>
                                <div className="c-input__max">
                                    <input type="text" value={titleValue} disabled={!titleEnable} onChange={(e) => handleChangeTitle(e)} />
                                    {titleEnable ? 
                                    <span onClick={() => handleChangeTitleValue()}>OK</span> : 
                                    <span onClick={() => handleChangeTitleEnable(titleEnable)}> <img src={EditPencilImg} /></span>}
                                </div>
                            </div>
                        </Form.Item>
                    </Form>
                    <Form name="basic" form={form} onFinish={onFinish}>
                        <Form.Item name="amount">
                            <div className="c-input">
                                <p className="c-input__title">Amount</p>
                                <div className="c-input__max">
                                    <input type="number" value={valueAmount} onChange={(e) => onAmount(e)} />
                                    <span onClick={() => setValueAmount(tokenInformSC?.totalSupply)}>Max</span>
                                </div>
                                <p className="c-txt is-blue">Balance: {formatNumber(tokenInformSC?.totalSupply)}</p>
                            </div>
                        </Form.Item>                        
                        <p className="c-ttl">TGE Date (UTC)</p>
                        <Form.Item name="tgeDate">
                            <DatePicker className="c-dateEdit" showTime={true} format={'DD/MM/YYYY HH:mm:ss'} placeholder='Select time' />
                        </Form.Item>
                        <div className="c-popup__btn">
                            <Button text='Cancel' type='greyBorder' onClick={() => {setCanEditLock(false);setValueAmount(vestingTeam?.amount)}}/>
                            {getActionButton(actionStatus)}
                        </div>
                    </Form>
                </div>
            </div>
        </div>}
    </>)
}

export default VestingTeam;
