
import { useEffect, useState } from 'react';
import HeaderPublicSale from '../../components/header-public-sale';
import BackerPartner from './backer-partner';
import BlsTokenSection from './bls-token-section';
import BlueSaleTokenSection from './bluesale-token-section';
import FeeStructure from './fee-structure';
import TokenUsecase from './token-usecase';
import Tokenomic from './tokenomic';
import VestingSchedule from './vestingschedule'; 


const PublicSale = () => { 
    const [isOffsetTop, setIsOffsetTop] = useState<number>(10);
    const handleToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
    }
    
    // useEffect(() => {
    //     const onScroll = () => setIsOffsetTop(window.pageYOffset);
    //     window.removeEventListener('scroll', onScroll);
    //     window.addEventListener('scroll', onScroll, { passive: true });
    //     return () => window.removeEventListener('scroll', onScroll);
    // }, []);
    return (
        <div className="main-layout"> 
            <HeaderPublicSale />
            <div className='social-fixed'>
                <ul>
                    <li><a className='social-fixed-item' target="_blank" href='https://t.me/BlueSaleFinance'><img src='../images/telegram-active.svg'/></a></li>
                    <li><a className='social-fixed-item' target="_blank" href='https://discord.io/BlueSale'><img src='../images/discord-active.svg'/></a></li>
                    <li><a className='social-fixed-item' target="_blank" href='https://twitter.com/BluesaleBls'><img src='../images/twitter-active.svg'/></a></li>
                </ul> 
                <span className='contact-public'>contact@bluesale.finance</span> 
            </div>
           
            <section id='bls-token' className='bls-token-section'> 
                <div className='water-effect'>
                    <img src='../images/water.svg'/>
                </div>  
                <BlsTokenSection />
            </section>  
            <section id='blueSale-token-section' className='bluesale-section'  >
                <BlueSaleTokenSection />
            </section> 
            <section id='token-usecase-section' className='token-usecase-section' >
                <TokenUsecase />
            </section> 
            <section id='fee-structure-section' className='token-usecase-section'>
                <FeeStructure />
            </section> 
            <section id='tokenomic-section' className='tokenomic-section' >
                <Tokenomic />
            </section> 
            <section id='vestingschedule-section' className='vestingschedule-section' >
                <VestingSchedule />
            </section> 
            <section id='backer-partner' className='backer-partner-section' >
                <BackerPartner />
            </section> 
            <section >
                <div className='container'>
                    <div className="p-bottom">
                        <div className="p-bottom__box">
                            <div className="p-bottom__img">
                                <a href="https://twitter.com/BluesaleBls" target="_blank"><img src="../images/home/bottom-img.svg" alt="" /></a>
                            </div>
                            <div className="p-bottom__content">
                                <h3>Spread the words!</h3>
                                <p>Tweet and share your thoughts on BlueSale or give us your ideas. Every feedback will be read and curate by our team to help shape us get closer to our mission.</p>
                                <div className="p-bottom__btn">
                                    <a href="https://twitter.com/BluesaleBls" target="_blank" className="c-btn c-btn--white">Let talk about ideas</a>
                                </div>
                            </div>
                        </div>
                        <div className="p-bottom__footer">
                            <ul>
                                <li><a href="/terms-of-service" target="_blank">Terms of Service</a></li>
                                <li><a href="/privacy-policy" target="_blank">Privacy Policy</a></li>
                                <li><a href="https://docs.bluesale.finance/" target="_blank">Docs</a></li>
                            </ul>
                            <p>© 2023 BlueSale</p>
                        </div>
                    </div>
                </div> 
            </section>
            <div className="toTop" onClick={() => handleToTop()}><img src="../images/home/toTop.svg" alt="" /></div>
        </div>
   )
}
 
export default PublicSale;