import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Tabs, Button as ButtonAntd } from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Button, Loading, SearchChange } from '../component';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getTokenInformByAddress} from '../../redux/slicers/tokenInformSlice';
import { setTokenStorage } from "../../redux/slicers/tokenStorageSlice";
import { launchpadCreate } from '../../contracts/launchpad';
import { useTokenContract } from '../../hooks/useContract';
import { useFairlaunchDeployContract } from '../../hooks/useContract';
import { ethers } from 'ethers';
import { APP_SETTINGS, DEX_EXCHANGES, FEE_SETTINGS, LAUNCHPAD_CONFIGS, RPC_URL_CONVERT, SMART_CONTRACT_ADDRESS, ZOOM } from '../../constants';
import { useActiveWeb3React } from "../../hooks";
import { additionInfo, createFairlaunch, createFairlaunchSelector, fairlaunchInfo, verifyToken } from "../../redux/slicers/createFairlaunchSlice";
import { convertToWei } from '../../contracts/utils';
import web3 from "web3";
import { Store } from 'react-notifications-component';
import addNotify from '../commons/Notify/addNotify';
import { formatDate } from '../../utils/formatDate';
import Countdown, { zeroPad } from 'react-countdown';
import { getCurrencyAddress, getCurrencyDecimals, getLiquidityTokens, getMainCurrencyDecimals, getPresaleTokens } from "./ultils";
import { getAppSettings, settingSelector } from '../../redux/slicers/settingSlice';
import { formatNumber, formatNumberAmount } from '../../utils/formatNumber';
import axios from 'axios';
import { tokenInformation } from '../../contracts/token';
import { useSearchParams } from 'react-router-dom';
import { fairlaunchCreate } from '../../contracts/fairlaunch';

ChartJS.register(ArcElement, Tooltip, Legend);

interface Size {
    width: number;
    height: number;
}

interface vestingTeam {
    totalTeamToken: string,
    dayReleaseAfterList: string,
    percentTokenTGE: string ,
    vestingCycle: string ,
    tokenReleaseCycle: string
} 

const chartOptions = {
    plugins: {
        tooltip: {
            callbacks: {
                label: function(tooltipItem:any) {
                    return ' '+tooltipItem.parsed+'% '+tooltipItem.label;
                }
            }
        }
    },
}
interface ITokenCreateForm {
    setActiveKey: any,
} 

declare const window: Window & typeof globalThis & { ethereum: any };

const FairLaunchFinish: React.FC<ITokenCreateForm>  =(props: ITokenCreateForm) => { 
    const {chainId, account} = useActiveWeb3React();
    let provider= "";
    if(chainId !== 1 && chainId){
        provider = RPC_URL_CONVERT[chainId];
    }
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [copied, setCopied] = useState<boolean>(false); 
    const [totalLiquidity, setTotalLiquidity] = useState<number>(0);  
    const [totalPresale, setTotalPresale] = useState<number>(0);
    const [actionStatus, setActionStatus] = useState("");
    const [bnbBalance, setBnbBalance] = useState<number>(0);
     const [dexExchanges, setDexExchanges] = useState<any>([]);
    const [chainDecimals, setChainDecimals] = useState<number>(0);
    const [priceBNB, setPriceBNB] = useState<any>();
    const [tokenID, setTokenID] = useState<number>(0);
    const [tokenInfos, setTokenInfos] = useState<any>();
    const [createPoolFee, setCreatePoolFee] = useState<any>(0);
    const [loadingPage, setLoadingPage] = useState<boolean>(false);
    const [launchpadRaisedFee, setLaunchpadRaisedFee] = useState<any>(0);
    const [totalTokenFee, setTotalTokenFee] = useState<number>(0);
    const [launchpadTokenRaisedFee, setLaunchpadTokenRaisedFee] = useState<any>(0);

    const { TabPane } = Tabs;
    const size: Size = useWindowSize();  
    const dispatch = useAppDispatch(); 
    const { 
        additionInfoStorage, 
        fairlaunchInfoStorage, 
        verifyTokenStorage 
    } = useAppSelector(createFairlaunchSelector);  
    
    const { settings } = useAppSelector(settingSelector); 
    const tokenContract = useTokenContract(verifyTokenStorage && verifyTokenStorage.tokenAddress);
    
    const [searchParams, setSearchParams] = useSearchParams();
    let urlTokenAddress = searchParams.get("token");
    let typeLaunchPad = searchParams.get("type");
    const launchpadDeployAddress = chainId && SMART_CONTRACT_ADDRESS[chainId]?.DEPLOY_FAIRLAUNCH_ADDRESS;
    const launchpadDeployContract = useFairlaunchDeployContract(launchpadDeployAddress);
    let navigate = useNavigate();
    
    const addressTokenEllipsis = `${verifyTokenStorage?.tokenAddress?.substring(0, 4)}...${verifyTokenStorage?.tokenAddress?.substring(verifyTokenStorage?.tokenAddress.length - 4)}`;
    const chartPresale = totalPresale * 100/verifyTokenStorage?.totalSupply;
    
    const chartLiquidity = totalLiquidity * 100/verifyTokenStorage?.totalSupply;
    const chartUnlocked = 100 - (chartPresale + chartLiquidity); 
    
    const data = {
        labels: ['Presale', 'Liquidity', 'Unlocked'],
        datasets: [
            {
                label: '',
                data: [chartPresale, chartLiquidity, chartUnlocked],
                backgroundColor: [
                    '#01058A',
                    '#012CB5',
                    '#1BA9EA',
                ],
                borderColor: [
                    '#01058A',
                    '#012CB5',
                    '#1BA9EA',
                ],
                borderWidth: 1,
            },
        ]
    };

    useEffect(() => {
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    }); 

    useEffect(()=> {
        const payload:any = {
            "page": 0,
            "size": 100
        }
        dispatch(getAppSettings(payload));
    }, []);
 
    useEffect(() => {
        axios
        .get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=binancecoin')
        .then((res) => {
            setPriceBNB(res.data[0].current_price);
        });

        if(urlTokenAddress) {
            (async () => {
                await dispatch(getTokenInformByAddress(urlTokenAddress, (res:any) => {
                    if(res?.tokenInform?.id) {
                        setTokenID(res?.tokenInform?.id);
                    }
                }));
            })();
        }
    }, []);

    useEffect(() => {
        if(chainId) {
            const feeSettings = FEE_SETTINGS[chainId];
         
            if (settings && settings.length > 0) { 
                let _initLaunchpadFee = settings.filter((itemSetting) => itemSetting?.settingKey === feeSettings?.INIT_FAIRLAUNCH_FEE)?.[0];  
                setCreatePoolFee(_initLaunchpadFee?.settingValue); 

                if(verifyTokenStorage.feeOptions === feeSettings?.LIST_FAIRLAUNCH_TOKEN_RAISED_FEE_ONLY ) { 
                    let _launchpadRaisedFeeOnly = settings.filter((itemSetting) => feeSettings?.LIST_FAIRLAUNCH_TOKEN_RAISED_FEE_ONLY === itemSetting.settingKey);
                    
                    if(_launchpadRaisedFeeOnly && _launchpadRaisedFeeOnly[0]) {
                        setLaunchpadRaisedFee(_launchpadRaisedFeeOnly[0].settingValue);
                        setLaunchpadTokenRaisedFee(0);
                    }
                } 
                else {
                    let _launchpadTokenRaisedFee = settings.filter((itemSetting) => feeSettings?.LIST_FAIRLAUNCH_AMOUNT_RAISED_FEE === itemSetting.settingKey);
                    if (_launchpadTokenRaisedFee && _launchpadTokenRaisedFee[0]) {
                        setLaunchpadTokenRaisedFee(_launchpadTokenRaisedFee[0].settingValue);
                    }

                    let launchpadAmountRaisedFee = settings.filter((itemSetting) => feeSettings?.LIST_FAIRLAUNCH_TOKEN_RAISED_FEE === itemSetting.settingKey);
                    if (launchpadAmountRaisedFee && launchpadAmountRaisedFee[0]) {
                        setLaunchpadRaisedFee(launchpadAmountRaisedFee[0].settingValue);
                    }
                }
            }
        }
    }, [settings, chainId, verifyTokenStorage?.feeOptions]);

    useEffect(() => {
		if (account) {
            w3.eth.getBalance(account, (err, balance) => {
                if (balance) {
                    let _balance = web3.utils.fromWei(web3.utils.toBN(balance), 'ether');
                    let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
                    setBnbBalance(bnbBl);
                }
                return 0;
            });
		}
	}, [account]);

    useEffect(() => {
        if(chainId) {
            setDexExchanges(DEX_EXCHANGES[chainId]);
            setChainDecimals(getMainCurrencyDecimals(chainId));
        }
    },[chainId]);

    useEffect(() => {
        setLoadingPage(true);
        (async() => {
            await tokenInformation(tokenContract)
            .then((res:any) => {   
                setTokenInfos(res);
                setLoadingPage(false);
            });
        })();
    }, [tokenContract]);

    useEffect(()=> {
        setTotalLiquidity(fairlaunchInfoStorage?.totalToken * fairlaunchInfoStorage?.liquidityPercent/100 * (1 - launchpadRaisedFee/100));
        setTotalPresale(fairlaunchInfoStorage?.totalToken);
        setTotalTokenFee(fairlaunchInfoStorage?.totalToken*launchpadTokenRaisedFee/100);
    }, [fairlaunchInfoStorage, launchpadTokenRaisedFee, launchpadRaisedFee, launchpadTokenRaisedFee]);  

    const _routerSelected = dexExchanges && dexExchanges.length > 0 ? dexExchanges.filter(item => item.id === fairlaunchInfoStorage?.router) : null;
    const routerOjbect = _routerSelected && _routerSelected.length > 0 && _routerSelected[0];
    
    const getTypeLaunchPad = () => {
        let getType;
        if(Number(typeLaunchPad) === 1) {
            getType = 'Presale';
        } else if(Number(typeLaunchPad) === 2) {
            getType = 'Fair Launch';
        }
        else if(Number(typeLaunchPad) === 3) {
            getType = 'Private Sale';
        }
        return getType;
    }

    const infoDetail = <>
    <h3>
        <p> {additionInfoStorage?.name}<span>{getTypeLaunchPad()}</span></p>
        {/* <p>
            <span>Audit</span>
            <span>KYC</span>
            <span>SAFU</span>
        </p> */}
    </h3>
    <ul> 
        {additionInfoStorage?.website ? <li><a href={additionInfoStorage?.website}><img src="../images/detail-icon-website.svg" alt="" /></a></li> : ''}
        {additionInfoStorage?.telegram ? <li><a href={additionInfoStorage?.telegram}><img src="../images/detail-icon-tele.svg" alt="" /></a></li> : ''}
        {additionInfoStorage.twitter ? <li><a href={additionInfoStorage.twitter}><img src="../images/detail-icon-tw.svg" alt="" /></a></li> : ''}
        {additionInfoStorage.medium ? <li><a href={additionInfoStorage.medium}><img src="../images/detail-icon-medium.svg" alt="" /></a></li> : ''}
        {additionInfoStorage.facebook ? <li><a href={additionInfoStorage.facebook}><img src="../images/detail-icon-facebook.svg" alt="" /></a></li> : ''}
        {additionInfoStorage.discord ? <li><a href={additionInfoStorage.discord}><img src="../images/detail-icon-discord.svg" alt="" /></a></li> : ''}
        {additionInfoStorage.youtube ? <li><a href={additionInfoStorage.youtube}><img src="../images/detail-icon-youtube.svg" alt="" /></a></li> : ''}
    </ul>
    </>; 
    
    const handleBack = () =>{
        props.setActiveKey("4"); 
        localStorage.setItem("stepFairLaunch", "4") 
    }
    
    const handleCancel = () => {
        dispatch(fairlaunchInfo({}));
        dispatch(verifyToken({}));
        dispatch(setTokenStorage({}));
        dispatch(additionInfo({}))
        navigate("/launchpad/list");
        localStorage.setItem("stepFairLaunch", "1");
    }

    const rendererTimer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return <span>Done !</span>;
        } else {
          // Render a countdown
          return <div className="c-countDown"><span>{days}d</span>:<span>{zeroPad(hours)}h</span>:<span>{zeroPad(minutes)}m</span>:<span>{zeroPad(seconds)}s</span></div>;
        }
    };  
    
    const dispatchCreateLauchpad = async (launchpadAddress:string) => {   

        if(tokenID === 0) {
            await dispatch(getTokenInformByAddress(urlTokenAddress, (res:any) => {
                if(res?.tokenInform && res?.tokenInform?.id) {
                    setTokenID(res?.tokenInform?.id);
                }
            }));
        }

        const payload :any = { 
            "ownerAddress":account?.toLocaleLowerCase(),
            "presaleAddress":launchpadAddress.toLowerCase(),
            "launchpadName": additionInfoStorage?.name,
            "launchpadInfo": additionInfoStorage?.info,
            "bannerUrl": additionInfoStorage?.banner,
            "bannerThumbnail": additionInfoStorage?.bannerThumbnail,
            "logoUrl": additionInfoStorage?.logo,
            "tokenInformId": tokenID,
            "currency": verifyTokenStorage?.currency,
            "feeOptions": verifyTokenStorage?.feeOptions,
            "listingOptions": "AUTO",
            "softCap": fairlaunchInfoStorage?.softCap,
            "audit": false,
            "kyc": false,
            "safu": false,
            "antiRug": false,
            "swapRate": fairlaunchInfoStorage?.swapRate,
            "minBuy": 0,
            "maxBuy": !isNaN(fairlaunchInfoStorage?.settingMaxContribution) ? Number(fairlaunchInfoStorage?.settingMaxContribution) : 0,
            "raisedFee": launchpadRaisedFee ? Number(launchpadRaisedFee) : 0,
            "raisedTokenFee": launchpadTokenRaisedFee ? Number(launchpadTokenRaisedFee) : 0,
            "whiteListOnly": fairlaunchInfoStorage?.whiteListOnly,
            "startTime": fairlaunchInfoStorage?.startTime,
            "endTime": fairlaunchInfoStorage?.endTime,
            "router": fairlaunchInfoStorage?.router,
            "chainId": chainId,
            "listingRate": fairlaunchInfoStorage?.listingRate,
            "liquidityPercent": fairlaunchInfoStorage?.liquidityPercent,
            "liquidityLockup": fairlaunchInfoStorage?.liquidityLockup,
            "socialNetworks": {
                "facebook": additionInfoStorage?.facebook ?? '',
                "twitter":  additionInfoStorage?.twitter ?? '',
                "medium":  additionInfoStorage?.medium ?? '',
                "discord":  additionInfoStorage?.discord ?? '',
                "telegram":  additionInfoStorage?.telegram ?? '',
                "website": additionInfoStorage?.website ?? '',
                "youtube": additionInfoStorage?.youtube ?? ''
            },
            "type": "FAIR",
            "vestingContributor": null
        }
        dispatch(createFairlaunch(payload, async (res:any) => { 
            if(res.result === "SUCCESS") {
                if(res?.data) {
                    // delete all local storage (react persist)  
                    dispatch(fairlaunchInfo({}));
                    dispatch(verifyToken({}));
                    dispatch(setTokenStorage({}));
                    dispatch(additionInfo({}))
                    localStorage.setItem('stepFairLaunch', "1"); 

                    // navigate to launchpad detail
                    navigate('/fairlaunch/' + res?.data?.handle);
                    setActionStatus("SUCCESS");
                    Store.addNotification(addNotify("Fairlaunch created successfully.", 'success'));
                }
            }
            else {
                setActionStatus("");
                if (res?.error?.message) {
                    Store.addNotification(addNotify(res?.error?.message, 'danger'));
                }
            }
        }));
    }
    
    const handleFairlaunchCreate = async () => {  
        //launchpadInfo
        const tokenDecimals = Number(tokenInfos?.decimals);
        const currencyDecimals = getCurrencyDecimals(chainId, verifyTokenStorage?.currency);
        const icoToken = tokenInfos?.tokenAddress;
        const totalIcoToken = convertToWei(totalPresale, tokenDecimals);
       
        if (!icoToken || icoToken.toLowerCase() == ethers.constants.AddressZero.toLocaleLowerCase()) {
            Store.addNotification(addNotify('Invalid Token Address!', 'danger'));
            return;
        }

        const isMaxinvest = !isNaN(fairlaunchInfoStorage?.settingMaxContribution) ? 1 : 0;
        const maxInvest = !isNaN(fairlaunchInfoStorage?.settingMaxContribution) ? convertToWei(fairlaunchInfoStorage?.settingMaxContribution, currencyDecimals) : 0;

        const feeToken = getCurrencyAddress(chainId, verifyTokenStorage?.currency);
        const softCap = convertToWei(fairlaunchInfoStorage?.softCap, currencyDecimals);
        
        if (Number(softCap) === 0) {
            Store.addNotification(addNotify('Invalid SoftCap Setting!', 'danger'));
            return;
        }

        const startTime = Math.floor((new Date(fairlaunchInfoStorage?.startTime).getTime()) / 1000);
        const endTime = Math.floor((new Date(fairlaunchInfoStorage?.endTime).getTime()) / 1000);

        if (startTime > endTime) {
            Store.addNotification(addNotify('Invalid Time Setting!', 'danger'));
            return;
        }
        const affiliate = verifyTokenStorage?.percentAffiliate && verifyTokenStorage?.percentAffiliate > 0 ? 1 : 0;
        
        // dexInfo
        const listingPercent = Number(fairlaunchInfoStorage?.liquidityPercent) * 100;
        const lpLockTime = fairlaunchInfoStorage?.liquidityLockup ? Number(fairlaunchInfoStorage?.liquidityLockup) * 60 : 0;
        if (listingPercent && (listingPercent/100 < 0 || listingPercent/100 > 100)) {
            Store.addNotification(addNotify('Invalid Listing Percent Setting!', 'danger'));
            return;
        }

        // feeInfo
        const initFee = convertToWei(createPoolFee, chainDecimals);
        const raisedFeePercent = (Math.floor(launchpadRaisedFee ? Number(launchpadRaisedFee) : 0) * 100);
        const raisedTokenFeePercent = (Math.floor(launchpadTokenRaisedFee ? Number(launchpadTokenRaisedFee) : 0) * 100);
        const penaltyFee = Math.floor(10 * 100);

        const launchpadInfo = [
            totalIcoToken,
            icoToken,
            feeToken,
            softCap,
            isMaxinvest,
            maxInvest,
            startTime,
            endTime,
            affiliate
        ];

        const dexInfo = [
            routerOjbect?.routerAddress, 
            routerOjbect?.factoryAddress,
            listingPercent, 
            lpLockTime
        ];

        const feeInfo = [
            initFee, 
            raisedFeePercent, 
            raisedTokenFeePercent, 
            penaltyFee
        ];
        
        setActionStatus("LOADING");
        await fairlaunchCreate(
            launchpadDeployContract, 
            launchpadInfo, 
            dexInfo, 
            feeInfo, 
            verifyTokenStorage?.percentAffiliate ? verifyTokenStorage?.percentAffiliate * 100 : 0
        ).then((res)=> {
            let resWait:any = res.wait();
            resWait.then((resTransaction:any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    if( resTransaction.logs && 
                        resTransaction.logs.length > 0 && 
                        resTransaction.logs[0].address
                    ) {
                        // Call API to save data from SC
                        dispatchCreateLauchpad(resTransaction.logs[0].address);
                    }
                }
            }).catch((error:any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            });
        }).catch((error:any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, 'danger'));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, 'danger'));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, 'danger'));
                }
            }
        });
    }

    const handleNetwork = (network:any) => {
        let networkName:any = '';
        if(network === 'BSCTESTNET') {
            networkName = <span className="is-network"><img src="../images/icon-bnb.svg" alt="" />BSC TESTNET</span>;
        } else if(network === 'BSC') {
            networkName = <span className="is-network"><img src="../images/icon-bnb.svg" alt="" />Binance Smart Chain</span>;
        } else if(network === 'ETH') {
            networkName = <span className="is-network"><img src="../images/icon-eth.svg" alt="" />Ethereum</span>;
        } else if(network === 'POLY') {
            networkName = <span className="is-network"><img src="../images/icon-polygon.svg" alt="" />Polygon</span>;
        } else if(network === 'ARBTESTNET') {
            networkName = <span className="is-network"><img src="../images/arb-icon.svg" alt="" />ARB TESTNET</span>;
        } else if(network === 'ARB') {
            networkName = <span className="is-network"><img src="../images/arb-icon.svg" alt="" />Arbitrum</span>;
        }

        return networkName;
    }

    return(
        <div className="verifyToken"> 
        <div className='step'>Step 5</div> 
        {copied && <span className="c-noti is-success">Copied</span>}
        <div className="p-launchpadDetail"> 
            <div className="p-launchpadDetail__banner">
                    <img src={additionInfoStorage?.banner ? additionInfoStorage?.banner : "../images/bgDefault.svg"} alt="" />
                <h2 className="p-launchpadDetail__logo">
                    <img src={additionInfoStorage?.logo ? additionInfoStorage?.banner : "../images/logoDefault.svg"} alt="" />  
                   {additionInfoStorage?.name}
                </h2>
            </div>
        
            <div className="p-launchpadDetail__detail">
                <div className="p-launchpadDetail__infoSC"> 
                {size.width <= 767 ? <div className="p-launchpadDetail__info">{infoDetail}</div> : ''}
                <div className={`p-launchpadDetail__status is-Upcoming`}>
                    <h3>
                        <span>Upcoming</span>
                        <Countdown date={fairlaunchInfoStorage?.startTime} renderer={rendererTimer} />
                    </h3>
                    <div className="c-progress">
                        <p className="c-progress__title">Progress (0%)</p>
                        <div className="c-progress__bar"><span style={{width: 0+'%'}}></span></div>
                        <p className="c-progress__txt">0 {verifyTokenStorage?.currency}<span> {fairlaunchInfoStorage?.softCap} {verifyTokenStorage?.currency}</span></p>
                    </div>
                </div>
                <div className={`p-launchpadDetail__boxFixed createLauchpad`}>
                    <p>Sale Type<span>Public</span></p>
                    <p>Current Rate<span>{formatNumberAmount(fairlaunchInfoStorage?.softCap/totalPresale, 0, 20)}</span></p>
                    <p>Soft Cap<span>{formatNumber(fairlaunchInfoStorage?.softCap)} {verifyTokenStorage?.currency}</span></p>
                    <p>Max Contribution<span>{ !isNaN(fairlaunchInfoStorage?.settingMaxContribution) ? formatNumber(fairlaunchInfoStorage?.settingMaxContribution) + " " + verifyTokenStorage?.currency : formatNumber(fairlaunchInfoStorage?.softCap) + " " + verifyTokenStorage?.currency}</span></p>
                    
                </div>
                </div>
                <div className="p-launchpadDetail__content">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Pool Information" key="1">
                            <div className="p-launchpadDetail__info">
                                {size.width <= 767 ? '' : infoDetail}
                                <div className="p-launchpadDetail__boxInfo">
                                    <p className="p-launchpadDetail__row">Start time<span>{formatDate(fairlaunchInfoStorage?.startTime)}</span></p>
                                    <p className="p-launchpadDetail__row">End time<span>{formatDate(fairlaunchInfoStorage?.endTime)}</span></p>
                                    <p className="p-launchpadDetail__row">Network{handleNetwork(verifyTokenStorage?.networkChain)}</p>
                                    <p className="p-launchpadDetail__row">Total token selling amount<span>{!isNaN(totalPresale) ? formatNumber(totalPresale) : 0} {tokenInfos?.symbol}</span></p>
                                    <p className="p-launchpadDetail__row">Total for liquidity<span>{!isNaN(totalLiquidity) ? formatNumber(totalLiquidity) : 0} {tokenInfos?.symbol}</span></p>
                                    <p className="p-launchpadDetail__row">Soft cap<span>{fairlaunchInfoStorage?.softCap} {verifyTokenStorage?.currency}</span></p>
                                    <p className="p-launchpadDetail__row">Max Contribution<span>{ !isNaN(fairlaunchInfoStorage?.settingMaxContribution) ? formatNumber(fairlaunchInfoStorage?.settingMaxContribution) + " " + verifyTokenStorage?.currency : formatNumber(fairlaunchInfoStorage?.softCap) + " " + verifyTokenStorage?.currency}</span></p>
                                    <p className="p-launchpadDetail__row">Liquidity percent<span>{fairlaunchInfoStorage?.liquidityPercent}%</span></p>
                                    <p className="p-launchpadDetail__row">Listing on<span>{fairlaunchInfoStorage?.router ? routerOjbect?.name : "--"}</span></p>
                                    <p className="p-launchpadDetail__row">Liquidity lockup time<span>{formatNumber(fairlaunchInfoStorage?.liquidityLockup)} minutes after pool ends</span></p>
                                </div>
                                <div className="p-launchpadDetail__chart">
                                    <h3>Token Metrics</h3>
                                    <Doughnut data={data} options={chartOptions} />
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tab="About the Project" key="2">
                            <div className="p-launchpadDetail__info">
                                {size.width <= 767 ? '' : infoDetail}
                                <p className="p-launchpadDetail__txt">
                                    {additionInfoStorage?.info}
                                </p>
                            </div>
                        </TabPane>
                        <TabPane tab="Token Information" key="3">
                            <div className="p-launchpadDetail__info">
                                {size.width <= 767 ? '' : infoDetail}
                                <div className="p-launchpadDetail__boxInfo">
                                    <p className="p-launchpadDetail__row">Name<span>{tokenInfos?.tokenName}</span></p>
                                    <p className="p-launchpadDetail__row">Symbol<span>{tokenInfos?.symbol}</span></p>
                                    <p className="p-launchpadDetail__row">Decimals<span>{tokenInfos?.decimals}</span></p>
                                    <p className="p-launchpadDetail__row">
                                        Address
                                        <span>
                                            <a href={`https://arbiscan.io/address/${tokenInfos?.tokenAddress}`}>{addressTokenEllipsis}</a>
                                            <CopyToClipboard text={tokenInfos?.tokenAddress} onCopy={() => setCopied(true)}>
                                            <button><img src="../images/icon-copy.svg" alt="" /></button>
                                            </CopyToClipboard>
                                        </span>
                                    </p>
                                    <p className="p-launchpadDetail__row">Total Supply<span>{formatNumber(tokenInfos?.totalSupply)}</span></p>
                                </div>
                            </div>
                        </TabPane> 
                    </Tabs>
                </div>
            </div>
        </div>
        <div className='btn-verify fixed'>
            <div className="isWidth">
                <Button text='Cancel' type='border'  onClick={()=> handleCancel()}/>
            </div> 
            <div className="m-25 isWidth">
                <ButtonAntd className="c-btn" htmlType="button" onClick={()=>handleBack()}>
                    Previous
                </ButtonAntd>
            </div> 
            <div className="isWidth">
                <Button text='Public' type="blue" 
                    isLoading={actionStatus === "LOADING" ? true : false}
                    disabled={(actionStatus === "SUCCESS" || actionStatus === "LOADING") ? true : false} 
                    onClick={()=> handleFairlaunchCreate()} 
                />
            </div> 
        </div>
        {loadingPage ? <Loading/> :null}
        </div>
    )
}

export default FairLaunchFinish;
