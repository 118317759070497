
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Alert, Form, Input, Select, Radio, DatePicker } from 'antd';
import useActiveWeb3React from '../../../../hooks/useActiveWeb3React';
import { formatUTCDate } from '../../../../utils/formatDate';



declare const window: Window & typeof globalThis & { ethereum: any };

const AirdropInfo = (props) => {
    const { form } = props;
    
    let storageAirdropInfo = window.localStorage.getItem('airdropInfo');
    let storageTokenInfo = window.localStorage.getItem('airdropTokenInfo');
    let airdropInfo;
    let airdropTokenInfo;
    if (storageAirdropInfo) {
        airdropInfo = JSON.parse(storageAirdropInfo);
    }
    if (storageTokenInfo) {
        airdropTokenInfo = JSON.parse(storageTokenInfo);
    }

    console.log('airdropInfo', airdropInfo);

    const [checkUpdate, setCheckUpdate] = useState<boolean>(false);
    const [startTime, setStartTime] = useState<any>("");
    const [endTime, setEndTime] = useState<any>(""); 
    const { chainId } = useActiveWeb3React();
    

    //BNB FEE PERCENT

    const onOkStartTime = (dates) => {  
        console.log('setStartTime: ', dates?.toISOString());
        setStartTime(dates?.toISOString()) 
    }
    const onOkEndTime = (dates) => {  
        console.log('setEndTime: ', dates?.toISOString());
        setEndTime(dates?.toISOString()) 
    }

    useEffect(() => {
        if (!checkUpdate) {
            form.setFieldsValue({
                join_link: airdropInfo ? airdropInfo.join_link : '',
                reward: airdropInfo ? airdropInfo.reward : 0,
                start_time: airdropInfo?.start_time ? formatUTCDate(airdropInfo?.start_time) : null,
                end_time: airdropInfo?.end_time ? formatUTCDate(airdropInfo?.end_time) : null,
            })
            setStartTime(formatUTCDate(airdropInfo?.start_time));
            setEndTime(formatUTCDate(airdropInfo?.end_time));
            setCheckUpdate(true);
        }

    }, [airdropInfo]);


    return (
        <>
            <div className="airdrop-step-bg-title">Step 2</div>
            <div className="airdrop-info-form">

               
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    
                    <Form.Item name="step_airdrop_info" initialValue={2} hidden={true}>
                        <Input className="amount-buy" />
                    </Form.Item>
                    
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="start_time" label="Start Time (UTC)" rules={[
                            { required: true, message: `Please input your contribution time` },
                            {
                                validator: (rule, value, cb: (msg?: string) => void) => {
                                    !value || new Date(value) <= new Date(Date.now())
                                        ? cb("Start time needs to be after now")
                                        : cb();
                                }
                            }
                        ]}> 
                            <DatePicker showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onOk={onOkStartTime} />
                        </Form.Item>
                
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="end_time"  label="End Time (UTC)" rules={[
                            { required: true, message: `Please input your contribution time` },
                            {
                                validator: (rule, value, cb: (msg?: string) => void) => {

                                    if (!value || new Date(value) <= new Date(Date.now())) {
                                        cb("End time needs to be after now time");
                                    } else if (new Date(value) <= form.getFieldValue('start_time')) {
                                        cb("End time needs to be after start time");
                                    } else {
                                        cb();
                                    }
                                }
                            }
                        ]}> 
                            <DatePicker showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onOk={onOkEndTime} />{/* <RangePicker showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onChange={onRangeChange}  /> */}
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item 
                            name="join_link" label="Join Link"
                            help={<>It is recommended to create an Airdrop event using <a target="_blank" href="https://gleam.io">Gleam</a> or a <a target="_blank" href="https://web.telegram.org/k/">Telegram</a> bot. Add a link here for users to participate easily.</>}
                        >
                            <Input className="inputForm" type="text" placeholder="Enter link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item 
                            name="reward" label="Reward"
                        >
                            <Input className="inputForm" type="number" placeholder="0" />
                        </Form.Item>
                    </Col>
                   
                </Row>
            </div>
        </>
    )
}

export default AirdropInfo
