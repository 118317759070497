import React from "react";

const ComingSoonPage = () => {
    return(
        <div className="p-comingSoon">
            <div className="p-comingSoon__box">
                <h2>Coming Soon</h2>
                <p>We are bringing new technologies to our community.</p>
                <img src={window.location.origin+ '/images/home/empty.png'} alt="Coming Soon" />
            </div>
        </div>
    )
}

export default ComingSoonPage;