import { PayloadAction } from "@reduxjs/toolkit";
import http from "./http-common";

const LiquidityService = {
    getLiquidities (payload:PayloadAction) {
        return http.get("/token-informs/my-liquidity", { params: payload });
    },  
    createLiquidities (payload:PayloadAction) {
        return http.post("/token-informs/create-lp",  payload);
    },  
    getLiquidityByAddress (handle:any) {
        return http.get(`/token-informs/by-token-lp/${handle}`);
    }, 
};
export default LiquidityService;