import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import BannerHomeService from "../services/bannerHomeService";

// ================================================
// ==                  ACTION                    ==
// ================================================
export const getBannerHome = () => {
  return async dispatch => {
      dispatch(setLoading(true));
      await BannerHomeService.getBannerHome()
      .then((res)=>{
          dispatch(setLoading(false));
          dispatch(setBannerHome(res.data));
      })
      .catch((error:any)=>{
          dispatch(setErrors(error));
          dispatch(setLoading(false));
      });
  }
}
// ================================================
// ==                REDUX STORE                 ==
// ================================================
type BannerHomeObject = {
  [key: string]: void | string | number | any;
};

export interface BannerHomeState {
    bannersHome: BannerHomeObject[];
    bannerHomeLoading: boolean;
    bannerHomeErrors: string;
}

const initialState: BannerHomeState = {
  bannersHome: [],
    bannerHomeLoading: false,
    bannerHomeErrors: "",
}

const bannerHomeSlice = createSlice({
  name: "bannerHome",
  initialState,
  reducers: {
    setBannerHome: (state, { payload }: PayloadAction<BannerHomeObject[]>) => {
      state.bannersHome = payload
    },

    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.bannerHomeLoading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.bannerHomeErrors = payload
    },

  },
})

export const { setBannerHome, setLoading, setErrors } = bannerHomeSlice.actions

export default bannerHomeSlice.reducer

export const bannerHomeSelector = (state: { bannerHomeStore: BannerHomeState }) => state.bannerHomeStore