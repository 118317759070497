import { DownOutlined, LeftOutlined } from '@ant-design/icons';
import { Col, Input, Row, Tabs } from 'antd';
import { useEffect, useRef } from 'react';
import useWindowSize from '../../../../hooks/useWindowSize';
import BuyBlock from './BuyBlock';
import ClaimBlock from './ClaimBlock'; 
interface Size {
    width: number;
    height: number;
}


const DividendsSection = (props: any) => { 
    const {roundDetail, authToken, tokenDecimal} = props;
    const now = new Date();  
    const size: Size = useWindowSize(); 
    
   
    return (  
        <div className='dividends-block'>
            <div className={size.width < 700 ? "" : "mb-15 mt-20"}>
                <Row>   
                    <Col xs={24} sm={10} md={10} lg={10} xl={10}></Col>
                    <Col xs={24} sm={4} md={4} lg={4} xl={4}></Col>
                    <Col xs={24} sm={9} md={9} lg={9} xl={9} className="text-center"> 
                        <div className= 'bls-round-center down-arrow' >
                            <div className='icon-arrow '> 
                                <img src='../images/gif_arrow.gif'/>
                            </div>
                        </div> 
                    </Col>
                </Row>
            </div>
            <div className='dividends-buyclaim'>
                <Row className='buyclaim'>   
                    <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                        <div className='dividends-left block-align-right text-center'>
                            <h3>Dividends</h3>
                            <p className='text-center mb-15'>
                                Most of the earnings generated by the platform are shared among users who allocate xBLS tokens to the 
                                Dividends plugin.<br></br>
                                

                            </p>
                            <ul>
                                <li><a className='footer-social-item' target="_blank" href='https://t.me/BlueSaleFinance'><img className="img-1" src="../images/telegram-active.svg" /></a></li>
                                <li><a className='footer-social-item' target="_blank" href='https://discord.io/BlueSale'><img className="img-1" src="../images/discord-active.svg" /></a></li>
                                <li><a className='footer-social-item' target="_blank" href='https://twitter.com/BluesaleBls'><img className="img-1" src="../images/twitter-active.svg" /></a></li>
                            </ul>
                            <div className='mt-15'>
                                <button onClick={(event) => (window.location.href = `/bls/dividends`)} className='btn-main'>Launch App</button>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={4} md={4} lg={4} xl={4} className="text-center">
                        <div className="icon-arrow-global mt-30per">
                            <LeftOutlined/>
                        </div>
                    </Col>
                    <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                        <div className='dividends-right' id="buy-claim-block">
                            {new Date(roundDetail?.startTime) < now ? null :
                            <div className='block-round'>
                                <img src='../images/lock.svg'/>
                                <span className='block-text'>This round hasn’t started yet.</span>
                            </div> } 
                            <div className={ new Date(roundDetail?.startTime) < now ? 'dividends-right-content animate__animated' :'dividends-right-content blur'}> 
                                <Tabs defaultActiveKey="claim" centered className='dividends-tabs'>
                                    <Tabs.TabPane tab="Buy" key="buy">
                                        <BuyBlock roundDetail={roundDetail} authToken={authToken} tokenDecimal={tokenDecimal}/>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Claim" key="claim">
                                        <ClaimBlock roundDetail={roundDetail} authToken={authToken}/>
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>  
   )
}
 
export default DividendsSection;