import { Contract } from 'ethers';

export const createVestingLock = async (
    gsLockContract:Contract | any, 
    owner:string | any | null | undefined, 
    token: any | null | undefined, 
    isLpToken: boolean, 
    amount: any, 
    tgeDate:any, 
    tgeBps:any, 
    cycle:any, 
    cycleBps:any, 
    description:any,
    fee: any) => {
        return await gsLockContract.vestingLock(owner, token, isLpToken, amount, tgeDate, tgeBps, cycle, cycleBps, description, fee);
};

export const createLock = async (
    gsLockContract:Contract | any,
    owner:string | any | null | undefined, 
    token: any | null | undefined, 
    isLpToken: boolean, 
    amount: any, 
    unlockDate: any, 
    description: any,
    fee: any) => {
	    return await gsLockContract.lock(owner, token, isLpToken, amount, unlockDate, description, fee);
};

export const editLock = async (
    gsLockContract:Contract | any,
    lockId:any | null | undefined, 
    newAmount: any | null | undefined, 
    newUnlockDate: any | null | undefined) => {
	    return await gsLockContract.editLock(lockId, newAmount, newUnlockDate);
};

export const editLockDescription = async (
    gsLockContract:Contract | any, 
    lockId:any | null | undefined, 
    description: any | null | undefined,) => {
    return await gsLockContract.editLockDescription(lockId, description);
};

export const unLock = async (gsLockContract:Contract | any, lockId:any | null | undefined) => {
	return await gsLockContract.unlock(lockId);
};

export const getWithdrawableTokens = async (gsLockContract:Contract | any, lockId:any | null | undefined) => {
	return await gsLockContract.withdrawableTokens(lockId);
};

export const renounceLockOwnership = async (gsLockContract:Contract | any, lockId:any) => {
	return await gsLockContract.renounceLockOwnership(lockId);
};

export const getNumberOfLPLock = async (gsLockContract:Contract | any) => {
	return await gsLockContract.allLpTokenLockedCount();
};

export const getCumulativeLpTokenLockInfo = async (gsLockContract:Contract | any, start:any, end:any) => {
	return await gsLockContract.getCumulativeLpTokenLockInfo(start, end);
};

//TODO: view LP detail
export const getCumulativeLpTokenLockInfoAt = async (gsLockContract:Contract | any, index:any) => {
	return await gsLockContract.getCumulativeLpTokenLockInfoAt(index);
};

//TODO: List Of Normal Token
export const getAllNormalTokenLockedCount = async (gsLockContract:Contract | any) => {
	return await gsLockContract.allNormalTokenLockedCount();
};

export const getCumulativeNormalTokenLockInfo = async (gsLockContract:Contract | any, start:any, end:any) => {
	return await gsLockContract.getCumulativeNormalTokenLockInfo(start, end);
};

//TODO: view Normal detail
export const getCumulativeNormalTokenLockInfoAt = async (gsLockContract:Contract | any, index:any) => {
	return await gsLockContract.getCumulativeNormalTokenLockInfoAt(index);
};

//TODO: Get my lock LP Token
export const lpLockCountForUser = async (gsLockContract:Contract | any, user:any) => {
	return await gsLockContract.lpLockCountForUser(user);
};

export const lpLocksForUser = async (gsLockContract:Contract | any, user:any) => {
	return await gsLockContract.lpLocksForUser(user);
};

export const lpLockForUserAtIndex = async (gsLockContract:Contract | any, user:any, index:any) => {
	return await gsLockContract.lpLockForUserAtIndex(user, index);
};

//TODO: Get my lock Normal Token
export const normalLockCountForUser = async (gsLockContract:Contract | any, user:any) => {
	return await gsLockContract.normalLockCountForUser(user);
};

export const getNormalLocksForUser = async (gsLockContract:Contract | any, user:any) => {
	return await gsLockContract.normalLocksForUser(user);
};

export const normalLockForUserAtIndex = async (gsLockContract:Contract | any, user:any, index:any) => {
	return await gsLockContract.normalLockForUserAtIndex(user, index);
};

export const totalLockCountForToken = async (gsLockContract:Contract | any, token:any) => {
	return await gsLockContract.totalLockCountForToken(token);
};

export const getLocksForToken = async (gsLockContract:Contract | any, token:any, start:any, end:any) => {
	return await gsLockContract.getLocksForToken(token, start, end);
};

export const cumulativeLockInfo = async (gsLockContract:Contract | any, token:any) => {
	return await gsLockContract.cumulativeLockInfo(token);
};

export const getLockById = async (gsLockContract:Contract | any, lockId:any) => {
	return await gsLockContract.getLockById(lockId);
};

export const getTotalTokenLockedCount = async (gsLockContract:Contract | any) => {
	return await gsLockContract.totalTokenLockedCount();
}; 

export const postRenounceLockOwnership = async (gsLockContract:Contract | any, lockId:any) => {
	return await gsLockContract.renounceLockOwnership(lockId);
};

export const claimTokenLock = async (gsLockContract:Contract | any, lockId:any) => {
	return await gsLockContract.unlock(lockId);
};

export const getFeeLock = async (gsLockContract:Contract | any) => {
	return await gsLockContract.fee();
};

export const getLockOwner = async (gsLockContract:Contract | any) => {
	return await gsLockContract.owner();
};
