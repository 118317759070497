import { Tokens } from '../../constants';
import refreshToken from './refreshToken';

const axiosInterceptor = {
    setupInterceptors: (axios:any, isToken = false, isFormData = false) => {
        axios.interceptors.request.use((config:any) => {
                if (isToken) {
                    return config;
                } 
                let token = localStorage.getItem(Tokens.AUTHUSER);
                if (token) {
                    config.headers = {
                        ...config.headers,
                        authorization: `Bearer ${token}`,
                    };
                }
                
                if (isFormData) {
                    config.headers['Content-Type'] = 'multipart/form-data';
                }
                return config;
            },
            (error: any) => {
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            (response: any) => {
                return response;
            },
            async (error:any) => {
                const config = error?.config;
    
                if (error?.response?.status === 403 && !config._retry) {
                    config._retry = true;
                    await refreshToken()
                    .then((res:any) => {
                        config.headers = {
                            ...config.headers,
                            authorization: `Bearer ${res}`,
                        };

                        return axios(config);
                    })
                    .catch((err:any) => {
                        return Promise.reject({ ...error })
                    });
                }
                return Promise.reject({ ...error })
            }
        );
    }
};

export default axiosInterceptor;