import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import SettingService from "../services/SettingService";

// ================================================
// ==                  ACTION                    ==
// ================================================
export const getAppSettings = (payload:PayloadAction) => {
    return async dispatch => {
        dispatch(setLoading(true));
        
        await SettingService.getAppSettings(payload)
        .then((res)=>{
            dispatch(setLoading(false));
            dispatch(setAppSettings(res.data));
        })
        .catch((error:any)=>{
            // dispatch(setErrors(error));
            dispatch(setLoading(false));
        });
    }
}

// ================================================
// ==                REDUX STORE                 ==
// ================================================

type SettingObject = {
  [key: string]: void | string | number | any;
};

export interface SettingState {
    settings: SettingObject[];
    loading: boolean;
    errors: string;
}

const initialState: SettingState = {
    settings: [],
    loading: false,
    errors: "",
}

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setAppSettings: (state, { payload }: PayloadAction<SettingObject[]>) => {
      state.settings = payload
    }
  },
})

export const { setLoading, setErrors, setAppSettings } = settingSlice.actions

export default settingSlice.reducer

export const settingSelector = (state: { settingStore: SettingState }) => state.settingStore; 