import { ArrowRightOutlined, DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Progress, Row } from 'antd';
import { useState, useEffect } from 'react'; 
import { Line } from 'react-chartjs-2';
import {CategoryScale} from 'chart.js'; 
import Chart from 'chart.js/auto';

Chart.register(CategoryScale);
const VestingSchedule = () => {
    // const labels = ['0', '10', '20', '30'];
    // const data = {
    //     labels,
    //     datasets: [
    //         {
    //             label: 'Public',
    //             data: [10, 20 ,30, 10],  
    //             borderColor: 'rgba(158, 166, 224, 1)',
    //             backgroundColor: 'rgba(158, 166, 224, 1)',
    //             fill:true
    //         },
    //         {
    //             label: 'Private (xBLS)',
    //             data: [11, 21, 31, 11],  
    //             borderColor: 'rgba(71, 83, 176, 0.5)',
    //             backgroundColor: 'rgba(71, 83, 176, 0.5)',
    //             fill:true
    //         },
    //         {
    //             label: 'Public',
    //             data: [12, 22, 32, 12],  
    //             borderColor: 'rgba(101, 112, 204, 1)',
    //             backgroundColor: 'rgba(101, 112, 204, 1)',
    //             fill:true
    //         },
    //         {
    //             label: 'Public (xBLS)',
    //             data: [13, 23, 33, 13],  
    //             borderColor: '#1C85CA',
    //             backgroundColor: '#1C85CA',
    //             fill:true
    //         },   
    //         {
    //             label: 'Public',
    //             data: [14, 24, 34, 14],  
    //             borderColor: '#624E8E',
    //             backgroundColor: '#624E8E',
    //             fill:true
    //         },
    //                 {
    //             label: 'Public',
    //             data: [15, 25, 35, 15],  
    //             borderColor: '#974C8D',
    //             backgroundColor: '974C8D',
    //             fill:true
    //         },
    //                 {
    //             label: 'Public',
    //             data: [16, 26, 36, 16],  
    //             borderColor: '#E8406F',
    //             backgroundColor: '#E8406F',
    //             fill:true
    //         },
    //         {
    //             label: 'Public',
    //             data: [17, 27, 37, 17],  
    //             borderColor: '#52A5A3',
    //             backgroundColor: '#52A5A3',
    //             fill:true
    //         },
    //         {
    //             label: 'Public',
    //             data: [18, 28, 38, 18],  
    //             borderColor: ' #2FB7F2',
    //             backgroundColor: ' #2FB7F2',
    //             fill:true
    //         },
    //         {
    //             label: 'Public',
    //             data: [19, 29, 39, 19],  
    //             borderColor: '#F6BA33',
    //             backgroundColor: '#F6BA33',
    //             fill:true
    //         },
    //         {
    //             label: 'Public',
    //             data: [20, 30, 40, 20],  
    //             borderColor: '#F68533',
    //             backgroundColor: '#F68533',
    //             fill:true
    //         },
    
    //     ]
    // };
    // const options = {
    //     responsive: true,
    //     plugins: {
    //         legend: {
    //         position: 'top' as const,
    //         },
    //         title: {
    //         display: true, 
    //         },
    //     },
    // };
    return (
        <>
         <div className='container'>
            {/* <div className="vestingschedule-block">
                <h3>Vesting schedule</h3>
            </div> */}
            {/* <Line options={options} data={data} /> */}
            <div className='vesting-block'>
                <h3>Vesting schedule</h3>
                
                <div className='vesting-chart'>
                    <img src='../images/vesting-img.svg' />
                    <div className='vesting-chart-info'>
                        <ul>
                            <li>
                                <div className='color-chart-info' style={{background: '#9EA6E0'}}></div>
                                <div className='name-chart-info'>Private</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#4753B0'}}></div>
                                <div className='name-chart-info'>Private (xBLS)</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#6570CC'}}></div>
                                <div className='name-chart-info'>Public</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#1C85CA'}}></div>
                                <div className='name-chart-info'>Public (xBLS)</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#624E8E'}}></div>
                                <div className='name-chart-info'>Protocol Grow</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#974C8D'}}></div>
                                <div className='name-chart-info'>Reserves</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#E8406F'}}></div>
                                <div className='name-chart-info'>Marketing+ Airdrop</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#52A5A3'}}></div>
                                <div className='name-chart-info'>Liquidity</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#2FB7F2'}}></div>
                                <div className='name-chart-info'>Development</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#F6BA33'}}></div>
                                <div className='name-chart-info'>Boost Pool</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#F68533'}}></div>
                                <div className='name-chart-info'>Core contributors</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>  
        </> 
   )
}
 
export default VestingSchedule;