
import axios from "axios";
import http from "./http-common";
import { instance } from '../../apiBase/instance';

const AirdropService = {
    getAuthToken (account:any) {
        return http.post("/authenticate/address", { 
            address: account
        });
    },
    
    getAirdropList (data:any) {
        let params = {
			page: data.page,
			size: data.size,
			sort: data.sort
		}
		return instance.post<any>(`airdrop/filter`, data, { params });
        // return instance.get(`/airdrop/filter`, { 
        //     headers:{
        //         "Authorization": `Bearer ${authToken}`,
        //     }
        // });
    },

    createAirDrop(data:any, authToken:any) {
		return instance.post<any>(`airdrops/create`, data, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
    updateAirDrop(id:any, data:any, authToken:any) {
		return instance.put<any>(`airdrops/${id}`, data, {
			headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},

    getAirdropDetail(handle) {
        console.log('handle', handle);
		return instance.get<any>(`airdrop/by-handle/${handle}` , {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},

	cancelAirDrop(id, authToken) {
        let data:any = null;
		return instance.post<any>(`airdrops/cancel/${id}`, data, {
            headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	},
    startAirDrop(id, data, authToken) {
		return instance.post<any>(`airdrops/start/${id}`,  data , {
            headers:{
                "Authorization": `Bearer ${authToken}`,
            }
		});
	}
    
};
export default AirdropService;