import React, { useCallback, useEffect, useState } from 'react';
import { DownOutlined, LeftOutlined, LoadingOutlined, LogoutOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Modal, Progress, Row, Spin } from 'antd';
import { createBrowserHistory } from 'history';
import { useContract } from "../../../hooks/useContract";
import TokenClaimPublicSaleAbi from "../../../contracts/abi/TokenClaimPublicSale.json";
import web3 from 'web3';
import { ethers } from "ethers";
import { convertDateTime } from '../formatDatetime';
import { Store } from 'react-notifications-component';
import addNotify from '../../../components/commons/Notify/addNotify';

import {
  _tokenBalance,
  _getSymbolToken,
  _claimTokensPublicSale,
  _isClaimedSC,
  _totalClaimed

} from "../airdrop-utils";
import useActiveWeb3React from '../../../hooks/useActiveWeb3React';
import { MAPPING_CHAINID_DECIMAL } from '../../../constants';
import BluesaleRoundsService from '../../../redux/services/bluesaleRoundsService';

declare const window: Window & typeof globalThis & { ethereum: any };

const ClaimAirdrop = (props) => {  
    const {roundDetail, authToken} = props;
    const {chainId, account} = useActiveWeb3React();
    const[isLoading, setIsLoading] = useState<any>(false);
    const[isClaimedBLS, setIsClaimedBLS] = useState<any>(false);
    const[totalClaimed, setTotalClaimed] = useState<any>(0);

    let roundChain:any = roundDetail?.networkChain.toLowerCase();
    const chainIdWallet:any = chainId;
    const roundChainId:any = MAPPING_CHAINID_DECIMAL[roundChain];

    let allocation:any = roundDetail?.whitelist?.allocations[0];
 
    let claimTime:any = new Date(roundDetail?.startClaim);

    const claimBLSContract = useContract(roundDetail?.addressClaim, TokenClaimPublicSaleAbi);

    const checkIsClaimed = async () => {
        if(claimBLSContract && account){
           let res = await _isClaimedSC(claimBLSContract, account);
           setIsClaimedBLS(res);
        }
    };
    const getTotalClaimed = async () => {
        if(claimBLSContract && account){
           let res = await _totalClaimed(claimBLSContract, account);
           let total:any = ethers.utils.formatUnits(res, 18);
           console.log('total', total);
           setTotalClaimed(total);
        }
    };

    useEffect(() => {  
        if(claimBLSContract && account){
            checkIsClaimed();
            getTotalClaimed();
        }
    }, [roundDetail, claimBLSContract, account])

    const handleClaim = (claimItem) => {

        // setTokenClaim(value?.symbol);
         setIsLoading(true);  

         if(!allocation){
            Store.addNotification(addNotify('Not found allocation', 'danger'));
            setIsLoading(false);
            return;  
         }
        
        (async () => {
            await _claimTokensPublicSale(claimBLSContract, claimItem, roundDetail) 
            .then((res:any) => {
                let resWait:any = res.wait();
                resWait.then((resTransaction:any) => {
                    setIsLoading(false);
                    if (resTransaction && resTransaction.status && resTransaction.blockNumber) { 
                        const payload = {
                            "whiteListId": roundDetail?.whitelist?.id,
                            "allocationId": claimItem?.id,
                            "txn": resTransaction?.transactionHash
                        };
                        BluesaleRoundsService.updateClaim(payload, authToken).then((res) => {
                            if(res){
                                Store.addNotification(addNotify('Claim successfully', 'success')); 
                                if(claimBLSContract && account){
                                    checkIsClaimed();
                                }
                                setTimeout(() => {
                                    window.location.reload();
                                }, 3000);
                            } 
                            setIsLoading(false);
                            
                        }).catch((error)=>{
                            Store.addNotification(addNotify(error.message, 'danger'));
                        });
                        
                    } else {
                        Store.addNotification(addNotify('Claim failed', 'danger'));
                    }
                })
                .catch((error:any) => {
                    setIsLoading(false);
                    if(error.data){
                        Store.addNotification(addNotify(error.data.message, 'warning'));
                    }            
                });
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
                if (error) {
                    if (error.code === 4001 && error.message) {
                        Store.addNotification(addNotify(error.message, 'danger'));
                    } else if (error.reason) {
                        Store.addNotification(addNotify(error.reason, 'danger'));
                    } else {
                        if (error.data && error.data.message) {
                            Store.addNotification(addNotify(error.data.message, 'danger'));
                        }
                    }
                }
            
            });
        })();  
    } 

    return (
        <>
            <div className='bls-price-info' id='round-selected'>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                        <div className='bls-price'>
                            <span>Allocation:</span>
                            <span>{allocation? allocation?.tokenAmount : 0} {allocation? allocation?.symbol : 'BLS'}</span>
                            
                        </div>
                    </Col> 
                    <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                        <span className='text-round'>KOLs & Airdrop</span> 
                    </Col>
                </Row>
            </div>
            <div className='bls-round-selected-content'>
                <div className='bls-sold-info p-20 text-center'>
                    {roundDetail?.whitelist === null ? (
                        <>
                            <div className='round-name join-text text-danger text-center'>
                                <div className='mt-10'>
                                    <img src='../images/icon-soldout.svg' />
                                </div>
                                <div className='mt-10'>
                                    You're not whitelist
                                </div>
                            </div>
                        </>
                    ):(
                        <>
                            {parseInt(chainIdWallet) === parseInt(roundChainId) && (
                                <>
                                    {isClaimedBLS ? (
                                        <>
                                            <button className='btn-main' disabled>Claimed</button>
                                        </>
                                    ):(
                                        <>
                                            <button className='btn-main' 
                                                onClick={()=>handleClaim(allocation)}
                                                disabled={isLoading || !allocation || allocation?.signClaim === ""}
                                            >
                                                Claim Airdrop {isLoading && ( <Spin className="style-loading purple-text" size="small" />)}
                                            </button>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    
                    
                </div>
                <div className='bls-sold-timing p-20'>
                    {totalClaimed > 0 && (<p>Your claimed: <span> { totalClaimed ? totalClaimed.toLocaleString() : 0 } {allocation? allocation?.symbol : 'BLS'}</span></p>)}
                    <p>Claim start: <span> {roundDetail?.startClaim ? convertDateTime(claimTime) : 'TBA' }</span></p>
                </div>
            </div>
        </>
   )
}
 
export default ClaimAirdrop;