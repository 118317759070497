import Web3 from 'web3';
import { HttpProviderOptions } from 'web3-core-helpers';
import { MAINNET_BSC_URL, TESTNET_BSC_URL } from '../constants';

const bscNetworkUrl = process.env.REACT_APP_BSC_NETWORK === "MAINNET" ? MAINNET_BSC_URL : TESTNET_BSC_URL;
const httpProvider = new Web3.providers.HttpProvider(bscNetworkUrl || '', { timeout: 10000 } as HttpProviderOptions);
const web3NoAccount = new Web3(httpProvider);

const getWeb3NoAccount = () => {
  return web3NoAccount;
}

export { getWeb3NoAccount }
export default web3NoAccount
