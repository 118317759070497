import web3 from 'web3';
import { ethers } from 'ethers';
import { getContract } from '../../../src/utils';
import { convertToWei } from '../../contracts/utils';

export const UINT256_MAX = '115792089237316195423570985008687907853269984665640564039457.584007913129639935';
export const convertWeiToToken = (value, decimal) => {
	return value.toString() / (10 ** decimal)
}


export const buyTokenSale = async (joinSC, amount, wlId, roundId, sig, decimals) => {
	// let amountWei = web3.utils.toWei(`${amount}`, 'ether');
	let amountWei = ethers.utils.parseUnits(`${amount}`, decimals);
	const transaction = await joinSC.join(amountWei, wlId, roundId, sig);
	return transaction;
};

export const _isJoined = async (joinSC, account, roundId) => {
	const transaction = await joinSC.isJoined(account, roundId);
	return transaction;
};
export const _totalJoined = async (joinSC) => {
	const transaction = await joinSC.totalJoined();
	return transaction;
};

export const gettotalJoinedItem = async (tokenSCAbi, tokenAddressVal) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		const totalJoined = await contract.totalJoined();
		return totalJoined;
	}
};

export const _userJoined = async (joinSC, account, roundId) => {
	const userJoin = await joinSC.userJoinAmount(account, roundId);
	return userJoin;
}; 


export const approveToken = async (tokenSCAbi, tokenAddressVal, library, account, spender) => {
	const args = [spender, web3.utils.toBN(2).pow(web3.utils.toBN(256)).sub(web3.utils.toBN(1)).toString()];
	const contract = getContract(tokenAddressVal, tokenSCAbi, library, account);

	const transaction = await contract.approve(...args);
	return transaction;
};

export const getTokenAllowance = async (tokenSCAbi, tokenAddressVal, account, spender) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		const allowance = await contract.allowance(account, spender);
		return allowance;
	}
};
export const _getSymbolToken = async (tokenContract) => {
	const symbol = tokenContract && await tokenContract.symbol();
	return symbol;
};

export const _getDecimalToken = async (tokenContract) => {
	const decimal = tokenContract && await tokenContract.decimals();
	return decimal;
};

export const _tokenBalance = async (tokenContract, account) => {
	const res = tokenContract && await tokenContract.balanceOf(account);
	return res;
}


export const claimTokensPublicSale = async (claimContract, claimItem, roundDetail) => { 
  console.log(roundDetail);
  console.log("claimItem", claimItem)
  const amountTemp = convertToWei(claimItem.tokenAmount, 18);  
  const args = [amountTemp, roundDetail.id, claimItem.signClaim];  

  console.log("claimItem", args)
  const gas = await claimContract.estimateGas.claimTokens(...args);
  return claimContract.claimTokens(...args, { gasLimit: gas });
}

export const _isRefundedSC = async (claimContract, account) => {
  return claimContract.userRefund(account.toLowerCase());
}

export const _isClaimedSC = async (claimContract, account) => {
  return claimContract.isClaimed(account.toLowerCase());
}

export const remainingFunds = async (claimContract, claimItem) => { 
	console.log("claimItem", claimItem)
	const amount = convertToWei(claimItem?.whitelist?.remainingFund, 6); 
  	
	const args = [amount, claimItem?.whitelist?.signatureRemainingFund];

	console.log("claimItem", args)
	const estimatedGas = await claimContract.estimateGas.refund(...args);
	return claimContract.refund(...args, {gasLimit: estimatedGas});  
}
