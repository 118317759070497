import { DownOutlined, LeftOutlined } from '@ant-design/icons';
import { Col, Input, Row, Tabs , Spin} from 'antd'; 
import { useEffect, useState } from 'react';
import { BLS_PUBLICSALE } from '../../../../../constants';
import { claimTokensPublicSale, remainingFunds, _isClaimedSC, _isRefundedSC } from '../../../public-sale-utils';
import { useActiveWeb3React } from '../../../../../hooks';
import { useClaimPublicContract } from "../../../../../hooks/useContract";
import { Store } from 'react-notifications-component';
import addNotify from '../../../../../components/commons/Notify/addNotify';
import BluesaleRoundsService from '../../../../../redux/services/bluesaleRoundsService';


const ClaimBlock = (props: any) => { 
    const {chainId, account} = useActiveWeb3React();
    const {roundDetail, authToken} = props; 

    const BLSAddress = roundDetail && roundDetail?.whitelist?.allocations?.filter(element => element.symbol === "BLS")[0]?.contractAddress;
    const xBLSAddress = roundDetail && roundDetail?.whitelist?.allocations?.filter(element => element.symbol === "xBLS")[0]?.contractAddress;
    //call api get contract address
    const claimBLSContract = useClaimPublicContract(BLSAddress);
    const claimxBLSContract = useClaimPublicContract(xBLSAddress);
    const claimRemainingFundContract =  useClaimPublicContract(roundDetail && roundDetail?.addressRemaningFund);

    const [isLoading, setIsLoading] = useState<any>(false); 
    const [isLoadingRemainning, setIsLoadingRemainning] = useState<any>(false); 
    const [isClaimBLS, setIsClaimBLS] = useState<any>(false); 
    const [isClaimxBLS, setIsClaimxBLS] = useState<any>(false); 
    const [tokenClaim, setTokenClaim] = useState<any>(''); 
    
    const [isClaimedRemaining, setIsClaimedRemaining] = useState<any>(false)
   
    useEffect(() => {
        if (account && claimRemainingFundContract) {
            _isRefundedSC(claimRemainingFundContract, account).then((res) => {
                setIsClaimedRemaining(res);
            });
        } 
    }, [account, claimRemainingFundContract]); 
    

    useEffect(() => {  
        _isClaimedSC(claimBLSContract, account).then((res) => {
            console.log("claimBLSContract",res);
            setIsClaimBLS(res);
        }); 
    
        _isClaimedSC(claimxBLSContract, account).then((res) => {
            console.log("claimxBLSContract",res);
            setIsClaimxBLS(res);
        });
        
    }, [roundDetail])
  
    var startClaimDate = new Date(roundDetail?.startClaim);
    var expiredDate = startClaimDate;
    expiredDate.setHours(startClaimDate.getHours() + 12);
  
    const nowDate = new Date();
     
    console.log("expiredDate",expiredDate.toUTCString());
    console.log("nowDate",nowDate.toUTCString())
    const handleClaim = (value) => {
        setTokenClaim(value?.symbol);
         setIsLoading(true);  
        (async () => {
            await claimTokensPublicSale(value?.symbol === "BLS" ? claimBLSContract : claimxBLSContract, value, roundDetail) 
            .then((res:any) => {
                let resWait:any = res.wait();
                resWait.then((resTransaction:any) => {
                    setIsLoading(false);
                    if (resTransaction && resTransaction.status && resTransaction.blockNumber) { 
                        const payload = {
                            "whiteListId": roundDetail?.whitelist?.id,
                            "allocationId": value?.id,
                            "txn": resTransaction?.transactionHash
                        };
                        BluesaleRoundsService.updateClaim(payload, authToken).then((res) => {
                            if(res){
                                  Store.addNotification(addNotify('Claim successfully', 'success')); 
                            } 
                            setIsLoading(false);

                            setTimeout(() => {
                                window.location.reload();
                            }, 3000);
                        }).catch((error)=>{
                            Store.addNotification(addNotify(error.message, 'danger'));
                        });
                        
                    } else {
                        Store.addNotification(addNotify('Claim failed', 'danger'));
                    }
                })
                .catch((error:any) => {
                    setIsLoading(false);
                    if(error.data){
                        Store.addNotification(addNotify(error.data.message, 'warning'));
                    }            
                });
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
                if (error) {
                    if (error.code === 4001 && error.message) {
                        Store.addNotification(addNotify(error.message, 'danger'));
                    } else if (error.reason) {
                        Store.addNotification(addNotify(error.reason, 'danger'));
                    } else {
                        if (error.data && error.data.message) {
                            Store.addNotification(addNotify(error.data.message, 'danger'));
                        }
                    }
                }
            
            });
        })();  
    } 


    const handleRemainingFunds = () => { 
        (async () => {
            setIsLoadingRemainning(true);
            await remainingFunds(claimRemainingFundContract, roundDetail)
            .then((res:any) => {
                let resWait:any = res.wait();
                resWait.then((resTransaction:any) => {
                    setIsLoadingRemainning(false);
                    if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                        const payload = {
                            "whiteListId": roundDetail?.whitelist?.id,
                            "roundId": roundDetail?.id,
                            "txn": resTransaction?.transactionHash
                        };
                        BluesaleRoundsService.updateRemaining(payload, authToken).then((rs) => {
                            Store.addNotification(addNotify('Claim remaining successfully', 'success')); 
                            setIsLoadingRemainning(false);

                            setTimeout(() => {
                                window.location.reload();
                            }, 3000);
                        }).catch((error)=>{
                            Store.addNotification(addNotify(error.message, 'danger'));
                        });
                        
                    } else {
                        Store.addNotification(addNotify('Claim failed', 'danger'));
                    }
                })
                .catch((error:any) => {
                    setIsLoadingRemainning(false);
                    if(error.data){
                        Store.addNotification(addNotify(error.data.message, 'warning'));
                    }            
                });
            })
            .catch((error) => {
                setIsLoadingRemainning(false);
                console.log(error);
                if (error) {
                    if (error.code === 4001 && error.message) {
                        Store.addNotification(addNotify(error.message, 'danger'));
                    } else if (error.reason) {
                        Store.addNotification(addNotify(error.reason, 'danger'));
                    } else {
                        if (error.data && error.data.message) {
                            Store.addNotification(addNotify(error.data.message, 'danger'));
                        }
                    }
                }
            
            });
        })();
     } 
    
    return (  
        <>
            <div className='dividends-claim-block'> 
            {roundDetail?.whitelist?.allocations.length > 0 ? 
            roundDetail?.whitelist?.allocations.map((e, i)=>{ 
                return(
                    <div key = {i.toString()}>
                    <span className='dividends-text'>My {e?.symbol}</span>
                        <div className='dividends-claim-token'>
                            <div className="dividends-claim-token-info">  
                                <div className='text-center'>
                                    {e?.symbol === "BLS" ? <img width={50} height={50} src='../images/bls-icon-50.svg'/> :  <img width={50} height={50} src='../images/xbls-icon-50.svg'/>}
                                </div>
                                <div className='text-center d-grid'> 
                                    <span className='font-medium font-24'>{e?.tokenAmount} {e?.symbol}</span>
                                </div> 
                            </div>
                            <div>
                                {e?.symbol === "BLS" && 
                                <button disabled={isClaimBLS || isLoading || isLoadingRemainning || !e.signClaim} onClick={ () => handleClaim(e)} className='btn-main fill'>{isClaimBLS ? "Claimed" :"Claim"}
                                 {isLoading && !isClaimBLS && tokenClaim === "BLS" && (
                                        <Spin className="style-loading" size="small" />
                                    )}</button>}
                                {e?.symbol === "xBLS" && 
                                <button disabled={expiredDate > nowDate || isClaimxBLS || isLoading || isLoadingRemainning|| !e.signClaim } onClick={ () => handleClaim(e)} className='btn-main fill'>{isClaimxBLS ? "Claimed" :"Claim"}
                                 {isLoading && !isClaimxBLS && tokenClaim === "xBLS" && (
                                        <Spin className="style-loading" size="small" />
                                    )}</button>}
                            </div>
                        </div> 
                    </div>
                )
            }): 
                <DefaultClaim/>
            } 
            
            </div>
            <Row className='mt-10 flex just-bw'> 
                <div className=''>Remaining Funds: </div>   
                <div className="flex">
                    <div className='font-medium'>{roundDetail?.whitelist?.remainingFund} USDC <img height={32} width={32} src='../images/usdc-icon-32.svg'/></div>
                   
                    <div className='dividends-buy-button small-btn'>
                        <button disabled={isClaimedRemaining || isLoadingRemainning || isLoading || !roundDetail?.whitelist?.signatureRemainingFund} onClick={() => handleRemainingFunds()} className='btn-main'>
                            {isClaimedRemaining ? "Claimed": "Claim"}
                            {isLoadingRemainning && (
                            <Spin className="style-loading" size="small" />)}
                        </button>
                    </div>
                </div>
            </Row> 
        </>
   )
} 

const DefaultClaim = () => {
    return(
    <>  
        <span className='dividends-text'>My BLS</span>
        <div className='dividends-claim-token'>
            <div className="dividends-claim-token-info">  
                <div className='text-center'>
                    <img width={50} height={50} src='../images/bls-icon-50.svg'/>
                </div>
                <div className='text-center d-grid'> 
                    <span className='font-medium font-24'> -- BLS</span>
                </div> 
            </div>
            <div className='btn-claim'>
                <button disabled className='btn-main'>Claim</button>
            </div>
        </div> 
        <span className='dividends-text'>My xBLS</span>
        <div className='dividends-claim-token'>
            <div className="dividends-claim-token-info">  
                <div className='text-center'>
                    <img width={50} height={50} src='../images/xbls-icon-50.svg'/>
                </div>
                <div className='text-center d-grid'> 
                    <span className='font-medium font-24'> -- xBLS</span>
                </div> 
            </div>
            <div className='btn-claim'>
                <button disabled className='btn-main'>Claim</button>
            </div>
        </div> 
    </> 
    )
}
 
export default ClaimBlock;