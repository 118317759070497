import {Form, Select, Input} from 'antd';

const StandardToken = (props: any)=>{
    const { Option } = Select;  
    return(
        <>
        <div className="form-createToken-item">
            <span className="form-createToken-text">Symbol</span>
            <Form.Item 
                name="symbol"
                rules={[{ required: true, message: 'Please input your symbol!' }]}
                > 
                    <Input placeholder="Ex:ABC" onWheel={ event => event.currentTarget.blur()} className="inputForm"/>
            </Form.Item>
        </div>
        <div className="form-createToken-item">
            <span className="form-createToken-text">Token Name</span>
            <Form.Item 
                name="tokenName"
                rules={[{ required: true, message: 'Please input your tokenName!' }]}
                > 
                    <Input placeholder='Enter name' onWheel={ event => event.currentTarget.blur()} className="inputForm"/>
            </Form.Item>
        </div>
        
        <div className="form-createToken-item">
            <span className="form-createToken-text">Decimals</span>
            <Form.Item 
                name="decimals"
                rules={[{ required: true, message: 'Please input your decimals!' }]}
                > 
                    <Select placeholder="Select decimals">
                        <Option value="6">6</Option>
                        <Option value="9">9</Option> 
                        <Option value="12">12</Option> 
                        <Option value="18">18</Option>
                    </Select>
            </Form.Item>
        </div>
        <div className="form-createToken-item">
            <span className="form-createToken-text">Total Supply</span>
            <Form.Item 
                name="totalSupply"
                rules={[{ required: true, message: 'Please input your Total Supply!' },
                        {validator(_,value) {   
                            if(Number(value) < 0){ 
                                return Promise.reject(`Total supply must larger than 0`)
                            }   
                            return Promise.resolve()}
                        }]}
                > 
                    <Input placeholder="Enter total supply" onWheel={ event => event.currentTarget.blur()}  className="inputForm" type="number" />
            </Form.Item> 
        </div>
        </>
    )
}

export default StandardToken;
