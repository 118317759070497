import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Select, Pagination } from 'antd';
import type { PaginationProps } from 'antd';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getMyLaunchpads, launchPadSelector } from "../../redux/slicers/launchpadSlice";
import ItemLaunchpad from './item';
import { Loading, SearchChange } from '../../components/component';
import { useActiveWeb3React } from '../../hooks';

const MyLaunchpad: React.FC = () => {
    // const { account , chainId} = useActiveWeb3React();
    const [isSort, setIsSort] = useState<boolean>(false);
    const [isTabPager, setIsTabPager] = useState<string>('all');
    const [isPager, setIsPager] = useState<any>(1);
    const [filterStatus, setFilterStatus] = useState<string>('ALL');
    const [filterChain, setFilterChain] = useState<string>('ALL');
    const [filterSort, setFilterSort] = useState<string>('');
    const { Option } = Select;
    const { TabPane } = Tabs;
    const dispatch = useAppDispatch();

    const { myLaunchpads, myLaunchpadsCount, launchpadLoading, launchpadErrors } = useAppSelector(launchPadSelector);

    // useEffect(() => {
    //     const payload:any = {
    //         "page": 0,
    //         "size": 10,
    //         "sort": 'id,desc',
    //         "tab": "all",
    //     }

    //     dispatch(getMyLaunchpads(payload));
    // }, [dispatch]);

    useEffect(() => {
        const payload:any = {
            "ownerAddress": "",
            "status": filterStatus,
            "networkChain": filterChain,
            "extend": filterSort,
            "page": isPager-1,
            "size": 10,
            "sort": 'id,desc',
            "tab": isTabPager,
        }
        dispatch(getMyLaunchpads(payload));
    }, [isPager, filterStatus, filterChain, isTabPager, filterSort]);

    const onChangeStatus = (value: string) => {
        setFilterStatus(value);
    };
    const onChangeChain = (value: string) => {
        setFilterChain(value);
    };

    const onChangeTabs = (key) => {
        setIsPager(1);
        if(key === '1') {
            setIsTabPager('all');
        } else if(key === '2') {
            setIsTabPager('presale');
        } else if(key === '3') {
            setIsTabPager('fair');
        } else if(key === '4') {
            setIsTabPager('dutch');
        } else if(key === '5') {
            setIsTabPager('supscription');
        }
        setFilterStatus('ALL');
        setFilterChain('ALL');
    };

    const onChangePager: PaginationProps['onChange'] = (pageNumber) => {
        setIsPager(pageNumber);
    };

    const operations = <>
        <div className="p-launchpadList__sort">
            <Select className="c-selectEdit" value={filterStatus} onChange={onChangeStatus} style={{ width: 130 }}>
                <Option value="ALL">All Status</Option>
                <Option value="UPCOMING">Upcoming</Option>
                <Option value="OPENING">Opening</Option>
                <Option value="COMPLETED">Completed</Option>
            </Select>
            {/* <Select className="c-selectEdit is-ml24" value={filterChain} onChange={onChangeChain} style={{ width: 130 }}>
                <Option value="ALL">All Chain</Option>
                <Option value="BSCTESTNET">BSC Testnet</Option>
                <Option value="ARBTESTNET">ARB Tesnet</Option>
                <Option value="ARB">ARB Mainnet</Option>
            </Select> */}
            <div className="c-sortBy">
                <span onClick={() => setIsSort(!isSort)}><img src={`../images/${isSort ? 'icon-sortby-active.svg' : 'icon-sortby.svg'}`} alt="" /></span>
                <div className={`c-sortBy__box ${isSort ? 'is-open' : ''}`}>
                    <ul onClick={() => setIsSort(!isSort)}>
                        {/* <li className={filterSort === 'kyc' ? 'is-active' : ''} onClick={() => setFilterSort('kyc')}>KYC</li>
                        <li className={filterSort === 'audit' ? 'is-active' : ''} onClick={() => setFilterSort('audit')}>Audit</li>
                        <li className={filterSort === 'safu' ? 'is-active' : ''} onClick={() => setFilterSort('safu')}>SAFU</li> */}
                        <li className={filterSort === 'fair' ? 'is-active' : ''} onClick={() => setFilterSort('fair')}>Fair Launch</li>
                        <li className={filterSort === 'softCap' ? 'is-active' : ''} onClick={() => setFilterSort('softCap')}>Soft Cap</li>
                        <li className={filterSort === 'hardCap' ? 'is-active' : ''} onClick={() => setFilterSort('hardCap')}>Hard Cap</li>
                        {/* <li className={filterSort === 'auction' ? 'is-active' : ''} onClick={() => setFilterSort('auction')}>Auction</li>
                        <li className={filterSort === 'subscription' ? 'is-active' : ''} onClick={() => setFilterSort('subscription')}>Subscription</li>
                        <li className={filterSort === 'lpPerCent' ? 'is-active' : ''} onClick={() => setFilterSort('lpPerCent')}>LP Percent</li> */}
                        <li className={filterSort === 'startTime' ? 'is-active' : ''} onClick={() => setFilterSort('startTime')}>Start Time</li>
                    </ul>
                </div>
            </div>
        </div>
    </>;

    // const onChangeTabs = (key: string) => {
    //     let tab = '';
    //     if(key === '1') {
    //         tab = 'all';
    //     } else if(key === '2') {
    //         tab = 'presale';
    //     } else if(key === '3') {
    //         tab = 'fair';
    //     } else if(key === '4') {
    //         tab = 'dutch';
    //     } else if(key === '5') {
    //         tab = 'supscription';
    //     }
    //     const payload:any = {
    //         "page": 0,
    //         "size": 10,
    //         "sort": 'id,desc',
    //         "tab": tab
    //     }

    //     dispatch(getMyLaunchpads(payload));
    // };

    

    const elmTabs = <>{myLaunchpads && myLaunchpads.length > 0 ? <>
            <div className="c-list">
                {myLaunchpads && myLaunchpads.map((item: object, key: number) => (
                    <ItemLaunchpad item={item} key={key} />
                ))}
            </div>
            <Pagination 
                className="c-pagingEdit"
                pageSize={10}
                defaultCurrent={1}
                current={isPager}
                onChange={onChangePager}
                total={Number(myLaunchpadsCount)}
            />
        </> : <div className="c-nodata">
            <img src="../images/img-nodata-launchpad.svg" alt="No data"/>
            <p>
                My Launchpads
                <span>This is where you will manage your launchpads that you created</span>
            </p>
        </div>
    }</>;

    return(
        <>
        {launchpadLoading && <Loading />}
        <div className="p-myLaunchpad">
            <div className="p-myLaunchpad__head">
                <h3>My Launchpads</h3>
                <Link to={'/launchpad/create-launchpad?type=1'}>Create Launchpad</Link>
            </div>
            <div className="p-myLaunchpad__box">
                <Tabs className="c-tabEdit" onChange={onChangeTabs} defaultActiveKey="1" tabBarExtraContent={operations}>
                    <TabPane tab="All Launchpads" key="1">
                        {elmTabs}
                    </TabPane>
                    <TabPane tab="Presale" key="2">
                        {elmTabs}
                    </TabPane>
                    <TabPane tab="Fair Launch" key="3">
                        {elmTabs}
                    </TabPane>
                    {/* <TabPane tab="Dutch Auction" key="4">
                        {elmTabs}
                    </TabPane>
                    <TabPane tab="Subscription" key="5">
                        {elmTabs}
                    </TabPane> */}
                </Tabs>       
            </div>
        </div>
        </>
    )
}

export default MyLaunchpad;