import { PayloadAction } from "@reduxjs/toolkit";
import http from "./http-common";

const LaunchPadService = {
  getLaunchpadList(payload: PayloadAction) {
    return http.get("/launchpads", { params: payload });
  },
  getLaunchpadsFilter(payload: any) {
    return http.post(
      `/launchpads/filter?ownerAddress=${payload.ownerAddress}&status=${payload.status}&networkChain=${payload.networkChain}&keySearch=${payload.keySearch}&extend=${payload.extend}&page=${payload.page}&size=${payload.size}&sort=${payload.sort}`
    );
  },
  getMyLaunchpads(payload: PayloadAction) {
    return http.get("/launchpads/my-launchpads", { params: payload });
  },
  getLaunchpadListJoined(payload: PayloadAction, pager: number, sort: string) {
    return http.post(
      `/launchpads/joined?page=${pager}&size=10&sort=${sort}`,
      payload
    );
  },
  getLaunchpadDetail(handle: string) {
    return http.get(`/launchpads/by-handle/${handle}`);
  },

  editLaunchpadDetail(payload: PayloadAction) {
    return http.post(`/launchpads/client/edit`, payload);
  },

  getLaunchpadTrending() {
    return http.get(`/launchpads/trending/ICO`);
  },
  createLauchpad(payload: any) {
    return http.post("/launchpads/create", payload);
  },
  createPrivateSale(payload: any) {
    return http.post("/launchpads/private/create", payload);
  },
  getLaunchpadVestingTeam(id: number) {
    return http.get(`/vesting-teams/${id}`);
  },
  editLaunchpadVestingTeam(payload: PayloadAction) {
    return http.put(`/vesting-teams`, payload);
  },
  completeLaunchpadVestingTeam(id: PayloadAction) {
    return http.put(`/vesting-teams/claim-complete`, id);
  },
  getLaunchpadByStatus(
    ownerAddress: any,
    status: any,
    page: any,
    size: any,
    sort: any
  ) {
    return http.post(
      `/launchpads/filter?ownerAddress=${ownerAddress}&status=${status}&page=${page}&size=${size}&sort=${sort}`
    );
  },

  //get cex
  getCexLauchpad() {
    return http.get(`/cex-lists`);
  },

  getPrivateList(payload: any) {
    return http.post(
      `/launchpads/private/filter?ownerAddress=${payload.ownerAddress}&status=${payload.status}&networkChain=${payload.networkChain}&keySearch=${payload.keySearch}&extend=${payload.extend}&page=${payload.page}&size=${payload.size}&sort=${payload.sort}`
    );
  },
  getPrivateListJoined(
    payload: PayloadAction,
    pager: number,
    size: number,
    sort: string
  ) {
    return http.post(
      `/launchpads/private/joined?page=${pager}&size=${size}&sort=${sort}`,
      payload
    );
  },

  finalizeLaunchpad(payload: PayloadAction) {
    return http.post(`/launchpads/finalize/${payload}`);
  },

  joinedLaunchpad(payload: PayloadAction) {
    return http.post(`/launchpads/${payload}/client/joined`);
  },

  setEndTimePool(payload: any) {
    return http.post(`/launchpads/end-time`, payload);
  },
};
export default LaunchPadService;
