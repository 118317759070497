import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import ToggleClose from '../assets/images/sideBar-toggle-close.svg';
import ToggleOpen from '../assets/images/sideBar-toggle-open.svg';
import ToggleSP from '../assets/images/sideBar-toggle-spOpen.svg';
import ToggleSPClose from '../assets/images/sideBar-toggle-sp.svg';
import LogoImage from '../assets/images/logo.svg';

type LogoChildProps = {
    toggleChange: (toggleChange: boolean) => void;
    toggleClose?: boolean;
}

interface Size {
    width: number;
    height: number;
}

const Logo: React.FC<LogoChildProps> = (props) => {
    const [isToggle, setIsToggle] = useState<boolean>(false);
    const size: Size = useWindowSize();
    const onToggle = () => {
        setIsToggle(!isToggle);
        props.toggleChange(!isToggle);
    }

    useEffect(() => {
        if(props.toggleClose === false) {
            setIsToggle(false);       
        }        
    }, [props.toggleClose]);

    return(
        <div className="c-logo">
            <span className="c-logo__toggle" onClick={() => onToggle()}>
                {size.width > 991 ? <img src={isToggle ? ToggleOpen : ToggleClose} alt="Toggle" /> : 
                <img src={isToggle ? ToggleSPClose : ToggleSP} alt="Toggle" />
                }                
            </span>
            <Link to={'/'}><img src={LogoImage} alt="BlueSale" /></Link>
        </div>
    )
}

export default Logo;