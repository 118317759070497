import moment from "moment";

export const formatDate = (timer) => {
    return new Date(timer).toLocaleTimeString('en-GB', {
        year: 'numeric', 
        month: 'short', 
        day: 'numeric',
        hour: '2-digit', 
        minute: '2-digit',
        timeZone: 'UTC',
        // timeZoneName: 'short',
        hour12: true
    });
}

export const formatUTCDate = (time) => {
    return moment(new Date(time).toUTCString()).utcOffset(0).utc();
}

export const convertDateTime = (dateTime) => {
    var options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC',
        timeZoneName: 'short',
        hour12: true
    };

    return dateTime.toLocaleTimeString('en-GB', options);
}