export const formatNumber = (number) => {
    return Number(number).toLocaleString('en-US', {maximumFractionDigits:4});
}

export const formatNumberAmount = (number:any, minPrecision = 0, maxPrecision = 2, locale = 'en-US') => {
    const options = {
      minimumFractionDigits: minPrecision,
      maximumFractionDigits: maxPrecision,
    }
    return Number(number).toLocaleString(locale, options)
}


export const formatNumberPrice = (number:any, minPrecision = 0, maxPrecision = 4, locale = 'en-US') => {
    const options = {
      minimumFractionDigits: minPrecision,
      maximumFractionDigits: maxPrecision,
    }
    return Number(number).toLocaleString(locale, options)
}

export const formatNumberUnit = (totalValue:any) => {
  let totalValueConverted = "";

  if(Math.abs(Number(totalValue)) >= 1.0e+9) {
    totalValueConverted = formatNumberAmount(Math.abs(Number(totalValue)) / 1.0e+9) + "B";
  }
  // Six Zeroes for Millions 
  else if(Math.abs(Number(totalValue)) >= 1.0e+6) {
    totalValueConverted = formatNumberAmount(Math.abs(Number(totalValue)) / 1.0e+6) + "M";
  }
  // Three Zeroes for Thousands
  else if(Math.abs(Number(totalValue)) >= 1.0e+3) {
    totalValueConverted = formatNumberAmount(Math.abs(Number(totalValue)) / 1.0e+3) + "K";
  }
  else {
    totalValueConverted = formatNumberAmount(Math.abs(Number(totalValue)));
  }

  return totalValueConverted;
}