import web3 from "web3";
import { DECIMALS_STRING } from "../constants";

export const convertToWei = (value: any, decimals: any) => {
return web3.utils.toWei(value?.toString(), DECIMALS_STRING[decimals]);
};

export const convertFromWei = (value: any, decimals: any) => {
return web3.utils.fromWei(value?.toString(), DECIMALS_STRING[decimals]);
};

export const formatLaunchpadInfo = (
info: any,
tokenDecimals: any,
feeTokenDecimals: any
) => {
if (info?.balance) {
    return { balance: Number(convertFromWei(info.balance, feeTokenDecimals)) }; // uint256
}
return {
    softCap: Number(convertFromWei(info.softCap, feeTokenDecimals)), // uint256
    hardCap: Number(convertFromWei(info.hardCap, feeTokenDecimals)), // uint256
    startTime:
    Number(info.startTime) !== 0
        ? new Date(Number(info.startTime) * 1000)
        : null, // uint256
    endTime:
    Number(info.endTime) !== 0 ? new Date(Number(info.endTime) * 1000) : null, // uint256
    state: Number(info.state), // uint256
    raisedAmount: Number(convertFromWei(info.raisedAmount, feeTokenDecimals)), // uint256
    feeToken: info.feeToken, // address
    listingTime:
    Number(info.listingTime) !== 0
        ? new Date(Number(info.listingTime) * 1000)
        : null, // uint256
    whitelistPool: Number(info.whitelistPool), // uint256
    holdingToken: info.holdingToken, // address
    holdingTokenAmount: Number(
    convertFromWei(info.holdingTokenAmount, tokenDecimals)
    ), // uint256
    maxInvest: Number(convertFromWei(info?.maxInvest, feeTokenDecimals)),
    minInvest: Number(convertFromWei(info?.minInvest, feeTokenDecimals)),
    globalTotalInvestment:Number(convertFromWei(info?.globalTotalInvestment, feeTokenDecimals)),
};
};

export const formatPrivateSaleInfo = (
info: any,
tokenDecimals: any,
feeTokenDecimals: any
) => {
 
return {
    softCap: Number(convertFromWei(info.softCap, feeTokenDecimals)), // uint256
    hardCap: Number(convertFromWei(info.hardCap, feeTokenDecimals)), // uint256
    startTime:
    Number(info.startTime) !== 0
        ? new Date(Number(info.startTime) * 1000)
        : null, // uint256
    endTime:
    Number(info.endTime) !== 0 ? new Date(Number(info.endTime) * 1000) : null, // uint256
    isWhitelist: info.isWhitelist, // uint256
    currency: info?.currency, // uint256
    maxInvest: Number(convertFromWei(info[5], feeTokenDecimals)),
    minInvest: Number(convertFromWei(info[4], feeTokenDecimals)),
};
};

export const formatAntiBotInfos = (info: any, tokenDecimals: any) => {
    return {
        amountAddedPerLock: Number(
        convertFromWei(info.amountAddedPerLock, tokenDecimals)), // uint256
        amountLimitPerTrade: Number(
        convertFromWei(info.amountLimitPerTrade, tokenDecimals)), // uint256
        enabled: Boolean(info.enabled), // bool
        factoryExchange: info.factoryExchange,
        owner: info.owner,
        pair: info.pair,
        pairToken: info.pairToken,
        routerExchange: info.routerExchange,
        timeLimitPerTrade: Number(
        convertFromWei(info.timeLimitPerTrade, tokenDecimals)), // uint256
        totalLockBlocks: Number(
        convertFromWei(info.totalLockBlocks, tokenDecimals)), // uint256
    };
};

export const formatAntiBotStatusInfo = (info: any, tokenDecimals: any) => {
    return {
        amountLimit: Number(convertFromWei(info.amountLimit, tokenDecimals)),
        balance: Number(convertFromWei(info.balance, tokenDecimals)),
        currentBlock: Number(info.currentBlock),
        enabled: Boolean(info.enabled), // bool
        funded: Boolean(info.funded), // bool
        timeLimit: Number(info.timeLimit), // uint256
        leftBlocks: Number(convertFromWei(info.leftBlocks, tokenDecimals)), // uint256
    };
};

export const formatFairlaunchInfo = (info: any, tokenDecimals:any, feeTokenDecimals: any) => {
    return {
        balance: Number(convertFromWei(info.balance, tokenDecimals)),
        endTime: Number(info.endTime) !== 0 ? new Date(Number(info.endTime) * 1000) : null,
        feeToken: info.feeToken,
        raisedAmount: Number(convertFromWei(info.raisedAmount, feeTokenDecimals)),
        softCap: Number(convertFromWei(info.softCap, feeTokenDecimals)),
        startTime: Number(info.startTime) !== 0 ? new Date(Number(info.startTime) * 1000) : null,
        state: Number(info.state),
        totalIcoToken: Number(convertFromWei(info?.totalIcoToken, tokenDecimals)),
    };
};