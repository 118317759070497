import { convertFromWei, convertToWei, formatFairlaunchInfo } from './utils';
import { ethers } from 'ethers';

declare const window: Window & typeof globalThis & { ethereum: any };

export const fairlaunchCreate = async (
    fairlaunchDeployContract:any,
    launchpadInfo:any,
    dexInfo:any,
    feeSystem:any,
    percertAffiliate:any
) => {
    return await fairlaunchDeployContract.deployLaunchpad(
        launchpadInfo, 
        dexInfo, 
        feeSystem, 
        percertAffiliate, 
        { value: feeSystem[0] }
    );
}

export const getLaunchpadInfo = async (launchpadContract:any, tokenDecimals:any, feeTokenDecimals:any) => {   
    try {
        const _launchpadInfo:any = await launchpadContract.getLaunchpadInfo();
        return formatFairlaunchInfo(_launchpadInfo, tokenDecimals, feeTokenDecimals);
    } catch (error) {

    } 
};

export const getJoinInfos = async (launchpadContract:any, account:any, decimals:any) => {
    return await launchpadContract.joinInfos(...[account]);
}

export const getUserClaimAble = async (launchpadContract:any, account:any, decimals:any) => {
    const data = await launchpadContract.getUserClaimAble(...[account]);
    return Number(convertFromWei(data, decimals));
}

export const getAffiliateReward = async (launchpadContract:any, account:any, decimals:any) => {
    const data = await launchpadContract.award(account);
    return {
        yourReward: Number(convertFromWei(data[0], decimals)),
        poolReferrerCount: Number(data[1]),
        totalRefAmount: Number(convertFromWei(data[2], decimals)),
        totalReward: Number(convertFromWei(data[3], decimals)), 
    }
}

export const getAffiliateListUser = async (launchpadContract:any, address: any) => {
    const data = await launchpadContract.getUsersAffiliate(address);  
    return data;
}

export const getAmountsAffiliate = async (launchpadContract:any, address: any) => {
    const data = await launchpadContract.getAmountsAffiliate(address);   
    return data;
}


export const getPercertAffiliate = async (launchpadContract:any) => { 
    const data = await launchpadContract.percentAffiliate(); 
    return Number(data);
}


export const claimTokens = async (launchpadContract:any) => {
    const args = [];
  
    const estimatedGas = await launchpadContract.estimateGas.claimTokens(...args);
    return await launchpadContract.claimTokens(...args, {
      gasLimit: estimatedGas,
    });
}

export const contribute = async (launchpadContract:any , amount:any, presenter:any , decimals:any) => {
   
    const args = convertToWei(amount, decimals);
    const feeToken = await launchpadContract.feeToken();
    let options:any = { value: 0 }
    if (!parseInt(feeToken)) {
        options['value'] = convertToWei(amount, decimals);
    } 
     console.log(args, presenter, options)
    return await launchpadContract.contribute(args, presenter, options);
}

export const emergencyWithdrawContribute = async (launchpadContract:any) => {
    return await launchpadContract.emergencyWithdrawContribute();
}

export const withdrawContribute = async (launchpadContract:any) => {
	return await launchpadContract.withdrawContribute();
};

export const withdrawContributeAffiliate = async (launchpadContract:any) => {
	return await launchpadContract.claimCommission();
};

export const finalizeLaunchpad = async (launchpadContract:any, ) => {
    return await launchpadContract.finalizeLaunchpad();
}

export const enableAffiliateLaunchpad = async (launchpadContract:any, status: any ,affiliatePrecent:any) => {
    return await launchpadContract.setAffiliate(status, affiliatePrecent);
} 

export const cancelLaunchpad = async (launchpadContract:any) => {
    return await launchpadContract.cancelLaunchpad();
}

export const claimCanceledTokens = async (launchpadContract:any) => {
    return await launchpadContract.claimCanceledTokens();
}


export const setWhitelistPoolLaunchpad = async (launchpadContract:any, wlPool:any,holdingTokenAddress:any, amount:any, decimals:any) => {
    const args = [wlPool, holdingTokenAddress, convertToWei(amount, decimals)];
    return await launchpadContract.setWhitelistPool(...args);
}

export const addWhiteListUsers = async (launchpadContract:any, listAddress: any) => {
    return await launchpadContract.setWhitelistBuyers(listAddress);
}

export const removeWhiteListUsers = async (launchpadContract:any, listAddress: any) => {
    return await launchpadContract.removeWhitelistBuyers(listAddress);
} 
 
export const getListOfWhiteListUsers = async (launchpadContract:any) => {
    const _allAllocationCount:any = await launchpadContract.allAllocationCount();
    
    let listWL;
    if(_allAllocationCount == 0) {
        listWL = [];
    } 
    else {
        listWL = launchpadContract.getAllocations(0, Number(_allAllocationCount));
    } 

    return listWL;
}

export const setLaunchpadAllocations = async (launchpadContract:any, listAddress: any, listAmounts: any) => {
	return await launchpadContract.setAllocations(listAddress, listAmounts);
};

export const getJoinedUsers = async (launchpadContract:any) => {
	return await launchpadContract.getJoinedUsers();
};

export const getAffiliateExist = async (launchpadContract:any) => {
	return await launchpadContract.affiliate();
}; 

export const getFinalizeExist = async (launchpadContract:any) => {
	return await launchpadContract.state();
}; 

export const getGsLock = async (launchpadContract:any) => {
	return await launchpadContract.gsLock();
};

export const getLpLockId = async (launchpadContract:any) => {
	return await launchpadContract.lpLockId();
};

export const getTeamFirstReleasePercent = async (launchpadContract:any) => {
	return await launchpadContract.teamFirstReleasePercent();
};

export const getTeamLockId = async (launchpadContract:any) => {
	return await launchpadContract.teamLockId();
};

export const getRouterAddress = async (launchpadContract:any) => {
	return await launchpadContract.routerAddress();
};

export const setEndTime = async (launchpadContract:any, claimTime:any, isNowTime:boolean) => {
	return await launchpadContract.setEndTime(...[claimTime, isNowTime]);
};

export const getTeamLockIdNoHook = async (launchpadSCAbi:any, launchpadContractAdress:any) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const launchpadContract = new ethers.Contract(launchpadContractAdress, launchpadSCAbi, provider);
		return await launchpadContract.teamLockId();
	}
};

export const getOwnerZoneInfo = async (launchpadContract:any, account:any, decimals:any) => {
    return await launchpadContract.getOwnerZoneInfo(...[account]);
}