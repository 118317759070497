import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import LiquidityService from "../services/LiquidityService";

// ================================================
// ==                  ACTION                    ==
// ================================================
export const getLiquidities = (payload:PayloadAction) => {
    return async dispatch => {
        dispatch(setLoading(true));
        
        await LiquidityService.getLiquidities(payload)
        .then((res)=>{
            dispatch(setLoading(false));
            dispatch(setLiquidities(res.data));
            dispatch(setLiquiditiesCount(Number(res.headers['x-total-count'])));
        })
        .catch((error:any)=>{
            dispatch(setErrors(error));
            dispatch(setLoading(false));
        });
    }
}

export const createLiquidities = (payload:PayloadAction, onCallback:Function) => {
  return async dispatch => {
      dispatch(setLoading(true));
      
      await LiquidityService.createLiquidities(payload)
      .then((res)=>{
          dispatch(setLoading(false)); 
          dispatch(setCreateLiquidity(res.data));
          onCallback({result: "SUCCESS", data: res?.data});
      })
      .catch((error:any)=>{
          dispatch(setErrors(error));
          dispatch(setLoading(false));
          onCallback({result: "ERROR", error: error});
      });
  }
}

export const getLiquidityByAddress = (handle:string, onCallback:Function) => {
  return async dispatch => {
      dispatch(setLoading(true));

      console.log(handle);
      
      await LiquidityService.getLiquidityByAddress(handle)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setLiquidityByAddress(res.data));
        onCallback({"tokenLP" : res.data ?? null});
      })
      .catch((error:any)=>{
          dispatch(setErrors(error));
          dispatch(setLoading(false));
      });
  }
}

// ================================================
// ==                REDUX STORE                 ==
// ================================================
type LiquidityObject = {
    [key: string]: void | string | number | any;
  };
  
  export interface LiquidityState {
      liquidities: LiquidityObject[];
      liquidityByAddress: LiquidityObject;
      liquidityLoading: boolean;
      liquidityErrors: string;
      createLiquidity:LiquidityObject;
      liquiditiesCount: number | string | undefined;
  }
  
  const initialState: LiquidityState = {
    liquidities: [],
    liquidityByAddress: {},
    liquidityLoading: false,
    liquidityErrors: "",
    createLiquidity:{},
    liquiditiesCount: 0
  }
  
  const liquiditySlice = createSlice({
    name: "liquility",
    initialState,
    reducers: {
      setLoading: (state, { payload }: PayloadAction<boolean>) => {
        state.liquidityLoading = payload
      },
  
      setErrors: (state, { payload }: PayloadAction<string>) => {
        state.liquidityErrors = payload
      },
  
      setLiquidities: (state, { payload }: PayloadAction<LiquidityObject[]>) => {
        state.liquidities = payload
      },

      setCreateLiquidity: (state, { payload }: PayloadAction<LiquidityObject[]>) => {
        state.createLiquidity = payload
      },

      setLiquidityByAddress: (state, { payload }: PayloadAction<LiquidityObject[]>) => {
        state.liquidityByAddress = payload
      },

      setLiquiditiesCount: (state, { payload }: PayloadAction<number | string | undefined>) => {
        state.liquiditiesCount = payload
      },
  
    },
  })
  
  export const { setLoading, setErrors, setLiquidities, setCreateLiquidity, setLiquidityByAddress, setLiquiditiesCount} = liquiditySlice.actions
  
  export default liquiditySlice.reducer
  
  export const liquiditySelector = (state: { liquidityStore: LiquidityState }) => state.liquidityStore