 import {Tabs} from 'antd';
import FormLock from '../../components/lockToken/formLock';
import { useState, useEffect} from 'react';  
import TokenLock from '../../components/lockToken';
import { useAppSelector } from "../../redux/hooks";
import { tokenLockSelector } from "../../redux/slicers/tokenLockSlice"; 

const CreateLock = () => {
    const [activeKey, setActiveKey] = useState<string>('1'); 
    const { tokenLock } = useAppSelector(tokenLockSelector);
    const onKeyChange : any = (key) =>
    {
        setActiveKey(key); 
        localStorage.setItem("stepLock", key) 
    }  
    
    useEffect(() => {
        setActiveKey(localStorage.getItem("stepLock")||"1"); 
        if (Object.keys(tokenLock).length === 0) {
            localStorage.setItem("stepLock", "1")
        }
    }, [localStorage.getItem("stepLock")]);

    return (
    <div className='c-createLaunchpad'>  
            <Tabs
            defaultActiveKey="1" activeKey={activeKey}  onChange={onKeyChange} 
            items={["Token", "Form Lock"].map((e, i) => {
            const id = String(i + 1); 
            return {
                label: ( 
                <div className='tab-header'>
                    <div className='tab-header-circle'>
                        <span className='tab-header-num'>{id}</span>
                    </div> 
                    <span className='tab-header-text'>{e}</span>
                </div>
                ),
                key: id,
                disabled: activeKey < id && true,
                children:  
                    e === "Token" ? <TokenLock setActiveKey={setActiveKey}/> :
                    e === "Form Lock" ? <FormLock setActiveKey={setActiveKey}/> : null 
            };
            })}
        /> 
    </div> 
   )
}
 
export default CreateLock;