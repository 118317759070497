import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RadioChangeEvent, DatePickerProps, Spin, Form, Input } from "antd";
import { Radio, Tabs, DatePicker } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  Button,
  InputChange,
  SearchChange,
  Loading,
} from "../../components/component";
import useWindowSize from "../../hooks/useWindowSize";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  finalizeLaunchpadAPI,
  getLaunchpadDetail,
  joinedLaunchpadAPI,
  setEndTimePool,
  launchPadSelector,
} from "../../redux/slicers/launchpadSlice";
import { formatDate, formatUTCDate } from "../../utils/formatDate";
import { formatNumber, formatNumberAmount } from "../../utils/formatNumber";
import Countdown, { zeroPad } from "react-countdown";
import { useActiveWeb3React } from "../../hooks";
import { formatData } from "../../utils/formatData";
import {
  useFairlaunchContract,
  useLaunchpadContract,
  useTokenContract,
} from "../../hooks/useContract";
import networkChange from "../../utils/networkChange";
import {
    getOwnerZoneInfo,
    getLaunchpadInfo,
    getUserClaimAble,
    getJoinInfos,
    getListOfWhiteListUsers,
    claimTokens,
    contribute,
    emergencyWithdrawContribute,
    finalizeLaunchpad,
    cancelLaunchpad,
    getRouterAddress,
    withdrawContribute,
    setEndTime,
    getJoinedUsers,
    getAffiliateReward,
    getPercertAffiliate,
    enableAffiliateLaunchpad,
    getAffiliateExist,
    getAffiliateListUser,
    getAmountsAffiliate,
    withdrawContributeAffiliate,
    claimCanceledTokens,
    getFinalizeExist,
} from "../../contracts/fairlaunch";
import {
    getTokenAllowanceNoHook,
    tokenApproveNoHook
} from "../../contracts/token";
import { Store } from "react-notifications-component";
import addNotify from "../../components/commons/Notify/addNotify";
import { getCurrencyDecimals } from "../../components/createlaunchpad/ultils";
import { ethers } from "ethers";
import axios from "axios";
import { convertFromWei, convertToWei } from "../../contracts/utils";
import { getDexInfo } from "../../utils/dex";
import {
    getTokenInformByAddress,
    tokenInformsSelector,
} from "../../redux/slicers/tokenInformSlice";
import StandardTokenABI from "../../contracts/abi/StandardTokenABI.json";
import { useSearchParams } from "react-router-dom";
import { getAppSettings, settingSelector } from '../../redux/slicers/settingSlice';
import { FEE_SETTINGS } from "../../constants";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Size {
  width: number;
  height: number;
}
const chartOptions = {
  plugins: {
    tooltip: {
      callbacks: {
        label: function (tooltipItem: any) {
          return " " + tooltipItem.parsed + "% " + tooltipItem.label;
        },
      },
    },
  },
};

type DexInfoObject = {
    factoryAddress: any;
    id: any;
    name: any;
    routerAddress: any;
};

const FairlaunchDetail: React.FC = () => {
    const { account, chainId, library } = useActiveWeb3React();
    const [notification, setNotification] = useState<boolean>(true);
    const [valueAmount, setValueAmount] = useState<number>(0);
    const [softCap, setSoftCap] = useState<any>();
    const [raisedAmount, setRaisedAmount] = useState<any>();
    const [listingTime, setListingTime] = useState<any>();
    const [feeTokenAddress, setFeeTokenAddress] = useState<any>();
    const [actionStatus, setActionStatus] = useState("");
    const [canCancel, setCanCancel] = useState<any>();
    const [canFinalize, setCanFinalize] = useState<any>();
    const [isOwner, setIsOwner] = useState<any>();
    const [priceBNB, setPriceBNB] = useState<any>();
    const [isListedOfTopRewards, setIsListedOfTopRewards] = useState<any>(false);
    const [isSwitch, setIsSwitch] = useState<any>();
    const [totalClaimable, setTotalClaimable] = useState(0);
    const { TabPane } = Tabs;
    const size: Size = useWindowSize();
    const { handle } = useParams<{ handle: any }>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { launchpadDetail, endTimePool, launchpadLoading, launchpadErrors } = useAppSelector(launchPadSelector);
    const { tokenInformsByAddress } = useAppSelector(tokenInformsSelector);
    const launchpadRound = launchpadDetail?.rounds && launchpadDetail?.rounds.length > 0 && launchpadDetail?.rounds[0];
    const tokenInform = launchpadDetail?.tokenInform;
    const vestingContributor = launchpadDetail?.vestingContributor;
    const nowTimer = new Date();
    const startTimer = new Date(launchpadDetail?.startTime);
    const endTimer = new Date(launchpadDetail?.endTime);
    const [tokenForPreasle, setTokenForPreasle] = useState(0);
    const [tokenForLiquidity, setTokenForLiquidity] = useState(0);
    const launchpadContract = useFairlaunchContract(launchpadRound?.presaleAddress);
    const chartPresale = (tokenForPreasle * 100) / tokenInform?.totalSupply;
    const chartLiquidity = (tokenForLiquidity * 100) / tokenInform?.totalSupply;
    const chartUnlocked = 100 - (chartPresale + chartLiquidity);
    const [isApprovedToken, setIsApprovedToken] = useState<boolean>(false);
    const [launchpadState, setLaunchpadState] = useState<number>(0);
    const [totalContributors, setTotalContributors] = useState<number>(0);
    const [totalInvestment, setTotalInvestment] = useState(0);
    const [claimedTokens, setClaimedTokens] = useState(0);
    const [totalUserTokens, setTotalUserTokens] = useState(0);
    const [routerAddress, setRouterAddress] = useState("");
    const [dexInfo, setDexInfo] = useState<DexInfoObject>();
    const [needApproveToken, setNeedApproveToken] = useState(false);
    const [refund, setRefund] = useState<boolean>();
    const [affiliateValue, setAffiliateValue] = useState<any>();
    const [percertAffiliate, setPercertAffiliate] = useState<any>("");
    const [checkAffiliate, setCheckAffiliate] = useState<boolean>(false);
    const [checkAffiliateBtn, setCheckAffiliateBtn] = useState<boolean>(false);
    const [listUserAffiliate, setListUserAffiliate] = useState<any>({});
    const [listAmountAffiliate, setListAmountAffiliate] = useState<any>({});
    const [percentAffiliateInput, setPercentAffiliateInput] = useState(0);
    const [endTimeDate, setEndTimeDate] = useState<any>();
    const [isListOfContribution, setIsListOfContribution] = useState<boolean>(false);
    const [listOfContribution, setListOfContribution] = useState<any>();
    const [maxContribution, setMaxContribution] = useState<any>(0);
    const currencyDecimals = getCurrencyDecimals(chainId,launchpadDetail?.currency);
    const [isRefresh, setIsRefresh] = useState<boolean>(false);
    const [isRefreshInfos, setIsRefreshInfos] = useState<boolean>(false);
    const [isRefreshOwner, setIsRefreshOwner] = useState<boolean>(false);

    const [searchParams, setSearchParams] = useSearchParams();
    let refID = searchParams.get("refID");

    let progressPercent: any | number = 0;
    let statusText: any = "";
    let progressTime: any = "";
    let contributeInput: any = null;
    let actionButton: any = null;

    const data = {
        labels: ["Presale", "Liquidity", "Unlocked"],
        datasets: [
        {
            label: "",
            data: [chartPresale, chartLiquidity, chartUnlocked],
            backgroundColor: ["#01058A", "#012CB5", "#1BA9EA"],
            borderColor: ["#01058A", "#012CB5", "#1BA9EA"],
            borderWidth: 1,
        },
        ],
    };

    useEffect(() => {
        if (handle) {
            dispatch(getLaunchpadDetail(handle));
        }
    }, [handle]);

    useEffect(() => {
        (async () => {
            await callLaunchpadInfos();
        })();
    }, [account, launchpadContract]);

    useEffect(() => {
        if(isRefreshInfos) {
          (async () => {
              await callLaunchpadInfos();
          })();
          setIsRefreshInfos(false);
        }
    }, [account, launchpadContract, isRefreshInfos]);

    const callLaunchpadInfos = async () => {
        if (launchpadContract && account) {
            await getLaunchpadInfo(
                launchpadContract,
                tokenInform?.decimals,
                currencyDecimals
            ).then((res: any) => {
                setSoftCap(res?.softCap);
                setRaisedAmount(res?.raisedAmount);
                setFeeTokenAddress(res?.feeToken);
                setLaunchpadState(res?.state);
                setTokenForPreasle(res?.totalIcoToken);
                setEndTimeDate(res?.endTime);

                if (parseInt(res.feeToken) !== 0) {
                    setNeedApproveToken(true);

                    (async () => {
                        if (launchpadDetail?.currency && launchpadRound?.presaleAddress) {
                        const allowance = await getTokenAllowanceNoHook(
                            StandardTokenABI,
                            res.feeToken,
                            account,
                            launchpadRound?.presaleAddress
                        );
                        setIsApprovedToken(allowance > 0 ? true : false);
                        }
                    })();
                } else {
                    setNeedApproveToken(false);
                }
            });

            setIsOwner(launchpadDetail?.ownerAddress.toLocaleLowerCase() === account?.toLocaleLowerCase() ? true: false);
        }
    }

    useEffect(() => {
      if ((!refID || refID && !ethers.utils.isAddress(refID?.toString())) && account && checkAffiliate) {
          refID = account;
          setSearchParams({refID: account});
      }
    }, [account, checkAffiliate]); 

    useEffect(() => {
        setMaxContribution(launchpadRound?.maxBuy && launchpadRound?.maxBuy > 0 ? launchpadRound?.maxBuy : (launchpadDetail?.softCap ?? 0));
    }, [launchpadRound, launchpadDetail]);

    useEffect(() => {
        (async () => {
            await callClaimInfos();
        })();
    }, [account, launchpadContract]);
  
    useEffect(() => {
      if(isRefresh) {
        (async () => {
            await callClaimInfos();
        })();
        setIsRefresh(false);
      }
    }, [account, launchpadContract, isRefresh]);

    const callClaimInfos = async () => {
        if (launchpadContract && account) {
            (async () => {
                await getPercertAffiliate(launchpadContract)
                .then((res: any) => { 
                    setPercertAffiliate(Number(res) / 100);
                });

                await getAffiliateReward(launchpadContract, account, currencyDecimals)
                .then((res: any) => { 
                    setAffiliateValue(res);
                });

                await getJoinInfos(launchpadContract, account, tokenInform?.decimals).then((res: any) => {
                    setTotalInvestment(Number(convertFromWei(res?.totalInvestment, currencyDecimals)));
                    setTotalUserTokens(Number(convertFromWei(res?.totalTokens, tokenInform?.decimals)));
                    setClaimedTokens(Number(convertFromWei(res?.claimedTokens, tokenInform?.decimals)))
                });

                await getUserClaimAble(launchpadContract, account, tokenInform?.decimals)
                .then((res: any) => {
                    setTotalClaimable(Number(res));
                });

                await getJoinedUsers(launchpadContract)
                .then((res: any) => {
                    setTotalContributors(res?.length);
                    setListOfContribution(res);
                });

                await getAffiliateListUser(launchpadContract, account)
                .then((res: any) => {
                    setListUserAffiliate(res);
                });

                await getAmountsAffiliate(launchpadContract, account)
                .then((res: any) => {
                    setListAmountAffiliate(res);
                });

                await getAffiliateExist(launchpadContract)
                .then((res: any) => {
                    setCheckAffiliate(Boolean(res));
                });
            })();
        }
    }

    useEffect(() => {
        (async () => {
            await callOwnerInfos();
        })();
    }, [account, launchpadContract]);
  
    useEffect(() => {
      if(isRefreshOwner) {
        (async () => {
            await callOwnerInfos();
        })();
        setIsRefreshOwner(false);
      }
    }, [account, launchpadContract, isRefreshOwner]);

    const callOwnerInfos = async () => {
        if (launchpadContract && account) {
            await getOwnerZoneInfo(launchpadContract, account, tokenInform?.decimals)
            .then((res: any) => {
                setCanCancel(res?.canCancel);
                setCanFinalize(res?.canFinalize);
            });
        }
    }

    useEffect(() => {
        if (launchpadContract && account) {
            (async () => {
                await getRouterAddress(launchpadContract).then((res: any) => {
                    setRouterAddress(res);
                });
            })();
        }
    }, [account, launchpadContract]);

    useEffect(() => {
        let _dexInfo = getDexInfo(chainId, routerAddress);
        setDexInfo(_dexInfo);
    }, [chainId, routerAddress]); 

    let availableAmount:number = Number(launchpadDetail?.softCap - raisedAmount);
    let maxBuyAmount = Number(maxContribution - Number(totalInvestment));
    let availableMaxAmount:number = maxBuyAmount > availableAmount ? availableAmount : maxBuyAmount;

    useEffect(() => {
        if (launchpadRound?.chainId && chainId) {
            if (launchpadRound?.chainId !== chainId) {
                setIsSwitch(true);
                if (launchpadRound?.chainId === 42161) {
                networkChange("0xa4b1");
                } else if (launchpadRound?.chainId === 421613) {
                networkChange("0x66eed");
                } else if (launchpadRound?.chainId === 97) {
                networkChange("0x61");
                }
            } else {
                setIsSwitch(false);
            }
        }
    }, [chainId, account, launchpadRound?.chainId]);

    useEffect(()=> {
        setTokenForLiquidity(tokenForPreasle * Number(launchpadRound?.liquidityPercent)/100 * (1 - Number(launchpadDetail?.raisedFee)/100));
    }, [launchpadDetail, launchpadRound?.liquidityPercent, tokenForPreasle]);  


    const handleSwitchNetwork = async () => {
        if (launchpadRound?.chainId === 42161) {
            await networkChange("0xa4b1");
        } else if (launchpadRound?.chainId === 421613) {
            await networkChange("0x66eed");
        } else if (launchpadRound?.chainId === 97) {
            await networkChange("0x61");
        }
    };

    const handleApproveToken = async () => {
        if (!launchpadRound?.presaleAddress) {
            Store.addNotification(addNotify("Presale Address is invalid!", "danger"));
            return;
        }

        setActionStatus("APPROVE_LOADING");

        await tokenApproveNoHook(
            StandardTokenABI,
            feeTokenAddress,
            library,
            account,
            launchpadRound?.presaleAddress)
        .then((res: any) => {
            let resWait = res.wait();
            resWait.then((resTransaction: any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    Store.addNotification(addNotify("Tokens approved successfully", "success"));
                    setIsApprovedToken(true);
                    setActionStatus("");
                } else {
                setActionStatus("");
                    Store.addNotification(addNotify("Transaction failed", "warning"));
                    return;
                }
            })
            .catch((error: any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, "danger"));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, "danger"));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, "danger"));
                    }
                }
            });
        })
        .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, "danger"));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, "danger"));
                }
            }
        });
    };

    const handleClaimTokens = async () => {
        setActionStatus("CLAIM_LOADING");

        await claimTokens(launchpadContract)
        .then((res: any) => {
            let resWait = res.wait();
            resWait.then((resTransaction: any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber
                ) {
                    Store.addNotification(addNotify("Tokens claimed successfully", "success"));
                    setActionStatus("CLAIMED");
                    setClaimedTokens(claimedTokens + totalClaimable);
                    setTotalClaimable(0);
                    setIsRefresh(true);
                    setIsRefreshInfos(true);
                } else {
                    setActionStatus("");
                    Store.addNotification(addNotify("Transaction failed", "warning"));
                    return;
                }
            })
            .catch((error: any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, "danger"));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, "danger"));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, "danger"));
                    }
                }
            });
        })
        .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, "danger"));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, "danger"));
                }
            }
        });
    };

    const handleContribute = async () => {
        availableAmount = Number(softCap - raisedAmount);
        if (!valueAmount || (maxContribution > 0 && valueAmount > maxContribution) || valueAmount > availableAmount) {
            Store.addNotification(addNotify("Amount is invalid!", "warning"));
            return;
        }
        setActionStatus("BUY_LOADING");
        let presenter;
        if (checkAffiliate && refID && account && refID !== account) {
            presenter = refID;
        } else {
            presenter = "0x0000000000000000000000000000000000000000";
        }

        await contribute(
            launchpadContract,
            valueAmount,
            presenter,
            currencyDecimals
        )
        .then((res: any) => {
            let resWait = res.wait();
            resWait.then((resTransaction: any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {  
                    dispatch(joinedLaunchpadAPI(launchpadDetail?.id, async (res:any) => { 
                        if(res.result === "SUCCESS") {
                            Store.addNotification(addNotify("Bought successfully", "success")); 
                            if (Number(maxContribution) - Number(totalInvestment) === 0) {
                                setActionStatus("BOUGHT");
                            } else {
                                setActionStatus("");
                            }
                            setValueAmount(0);
                            setIsRefresh(true);
                            setIsRefreshInfos(true);
                            isOwner && setIsRefreshOwner(true);
                        }
                        else {
                            setActionStatus("");
                            if (res?.error?.message) {
                                Store.addNotification(addNotify(res?.error?.message, 'danger'));
                            }
                        }
                    })); 
                }
                else {
                    setActionStatus("");
                    Store.addNotification(addNotify("Transaction failed", "warning"));
                    return;
                }
            })
            .catch((error: any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, "danger"));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, "danger"));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, "danger"));
                    }
                }
            });
        })
        .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, "danger"));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, "danger"));
                }
            }
        });
    };

    const handleEmergencyWithdraw = async () => {
        setActionStatus("EMERGENCY_WITHDRAW_LOADING");
        await emergencyWithdrawContribute(launchpadContract)
        .then((res: any) => {
            let resWait = res.wait();
            resWait.then((resTransaction: any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    Store.addNotification(
                        addNotify("Emergency withdraw successfully", "success")
                    );
                    setActionStatus("EMERGENCY_WITHDRAW");
                    setIsRefresh(true);
                    setIsRefreshInfos(true);
                    isOwner && setIsRefreshOwner(true);
                } else {
                    setActionStatus("");
                    Store.addNotification(addNotify("Transaction failed", "danger"));
                    return;
                }
            })
            .catch((error: any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, "danger"));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, "danger"));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, "danger"));
                    }
                }
            });
        })
        .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, "danger"));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, "danger"));
                }
            }
        });
    };
    
    const handleFinalize = async () => {
        setActionStatus("FINALIZE_LOADING");

        await finalizeLaunchpad(launchpadContract)
        .then((res: any) => {
            let resWait = res.wait();
            resWait.then((resTransaction: any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) { 
                    dispatch(finalizeLaunchpadAPI(launchpadDetail?.id, async (res:any) => { 
                        if(res.result === "SUCCESS") {
                            Store.addNotification(addNotify("Finalized launchpad successfully", "success"));
                            setActionStatus("FINALIZED");
                            window.location.reload();
                        }
                        else {
                            setActionStatus("");
                            if (res?.error?.message) {
                                Store.addNotification(addNotify(res?.error?.message, 'danger'));
                            }
                        }
                    }));  
                } else {
                    setActionStatus("");
                    Store.addNotification(addNotify("Transaction failed", "danger"));
                    return;
                }
            })
            .catch((error: any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, "danger"));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, "danger"));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, "danger"));
                    }
                }
            });
        })
        .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, "danger"));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, "danger"));
                }
            }
        });
    };

    const handleAffiliate = async () => {
        if (percentAffiliateInput > 5 || percentAffiliateInput < 0) {
            Store.addNotification(addNotify("0 < Percent Affiliate <= 5] ", "danger"));
            return;
        }
        setActionStatus("AFFILIATE_LOADING");
        await enableAffiliateLaunchpad(launchpadContract, !checkAffiliate, percentAffiliateInput * 100)
        .then((res: any) => {
            let resWait = res.wait();
            resWait.then((resTransaction: any) => {
                if ( resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    if (percentAffiliateInput !== 0) {
                        Store.addNotification(addNotify("Enable Affiliate successfully", "success"));
                        setCheckAffiliate(false);
                    } else {
                        Store.addNotification(addNotify("Disable Affiliate successfully", "success"));
                        setCheckAffiliate(true);
                    }
                    setActionStatus("");
                    window.location.reload();
                } else {
                    setActionStatus("");
                    Store.addNotification(addNotify("Transaction failed", "danger"));
                    return;
                }
            })
            .catch((error: any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, "danger"));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, "danger"));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, "danger"));
                    }
                }
            });
        })
        .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, "danger"));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, "danger"));
                }
            }
        });
    };

    const handleCancelLaunchpad = async () => {
        setActionStatus("CANCEL_LAUNCHPAD_LOADING");
        await cancelLaunchpad(launchpadContract)
        .then((res: any) => {
            let resWait = res.wait();
            resWait.then((resTransaction: any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                //tra token 
                    (async () => {
                        await claimCanceledTokens(launchpadContract).then((res: any) => {
                            let resWait = res.wait();
                            resWait.then((resTransaction: any) => {
                                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                                    dispatch(finalizeLaunchpadAPI(launchpadDetail?.id, async (res:any) => { 
                                        if(res.result === "SUCCESS") {
                                            if(res?.data) { 
                                                Store.addNotification(addNotify("Cancel launchpad successfully", "success"));
                                                setActionStatus("CANCEL_LAUNCHPAD_DONE");
                                                window.location.reload();
                                            }
                                        }
                                        else {
                                            setActionStatus("CANCEL_LAUNCHPAD_DONE");
                                            if (res?.error?.message) {
                                                Store.addNotification(addNotify(res?.error?.message, 'danger'));
                                            }
                                        }
                                    })); 
                                } else {
                                    setActionStatus("CANCEL_LAUNCHPAD_DONE");
                                    Store.addNotification(
                                    addNotify("Transaction failed", "danger")
                                    );
                                    return;
                                }
                            });
                        }
                        );
                    })();
                    setActionStatus("CANCEL_LAUNCHPAD_DONE");
                } else {
                    setActionStatus("");
                    Store.addNotification(addNotify("Transaction failed", "danger"));
                    return;
                }
            })
            .catch((error: any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, "danger"));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, "danger"));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, "danger"));
                    }
                }
            });
        })
        .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, "danger"));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, "danger"));
                }
            }
        });
    };


    const handleWithdrawContribute = async () => {
        setActionStatus("WITHDRAW_CONTRIBUTE_LOADING");
        await withdrawContribute(launchpadContract)
        .then((res: any) => {
            let resWait = res.wait();
            resWait.then((resTransaction: any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    setTotalInvestment(0);
                    setActionStatus("WITHDRAW_CONTRIBUTED");
                    Store.addNotification(
                        addNotify("Withdraw contribute successfully", "success")
                    );
                }
            })
            .catch((error: any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, "danger"));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, "danger"));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, "danger"));
                    }
                }
            });
        })
        .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, "danger"));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, "danger"));
                }
            }
        });
    };

    const handleClaimRewardAffiliate = async () => {
        setActionStatus("REWARD_LOADING");
        await withdrawContributeAffiliate(launchpadContract)
        .then((res: any) => {
            let resWait = res.wait();
            resWait.then((resTransaction: any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    Store.addNotification(
                        addNotify("Withdraw successfully", "success")
                    );
                    setActionStatus("");
                    window.location.reload();
                } else {
                    setActionStatus("");
                    Store.addNotification(addNotify("Transaction failed", "danger"));
                    return;
                }
            })
            .catch((error: any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, "danger"));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, "danger"));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, "danger"));
                    }
                }
            });
        })
        .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, "danger"));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, "danger"));
                }
            }
        });
    };

    const infoDetail = (
        <>
        <h3>
            <p>
                {launchpadDetail?.name}
                <span>{launchpadDetail?.type}</span>
            </p>
            <p>
                {launchpadDetail?.audit ? <span>Audit</span> : ""}
                {launchpadDetail?.kyc ? <span>KYC</span> : ""}
                {launchpadDetail?.safu ? <span>SAFU</span> : ""}
            </p>
        </h3>
        <ul>
            {launchpadDetail?.socialNetwork?.website ? (
            <li>
                <a href={launchpadDetail?.socialNetwork?.website}>
                <img src="../images/detail-icon-website.svg" alt="" />
                </a>
            </li>
            ) : ("")}
            {launchpadDetail?.socialNetwork?.telegram ? (
            <li>
                <a href={launchpadDetail?.socialNetwork?.telegram}>
                <img src="../images/detail-icon-tele.svg" alt="" />
                </a>
            </li>
            ) : ("")}
            {launchpadDetail?.socialNetwork?.twitter ? (
            <li>
                <a href={launchpadDetail?.socialNetwork?.twitter}>
                <img src="../images/detail-icon-tw.svg" alt="" />
                </a>
            </li>
            ) : ("")}
            {launchpadDetail?.socialNetwork?.medium ? (
            <li>
                <a href={launchpadDetail?.socialNetwork?.medium}>
                <img src="../images/detail-icon-medium.svg" alt="" />
                </a>
            </li>
            ) : ("")}
            {launchpadDetail?.socialNetwork?.facebook ? (
            <li>
                <a href={launchpadDetail?.socialNetwork?.facebook}>
                <img src="../images/detail-icon-facebook.svg" alt="" />
                </a>
            </li>) : ("")}
            {launchpadDetail?.socialNetwork?.discord ? (
            <li>
                <a href={launchpadDetail?.socialNetwork?.discord}>
                <img src="../images/detail-icon-discord.svg" alt="" />
                </a>
            </li>) : ("")}
            {launchpadDetail?.socialNetwork?.youtube ? (
                <li>
                    <a href={launchpadDetail?.socialNetwork?.youtube}>
                        <img src="../images/detail-icon-youtube.svg" alt="" />
                    </a>
                </li>
            ) : ("")}
        </ul>
        </>
    );

    const handleChangeAffiliate = (e: any) => {
        setPercentAffiliateInput(e.target.value);
    };

    const onAmount = (e: any) => {
        setValueAmount(e.target.value);
    };

    const rendererTimer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            window.location.reload();
        } else {
            return (
                <div className="c-countDown">
                    <span>{zeroPad(days)}d</span>:<span>{zeroPad(hours)}h</span>:<span>{zeroPad(minutes)}m</span>:<span>{zeroPad(seconds)}s</span>
                </div>
            );
        }
    };

    const handleClassStatus = () => {
        let classStatus: string = "";

        if (startTimer && endTimer) {
            if (nowTimer < startTimer) {
                classStatus = "is-Upcoming";
            } else if (startTimer < nowTimer && nowTimer < endTimer) {
                classStatus = "is-Opening";
            } else if (endTimer < nowTimer) {
                classStatus = "is-Completed";
            }
        }

        return classStatus;
    };

    const getActionButton = () => {
        let _button: any = "",
        _emergencyWithdrawbutton: any = "";

        if (needApproveToken) {
            if (isApprovedToken) {
                _button = (
                    <Button
                        text={`${actionStatus === "BOUGHT" ? "Bought" : "Buy Now"}`}
                        type="blue"
                        onClick={() => handleContribute()}
                        isLoading={actionStatus === "BUY_LOADING" ? true : false}
                        disabled={actionStatus === "BUY_LOADING" || actionStatus === "BOUGHT" || Number(maxContribution) <= Number(totalInvestment) ? true : false}
                    />
                );
            } else {
                _button = (
                    <Button
                        text={`Approve ${launchpadRound?.currencyPay}`}
                        type="blue"
                        onClick={() => handleApproveToken()}
                        isLoading={actionStatus === "APPROVE_LOADING" ? true : false}
                        disabled={actionStatus === "APPROVE_LOADING" ? true : false}
                    />
                );
            }
        } else {
            _button = ( <Button
                            text={`${actionStatus === "BOUGHT" ? "Bought" : "Buy Now"}`}
                            type="blue"
                            onClick={() => handleContribute()}
                            isLoading={actionStatus === "BUY_LOADING" ? true : false}
                            disabled={actionStatus === "BUY_LOADING" || actionStatus === "BOUGHT" ? true : false}
                        />);
        }

        if (totalInvestment > 0) {
            _emergencyWithdrawbutton = (
                <Button
                    text="Urgent Withdrawal"
                    type="red"
                    onClick={() => handleEmergencyWithdraw()}
                    isLoading={actionStatus === "EMERGENCY_WITHDRAW_LOADING" ? true : false}
                    disabled={actionStatus === "EMERGENCY_WITHDRAW_LOADING" || actionStatus === "EMERGENCY_WITHDRAW" ? true : false}
                />
            );
        }
        
        return (
        <>
            {_button} {_emergencyWithdrawbutton}
        </>
        );
    };

    const launchpadAction = () => {
        if (launchpadState === 1) {
            if (nowTimer < startTimer) {
                progressPercent = 0;
                statusText = "Upcoming";
                progressTime = startTimer;
            } else if (startTimer <= nowTimer && nowTimer <= endTimer) {
                if (raisedAmount >= launchpadDetail?.softCap) {
                    statusText = "This pool has filled";
                    progressTime = null;
                } else {
                    statusText = "Opening";
                    progressTime = endTimer;
                    contributeInput = (
                        <>
                        <div className="c-input">
                            <p className="c-input__title">
                                Amount (Max:{" "}
                                {formatNumberAmount(availableMaxAmount, 0, 20)}{" "}
                                {launchpadRound?.currencyPay})
                            </p>
                            <div className="c-input__max">
                                <input
                                    type="text"
                                    disabled={Number(formatNumberAmount(availableMaxAmount, 0, 20)) < 0 ? true : false}
                                    onWheel={(event) => event.currentTarget.blur()}
                                    value={valueAmount}
                                    onChange={(e) => onAmount(e)}
                                />
                                <span onClick={() => setValueAmount(Number(formatNumberAmount(availableMaxAmount, 0, 20)))}>
                                    Max
                                </span>
                            </div>
                        </div>
                        <p className="c-txt">
                            Available: {availableAmount ? formatNumberAmount(availableMaxAmount, 0, 20) : 0}{" "}
                            {launchpadRound?.currencyPay}
                        </p>
                        </>
                    );
                    actionButton = getActionButton();
                }
                progressPercent = launchpadDetail?.softCap && Number(launchpadDetail?.softCap) !== 0 ? formatNumber((Number(raisedAmount) / parseFloat(launchpadDetail?.softCap)) * 100) : 0;
            } else if (endTimer < nowTimer) {
                progressPercent = launchpadDetail?.softCap && Number(launchpadDetail?.softCap) !== 0 ? formatNumber((Number(raisedAmount) / parseFloat(launchpadDetail?.softCap)) * 100) : 0;
                statusText = "Ended";
                progressTime = null;

                if (totalInvestment === 0) {
                    actionButton = (<Button text="You're not join" type="blue" disabled={true} />);
                } else {
                    actionButton = totalInvestment > 0 && !refund &&
                    Number(raisedAmount) < Number(softCap) && (
                        <Button
                        text="Withdraw Contribute"
                        type="blue"
                        onClick={() => handleWithdrawContribute()}
                        isLoading={
                            actionStatus === "WITHDRAW_CONTRIBUTE_LOADING"
                            ? true
                            : false
                        }
                        disabled={
                            refund ||
                            actionStatus === "WITHDRAW_CONTRIBUTE_LOADING" ||
                            actionStatus === "WITHDRAW_CONTRIBUTED"
                            ? true
                            : false
                        }
                        />
                    );
                }
            }
        } else if (launchpadState === 2) {
            progressPercent = launchpadDetail?.softCap && Number(launchpadDetail?.softCap) !== 0 ? formatNumber((Number(raisedAmount) / parseFloat(launchpadDetail?.softCap)) * 100): 0;
            statusText = "Finished";
            progressTime = null;
            
            if (totalInvestment === 0) {
                actionButton = (<Button text="You're not join" type="blue" disabled={true} />);
            } else {
                if (raisedAmount >= softCap) {
                    actionButton = (<Button
                        text={`${totalClaimable > 0 && claimedTokens === 0
                            ? `Claim Token (${totalClaimable.toLocaleString()} ${tokenInform?.symbol})`
                            : `Claimed (${claimedTokens.toLocaleString()} ${tokenInform?.symbol})`}`}
                        type="blue"
                        onClick={() => handleClaimTokens()}
                        isLoading={actionStatus === "CLAIM_LOADING" ? true : false}
                        disabled={!(totalClaimable > 0 && claimedTokens === 0) || actionStatus === "CLAIM_LOADING" || actionStatus === "CLAIMED" ? true : false}
                    />);
                } else {
                    actionButton = totalInvestment > 0 && !refund && (
                        <Button
                            text="Withdraw Contribute"
                            type="blue"
                            onClick={() => handleWithdrawContribute()}
                            isLoading={actionStatus === "WITHDRAW_CONTRIBUTE_LOADING" ? true : false}
                            disabled={refund || actionStatus === "WITHDRAW_CONTRIBUTE_LOADING" || actionStatus === "WITHDRAW_CONTRIBUTED" ? true : false}
                        />
                    );
                }
            }
        
        } else if (launchpadState === 3) {
            progressPercent = 0;
            statusText = "Canceled";
            progressTime = null;
            actionButton = totalInvestment > 0 && !refund ? (
                <Button
                        text="Withdraw Contribute"
                        type="blue"
                        onClick={() => handleWithdrawContribute()}
                        isLoading={actionStatus === "WITHDRAW_CONTRIBUTE_LOADING" ? true : false}
                        disabled={ refund || actionStatus === "WITHDRAW_CONTRIBUTE_LOADING" || actionStatus === "WITHDRAW_CONTRIBUTED" ? true : false}
                />) : ("");
        }

        return (
        <>
            <h3>
            <span>{statusText}</span>
            {progressTime && (
                <Countdown date={progressTime} renderer={rendererTimer} />
            )}
            </h3>
            {account ? (
            <>
                {isSwitch ? (
                <div className="switch-network">
                    <button
                    className="btn-switch-network"
                    onClick={handleSwitchNetwork}
                    >
                    Switch Network
                    </button>
                </div>
                ) : (
                <>
                    <div className="c-progress">
                    <p className="c-progress__title">
                        Progress ({progressPercent}%)
                    </p>
                    <div className="c-progress__bar">
                        <span style={{ width: progressPercent + "%" }}></span>
                    </div>
                    <p className="c-progress__txt">
                        {formatNumber(raisedAmount)} {launchpadRound?.currencyPay}
                        <span>
                        {formatNumber(launchpadDetail?.softCap)}{" "}
                        {launchpadRound?.currencyPay}
                        </span>
                    </p>
                    </div>
                    {contributeInput}
                    <div
                    className="p-launchpadDetail__btnStatus"
                    style={{ marginTop: !(nowTimer <= endTimer) ? "15px" : 0 }}
                    >
                    {actionButton}
                    </div>
                </>
                )}
            </>
            ) : (
            <div className="no-connect">
                <div
                className="btn-not-connect"
                // onClick={handleSwitchNetwork}
                >
                Not Connect Wallet
                </div>
            </div>
            )}
        </>
        );
    };
 
  const handleCopy = () => {
    Store.addNotification(addNotify("Copied !", "success"));
  };

  useEffect(() => {
    axios
      .get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=binancecoin"
      )
      .then((res) => {
        setPriceBNB(res.data[0].current_price);
      });
  }, []);

    const _listUserAffiliate =
        listUserAffiliate &&
        listUserAffiliate.length > 0 &&
        listUserAffiliate.map((item: any) => {
        return <p>{item}</p>;
        });

    const _listAmountAffiliate =
        listAmountAffiliate &&
        listAmountAffiliate.length > 0 &&
        listAmountAffiliate.map((item: any) => {
            return <p>{Number(convertFromWei(item, currencyDecimals))* percertAffiliate /100} {launchpadDetail?.currency}</p>;
        });
    
    const handleNetwork = (network: any) => {
        let networkName: any = "";
        if (network === "BSCTESTNET") {
            networkName = (
                <span className="is-network">
                <img src="../images/icon-bnb.svg" alt="" />
                BSC TESTNET
                </span>
            );
        } else if (network === "BSC") {
            networkName = (
                <span className="is-network">
                <img src="../images/icon-bnb.svg" alt="" />
                Binance Smart Chain
                </span>
            );
        } else if (network === "ETH") {
            networkName = (
                <span className="is-network">
                <img src="../images/icon-eth.svg" alt="" />
                Ethereum
                </span>
            );
        } else if (network === "POLY") {
            networkName = (
                <span className="is-network">
                <img src="../images/icon-polygon.svg" alt="" />
                Polygon
                </span>
            );
        } else if (network === "ARBTESTNET") {
            networkName = (
                <span className="is-network">
                <img src="../images/arb-icon.svg" alt="" />
                ARB TESTNET
                </span>
            );
        } else if (network === "ARB") {
            networkName = (
                <span className="is-network">
                    <img src="../images/arb-icon.svg" alt="" />
                    Arbitrum
                </span>
            );
        }

        return networkName;
    };

    const handleLinkNetwork = (network: any) => {
        let link: any = "";
        if (network === "BSCTESTNET") {
            link = "https://testnet.bscscan.com/address/";
        } else if (network === "BSC") {
            link = "https://bscscan.com/address/";
        } else if (network === "ETH") {
            link = "";
        } else if (network === "POLY") {
            link = "";
        } else if (network === "ARBTESTNET") {
            link = "https://goerli.arbiscan.io/address/";
        } else if (network === "ARB") {
            link = "https://arbiscan.io/address/";
        }
        return link;
    };
  
    const handleSetEndTime = async (isNowTime:boolean) => {
        
        let _endTime:any;
        if(isNowTime) {
            setActionStatus("SET_END_TIME_NOW_LOADING");
            _endTime = Math.floor(new Date().getTime() / 1000);
        }
        else {
            if (!endTimeDate) {
                Store.addNotification(addNotify("Please select end time!", "warning"));
                return;
            }
            setActionStatus("SET_END_TIME_LOADING");
            _endTime = Math.floor(new Date(endTimeDate).getTime() / 1000);
        }

        await setEndTime(launchpadContract, _endTime, isNowTime)
        .then((res: any) => {
            let resWait = res.wait();
            resWait.then((resTransaction: any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    //End Time API
                    const payloadEndTime:any = {
                        "launchpadId": Number(launchpadDetail?.id),
                        "endTime": _endTime
                    }
                    dispatch(setEndTimePool(payloadEndTime, async (res:any) => { 
                        if(res.result === "SUCCESS") {
                            Store.addNotification(addNotify("Set end time successfully", "success"));
                            setActionStatus("SET_END_TIME_DONE");
                            window.location.reload();
                        } else {
                            setActionStatus("");
                            if (res?.error?.message) {
                                Store.addNotification(addNotify(res?.error?.message, 'danger'));
                            }
                        }
                    }));
                } else {
                    setActionStatus("");
                    Store.addNotification(addNotify("Transaction failed", "danger"));
                    return;
                }
            })
            .catch((error: any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, "danger"));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, "danger"));
                } else {
                    if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, "danger"));
                    }
                }
            });
        })
        .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, "danger"));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, "danger"));
                }
            }
        });
    };

    const onEndTimeDate = (dates) => {
        setEndTimeDate(dates?.toISOString());
    }

    return (
    <>
      {launchpadLoading && <Loading />}
      <div className="p-launchpadDetail">
        <div className="c-breadcrumb">
          <ul>
            <li>
              <span>LaunchPads</span>
            </li>
            <li>
              <Link to={`/launchpad/list`}>LaunchPads List</Link>
            </li>
            <li>
              <span>{launchpadDetail?.name}</span>
            </li>
          </ul>
        </div>
        <div className="p-launchpadDetail__banner">
          <img
            src={
              launchpadDetail?.bannerUrl
                ? launchpadDetail?.bannerUrl
                : "../images/bgDefault.svg"
            }
            alt={launchpadDetail?.name}
          />
          <h2 className="p-launchpadDetail__logo">
            <img
              src={
                launchpadDetail?.logoUrl
                  ? launchpadDetail?.logoUrl
                  : "../images/logoDefault.svg"
              }
              alt={launchpadDetail?.name}
            />
            {launchpadDetail?.name}
          </h2>
          {isOwner && (
            <Button
              text="Edit Launchpad Info"
              iconLeft="Edit"
              onClick={() => navigate(`/launchpad-edit/${handle}`)}
            />
          )}
        </div>
        <div className="p-launchpadDetail__detail">
          <div className="p-launchpadDetail__infoSC">
            {size.width <= 767 ? (
              <div className="p-launchpadDetail__info">{infoDetail}</div>
            ) : (
              ""
            )}
            <div className={`p-launchpadDetail__status ${handleClassStatus()}`}>
              {launchpadAction()}
            </div>
            <div className={`p-launchpadDetail__boxFixed`}>
              <p>
                Sale Type
                <span>Public</span>
              </p>
              <p>
                Soft Cap
                <span>
                  {launchpadDetail?.softCap ? formatNumber(launchpadDetail?.softCap) : ''} {launchpadRound?.currencyPay}
                </span>
              </p>
              <p>
                Max Contribution
                <span>
                  {maxContribution ? formatNumber(maxContribution) : ''}{" "}
                  {launchpadRound?.currencyPay}
                </span>
              </p>
              <p>
                Total Contributors<span>{formatNumber(totalContributors)}</span>
              </p>
              <p>
                Your Purchases
                <span>
                  {formatNumberAmount(totalInvestment, 0, 20)} {launchpadRound?.currencyPay}
                </span>
              </p>
              <p>
                Total Claimed
                <span>
                  {formatNumber(claimedTokens)} {tokenInform?.symbol}
                </span>
              </p>
            </div>
          </div>
          <div className="p-launchpadDetail__content">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Pool Information" key="1">
                <div className="p-launchpadDetail__info">
                  {size.width <= 767 ? "" : infoDetail}
                  <div className="p-launchpadDetail__boxInfo">
                    <p className="p-launchpadDetail__row">
                      Start time
                      <span>{formatDate(launchpadDetail?.startTime)}</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      End time
                      <span>{formatDate(launchpadDetail?.endTime)}</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Presale address
                      {launchpadRound?.presaleAddress ? (
                        <span>
                          <a
                            target="_blank"
                            href={`${handleLinkNetwork(
                              launchpadDetail?.networkChain
                            )}${launchpadRound?.presaleAddress}`}
                          >
                            {launchpadRound?.presaleAddress?.substring(0, 4) +
                              "..." +
                              launchpadRound?.presaleAddress?.substr(
                                launchpadRound?.presaleAddress?.length - 4
                              )}
                          </a>
                          <CopyToClipboard
                            text={launchpadRound?.presaleAddress}
                            onCopy={() => handleCopy()}
                          >
                            <button>
                              <img src="../images/icon-copy.svg" alt="" />
                            </button>
                          </CopyToClipboard>
                        </span>
                      ) : (
                        <span>--</span>
                      )}
                    </p>
                    <p className="p-launchpadDetail__row">
                      Network{handleNetwork(launchpadRound?.networkChain)}
                    </p>
                    <p className="p-launchpadDetail__row">
                        Total token selling amount
                      <span>
                        {!isNaN(tokenForPreasle) ? formatNumber(tokenForPreasle) : 0} {tokenInform?.symbol}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Token for liquidity
                      <span>
                        {!isNaN(tokenForLiquidity) ? formatNumber(tokenForLiquidity) : 0} {tokenInform?.symbol}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Soft cap
                      <span>
                        {launchpadDetail?.softCap ? formatNumber(launchpadDetail?.softCap) : ''} {launchpadRound?.currencyPay}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                        Max Contribution
                      <span>
                        {maxContribution>0 ? formatNumber(maxContribution) : softCap}{" "}
                        {launchpadRound?.currencyPay}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Listing on<span>{dexInfo?.name}</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Liquidity percent
                      <span>{launchpadRound?.liquidityPercent || "--"}%</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Liquidity lockup time
                      <span>
                        {launchpadRound?.liquidityLockup || "--"} minutes after
                        pool ends
                      </span>
                    </p>
                  </div>
                  {checkAffiliate && (
                    <div className="p-launchpadDetail__chart">
                      <h3>Affiliate Program</h3>
                      <p className="p-launchpadDetail__row">
                        Affiliate Link
                        <span>
                            {account &&
                            `bluesale.finance...${ 
                              account?.substring(account?.length - 4)
                            }`} 
                          <CopyToClipboard
                            text={process.env.REACT_APP_URL + `fairlaunch/${launchpadDetail?.handle}?refID=${account}`}
                            onCopy={() => handleCopy()}
                          >
                            <button>
                              <img src="../images/icon-copy.svg" alt="" />
                            </button>
                          </CopyToClipboard>
                        </span>
                      </p>
                      <div className="your-rewards">
                        <p className="p-launchpadDetail__row">Your Rewards </p>
                        <Button
                          text={ affiliateValue?.totalRefAmount === 0 && launchpadState === 2 && affiliateValue?.yourReward ? "Claimed" :`Claim ${affiliateValue?.yourReward ?? 0} ${launchpadRound?.currencyPay}`}
                          type="blue"
                          isLoading={
                            actionStatus === "REWARD_LOADING" ? true : false
                          }
                          disabled={
                            (affiliateValue?.totalRefAmount === 0 || launchpadState !== 2) ||
                            actionStatus === "REWARD_LOADING"
                              ? true
                              : false
                          }
                          onClick={() => handleClaimRewardAffiliate()}
                        />
                      </div>
                      <p className="p-launchpadDetail__row">
                        Pool Referrer Count{" "}
                        <span>{Number(affiliateValue?.poolReferrerCount)}</span>
                      </p>
                      <p className="p-launchpadDetail__row">
                        Reward Percentage<span>{percertAffiliate}%</span>
                      </p>
                      <p className="p-launchpadDetail__row">
                        Total Rewards<span>{formatNumberAmount(Number(affiliateValue?.totalRefAmount * percertAffiliate) / 100, 0 ,9)  ?? 0} {launchpadRound?.currencyPay}</span>
                      </p>
                      <div className="verifyToken affiliate-row"> 
                        <p className="p-launchpadDetail__row">
                          Max Rewards<span>{!isNaN(softCap * percertAffiliate) ? formatNumberAmount(Number(softCap * percertAffiliate / 100), 0 ,9) : 0 ?? 0} {launchpadRound?.currencyPay}</span>  
                        </p>
                         <span className="swaprate assume">assume softcap reached</span> 
                      </div> 
                      <p className="p-launchpadDetail__row">
                        Total Ref Amount
                        <span>{affiliateValue?.totalRefAmount ?? 0} {launchpadRound?.currencyPay}</span>
                      </p>
                      <div className="p-launchpadDetail__selectRadio">
                        <Button
                          text="List of Top Rewards"
                          onClick={() => setIsListedOfTopRewards(true)}
                        />
                      </div>
                    </div>
                  )}

                  <div className="p-launchpadDetail__chart">
                    <h3>Token Metrics</h3>
                    <Doughnut data={data} options={chartOptions} />
                  </div>
                </div>
              </TabPane>
              <TabPane tab="About the Project" key="2">
                <div className="p-launchpadDetail__info">
                  {size.width <= 767 ? "" : infoDetail}
                  <p className="p-launchpadDetail__txt">
                    {launchpadDetail?.info}
                  </p>
                </div>
              </TabPane>
              <TabPane tab="Token Information" key="3">
                <div className="p-launchpadDetail__info">
                  {size.width <= 767 ? "" : infoDetail}
                  <div className="p-launchpadDetail__boxInfo">
                    <p className="p-launchpadDetail__row">
                      Name<span>{tokenInform?.name}</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Symbol<span>{tokenInform?.symbol}</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Decimals<span>{tokenInform?.decimals}</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Address
                      {tokenInform?.tokenAddress ? (
                        <span>
                          <a
                            target="_blank"
                            href={`${handleLinkNetwork(
                              tokenInform?.networkChain
                            )}${tokenInform?.tokenAddress}`}
                          >
                            {tokenInform?.tokenAddress?.substring(0, 4) +
                              "..." +
                              tokenInform?.tokenAddress?.substr(
                                tokenInform?.tokenAddress?.length - 4
                              )}
                          </a>
                          <CopyToClipboard
                            text={tokenInform?.tokenAddress}
                            onCopy={() => handleCopy()}
                          >
                            <button>
                              <img src="../images/icon-copy.svg" alt="" />
                            </button>
                          </CopyToClipboard>
                        </span>
                      ) : (
                        <span>--</span>
                      )}
                    </p>
                    <p className="p-launchpadDetail__row">
                      Total Supply
                      <span>{formatNumber(tokenInform?.totalSupply)}</span>
                    </p>
                  </div>
                </div>
              </TabPane>
              {isOwner && (
                <TabPane tab="Owner Zone" key="4">
                  <div className="p-launchpadDetail__info">
                    {size.width <= 767 ? "" : infoDetail}
                    {notification && (
                      <p className="c-notification">
                        <button onClick={() => setNotification(false)}>
                          <img src="../images/icon-close-12px.svg" alt="" />
                        </button>
                        To make sure there will be no issues during the presale
                        time, please do not send tokens to wallets before you
                        finalize the presale pool.
                        <span>Pool fee: 5% {launchpadRound?.currencyPay} raised only</span>
                      </p>
                    )}
                    <div className="p-launchpadDetail__boxInfo mt-16">
                      <h3>
                        Pool Actions
                        <span>
                          To Finalize presale, you have to exclude token
                          transfer fee for presale contract
                        </span>
                      </h3>
                      <div className="p-launchpadDetail__btn">
                        <Button
                          text="Cancel"
                          type="greyBorder"
                          isLoading={
                            actionStatus === "CANCEL_LAUNCHPAD_LOADING"
                              ? true
                              : false
                          }
                          disabled={
                            (isOwner && !canCancel) ||
                            actionStatus === "CANCEL_LAUNCHPAD_LOADING" ||
                            actionStatus === "CANCEL_LAUNCHPAD_DONE"
                              ? true
                              : false
                          }
                          onClick={() => handleCancelLaunchpad()}
                        />
                        <Button text='List of Contributor' onClick={() => setIsListOfContribution(true)} />
                        <Button
                          isLoading={
                            actionStatus === "AFFILIATE_LOADING" ? true : false
                          }
                          disabled={
                            (isOwner && launchpadState !== 1) || 
                            actionStatus === "AFFILIATE_LOADING" ? true : false
                          }
                          text={`${
                            checkAffiliate ? "Disable" : "Enable"
                          }  Affiliate Program`}
                          onClick={() =>
                            checkAffiliate
                              ? handleAffiliate()
                              : setCheckAffiliateBtn(true)
                          }
                        />
                        {!checkAffiliateBtn ? null : (
                          <div className="p-launchpadDetail__selectRadio affiliate-value">
                            <Input
                              status={
                                percentAffiliateInput < 0 || percentAffiliateInput > 5
                                  ? "error"
                                  : ""
                              }
                              className="c-dateEdit"
                              placeholder="Precent Affiliate"
                              onChange={(e) => handleChangeAffiliate(e)}
                            />
                            <Button
                              disabled={
                                actionStatus === "AFFILIATE_LOADING"
                                  ? true
                                  : false
                              }
                              text="OK"
                              type="blue"
                              onClick={() => handleAffiliate()}
                            />
                          </div>
                        )}

                        <Button
                          text="Finalize"
                          type="blue"
                          isLoading={
                            actionStatus === "FINALIZE_LOADING" ? true : false
                          }
                          disabled={
                            (isOwner && !canFinalize) ||
                            (isOwner && launchpadState !== 1) || 
                            (isOwner &&
                              canFinalize &&
                              (actionStatus === "FINALIZED" ||
                                actionStatus === "FINALIZE_LOADING"))
                              ? true
                              : false
                          }
                          onClick={() => handleFinalize()}
                        />
                      </div>
                    </div>
                    <div className="p-launchpadDetail__boxInfo mt-16">
                        <h3>
                            Set End Time
                        </h3>
                        <div className="p-launchpadDetail__selectRadio">
                          <p className="c-ttl">Set End Time</p>
                            <DatePicker showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onOk={onEndTimeDate} />
                            <Button
                                text="Set End Now"
                                type="greyBorder"
                                onClick={() => handleSetEndTime(true)}
                                isLoading={actionStatus === "SET_END_TIME_NOW_LOADING" ? true : false}
                                disabled={actionStatus === "SET_END_TIME_NOW_LOADING" || actionStatus === "SET_END_TIME_DONE"}
                            />
                            <Button
                                text="Set End With Special Time"
                                type="blue"
                                onClick={() => handleSetEndTime(false)}
                                isLoading={actionStatus === "SET_END_TIME_LOADING" ? true : false}
                                disabled={actionStatus === "SET_END_TIME_LOADING" || actionStatus === "SET_END_TIME_DONE"}
                            />
                        </div>
                    </div>
                    </div>
                </TabPane>
              )}
            </Tabs>
          </div>
        </div>
      </div>

      {isListedOfTopRewards && (
        <div className="c-popup">
          <div className="c-popup__content is-btnAbsolute">
            <h3 className="c-popup__title">List Of Top Rewards</h3>
            <div className="flex-sp mb-0">
              <p className="c-ttl">Wallet address</p>
              <p className="c-ttl">Rewards</p>
            </div>
            <div className="flex-sp mb-0">
              <div className="c-popup__whitelist affiliate-list">
                {_listUserAffiliate}
              </div>
              <div className="c-popup__whitelist affiliate-list">
                {_listAmountAffiliate }
              </div>
            </div>

            <div className="c-popup__btn button-100">
              <Button
                text="Cancel"
                type="greyBorder"
                onClick={() => setIsListedOfTopRewards(false)}
              />
            </div>
          </div>
        </div>
      )}

      {isListOfContribution && (
        <div className="c-popup">
          <div className="c-popup__content is-btnAbsolute">
            <h3 className="c-popup__title">List of Contributor</h3>
            <p className="c-ttl">Wallet address</p>
            <div className="c-popup__whitelist">
              {listOfContribution && listOfContribution.length > 0 && listOfContribution.map((item: any, key:any) => {
                return <p key={key}>{size.width <= 767 ? item?.substring(0, 24) + "..." + item?.substr(item?.length - 4) : item}</p>;
              })}
              {}
            </div>
            <div className="c-popup__btn button-100">
              <Button
                text="Cancel"
                type="greyBorder"
                onClick={() => setIsListOfContribution(false)}
              />
            </div>
          </div>
        </div>
      )}
    </>
    );
};

export default FairlaunchDetail;
