import * as dayjs from 'dayjs';
export const convertDateTime = (dateTime) => {
    var options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC',
        timeZoneName: 'short',
        hour12: true
    };

    return dateTime.toLocaleTimeString('en-GB', options);
}

// export const formatNumber = (number, decPlaces, decSep, thouSep) => {
//   decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
//   decSep = typeof decSep === "undefined" ? "." : decSep;
//   thouSep = typeof thouSep === "undefined" ? "," : thouSep;
//   var sign = number < 0 ? "-" : "";
//   var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
//   var j = (j = i.length) > 3 ? j % 3 : 0;

//   return sign +
//       (j ? i.substr(0, j) + thouSep : "") +
//       i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
//       (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
// }