import { ArrowRightOutlined, DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Progress, Row } from 'antd';
import { useState, useEffect } from 'react'; 


const Tokenomic = () => {
   
    
    return (
        <div className='container'>
            <div className='tokenomic-block'>
                <h3>Tokenomic</h3>
                <div className='tokenomic-info'>
                    <Row>   
                        <Col xs={24} sm={6} md={6} lg={6} xl={6} className="text-center"> 
                            <p className="text-center">Max supply: <span className='purple-text font-medium'>10,000,000</span> BLS</p>
                        </Col>
                        <Col xs={24} sm={6} md={6} lg={6} xl={6} className="text-center"> 
                            <p className="text-center">Name: <span className='purple-text font-medium'>BlueSale Token</span></p>
                        </Col>
                        <Col xs={24} sm={6} md={6} lg={6} xl={6} className="text-center"> 
                            <p className="text-center">Ticker: <span className='purple-text font-medium'>BLS</span> <img width={25} height={25} src="../images/bls-icon-50.svg" /></p>
                        </Col>
                        <Col xs={24} sm={6} md={6} lg={6} xl={6} className="text-center"> 
                            <p className="text-center">Chain: <span className='purple-text font-medium'>Arbitrum</span> <img width={25} height={25} src="../images/arb-chain-icon.svg" /></p>
                        </Col>
                    </Row>
                </div>
                <div className='tokenomic-chart'>
                    <img src='../images/tokenomic-chart.svg' />
                    <div className='tokenomic-chart-info'>
                        <ul>
                            <li>
                                <div className='color-chart-info' style={{background: '#5667F1'}}></div>
                                <div className='name-chart-info'>Private</div>
                                <div className='percent-chart-info' style={{color: '#5667F1'}}>18%</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#137DC3'}}></div>
                                <div className='name-chart-info'>Public</div>
                                <div className='percent-chart-info' style={{color: '#137DC3'}}>8%</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#546BB9'}}></div>
                                <div className='name-chart-info'>Protocol Grow Incentives</div>
                                <div className='percent-chart-info' style={{color: '#546BB9'}}>17,5%</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#7259AA'}}></div>
                                <div className='name-chart-info'>Reserves</div>
                                <div className='percent-chart-info' style={{color: '#7259AA'}}>8%</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#B455A7'}}></div>
                                <div className='name-chart-info'>Marketing + Airdrop</div>
                                <div className='percent-chart-info' style={{color: '#B455A7'}}>7%</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#F6BA33'}}></div>
                                <div className='name-chart-info'>Development</div>
                                <div className='percent-chart-info' style={{color: '#F6BA33'}}>6,5%</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#F15B86'}}></div>
                                <div className='name-chart-info'>Liquidity</div>
                                <div className='percent-chart-info' style={{color: '#F15B86'}}>10%</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#5EC8C5'}}></div>
                                <div className='name-chart-info'>Boost Pool</div>
                                <div className='percent-chart-info' style={{color: '#5EC8C5'}}>10%</div>
                            </li>
                            <li>
                                <div className='color-chart-info' style={{background: '#31C1FF'}}></div>
                                <div className='name-chart-info'>Core contributors</div>
                                <div className='percent-chart-info' style={{color: '#31C1FF'}}>15%</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
   )
}
 
export default Tokenomic;