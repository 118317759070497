import { Contract } from "ethers";
import web3 from "../utils/web3";
import { convertFromWei, convertToWei } from "./utils";

export const tokenDecimals = async (contract:Contract | any) => {
    return await contract.decimals();
}

export const tokenSymbol = async (contract:Contract | any) => {
    return await contract.symbol();
}

export const tokenName = async (contract:Contract | any) => {
    return await contract.name();
}

export const tokenTotalSupply = async (contract:Contract | any) => {
    const _decimals = await tokenDecimals(contract);
    const _totalSupply = await contract.totalSupply();
    return parseFloat(convertFromWei(_totalSupply, _decimals));
}

export const tokenInformation = async (contract:Contract | any) => {
    const _symbol = await tokenSymbol(contract);
    const _decimals = await tokenDecimals(contract);
    const _name = await tokenName(contract);
    const _totalSupply = await tokenTotalSupply(contract);
    
    return {
        "tokenName": _name,
        "symbol": _symbol,
        "decimals": _decimals,
        "tokenAddress": contract.address,
        "totalSupply": _totalSupply
    };
}

export const getTokenAllowance = async (contract:Contract | any, owner:string | any | null | undefined, spender:string | any | null | undefined) => {
    const _decimals:any = tokenDecimals(contract);
    const _allowance = await contract.allowance(owner, spender);
    return parseFloat(convertFromWei(_allowance, _decimals));
}

export const tokenApprove = async (contract:Contract | any, spender:string) => {
    const args = [spender, convertToWei(10 * 1e6, 18)]; 
    return await contract.approve(...args);
}

export const getTokenBalanceOf = async (contract:Contract | any, owner:string | any | null | undefined) => {
    const _decimals:any = tokenDecimals(contract);
    const _balance = await contract.balanceOf(owner);
    return parseFloat(convertFromWei(_balance, _decimals));
}
