import { PayloadAction } from "@reduxjs/toolkit";
import http from "./http-common";

type PriceObject = {
    [key: string]: void | string | number | any;
};
  
export interface IPrice {
    usd: PriceObject;
}
const PriceService = {
    async getPrice (tokenId:string, api:any = 'coingecko') {
        let prices = {};
        if(api === 'coingecko') {
            const res = await http.get(`https://api.coingecko.com/api/v3/simple/price?ids=${tokenId}&vs_currencies=usd`);
            
            for (const [key, value] of Object.entries(res.data)) {
                if(value) 
                    prices[key] = value;
            }
            return prices; 
        }
        else if(api === 'cryptorank') {
            return await http.get(`https://api.cryptorank.io/v1/currencies?api_key=f3bc66d719ac0d75bd6bfdaa3978e8f80c09cf7e0239850d527a377f1828&symbols=${tokenId}`);
        }
        
    },

    async getPriceBLS () {
        return http.get(`https://api.coingecko.com/api/v3/simple/price?ids=BLUESALE&vs_currencies=usd`);
    }
};
export default PriceService;
