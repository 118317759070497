import React, { useCallback, useEffect, useState } from 'react';
import { DownOutlined, LeftOutlined, LoadingOutlined, LogoutOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Modal, Progress, Row, Spin } from 'antd';
import { createBrowserHistory } from 'history';
import ConnectWallet from '../../../components/header/wallet/connectWallet';
import useActiveWeb3React from '../../../hooks/useActiveWeb3React';
import DividendsSection from './dividends-section';
import JoinPublicSale from "../../../contracts/abi/JoinPublicSale.json";
import web3 from 'web3';
import { ethers } from "ethers";
import {
  _totalJoined,
  gettotalJoinedItem,
  _getDecimalToken
} from "../public-sale-utils";
import { useContract, useTokenContract } from "../../../hooks/useContract";
import BluesaleRoundsService from '../../../redux/services/bluesaleRoundsService';
import { convertDateTime } from '../formatDatetime';
import Countdown from 'react-countdown';
import useWindowSize from '../../../hooks/useWindowSize';
import { CHAINID_FULLNAME, CHAINID_SYMBOL, MAPPING_CHAINID_DECIMAL, RPC_URL_CONVERT, SALE_CURRENCIES } from '../../../constants';
import useConnectWallet from '../../../hooks/useConnectWallet';
import ClaimAirdrop from './ClaimAirdrop';

declare const window: Window & typeof globalThis & { ethereum: any };

const BlsTokenSection = () => {  
    const history = createBrowserHistory();
    const roundDefault:any = history.location.search.replace('?', '') ? history.location.search.replace('?', '') : 'PRIVATE';
    const [roundDetail, setRoundDetail] = useState<any>();
    const [tokenDecimal, setTokenDecimal] = useState<any>(0);
    const [showConnect, setShowConnect] = useState<any>(false);
    const [showDisConnect, setShowDisConnect] = useState<any>(false);
    const [isLoading, setIsLoading] = useState<any>(false);
    const [authToken, setAuthToken] = useState<any>();
    const { account, chainId } = useActiveWeb3React(); 
    const [rounds, setRounds] = useState<any>([]);
    const [totalJoined, setTotalJoined] = useState<any>(0);
    const { walletLogout } = useConnectWallet();

    const [roundActive, setRoundActive] = useState<any>(roundDefault);
    const size = useWindowSize()

    const joinPublicSaleContract:any = useContract(roundDetail?.roundAddress, JoinPublicSale);

    let tokenJoinAddess:any = roundDetail?.tokenJoinAddress;
    const tokenContract = useTokenContract(tokenJoinAddess);

    let roundChain:any = roundDetail?.networkChain.toLowerCase();

    const chainIdWallet:any = chainId;
    const roundChainId:any = MAPPING_CHAINID_DECIMAL[roundChain];
    const rountChainName:any = CHAINID_FULLNAME[roundChain];

    const getAuthToken = async (account) => {
        await BluesaleRoundsService.getAuthToken(account)
        .then((res)=>{
            setAuthToken(res.data.id_token);
        })
        .catch((error:any)=>{});
    }

    const getRounds = async (authToken) => {
       
        await BluesaleRoundsService.getRoundList(authToken)
        .then(async (res)=>{
            
            for (var i = 0, len = res.data.rounds.length; i < len; i++) {
                if(res.data.rounds[i]?.roundType !== "AIRDROP"){
                    if(JoinPublicSale && account && tokenDecimal > 0){
                        let data: any = await gettotalJoinedItem(JoinPublicSale, res.data.rounds[i].roundAddress);
                        if(data){
                            res.data.rounds[i].totalJoined = parseFloat(ethers.utils.formatUnits(data, tokenDecimal));
                        }
                    }else{
                        res.data.rounds[i].totalJoined = 0;
                    }
                }
            }
            
            setRounds(res.data.rounds);

            let round:any = res.data.rounds.find(element => element.roundType === roundDefault);
            setRoundDetail(round);
        })
        .catch((error:any)=>{});
        
    } 

    const getTokenDecimal = async ()=>{
        let decimalSc = await _getDecimalToken(tokenContract);
        if(decimalSc){
            let decimal = decimalSc.toString();
            setTokenDecimal(decimal);
        }
    }

    useEffect(() => {
        if(account){
            if(tokenContract){
                getTokenDecimal();
            }
        }
    }, [account, tokenContract]);
    useEffect(() => {
        if(account){
            getAuthToken(account);
        }
    }, [account]);

    useEffect(() => {
        getRounds(authToken);    
    }, [authToken, tokenDecimal]);

    
    const changeRoundActive = (id:any, round: any, idRoundSelected:any)=>{
        let roundDetail:any = rounds.find(element => parseInt(element.id) === parseInt(id));
        setRoundActive(round);
        setRoundDetail(roundDetail);
        history.push({
            pathname: '',
            search: round
        });

        if(size.width < 768){
            const element = document.getElementById(idRoundSelected);
            if (element) {
                let yourHeight:any = '70';
                element.scrollIntoView(true);
                var scrolledY = window.scrollY;
                if(scrolledY){
                    window.scroll({left: 0, top: scrolledY - yourHeight, behavior: "smooth"});
                }
            }
        }

        
        
    }

    const handleConnectWallet = ()=>{
        setShowConnect(true);
    }

    const getTotalJoined = async () => {
        if(joinPublicSaleContract){
            let total = await _totalJoined(joinPublicSaleContract);
            if(total){
                let totalWei = ethers.utils.formatUnits(total, tokenDecimal);
                setTotalJoined(parseFloat(totalWei));
            }
        }
    };

    useEffect(() => {
        if (account && joinPublicSaleContract && tokenDecimal > 0) {
            console.log('d', tokenDecimal);
            getTotalJoined();
        }
    }, [account, joinPublicSaleContract, tokenDecimal]);

    
    useEffect(() => {
        if (account && showConnect) {
            setShowConnect(false);

            if(size.width < 768){
                const element:any = document.getElementById("buy-claim-block");
                if (element) {
                    let yourHeight:any = '70';
                    element.scrollIntoView(true);
                    var scrolledY = window.scrollY;
                    if(scrolledY){
                        window.scroll({left: 0, top: scrolledY - yourHeight, behavior: "smooth"});
                    }
                }
            }

            
        }
    }, [account, showConnect]);

    const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            window.location.reload();
            return;
        }
        return (
            <>
                <span>{days}</span> : <span>{hours}</span> : <span>{minutes}</span> : <span>{seconds}</span>
            </>
        );
    };
    const getProgressTime = useCallback((startTime) => {

        const now = new Date();
        const utcDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), 0);
        const startDate = new Date(startTime);
        const startTS = Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            startDate.getHours(),
            startDate.getMinutes(),
            startDate.getSeconds(),
            0
        );
        let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600);
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60);

        if (days >= 1) {
            return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        } else {
            return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        }
    }, []);

    const changeNetwork = async (chainIdDetail, data) => {
        setIsLoading(true);
        try {
            if (data) {
                await window.ethereum.enable();
                const params = {
                    chainId: `0x${parseInt(data.chainId).toString(16)}`, // A 0x-prefixed hexadecimal string
                    chainName: data.name,
                    nativeCurrency: {
                        name: data.currency,
                        symbol: data.currency, // 2-6 characters long
                        decimals: 18,
                    },
                    rpcUrls: [data.rpcUrl,],
                    blockExplorerUrls: [
                        data?.blockExplorer,
                    ],
                };
                window.ethereum
                    .request({
                        method: 'wallet_addEthereumChain',
                        params: [params, account]
                    })
                    .then((result) => {
                        console.log(result);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: `0x${parseInt(data.chainId).toString(16)}` }], // chainId must be in hexadecimal numbers
                });
                setIsLoading(false);
            }
        } catch (error: any) {
            if (error.code === 4902) {
                try {
                    if (data) {
                        await window.ethereum.request({
                            method: "wallet_addEthereumChain",
                            params: [
                                {
                                    chainId: chainIdDetail,
                                    rpcUrl: data.rpcUrl,
                                },
                            ],
                        });
                    }

                } catch (addError) {
                    // handle "add" error
                }
                setIsLoading(false);
            }
            console.error(error);
            setIsLoading(false);
        }
    };
    let dataNetwork:any = {
        chainId: roundChainId,
        name: rountChainName,
        currency: SALE_CURRENCIES[roundChainId]?.[0],
        symbol: CHAINID_SYMBOL[roundDetail?.networkChain],
        rpcUrl: RPC_URL_CONVERT[roundChainId],
        blockExplorer: process.env.REACT_APP_BSC_NETWORK === "MAINNET" ? 'https://arbiscan.io' : 'https://goerli.arbiscan.io'
    }

    const onShowDisconnect = ()=>{
        setShowDisConnect(true);
    }
    const onDisconnect = ()=>{
        walletLogout();
        setShowDisConnect(false);
    }

    const RoundSelected = (props) => {
        const {roundActive} = props
        let blsTotalPool:any = roundDetail?.totalSale * (parseInt(roundDetail?.blsPercent)/100)/roundDetail?.price;
        let xBlsTotalPool:any = roundDetail?.totalSale * ((100 - roundDetail?.blsPercent)/100)/roundDetail?.price;
        let totalSold:any = totalJoined;
       
        let soldPercent:any = totalSold > 0 ? (totalSold/parseFloat(roundDetail?.totalSale) * 100).toFixed(1) : 0;
     
        let claimTime:any = new Date(roundDetail?.startClaim);
        let listingTime:any = new Date(roundDetail?.listingDate);

        return (
            <>
                {roundDetail ? (
                    <>
                        <div className='bls-price-info' id='round-selected'>
                            <Row>
                                <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                    <div className='bls-price'>
                                        <span>Price: <strong>{roundDetail?.price}</strong> {roundDetail?.currencyPay}</span>
                                        <span>{blsTotalPool.toLocaleString()} BLS</span>
                                        <span>{xBlsTotalPool.toLocaleString()} xBLS</span>
                                        <span>{totalJoined ? totalJoined.toLocaleString() : 0} {roundDetail?.currencyPay} / {roundDetail?.totalSale.toLocaleString()} {roundDetail?.currencyPay}</span>
                                    </div>
                                </Col> 
                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <span className='text-round'>{roundActive == "COMMUNITY" ? "Community Round":"Whitelist Round"}</span> 
                                    <div className='bls-price-img'>
                                        <img src='../images/bls-price-img.svg'/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='bls-round-selected-content'>
                            <div className='bls-sold-info p-20'>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className='sold-info purple-text'>
                                            <span className='title'>Sold</span> <span>{soldPercent}%</span></div>
                                    </Col>
                                    {/* {soldPercent < 100 && ( */}
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='sold-info text-right purple-text'>
                                                <span className='title'>Remain</span> 
                                                <span> {soldPercent < 100 ? 100 - soldPercent : 0}%</span>
                                            </div>
                                        </Col>
                                    {/* )} */}
                                    
                                </Row>
                                <Progress className='bls-sold-process' percent={soldPercent} showInfo={false} />
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className='sold-info'>{roundDetail?.currencyPay}</div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className='sold-info text-right'>{roundDetail?.totalSale.toLocaleString()} {roundDetail?.currencyPay}</div>
                                    </Col>
                                </Row>
                                <div className='bls-vesting-info'>
                                    Vesting: <span>TGE 100% ({roundDetail?.blsPercent}% BLS, {100 - roundDetail?.blsPercent}% xBLS)</span>
                                </div>
                            </div>
                            <div className='bls-sold-timing p-20'>
                                <p>Claim Time: <span> {roundDetail?.startClaim ? convertDateTime(claimTime) : 'TBA' }</span></p>
                                <p>Listing Time: <span>{roundDetail?.listingDate ? convertDateTime(listingTime) : 'TBA' }</span></p>
                            </div>
                        </div>
                        <div className='bls-round-selected-button'>
                            {account ? (
                                <>
                                    {parseInt(chainIdWallet) === parseInt(roundChainId) ? (
                                        <button className='btn-white' onClick={onShowDisconnect}>
                                            <img src='../images/wallet-money.svg'/>
                                            {account?.substring(0, 5)}...{account?.substring(account.length - 5)}
                                        </button>
                                    ):(
                                        <>
                                            <button
                                                onClick={() => changeNetwork(roundChainId, dataNetwork)}
                                                className='btn-white btn-danger'
                                                disabled={isLoading}
                                            >
                                                    Switch to {rountChainName} 
                                                    {isLoading && (
                                                        <Spin className="style-loading" size="small" />
                                                    )}
                                            </button>
                                        </>
                                    )}
                                </>
                            ):(
                                <div className=''>
                                    <button className='btn-main' onClick={handleConnectWallet}>Connect Wallet</button>
                                </div>
                            )}
                            
                        </div>
                    </>
                ):(
                    <div className='bls-price-info text-center'>
                        <Spin><LoadingOutlined className='font-30'/></Spin>
                    </div>
                )}
                
            </>
        )
    }
    const RoundAirdropSelected = (props) => {
        
        return (
            <>
                {roundDetail ? (
                    <>
                        <ClaimAirdrop roundDetail={roundDetail} authToken={authToken}/>
                        <div className='bls-round-selected-button'>
                            {account ? (
                                <>
                                    {parseInt(chainIdWallet) === parseInt(roundChainId) ? (
                                        <button className='btn-white' onClick={onShowDisconnect}>
                                            <img src='../images/wallet-money.svg'/>
                                            {account?.substring(0, 5)}...{account?.substring(account.length - 5)}
                                        </button>
                                    ):(
                                        <>
                                            <button
                                                onClick={() => changeNetwork(roundChainId, dataNetwork)}
                                                className='btn-white btn-danger'
                                                disabled={isLoading}
                                            >
                                                    Switch to {rountChainName} 
                                                    {isLoading && (
                                                        <Spin className="style-loading" size="small" />
                                                    )}
                                            </button>
                                        </>
                                    )}
                                </>
                            ):(
                                <div className=''>
                                    <button className='btn-main' onClick={handleConnectWallet}>Connect Wallet</button>
                                </div>
                            )}
                        </div>
                    </>
                ):(
                    <div className='bls-price-info text-center'>
                        <Spin><LoadingOutlined className='font-30'/></Spin>
                    </div>
                )}
                
            </>
        )
    }
 
    return (
        <div className='container'>
            <div className='bls-token-section'> 
                <div className='icon-public-sale'>
                    <img src='../images/icon-public-sale.svg'/>
                </div> 
                <div className='bls-token-title'>
                    <span>BLS</span> Token
                </div> 
                <p>Experience a seamless ownership process from token creation to launchpad management with BlueSale.</p>
                <div className='bls-token-arb-chain'>
                    <button type='button'>
                        <img src='../images/arb-chain-icon.svg'/>
                        <span className='purple-text'>Available on Arbitrum</span>
                    </button>
                </div>
                <div className='bls-token-round'>
                    <Row>
                        <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                            <div className='bls-round-block block-align-right'> 
                                {rounds?.length > 0 ? (
                                    rounds.map((item, index) => {

                                        if(item.roundType !== "AIRDROP"){
                                            let roundName:any = item.roundType === 'COMMUNITY' ? 'Community' : 'Whitelist';
                                            let startSale:any = new Date(item?.startTime);
                                            let endSale:any = new Date(item?.endTime);
                                            let now = new Date();
                                            let soldPercent:any = (item.totalJoined/item?.totalSale * 100).toFixed(1);

                                            return (
                                                <>
                                                    {item.roundType === 'COMMUNITY' &&  <div className='circleblue-effect'>
                                                        <img src='../images/circleblue.svg'/>
                                                    </div>} 
                                                    <div onClick={()=> changeRoundActive(item.id, item.roundType, 'round-selected')} className={`bls-round-item ${roundActive === item.roundType ? 'round-item-active' : ''} ${index > 0 ? 'mt-20' : ''}`} key={`round-${index}`} >
                                                        <div className='round-info'> 
                                                            <Row>
                                                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                                    <div className='round-name'>
                                                                        {roundName} Round
                                                                    </div>
                                                                </Col>
                                                                {soldPercent < 100 && (
                                                                    <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                                                        <div className='round-remain'>
                                                                            <span className='remain'>Remain</span>
                                                                            <span className='percent'> {soldPercent < 100 ? 100 - soldPercent : 0}% </span> 
                                                                            <span className='totalSale'>({(item?.totalSale > item.totalJoined ? item?.totalSale - item.totalJoined : 0).toLocaleString()} {item?.currencyPay})</span>
                                                                        </div>
                                                                    </Col>
                                                                )}
                                                                
                                                            </Row>
                                                        </div>
                                                        <>
                                                            {startSale > now && endSale > now && (
                                                                <>
                                                                    <div className='round-time-coundown'>
                                                                        {size.width < 700 ? "" : "Start in"} 
                                                                        <Countdown
                                                                            date={getProgressTime(startSale)}
                                                                            renderer={rendererCountDown}
                                                                        />
                                                                    </div>
                                                                        <div className='round-time-info'>
                                                                        Start sale: <span>{convertDateTime(startSale)}</span>
                                                                    </div>
                                                                    <div className='round-accepted-payment'>
                                                                        <span className='payment'> Accepted Payment: </span>
                                                                        <span className='currency'>{item?.currencyPay} </span>
                                                                        <img src='../images/usdc-icon.svg'/>
                                                                    </div> 
                                                                </>
                                                            )}
                                                            {startSale < now && endSale > now && (
                                                                <>
                                                                    <div className='round-time-coundown'>
                                                                        {size.width < 700 ? "" : "End in"} 
                                                                        <Countdown
                                                                            date={getProgressTime(endSale)}
                                                                            renderer={rendererCountDown}
                                                                        />
                                                                    </div>
                                                                    <div className='round-time-info'>
                                                                        End sale: <span>{convertDateTime(endSale)}</span>
                                                                    </div>
                                                                    <div className='round-accepted-payment'>
                                                                        <span className='payment'> Accepted Payment: </span>
                                                                        <span className='currency'>{item?.currencyPay} </span>
                                                                        <img src='../images/usdc-icon.svg'/>
                                                                    </div> 
                                                                </>
                                                            )}
                                                            {startSale < now && endSale < now && (
                                                                <>
                                                                    <div className='text-center'>
                                                                        <img src='../images/icon-soldout.svg' />
                                                                    </div>
                                                                    <div className='text-danger font-18 font-medium text-center mt-15'>
                                                                        Ended
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    </div>
                                                </>
                                            )
                                        }else{
                                            let startAirdrop:any = new Date(item?.startTime);
                                            let endAirdrop:any = new Date(item?.endTime);
                                            let now = new Date();
                                            let roundName:any = item.roundType === 'AIRDROP' ? 'KOLs & Airdrop' : '';
                                            return (
                                                <>
                                                    <div onClick={()=> changeRoundActive(item.id, item.roundType, 'round-selected')} className={`bls-round-item ${roundActive === item.roundType ? 'round-item-active' : ''} ${index > 0 ? 'mt-20' : ''}`} key={`round-${index}`} >
                                                        <div className='round-info'> 
                                                            <Row>
                                                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                                    <div className='round-name'>{roundName}</div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <>
                                                            {startAirdrop > now && endAirdrop > now && (
                                                                <>
                                                                    <div className='round-time-coundown'>
                                                                        {size.width < 700 ? "" : "Start in"} 
                                                                        <Countdown
                                                                            date={getProgressTime(startAirdrop)}
                                                                            renderer={rendererCountDown}
                                                                        />
                                                                    </div>
                                                                        <div className='round-time-info'>
                                                                        Claim start: <span>{convertDateTime(startAirdrop)}</span>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {startAirdrop < now && endAirdrop > now && (
                                                                <>
                                                                    <div className='round-time-coundown'>
                                                                        {size.width < 700 ? "" : "End in"} 
                                                                        <Countdown
                                                                            date={getProgressTime(endAirdrop)}
                                                                            renderer={rendererCountDown}
                                                                        />
                                                                    </div>
                                                                    <div className='round-time-info'>
                                                                        Claim end: <span>{convertDateTime(endAirdrop)}</span>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {startAirdrop < now && endAirdrop < now && (
                                                                <>
                                                                    <div className='text-center'>
                                                                        <img src='../images/icon-soldout.svg' />
                                                                    </div>
                                                                    <div className='text-danger font-18 font-medium text-center mt-15'>
                                                                        Airdrop Ended
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    </div>
                                                </>
                                            )
                                        }

                                        
                                    })
                                ):(
                                    <Spin className='text-center'><LoadingOutlined className='font-30'/></Spin>
                                )} 
                            </div>
                        </Col>
                      
                        <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                            <div className='bls-round-center d-mobile'>
                                <div className='icon-arrow'>
                                    <img src='../images/gif_arrow.gif'/>
                                </div>
                            </div>
                        
                        </Col>
                        <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                            <div className='bls-round-block block-align-left'>
                                <div className='bls-round-item-selected'>
                                    {roundDetail?.roundType !== "AIRDROP" ? (
                                        <>
                                            <RoundSelected roundActive={roundActive}/>
                                        </>
                                    ):(
                                        <>
                                            <RoundAirdropSelected roundActive={roundActive} authToken={authToken}/>
                                        </>
                                    )}
                                    
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                {account && parseInt(chainIdWallet) === parseInt(roundChainId) && roundDetail.roundType !== "AIRDROP" && (
                    <DividendsSection roundDetail={roundDetail} authToken={authToken} tokenDecimal={tokenDecimal}/>
                )}
                
            </div>
            <Modal
                open={showConnect}
                footer={false}
                onOk={() => { setShowConnect(false); }}
                onCancel={() => { setShowConnect(false); }}
                className="public-bluesale-connect"
            >
                <ConnectWallet setConnectWallet />
            </Modal>
            <Modal
                open={showDisConnect}
                footer={false}
                onCancel={() => { setShowDisConnect(false); }}
                className="public-bluesale-connect"
            >
                <div className='bls-account-detail'>
                    <div className='your-wallet'>You Wallet</div>
                    <div className='your-wallet-content'>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-left">
                               <img src="../images/icon-popup-wallet.svg" />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                                <button className='btn-connected'>
                                    <svg style={{marginRight: '5px'}} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="5" cy="5" r="4" fill="#12FD8C"/>
                                        <circle cx="5" cy="5" r="4.5" stroke="#12FD8C" stroke-opacity="0.3"/>
                                    </svg>

                                    Connected
                                </button>
                            </Col>
                        </Row>
                        <Row className='mt-10'>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-left">
                                Wallet address: 
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-right">
                                {account && (
                                    <span className='purple-text font-medium'>{account?.substring(0, 8)}...{account?.substring(account.length - 8)}</span>
                                )}
                            </Col>
                        </Row>
                        <Row className='mt-15'>
                            <Col xs={24} className="text-center">
                                <button className='btn-white w-full' onClick={onDisconnect}><LogoutOutlined /> Disconnect</button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal>
        </div>
   )
}
 
export default BlsTokenSection;