import { createSlice, PayloadAction } from "@reduxjs/toolkit"; 

// ================================================
// ==                  ACTION                    ==
// ================================================ 


// ================================================
// ==                REDUX STORE                 ==
// ================================================

type TokenObject = {
  [key: string]: void | string | number | any;
};

export interface TokenInformState { 
    tokenLock: TokenObject;  
    loading: boolean;
    errors: string;
}

const initialState: TokenInformState = { 
    tokenLock: {}, 
    loading: false,
    errors: "",
}

const tokenInformSlice = createSlice({
  name: "tokenInforms",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },  
    
    setTokenLock: (state, { payload }: PayloadAction<TokenObject>) => {
      state.tokenLock = payload
    },  
    
  },
})

export const { setLoading, setErrors, setTokenLock } = tokenInformSlice.actions

export default tokenInformSlice.reducer;

export const tokenLockSelector = (state: { tokenLockStore: TokenInformState }) => state.tokenLockStore; 
 