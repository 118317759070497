import React from "react";  
import { Link } from 'react-router-dom';
import { useActiveWeb3React } from "../../../hooks";
import { useAppDispatch} from "../../../redux/hooks";
import { authenticate } from "../../../redux/slicers/authenticateSlice";
import { getHeader } from "../../../redux/slicers/headerSlice";
interface IFormCreate {
    setConnectNetwork: any,
}

const ListCreate : Array<object> = [
    {title: 'Create Token', link: '/token/create-token'},
    {title: 'Create Lock', link: '/token/create-lock'},
    {title: 'Create Launchpad', link: '/launchpad/create-launchpad?type=1', type: 1},
    {title: 'Create Fair Launch', link: '/launchpad/create-fairlaunch'},
    {title: 'Create Private Sale', link: '/private-sale/create?type=3'},
    {title: 'Create Airdrop', link: '/airdrop/create'},  
]; 

const FormCreate : React.FC<IFormCreate> =(props: IFormCreate) => {
    const dispatch = useAppDispatch();
    const { account, chainId } = useActiveWeb3React();
    const handleClick = () =>{ 
        dispatch(getHeader("")); 
    }

    const elmCreate = ListCreate.map((item: any,key :number) => (
        <Link to={item?.link} className="tabwallet" key={key} onClick={() => handleClick()} >
            <div className="listwallet_left">  
                <span className="title">{item.title}</span>
            </div>  
        </Link>
    ));
    return (  
        <ul className='popupwallet create'>
            <div className="smalltriangle create" /> 
            {elmCreate}
        </ul> 
    )
}
export default FormCreate;