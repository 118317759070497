import { Contract } from '@ethersproject/contracts';
import { getAddress } from '@ethersproject/address';
import { AddressZero } from '@ethersproject/constants';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import web3NoAccount from './web3';

export const BIG_ZERO = new BigNumber(0);
export const BIG_ONE = new BigNumber(1);
export const BIG_NINE = new BigNumber(9);
export const BIG_TEN = new BigNumber(10);

// account is not optional
export function getSigner(library:any, account:any) {
	return library.getSigner(account).connectUnchecked();
}

export function getNormalSigner(library:any, account:any) {
	return library.getSigner(account);
}

// account is optional
export function getProviderOrSigner(library:any, account:any) {
	return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(address:any, ABI:any, library:any, account:any) {
	if (!isAddress(address) || address === AddressZero) {
		throw Error(`Invalid 'address' parameter '${address}'.`);
	}

	return new Contract(address, ABI, getProviderOrSigner(library, account));
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value:any) {
	try {
		return getAddress(value);
	} catch {
		return false;
	}
}

export const formatValue = (value:any, amountFixed:any) => {
	let newValue:any = 0;
	value = parseFloat(value);
	if (parseFloat(value) > 0) {
		newValue = value.toFixed(amountFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	} else {
		newValue = '0,00';
	}

	return newValue;
};

export const getSignature = async (account:any, library:any, message:any) => {
	const provider =  getProviderOrSigner(library, account);
	const signature = await provider.signMessage(message);
	return signature;
};

export const getBalanceBNB = async (account: string, web3?: Web3) => {
	return new Promise((resolve, reject) => {
	  const _web3 = web3 ?? web3NoAccount;
	  _web3.eth
		.getBalance(account)
		.then((data) => {
		  resolve(data);
		})
		.catch((err) => {
		  console.log('err',err);
		  reject(err);
		})
	})
  }