import { Contract , ethers} from "ethers"; 
import { convertToWei, formatAntiBotInfos, formatAntiBotStatusInfo } from "./utils";
import AntibotABI from "../contracts/abi/GSAntiBot.json";
declare const window: Window & typeof globalThis & { ethereum: any }; 

export const configAntiBot = async (antibotContract: Contract | any, tokenAddress: string, pairToken: string, routerExchange: string, factoryExchange: string, amountLimitPerTrade: any, amountToBeAddedPerBlock: any, timeLimitPerTrade: any, totalLockBlocks: any) => {
    return await antibotContract.configAntiBot(
        tokenAddress,
        pairToken,
        routerExchange,
        factoryExchange,
        amountLimitPerTrade,
        amountToBeAddedPerBlock,
        timeLimitPerTrade,
        totalLockBlocks
    );
}

export const getAntiBotInfos = async (antibotContract: Contract | any, tokenInform:any) => {
    return new Promise(async (resolve, reject) => {
        await antibotContract.antiBotInfos(tokenInform?.tokenAddress)
        .then((res:any) => {   
            const data:any = formatAntiBotInfos(res, tokenInform?.decimals);
            resolve(data);
        })
        .catch((err:any)=>{
            reject(err);
        });
    });
}

export const getAntiBotStatusInfo = async (antibotContract: Contract | any, tokenInform:any) => {
    // console.log("🚀 ~ file: antibot.ts:33 ~ getAntiBotStatusInfo ~ tokenInform", tokenInform)
    return new Promise(async (resolve, reject) => {
        await antibotContract.getTokenStatus(tokenInform?.tokenAddress)
        .then((res:any) => { 
            // console.log("🚀 ~ file: antibot.ts:37 ~ .then ~ res", res?.amountLimit.toString().length)
            const data:any = formatAntiBotStatusInfo(res, tokenInform?.decimals);
            resolve(data);
        })
        .catch((err:any)=>{
            reject(err);
        });
    });
}

export const getBlackList = async (antibotContract: Contract | any, tokenAddress:string) => {
    return await antibotContract.getBlackList(tokenAddress);
}

export const getWhiteList = async (antibotContract: Contract | any, tokenAddress:string) => {
    return await antibotContract.getWhiteList(tokenAddress);
}

export const setWhitelistUsers = async (antibotContract: Contract | any, tokenAddress:string, status:boolean, usersAddress:any) => {
    return await antibotContract.whitelistUsers(tokenAddress, status, usersAddress);
}

export const setBlacklistUsers = async (antibotContract: Contract | any, tokenAddress:string, status:boolean, usersAddress:any) => {
    return await antibotContract.blacklistUsers(tokenAddress, status, usersAddress);
}

export const getTotalWhiteList = async (antibotContract: Contract | any, tokenAddress:string) => {
    return await antibotContract.totalWhiteList(tokenAddress);
}

export const getTotalBlackList = async (antibotContract: Contract | any, tokenAddress:string) => {
    return await antibotContract.totalBlackList(tokenAddress);
}

export const setEnableAntiBot = async (antibotContract: Contract | any, tokenAddress:string, isEnabled:boolean) => {   
     return await antibotContract.enableAntiBot(tokenAddress, isEnabled, {
        value: await antibotContract.fundAmount()
    });
}

export const setContractTransfer = async (tokenContract: Contract | any ,isEnabled:boolean) => { 
    return await tokenContract.setEnableAntiBot(isEnabled);
}

export const getStatusContractTransfer = async (tokenContract: Contract | any ) => { 
    return await tokenContract.enableAntiBot();
}
