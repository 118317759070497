/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RadioChangeEvent } from "antd";
import { Radio, Tabs, DatePicker, Skeleton } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { CSVLink } from 'react-csv';
import { isMobile } from 'react-device-detect';
import {
  Button,
  // InputChange,
  SearchChange,
  Loading,
} from "../../components/component";
import useWindowSize from "../../hooks/useWindowSize";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  finalizeLaunchpadAPI,
  getLaunchpadDetail,
  joinedLaunchpadAPI,
  launchPadSelector,
} from "../../redux/slicers/launchpadSlice";
import { formatDate } from "../../utils/formatDate";
import { formatNumber } from "../../utils/formatNumber";
import Countdown, { zeroPad } from "react-countdown";
import { useActiveWeb3React } from "../../hooks";
import { formatData } from "../../utils/formatData";
import { usePrivateSaleContract } from "../../hooks/useContract";
import networkChange from "../../utils/networkChange";
import {
  // getOwnerZoneInfo,
  // getUserClaimAble,
  // getJoinInfos, 
  // getTeamCliffVesting,
  claimTokens,
  contribute,
  emergencyWithdrawContribute,
  finalizeLaunchpad,
  cancelLaunchpad,
  setWhitelistPoolPrivateSale,
  addWhiteListUsers,
  removeWhiteListUsers,
  // setLaunchpadAllocations,
  // getGsLock,
  // getLpLockId,
  // getTeamLockId,
  // getRouterAddress,
  withdrawContribute,
  // setClaimTime,
  // getJoinedUsers,
  getPrivateSaleType,
  getPrivateSaleInfo,
  privateSaleGetState,
  approveToken,
  getTokenAllowance,
  getRaisedAmountPrivateSale,
  getAmountApprove,
  _investInfos,
  allInvestorCount,
  getListOfWhiteListUsersPS,
  isWhiteListBuyer,
  _totalClaimed,
  withdrawableTokens,
  getInvestors,
  getAmountsInvest
} from "../../contracts/privateSaleUltil";
import { Store } from "react-notifications-component";
import addNotify from "../../components/commons/Notify/addNotify";
import { getCurrencyDecimals } from "../../components/createlaunchpad/ultils";
import { ethers } from "ethers";
import { SALETYPE_WHITELIST_POOL, CURRENCIES_ADDRESS } from "../../constants";
import { formatNum } from "../bls/utils";
import StandardTokenABI from "../../contracts/abi/StandardTokenABI.json";


ChartJS.register(ArcElement, Tooltip, Legend);

interface Size {
  width: number;
  height: number;
}

const PrivateSaleDetail: React.FC = () => {
  const { account, chainId, library } = useActiveWeb3React();
  const [loadingEnableWhitelist, setLoadingEnableWhitelist] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [notification, setNotification] = useState<boolean>(true);
  // const [saleType, setSaleType] = useState<number>(0);
  const [isAddedWhitelist, setIsAddedWhitelist] = useState<boolean>(false);
  const [isRemovedWhitelist, setIsRemovedWhitelist] = useState<boolean>(false);
  const [isListedWhitelist, setIsListedWhitelist] = useState<boolean>(false);
  const [isListContributor, setIsListContributor] = useState<boolean>(false);
  const [disableWhitelist, setDisableWhitelist] = useState<boolean>(false);
  const [valueAmount, setValueAmount] = useState<number>(0);
  const [totalInves, setTotalInves] = useState<number>(0);

  const [softCap, setSoftCap] = useState<any>();
  //   const [hardCap, setHardCap] = useState<any>();
  const [raisedAmount, setRaisedAmount] = useState<any>(0);
  const [listingTime, setListingTime] = useState<any>();
  // const [feeTokenAddress, setFeeTokenAddress] = useState<any>();
  const [actionStatus, setActionStatus] = useState("");
  const [actionWLPStatus, setActionWLPStatus] = useState("");
  const [canCancel, setCanCancel] = useState<any>();
  // const [canFinalize, setCanFinalize] = useState<any>();
  const [isOwner, setIsOwner] = useState<any>();
  const [amountApprove, setAmountApprove] = useState<any>();
  // const [whitelistPoolOwner, setWhitelistPoolOwner] = useState<any>();
  // const [priceBNB, setPriceBNB] = useState<any>();
  // const [teamCliffVesting, setTeamCliffVesting] = useState<number>();
  const [listOfWhiteListUsers, setListOfWhiteListUsers] = useState<any>([]);
  const [addListOfWhiteListUsers, setAddListOfWhiteListUsers] = useState<any>(
    []
  );

  const [listAddContributor, setListAddContributor] = useState<any>([]);
  const [listAmountContributor, setListAmountContributor] = useState<any>([]);

  const [removeListOfWhiteListUsers, setRemoveListOfWhiteListUsers] =
    useState<any>([]);
  const [isUserWhitelist, setIsUserWhitelist] = useState<boolean>(false);
  const [isWhitelistPool, setIsWhitelistPool] = useState<boolean>(false);
  const [whitelistPool, setWhitelistPool] = useState<any>(-1);
  const [totalClaimable, setTotalClaimable] = useState(0);
  const [filterSearch, setFilterSearch] = useState<string>('');
  const { TabPane } = Tabs;
  const size: Size = useWindowSize();
  const { handle } = useParams<{ handle: any }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { launchpadDetail, launchpadLoading } =
    useAppSelector(launchPadSelector);
  // const { tokenInformsByAddress } = useAppSelector(tokenInformsSelector);
  const launchpadRound =
    launchpadDetail?.rounds &&
    launchpadDetail?.rounds.length > 0 &&
    launchpadDetail?.rounds[0];
  const tokenInform = launchpadDetail?.tokenInform;
  // const vestingTeam = launchpadDetail?.vestingTeam;
  const vestingContributor = launchpadDetail?.vestingContributor;
  const nowTimer = new Date();
  const startTimer = new Date(launchpadDetail?.startTime);
  const endTimer = new Date(launchpadDetail?.endTime);

  const launchpadContract = usePrivateSaleContract(
    launchpadRound?.presaleAddress
  );
  // const [isApprovedToken, setIsApprovedToken] = useState<boolean>(false);
  const [launchpadState, setLaunchpadState] = useState<number>(-1);
  // const [totalContributors, setTotalContributors] = useState<number>(0);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [claimedTokens, setClaimedTokens] = useState(0);
  const [totalUserTokens, setTotalUserTokens] = useState(0);
  // const [routerAddress, setRouterAddress] = useState("");
  const [refund, setRefund] = useState<boolean>();
  // const [holdingAmount, setHoldingAmount] = useState<any>("");
  // const [holdingName, setHoldingName] = useState<any>("");
  // const [holdingSymbol, setHoldingSymbol] = useState<any>("");
  // const [holdingDecimals, setHoldingDecimals] = useState<any>(18);
  const [totalOwnerClaimed, setTotalOwnerClaimed] = useState(0);
  const [ownerClaimNext, setOwnerClaimNext] = useState(0);

  const [allowance, setAllowance] = useState<any>(0);

  //   const [currency, setCurrency] = useState<any>(ethers.constants.AddressZero);
  const [currency, setCurrency] = useState<any>();

  const currencyDecimals = getCurrencyDecimals(
    chainId,
    launchpadDetail?.currency
  );

  let progressPercent: any | number = 0;
  let statusText: any = "";
  let progressTime: any = "";
  let contributeInput: any = null;
  let actionButton: any = null;


  useEffect(() => {
    if (handle) {
      dispatch(getLaunchpadDetail(handle));
    }
  }, [handle]);

  const getStatePrivateSale = async () => {
    try {
      const stateSC = await privateSaleGetState(launchpadContract, account);
      setLaunchpadState(parseInt(stateSC));
    } catch (e) {
      setLaunchpadState(0)
      console.log(e);
    }
  };


  const getSCInfo = async () => {
    try {
      const privateSaleTypeSC = await getPrivateSaleType(
        launchpadContract,
        account
      );
      const raisedAmountSC = await getRaisedAmountPrivateSale(
        launchpadContract,
        account
      );

      const getListInvestors = await getInvestors(
        launchpadContract
      );
      setListAddContributor(getListInvestors)
      const getListAmountsInvest = await getAmountsInvest(
        launchpadContract
      );
      setListAmountContributor(getListAmountsInvest)
      const investorCount = await allInvestorCount(launchpadContract)
      setTotalInves(Number(investorCount))
      setWhitelistPool(parseInt(privateSaleTypeSC));
      setRaisedAmount(
        parseFloat(
          ethers.utils.formatUnits(raisedAmountSC, parseInt(currencyDecimals))
        )
      );
    } catch (e) {
      console.log(e);
    }
  };


  const getTokenApprove = async (tokenAddress, spender) => {
    if (launchpadDetail?.currency !== 'ETH') {
      let allowanceSC = await getTokenAllowance(
        StandardTokenABI,
        tokenAddress,
        library,
        account,
        spender
      );
      setAllowance(
        parseFloat(
          ethers.utils.formatUnits(allowanceSC, parseInt(currencyDecimals))
        )
      );
    } else {
      setAllowance(1e18)
    }

  };

  useEffect(() => {
    if (account && launchpadContract) {
      getStatePrivateSale();
      getSCInfo();
    }
  }, [launchpadContract, account, totalInvestment]);


  useEffect(() => {
    (async () => {
      await getListOfWhiteListUsersPS(launchpadContract).then((res: any) => {
        setListOfWhiteListUsers(res);
      })

      if (account) {
        await isWhiteListBuyer(launchpadContract, account).then((res: any) => {
          setIsUserWhitelist(res)
        })
      }

    })();
  }, [account, launchpadContract, isListedWhitelist, disableWhitelist])


  useEffect(() => {
    if (
      currency &&
      launchpadRound?.presaleAddress &&
      account &&
      currency.toLowerCase() !== ethers.constants.AddressZero.toLowerCase() &&
      launchpadRound?.presaleAddress.toLowerCase() !==
      ethers.constants.AddressZero.toLowerCase()
    ) {
      getTokenApprove(currency, launchpadRound?.presaleAddress);
    }
  }, [account, currency, launchpadRound?.presaleAddress]);

  useEffect(() => {
    if (launchpadContract && account) {
      (async () => {
        await getPrivateSaleInfo(
          launchpadContract,
          tokenInform?.decimals,
          currencyDecimals,
          launchpadRound?.presaleAddress
        )
          .then((launchpadInfoSC: any) => {
            setSoftCap(launchpadInfoSC?.softCap);
            setIsWhitelistPool(launchpadInfoSC?.isWhitelist);
            setCurrency(
              CURRENCIES_ADDRESS[launchpadRound?.chainId][launchpadRound?.currencyPay]
            );
          })
          .catch((e) => {
            console.log("private sale info error", e);
          });
      })();
      setIsOwner(
        launchpadDetail?.ownerAddress.toLocaleLowerCase() ===
          account?.toLocaleLowerCase()
          ? true
          : false
      );
    }
  }, [launchpadContract, account, launchpadRound]);

  useEffect(() => {
    if (Number(launchpadRound?.maxBuy) - Number(totalInvestment) === 0) {
      setActionStatus("BOUGHT");
    } else {
      setActionStatus("");
    }
  }, [totalInvestment, account, launchpadRound]);

  let availableAmount: number = Number(launchpadDetail?.hardCap - raisedAmount);

  useEffect(() => {
    if (launchpadRound?.presaleAddress && launchpadDetail?.currency !== 'ETH'
    ) {
      console.log('aunchpadDetail?.currency---', launchpadDetail?.currency)
      getAmountApprove(
        StandardTokenABI,
        currency,
        library,
        account,
        launchpadRound?.presaleAddress
      )
        .then((res) => {
          setAmountApprove(Number(res) / (1 * 10 ** currencyDecimals));
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      setAmountApprove(1e18)
    }
  }, [launchpadRound, launchpadDetail]);

  const handleApproveToken = async () => {
    try {
      setLoading(true);
      if (!launchpadRound?.presaleAddress) {
        Store.addNotification(
          addNotify("Presale Address is invalid!", "danger")
        );
        return;
      }
      // console.log('currency', currency)
      await approveToken(
        StandardTokenABI,
        currency,
        library,
        account,
        launchpadRound?.presaleAddress,
        valueAmount
      )
        .then((res) => {
          let resWait = res.wait();
          resWait
            .then((resTransaction: any) => {
              if (
                resTransaction &&
                resTransaction.status &&
                resTransaction.blockNumber
              ) {
                setLoading(false);
                if (
                  currency &&
                  launchpadRound?.presaleAddress &&
                  account &&
                  currency.toLowerCase() !==
                  ethers.constants.AddressZero.toLowerCase() &&
                  launchpadRound?.presaleAddress.toLowerCase() !==
                  ethers.constants.AddressZero.toLowerCase()
                ) {
                  getTokenApprove(currency, launchpadRound?.presaleAddress);
                  getAmountApprove(
                    StandardTokenABI,
                    currency,
                    library,
                    account,
                    launchpadRound?.presaleAddress
                  )
                    .then((res) => {
                      setAmountApprove(Number(res) / 1 * 10 ** currencyDecimals);
                    })
                    .catch((err) => {
                      console.log("err", err);
                    });
                }
                Store.addNotification(
                  addNotify("Tokens approved successfully", "success")
                );
                setActionStatus("");
              } else {
                setLoading(false);

                Store.addNotification(
                  addNotify("Transaction failed", "warning")
                );
                return;
              }
            })
            .catch((error: any) => {
              setLoading(false);
              if (error.code === 4001 && error.message) {
                Store.addNotification(addNotify(error.message, "danger"));
              } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, "danger"));
              } else {
                if (error.data && error.data.message) {
                  Store.addNotification(
                    addNotify(error.data.message, "danger")
                  );
                }
              }
            });
        })
        .catch((error) => {
          console.log('error----', error);
          setLoading(false);
          if (error.code === 4001 && error.message) {
            Store.addNotification(addNotify(error.message, "danger"));
          } else if (error.reason) {
            Store.addNotification(addNotify(error.reason, "danger"));
          } else {
            if (error.data && error.data.message) {
              Store.addNotification(addNotify(error.data.message, "danger"));
            }
          }
        });
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleClaimTokens = async () => {
    setActionStatus("CLAIM_LOADING");
    await claimTokens(launchpadContract)
      .then((res: any) => {
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              Store.addNotification(
                addNotify("Tokens claimed successfully", "success")
              );
              setActionStatus("CLAIMED");
              setClaimedTokens(claimedTokens + totalClaimable);
              setTotalClaimable(0);
              getInvestInfos()
            } else {
              setActionStatus("");
              Store.addNotification(addNotify("Transaction failed", "warning"));
              return;
            }
          })
          .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        setActionStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };


  const getInvestInfos = async () => {
    await _investInfos(launchpadContract, account).then((res) => {
      setTotalInvestment(Number(res.totalInvestment) / (1 * 10 ** currencyDecimals))
    })
      .catch((err) => {
        console.log('err----', err)
      })

    await _totalClaimed(launchpadContract).then((res) => {
      setTotalOwnerClaimed(Number(res) / (1 * 10 ** currencyDecimals))
    })

    await withdrawableTokens(launchpadContract).then((res) => {
      setOwnerClaimNext(Number(res) / (1 * 10 ** currencyDecimals))
    })
  }

  useEffect(() => {
    if (launchpadContract) {
      getInvestInfos()
    }
  }, [account, launchpadContract, launchpadState])


  const handleContribute = async () => {
    // availableAmount = Math.floor(((launchpadDetail?.hardCap - raisedAmount)* 1000) / 1000);
    availableAmount = formatNum(launchpadDetail?.hardCap - raisedAmount)
    if (amountApprove < valueAmount) {
      Store.addNotification(addNotify("not approve!", "warning"));
      return;
    }
    if (
      !valueAmount ||
      valueAmount < launchpadRound?.minBuy ||
      valueAmount > launchpadRound?.maxBuy ||
      valueAmount > availableAmount
    ) {
      Store.addNotification(addNotify("Amount is invalid!", "warning"));
      return;
    }
    if (valueAmount > (launchpadRound?.maxBuy - Number(totalInvestment))) {
      Store.addNotification(addNotify("Amount is invalid!", "warning"));
      return;
    }
    setActionStatus("BUY_LOADING");
    await contribute(
      launchpadContract,
      valueAmount,
      currencyDecimals,
      launchpadRound?.currencyPay
    )
      .then((res: any) => {
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              dispatch(joinedLaunchpadAPI(launchpadDetail?.id, async (res: any) => {
                if (res.result === "SUCCESS") {
                  Store.addNotification(addNotify("Bought successfully", "success"));
                }
                else {
                  setActionStatus("");
                  if (res?.error?.message) {
                    Store.addNotification(addNotify(res?.error?.message, 'danger'));
                  }
                }
              }));
              launchpadAction()
              if (
                Number(launchpadRound?.maxBuy) - Number(totalInvestment) ===
                0
              ) {
                setActionStatus("BOUGHT");
              } else {
                setActionStatus("");
              }
              setTotalInvestment(Number(totalInvestment) + Number(valueAmount));
              setRaisedAmount(Number(raisedAmount) + Number(valueAmount));
              setValueAmount(0);
              //   if (raisedAmount >= hardCap) {
              //     setCanFinalize(true);
              //   }
            } else {
              setActionStatus("");
              Store.addNotification(addNotify("Transaction failed", "warning"));
              return;
            }
          })
          .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {

        setActionStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };

  const handleEmergencyWithdraw = async () => {
    setActionStatus("EMERGENCY_WITHDRAW_LOADING");
    await emergencyWithdrawContribute(launchpadContract)
      .then((res: any) => {
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              Store.addNotification(
                addNotify("Emergency withdraw successfully", "success")
              );
              setActionStatus("EMERGENCY_WITHDRAW");
              window.location.reload();
            } else {
              setActionStatus("");
              Store.addNotification(addNotify("Transaction failed", "danger"));
              return;
            }
          })
          .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        setActionStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };

  const handleFinalize = async () => {
    setActionStatus("FINALIZE_LOADING");
    try {
      await finalizeLaunchpad(launchpadContract)
        .then((res: any) => {
          let resWait = res.wait();
          resWait
            .then((resTransaction: any) => {
              if (
                resTransaction &&
                resTransaction.status &&
                resTransaction.blockNumber
              ) {
                dispatch(finalizeLaunchpadAPI(launchpadDetail?.id, async (res: any) => {
                  console.log('res', res)
                  if (res.result === "SUCCESS") {
                    // if (res?.data) {
                    Store.addNotification(addNotify("Finalized private sale successfully", "success"));
                    setLaunchpadState(2)
                    setActionStatus("FINALIZED");
                    //  window.location.reload()
                    // }
                  }
                  else {
                    setActionStatus("");
                    if (res?.error?.message) {
                      Store.addNotification(addNotify(res?.error?.message, 'danger'));
                    }
                  }
                }));
              } else {
                setActionStatus("");
                Store.addNotification(addNotify("Transaction failed", "danger"));
                return;
              }
            })
            .catch((error: any) => {
              setActionStatus("");
              if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, "danger"));
              } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, "danger"));
              } else {
                if (error.data && error.data.message) {
                  Store.addNotification(addNotify(error.data.message, "danger"));
                }
              }
            });
        })
        .catch((error: any) => {
          setActionStatus("");
          if (error.code == 4001 && error.message) {
            Store.addNotification(addNotify(error.message, "danger"));
          } else if (error.reason) {
            Store.addNotification(addNotify(error.reason, "danger"));
          } else {
            if (error.data && error.data.message) {
              Store.addNotification(addNotify(error.data.message, "danger"));
            }
          }
        });
    } catch (error) {
      setActionStatus("");
      Store.addNotification(addNotify('Error', "danger"));
    }

  };

  const handleCancelLaunchpad = async () => {
    setActionStatus("CANCEL_LAUNCHPAD_LOADING");
    try {
      await cancelLaunchpad(launchpadContract)
        .then((res: any) => {
          let resWait = res.wait();
          resWait
            .then((resTransaction: any) => {
              if (
                resTransaction &&
                resTransaction.status &&
                resTransaction.blockNumber
              ) {
                dispatch(finalizeLaunchpadAPI(launchpadDetail?.id, async (res: any) => {
                  if (res.result === "SUCCESS") {
                    Store.addNotification(addNotify("Cancel private sale successfully", "success"));
                    setLaunchpadState(3)
                    setActionStatus("CANCEL_LAUNCHPAD_DONE");
                    //  window.location.reload()

                  }
                  else {
                    setActionStatus("");
                    if (res?.error?.message) {
                      Store.addNotification(addNotify(res?.error?.message, 'danger'));
                    }
                  }
                }));

              } else {
                setActionStatus("");
                Store.addNotification(addNotify("Transaction failed", "danger"));
                return;
              }
            })
            .catch((error: any) => {
              setActionStatus("");
              if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, "danger"));
              } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, "danger"));
              } else {
                if (error.data && error.data.message) {
                  Store.addNotification(addNotify(error.data.message, "danger"));
                }
              }
            });
        })
        .catch((error: any) => {
          setActionStatus("");
          if (error.code == 4001 && error.message) {
            Store.addNotification(addNotify(error.message, "danger"));
          } else if (error.reason) {
            Store.addNotification(addNotify(error.reason, "danger"));
          } else {
            if (error.data && error.data.message) {
              Store.addNotification(addNotify(error.data.message, "danger"));
            }
          }
        });
    } catch (error) {
      setActionStatus("");
      Store.addNotification(addNotify('Error', "danger"));
    }

  };

  const handleWhitelistPool = async (
    wlPool: number,
    isModal: boolean = false
  ) => {
    // console.log("wlPool", wlPool);
    setLoadingEnableWhitelist(true);
    let _holdingTokenAddress: any;
    let _amount: any = 0;

    _holdingTokenAddress = ethers.constants.AddressZero.toLocaleLowerCase();
    _amount = 0;

    setActionWLPStatus("LOADING_" + wlPool);

    await setWhitelistPoolPrivateSale(
      launchpadContract,
      wlPool,
      _holdingTokenAddress,
      _amount,
      tokenInform?.decimals
    )
      .then((res: any) => {
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              setLoadingEnableWhitelist(false);
              // (async () => {
              //     await getOwnerZoneInfo(launchpadContract, account, tokenInform?.decimals)
              //     .then((res:any) => {
              //         setCanCancel(res?.canCancel);
              //         setCanFinalize(res?.canFinalize);
              //         setIsOwner(res?.isOwner);
              //         setWhitelistPoolOwner(Number(res?.whitelistPool));
              //         setActionWLPStatus("");
              //         setSaleType(wlPool);
              //         setHoldingName('');
              //         setHoldingSymbol('');
              //         setHoldingDecimals('');
              //         setHoldingAmount(0);
              setWhitelistPool(wlPool);
              Store.addNotification(
                addNotify("Successfully", "success")
              );
              if (isModal) {
                setDisableWhitelist(false);
              }
              //     });
              // })();
            } else {
              setLoadingEnableWhitelist(false);
              // setSaleType(Number(whitelistPoolOwner));
              setActionWLPStatus("");
              Store.addNotification(addNotify("Transaction failed", "danger"));
              return;
            }
          })
          .catch((error: any) => {
            setLoadingEnableWhitelist(false);
            // setSaleType(Number(whitelistPoolOwner));
            setActionWLPStatus("");
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        setLoadingEnableWhitelist(false);
        // setSaleType(Number(whitelistPoolOwner));
        setActionWLPStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };

  const handleAddWhitelist = async () => {
    const _addUsers = addListOfWhiteListUsers.split(/\r?\n/);
    setActionStatus("ADD_WL_LOADING");
    await addWhiteListUsers(launchpadContract, _addUsers)
      .then((res: any) => {
        console.log("res", res);
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              (async () => {
                await getListOfWhiteListUsersPS(launchpadContract).then(
                  (res: any) => {
                    setListOfWhiteListUsers(res);
                    setActionStatus("");
                    setIsAddedWhitelist(false);
                    Store.addNotification(
                      addNotify("Whitelist is added successfully", "success")
                    );
                  }
                );
              })();
            } else {
              setActionStatus("");
              setIsAddedWhitelist(false);
              Store.addNotification(addNotify("Transaction failed", "danger"));
              return;
            }
          })
          .catch((error: any) => {
            setActionStatus("");
            setIsAddedWhitelist(false);
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        console.log("error", error);
        setActionStatus("");
        setIsAddedWhitelist(false);
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };

  const handleRemoveWhitelist = async () => {
    const _removeUsers = removeListOfWhiteListUsers.split(/\r?\n/);

    if ((_removeUsers && _removeUsers.length === 0) || !_removeUsers) {
      Store.addNotification(
        addNotify("Whitelist remove list is empty. Please insert!", "danger")
      );
      return;
    }
    setActionStatus("REMOVE_WL_LOADING");

    await removeWhiteListUsers(launchpadContract, _removeUsers)
      .then((res: any) => {
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              (async () => {
                await getListOfWhiteListUsersPS(launchpadContract).then(
                  (res: any) => {
                    setListOfWhiteListUsers(res);
                    setActionStatus("");
                    setIsRemovedWhitelist(false);
                    Store.addNotification(
                      addNotify("Whitelist is removed successfully", "success")
                    );
                  }
                );
              })();
            } else {
              setActionStatus("");
              setIsRemovedWhitelist(false);
              Store.addNotification(addNotify("Transaction failed", "danger"));
              return;
            }
          })
          .catch((error: any) => {
            setIsRemovedWhitelist(false);
            setActionStatus("");
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        setIsRemovedWhitelist(false);
        setActionStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };

  const handleWithdrawContribute = async () => {
    setActionStatus("WITHDRAW_CONTRIBUTE_LOADING");

    await withdrawContribute(launchpadContract)
      .then((res: any) => {
        let resWait = res.wait();
        resWait
          .then((resTransaction: any) => {
            if (
              resTransaction &&
              resTransaction.status &&
              resTransaction.blockNumber
            ) {
              setTotalInvestment(0);
              setActionStatus("WITHDRAW_CONTRIBUTED");
              Store.addNotification(
                addNotify("Withdraw contribute successfully", "success")
              );
            }
          })
          .catch((error: any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
              Store.addNotification(addNotify(error.message, "danger"));
            } else if (error.reason) {
              Store.addNotification(addNotify(error.reason, "danger"));
            } else {
              if (error.data && error.data.message) {
                Store.addNotification(addNotify(error.data.message, "danger"));
              }
            }
          });
      })
      .catch((error: any) => {
        setActionStatus("");
        if (error.code == 4001 && error.message) {
          Store.addNotification(addNotify(error.message, "danger"));
        } else if (error.reason) {
          Store.addNotification(addNotify(error.reason, "danger"));
        } else {
          if (error.data && error.data.message) {
            Store.addNotification(addNotify(error.data.message, "danger"));
          }
        }
      });
  };
  const infoDetail = (
    <>
      <h3>
        <p>
          {launchpadDetail?.name}
          <span>{launchpadDetail?.type}</span>
        </p>
        <p>
          {launchpadDetail?.audit ? <span>Audit</span> : ""}
          {launchpadDetail?.kyc ? <span>KYC</span> : ""}
          {launchpadDetail?.safu ? <span>SAFU</span> : ""}
        </p>
      </h3>
      <ul>
        {launchpadDetail?.socialNetwork?.website ? (
          <li>
            <a href={launchpadDetail?.socialNetwork?.website}>
              <img src="../images/detail-icon-website.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {launchpadDetail?.socialNetwork?.telegram ? (
          <li>
            <a href={launchpadDetail?.socialNetwork?.telegram}>
              <img src="../images/detail-icon-tele.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {launchpadDetail?.socialNetwork?.twitter ? (
          <li>
            <a href={launchpadDetail?.socialNetwork?.twitter}>
              <img src="../images/detail-icon-tw.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {launchpadDetail?.socialNetwork?.medium ? (
          <li>
            <a href={launchpadDetail?.socialNetwork?.medium}>
              <img src="../images/detail-icon-medium.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {launchpadDetail?.socialNetwork?.facebook ? (
          <li>
            <a href={launchpadDetail?.socialNetwork?.facebook}>
              <img src="../images/detail-icon-facebook.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {launchpadDetail?.socialNetwork?.discord ? (
          <li>
            <a href={launchpadDetail?.socialNetwork?.discord}>
              <img src="../images/detail-icon-discord.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
        {launchpadDetail?.socialNetwork?.youtube ? (
          <li>
            <a href={launchpadDetail?.socialNetwork?.youtube}>
              <img src="../images/detail-icon-youtube.svg" alt="" />
            </a>
          </li>
        ) : (
          ""
        )}
      </ul>
    </>
  );

  const onChangeRadio = (e: RadioChangeEvent) => {
    // = 0 pool PUBLIC, = 1 pool WHITELIST

    // if(e.target.value === 1 && whitelistPoolOwner !== 1) {
    //     setSaleType(-1);
    // console.log("e.target.value", e.target.value);

    // setWhitelistPool(e.target.value);
    handleWhitelistPool(e.target.value);
    // }
    // else if (e.target.value === 2 && whitelistPoolOwner !== 2) {
    //     setSaleType(2);
    // }
    // else if(e.target.value === 0){
    //     setSaleType(0)
    // }
  };

  // const changeHoldingAmount = (e: any) => {
  //   setHoldingAmount(e.target.value);
  // };

  const handleChangeAddWhitelists = (e: any) => {
    setAddListOfWhiteListUsers(e.target.value);
  };

  const handleChangeRemoveWhitelists = (e: any) => {
    setRemoveListOfWhiteListUsers(e.target.value);
  };

  const elmSaleType = () => {
    let elm: any = "";
    if (whitelistPool === SALETYPE_WHITELIST_POOL.PUBLIC) {
      elm = (
        <div className="p-launchpadDetail__selectRadio pri">
          <Button
            text="Enable Whitelist"
            type="border"
            isLoading={loadingEnableWhitelist}
            disabled={loadingEnableWhitelist}
            onClick={() =>
              handleWhitelistPool(SALETYPE_WHITELIST_POOL.WHITELIST)
            }
          />
        </div>
      );
    } else if (whitelistPool === SALETYPE_WHITELIST_POOL.WHITELIST) {
      elm = (
        <div className="p-launchpadDetail__selectRadio">
          <p className="p-launchpadDetail__txt">
            The whitelist function can be both enabled/disabled during the
            creation of a presale, prior to the beginning of the presale
          </p>
          <div className="p-launchpadDetail__twoBtn">
            <Button
              text="Add Whitelist"
              type="blue"
              onClick={() => setIsAddedWhitelist(true)}
            />
            <Button
              text="Remove Whitelist"
              type="blue"
              onClick={() => setIsRemovedWhitelist(true)}
            />
          </div>
          <Button
            text="List of Whitelist"
            onClick={() => setIsListedWhitelist(true)}
          />
          <Button
            text="Disable Whitelist"
            type="greyBorder"
            onClick={() => setDisableWhitelist(true)}
          />
        </div>
      );
    }
    return elm;
  };

  const onAmount = (e: any) => {
    setValueAmount(e.target.value);
  };

  const rendererTimer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      window.location.reload();
    } else {
      return (
        <div className="c-countDown">
          <span>{zeroPad(days)}d</span>:<span>{zeroPad(hours)}h</span>:
          <span>{zeroPad(minutes)}m</span>:<span>{zeroPad(seconds)}s</span>
        </div>
      );
    }
  };

  const handleClassStatus = () => {
    let classStatus: string = "";

    if (startTimer && endTimer) {
      if (nowTimer < startTimer) {
        classStatus = "is-Upcoming";
      } else if (startTimer < nowTimer && nowTimer < endTimer) {
        classStatus = "is-Opening";
      } else if (endTimer < nowTimer) {
        classStatus = "is-Completed";
      }
    }

    return classStatus;
  };

  const getUserWhitelist = () => {

    if (whitelistPool === 0) {
      return true;
    } else {
      if (isUserWhitelist) return true;
      else return false;
    }
  };
  const getActionButton = () => {
    let _button: any = "",
      _emergencyWithdrawbutton: any = "";
    let _userWhitelist = getUserWhitelist();

    if (!_userWhitelist && totalInvestment <= 0) {

      _button = (
        <Button text="You're not whitelist" type="blue" disabled={true} />
      );


    } else {
      if (launchpadDetail?.currency !== 'ETH') {
        if (
          launchpadRound?.currencyPay.toLowerCase() !==
          ethers.constants.AddressZero.toLowerCase() &&
          amountApprove >= valueAmount &&
          allowance > 0
          // allowance > 0
        ) {
          _button = (
            <Button
              text={`${actionStatus === "BOUGHT" ? "Bought" : actionStatus === "BUY_LOADING" ? 'Waiting' : "Buy Now"}`}
              type="blue"
              onClick={() => handleContribute()}
              isLoading={actionStatus === "BUY_LOADING" ? true : false}
              disabled={
                actionStatus === "BUY_LOADING" || actionStatus === "BOUGHT" || actionStatus === "EMERGENCY_WITHDRAW_LOADING"
                  ? true
                  : false
              }
            />
          );
        } else if (launchpadRound?.maxBuy - Number(totalInvestment) > 0) {

          _button = (
            <Button
              text={`Approve ${launchpadRound?.currencyPay}`}
              type="blue"
              onClick={() => handleApproveToken()}
              isLoading={loading}
              disabled={loading}
            />
          );
        }
      } else {
        _button = (
          <Button
            text={`${actionStatus === "BOUGHT" ? "Bought" : actionStatus === "BUY_LOADING" ? 'Waiting' : "Buy Now"}`}
            type="blue"
            onClick={() => handleContribute()}
            isLoading={actionStatus === "BUY_LOADING" ? true : false}
            disabled={
              actionStatus === "BUY_LOADING" || actionStatus === "BOUGHT" || actionStatus === "EMERGENCY_WITHDRAW_LOADING"
                ? true
                : false
            }
          />
        );
      }


      if (totalInvestment > 0) {
        _emergencyWithdrawbutton = (
          <Button
            text={actionStatus === "EMERGENCY_WITHDRAW_LOADING" ? "Waiting" : "Urgent Withdrawal"}
            type="red"
            onClick={() => handleEmergencyWithdraw()}
            isLoading={
              actionStatus === "EMERGENCY_WITHDRAW_LOADING" ? true : false
            }
            disabled={
              actionStatus === "EMERGENCY_WITHDRAW_LOADING" ||
                actionStatus === "EMERGENCY_WITHDRAW" || actionStatus === "BUY_LOADING"
                ? true
                : false
            }
          />
        );
      }
    }

    return (
      <>
        {_button} {_emergencyWithdrawbutton}
      </>
    );
  };

  const handleSetMax = () => {
    if ((launchpadRound?.maxBuy - totalInvestment) <= (launchpadDetail?.hardCap - raisedAmount)) {
      setValueAmount(
        Number(
          formatNumber(launchpadRound?.maxBuy - totalInvestment)
        )
      )
    } else {
      setValueAmount(
        Number(
          formatNumber(launchpadDetail?.hardCap - raisedAmount)
        )
      )
    }

  }

  const launchpadAction = () => {
    let _userWhitelist = getUserWhitelist();

    if (launchpadState === 1) {
      if (nowTimer < startTimer) {
        progressPercent = 0;
        statusText = "Upcoming";
        progressTime = startTimer;
      } else if (startTimer <= nowTimer && nowTimer <= endTimer) {
        if (raisedAmount >= launchpadDetail?.hardCap) {
          statusText = "This pool has filled";
          progressTime = null;
          if (!_userWhitelist && totalInvestment <= 0) {
            actionButton = (
              <Button text="You're not whitelist" type="blue" disabled={true} />
            );
          }
        } else {
          statusText = "Opening";
          progressTime = endTimer;
          contributeInput = (
            <>
              <div className="c-input">
                <p className="c-input__title">
                  Amount (Max:{" "}
                  {formatNumber(
                    launchpadRound?.maxBuy
                    // launchpadRound?.maxBuy - Number(totalInvestment)
                  )}{" "}
                  {launchpadRound?.currencyPay})
                </p>
                <div className="c-input__max">
                  <input
                    type="number"
                    disabled={
                      Number(
                        formatNumber(
                          launchpadRound?.maxBuy - Number(totalInvestment)
                        )
                      ) < Number(launchpadRound?.minBuy)
                        ? true
                        : false
                    }
                    onWheel={(event) => event.currentTarget.blur()}
                    value={valueAmount}
                    onChange={(e) => onAmount(e)}
                  />
                  <span
                    onClick={handleSetMax}
                  >
                    Max
                  </span>
                </div>
              </div>
              <p className="c-txt">
                Min buy: {formatNumber(launchpadRound?.minBuy)}{" "}
                {launchpadRound?.currencyPay}
              </p>
              {amountApprove < valueAmount ? (
                <p className="c-txt">
                  Your approved: {formatNumber(amountApprove)}{" "}
                  {launchpadRound?.currencyPay}
                </p>
              ) : (
                ""
              )}

              <p className="c-txt">
                Available: {(launchpadRound?.maxBuy - totalInvestment) <= (launchpadDetail?.hardCap - raisedAmount) ? (
                  formatNumber(launchpadRound?.maxBuy - totalInvestment)
                ) : (
                  formatNumber(launchpadDetail?.hardCap - raisedAmount)
                )}
                {" "}
                {launchpadRound?.currencyPay}
              </p>
            </>
          );
          actionButton = getActionButton();
        }
        progressPercent =
          launchpadDetail?.hardCap &&
            Number(launchpadDetail?.hardCap) !== 0 &&
            raisedAmount > 0
            ? formatNumber(
              (Number(raisedAmount) / parseFloat(launchpadDetail?.hardCap)) *
              100
            )
            : 0;
      } else if (endTimer < nowTimer) {
        progressPercent =
          launchpadDetail?.hardCap &&
            Number(launchpadDetail?.hardCap) !== 0 &&
            raisedAmount > 0
            ? formatNumber(
              (Number(raisedAmount) / parseFloat(launchpadDetail?.hardCap)) *
              100
            )
            : 0;
        statusText = "Ended";
        progressTime = null;

        if (!_userWhitelist && totalInvestment <= 0) {
          actionButton = (
            <Button text="You're not whitelist" type="blue" disabled={true} />
          );
        } else {
          if (totalInvestment === 0) {
            actionButton = (
              <Button text="You're not join" type="blue" disabled={true} />
            );
          } else {
            actionButton = totalInvestment > 0 &&
              !refund &&
              Number(raisedAmount) < Number(softCap) && (
                <Button
                  text="Withdraw Contribute"
                  type="blue"
                  onClick={() => handleWithdrawContribute()}
                  isLoading={
                    actionStatus === "WITHDRAW_CONTRIBUTE_LOADING"
                      ? true
                      : false
                  }
                  disabled={
                    refund ||
                      actionStatus === "WITHDRAW_CONTRIBUTE_LOADING" ||
                      actionStatus === "WITHDRAW_CONTRIBUTED"
                      ? true
                      : false
                  }
                />
              );
          }
        }
      }
    } else if (launchpadState === 2) {
      progressPercent =
        launchpadDetail?.hardCap && Number(launchpadDetail?.hardCap) !== 0
          ? formatNumber(
            (Number(raisedAmount) / parseFloat(launchpadDetail?.hardCap)) *
            100
          )
          : 0;
      if (listingTime) {
        const _listingTime: any = new Date(listingTime);
        let _claimTimeTge: any = new Date(_listingTime);

        if (launchpadDetail?.vestingContributor?.cliffVesting > 0) {
          _claimTimeTge.setMinutes(
            _claimTimeTge.getMinutes() +
            Number(launchpadDetail?.vestingContributor?.cliffVesting)
          );
        }

        if (nowTimer <= _claimTimeTge) {
          statusText = "Claim starts in";
          progressTime = _claimTimeTge;
        } else {
          if (
            launchpadDetail?.vestingContributor?.firstReleasePercent !== 100
          ) {
            let _nextClaimAfterCliffVesting: any = new Date(_claimTimeTge);
            _nextClaimAfterCliffVesting.setMinutes(
              _nextClaimAfterCliffVesting.getMinutes() +
              Number(
                launchpadDetail?.vestingContributor?.lockAfterCliffVesting
              )
            );

            let tgeTokens: any =
              (Number(
                launchpadDetail?.vestingContributor?.firstReleasePercent
              ) *
                totalUserTokens) /
              100;
            if (
              launchpadDetail?.vestingContributor?.vestingPeriodEachCycle > 0
            ) {
              if (
                claimedTokens + totalClaimable <= tgeTokens &&
                _nextClaimAfterCliffVesting >= new Date()
              ) {
                statusText = "Next claim starts in";
                progressTime = _nextClaimAfterCliffVesting;
              } else if (
                claimedTokens + totalClaimable <= tgeTokens &&
                progressTime < new Date()
              ) {
                statusText = "Finished";
                progressTime = null;
              } else if (
                tgeTokens < claimedTokens + totalClaimable &&
                claimedTokens + totalClaimable <= totalUserTokens &&
                launchpadDetail?.vestingContributor?.vestingPeriodEachCycle > 0
              ) {
                let _totalClaimedpercent: any = Number(
                  launchpadDetail?.vestingContributor?.firstReleasePercent
                );
                let _nextClaimTime: any;
                _nextClaimTime = new Date(_nextClaimAfterCliffVesting);

                while (_totalClaimedpercent < 100) {
                  _totalClaimedpercent +=
                    launchpadDetail?.vestingContributor?.tokenReleaseEachCycle;
                  _nextClaimTime.setMinutes(
                    _nextClaimTime.getMinutes() +
                    Number(
                      launchpadDetail?.vestingContributor
                        ?.vestingPeriodEachCycle
                    )
                  );
                }
                progressTime = _nextClaimTime;

                if (claimedTokens + totalClaimable === totalUserTokens) {
                  statusText = "Finished";
                } else {
                  statusText = "Next claim starts in";
                }
              } else {
                statusText = "Finished";
                progressTime = null;
              }
            } else {
              statusText = "Data invalid";
              progressTime = null;
            }
          } else {
            statusText = "Finished";
            progressTime = null;
          }
        }
      } else {
        statusText = "Finalized";
        progressTime = null;
      }

      if (!_userWhitelist) {
        if (!isOwner) {
          actionButton = (
            <Button text="You're not whitelist" type="blue" disabled={true} />
          );
        } else {
          actionButton = (
            <div>
              <Button
                text='Claim Fund'
                // text={`${
                //   totalClaimable > 0
                //     ? `Claim Token (${totalClaimable.toLocaleString()} ${
                //         tokenInform?.symbol
                //       })`
                //     : `Claimed (${claimedTokens.toLocaleString()}/${totalUserTokens.toLocaleString()} ${
                //         tokenInform?.symbol
                //       })`
                // }`}
                type="blue"
                onClick={() => handleClaimTokens()}
                isLoading={actionStatus === "CLAIM_LOADING" ? true : false}
                disabled={
                  ownerClaimNext === 0 ||
                    actionStatus === "CLAIM_LOADING" ||
                    actionStatus === "CLAIMED"
                    ? true
                    : false
                }
              />
            </div>
          );
        }

      } else {
        if (totalInvestment === 0) {
          if (!isOwner) {
            actionButton = (
              <Button text="You're not join" type="blue" disabled={true} />
            );
          } else {
            actionButton = (
              <div>
                <Button
                  text='Claim Fund'
                  // text={`${
                  //   totalClaimable > 0
                  //     ? `Claim Token (${totalClaimable.toLocaleString()} ${
                  //         tokenInform?.symbol
                  //       })`
                  //     : `Claimed (${claimedTokens.toLocaleString()}/${totalUserTokens.toLocaleString()} ${
                  //         tokenInform?.symbol
                  //       })`
                  // }`}
                  type="blue"
                  onClick={() => handleClaimTokens()}
                  isLoading={actionStatus === "CLAIM_LOADING" ? true : false}
                  disabled={
                    ownerClaimNext === 0 ||
                      actionStatus === "CLAIM_LOADING" ||
                      actionStatus === "CLAIMED"
                      ? true
                      : false
                  }
                />
              </div>
            );
          }


        } else {
          if (raisedAmount >= softCap) {
            if (isOwner) {
              actionButton = (
                <Button
                  text='Claim Fund'
                  // text={`${
                  //   totalClaimable > 0
                  //     ? `Claim Token (${totalClaimable.toLocaleString()} ${
                  //         tokenInform?.symbol
                  //       })`
                  //     : `Claimed (${claimedTokens.toLocaleString()}/${totalUserTokens.toLocaleString()} ${
                  //         tokenInform?.symbol
                  //       })`
                  // }`}
                  type="blue"
                  onClick={() => handleClaimTokens()}
                  isLoading={actionStatus === "CLAIM_LOADING" ? true : false}
                  disabled={
                    ownerClaimNext === 0 ||
                      actionStatus === "CLAIM_LOADING" ||
                      actionStatus === "CLAIMED"
                      ? true
                      : false
                  }
                />
              );
            }

          } else {
            actionButton = totalInvestment > 0 && !refund && (
              <Button
                text="Withdraw Contribute"
                type="blue"
                onClick={() => handleWithdrawContribute()}
                isLoading={
                  actionStatus === "WITHDRAW_CONTRIBUTE_LOADING" ? true : false
                }
                disabled={
                  refund ||
                    actionStatus === "WITHDRAW_CONTRIBUTE_LOADING" ||
                    actionStatus === "WITHDRAW_CONTRIBUTED"
                    ? true
                    : false
                }
              />
            );
          }
        }
      }
    } else if (launchpadState === 3) {
      progressPercent = 0;
      statusText = "Canceled";
      progressTime = null;
      actionButton =
        totalInvestment > 0 && !refund ? (
          <>
            <Button
              text="Withdraw Contribute"
              type="blue"
              onClick={() => handleWithdrawContribute()}
              isLoading={
                actionStatus === "WITHDRAW_CONTRIBUTE_LOADING" ? true : false
              }
              disabled={
                refund ||
                  actionStatus === "WITHDRAW_CONTRIBUTE_LOADING" ||
                  actionStatus === "WITHDRAW_CONTRIBUTED"
                  ? true
                  : false
              }
            />
          </>
        ) : (
          ""
        );
    }

    return (
      <>
        <h3>
          <span>{statusText}</span>
          {progressTime && (
            <Countdown date={progressTime} renderer={rendererTimer} />
          )}
        </h3>
        <div className="c-progress">
          <p className="c-progress__title">
            Progress ({progressPercent ? progressPercent : 0}%)
          </p>
          <div className="c-progress__bar">
            <span style={{ width: progressPercent + "%" }}></span>
          </div>
          <p className="c-progress__txt">
            {raisedAmount ? formatNumber(raisedAmount) : 0}{" "}
            {launchpadRound?.currencyPay}
            <span>
              {formatNumber(launchpadDetail?.hardCap)}{" "}
              {launchpadRound?.currencyPay}
            </span>
          </p>
        </div>
        {contributeInput}
        <div
          className="p-launchpadDetail__btnStatus"
          style={{ marginTop: !(nowTimer <= endTimer) ? "15px" : 0 }}
        >
          {actionButton}
        </div>
      </>
    );
  };

  const handleCopy = () => {
    Store.addNotification(addNotify("Copied !", "success"));
  };

  // useEffect(() => {
  //     axios
  //         .get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=binancecoin')
  //         .then((res) => {
  //             setPriceBNB(res.data[0].current_price)
  //         })
  // }, []);

  // const marketcap =
  //   launchpadDetail?.currency === "BNB"
  //     ? formatNumber(
  //         (1 / (launchpadRound?.listingRate / priceBNB)) *
  //           tokenInform?.totalSupply
  //       )
  //     : formatNumber(
  //         (1 / launchpadRound?.listingRate) * tokenInform?.totalSupply
  //       );

  // console.log("listOfWhiteListUsers", listOfWhiteListUsers)
  const _listOfWhiteListUsers =
    listOfWhiteListUsers &&
    listOfWhiteListUsers.length > 0 &&
    listOfWhiteListUsers.map((item: any) => {
      return <p>{item}</p>;
    });

  const handleSearch = () => {
    if (filterSearch) {
      const a = listOfWhiteListUsers.filter(x => x == filterSearch);
      setListOfWhiteListUsers(a)
    }
    else {
      (async () => {
        await getListOfWhiteListUsersPS(launchpadContract).then(
          (res: any) => {
            setListOfWhiteListUsers(res);
          }
        );
      })();
    }
  }

  // const handleNetwork = (network: any) => {
  //   let networkName: any = "";
  //   if (network === "BSCTESTNET") {
  //     networkName = (
  //       <span className="is-network">
  //         <img src="../images/icon-bnb.svg" alt="" />
  //         BSC TESTNET
  //       </span>
  //     );
  //   } else if (network === "BSC") {
  //     networkName = (
  //       <span className="is-network">
  //         <img src="../images/icon-bnb.svg" alt="" />
  //         Binance Smart Chain
  //       </span>
  //     );
  //   } else if (network === "ETH") {
  //     networkName = (
  //       <span className="is-network">
  //         <img src="../images/icon-eth.svg" alt="" />
  //         Ethereum
  //       </span>
  //     );
  //   } else if (network === "POLY") {
  //     networkName = (
  //       <span className="is-network">
  //         <img src="../images/icon-polygon.svg" alt="" />
  //         Polygon
  //       </span>
  //     );
  //   }

  //   return networkName;
  // };

  const switchArb = async () => {
    await networkChange("0xa4b1");
  };
  let dataCSV: Array<any> = [["Address", "Amount"]]
  if (listAddContributor.length > 0 && listAmountContributor.length > 0)
    listAddContributor && listAddContributor.map((item: any, index: any) => (
      dataCSV.push([listAddContributor[index], Number(listAmountContributor[index]) / (1 * 10 ** currencyDecimals)])
    ))


  return (
    <>
      {launchpadLoading && <Loading />}
      <div className="p-launchpadDetail">
        <div className="c-breadcrumb">
          <ul>
            <li>
              <span>Private Sale</span>
            </li>
            <li>
              <Link to={`/private-sale/list`}><span>Private-Sale-List</span> </Link>
            </li>
            <li>
              <Link to={`/private-sale/list`}>
                <span>{launchpadDetail?.name}</span>

              </Link>
              {/* <span>{launchpadDetail?.name}</span> */}
            </li>
          </ul>
        </div>
        <div className="p-launchpadDetail__banner">
          <img
            src={
              launchpadDetail?.bannerUrl
                ? launchpadDetail?.bannerUrl
                : "../../images/bgDefault.svg"
            }
            alt={launchpadDetail?.name}
          />
          <h2 className="p-launchpadDetail__logo">
            <img
              src={
                launchpadDetail?.logoUrl
                  ? launchpadDetail?.logoUrl
                  : "../../../images/logoDefault.svg"
              }
              alt={launchpadDetail?.name}
            />
            {launchpadDetail?.name}
          </h2>
          {isOwner && (
            <Button
              text="Edit Private Sale Info"
              iconLeft="Edit"
              onClick={() => navigate(`/private-sale-edit/${handle}`)}
            />
          )}
        </div>
        <div className="p-launchpadDetail__detail">
          <div className="p-launchpadDetail__infoSC">
            {size.width <= 767 ? (
              <div className="p-launchpadDetail__info">{infoDetail}</div>
            ) : (
              ""
            )}

            {launchpadState === -1 ? (
              <>
                {account ? (
                  <Skeleton.Button active={true} block={true} size='large' />

                ) : (
                  <div className="not-connect-pri">Not connect Wallet</div>
                )}

              </>
            ) : (
              <>
                {launchpadState === 0 ? (
                  <>
                    <div className="err-net" >
                      <button className="btn_switch_net_pri" onClick={switchArb}> Switch network</button>
                    </div>
                  </>
                ) : (
                  <div className={`p-launchpadDetail__status ${handleClassStatus()}`}>
                    {launchpadAction()}
                  </div>
                )}
              </>
            )}

            <div className={`p-launchpadDetail__boxFixed`}>
              <p>
                Sale Type
                <span>
                  {whitelistPool == 0
                    ? "Public"
                    : whitelistPool == 1
                      ? "Whitelist"
                      : whitelistPool == 2
                        ? "Public Anti-Bot"
                        : ""}
                </span>
              </p>
              <p>
                Soft Cap
                <span>
                  {softCap ? formatNumber(softCap) : ''} {launchpadRound?.currencyPay}
                </span>
              </p>
              <p>
                Hard Cap
                <span>
                  {launchpadDetail?.hardCap ? formatNumber(launchpadDetail?.hardCap) : ''}{" "}
                  {launchpadRound?.currencyPay}
                </span>
              </p>
              {/* {vestingContributor?.firstReleasePercent === 100 ? (
                <p>
                  Vesting For Presale<span>First Release 100%</span>
                </p>
              ) : (
                <p>
                  Vesting For Presale
                  <span>
                    {vestingContributor?.tokenReleaseEachCycle}% each{" "}
                    {vestingContributor?.vestingPeriodEachCycle} minutes
                  </span>
                </p>
              )}  */}

              <p>
                Total Contributors
                <span>
                  {totalInves}
                </span>
              </p>

              <p>
                Your Purchases
                <span>
                  {formatNumber(totalInvestment)}{" "}
                  {launchpadRound?.currencyPay}
                </span>
              </p>
              {launchpadState === 2 && isOwner ? (
                <>
                  <p>
                    Your Claimed
                    <span>
                      {totalOwnerClaimed}{" "}
                      {launchpadRound?.currencyPay}
                    </span>
                  </p>
                  {totalInvestment == totalOwnerClaimed ? ('') : (
                    <p>
                      Claim next
                      <span>
                        {ownerClaimNext}{" "}
                        {launchpadRound?.currencyPay}
                      </span>
                    </p>
                  )}

                </>
              ) : ('')}


            </div>
          </div>
          <div className="p-launchpadDetail__content">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Pool Information" key="1">
                <div className="p-launchpadDetail__info">
                  {size.width <= 767 ? "" : infoDetail}
                  <div className="p-launchpadDetail__boxInfo">
                    <p className="p-launchpadDetail__row">
                      Start time
                      <span>{formatDate(launchpadDetail?.startTime)}</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      End time
                      <span>{formatDate(launchpadDetail?.endTime)}</span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Presale address
                      {launchpadRound?.presaleAddress ? (
                        <span>
                          <a
                            target="_blank"
                            href={`https://bscscan.com/address/${launchpadRound?.presaleAddress}`}
                          >
                            {launchpadRound?.presaleAddress?.substring(0, 4) +
                              "..." +
                              launchpadRound?.presaleAddress?.substr(
                                launchpadRound?.presaleAddress?.length - 4
                              )}
                          </a>
                          <CopyToClipboard
                            text={launchpadRound?.presaleAddress}
                            onCopy={() => handleCopy()}
                          >
                            <button>
                              <img src="../images/icon-copy.svg" alt="" />
                            </button>
                          </CopyToClipboard>
                        </span>
                      ) : (
                        <span>--</span>
                      )}
                    </p>
                    <p className="p-launchpadDetail__row">
                      Soft cap
                      <span>
                        {formatNumber(launchpadDetail?.softCap)}{" "}
                        {launchpadRound?.currencyPay}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      Hard cap
                      <span>
                        {formatNumber(launchpadDetail?.hardCap)}{" "}
                        {launchpadRound?.currencyPay}
                      </span>
                    </p>
                    <p className="p-launchpadDetail__row">
                      First release for presale
                      <span>
                        {formatData(
                          vestingContributor?.firstReleasePercent,
                          "%"
                        )}
                      </span>
                    </p>
                    {vestingContributor?.firstReleasePercent === 100 ? (
                      <p className="p-launchpadDetail__row">
                        Vesting For Presale<span>First Release 100%</span>
                      </p>
                    ) : (
                      <p className="p-launchpadDetail__row">
                        Vesting For Presale
                        <span>
                          {vestingContributor?.tokenReleaseEachCycle}% each{" "}
                          {vestingContributor?.vestingPeriodEachCycle} minutes
                        </span>
                      </p>
                    )}
                    {/* <p className="p-launchpadDetail__row">
                      Vesting for project
                      <span>
                        {formatData(vestingContributor?.cliffVesting, "")}
                      </span>
                    </p> */}
                  </div>
                </div>
              </TabPane>
              <TabPane tab="About the Project" key="2">
                <div className="p-launchpadDetail__info">
                  {size.width <= 767 ? "" : infoDetail}
                  <p className="p-launchpadDetail__txt">
                    {launchpadDetail?.info}
                  </p>
                </div>
              </TabPane>

              {isOwner && (
                <TabPane tab="Owner Zone" key="4">
                  <div className="p-launchpadDetail__info">
                    {size.width <= 767 ? "" : infoDetail}
                    {/* {notification && (
                      <p className="c-notification">
                        <button onClick={() => setNotification(false)}>
                          <img src="../images/icon-close-12px.svg" alt="" />
                        </button>
                        To make sure there will be no issues during the presale
                        time, please do not send tokens to wallets before you
                        finalize the presale pool.
                        <span>Pool fee: 10% BNB raised only</span>
                      </p>
                    )} */}

                    {
                      launchpadState !== 2 && launchpadState !== 3 ? (
                        <>
                          <div className="p-launchpadDetail__boxInfo">
                            <h3>Sale Type</h3>
                            <div className="p-launchpadDetail__radio">
                              <Radio.Group
                                className="c-radioEdit"
                                onChange={onChangeRadio}
                                value={whitelistPool}
                              >
                                <Radio value={0}>Public</Radio>
                                <Radio value={1}>Whitelist</Radio>
                                {/* {
													saleType === -1 ?<Radio disabled={true} value={1}>Whitelist Only </Radio>
													:<Radio value={1}>Whitelist {actionWLPStatus === ("LOADING_"+SALETYPE_WHITELIST_POOL.WHITELIST) ? <Spin size="small" style={{position: 'absolute'}} /> : ''} </Radio>
												}   */}
                              </Radio.Group>
                            </div>
                            {elmSaleType()}
                          </div>
                        </>
                      ) : ('')
                    }


                    <div className="p-launchpadDetail__boxInfo mt-16">
                      <h3>
                        Pool Actions
                        <span>
                          To Finalize presale, you have to exclude token
                          transfer fee for presale contract
                        </span>
                      </h3>
                      <div className="p-launchpadDetail__btn ">
                        {launchpadState === 3 ? (
                          <Button
                            text="Canceled"
                            type="greyBorder"
                            isLoading={
                              actionStatus === "CANCEL_LAUNCHPAD_LOADING"
                                ? true
                                : false
                            }
                            disabled={
                              true
                            }
                          // onClick={() => handleCancelLaunchpad()}
                          />
                        ) : (
                          <>
                            {launchpadState === 2 ? (
                              <Button
                                text="Finalized"
                                type="blue"
                                // isLoading={
                                //   actionStatus === "FINALIZE_LOADING" ? true : false
                                // }
                                disabled={
                                  true
                                }
                              // onClick={() => handleFinalize()}
                              />
                            ) : (
                              <>
                                <Button
                                  text="Cancel"
                                  type="greyBorder"
                                  isLoading={
                                    actionStatus === "CANCEL_LAUNCHPAD_LOADING"
                                      ? true
                                      : false
                                  }
                                  disabled={
                                    // launchpadState===3 ||
                                    // launchpadState===2 ||
                                    actionStatus === "CANCEL_LAUNCHPAD_LOADING" ||
                                      actionStatus === "CANCEL_LAUNCHPAD_DONE" ||
                                      actionStatus === "FINALIZE_LOADING"
                                      ? true
                                      : false
                                  }
                                  onClick={() => handleCancelLaunchpad()}
                                />

                                {launchpadDetail?.hardCap === raisedAmount || (raisedAmount > launchpadDetail?.softCap && endTimer < nowTimer) ? (
                                  <Button
                                    text="Finalize"
                                    type="blue"
                                    isLoading={
                                      actionStatus === "FINALIZE_LOADING" ? true : false
                                    }
                                    disabled={
                                      // (isOwner && !canFinalize) ||
                                      //  launchpadState === 2 || 
                                      //  launchpadState === 3 ||                           
                                      actionStatus === "FINALIZED" ||
                                        actionStatus === "FINALIZE_LOADING" ||
                                        actionStatus === "CANCEL_LAUNCHPAD_LOADING"
                                        ? true
                                        : false
                                    }
                                    onClick={() => handleFinalize()}
                                  />
                                ) : (
                                  <Button
                                    text="Finalize"
                                    type="blue"
                                    // isLoading={
                                    //   actionStatus === "FINALIZE_LOADING" ? true : false
                                    // }
                                    disabled={
                                      true
                                    }
                                  // onClick={() => handleFinalize()}
                                  />
                                )}


                              </>
                            )}
                          </>
                        )}

                        <Button
                          text="List of Contributors"
                          type="greyBorder"
                          // isLoading={
                          //   actionStatus === "CANCEL_LAUNCHPAD_LOADING"
                          //     ? true
                          //     : false
                          // }
                          // disabled={
                          //   true
                          // }
                          onClick={() => setIsListContributor(true)}
                        />

                      </div>
                    </div>
                    {launchpadDetail?.listingOptions === "MANUAL" && (
                      <div className="p-launchpadDetail__boxInfo mt-16">
                        <h3>
                          Set Listing Time
                          <span>For manual listing</span>
                        </h3>
                        <div className="p-launchpadDetail__selectRadio">
                          <p className="c-ttl">Set Listing Time</p>
                          <DatePicker
                            className="c-dateEdit"
                            showTime={true}
                            placeholder="Select time"
                          />
                          <Button text="Set Listing Time" type="blue" />
                        </div>
                      </div>
                    )}
                  </div>
                </TabPane>
              )}
            </Tabs>
          </div>
        </div>
      </div>

      {isAddedWhitelist && (
        <div className="c-popup">
          <div className="c-popup__content is-small">
            <h3 className="c-popup__title">Add Users To Whitelist</h3>
            <div className="c-popup__box">
              <div className="c-input">
                <p className="c-input__title">User wallet address</p>
                <textarea
                  placeholder="0x"
                  onChange={(e) => handleChangeAddWhitelists(e)}
                ></textarea>
              </div>
            </div>
            <div className="c-popup__btn">
              <Button
                text="Cancel"
                type="greyBorder"
                onClick={() => setIsAddedWhitelist(false)}
              />
              <Button
                text="Add Address"
                type="blue"
                onClick={() => handleAddWhitelist()}
                isLoading={actionStatus === "ADD_WL_LOADING" ? true : false}
                disabled={actionStatus === "ADD_WL_LOADING" ? true : false}
              />
            </div>
          </div>
        </div>
      )}

      {isRemovedWhitelist && (
        <div className="c-popup">
          <div className="c-popup__content is-small">
            <h3 className="c-popup__title">Remove Users To Whitelist</h3>
            <div className="c-popup__box">
              <div className="c-input">
                <p className="c-input__title">User wallet address</p>
                <textarea
                  placeholder="0x"
                  onChange={(e) => handleChangeRemoveWhitelists(e)}
                ></textarea>
              </div>
            </div>
            <div className="c-popup__btn">
              <Button
                text="Cancel"
                type="greyBorder"
                onClick={() => setIsRemovedWhitelist(false)}
              />
              <Button
                text="Remove Address"
                type="blue"
                onClick={() => handleRemoveWhitelist()}
                isLoading={actionStatus === "REMOVE_WL_LOADING" ? true : false}
                disabled={actionStatus === "REMOVE_WL_LOADING" ? true : false}
              />
            </div>
          </div>
        </div>
      )}

      {isListedWhitelist && <div className="c-popup">
        <div className="c-popup__content is-btnAbsolute">
          <h3 className="c-popup__title">List Of Whitelist</h3>
          <SearchChange value={filterSearch} onChange={(e) => setFilterSearch(e.target.value)} onClick={handleSearch} placeholder='Search wallet address' />
          <p className="c-ttl">Wallet address</p>
          <div className="c-popup__whitelist">
            {_listOfWhiteListUsers}
          </div>
          <div className="c-popup__btn button-100">
            <Button text='Cancel' type='greyBorder' onClick={() => setIsListedWhitelist(false)} />
          </div>
        </div>
      </div>
      }

      {disableWhitelist && (
        <div className="c-popup">
          <div className="c-popup__content is-w400">
            <div className="c-popup__icon">
              <img src="../images/popup-icon-delete.svg" alt="" />
            </div>
            <h3 className="c-popup__title">
              Disable Whitelist
              <span>
                Do you want to disable whitelist? You can't restore this
                whitelist
              </span>
            </h3>
            <div className="c-popup__btn">
              <Button
                text="Cancel"
                type="greyBorder"
                onClick={() => setDisableWhitelist(false)}
                disabled={
                  actionWLPStatus ===
                    "LOADING_" + SALETYPE_WHITELIST_POOL.PUBLIC
                    ? true
                    : false
                }
              />
              <Button
                text="Disable"
                type="blue"
                isLoading={
                  actionWLPStatus ===
                    "LOADING_" + SALETYPE_WHITELIST_POOL.PUBLIC
                    ? true
                    : false
                }
                disabled={
                  actionWLPStatus ===
                    "LOADING_" + SALETYPE_WHITELIST_POOL.PUBLIC
                    ? true
                    : false
                }
                onClick={() =>
                  handleWhitelistPool(SALETYPE_WHITELIST_POOL.PUBLIC, true)
                }
              />
            </div>
          </div>
        </div>
      )}


      {isListContributor && <div className="c-popup">
        <div className="c-popup__content is-btnAbsolute">
          <h3 className="c-popup__title">Contributors</h3>
          {/* <SearchChange value={filterSearch} onChange={(e) => setFilterSearch(e.target.value)} onClick={handleSearch} placeholder='Search wallet address' /> */}

          {/* <p className="c-ttl">Wallet address</p>
          <div className="c-popup__whitelist">
            {_listOfWhiteListUsers}
          </div> */}
          <table className="c-ttl list-contributor">
            <tr >
              <th className="th-title no">No	</th>
              <th className="th-title add-cont">Address </th>
              <th className="th-title amount">Amount</th>
            </tr>
            {listAddContributor && listAmountContributor && listAddContributor.map((item: any, index: any) => (
              <tr>
                <td className="no">{index + 1}</td>
                {isMobile ? (
                  <>
                    <td className="add-cont coppy">
                      {item?.substring(0, 4)}...{item?.substring(item.length - 4)}
                      <CopyToClipboard
                        text={item}
                        onCopy={() => handleCopy()}
                        className='copy-private'
                      >
                        <img src="../images/icon-copy.svg" alt="" />
                      </CopyToClipboard>
                    </td>

                  </>

                ) : (
                  <td className="add-cont coppy">
                    {item}
                    <CopyToClipboard
                      text={item}
                      onCopy={() => handleCopy()}
                      className='copy-private'
                    >
                      <img src="../images/icon-copy.svg" alt="" />
                    </CopyToClipboard>
                  </td>
                )}

                <td className="amount">{Number(listAmountContributor[index]) / (1 * 10 ** currencyDecimals)}</td>
              </tr>
            ))}


          </table>
          <div className="c-popup__btn button-100 btn-contributor">
            {isMobile ? ('') : (
              <div className="down-csv">
                <CSVLink
                  filename={`bluesale_${launchpadRound?.presaleAddress}_contributors.csv`}
                  data={
                    dataCSV || ''
                  }>Export as CSV</CSVLink>
              </div>
            )}

            <div className="close-modal-contri">
              <Button text='Close' type='greyBorder' onClick={() => setIsListContributor(false)} />
            </div>
          </div>
        </div>
      </div>
      }
    </>
  );
};

export default PrivateSaleDetail;
