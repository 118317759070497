import { BytesLike, Contract, ethers } from 'ethers';
import { getContract, getNormalSigner } from '../utils';
import web3 from 'web3';
import { convertFromWei, convertToWei } from './utils';

declare const window: Window & typeof globalThis & { ethereum: any };

export const createStandardToken = async (
        abi: ethers.ContractInterface, 
        bytecode: BytesLike | { object: string; }, 
        library: any, 
        deployer: any, 
        name: any, 
        symbol: any, 
        decimal: any, 
        totalSupply: any,
        serviceFeeReceiver: any, 
        serviceFee: any
    ) => {
	const factory = new ethers.ContractFactory(
        abi, 
        bytecode, 
        getNormalSigner(library, deployer)
    );
    
    const contract = await factory.deploy(
        name, 
        symbol, 
        decimal, 
        totalSupply,
        serviceFeeReceiver, 
        serviceFee, 
        { value: serviceFee }
    );
	
    return contract.deployTransaction;
};

export const createAntiBotStandardToken = async (
    abi: ethers.ContractInterface, 
    bytecode: BytesLike | { object: string; }, 
    library: any, 
    deployer: any, 
    name: any, 
    symbol: any, 
    decimal: any, 
    totalSupply: any,
    antiBotAddress: any,
    serviceFeeReceiver: any, 
    serviceFee: any
) => {
    const factory = new ethers.ContractFactory(
        abi, 
        bytecode, 
        getNormalSigner(library, deployer)
    );
    
    const contract = await factory.deploy(
        name, 
        symbol, 
        decimal, 
        totalSupply,
        antiBotAddress,
        serviceFeeReceiver, 
        serviceFee, 
        { value: serviceFee }
    );

    return contract.deployTransaction;
};

export const getTokenAllowance = async (contract:Contract | any, owner:string | any | null | undefined, spender:string | any | null | undefined) => {
    const _decimals:any = tokenDecimals(contract);
    const _allowance = await contract.allowance(owner, spender);
    return parseFloat(convertFromWei(_allowance, _decimals));
}


export const getTokenAllowanceNoHook = async (tokenSCAbi:any, tokenAddressVal:any, account:any, spender:any) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		return await contract.allowance(account, spender);
	}
};

export const tokenApprove = async (contract:Contract | any, spender:string) => {
    const args = [spender, web3.utils.toBN(2).pow(web3.utils.toBN(256)).sub(web3.utils.toBN(1)).toString()]; 
    console.log("args", args)
    return await contract.approve(...args);
}

export const tokenAmountApprove = async (contract:Contract | any, spender:string, amount:any, decimals:any) => {
    const args = [spender, convertToWei(amount, decimals)];
    return await contract.approve(...args);
}

export const tokenApproveNoHook = async (tokenAbi:any, tokenAddress:any, library:any, account:any, spender:any) => {
	const args = [spender, web3.utils.toBN(2).pow(web3.utils.toBN(256)).sub(web3.utils.toBN(1)).toString()];
	const contract = getContract(tokenAddress, tokenAbi, library, account);
	return await contract.approve(...args);
};

export const tokenBalanceOf = async (contract:Contract | any, account:string) => {
    const _decimals = tokenDecimals(contract);
    const _balance = await contract.balanceOf(account);
    return parseFloat(convertFromWei(_balance, _decimals));
}

export const tokenDecimals = async (contract:Contract | any) => {
    return await contract.decimals();
}

export const tokenSymbol = async (contract:Contract | any) => {
    return await contract.symbol();
}

export const tokenName = async (contract:Contract | any) => {
    return await contract.name();
}

export const tokenTotalSupply = async (contract:Contract | any) => {
    const _decimals = await tokenDecimals(contract);
    const _totalSupply = await contract.totalSupply();
    return parseFloat(convertFromWei(_totalSupply, _decimals));
}

export const tokenInformation = async (contract:Contract | any) => { 
    const _symbol = await tokenSymbol(contract);
    const _decimals = await tokenDecimals(contract);
    const _name = await tokenName(contract);
    const _totalSupply = await tokenTotalSupply(contract);
    
    return {
        "tokenName": _name,
        "symbol": _symbol,
        "decimals": _decimals,
        "tokenAddress": contract.address,
        "totalSupply": _totalSupply
    };
}
 

export const tokenHoldingInformation = async (contract:Contract | any, address:any) => { 
    return {  
        "balance":await contract?.balanceOf(address)
    };
}