import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Store } from 'react-notifications-component';
import addNotify from '../../components/commons/Notify/addNotify';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ImgCopy from '../../components/assets/images/icon-copy.svg';
import LockRecordTable from "./lockRecordTable";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getTokenInformByAddress, tokenInformsSelector } from "../../redux/slicers/tokenInformSlice";
import { getLiquidityByAddress, liquiditySelector } from "../../redux/slicers/liquiditySlice";
import { Loading } from "../../components/component";
import { formatData } from "../../utils/formatData";
import { formatNumber } from "../../utils/formatNumber";
import { totalLockCountForToken, getAllNormalTokenLockedCount, getTotalTokenLockedCount, getNormalLocksForUser, cumulativeLockInfo } from "../../contracts/goldSaleLock";
import { useTokenContract, useGSLockContract, useLaunchpadContract } from "../../hooks/useContract";
import { LAUNCHPAD_CONFIGS, SMART_CONTRACT_ADDRESS } from "../../constants";
import { convertFromWei } from "../../contracts/utils";
import { formatDate } from "../../utils/formatDate";
import { useActiveWeb3React } from "../../hooks";
import { ethers } from "ethers";
import { getTeamLockId } from "../../contracts/launchpad";

const LockRecords: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useAppDispatch();
    const { handle } = useParams<{ handle: any }>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [typeHandle, setTypeHandle] = useState(searchParams.get("type"));
    const [launchpadAddress, setLaunchpadAddress] = useState(searchParams.get("launchpad"));
    const [dataInfo, setDataInfo] = useState<any>({});
    const [teamLockId, setTeamLockId] = useState<any>("");
    // const { tokenInformsByAddress, loading } = useAppSelector(tokenInformsSelector);
    // const { liquidityByAddress } = useAppSelector(liquiditySelector);
    const tokenContract = useTokenContract(dataInfo?.tokenAddress);
    const {chainId} = useActiveWeb3React();
    const lockAddress = chainId && SMART_CONTRACT_ADDRESS[chainId]?.GS_LOCK_ADDRESS;
    const gsLockContract = useGSLockContract(lockAddress) 
    const [lockedAmount, setLockedAmount] = useState<number>(0);
    const launchpadContract = useLaunchpadContract(launchpadAddress);
   
    useEffect(() => {
        if(handle && typeHandle === 'token') {
            dispatch(getTokenInformByAddress(handle, (res:any)=>{
                setDataInfo(res.tokenInform);
                setIsLoading(false);
            }));
        } else if(handle && typeHandle === 'lp') {
            dispatch(getLiquidityByAddress(handle, (res:any)=>{
                setDataInfo(res.tokenLP);
                setIsLoading(false);
            }));
        }
    }, [dispatch, handle, typeHandle]);

    useEffect(()=>{
        (async () => {
            await cumulativeLockInfo(gsLockContract, dataInfo?.tokenAddress)
            .then((res:any) => {
                setLockedAmount(Number(convertFromWei(res?.amount, dataInfo?.decimals)));
            });
        })();
    },[gsLockContract, dataInfo?.tokenAddress]);

    useEffect(()=>{
        (async () => {
            await getTeamLockId(launchpadContract)
            .then((res:any) => {
                setTeamLockId(Number(res));
            });
        })();
    },[launchpadAddress]);
    console.log("teamLockId", teamLockId);
    const handleCopy = () => {
        Store.addNotification(addNotify('Copied !', 'success'));
    }

    const handleNetwork = (network:any) => {
        let networkName:any = '';
        if(network === 'BSCTESTNET') {
            networkName = <span className="is-network"><img src="../images/icon-bnb.svg" alt="" />BSC TESTNET</span>;
        } else if(network === 'BSC') {
            networkName = <span className="is-network"><img src="../images/icon-bnb.svg" alt="" />Binance Smart Chain</span>;
        } else if(network === 'ETH') {
            networkName = <span className="is-network"><img src="../images/icon-eth.svg" alt="" />Ethereum</span>;
        } else if(network === 'POLY') {
            networkName = <span className="is-network"><img src="../images/icon-polygon.svg" alt="" />Polygon</span>;
        } else if(network === 'ARBTESTNET') {
            networkName = <span className="is-network"><img src="../images/arb-icon.svg" alt="" />ARB TESTNET</span>;
        } else if(network === 'ARB') {
            networkName = <span className="is-network"><img src="../images/arb-icon.svg" alt="" />Arbitrum</span>;
        }

        return networkName;
    }

    return(
        <>
        {isLoading && <Loading />}
        <div className="p-lockRecords">
            <div className="c-breadcrumb">
                <ul>
                    <li><span>Token</span></li>
                    <li><Link to={typeHandle === 'token' ? '/token/my-token' : '/liquidity'}>{typeHandle === 'token' ? 'My Token List' : 'My Liquidity Lock'}</Link></li>
                    <li onClick={() => window.location.replace(typeHandle === 'token' ? '/token/my-token' : '/liquidity')}><span>{dataInfo?.name}</span></li>
                </ul>
            </div>
            <div className="p-lockRecords__box">
                <div className="p-lockRecords__info">
                    <h3>Token Infor</h3>
                    <ul>
                        {typeHandle === 'token' ? <>
                            <li>
                                <img src="../../../images/icon-address.svg" alt="" />
                                Address:
                                <span className="c-copy">
                                    {dataInfo?.tokenAddress?.substring(0,4) + "..." + dataInfo?.tokenAddress?.substr(dataInfo?.tokenAddress?.length - 4)}
                                    <CopyToClipboard text={dataInfo?.tokenAddress} onCopy={() => handleCopy()}>
                                        <button><img src={ImgCopy} alt="" /></button>
                                    </CopyToClipboard>
                                </span>
                            </li>
                            <li>
                                <img src="../../../images/icon-name.svg" alt="" />
                                Name:<span>{dataInfo?.name}</span>
                            </li>
                            <li>
                                <img src="../../../images/icon-sympol.svg" alt="" />
                                Sympol:<span>{dataInfo?.symbol}</span>
                            </li>
                            <li>
                                <img src="../../../images/icon-lockAmount.svg" alt="" />
                                Network:<span>{handleNetwork(dataInfo?.networkChain)}</span>
                            </li>
                            <li>
                                <img src="../../../images/icon-decimal.svg" alt="" />
                                Decimal:<span>{dataInfo?.decimals}</span>
                            </li>
                            <li>
                                <img src="../../../images/icon-totalSupply.svg" alt="" />
                                Total Supply:<span>{formatNumber(dataInfo?.totalSupply)} {dataInfo?.symbol}</span>
                            </li>
                            <li>
                                <img src="../../../images/icon-lockAmount.svg" alt="" />
                                Current Locked Amount:<span>{formatNumber(lockedAmount)} {dataInfo?.symbol}</span>
                            </li>
                            <li>
                                <img src="../../../images/icon-valueLock.svg" alt="" />
                                Current Values Locked:<span>$ --</span>
                            </li>
                            <li>
                                <img src="../../../images/icon-lockAntiBot.svg" alt="" />
                                Anti-Bot:<span>{dataInfo?.antiBotEnable ? <Link to={`/anti-bot/${dataInfo?.antiBotId}`}>Anti-bot</Link> : '--'}</span>
                            </li>
                        </> : <>
                            <li>
                                <img src="../../../images/icon-address.svg" alt="" />
                                LP Token Address:
                                <span className="c-copy">
                                    {dataInfo?.tokenAddress ? dataInfo?.tokenAddress?.substring(0,4) + "..." + dataInfo?.tokenAddress?.substr(dataInfo?.tokenAddress?.length - 4) : '--'}
                                    <CopyToClipboard text={dataInfo?.tokenAddress} onCopy={() => handleCopy()}>
                                        <button><img src={ImgCopy} alt="" /></button>
                                    </CopyToClipboard>
                                </span>
                            </li>
                            <li>
                                <img src="../../../images/icon-name.svg" alt="" />
                                LP Token Name:<span>{dataInfo?.name ? dataInfo?.name : '--'}</span>
                            </li>
                            <li>
                                <img src="../../../images/icon-sympol.svg" alt="" />
                                Pair:<span>{dataInfo?.pair ? dataInfo?.pair : '--'}</span>
                            </li>
                            <li>
                                <img src="../../../images/icon-decimal.svg" alt="" />
                                Dex:<span>{dataInfo?.dex ? dataInfo?.dex : '--'}</span>
                            </li>
                            <li>
                                <img src="../../../images/icon-totalSupply.svg" alt="" />
                                Current LP Amount: <span>{formatNumber(dataInfo?.totalSupply)}</span>
                            </li>
                            {/* <li>
                                <img src="../../../images/icon-lockAmount.svg" alt="" />
                                Current LP Lock: <span>{formatNumber(dataInfo?.totalLpLock)}</span>
                            </li> */}
                        </>}
                        
                    </ul>
                </div>
                <LockRecordTable 
                    tokenAddress={dataInfo?.tokenAddress}
                    records={dataInfo?.vestingTeams} 
                    symbol={dataInfo?.symbol} 
                    type={typeHandle} 
                    teamLockId={teamLockId} 
                />
            </div>
        </div>
        </>
    )
}

export default LockRecords;