import { ArrowRightOutlined, DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Progress, Row } from 'antd';
import { useState, useEffect } from 'react'; 

const BackerPartner = () => {
    
    return (
        <>
            <div className='container'>
                
                <div className='backer-partner-block'>
                    <h3>Backer & Partner</h3>
                    
                    <div className='backer-partner-content'>
                        <ul>
                            <li><img src="../images/backer-partner/bit-value.svg" alt=""/></li>
                            <li><img src="../images/backer-partner/bscs.svg" alt=""/></li>
                            <li><img src="../images/backer-partner/lossless.svg" alt=""/></li>
                            <li><img src="../images/backer-partner/trade-coin-vn.svg" alt=""/></li>
                            <li><img src="../images/backer-partner/kdg.svg" alt=""/></li>
                            <li><img src="../images/backer-partner/trust-rank.svg" alt=""/></li>
                            <li><img src="../images/backer-partner/solid-proof.svg" alt=""/></li>
                            <li><img src="../images/backer-partner/arbitrum-insider.svg" alt=""/></li>
                            <li><img src="../images/backer-partner/arbitrum-space.svg" alt=""/></li>
                            <li><img src="../images/backer-partner/arbitrum-daily.svg" alt=""/></li>
                            <li><img src="../images/backer-partner/arbitrum-call.svg" alt=""/></li>
                            <li><img src="../images/backer-partner/mises.svg" alt=""/></li>
                            <li><img src="../images/backer-partner/a2m-venture.svg" alt=""/></li>
                        </ul>
                    </div>
                </div>
            </div>  
        </> 
   )
}
 
export default BackerPartner;