import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// ================================================
// ==                  ACTION                    ==
// ================================================

export const getHeader = (payload:any) => {
  return async dispatch => { 
      dispatch(setHeader(payload)); 
  }
}

// ================================================
// ==                REDUX STORE                 ==
// ================================================
export interface HeaderState {
    header: string;
    loading: boolean;
    errors: string;
}

const initialState: HeaderState = {
    header: "",
    loading: false,
    errors: "",
}

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setHeader: (state, { payload }: PayloadAction<string>) => {
      state.header = payload
    },
  },
})

export const { setLoading, setErrors, setHeader } = headerSlice.actions

export default headerSlice.reducer

export const headerSelector = (state: { headerStore: HeaderState }) => state.headerStore