import React from "react";
import { Link } from "react-router-dom";

interface Props {
    setIsHeaderBanner?: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderBanner: React.FC<Props> = (props: Props) => {
    const { setIsHeaderBanner } = props;
    const handleSetBanner = () => {
        setIsHeaderBanner && setIsHeaderBanner(false)
    }
    return (
        <div className="c-headerBanner">
            <button onClick={() => handleSetBanner()}><img src="../images/headerBanner-close.svg" alt="" /></button>
            <div className="c-headerBanner__box">
                <div className="c-headerBanner__icon">
                    <img src="../images/headerBanner-icon.png" alt="" />
                </div>
                <div className="c-headerBanner__content">
                    <h3>
                        IBO is finished. <br className="is-br991" />Claim your BLS token at <span className="is-blue"><a href="http://bluesale.finance/sale">http://bluesale.finance/sale</a></span>. <br className="is-br1200" />
                        You could Buy BLS <span className="is-blue"><a href="https://www.mexc.com/exchange/BLS_USDT" target="_blank">here</a></span>
                        {/* <span className="is-gray">Please make sure you’re visiting</span> <br className="is-br991"/><a href="http://bluesale.finance/sale">http://bluesale.finance/sale</a> - <span className="is-gray">check the URL carefully.</span> */}
                    </h3>
                </div>
            </div>
        </div>
    )
}

export default HeaderBanner;