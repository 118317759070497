import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { bsc, injected, walletconnect, walletconnect1 } from '../connectors';

export default function useConnectWallet() {
	const { connector, account, activate, deactivate, active } = useWeb3React();
	const [currentConnector, setCurrentConnector] = useState();
	const [currentConnectorId, setCurrentConnectorId] = useState();

	useEffect(() => {
		if (account) {
			if (currentConnectorId && currentConnector && currentConnector === connector) {
				localStorage.setItem('accountStatus', '1');
				localStorage.setItem('connectorId', currentConnectorId);
				localStorage.setItem('account', account);
			}
		}
	}, [account, currentConnectorId, currentConnector, connector]);

	async function walletLogin(connectorId:any) {
		let _connector:any;

		switch (connectorId) {
			case 'walletconnect':
				// if (!walletconnect.connected) {
				// 	// create new session
				// 	walletconnect.createSession();
				// }
				// walletconnect.on("connect", (error, payload) => {
				// 	if (error) {
				// 	  throw error;
				// 	}
				// 	window.location.reload();
				// });
				  
				// walletconnect.on("session_update", (error, payload) => {
				// 	if (error) {
				// 	  throw error;
				// 	}
				// 	window.location.reload();
				// });
				  
				// walletconnect.on("disconnect", (error, payload) => {
				// 	if (error) {
				// 	  throw error;
				// 	}
				// 	window.location.reload();
				// });
				_connector = walletconnect;
				setCurrentConnectorId(connectorId);
				setCurrentConnector(_connector);
				!active && (await activate(_connector));

				break;
			case 'bsc':
				_connector = bsc;
				setCurrentConnectorId(connectorId);
				setCurrentConnector(_connector);
				!active && (await activate(_connector));

				break;
			default:
				// injected
				_connector = injected;
				setCurrentConnectorId(connectorId);
				setCurrentConnector(_connector);
				!active && (await activate(_connector));
				break;
		}
	}

	function walletLogout() {
		deactivate();
		window.localStorage.removeItem('accountStatus');
		window.localStorage.removeItem('connectorId');
		window.localStorage.removeItem('account');
		window.localStorage.removeItem('AUTHUSER');
		localStorage.removeItem('persist:root');
		window.localStorage.setItem('step', "1");
		window.location.reload();
	}

	return { walletLogin, walletLogout };
}
