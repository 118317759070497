import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Tabs, Button as ButtonAntd } from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Button, Loading, SearchChange } from '../component';
import useWindowSize from '../../hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getTokenInformByAddress} from '../../redux/slicers/tokenInformSlice';
import { setTokenStorage } from "../../redux/slicers/tokenStorageSlice";
import { launchpadCreate } from '../../contracts/launchpad';
import { useTokenContract } from '../../hooks/useContract';
import { useLaunchpadDeployContract } from '../../hooks/useContract';
import { ethers } from 'ethers';
import { APP_SETTINGS, DEX_EXCHANGES, FEE_SETTINGS, LAUNCHPAD_CONFIGS, RPC_URL_CONVERT, SMART_CONTRACT_ADDRESS, ZOOM } from '../../constants';
import { useActiveWeb3React } from "../../hooks";
import { additionInfo, createLauchpad, createLaunchpadSelector, lauchpadInfo, verifyToken } from "../../redux/slicers/createLauchpadSlice";
import { convertToWei } from '../../contracts/utils';
import web3 from "web3";
import { Store } from 'react-notifications-component';
import addNotify from '../commons/Notify/addNotify';
import { formatDate } from '../../utils/formatDate';
import Countdown, { zeroPad } from 'react-countdown';
import { getCurrencyAddress, getCurrencyDecimals, getLiquidityTokens, getMainCurrencyDecimals, getPresaleTokens, getTotalFeeTokens } from "./ultils";
import { getAppSettings, settingSelector } from '../../redux/slicers/settingSlice';
import { formatNumber } from '../../utils/formatNumber';
import axios from 'axios';
import { tokenInformation } from '../../contracts/token';
import { useSearchParams } from 'react-router-dom';
import LaunchpadABI from "../../contracts/abi/LaunchpadABI.json";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Size {
    width: number;
    height: number;
}

interface vestingTeam {
    totalTeamToken: string,
    dayReleaseAfterList: string,
    percentTokenTGE: string ,
    vestingCycle: string ,
    tokenReleaseCycle: string
} 

const chartOptions = {
    plugins: {
        tooltip: {
            callbacks: {
                label: function(tooltipItem:any) {
                    return ' '+tooltipItem.parsed+'% '+tooltipItem.label;
                }
            }
        }
    },
}
interface ITokenCreateForm {
    setActiveKey: any,
} 

declare const window: Window & typeof globalThis & { ethereum: any };

const Finish: React.FC<ITokenCreateForm>  =(props: ITokenCreateForm) => { 
    const {chainId, account} = useActiveWeb3React();
    let provider= "";
    if(chainId !== 1 && chainId){
        provider = RPC_URL_CONVERT[chainId];
    }
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
  
    const [copied, setCopied] = useState<boolean>(false); 
    const [addWhitelist, setAddWhitelist] = useState<boolean>(false);
    const [listWhitelist, setListWhitelist] = useState<boolean>(false);
    const [disableWhitelist, setDisableWhitelist] = useState<boolean>(false); 
    const [removeWhitelist, setRemoveWhitelist] = useState<boolean>(false);
    const [marketcapToken, setMarketcapToken] = useState<number>(0);  
    const [totalLiquidity, setTotalLiquidity] = useState<number>(0);  
    const [totalPresale, setTotalPresale] = useState<number>(0);
    const [totalFeeTokens, setTotalFeeTokens] = useState<number>(0);
    const [actionStatus, setActionStatus] = useState("");
    const [bnbBalance, setBnbBalance] = useState<number>(0);
     const [dexExchanges, setDexExchanges] = useState<any>([]);
    const [chainDecimals, setChainDecimals] = useState<number>(0);
    const [priceBNB, setPriceBNB] = useState<any>();
    const [tokenID, setTokenID] = useState<number>(0);
    const [tokenParam, setTokenParam] = useState<string>('');
    const [isManualListing, setIsManualListing] = useState<boolean>(false);
    const [tokenInfos, setTokenInfos] = useState<any>();
    //INIT_LAUNCHPAD_FEE
    const [createPoolFee, setCreatePoolFee] = useState<any>(0);
    const [loadingPage, setLoadingPage] = useState<boolean>(false);
    const [launchpadRaisedFee, setLaunchpadRaisedFee] = useState<any>(0);

    // LAUNCHPAD_TOKEN_RAISED_FEE
    const [launchpadTokenRaisedFee, setLaunchpadTokenRaisedFee] = useState<any>(0);

    const { TabPane } = Tabs;
    const size: Size = useWindowSize();  
    const dispatch = useAppDispatch(); 
    const { additionInfoStorage, lauchpadInfoStorage, verifyTokenStorage } = useAppSelector(createLaunchpadSelector);  
    const { settings } = useAppSelector(settingSelector); 
    const tokenContract = useTokenContract(verifyTokenStorage && verifyTokenStorage.tokenAddress);
    
    const [searchParams, setSearchParams] = useSearchParams();
    let urlTokenAddress = searchParams.get("token");
    let typeLaunchPad = searchParams.get("type");
    const launchpadDeployAddress = chainId && SMART_CONTRACT_ADDRESS[chainId]?.DEPLOY_LAUNCHPAD_ADDRESS;
    const launchpadDeployContract = useLaunchpadDeployContract(launchpadDeployAddress);
     
    let navigate = useNavigate();
    
    const addressTokenEllipsis = `${verifyTokenStorage?.tokenAddress?.substring(0, 4)}...${verifyTokenStorage?.tokenAddress?.substring(verifyTokenStorage?.tokenAddress.length - 4)}`;
    const chartPresale = totalPresale * 100/verifyTokenStorage?.totalSupply;
    
    const chartLiquidity = totalLiquidity * 100/verifyTokenStorage?.totalSupply;
    const chartUnlocked = 100 - (chartPresale + chartLiquidity);  
    const nodeEvn = process.env.REACT_APP_NODE_ENV;
    const data = {
        labels: ['Presale', 'Liquidity', 'Unlocked'],
        datasets: [
            {
                label: '',
                data: [chartPresale, chartLiquidity, chartUnlocked],
                backgroundColor: [
                    '#01058A',
                    '#012CB5',
                    '#1BA9EA',
                ],
                borderColor: [
                    '#01058A',
                    '#012CB5',
                    '#1BA9EA',
                ],
                borderWidth: 1,
            },
        ]
    };

    useEffect(() => { 
        setIsManualListing(verifyTokenStorage?.listingOptions === "MANUAL" ? true : false);
    }, [verifyTokenStorage, localStorage?.getItem("step")]); 

    useEffect(() => {
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    }); 

    useEffect(()=> {
        const payload:any = {
            "page": 0,
            "size": 100
        }
        dispatch(getAppSettings(payload));
    }, []);
 
    useEffect(() => {
        axios
        .get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=binancecoin')
        .then((res) => {
            setPriceBNB(res.data[0].current_price);
        });

        if(urlTokenAddress) {
            (async () => {
                await dispatch(getTokenInformByAddress(urlTokenAddress, (res:any) => {
                    if(res?.tokenInform?.id) {
                        setTokenID(res?.tokenInform?.id);
                    }
                }));
            })();
        }
    }, []);
    
    useEffect(() => {
        let _chainId = (nodeEvn === 'production' || nodeEvn === 'staging') ? 42161 : (chainId ?? 97);
        const feeSettings = FEE_SETTINGS[_chainId];
        if (settings && settings.length > 0) { 
            let _initLaunchpadFee = settings.filter((itemSetting) => itemSetting?.settingKey === feeSettings?.INIT_LAUNCHPAD_FEE)?.[0];  
            setCreatePoolFee(_initLaunchpadFee?.settingValue); 
            
            if(verifyTokenStorage.feeOptions.settingKey === feeSettings?.LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY ) { //  APP_SETTINGS.LIST_LAUNCHPAD_TOKEN_5_RAISED_ONLY) {
                
                let _launchpadRaisedFeeOnly = settings.filter((itemSetting) => feeSettings?.LIST_LAUNCHPAD_TOKEN_RAISED_FEE_ONLY === itemSetting.settingKey);
                
                if(_launchpadRaisedFeeOnly && _launchpadRaisedFeeOnly[0]) {
                    setLaunchpadRaisedFee(_launchpadRaisedFeeOnly[0].settingValue);
                    setLaunchpadTokenRaisedFee(0);
                }
            } 
            else {
                let _launchpadTokenRaisedFee = settings.filter((itemSetting) => feeSettings?.LIST_LAUNCHPAD_AMOUNT_RAISED_FEE === itemSetting.settingKey);
                if (_launchpadTokenRaisedFee && _launchpadTokenRaisedFee[0]) {
                    setLaunchpadTokenRaisedFee(_launchpadTokenRaisedFee[0].settingValue);
                }

                let launchpadAmountRaisedFee = settings.filter((itemSetting) => feeSettings?.LIST_LAUNCHPAD_TOKEN_RAISED_FEE === itemSetting.settingKey);
                if (launchpadAmountRaisedFee && launchpadAmountRaisedFee[0]) {
                    setLaunchpadRaisedFee(launchpadAmountRaisedFee[0].settingValue);
                }
            }
        }
    }, [settings]);

    useEffect(() => {
		if (account) {
            w3.eth.getBalance(account, (err, balance) => {
                if (balance) {
                    let _balance = web3.utils.fromWei(web3.utils.toBN(balance), 'ether');
                    let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
                    setBnbBalance(bnbBl);
                }
                return 0;
            });
		}
	}, [account]);

    useEffect(() => {
        if(chainId) {
            setDexExchanges(DEX_EXCHANGES[chainId]);
            setChainDecimals(getMainCurrencyDecimals(chainId));
        }
    },[chainId]);

    const _routerSelected = dexExchanges && dexExchanges.length > 0 ? dexExchanges.filter(item => item.id === lauchpadInfoStorage?.router) : null;
    const routerOjbect = _routerSelected && _routerSelected.length > 0 && _routerSelected[0];

    const getTypeLaunchPad = () => {
        let getType;
        if(Number(typeLaunchPad) === 1) {
            getType = 'Presale';
        } else if(Number(typeLaunchPad) === 2) {
            getType = 'Fair Launch';
        }
        else if(Number(typeLaunchPad) === 3) {
            getType = 'Private Sale';
        }
        return getType;
    }

    const infoDetail = <>
    <h3>
        <p> {additionInfoStorage?.name}<span>{getTypeLaunchPad()}</span></p>
        {/* <p>
            <span>Audit</span>
            <span>KYC</span>
            <span>SAFU</span>
        </p> */}
    </h3>
    <ul> 
        {additionInfoStorage?.website ? <li><a href={additionInfoStorage?.website}><img src="../images/detail-icon-website.svg" alt="" /></a></li> : ''}
        {additionInfoStorage?.telegram ? <li><a href={additionInfoStorage?.telegram}><img src="../images/detail-icon-tele.svg" alt="" /></a></li> : ''}
        {additionInfoStorage.twitter ? <li><a href={additionInfoStorage.twitter}><img src="../images/detail-icon-tw.svg" alt="" /></a></li> : ''}
        {additionInfoStorage.medium ? <li><a href={additionInfoStorage.medium}><img src="../images/detail-icon-medium.svg" alt="" /></a></li> : ''}
        {additionInfoStorage.facebook ? <li><a href={additionInfoStorage.facebook}><img src="../images/detail-icon-facebook.svg" alt="" /></a></li> : ''}
        {additionInfoStorage.discord ? <li><a href={additionInfoStorage.discord}><img src="../images/detail-icon-discord.svg" alt="" /></a></li> : ''}
        {additionInfoStorage.youtube ? <li><a href={additionInfoStorage.youtube}><img src="../images/detail-icon-youtube.svg" alt="" /></a></li> : ''}
    </ul>
    </>; 
    
    const handleBack = () =>{
        props.setActiveKey("4"); 
        localStorage.setItem("step", "4") 
    }
    
    const handleCancel = () => {
        dispatch(lauchpadInfo({}));
        dispatch(verifyToken({}));
        dispatch(setTokenStorage({}));
        dispatch(additionInfo({}))
        navigate("/launchpad/list");
        localStorage.setItem("step", "1");
    }

    const rendererTimer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return <span>Done !</span>;
        } else {
          // Render a countdown
          return <div className="c-countDown"><span>{zeroPad(days)}d</span>:<span>{zeroPad(hours)}h</span>:<span>{zeroPad(minutes)}m</span>:<span>{zeroPad(seconds)}s</span></div>;
        }
    };
    
    const dispatchCreateLauchpad = async (launchpadAddress:string, lockId:any) => {   
       let type;
        if(tokenID === 0) {
            await dispatch(getTokenInformByAddress(urlTokenAddress, (res:any) => {
                if(res?.tokenInform && res?.tokenInform?.id) {
                    setTokenID(res?.tokenInform?.id);
                }
            }));
        }

        if(Number(typeLaunchPad) === 1) {
            type = 'ICO';
        } else if(Number(typeLaunchPad) === 2) {
            type = 'FAIR';
        }

        const payload :any = { 
            "ownerAddress":account?.toLocaleLowerCase(),
            "presaleAddress":launchpadAddress.toLowerCase(),
            "launchpadName": additionInfoStorage?.name,
            "launchpadInfo": additionInfoStorage?.info,
            "bannerUrl": additionInfoStorage?.banner,
            "bannerThumbnail": additionInfoStorage?.bannerThumbnail,
            "logoUrl": additionInfoStorage?.logo,
            "tokenInformId": tokenID,
            "currency": verifyTokenStorage?.currency,
            "feeOptions": verifyTokenStorage?.feeOptions?.settingKey,
            "listingOptions": verifyTokenStorage?.listingOptions,
            "softCap": lauchpadInfoStorage?.softcap,
            "hardCap": lauchpadInfoStorage?.hardcap, 
            "audit": false,
            "kyc": false,
            "safu": false,
            "antiRug": true,
            "swapRate": lauchpadInfoStorage?.swapRate,
            "minBuy": lauchpadInfoStorage?.minBuy,
            "maxBuy": lauchpadInfoStorage?.maxBuy,
            "raisedFee": launchpadRaisedFee ? Number(launchpadRaisedFee) : 0,
            "raisedTokenFee": launchpadTokenRaisedFee ? Number(launchpadTokenRaisedFee) : 0,
            "refundType": lauchpadInfoStorage?.refundType === '0' ? "BURN" : "REFUND",
            "whiteListOnly": lauchpadInfoStorage?.whiteListOnly,
            "startTime": lauchpadInfoStorage?.startTime,
            "endTime": lauchpadInfoStorage?.endTime,
            "router": lauchpadInfoStorage?.router,
            "chainId": chainId,
            "listingRate": lauchpadInfoStorage?.listingRate,
            "liquidityPercent": lauchpadInfoStorage?.liquidityPercent,
            "liquidityLockup": lauchpadInfoStorage?.liquidityLockup,
            "socialNetworks": {
                "facebook": additionInfoStorage?.facebook,
                "twitter":  additionInfoStorage?.twitter,
                "medium":  additionInfoStorage?.medium,
                "discord":  additionInfoStorage?.discord,
                // "github": additionInfoStorage?.github,
                "telegram":  additionInfoStorage?.telegram,
                // "instagram":  additionInfoStorage?.instagram,
                // "reddit":  additionInfoStorage?.reddit,
                "website": additionInfoStorage?.website,
                "youtube": additionInfoStorage?.youtube
            },
            "type": typeLaunchPad && type,
            "usingVestingContributors": lauchpadInfoStorage?.usingVestingContributors,
            "vestingContributor": {
                "firstReleasePercent": lauchpadInfoStorage?.vestingContributor?.firstReleasePercent,
                "cliffVesting": lauchpadInfoStorage?.vestingContributor?.cliffVesting,
                "lockAfterCliffVesting": lauchpadInfoStorage?.vestingContributor?.lockAfterCliffVesting,
                "vestingPeriodEachCycle": lauchpadInfoStorage?.vestingContributor?.vestingPeriodEachCycle,
                "tokenReleaseEachCycle": lauchpadInfoStorage?.vestingContributor?.tokenReleaseEachCycle
            },
            "usingVestingTeamLock": lauchpadInfoStorage?.usingVestingTeamLock,
            "createVestingTeam": {
                "title": lauchpadInfoStorage?.vestingTeam?.title,
                "useAnotherOwner": lauchpadInfoStorage?.vestingTeam?.useAnotherOwner,
                "anotherOwner": lauchpadInfoStorage?.vestingTeam?.anotherOwner,
                "useVesting": lauchpadInfoStorage?.vestingTeam?.useVesting,
                "tgeDate": lauchpadInfoStorage?.vestingTeam?.tgeDate,
                "amount": lauchpadInfoStorage?.vestingTeam?.amount,
                "tgePercent": lauchpadInfoStorage?.vestingTeam?.tgePercent,
                "vestingCycle": lauchpadInfoStorage?.vestingTeam?.vestingCycle,
                "cycleReleasePercent": lauchpadInfoStorage?.vestingTeam?.cycleReleasePercent,
                "cliffVesting": lauchpadInfoStorage?.vestingTeam?.cliffVesting,
                "lockAfterCliffVesting": lauchpadInfoStorage?.vestingTeam?.lockAfterCliffVesting,
                'lockId': lockId
            }
        }
        
        dispatch(createLauchpad(payload, async (res:any) => { 
            if(res.result === "SUCCESS") {
                if(res?.data) {
                    // delete all local storage (react persist)  
                    dispatch(lauchpadInfo({}));
                    dispatch(verifyToken({}));
                    dispatch(setTokenStorage({}));
                    dispatch(additionInfo({}))
                    localStorage.setItem('step', "1"); 

                    // navigate to launchpad detail
                    {account ?  navigate('/launchpad/' + res?.data?.handle+ "?refID=" + account) 
                    : navigate('/launchpad/' + res?.data?.handle)}
                   
                    setActionStatus("SUCCESS");
                    Store.addNotification(addNotify("Launchpad created successfully.", 'success'));
                }
            }
            else {
                setActionStatus("");
                if (res?.error?.message) {
                    Store.addNotification(addNotify(res?.error?.message, 'danger'));
                }
            }
        }));
    }
    let tokensPresale:number = 0;
    let tokensLiquidity:number = 0;
    let feeTokenRaised:number = 0;
     
    useEffect(() => {
        setLoadingPage(true);
        (async() => {
            await tokenInformation(tokenContract)
            .then((res:any) => {   
                setTokenInfos(res);
                setLoadingPage(false);
            });
        })();
    }, [tokenContract]);

    let _totalFeeTokens:number = 0;

    useEffect(()=> {
        if(lauchpadInfoStorage?.swapRate && lauchpadInfoStorage?.hardcap) {
            tokensPresale = getPresaleTokens(lauchpadInfoStorage?.swapRate, lauchpadInfoStorage?.hardcap);

            if(launchpadRaisedFee) {
                _totalFeeTokens = getTotalFeeTokens(lauchpadInfoStorage?.swapRate, lauchpadInfoStorage?.hardcap, launchpadRaisedFee);
            }
        }
        if(lauchpadInfoStorage?.swapRate && lauchpadInfoStorage?.hardcap && lauchpadInfoStorage?.liquidityPercent) {
            tokensLiquidity = getLiquidityTokens(lauchpadInfoStorage?.hardcap, feeTokenRaised, lauchpadInfoStorage?.liquidityPercent, lauchpadInfoStorage?.listingRate);
        } 
        setTotalLiquidity(tokensLiquidity);
        setTotalPresale(tokensPresale);
        setTotalFeeTokens(_totalFeeTokens);
        const marketcap = verifyTokenStorage?.currency === 'BNB' 
        ? ( 1 / (Number(lauchpadInfoStorage?.listingRate) / Number(priceBNB)) * Number(tokenInfos?.totalSupply)) 
        : ( 1 / Number(lauchpadInfoStorage?.listingRate)) * Number(tokenInfos?.totalSupply); 
        setMarketcapToken(Number(marketcap));  
        
    }, [lauchpadInfoStorage, verifyTokenStorage, tokenInfos, priceBNB, launchpadRaisedFee]);  

    const handleLaunchpadCreate = async () => {  
        //launchpadInfo
        const tokenDecimals = Number(tokenInfos?.decimals);
        const currencyDecimals = getCurrencyDecimals(chainId, verifyTokenStorage?.currency);
        const icoToken = tokenInfos?.tokenAddress;
       
        if (!icoToken || icoToken.toLowerCase() == ethers.constants.AddressZero.toLocaleLowerCase()) {
            Store.addNotification(addNotify('Invalid Token Address!', 'danger'));
            return;
        }

        const feeToken = getCurrencyAddress(chainId, verifyTokenStorage?.currency);
        const softCap = convertToWei(lauchpadInfoStorage?.softcap, currencyDecimals);
        const hardCap = convertToWei(lauchpadInfoStorage?.hardcap, currencyDecimals);

        if (Number(softCap) > Number(hardCap)) {
            Store.addNotification(addNotify('Invalid Cap Setting!', 'danger'));
            return;
        }

        const presaleRate = convertToWei(lauchpadInfoStorage?.swapRate, tokenDecimals);
        const minInvest = convertToWei(lauchpadInfoStorage?.minBuy, currencyDecimals);
        const maxInvest = convertToWei(lauchpadInfoStorage?.maxBuy, currencyDecimals);

        if (Number(minInvest) > Number(maxInvest)) {
            Store.addNotification(addNotify('Invalid Investment Setting!', 'danger'));
            return;
        }

        const now = new Date();  
       
        if (new Date(lauchpadInfoStorage?.startTime) < now) {
            Store.addNotification(addNotify('Invalid Start Time Setting', 'danger'));
            return;
        }

        const startTime = Math.floor((new Date(lauchpadInfoStorage?.startTime).getTime()) / 1000);
        const endTime = Math.floor((new Date(lauchpadInfoStorage?.endTime).getTime()) / 1000);

        if (startTime > endTime) {
            Store.addNotification(addNotify('Invalid Time Setting!', 'danger'));
            return;
        }
      

        const whitelistPool = lauchpadInfoStorage?.whiteListOnly == true ? 1 : 0;
        const affiliatePool = verifyTokenStorage?.affiliateOptions == true ? 1 : 0;
        const poolType = Number(lauchpadInfoStorage?.refundType);
        
        // claimInfo
        const firstRelease = parseFloat(lauchpadInfoStorage?.vestingContributor?.firstReleasePercent) > 0;
        const firstReleasePercent = firstRelease ? Number(lauchpadInfoStorage?.vestingContributor?.firstReleasePercent)*100 : 0;
        const vestingPeriodEachCycle = firstRelease ? Number(lauchpadInfoStorage?.vestingContributor?.vestingPeriodEachCycle) * 60 : 0;
        const tokenReleaseEachCycle = firstRelease ? Number(lauchpadInfoStorage?.vestingContributor?.tokenReleaseEachCycle) * 100 : 0;
        const cliffVesting = firstRelease ? Number(lauchpadInfoStorage?.vestingContributor?.cliffVesting) * 60 : 0;
        const lockAfterCliffVesting = firstRelease ? Number(lauchpadInfoStorage?.vestingContributor?.lockAfterCliffVesting) * 60 : 0;
        
        if (firstReleasePercent < 0 || tokenReleaseEachCycle < 0 || (firstReleasePercent + tokenReleaseEachCycle)/100 > 100) {
            Store.addNotification(addNotify('Invalid User Claim Percent Setting!', 'danger'));
            return;
        }
      
        // teamVestingInfo
        const teamFirstRelease = lauchpadInfoStorage?.usingVestingTeamLock;
        const teamLockAfterRelease = lauchpadInfoStorage?.vestingTeam?.useVesting; 
        const teamFirstReleasePercent = teamLockAfterRelease ? Number(lauchpadInfoStorage?.vestingTeam?.tgePercent ?? 0) * 100 : 0;
        const teamVestingPeriodEachCycle = teamFirstRelease ? Number(lauchpadInfoStorage?.vestingTeam?.lockAfterCliffVesting ?? 0) * 60 : 0;
        const teamTokenReleaseEachCycle = teamLockAfterRelease ? Number(lauchpadInfoStorage?.vestingTeam?.cycleReleasePercent ?? 0)* 100 : 0;
        const teamCliffVesting = teamFirstRelease ? Number(lauchpadInfoStorage?.vestingTeam?.cliffVesting ?? 0) : 0; 
        const teamTotalVestingTokens = teamFirstRelease ? convertToWei(lauchpadInfoStorage?.vestingTeam?.amount, tokenDecimals) : 0;
      
        if (teamFirstReleasePercent < 0 || teamTokenReleaseEachCycle < 0 || teamFirstReleasePercent + teamTokenReleaseEachCycle > ZOOM) {
            Store.addNotification(addNotify('Invalid Team Claim Percent Setting!', 'danger'));
            return;
        }

        // dexInfo
        const listingPercent = isManualListing ? 0 : Number(lauchpadInfoStorage?.liquidityPercent) * 100;
        const listingPrice = convertToWei(Number(lauchpadInfoStorage?.listingRate), tokenDecimals);
        const lpLockTime = isManualListing ? 0 : lauchpadInfoStorage?.liquidityLockup ? Number(lauchpadInfoStorage?.liquidityLockup) * 60 : 0;
        if (listingPercent && (listingPercent/100 < 0 || listingPercent/100 > 100)) {
            Store.addNotification(addNotify('Invalid Listing Percent Setting!', 'danger'));
            return;
        }

        // feeInfo
        const initFee = convertToWei(createPoolFee, chainDecimals);
        const raisedFeePercent = (Math.floor(launchpadRaisedFee ? Number(launchpadRaisedFee) : 0) * 100);
        const raisedTokenFeePercent = (Math.floor(launchpadTokenRaisedFee ? Number(launchpadTokenRaisedFee) : 0) * 100);
        const penaltyFee = Math.floor(10 * 100);

        const launchpadInfo = [
            icoToken,  
            feeToken, 
            softCap,  
            hardCap, 
            presaleRate, 
            minInvest, 
            maxInvest, 
            startTime, 
            endTime, 
            whitelistPool, 
            poolType,
            affiliatePool
        ];
        
        const claimInfo = [
            cliffVesting, 
            lockAfterCliffVesting, 
            firstReleasePercent, 
            vestingPeriodEachCycle, 
            tokenReleaseEachCycle
        ];

        const teamVestingInfo = [
            teamTotalVestingTokens, 
            teamCliffVesting, 
            teamFirstReleasePercent, 
            teamVestingPeriodEachCycle, 
            teamTokenReleaseEachCycle
        ]; 
        
        const dexInfo = [
            isManualListing,
            routerOjbect?.routerAddress ? routerOjbect?.routerAddress : "0x0000000000000000000000000000000000000000", 
            routerOjbect?.factoryAddress ? routerOjbect?.factoryAddress : "0x0000000000000000000000000000000000000000", 
            listingPrice, 
            listingPercent, 
            lpLockTime
        ];

        const feeInfo = [
            initFee, 
            raisedFeePercent, 
            raisedTokenFeePercent, 
            penaltyFee
        ];
        
        setActionStatus("LOADING"); 
        
        await launchpadCreate(
            launchpadDeployContract,
            launchpadInfo,
            claimInfo,
            teamVestingInfo,
            dexInfo,
            feeInfo,
            verifyTokenStorage?.percentAffiliate ? verifyTokenStorage?.percentAffiliate *100 : 0
        ).then((res)=> {
            let resWait:any = res.wait();
            resWait.then((resTransaction:any) => { 
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    if( resTransaction.logs && 
                        resTransaction.logs.length > 0 && 
                        resTransaction.logs[0].address
                    ) {
                        (async () => {
                            // Call API to save data from SC
                            dispatchCreateLauchpad(resTransaction.logs[0].address, 0);
                        })();
                    }
                }
            }).catch((error:any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            });
        }).catch((error:any) => {
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, 'danger'));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, 'danger'));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, 'danger'));
                }
            }
        });
    }
    
    const handleNetwork = (network:any) => {
        let networkName:any = '';
        if(network === 'BSCTESTNET') {
            networkName = <span className="is-network"><img src="../images/icon-bnb.svg" alt="" />BSC TESTNET</span>;
        } else if(network === 'BSC') {
            networkName = <span className="is-network"><img src="../images/icon-bnb.svg" alt="" />Binance Smart Chain</span>;
        } else if(network === 'ETH') {
            networkName = <span className="is-network"><img src="../images/icon-eth.svg" alt="" />Ethereum</span>;
        } else if(network === 'POLY') {
            networkName = <span className="is-network"><img src="../images/icon-polygon.svg" alt="" />Polygon</span>;
        } else if(network === 'ARBTESTNET') {
            networkName = <span className="is-network"><img src="../images/arb-icon.svg" alt="" />ARB TESTNET</span>;
        } else if(network === 'ARB') {
            networkName = <span className="is-network"><img src="../images/arb-icon.svg" alt="" />Arbitrum</span>;
        }

        return networkName;
    }

    const handleLinkNetwork = (network:any) => {
        let link:any = '';
        if(network === 'BSCTESTNET') {
            link = 'https://testnet.bscscan.com/address/';
        } else if(network === 'BSC') {
            link = 'https://bscscan.com/address/';
        } else if(network === 'ETH') {
            link = '';
        } else if(network === 'POLY') {
            link = '';
        } else if(network === 'ARBTESTNET') {
            link = 'https://goerli.arbiscan.io/address/';
        } else if(network === 'ARB') {
            link = 'https://arbiscan.io/address/';
        }
        return link;
    }

    return(
        <div className="verifyToken"> 
        <div className='step'>Step 5</div> 
        {copied && <span className="c-noti is-success">Copied</span>}
        <div className="p-launchpadDetail"> 
            <div className="p-launchpadDetail__banner">
                    <img src={additionInfoStorage?.banner ? additionInfoStorage?.banner : "../images/bgDefault.svg"} alt="" />
                <h2 className="p-launchpadDetail__logo">
                    <img src={additionInfoStorage?.logo ? additionInfoStorage?.banner : "../images/logoDefault.svg"} alt="" />  
                   {additionInfoStorage?.name}
                </h2>
            </div>
        
            <div className="p-launchpadDetail__detail">
                <div className="p-launchpadDetail__infoSC"> 
                {size.width <= 767 ? <div className="p-launchpadDetail__info">{infoDetail}</div> : ''}
                <div className={`p-launchpadDetail__status is-Upcoming`}>
                    <h3>
                        <span>Upcoming</span>
                        <Countdown date={lauchpadInfoStorage?.startTime} renderer={rendererTimer} />
                    </h3>
                    <div className="c-progress">
                        <p className="c-progress__title">Progress (0%)</p>
                        <div className="c-progress__bar"><span style={{width: 0+'%'}}></span></div>
                        <p className="c-progress__txt">0 {verifyTokenStorage?.currency}<span> {lauchpadInfoStorage?.hardcap} {verifyTokenStorage?.currency}</span></p>
                    </div>
                    {/* <div className="c-input">
                        <p className="c-input__title">Amount (Max: 10 {verifyTokenStorage?.currency})</p>
                        <div className="c-input__max">
                            <input disabled type="number" value={valueAmount} onChange={(e) => onAmount(e)} />
                            <span>Max</span>
                        </div>
                    </div> */}
                    {/* <p className="c-txt">Available: {bnbBalance} {verifyTokenStorage?.currency}</p> */}
                    {/* <div style={{textAlign: 'center'}}>
                        <Button disabled text={`Buy with ${verifyTokenStorage?.currency}`} type='blue' />
                    </div> */}
                </div>
                <div className={`p-launchpadDetail__boxFixed createLauchpad`}>
                    <p>Sale Type<span>{verifyTokenStorage?.whiteListOnly ? "Whitelist" : "Not Whitelist"}</span></p>
                    <p>Soft Cap<span>{formatNumber(lauchpadInfoStorage?.softcap)} {verifyTokenStorage?.currency}</span></p>
                    <p>Hard Cap<span>{formatNumber(lauchpadInfoStorage?.hardcap)} {verifyTokenStorage?.currency}</span></p>
                    {
                        lauchpadInfoStorage?.vestingContributor?.firstReleasePercent=== 100? 
                        <p>Vesting For Presale<span>First Release 100%</span></p>
                        :<p>Vesting For Presale<span>{lauchpadInfoStorage?.vestingContributor?.tokenReleaseEachCycle}% each {lauchpadInfoStorage?.vestingContributor?.vestingPeriodEachCycle} minutes</span></p>
                    } 
                </div>
                </div>
                <div className="p-launchpadDetail__content">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Pool Information" key="1">
                            <div className="p-launchpadDetail__info">
                                {size.width <= 767 ? '' : infoDetail}
                                <div className="p-launchpadDetail__boxInfo">
                                    <p className="p-launchpadDetail__row">Start time<span>{formatDate(lauchpadInfoStorage?.startTime)}</span></p>
                                    <p className="p-launchpadDetail__row">End time<span>{formatDate(lauchpadInfoStorage?.endTime)}</span></p>
                                    <p className="p-launchpadDetail__row">Network{handleNetwork(verifyTokenStorage?.networkChain)}</p>
                                    <p className="p-launchpadDetail__row">Token for presale<span>{formatNumber(totalPresale)} {tokenInfos?.symbol}</span></p>
                                    <p className="p-launchpadDetail__row">Token for liquidity<span>{formatNumber(totalLiquidity)} {tokenInfos?.symbol}</span></p>
                                    <p className="p-launchpadDetail__row">Presale rate<span> 1 {verifyTokenStorage?.currency} = {formatNumber(lauchpadInfoStorage?.swapRate)} {tokenInfos?.symbol}</span></p>
                                    <p className="p-launchpadDetail__row">First release for presale<span>{lauchpadInfoStorage?.vestingContributor?.firstReleasePercent}%</span></p>
                                    <p className="p-launchpadDetail__row">Listing rate<span>1 {verifyTokenStorage?.currency} = {lauchpadInfoStorage?.listingRate} {tokenInfos?.symbol}</span></p>
                                    <p className="p-launchpadDetail__row">Initial marketcap (Estimate)<span>${formatNumber(marketcapToken)}</span></p>
                                    <p className="p-launchpadDetail__row">Soft cap<span>{lauchpadInfoStorage?.softcap} {verifyTokenStorage?.currency}</span></p>
                                    <p className="p-launchpadDetail__row">Hard cap<span>{lauchpadInfoStorage?.hardcap} {verifyTokenStorage?.currency}</span></p>
                                    <p className="p-launchpadDetail__row">Unsold tokens<span>{lauchpadInfoStorage?.refundType ==="0"?"Burn":"Refund"}</span></p>
                                    <p className="p-launchpadDetail__row">Listing on<span>{_routerSelected && _routerSelected.length > 0 && _routerSelected[0] ? _routerSelected[0].name : "--"}</span></p>
                                    {verifyTokenStorage?.listingOptions === "AUTO" && <>
                                        <p className="p-launchpadDetail__row">Liquidity percent<span>{lauchpadInfoStorage?.liquidityPercent}%</span></p>
                                        <p className="p-launchpadDetail__row">Liquidity lockup time<span>{formatNumber(lauchpadInfoStorage?.liquidityLockup)} minutes after pool ends</span></p></>
                                    }
                                </div>
                                <div className="p-launchpadDetail__chart">
                                    <h3>Token Metrics</h3>
                                    <Doughnut data={data} options={chartOptions} />
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tab="About the Project" key="2">
                            <div className="p-launchpadDetail__info">
                                {size.width <= 767 ? '' : infoDetail}
                                <p className="p-launchpadDetail__txt">
                                    {additionInfoStorage?.info}
                                </p>
                            </div>
                        </TabPane>
                        <TabPane tab="Token Information" key="3">
                            <div className="p-launchpadDetail__info">
                                {size.width <= 767 ? '' : infoDetail}
                                <div className="p-launchpadDetail__boxInfo">
                                    <p className="p-launchpadDetail__row">Name<span>{tokenInfos?.tokenName}</span></p>
                                    <p className="p-launchpadDetail__row">Symbol<span>{tokenInfos?.symbol}</span></p>
                                    <p className="p-launchpadDetail__row">Decimals<span>{tokenInfos?.decimals}</span></p>
                                    <p className="p-launchpadDetail__row">
                                        Address
                                        <span>
                                            <a href={`${handleLinkNetwork(verifyTokenStorage?.networkChain)}${verifyTokenStorage?.tokenAddress}`}>{addressTokenEllipsis}</a>
                                            <CopyToClipboard text="hello" onCopy={() => setCopied(true)}>
                                            <button><img src="../images/icon-copy.svg" alt="" /></button>
                                            </CopyToClipboard>
                                        </span>
                                    </p>
                                    <p className="p-launchpadDetail__row">Total Supply<span>{formatNumber(tokenInfos?.totalSupply)}</span></p>
                                </div>
                            </div>
                        </TabPane> 
                    </Tabs>
                </div>
            </div>
            
        </div>

        {addWhitelist && <div className="c-popup">
            <div className="c-popup__content is-small">
                <h3 className="c-popup__title">Add Users To Whitelist</h3>
                <div className="c-popup__box"> 
                    <div className="c-input">
                    <p className="c-input__title">User wallet address</p>
                    <textarea placeholder='0x'></textarea>
                    </div>
                </div>
                <div className="c-popup__btn">
                    <Button text='Cancel' type='greyBorder' onClick={() => setAddWhitelist(false)}/>
                    <Button text='Add Address' type='blue' />
                </div>
            </div>
        </div>}

        {removeWhitelist && <div className="c-popup">
            <div className="c-popup__content is-small">
                <h3 className="c-popup__title">Remove Users To Whitelist</h3>
                <div className="c-popup__box">
                    <div className="c-input">
                        <p className="c-input__title">User wallet address</p>
                        <textarea placeholder='0x'></textarea>
                    </div>                    
                </div>
                <div className="c-popup__btn">
                    <Button text='Cancel' type='greyBorder' onClick={() => setRemoveWhitelist(false)}/>
                    <Button text='Remove Address' type='blue' />
                </div>
            </div>
        </div>}

        {listWhitelist && <div className="c-popup">
            <div className="c-popup__content is-btnAbsolute">
                <h3 className="c-popup__title">List Of Whitelist</h3>
                <SearchChange placeholder='Search wallet address'/>
                <p className="c-ttl">Wallet address</p>
                <div className="c-popup__whitelist">
                    <p>0xB1dFdc63208C398dF95Caa577A737c3feda72E4f<span>Remove</span></p>
                    <p>0xB1dFdc63208C398dF95Caa577A737c3feda72E4f<span>Remove</span></p>
                    <p>0xB1dFdc63208C398dF95Caa577A737c3feda72E4f<span>Remove</span></p>
                    <p>0xB1dFdc63208C398dF95Caa577A737c3feda72E4f<span>Remove</span></p>
                    <p>0xB1dFdc63208C398dF95Caa577A737c3feda72E4f<span>Remove</span></p>
                    <p>0xB1dFdc63208C398dF95Caa577A737c3feda72E4f<span>Remove</span></p>
                    <p>0xB1dFdc63208C398dF95Caa577A737c3feda72E4f<span>Remove</span></p>
                    <p>0xB1dFdc63208C398dF95Caa577A737c3feda72E4f<span>Remove</span></p>
                    <p>0xB1dFdc63208C398dF95Caa577A737c3feda72E4f<span>Remove</span></p>
                    <p>0xB1dFdc63208C398dF95Caa577A737c3feda72E4f<span>Remove</span></p>
                    <p>0xB1dFdc63208C398dF95Caa577A737c3feda72E4f<span>Remove</span></p>
                </div>
                <div className="c-popup__btn">
                    <Button text='Cancel' type='greyBorder' onClick={() => setListWhitelist(false)}/>
                    <Button text='Save Changes' type='blue' />
                </div>
            </div>
        </div>}

        {disableWhitelist && <div className="c-popup">
            <div className="c-popup__content is-w400">
                <div className="c-popup__icon"><img src="../images/popup-icon-delete.svg" alt="" /></div>
                <h3 className="c-popup__title">
                    Disable Whitelist
                    <span>Do you want to disable whitelist? You can’t restore this whitelist</span>
                </h3>
                <div className="c-popup__btn">
                    <Button text='Cancel' type='greyBorder' onClick={() => setDisableWhitelist(false)}/>
                    <Button text='Disable' type='blue' />
                </div>
            </div>
        </div>}

        <div className='btn-verify fixed'>
            <div className="isWidth">
                <Button text='Cancel' type='border'  onClick={()=> handleCancel()}/>
            </div> 
            <div className="m-25 isWidth">
                <ButtonAntd className="c-btn" htmlType="button" onClick={()=>handleBack()}>
                    Previous
                </ButtonAntd>
            </div> 
            <div className="isWidth">
                <Button text='Public' type="blue" 
                    isLoading={actionStatus === "LOADING" ? true : false}
                    disabled={(actionStatus === "SUCCESS" || actionStatus === "LOADING") ? true : false} 
                    onClick={()=> handleLaunchpadCreate()} 
                />
            </div> 
        </div>
        {loadingPage ? <Loading/> :null}
        </div>
    )
}

export default Finish;
