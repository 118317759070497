import { ArrowRightOutlined, DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Progress, Row } from 'antd';
import { useState, useEffect } from 'react'; 




const BlueSaleTokenSection = () => {
    
    return (
        <div className='container'>
            
            <Row>
                <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                    <div className='d-mobile'>
                        <div className='bluesale-token-right'>
                            <img src='../images/bluesale-token-right.svg' />
                        </div>
                    </div>
                    <div className='d-desktop'>
                        <div className='bluesale-token-content'>
                            <h3>BlueSale Token</h3>
                            <p>The DeFi market currently has millions of tokens, each with its unique mechanics and incentives. This complexity can make it difficult for users to make informed decisions.</p>
                            <p>To address this issue, the BlueSale token will be used across various products within the BlueSale ecosystem. This approach will simplify the decision-making process for users.</p>
                            <p>Furthermore, the platform will share a significant portion of its earnings with its users to support their growth and the development of new products. This process will generate several positive cycles within the ecosystem.</p>
                            
                            <button className='btn-main fill'>Buy BLS</button>
                            <a href="https://docs.bluesale.finance/" target="_blank">Whitepaper <ArrowRightOutlined /></a>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={14} md={14} lg={14} xl={14}>
                    <div className='d-desktop'>
                        <div className='bluesale-token-right'>
                            <img src='../images/bluesale-token-right.svg' />
                        </div>
                    </div>
                    <div className='d-mobile'>
                        <div className='bluesale-token-content'>
                            <h3>BlueSale Token</h3>
                            <p>The DeFi market currently has millions of tokens, each with its unique mechanics and incentives. This complexity can make it difficult for users to make informed decisions.</p>
                            <p>To address this issue, the BlueSale token will be used across various products within the BlueSale ecosystem. This approach will simplify the decision-making process for users.</p>
                            <p>Furthermore, the platform will share a significant portion of its earnings with its users to support their growth and the development of new products. This process will generate several positive cycles within the ecosystem.</p>
                            
                            <button className='btn-main fill'>Buy BLS</button>
                            <a href="">Whitepaper <ArrowRightOutlined /></a>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
   )
}
 
export default BlueSaleTokenSection;