import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import PriceService from "../services/PriceService";

// ================================================
// ==                  ACTION                    ==
// ================================================

export const getArbitrumPrice = (tokenId:any) => {
  return async dispatch => {
      await PriceService.getPrice(tokenId, 'cryptorank')
      .then((res:any) => {
        dispatch(setArbitrumPrice(res?.data?.data[0]?.values?.USD?.price.toFixed(3)));
      })
      .catch((error:any)=>{
      });
  }
}

export const getXBLSPrice = () => {
  return async dispatch => {
      await PriceService.getPrice('xbls')
      .then((res) => {
        if(res && res?.['xbls'] && res?.['xbls'].usd) {
          dispatch(setXBLSPrice(res?.['xbls']?.usd));
        }
      })
      .catch((error:any)=>{
      });
  }
}

export const getBLSPrice = () => {
  return async dispatch => {
      await PriceService.getPrice('bls')
      .then((res) => {
        if(res && res?.['bls'] && res?.['bls'].usd) {
          dispatch(setBLSPrice(res?.['bls'].usd));
        }
      })
      .catch((error:any)=>{
      });
  }
}

 const formatNumber = (x: any, max: any) => {
    if (x) {
        return x.toLocaleString("en-US", {
            minimumFractionDigits: 1,
            maximumFractionDigits: max,
        });
    } else return 0;
};

export const getBLSPriceMexc = () => {
  return async dispatch => {
    dispatch(setLoading(true));
    await PriceService.getPriceBLS()
    .then((res) => {
      dispatch(setLoading(false));
      dispatch(setBLSPriceMexc(formatNumber(res?.data?.bluesale?.usd, 5)));
    })
    .catch((error:any)=>{
      dispatch(setErrors(error));
      dispatch(setLoading(false));
    });
  }
}

// ================================================
// ==                REDUX STORE                 ==
// ================================================

type PriceObject = {
  [key: string]: void | string | number | any;
};

export interface PriceState {
    ARBPrice: PriceObject; 
    XBLSPrice: PriceObject;
    BLSPrice: PriceObject;
    BLSPriceMexc: PriceObject;
    loading: boolean;
    errors: string;
}

const initialState: PriceState = {
    ARBPrice: {},
    XBLSPrice: {},
    BLSPrice: {},
    BLSPriceMexc: {},
    loading: false,
    errors: "",
}

const priceSlice = createSlice({
  name: "price",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setArbitrumPrice: (state, { payload }: PayloadAction<PriceObject>) => {
      state.ARBPrice = payload
    }, 

    setXBLSPrice: (state, { payload }: PayloadAction<PriceObject>) => {
      state.XBLSPrice = payload
    }, 

    setBLSPrice: (state, { payload }: PayloadAction<PriceObject>) => {
      state.BLSPrice = payload
    },

    setBLSPriceMexc: (state, { payload }: PayloadAction<PriceObject>) => {
      state.BLSPriceMexc = payload
    }, 
  },
})

export const { setLoading, setErrors, setArbitrumPrice, setXBLSPrice, setBLSPrice, setBLSPriceMexc } = priceSlice.actions

export default priceSlice.reducer

export const priceSelector = (state: { priceStore: PriceState }) => state.priceStore; 
