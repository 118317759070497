import { useState, useEffect } from 'react'; 
import { Tabs } from 'antd'; 
import PrivateSaleInfo from '../../components/privatesale/privateSaleInfo';
import AdditionInfo from '../../components/privatesale/additionInfo';
import Finish from '../../components/privatesale/finish';
import { useNavigate } from 'react-router'; 

const PrivateSale = () => {
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState<string>('1'); 

    const onKeyChange : any = (key) =>
    {
        setActiveKey(key); 
        localStorage.setItem("stepPrivateSale", key);  
    }  
    
    useEffect(() => {
        setActiveKey(localStorage.getItem("stepPrivateSale")||"1");  
    }, [localStorage.getItem("stepPrivateSale")]);
 
    return (
    <div className='c-createLaunchpad'> 
        <Tabs
            activeKey={activeKey} onChange={onKeyChange} 
            items={["Private Sale Info", "Add Addition Info", "Finish"].map((e, i) => {
            const id = String(i + 1); 
            return {
                label: ( 
                <div className='tab-header'>
                    {
                        activeKey <= id  ? 
                        <div className='tab-header-circle'>
                            <span className='tab-header-num'>{id}</span>
                        </div> :
                        <img src="../images/check.png"/>
                    } 
                    <span className='tab-header-text'>{e}</span>
                </div>
                ),
                key: id,
                disabled: activeKey < id && true,
                children:  
                    e === "Private Sale Info" ? <PrivateSaleInfo setActiveKey={setActiveKey}/> :
                    e === "Add Addition Info" ? <AdditionInfo setActiveKey={setActiveKey}/> :
                    e === "Finish" ? <Finish setActiveKey={setActiveKey}/> : null  
               ,
            };
            })}
        /> 
    </div> 
   )
}
 
export default PrivateSale;