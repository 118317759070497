import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SearchChange } from "../../components/component";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getTokenInformMyToken, tokenInformsSelector } from "../../redux/slicers/tokenInformSlice";
import { useActiveWeb3React } from "../../hooks";
import useWindowSize from "../../hooks/useWindowSize";
import { Tokens } from "../../constants";
import ItemTable from "../../components/tokenLpTable/itemTable";
import { Pagination } from "antd";
import type { PaginationProps } from 'antd';

interface TokenObject {
    antiBotEnable?: boolean;
    chainId?: number;
    decimals?: number;
    id?: number;
    launchpadHandle?: string;
    launchpadName?: string;
    lockStatus?: boolean;
    logoURL?: string;
    name?: string;
    networkChain?: string;
    ownerAddress?: string;
    symbol?: string;
    tokenAddress?: string;
    tokenType?: string;
    totalSupply?: number;
    type?: any;
    vestingteamId?: number;
}
interface Size {
    width: number;
    height: number;
}

const MyToken: React.FC = () => {
    const [isPager, setIsPager] = useState<any>(1);
    const { account } = useActiveWeb3React();
    const dispatch = useAppDispatch();
    const size: Size = useWindowSize();
    const { tokenInformsMyToken, tokenInformsMyTokenCount } = useAppSelector(tokenInformsSelector); 
    const tokenAddress = localStorage.getItem(Tokens.AUTHUSER);
  
    useEffect(() => {
        const payload:any = {
            "page": 0,
            "size": 10,
            "sort": ''
        }
        if(account) {
            dispatch(getTokenInformMyToken(payload));
        }
        
    }, [dispatch, account, tokenAddress]);

    useEffect(() => {
        const payload:any = {
            "page": isPager-1,
            "size": 10,
            "sort": '',
        }
        dispatch(getTokenInformMyToken(payload));
    }, [dispatch, isPager]);

    const onChangePager: PaginationProps['onChange'] = (pageNumber) => {
        setIsPager(pageNumber);
    };

    return(
        <div className="p-myToken">
            <div className="p-myToken__head">
                <h3>My Tokens</h3>
                <Link to={'/token/create-token'}>Create Token</Link>
            </div>
            <div className="p-myToken__box">
                {tokenInformsMyToken && tokenInformsMyToken.length > 0 ? <>
                    <SearchChange placeholder='Search token address' />
                    <div className="c-tableToken">
                        <div className="c-tableToken__head">
                            {size.width >= 767 ? 
                            <>
                                <p>Token Name</p>
                                <p>Token Address</p>
                                <p>Anti-bot</p>
                                <p>Network</p>
                                <p>Total Supply</p>
                                <p>Total Token Lock</p>
                                <p>Type</p>
                                <p className="is-ellipsis">Launchpad</p>
                            </> : <>
                                <p>Name</p>
                            </>}                            
                        </div>
                        {tokenInformsMyToken && tokenInformsMyToken.length > 0 && tokenInformsMyToken.map((item:any, key: number) => (
                            <ItemTable data={item} key={key} type='token' />
                        ))}
                    </div>
                </> : <div className="c-nodata">
                    <img src="../images/img-nodata.svg" alt="No data"/>
                    <p>
                        My Tokens
                        <span>This is where you will manage your tokens that you created on the BlueSale</span>
                    </p>
                </div>}
                {Number(tokenInformsMyTokenCount) > 0 ? <Pagination
                    className="c-pagingEdit"
                    defaultCurrent={1}
                    total={Number(tokenInformsMyTokenCount)}
                    pageSize={10}
                    current={isPager}
                    onChange={onChangePager}
                /> : ''}
            </div>
        </div>
    )
}

export default MyToken;