// import Header from './components/common/header';
import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'antd/dist/antd.css';
import './components/assets/scss/style.scss';
import Logo from './components/logo';
import SideBar from './components/sidebar';
import PageComponent from './components/pageComponent';
import Headers from './components/header'
// import Home from './page/home';
import LaunchpadList from './page/launchpad/launchpadList';
import CreateLaunchpad from './page/createLaunchpad';
import CreateFairLaunch from './page/createLaunchpad/fairLaunch';
import LaunchpadDetail from './page/launchpadDetail';
import CreateLock from './page/createLock';
import CreateToken from './components/createtoken';
import useWindowSize from './hooks/useWindowSize';
import VestingTeam from './page/launchpadDetail/vestingTeam';
import MyToken from './page/token';
import MyLaunchpad from './page/launchpad/myLaunchpad';
import LaunchPadEdit from './page/launchpadDetail/edit';
import FormLock from './components/lockToken/formLock';
import { useAppDispatch } from './redux/hooks';
import { getAppSettings } from './redux/slicers/settingSlice';
import CreateLP from './page/createLP';
import MyLiquidity from './page/liquidity';
import LockRecords from './page/token/lockRecords';
import AntiBot from './page/antiBot';
import AntiBotDetail from './page/antiBot/detail';
import AntiBotEdit from './page/antiBot/edit';
import FormLP from './components/liquidity/formLP';
import BlsDividends from './page/bls/dividends';
import BlsDashboard from './page/bls/dashboard';
import MultiSender from './page/multiSender';
import AirdropList from './page/airdrop/AirdropList/AirdropList';

import PrivateSale from './page/privatesale';
import PrivateSaleList from './page/privatesale/list';
import MyPrivateSale from './page/privatesale/myPrivate';
import PublicSale from './page/public-sale';
import PrivateSaleDetail from './page/privatesale/privateSaleDetail';
import HomePage from './page/homeNew';
import AOS from "aos";
import HeaderBanner from './components/headerBanner';
import ComingSoonPage from './page/comingSoon';
import TermsOfService from './page/term-policy/terms-of-service';
import PrivacyPolicy from './page/term-policy/privacy-policy';
import { useActiveWeb3React } from './hooks';
import networkChange from './utils/networkChange';
import FairlaunchDetail from './page/fairlaunchDetail';
import AirdropCreate from './page/airdrop/AirdropCreate';
import PrivateSaleEdit from './page/privatesale/edit';
import AirdropDetail from './page/airdrop/AirdropDetail';
import AirdropUpdate from './page/airdrop/AirdropUpdate';
import DividendsV1 from './page/blsv2/dividends';
import DashboardV1 from './page/blsv2/dashboard';

interface Size {
  width: number;
  height: number;
}


function App() {
  useEffect(() => {
    AOS.init({
      offset: 200, // offset (in px) from the original trigger point
      delay: 500, // values from 0 to 3000, with step 50ms
      duration: 1000 // values from 0 to 3000, with step 50ms
    });
  }, []);
  // const { pathname } = useLocation();
  const [isToggle, setIsToggle] = useState<boolean>(false);
  const [isHeaderBanner, setIsHeaderBanner] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { account, chainId } = useActiveWeb3React();
  useEffect(() => {
    const payload: any = {
      "page": 0,
      "size": 100
    }
    dispatch(getAppSettings(payload));
  }, []);

  const toggleChange = (toggleChange: boolean) => {
    setIsToggle(toggleChange);
  }

  const setToggle = (setToggle: boolean) => {
    setIsToggle(setToggle);
  }

  const size: Size = useWindowSize();
  const nodeEvn = process.env.REACT_APP_NODE_ENV;

  useEffect(() => {
    if (nodeEvn === 'production') {
      if (chainId && account && chainId !== 42161) {
        networkChange("0xa4b1");
      }
    }
  }, [chainId, account]);

  return (
    <>
      {window.location.pathname === "/sale" || window.location.pathname === "/terms-of-service" || window.location.pathname === "/privacy-policy" ? (
        <Router>
          <ReactNotifications />
          <Routes>
            <Route path='/sale' element={<PublicSale />} />
            <Route path='/terms-of-service' element={<TermsOfService />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          </Routes>

        </Router>
      ) : (
        <Router>
          <ReactNotifications />
          <div className={`l-main ${isToggle ? 'is-open' : ''}`}>

            {window.location.pathname === "/" && isHeaderBanner ? <div className={`is-isBanner ${isToggle ? 'is-open' : ''}`}>
              <HeaderBanner setIsHeaderBanner={setIsHeaderBanner} />
              {size.width > 991 && <Headers />}
              <Logo toggleChange={toggleChange} toggleClose={isToggle} />
              <SideBar toggle={isToggle} setToggle={setToggle} />
            </div> : <>
              {size.width > 991 && <Headers />}
              <Logo toggleChange={toggleChange} toggleClose={isToggle} />
              <SideBar toggle={isToggle} setToggle={setToggle} />
            </>}
            <Routes>
              <Route path='/' element={<HomePage isBanner={isHeaderBanner} />} />
              {nodeEvn === 'development' ? <>
                <Route path='/launchpad/list' element={<LaunchpadList />} />
                <Route path='/launchpad/my-launchpad' element={<MyLaunchpad />} />
                <Route path='/launchpad/:handle' element={<LaunchpadDetail />} />
                <Route path='/launchpad-edit/:handle' element={<LaunchPadEdit />} />
                <Route path='/launchpad/create-launchpad' element={<CreateLaunchpad />} />
                <Route path='/launchpad/create-fairlaunch' element={<CreateFairLaunch />} />
                <Route path='/fairlaunch/:handle' element={<FairlaunchDetail />} />
                <Route path='/record/:handle' element={<VestingTeam />} />
                <Route path='/token/create-token' element={<CreateToken type={null} setCreateToken={null} setActiveKey={null} />} />
                <Route path='/token/create-lock' element={<CreateLock />} />
                <Route path='/token/create-lock/:handle' element={<FormLock setActiveKey={null} />} />
                <Route path='/token/my-token' element={<MyToken />} />
                <Route path='/token/:handle' element={<FormLock setActiveKey={null} />} />
                <Route path='/lock-records/:handle' element={<LockRecords />} />
                <Route path='/liquidity' element={<MyLiquidity />} />
                <Route path='/anti-bot' element={<AntiBot />} />
                <Route path='/anti-bot/:handle' element={<AntiBotDetail />} />
                <Route path='/anti-bot/edit' element={<AntiBotEdit />} />
                <Route path='/liquidity/create-liquidity' element={<CreateLP />} />
                <Route path='/liquidity/create-liquidity/:handle' element={<FormLP setActiveKey={null} />} />
                <Route path='/bls/dividends' element={<BlsDividends />} />
                <Route path='/bls/dividends-v1' element={<DividendsV1 />} />
                <Route path='/bls/dashboard' element={<BlsDashboard />} />
                <Route path='/bls/dashboard-v1' element={<DashboardV1 />} />
                <Route path='/multi-sender' element={<MultiSender />} />
                <Route path='/airdrop/list' element={<AirdropList />} />
                <Route path='/airdrop/create' element={<AirdropCreate />} />
                <Route path='/airdrop/detail/:handle' element={<AirdropDetail />} />
                <Route path='/airdrop/update/:handle' element={<AirdropUpdate />} />
                <Route path='/component' element={<PageComponent />} />
                <Route path='/private-sale/create' element={<PrivateSale />} />
                <Route path='/private-sale/list' element={<PrivateSaleList />} />
                <Route path='/private-sale/:handle' element={<PrivateSaleDetail />} />
                <Route path='/private-sale-edit/:handle' element={<PrivateSaleEdit />} />
                <Route path='/private-sale/my-private' element={<MyPrivateSale />} />
                <Route path='/leaderboard' element={<ComingSoonPage />} />
              </> : <>
                <Route path='/launchpad/list' element={<LaunchpadList />} />
                <Route path='/launchpad/my-launchpad' element={<MyLaunchpad />} />
                <Route path='/launchpad/:handle' element={<LaunchpadDetail />} />
                <Route path='/launchpad-edit/:handle' element={<LaunchPadEdit />} />
                <Route path='/launchpad/create-launchpad' element={<CreateLaunchpad />} />
                <Route path='/launchpad/create-fairlaunch' element={<CreateFairLaunch />} />
                <Route path='/fairlaunch/:handle' element={<FairlaunchDetail />} />
                <Route path='/record/:handle' element={<VestingTeam />} />
                <Route path='/token/create-token' element={<CreateToken type={null} setCreateToken={null} setActiveKey={null} />} />
                <Route path='/token/create-lock' element={<CreateLock />} />
                <Route path='/token/create-lock/:handle' element={<FormLock setActiveKey={null} />} />
                <Route path='/token/my-token' element={<MyToken />} />
                <Route path='/token/:handle' element={<FormLock setActiveKey={null} />} />
                <Route path='/lock-records/:handle' element={<LockRecords />} />
                <Route path='/liquidity' element={<MyLiquidity />} />
                <Route path='/anti-bot' element={<AntiBot />} />
                <Route path='/anti-bot/:handle' element={<AntiBotDetail />} />
                <Route path='/anti-bot/edit' element={<AntiBotEdit />} />
                <Route path='/liquidity/create-liquidity' element={<CreateLP />} />
                <Route path='/liquidity/create-liquidity/:handle' element={<FormLP setActiveKey={null} />} />
                <Route path='/bls/dividends' element={<BlsDividends />} />
                <Route path='/bls/dividends-v1' element={<DividendsV1 />} />
                <Route path='/bls/dashboard' element={<BlsDashboard />} />
                <Route path='/bls/dashboard-v1' element={<DashboardV1 />} />
                <Route path='/multi-sender' element={<MultiSender />} />
                <Route path='/airdrop/list' element={<AirdropList />} />
                <Route path='/airdrop/create' element={<AirdropCreate />} />
                <Route path='/airdrop/detail/:handle' element={<AirdropDetail />} />
                <Route path='/airdrop/update/:handle' element={<AirdropUpdate />} />
                <Route path='/component' element={<ComingSoonPage />} />
                <Route path='/private-sale/create' element={<PrivateSale />} />
                <Route path='/private-sale/list' element={<PrivateSaleList />} />
                <Route path='/private-sale/:handle' element={<PrivateSaleDetail />} />
                <Route path='/private-sale-edit/:handle' element={<PrivateSaleEdit />} />
                <Route path='/private-sale/my-private' element={<MyPrivateSale />} />
                <Route path='/leaderboard' element={<ComingSoonPage />} />
              </>}
            </Routes>
          </div>
        </Router>
      )}

    </>

  );
}

export default App;
