import { Contract } from "ethers";
import { useContract } from "../hooks/useContract";
import { getContract } from "../utils";
import { getDexName } from "./factoryAddress";
import { convertFromWei } from "./utils";

declare const window: Window & typeof globalThis & { ethereum: any };


export const token0Address = async (contract:Contract | any) => {
    return await contract.token0();
}

export const token1Address = async (contract:Contract | any) => {
    return await contract.token1();
}

export const getName = async (contract:Contract | any) => {
    return await contract.name();
}

export const getSymbol = async (contract:Contract | any) => {
    return await contract.symbol();
}

export const getBalanceOf = async (contract:Contract | any, account: any) => {
    return await contract.balanceOf(account);
}

export const tokenDecimals = async (contract:Contract | any) => {
    return await contract.decimals();
}

export const getFactory = async (contract:Contract | any) => {
    return await contract.factory();
}

// export const tokenTotalSupply = async (contract:Contract | any) => {
//     const _decimals = await tokenDecimals(contract);
//     return _totalSupply = await contract.totalSupply();
//     // return parseFloat(convertFromWei(_totalSupply, _decimals));
// }
export const getLiquidityInformation = async (PancakePairABI:any, lpAdress:any, library:any, account:any) => {
    if (typeof window.ethereum !== 'undefined') {
        const pancakePairContract = getContract(lpAdress, PancakePairABI, library, account);

        const name:any = await getName(pancakePairContract);
        
        const token0 = await token0Address(pancakePairContract);
        const token1 = await token1Address(pancakePairContract);

        const token0Contract = getContract(token0, PancakePairABI, library, account);
        const token0Name:any = await getSymbol(token0Contract);
        const lpToken0Name:any = await getName(token0Contract);
        const token1Contract = getContract(token1, PancakePairABI, library, account);
        const token1Name:any = await getSymbol(token1Contract);
        const lpToken1Name:any = await getName(token1Contract);
        const decimals:any = await tokenDecimals(pancakePairContract);

        const tokenAmount:any = await getBalanceOf(pancakePairContract, account);
        const factory:any = await getFactory(pancakePairContract);
        console.log("🚀 ~ file: pancakePair.ts:62 ~ getLiquidityInformation ~ factory:", factory)
        console.log("🚀 ~ file: pancakePair.ts:62 ~ getLiquidityInformation ~ factory:", getDexName(factory))

        return {
            "lpName": name,
            "lpTokenname": lpToken0Name+'/'+lpToken1Name,
            "lpAddress": lpAdress,
            "pair": token0Name+'/'+token1Name,
            "dex": getDexName(factory),
            "balance": convertFromWei(Number(tokenAmount), decimals),
            "decimals": decimals,
            "linkWebScan": 'https://testnet.bscscan.com/token/'+lpAdress,
            "linkLiquidity": null
        };
    }
}