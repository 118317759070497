import React, { useEffect, useRef, useState } from "react";
import { Store } from "react-notifications-component";
import addNotify from "../../components/commons/Notify/addNotify";
import { Button, SearchChange } from "../../components/component";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ImgCopy from '../../components/assets/images/icon-copy.svg';
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { antibotInfoSelector, getAntiBotById } from "../../redux/slicers/antibotSlice";
import { antibotStorageSelector } from "../../redux/slicers/antibotStorageSlice";
import { getTokenInform, tokenInformsSelector } from '../../redux/slicers/tokenInformSlice';
import { formatNumber } from "../../utils/formatNumber";
import { getAntiBotInfos, getAntiBotStatusInfo, getBlackList, getTotalBlackList, getTotalWhiteList, getWhiteList, setBlacklistUsers, setEnableAntiBot, setWhitelistUsers } from "../../contracts/antibot";
import { useAntiBotContract } from "../../hooks/useContract";
import { SMART_CONTRACT_ADDRESS } from "../../constants";
import { convertFromWei } from "../../contracts/utils";
import { useActiveWeb3React } from "../../hooks";
import { formatNum } from "../bls/utils";

const AntiBotDetail: React.FC = () => {
    const {chainId, account} = useActiveWeb3React();
    const [addBlockList, setAddBlockList] = useState<boolean>(false);
    const [removeBlockList, setRemoveBlockList] = useState<boolean>(false);
    const [listBlockList, setListBlockList] = useState<boolean>(false);
    const [addWhitelist, setAddWhitelist] = useState<boolean>(false);
    const [removeWhitelist, setRemoveWhitelist] = useState<boolean>(false);
    const [listWhitelist, setListWhitelist] = useState<boolean>(false);
    const [antibotStatus, setAntibotStatus] = useState<boolean>(false);
    const [amountLimit, setAmountLimit] = useState<any>(0);
    const [timeLimit, setTimeLimit] = useState<any>(0);
    const [leftBlocks, setLeftBlocks] = useState<any>(0);
    const [currentBlock, setCurrentBlock] = useState<any>(0);
    const [addressWhitelists, setAddressWhitelists] = useState<any>([]);
    const [addressBlacklists, setAddressBlacklists] = useState<any>([]);
    const [totalWhitelist, setTotalWhitelist] = useState<any>(0);
    const [totalBlacklist, setTotalBlacklist] = useState<any>(0);
    const [actionStatus, setActionStatus] = useState("");
    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { handle } = useParams<{ handle: any }>(); 
    const { antiBotById } = useAppSelector(antibotInfoSelector); 
    const { tokenInform } = useAppSelector(tokenInformsSelector);
    const antibotAddress = chainId && SMART_CONTRACT_ADDRESS[chainId]?.ANTI_BOT_ADDRESS;
  
    const antibotContract = useAntiBotContract(antibotAddress);
    const [addListOfWhiteListUsers, setAddListOfWhiteListUsers] = useState<any>([]);
    const [removeListOfWhiteListUsers, setRemoveListOfWhiteListUsers] = useState<any>([]); 
    const [addListOfBlackListUsers, setAddListOfBlackListUsers] = useState<any>([]);
    const [removeListOfBlackListUsers, setRemoveListOfBlackListUsers] = useState<any>([]); 

    useEffect(() => {
        if(handle) {       
            dispatch(getAntiBotById(handle));
        }
    }, [dispatch, handle]);
    
    useEffect(() => {        
        if(antiBotById?.tokenInformId) {
            dispatch(getTokenInform(antiBotById?.tokenInformId));
        }
    }, [dispatch, antiBotById?.tokenInformId]);
    
    useEffect(() => {        
        if(antibotContract && tokenInform?.tokenAddress) {
            (async () => {
                await getAntiBotStatusInfo(antibotContract, tokenInform)
                .then((res:any) => { 
                    console.log(res);
                    setAmountLimit(res?.amountLimit);
                    setTimeLimit(res?.timeLimit)
                    setAntibotStatus(res?.enabled);
                    setLeftBlocks(res?.leftBlocks);
                    setCurrentBlock(res?.currentBlock);
                });

                await getWhiteList(antibotContract, tokenInform?.tokenAddress)
                .then((res:any) => {
                    setAddressWhitelists(res);
                });

                await getBlackList(antibotContract, tokenInform?.tokenAddress)
                .then((res:any) => {
                    setAddressBlacklists(res);
                });

                await getTotalWhiteList(antibotContract, tokenInform?.tokenAddress)
                .then((res:any) => {
                    setTotalWhitelist(Number(res));
                });

                await getTotalBlackList(antibotContract, tokenInform?.tokenAddress)
                .then((res:any) => { 
                    setTotalBlacklist(Number(res));
                });
            })();
        }
    }, [antibotContract, tokenInform, addWhitelist, removeWhitelist, addBlockList, removeBlockList]); 
    
    const _listOfWhiteListUsers = addressWhitelists && addressWhitelists.length>0 && addressWhitelists.map((item:any) => {
        return <p>{item}</p>
    });
    const _listOfBlacklistUsers = addressBlacklists && addressBlacklists.length>0 && addressBlacklists.map((item:any) => {
        return <p>{item}</p>
    });

    const handleCopy = () => {
        Store.addNotification(addNotify('Copied !', 'success'));
    }
    const handleNetwork = (network:any) => {
        let networkName:any = '';
        if(network === 'BSCTESTNET') {
            networkName = <span className="is-network"><img src="../images/icon-bnb.svg" alt="" />BSC TESTNET</span>;
        } else if(network === 'BSC') {
            networkName = <span className="is-network"><img src="../images/icon-bnb.svg" alt="" />Binance Smart Chain</span>;
        } else if(network === 'ETH') {
            networkName = <span className="is-network"><img src="../images/icon-eth.svg" alt="" />Ethereum</span>;
        } else if(network === 'POLY') {
            networkName = <span className="is-network"><img src="../images/icon-polygon.svg" alt="" />Polygon</span>;
        }
        else if(network === 'ARB') {
             networkName = <span className="is-network"><img src="../images/arb-icon.svg" alt="" />Arbitrum</span>;
        }
        return networkName;
    }

    const handleChangeAddWhitelists = (e:any) => {
        setAddListOfWhiteListUsers(e.target.value);
    }

    const handleChangeRemoveWhitelists = (e:any) => {
        setRemoveListOfWhiteListUsers(e.target.value);
    }

    const handleChangeAddBlacklists = (e:any) => {
        setAddListOfBlackListUsers(e.target.value);
    }

    const handleChangeRemoveBlacklists = (e:any) => {
        setRemoveListOfBlackListUsers(e.target.value);
    }

    const handleAddUsersToBlacklist = async () => {

        const _addUsers = addListOfBlackListUsers.split(/\r?\n/);
        
        setActionStatus("ADD_BLACKLIST_LOADING");
        await setBlacklistUsers(antibotContract, tokenInform?.tokenAddress, true, _addUsers)
        .then((res:any)=> {
            let resWait = res.wait();
            resWait.then((resTransaction:any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    setAddBlockList(false)
                    Store.addNotification(addNotify('Add users to blacklist successfully', 'success'));
                    setActionStatus(""); 
                }
                else {
                    setActionStatus("");
                    Store.addNotification(addNotify('Transaction failed', 'danger'));
                    return;
                }
            })
            .catch ((error:any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            });
           
        })
        .catch((error:any)=>{
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, 'danger'));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, 'danger'));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, 'danger'));
                }
            }
        });
    }

    const handleRemoveUsersToBlacklist = async () => {

        const _removeUsers = removeListOfBlackListUsers.split(/\r?\n/);
        
        setActionStatus("REMOVE_BLACKLIST_LOADING");
        await setBlacklistUsers(antibotContract, tokenInform?.tokenAddress, false, _removeUsers)
        .then((res:any)=> {
            let resWait = res.wait();
            resWait.then((resTransaction:any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    setRemoveBlockList(false)
                    Store.addNotification(addNotify('Remove users from blacklist successfully', 'success'));
                    setActionStatus(""); 
                }
                else {
                    setActionStatus("");
                    Store.addNotification(addNotify('Transaction failed', 'danger'));
                    return;
                }
            })
            .catch ((error:any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            });
        })
        .catch((error:any)=>{
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, 'danger'));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, 'danger'));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, 'danger'));
                }
            }
        });
    }

    const handleAddUsersToWhitelist = async () => {

        const _addUsers = addListOfWhiteListUsers.split(/\r?\n/);
        
        setActionStatus("ADD_WHITELIST_LOADING");
        await setWhitelistUsers(antibotContract, tokenInform?.tokenAddress, true, _addUsers)
        .then((res:any)=> {
            let resWait = res.wait();
            resWait.then((resTransaction:any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    setAddWhitelist(false)
                    Store.addNotification(addNotify('Add users to whitelist successfully', 'success'));
                    setActionStatus(""); 
                }
                else {
                   
                    setActionStatus("");
                    Store.addNotification(addNotify('Transaction failed', 'danger'));
                    return;
                }
            })
            .catch ((error:any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }); 
           
        })
        .catch((error:any)=>{
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, 'danger'));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, 'danger'));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, 'danger'));
                }
            }
        });
    }

    const handleRemoveUsersToWhitelist = async () => {

        const _removeUsers = removeListOfWhiteListUsers.split(/\r?\n/);
        
        setActionStatus("REMOVE_WHITELIST_LOADING");
        await setWhitelistUsers(antibotContract, tokenInform?.tokenAddress, false, _removeUsers)
        .then((res:any)=> {
            let resWait = res.wait();
            resWait.then((resTransaction:any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    setRemoveWhitelist(false)
                    Store.addNotification(addNotify('Remove users from whitelist successfully', 'success'));
                    setActionStatus(""); 
                }
                else {
                    setActionStatus("");
                    Store.addNotification(addNotify('Transaction failed', 'danger'));
                    return;
                }
            })
            .catch ((error:any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            });
        })
        .catch((error:any)=>{
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, 'danger'));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, 'danger'));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, 'danger'));
                }
            }
        });
    }

    const handleEnableAntibot = async (isEnable:boolean) => {
    console.log("🚀 ~ file: detail.tsx:319 ~ handleEnableAntibot ~ isEnable", isEnable, tokenInform?.tokenAddress)

        setActionStatus(isEnable ? "ENABLE_ANTIBOT_LOADING" : "DISABLE_ANTIBOT_LOADING");
        await setEnableAntiBot(antibotContract, tokenInform?.tokenAddress, isEnable)
        .then((res:any)=> {
            let resWait = res.wait();
            resWait.then((resTransaction:any) => {
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    if(isEnable) {
                        Store.addNotification(addNotify('Enable Gold antibot successfully', 'success'));
                    }
                    else {
                        Store.addNotification(addNotify('Disable Gold antibot successfully', 'success'));
                    }
                    setActionStatus("");
                    window.location.reload();
                }
                else {
                    setActionStatus("");
                    Store.addNotification(addNotify('Transaction failed', 'danger'));
                    return;
                }
            })
            .catch ((error:any) => {
                setActionStatus("");
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            });
        })
        .catch((error:any)=>{
            setActionStatus("");
            if (error.code == 4001 && error.message) {
                Store.addNotification(addNotify(error.message, 'danger'));
            } else if (error.reason) {
                Store.addNotification(addNotify(error.reason, 'danger'));
            } else {
                if (error.data && error.data.message) {
                    Store.addNotification(addNotify(error.data.message, 'danger'));
                }
            }
        });
    }
    // console.log("tokenInform",tokenInform);
    return(
        <>
        <div className="p-antiBotDetail">
            <div className="p-antiBotDetail__head">
                <p>
                    You haven’t actived Gold Anti-Bot. Clicking on “Enable Gold Anti-Bot” to activate the Gold Anti-Bot.<br/>
                    You must do this before adding liquidity, otherwise the Gold Anti-Bot will not work
                </p>
                <Button text='Disable Gold Anti-Bot' type='greyBorder' 
                    isLoading={actionStatus === "DISABLE_ANTIBOT_LOADING" ? true : false}
                    disabled={actionStatus === "DISABLE_ANTIBOT_LOADING" || !antibotStatus ? true : false}
                    onClick={() => handleEnableAntibot(false)}
                />
                <Button text='Enable Gold Anti-Bot' type='blue' 
                    isLoading={actionStatus === "ENABLE_ANTIBOT_LOADING" ? true : false}
                    disabled={actionStatus === "ENABLE_ANTIBOT_LOADING" || antibotStatus ? true : false}
                    onClick={() => handleEnableAntibot(true)}
                />
            </div>
            <div className="p-antiBotDetail__body">
                <div className="p-antiBotDetail__left">
                    <div className="p-antiBotDetail__box">
                        <h3>Blacklist</h3>
                        <p>You can add/remove any users to the blacklist. Once added to this list, they will not be able to trade your tokens</p>
                        <Button type='blue' text='Add User' 
                            onClick={() => setAddBlockList(true)} 
                        />
                        <Button type='blue' text='Remove User' onClick={() => setRemoveBlockList(true)} />
                        <Button text={`List of Blacklist (${totalBlacklist})`} onClick={() => setListBlockList(true)} />
                    </div>
                    <div className="p-antiBotDetail__box">
                        <h3>Whitelist</h3>
                        <p>You can add/remove any users to the whitelist</p>
                        <Button type='blue' text='Add User' onClick={() => setAddWhitelist(true)} />
                        <Button type='blue' text='Remove User' onClick={() => setRemoveWhitelist(true)} />
                        <Button text={`List of Whitelist (${totalWhitelist})`} onClick={() => setListWhitelist(true)} />
                    </div>
                </div>
                <div className="p-antiBotDetail__right">
                    <div className="p-antiBotDetail__info">
                        <h3>Token Infor</h3>
                        <ul>
                            <li>
                                <img src="../../../images/icon-address.svg" alt="" />
                                Address:
                                <span className="c-copy">
                                    {tokenInform?.tokenAddress?.substring(0,4) + "..." + tokenInform?.tokenAddress?.substr(tokenInform?.tokenAddress?.length - 4)}
                                    <CopyToClipboard text={tokenInform?.tokenAddress} onCopy={() => handleCopy()}>
                                        <button><img src={ImgCopy} alt="" /></button>
                                    </CopyToClipboard>
                                </span>
                            </li>
                            <li>
                                <img src="../../../images/icon-name.svg" alt="" />
                                Name:<span>{tokenInform?.name}</span>
                            </li>
                            <li>
                                <img src="../../../images/icon-sympol.svg" alt="" />
                                Symbol:<span>{tokenInform?.symbol}</span>
                            </li>
                            <li>
                                <img src="../../../images/icon-lockAmount.svg" alt="" />
                                Network:{handleNetwork(tokenInform?.networkChain)}
                            </li>
                            <li>
                                <img src="../../../images/icon-decimal.svg" alt="" />
                                Decimal:<span>{tokenInform?.decimals}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="p-antiBotDetail__info">
                        <h3>
                            Antibot Status
                            <Button text='Edit' iconLeft='Edit' onClick={() => navigate(`/anti-bot/edit?id=${antiBotById?.id}&token=${tokenInform?.tokenAddress}`)} />
                        </h3>
                        <div className="p-antiBotDetail__table">
                            <p>
                                Protect Status
                                <span>{antibotStatus === true ? "Waiting liquidity" : "Not active"}</span>
                            </p>
                            <p>
                                Amount Limit
                                <span>{ amountLimit ? formatNum(amountLimit) : 'N/A'}</span>
                            </p>
                            <p>
                                Time Limit
                                <span>{ timeLimit ? formatNum(timeLimit) +" "+"seconds" : 'N/A'}</span>
                            </p>
                            <p>
                                Blocks Left To Disable
                                <span>N/A</span>
                            </p>
                            <p>
                                Current Block
                                <span>{currentBlock ? currentBlock : 'N/A'}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {addBlockList && <div className="c-popup">
            <div className="c-popup__content is-small">
                <h3 className="c-popup__title">Add Users To Blacklist</h3>
                <div className="c-popup__box">
                    <div className="c-input">
                        <p className="c-input__title">Enter user wallet address list</p>
                        <textarea placeholder='0x' onChange={(e) => handleChangeAddBlacklists(e)}></textarea>
                    </div>                    
                </div>
                <div className="c-popup__btn">
                    <Button text='Cancel' type='greyBorder' onClick={() => setAddBlockList(false)}/>
                    <Button text='Add Address' type='blue' 
                        isLoading={actionStatus === "ADD_BLACKLIST_LOADING" ? true : false}
                        disabled={actionStatus === "ADD_BLACKLIST_LOADING" ? true : false}
                        onClick={() => handleAddUsersToBlacklist()}
                    />
                </div>
            </div>
        </div>}

        {removeBlockList && <div className="c-popup">
            <div className="c-popup__content is-small">
                <h3 className="c-popup__title">Remove Users To Blacklist</h3>
                <div className="c-popup__box">
                    <div className="c-input">
                        <p className="c-input__title">Enter user wallet address list</p>
                        <textarea placeholder='0x' onChange={(e) => handleChangeRemoveBlacklists(e)}></textarea>
                    </div>                    
                </div>
                <div className="c-popup__btn">
                    <Button text='Cancel' type='greyBorder' onClick={() => setRemoveBlockList(false)}/>
                    <Button text='Remove Address' type='blue' 
                        isLoading={actionStatus === "REMOVE_BLACKLIST_LOADING" ? true : false}
                        disabled={actionStatus === "REMOVE_BLACKLIST_LOADING" ? true : false}
                        onClick={() => handleRemoveUsersToBlacklist()}
                    />
                </div>
            </div>
        </div>}

        {listBlockList && <div className="c-popup">
            <div className="c-popup__content is-btnAbsolute">
                <h3 className="c-popup__title">List Of Blacklist</h3>
                <SearchChange placeholder='Search wallet address'/>
                <p className="c-ttl">Wallet address</p>
                <div className="c-popup__whitelist">
                    {_listOfBlacklistUsers}
                </div>
                <div className="c-popup__btn is-full">
                    <Button text='Cancel' type='greyBorder' onClick={() => setListBlockList(false)}/>
                </div>
            </div>
        </div>}

        {addWhitelist && <div className="c-popup">
            <div className="c-popup__content is-small">
                <h3 className="c-popup__title">Add Users To Whitelist</h3>
                <div className="c-popup__box">
                    <div className="c-input">
                        <p className="c-input__title">Enter user wallet address list</p>
                        <textarea placeholder='0x' onChange={(e) => handleChangeAddWhitelists(e)}></textarea>
                    </div>                    
                </div>
                <div className="c-popup__btn">
                    <Button text='Cancel' type='greyBorder' onClick={() => setAddWhitelist(false)}/>
                    <Button text='Add Address' type='blue' 
                        isLoading={actionStatus === "ADD_WHITELIST_LOADING" ? true : false}
                        disabled={actionStatus === "ADD_WHITELIST_LOADING" ? true : false}
                        onClick={() => handleAddUsersToWhitelist()}
                    />
                </div>
            </div>
        </div>}

        {removeWhitelist && <div className="c-popup">
            <div className="c-popup__content is-small">
                <h3 className="c-popup__title">Remove Users To Whitelist</h3>
                <div className="c-popup__box">
                    <div className="c-input">
                        <p className="c-input__title">Enter user wallet address list</p>
                        <textarea placeholder='0x' onChange={(e) => handleChangeRemoveWhitelists(e)}></textarea>
                    </div>                    
                </div>
                <div className="c-popup__btn">
                    <Button text='Cancel' type='greyBorder' onClick={() => setRemoveWhitelist(false)}/>
                    <Button text='Remove Address' type='blue' 
                        isLoading={actionStatus === "REMOVE_WHITELIST_LOADING" ? true : false}
                        disabled={actionStatus === "REMOVE_WHITELIST_LOADING" ? true : false}
                        onClick={() => handleRemoveUsersToWhitelist()}
                    />
                </div>
            </div>
        </div>}

        {listWhitelist && <div className="c-popup">
            <div className="c-popup__content is-btnAbsolute">
                <h3 className="c-popup__title">List Of Whitelist</h3>
                <SearchChange placeholder='Search wallet address'/>
                <p className="c-ttl">Wallet address</p>
                <div className="c-popup__whitelist">
                    {_listOfWhiteListUsers}
                </div>
                <div className="c-popup__btn is-full">
                    <Button text='Cancel' type='greyBorder' onClick={() => setListWhitelist(false)}/>
                </div>
            </div>
        </div>}
        </>
    )
}

export default AntiBotDetail;