import Token from '../../components/createlaunchpad/token';
import { Tabs } from 'antd';
import FormAntibot from '../../components/antibot/formAntibot';
import { useState, useEffect } from 'react';
import TokenAntiBot from '../../components/antibot';
import { useAppSelector } from "../../redux/hooks";
import { antibotStorageSelector } from '../../redux/slicers/antibotStorageSlice';

const AntiBot = () => {
    const [activeKey, setActiveKey] = useState<string>('1'); 
    const onKeyChange : any = (key) =>
    {
        setActiveKey(key); 
        localStorage.setItem("stepAntibot", key);  
    }  
    
    useEffect(() => {
        setActiveKey(localStorage.getItem("stepAntibot")||"1");  
    }, [localStorage.getItem("stepAntibot")]);
    
    return (
        <div className='c-createLaunchpad'>
            <Tabs
                defaultActiveKey="1" activeKey={activeKey} onChange={onKeyChange}
                items={["Token", "Anti-Bot config"].map((e, i) => {
                    const id = String(i + 1);
                    return {
                        label: (
                            <div className='tab-header'>
                                <div className='tab-header-circle'>
                                    <span className='tab-header-num'>{id}</span>
                                </div>
                                <span className='tab-header-text'>{e}</span>
                            </div>
                        ),
                        key: id,
                        disabled: activeKey < id && true,
                        children:
                            e === "Token" ? <TokenAntiBot setActiveKey={setActiveKey} /> :
                            e === "Anti-Bot config" ? <FormAntibot setActiveKey={setActiveKey} /> : null
                    };
                })}
            />
        </div>
    )
}

export default AntiBot;