import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import TokenInformService from "../services/TokenInformService";

// ================================================
// ==                  ACTION                    ==
// ================================================ 


// ================================================
// ==                REDUX STORE                 ==
// ================================================

type TokenObject = {
  [key: string]: void | string | number | any;
};

export interface TokenInformState { 
    tokenLP: TokenObject;  
    loading: boolean;
    errors: string;
}

const initialState: TokenInformState = { 
    tokenLP: {}, 
    loading: false,
    errors: "",
}

const tokenInformSlice = createSlice({
  name: "tokenInforms",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },  
    
    setTokenLP: (state, { payload }: PayloadAction<TokenObject>) => {
      state.tokenLP = payload
    },  
    
  },
})

export const { setLoading, setErrors, setTokenLP } = tokenInformSlice.actions

export default tokenInformSlice.reducer;

export const tokenLPSelector = (state: { tokenLPStore: TokenInformState }) => state.tokenLPStore; 
 