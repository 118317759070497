import { PayloadAction } from "@reduxjs/toolkit";
import http from "./http-common";

const AntiBotService = {
    postAntiBot(payload: PayloadAction) {
        return http.post(`/anti-bots/create`, payload);
    }, 

    getAntiBotById (id:number) {
        return http.get(`/anti-bots/${id}`);
    },

    editAntiBotById (id:number, payload:PayloadAction) {
        return http.put(`/anti-bots/${id}`, payload);
    },

    checkAntiBotByAdress(address) {
        return http.get(`/token-informs/check-antibot/${address}`);
    },

    checkConfigAntiBotByAdress(address) {
        return http.get(`/token-informs/check-antibot-exist/${address}`);
    }, 
};
export default AntiBotService;