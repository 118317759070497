import { createSlice, PayloadAction } from "@reduxjs/toolkit"; 
import LaunchPadService from "../services/LaunchPadService";

// ================================================
// ==                  ACTION                    ==
// ================================================
 

export const createFairlaunch = (payload:PayloadAction, onCallback:Function) => {
  return async dispatch => {
      dispatch(setLoading(true)); 
      await LaunchPadService.createLauchpad(payload)
      .then((res:any)=>{
        console.log(res);
        onCallback({result: "SUCCESS", data: res?.data});
      })
      .catch((err:Error)=>{
        console.log(err);
        onCallback({result: "ERROR", error: err});
      });
  }
}

export const verifyToken = (payload:any) => {
  return async dispatch => {
      dispatch(setLoading(true)); 
      dispatch(setVerifyTokenStorage(payload));  
  }
}

export const fairlaunchInfo = (payload:any) => {
  return async dispatch => {
      dispatch(setLoading(true)); 
      dispatch(setFairlaunchInfo(payload));  
  }
}

export const additionInfo = (payload:any) => {
  return async dispatch => {
      dispatch(setLoading(true)); 
      dispatch(setAdditionInfo(payload));  
  }
} 


// ================================================
// ==                REDUX STORE                 ==
// ================================================

type StoreObject = {
  [key: string]: void | string | number | Date | any;
};

export interface createFairlaunchState { 
    createFairlaunch:StoreObject;
    tokenStorage: StoreObject;
    verifyTokenStorage: StoreObject;
    fairlaunchInfoStorage: StoreObject;
    additionInfoStorage: StoreObject; 
    loading: boolean;
    errors: string;
}

const initialState: createFairlaunchState = { 
    createFairlaunch:{},
    tokenStorage: {},
    verifyTokenStorage: {},
    fairlaunchInfoStorage: {},
    additionInfoStorage: {}, 
    loading: false,
    errors: "",
}

const createFairlaunchSlice = createSlice({
  name: "createFairlaunch",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setCreateFairlaunch: (state, { payload }: PayloadAction<StoreObject>) => {
      state.createFairlaunch = payload
    },

    setVerifyTokenStorage: (state, { payload }: PayloadAction<StoreObject>) => {
      state.verifyTokenStorage = payload
    },

    setAdditionInfo: (state, { payload }: PayloadAction<StoreObject>) => {
      state.additionInfoStorage = payload
    }, 
    
    setFairlaunchInfo: (state, { payload }: PayloadAction<any>) => {
      state.fairlaunchInfoStorage = payload
    },
  },
})

export const { 
  setLoading, 
  setVerifyTokenStorage, 
  setFairlaunchInfo, 
  setAdditionInfo, 
  setCreateFairlaunch 
} = createFairlaunchSlice.actions

export default createFairlaunchSlice.reducer

export const createFairlaunchSelector = (state: { createFairlaunchStore: createFairlaunchState }) => state.createFairlaunchStore; 
 