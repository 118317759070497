import React, { useCallback, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Countdown, { zeroPad } from 'react-countdown';
import { useActiveWeb3React } from '../../../hooks';
import { useAppDispatch } from '../../../redux/store';
import { Col } from 'antd';
import { now } from 'moment';

interface Size {
    width: number;
    height: number;
}

type ItemProps = {
    item?: any | undefined;
    display?: boolean;
}

const ItemAirdrop: React.FC<ItemProps> = (props: any) => {
    const { account, chainId, library } = useActiveWeb3React();
    const { item } = props;

    let nowDay:any = new Date();
    let startJoin: any = new Date(item?.startJoin);
    let endJoin: any = new Date(item?.endJoin);
    
    const dispatch = useAppDispatch(); 
    
    const rendererTimer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            window.location.reload();
            return;
        }    
        return <div className="c-countDown"><span>{days}d</span>:<span>{hours}h</span>:<span>{minutes}m</span>:<span>{seconds}s</span></div>;
        
    };
    const getProgressTime = useCallback((startTime:any) => {
        const now = new Date();
        const utcDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), 0);
        const startDate = new Date(startTime);
        const startTS = Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            startDate.getHours(),
            startDate.getMinutes(),
            startDate.getSeconds(),
            0
        );
        let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600);
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60);

        if (days >= 1) {
            return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        } else {
            return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        }
    }, []);
    const handleClassStatus = () => {
        let classStatus:string = '';

        if(nowDay < startJoin) {
            classStatus= 'airdrop-upcoming';
        } else if(startJoin < nowDay && nowDay < endJoin) {
            classStatus= 'airdrop-opening';
        } else if(endJoin < nowDay) {
            classStatus= 'airdrop-completed';
        }

        if(!item?.activated){
            classStatus= 'airdrop-ended';
        }

        return classStatus;
    }
    const TimerContent = () => {
        let timeContent:any = '';

        if(nowDay < startJoin) {
            timeContent = (
                <>
                    <span className='text-normal-gray'>Upcoming:</span>
                    <span className='text-time-countdown'>
                        <Countdown
                            date={getProgressTime(startJoin)}
                            renderer={rendererTimer}
                        />
                    </span>
                </>
            )
        } else if(startJoin < nowDay && nowDay < endJoin) {
            timeContent= (
                <>
                    <span className='text-normal-gray'>Ending:</span>
                    <span className='text-time-countdown'>
                        <Countdown
                            date={getProgressTime(endJoin)}
                            renderer={rendererTimer}
                        />
                    </span>
                </>
            );
        } else if(endJoin < nowDay) {
            timeContent= (
                <>
                    <span className='text-normal-gray'>Ended:</span>
                    <span className='text-time-countdown'>--</span>
                </>
            );
        }

        return timeContent;
    }
    
    return(
        <> 
            <Col className="gutter-row" xs={24} sm={8} xl={8} md={8}>
                <div className={`airdrop-item ${handleClassStatus()}`}>
                    <div className='airdrop-status'>
                        <a href={`/airdrop/detail/${item.handle}`} className='icon-status-airdrop'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16" className="bi bi-fullscreen-exit">
                                <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"/>
                            </svg>
                        </a>
                    </div>
                    <div className='airdrop-name'>
                        <a href={`/airdrop/detail/${item.handle}`}>{item?.airdropName}</a>
                    </div>
                    <div className='airdrop-text'>
                        <span className='text-normal'>Reward</span>
                        <span className='text-info'>{item?.reward.toLocaleString()} {item?.airdropTokenInform?.symbol}</span>
                    </div>
                    <div className='airdrop-img'>
                        <img src={item?.bannerUrl} />
                    </div>
                    <div className='airdrop-text'>
                        <span className='text-normal-gray'>Network:</span>
                        <span className='text-gray-blue'>Arbitrum </span>
                    </div>
                   
                    <div className='airdrop-text'>
                        
                        {item?.activated ? (
                            <TimerContent />
                        ):(
                            <>
                                <span className='text-normal-gray'>Canceled:</span>
                                <span className='text-time-countdown'>--</span>
                            </>
                        )}
                    </div>
                    <div className='airdrop-button'>
                        <button className='btn-airdrop' type='button' onClick={(event) => (window.location.href = `/airdrop/detail/${item.handle}`)}>
                            Detail
                        </button>
                    </div>
                </div>
            </Col>
        </> 
    )}

export default ItemAirdrop;