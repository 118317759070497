import React, { createContext, useState, useContext, ReactNode } from 'react';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import './style.css';
// import { AuthApi } from 'config/api/authApi';
import {useActiveWeb3React} from '../hooks';

export type IProps = {
	showNoti: Function;
	onReloadBalance: Function;
	tokenID: string;
	routerWrap: string;
	reloadBalance: boolean;
};

const WrapperContext = createContext<IProps>({
	showNoti: () => { },
	onReloadBalance: Function,
	tokenID: '',
	routerWrap: '',
	reloadBalance: false
});

interface Props {
    children?: ReactNode
    // any props that come into the component
}

export const WrapperProvider = ({ children, ...props }: Props) => {
	const { account } = useActiveWeb3React();
	const [tokenID, setTokenID] = useState('');
	const [reloadBalance, setReloadBalance] = useState(false);
	// const history = useNavigate();
	// console.log("🚀 ~ file: WrapperContext.tsx ~ line 36 ~ WrapperProvider ~ history", history)
	// const routerWrap = history.location.pathname;

	// const getAuthHeader = async () => {
	// 	try {
	// 		let res = await AuthApi.getAuth({ address: account });
	// 		if (res.status == 200) {
	// 			setTokenID(res.data.id_token);
	// 		}
	// 	} catch (error) { }
	// };

	// useEffect(() => {
	// 	if (account) {
	// 		getAuthHeader();
	// 	}
	// }, [account]);

	// --- Show Notification ---
	const showNoti = (type: string, content: string) => {
		// const nodeNoti = () => {
		// 	return (
		// 		<div className={`noti-box`}>
		// 			<div className="noti-box__content">
		// 				<span className="icon">
		// 					{type == 'danger' ? <WarningOutlined /> : type == 'success' && <CheckCircleOutlined />}
		// 				</span>
		// 				<span className="text">{content}</span>
		// 			</div>
		// 		</div>
		// 	);
		// };

	// 	switch (type) {
	// 		case 'success':
	// 			toast.success(content);
	// 			break;
	// 		case 'error':
	// 			toast.error(content);
	// 			break;
	// 		case 'warning':
	// 			toast.warning(content);
	// 			break;
	// 		default:
	// 			break;
	// 	}
	};

	const onReloadBalance = () => {
		setReloadBalance(true);
		setTimeout(() => {
			setReloadBalance(false);
		}, 1000);
	};

	return (
		<>
			<WrapperContext.Provider
				value={{
					showNoti,
					tokenID: tokenID,
					routerWrap: '/',
					reloadBalance: reloadBalance,
					onReloadBalance: onReloadBalance
				}}
			>
				{/* <ToastContainer
					position="top-right"
					autoClose={3000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/> */}

				{children}
			</WrapperContext.Provider>
		</>
	);
};

export const useWrap = () => useContext(WrapperContext);
