import { DownOutlined, LeftOutlined } from '@ant-design/icons';
import { Col, Input, message, Row, Spin, Tabs } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { ethers } from "ethers";
import tokenAbi from "../../../../../contracts/abi/tokenAbi.json";
import JoinPublicSale from "../../../../../contracts/abi/JoinPublicSale.json";
import useActiveWeb3React from '../../../../../hooks/useActiveWeb3React';
import { useContract } from "../../../../../hooks/useContract";
import web3 from 'web3';
import {
  getTokenAllowanceNoHook,
  tokenApprove
} from "../../../../../contracts/token";

import {
  _tokenBalance,
  buyTokenSale,
  _getSymbolToken,
  _isJoined,
  _totalJoined,
  _userJoined,
} from "../../../public-sale-utils";

import { useTokenContract } from "../../../../../hooks/useContract";
import { Store } from 'react-notifications-component';
import addNotify from '../../../../../components/commons/Notify/addNotify';
import BluesaleRoundsService from '../../../../../redux/services/bluesaleRoundsService';
import { createBrowserHistory } from 'history';

declare const window: Window & typeof globalThis & { ethereum: any };

const BuyBlock = (props: any) => { 
    const {roundDetail, authToken, tokenDecimal} = props;
   
    const history = createBrowserHistory();
    
    const { account } = useActiveWeb3React(); 
    const [approved, setTokenApprove] = useState<any>(0);
    const [amountBuying, setAmountBuying] = useState(0);
    const [isLoading, setIsLoading] = useState<any>(false);
    const [joined, setJoined] = useState<any>(false);
    const [tokenBalance, setTokenBalance] = useState<any>(0);
    const [userJoined, setUserJoined] = useState<any>(0);

    let tokenJoinAddess:any = roundDetail?.tokenJoinAddress;
    const tokenContract = useTokenContract(tokenJoinAddess); 

    let startSale:any = new Date(roundDetail?.startTime);
    let endSale:any = new Date(roundDetail?.endTime);
    let now = new Date();

    const joinPublicSaleContract:any = useContract(roundDetail?.roundAddress, JoinPublicSale);
    
    const getTokenInfo = async (tokenJoinAddess: any, roundAddress: any) => {
        let allowanceSC = await getTokenAllowanceNoHook(
            tokenAbi,
            tokenJoinAddess,
            account,
            roundAddress
        );
        setTokenApprove(parseFloat(ethers.utils.formatUnits(allowanceSC, tokenDecimal)));
    };
    const getTokenBalance = async (tokenContract: any, account: any) => {
        let tokenBalance = await _tokenBalance(tokenContract, account);
        if(tokenBalance && tokenDecimal > 0){
            let balance:any = ethers.utils.formatUnits(tokenBalance, tokenDecimal);
            setTokenBalance(parseFloat(balance));
        }
    };

    const checkIsJoined = async () => {
        if(joinPublicSaleContract && roundDetail?.id){
            let isJoined = await _isJoined(joinPublicSaleContract, account, roundDetail?.id);
            setJoined(isJoined);
        }
    };
    const getUserJoined = async () => {
        if(joinPublicSaleContract && tokenDecimal > 0){
            let total = await _userJoined(joinPublicSaleContract, account, roundDetail?.id);
            if(total){
                // let totalWei = web3.utils.fromWei(total.toString(), 'ether');
                
                let totalWei = ethers.utils.formatUnits(total, tokenDecimal);
                setUserJoined(totalWei);
            }
        }
    };

    
    
    useEffect(() => {
        if (tokenJoinAddess && roundDetail?.roundAddress && account && tokenDecimal > 0) {
            getTokenInfo(tokenJoinAddess, roundDetail?.roundAddress);
        }
    }, [account, tokenJoinAddess, roundDetail?.roundAddress, tokenDecimal]);
    
    useEffect(() => {
        if (tokenContract && account && tokenDecimal > 0) {
            getTokenBalance(tokenContract, account);
        }
    }, [account, tokenContract, tokenDecimal]);

    useEffect(() => {
        if (account && joinPublicSaleContract) {
            checkIsJoined();
        }
    }, [account, joinPublicSaleContract]);

    useEffect(() => {
        if (account && joinPublicSaleContract && tokenDecimal > 0) {
            getUserJoined();
        }
    }, [account, joinPublicSaleContract, tokenDecimal]);

    const handleApproveToken = async () => {
        try {
            setIsLoading(true);
            await tokenApprove(tokenContract, roundDetail?.roundAddress)
                .then((res) => {
                    let resWait:any = res.wait();
                    resWait.then((resTransaction:any) => {
                        setIsLoading(false);
                        if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                            Store.addNotification(addNotify('Approved successfully', 'success'));
                            getTokenInfo(tokenJoinAddess, roundDetail?.roundAddress);
                        } else {
                            Store.addNotification(addNotify('Approved failed', 'danger'));
                        }
                    })
                    .catch((error:any) => {
                        setIsLoading(false);
                        if(error.data){
                            Store.addNotification(addNotify(error.data.message, 'warning'));
                        }            
                    });
                })
                .catch((error) => {
                    setIsLoading(false);
                    if (error) {
                        if (error.code === 4001 && error.message) {
                            Store.addNotification(addNotify(error.message, 'danger'));
                        } else if (error.reason) {
                            Store.addNotification(addNotify(error.reason, 'danger'));
                        } else {
                            if (error.data && error.data.message) {
                                Store.addNotification(addNotify(error.data.message, 'danger'));
                            }
                        }
                    }
            });
        } catch (error: any) {
            setIsLoading(false);
            console.log(error);
        }
    };
    const onBuyToken = async (roundId: any, launchpadId:any, whitelistId:any) => {
        setIsLoading(true);
        try {
            if(amountBuying <= 0 || amountBuying > tokenBalance){
                Store.addNotification(addNotify('Invalid amount!', 'danger'));
                return;
            }
            let signer:any = await BluesaleRoundsService.getSignBuyToken(whitelistId, roundId, authToken);
            if(!signer){
                Store.addNotification(addNotify('Wrong signature!', 'danger'));
                return;
            }
            if (!account || isLoading) {
                Store.addNotification(addNotify('Wrong account!', 'danger'));
                return;
            }
            
            await buyTokenSale(joinPublicSaleContract, Number(amountBuying).toFixed(1), signer.data.whiteListId, roundId, signer.data.sign, tokenDecimal)
            .then((res) => {
                let resWait:any = res.wait();
                resWait.then((resTransaction:any) => {
                    
                    if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                        const payloadJoin = {
                          whiteListId: signer.data.whiteListId,
                          roundId: roundId,
                          amount: parseFloat(Number(amountBuying).toFixed(1)),
                          txn: resTransaction?.transactionHash,
                        };
                        BluesaleRoundsService.updateHashBuyToken(payloadJoin, authToken).then((rs) => {
                            Store.addNotification(addNotify('Buy successfully', 'success'));
                            if (account && joinPublicSaleContract) {
                                checkIsJoined();
                            }
                            let tokenExit:any = tokenBalance - amountBuying;
                            setTokenBalance(tokenExit);
                            setAmountBuying(0);
                            getUserJoined();
                            
                            setIsLoading(false);
                            setTimeout(() => {
                                window.location.reload();
                            }, 3000);
                        }).catch((error)=>{
                            Store.addNotification(addNotify(error.message, 'danger'));
                        });

                        setIsLoading(false);
                    } else {
                        Store.addNotification(addNotify('Buy failed', 'danger'));
                    }
                    setIsLoading(false);
                })
                .catch((error:any) => {
                    setIsLoading(false);
                    if(error.data){
                        Store.addNotification(addNotify(error.data.message, 'warning'));
                    }            
                });
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
                if (error) {
                    if (error.code === 4001 && error.message) {
                        Store.addNotification(addNotify(error.message, 'danger'));
                    } else if (error.reason) {
                        Store.addNotification(addNotify(error.reason, 'danger'));
                    } else {
                        if (error.data && error.data.message) {
                            Store.addNotification(addNotify(error.data.message, 'danger'));
                        }
                    }
                }
            
            });
        } catch (error: any) {
            setIsLoading(false);
            console.log(error);
            Store.addNotification(addNotify(error.message, 'danger'));
        }
    };
    const changeAmountBuying = (e)=>{
        setAmountBuying(e.target.value > 0 ? Number(e.target.value) : 0)
    }
    if(userJoined > 0){
        return(
            <Row className='joined'>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className='round-name join-text'>
                        Your joined
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className='round-remain text-right'>
                        <span className='purple-text currency-join'>{userJoined.toLocaleString()} {roundDetail?.currencyPay}</span>
                    </div>
                </Col>
            </Row>
        )
    }

    if(roundDetail?.whiteListOnly && roundDetail?.whitelist === null){
        return(
            <Row className='joined'>
                <Col xs={24}>
                    <div className='round-name join-text text-danger text-center'>
                        <div className='mt-10'>
                            <img src='../images/icon-soldout.svg' />
                        </div>
                        <div className='mt-10'>
                            You're not whitelist
                        </div>
                    </div>
                </Col>
                
            </Row>
        )
    }
   
    return (  
        <>
            <div className='dividends-buy-block'>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='round-name'>
                            You Pay <small className='purple-text'>(Min: {roundDetail?.minBuy} - Max: {roundDetail?.maxBuy})</small>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='round-remain text-right'>
                            Balance: <span className='purple-text font-medium'>{tokenBalance.toLocaleString()}</span>
                        </div>
                    </Col>
                </Row>
                <Row className='flex '>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='font-medium font-18'><img src='../images/usdc-icon-32.svg'/> {roundDetail?.currencyPay}</div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}> 
                        <Input placeholder='0.0' type="number" 
                            onChange={changeAmountBuying} 
                            status={amountBuying > 0 && amountBuying < roundDetail?.minBuy && amountBuying > roundDetail?.maxBuy ? 'error' : ''}
                            value={amountBuying === 0 ? '' : amountBuying} className='font-medium font-30 text-right pay-input'></Input>
                    </Col>
                </Row>
            </div>
            <div className='arrow-bottom'>
                <img src='../images/arrow-bottom.svg'/>
            </div>
            <div className='dividends-buy-block'>
                <Row>
                    <Col xs={24}>
                        <div className='round-name'>You Receive</div>
                    </Col>
                </Row>
                <Row className='mt-10'>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='font-medium font-18'><img width={32} height={32} src='../images/bls-icon-50.svg'/> BLS</div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='font-medium font-18 text-right'>{(amountBuying * (roundDetail?.blsPercent / 100)/roundDetail?.price).toFixed(1)}</div>
                    </Col>
                </Row>
                <Row className='mt-15'>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='font-medium font-18'><img width={32} height={32} src='../images/xbls-icon-50.svg'/> xBLS</div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className='font-medium font-18 text-right'>{(amountBuying * ((100 - roundDetail?.blsPercent) / 100)/roundDetail?.price).toFixed(1)}</div>
                    </Col>
                </Row>
                {userJoined > 0 && (
                    <Row className='mt-15'>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className='round-name'>
                                Your joined
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className='round-remain text-right'>
                                <span className='purple-text font-medium'>{userJoined.toLocaleString()} {roundDetail?.currencyPay}</span>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
            <div className='dividends-buy-button mt-15'>
                {joined ? (
                    <>
                        <button className='btn-main' disabled>Joined</button>
                    </>
                ):(
                    <>
                        {startSale > now && (
                            <button className='btn-main' disabled>Coming soon</button>
                        )}
                        {startSale < now && endSale < now && (
                            <button className='btn-main text-danger' disabled>Ended</button>
                        )}
                        {startSale < now && endSale > now && (
                            <>
                                {roundDetail?.whiteListOnly ? (
                                    roundDetail?.whitelist ? (
                                        approved > 0 && amountBuying <= approved ? (
                                            <>
                                                <button className='btn-main' 
                                                    disabled={isLoading || amountBuying < roundDetail?.minBuy || amountBuying > roundDetail?.maxBuy || amountBuying > tokenBalance} 
                                                    onClick={() => onBuyToken(roundDetail?.id, roundDetail?.launchpadId, roundDetail?.whitelist?.id)}>
                                                    Buy
                                                    {isLoading && (
                                                        <Spin className="style-loading" size="small" />
                                                    )}
                                                </button>   
                                            </>
                                        ):(
                                            <button className='btn-main' disabled={isLoading} onClick={handleApproveToken}>
                                                Approve {roundDetail?.currencyPay}
                                                {isLoading && (
                                                    <Spin className="style-loading" size="small" />
                                                )}
                                            </button>
                                        )
                                    ):(
                                        <button className='btn-main' disabled>You're not whitelist</button>
                                    )
                                ):(
                                    approved > 0 && amountBuying <= approved ? (
                                        <>
                                            <button className='btn-main' 
                                                disabled={isLoading || amountBuying < roundDetail?.minBuy ||  amountBuying > roundDetail?.maxBuy || amountBuying > tokenBalance} 
                                                onClick={() => onBuyToken(roundDetail?.id, roundDetail?.launchpadId, 0)}>
                                                Buy
                                                {isLoading && (
                                                    <Spin className="style-loading" size="small" />
                                                )}
                                            </button>
                                        </>
                                    ):(
                                        <button className='btn-main' disabled={isLoading} onClick={handleApproveToken}>
                                            Approve {roundDetail?.currencyPay}
                                            {isLoading && (
                                                <Spin className="style-loading" size="small" />
                                            )}
                                        </button>
                                    )
                                )}
                            </>
                        )}
                        
                    </>
                )}
                
            </div>
        </>
   )
}
 
export default BuyBlock;