import { useState, useEffect } from "react";  
import {Form, Checkbox, DatePicker} from 'antd';    
import { InputChange } from "../component";
import { Button as ButtonComp } from '../component';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useGSLockContract, usePancakePairContract } from "../../hooks/useContract";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { tokenInformsSelector, getTokenInformByAddress, createTokenLPInform} from "../../redux/slicers/tokenInformSlice"; 
import { setTokenLP, tokenLPSelector } from "../../redux/slicers/tokenLPSlice";
import { tokenApprove, getTokenAllowance } from "../../contracts/token";
import { LAUNCHPAD_CONFIGS, RPC_URL_CONVERT, SMART_CONTRACT_ADDRESS, TESTNET_BSC_URL } from "../../constants";
import { useActiveWeb3React } from "../../hooks";
import addNotify from "../commons/Notify/addNotify";
import { Store } from 'react-notifications-component';
import { Loading } from "../component";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import { cumulativeLockInfo, getFeeLock } from "../../contracts/goldSaleLock"; 
import { convertFromWei } from "../../contracts/utils"; 
import { createVestingTeam } from "../../redux/slicers/vestingTeamSlice";
import { getLiquidityInformation } from "../../contracts/pancakePair";
import PancakePairABI from '../../contracts/abi/PancakePairABI.json';
import { createLock, createVestingLock } from "../../contracts/goldSaleLock";
import { convertToWei } from "../../contracts/utils";
import web3 from "web3";
import { formatNumberAmount } from "../../utils/formatNumber";


interface IFormCreate{
    setActiveKey: any, 
  } 
declare const window: Window & typeof globalThis & { ethereum: any };

const FormLP : React.FC<IFormCreate> =(props: IFormCreate) =>{  
    const [form] = Form.useForm();  
    const { handle } = useParams<{ handle: any }>();   
    const [isNoti, setIsNoti] = useState<boolean>(true); 
    const [loadingPage, setLoadingPage] = useState<boolean>(false); 
    const [valueAmount, setValueAmount] = useState<number>(0);
    const [tokenSC, setTokenSC] = useState<any>();
    const [actionStatus, setActionStatus] = useState("");  
    const [isOwner, setIsOwner] = useState<boolean>(); 
    const { tokenInformsByAddress } = useAppSelector(tokenInformsSelector);  
    const { tokenLP } = useAppSelector(tokenLPSelector);  
    const pancakePairContract = usePancakePairContract(tokenLP && tokenLP?.lpTokenAddress);  
    const { account, library, chainId } = useActiveWeb3React(); 
    const lockAddress = chainId && SMART_CONTRACT_ADDRESS[chainId]?.GS_LOCK_ADDRESS;
    const [isUseVesting, setIsUseVesting] = useState<boolean>(false);
    const gsLockContract = useGSLockContract(lockAddress);  
     
    let navigate = useNavigate();
    const dispatch = useAppDispatch(); 
    
    let provider = ""; 
    if(chainId !== 1 && chainId){
        provider = RPC_URL_CONVERT[chainId];
    }
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    useEffect(() => {  
        dispatch(getTokenInformByAddress(tokenLP?.lpTokenAddress, (res:any)=>{})); 
    }, [tokenLP]); 

    useEffect(() => {
        if (handle) {
            dispatch(setTokenLP({
                "lpTokenAddress": handle
            }));
        }
    }, [handle]);
  
    useEffect(() => {
        form.setFieldsValue({
            tgeDate: moment(new Date()).utc(),
        })
    }, [isUseVesting]);

    const getLockId = (res:any) => {
        if (res.logs && res.logs.length > 0) {
            console.log("getLockId logs", res.logs);
            let log:any;
            for (let i = 0; i < res.logs.length; i++) {
                if (res.logs[i].topics && res.logs[i].topics[0].toLowerCase() === '0x694af1cc8727cdd0afbdd53d9b87b69248bd490224e9dd090e788546506e076f') {
                    log = res.logs[i];
                }
            }
            if (log) {
                const data = log.data;
                const topic = log.topics;
                const decodedData = w3.eth.abi.decodeLog([
                    {
                        "indexed": true,
                        "internalType": "uint256",
                        "name": "id",
                        "type": "uint256"
                    },
                    {
                        "indexed": false,
                        "internalType": "address",
                        "name": "token",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "address",
                        "name": "owner",
                        "type": "address"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "amount",
                        "type": "uint256"
                    },
                    {
                        "indexed": false,
                        "internalType": "uint256",
                        "name": "unlockDate",
                        "type": "uint256"
                    }
                ], data, topic.slice(1, 5));

                return decodedData.id || 0;
            }
        }
    }  

    useEffect(() => {
        form.setFieldsValue({ 
            tgeDate: moment(new Date()).utc(),
            amount: 0,
            lockUntilDate: moment(new Date()).utc()
        })
    }, []);

    const onFinish = async (values: any) => {  
        if (tokenSC?.balance < Number(values.amount)){
            Store.addNotification(addNotify("Insufficient balance", 'danger'));
            return;
        }
        let fees  = await getFeeLock(gsLockContract)
        
        values.tokenInformId = Number(tokenInformsByAddress?.id);
        values.title = values.title ? values.title : null;
        values.useAnotherOwner = isOwner ? isOwner : false;
        values.anotherOwner = values.anotherOwner ? values.anotherOwner : null;
        values.useVesting = isUseVesting; 
        values.amount = values.amount ? Number(values.amount) : 0;
        values.cliffVesting = 0;
        values.lockAfterCliffVesting = 0;  
        
        // useVesting = true
        values.tgeDate = isUseVesting ? values.tgeDate && values.tgeDate._d : values.lockUntilDate && values.lockUntilDate._d;
        values.tgePercent = isUseVesting ? Number(values.tgePercent) : 100;
        values.vestingCycle = values.vestingCycle ? Number(values.vestingCycle) : 0;
        values.cycleReleasePercent = values.cycleReleasePercent ? Number(values.cycleReleasePercent) : 0;
        const _useOwnerAddress:any = values.useAnotherOwner && values.anotherOwner ? values.anotherOwner : account;

        //call SC  
        if (isUseVesting) {  
            await createVestingLock(
                gsLockContract,
                _useOwnerAddress,
                tokenSC?.lpAddress,
                true , //LP token
                convertToWei(values.amount, tokenSC?.decimals),
                Math.floor((new Date(values.tgeDate).getTime()) / 1000),
                values.tgePercent*100,
                values.vestingCycle*60,
                values.cycleReleasePercent*100,
                values.title,
                {value : Number(fees) }
            )
            .then((res:any) => {
                setActionStatus("LOADINGLOCK");
                let resWait:any = res.wait();
                resWait.then((resTransaction:any) => { 
                    if (resTransaction && resTransaction.status && resTransaction.blockNumber) { 
                        const lockId = getLockId(resTransaction);
                        values.lockId = lockId; 
                         //check & call api
                        dispatch(getTokenInformByAddress(tokenLP?.lpTokenAddress, (res:any) => { 
                            if(res?.tokenInform && res?.tokenInform?.id) {  
                                dispatch(createVestingTeam(values, async (res:any) => { 
                                    if(res.result === "SUCCESS") { 
                                        if(res?.data) {  
                                            setActionStatus("APPROVED");
                                            localStorage.setItem("stepLP", "1");
                                            navigate('/liquidity');
                                            Store.addNotification(addNotify("Lock liquidity created successfully.", 'success'));
                                        }
                                    }
                                    else {  
                                        if (res?.error?.message) {
                                            setActionStatus("APPROVED");
                                            setLoadingPage(false)
                                            Store.addNotification(addNotify(res?.error?.response?.data?.title, 'danger'));
                                        }
                                    } 
                                }));   
                            }   
                            else { 
                                const tokenPayload :any= {
                                    "ownerAddress": _useOwnerAddress, 
                                    "lpTokenName": tokenSC?.lpTokenname,
                                    "pair": tokenSC?.pair,
                                    "lpTokenAddress": tokenLP?.lpTokenAddress,
                                    "lockAmount": 0,
                                    "type": "Available",
                                    "linkLiquidity":  tokenSC?.linkLiquidity,
                                    "linkWebScan": tokenSC?.linkWebScan,
                                    "decimals": tokenSC?.decimals,
                                    "totalSupply": tokenSC?.balance,
                                    "dex": tokenSC?.dex,   
                                    "vestingTeamTitle": values?.title,
                                    "vestingTeamUseAnotherOwner": values?.useAnotherOwner,
                                    "vestingTeamAnotherOwner": values?.anotherOwner,
                                    "vestingTeamAmount": values?.amount,
                                    "cliffVesting": 0,
                                    "lockAfterCliffVesting": 0,
                                    "useVesting": values?.useVesting,
                                    "tgeDate": values?.tgeDate,
                                    "tgePercent":values?.tgePercent,
                                    "vestingCycle": values?.vestingCycle,
                                    "cycleReleasePercent": values?.cycleReleasePercent,
                                    "lockId": values?.lockId
                                  }   
                                dispatch(createTokenLPInform(tokenPayload, async (res:any) =>{
                                    if(res.result === "SUCCESS") {
                                        if(res?.data) {   
                                            setActionStatus("APPROVED");
                                            localStorage.setItem("stepLP", "1");
                                            navigate('/liquidity');
                                            Store.addNotification(addNotify("Lock liquidity created successfully.", 'success'));
                                        }
                                    }
                                    else {  
                                        if (res?.error?.message) {
                                            setActionStatus("APPROVED");
                                            Store.addNotification(addNotify(res?.error?.message, 'danger'));
                                        }
                                    }  
                                    setActionStatus(""); 
                                })); 
                                }
                            }));
                    }
                }).catch((error:any) => { 
                    setActionStatus("APPROVED");
                    if (error.data) {
                        Store.addNotification(addNotify('Transaction error', 'danger'));
                        return;
                    }
                    if (error) {
                        Store.addNotification(addNotify(error?.reason, 'danger'));
                        return;  
                    } 
                });
            })
            .catch((error:any) => { 
                setActionStatus("APPROVED");
                if (error.data) {
                    Store.addNotification(addNotify('Transaction error', 'danger'));
                    return;
                }
                if (error) {
                    Store.addNotification(addNotify(error?.reason, 'danger'));
                    return;  
                }
            });
        } else {
            await createLock(
                gsLockContract,
                _useOwnerAddress,
                tokenSC?.lpAddress,
                true , //LP token
                convertToWei(values.amount, tokenSC?.decimals),
                Math.floor((new Date(values.tgeDate).getTime()) / 1000),
                values.title,
                {value : Number(fees) }
            )
            .then((res:any) => {
                setActionStatus("LOADINGLOCK");
                let resWait:any = res.wait();
                resWait.then((resTransaction:any) => { 
                    if (resTransaction && resTransaction.status && resTransaction.blockNumber) { 
                        console.log("createLock resTransaction ", resTransaction);
                        const lockId = getLockId(resTransaction);
                        values.lockId = lockId; 
                        //check & call api
                        dispatch(getTokenInformByAddress(tokenLP?.lpTokenAddress, (res:any) => { 
                            if(res?.tokenInform && res?.tokenInform?.id) {  
                                dispatch(createVestingTeam(values, async (res:any) => { 
                                    if(res.result === "SUCCESS") { 
                                        if(res?.data) { 
                                            setActionStatus("APPROVED"); 
                                            localStorage.setItem("stepLP", "1");
                                            navigate('/liquidity');
                                            Store.addNotification(addNotify("Lock liquidity created successfully.", 'success'));
                                        }
                                    }
                                    else {  
                                        if (res?.error?.message) {
                                            setLoadingPage(false);
                                            setActionStatus("APPROVED"); 
                                            Store.addNotification(addNotify(res?.error?.response?.data?.title, 'danger'));
                                        }
                                    }
                                }));   
                            }   
                            else { 
                                const tokenPayload :any= {
                                    "ownerAddress": _useOwnerAddress, 
                                    "lpTokenName": tokenSC?.lpTokenname,
                                    "pair": tokenSC?.pair,
                                    "lpTokenAddress": tokenLP?.lpTokenAddress,
                                    "lockAmount": 0,
                                    "type": "Available",
                                    "linkLiquidity":  tokenSC?.linkLiquidity,
                                    "linkWebScan": tokenSC?.linkWebScan,
                                    "decimals": tokenSC?.decimals,
                                    "totalSupply": tokenSC?.balance,
                                    "dex": tokenSC?.dex,   
                                    "vestingTeamTitle": values?.title,
                                    "vestingTeamUseAnotherOwner": values?.useAnotherOwner,
                                    "vestingTeamAnotherOwner": values?.anotherOwner,
                                    "vestingTeamAmount": values?.amount,
                                    "cliffVesting": 0,
                                    "lockAfterCliffVesting": 0,
                                    "useVesting": values?.useVesting,
                                    "tgeDate": values?.tgeDate,
                                    "tgePercent":values?.tgePercent,
                                    "vestingCycle": values?.vestingCycle,
                                    "cycleReleasePercent": values?.cycleReleasePercent,
                                    "lockId": values?.lockId 
                                  }   
                                dispatch(createTokenLPInform(tokenPayload, async (res:any) =>{
                                    if(res.result === "SUCCESS") {
                                        if(res?.data) {
                                            setActionStatus("APPROVED");
                                            localStorage.setItem("stepLP", "1");
                                            navigate('/liquidity');
                                            Store.addNotification(addNotify("Lock liquidity created successfully.", 'success'));
                                        }
                                    }
                                    else {  
                                        if (res?.error?.message) {
                                            setActionStatus("APPROVED");
                                            Store.addNotification(addNotify(res?.error?.message, 'danger'));
                                        }
                                    }   
                                })); 
                                }
                            }));
                    }
                })
                .catch((error:any) => { 
                    setActionStatus("APPROVED"); 
                    if (error.data) {
                        Store.addNotification(addNotify('Transaction error', 'danger'));
                        return;
                    }
                    if (error) {
                        Store.addNotification(addNotify(error?.reason, 'danger'));
                        return;  
                    }
                });
            })
            .catch((error:any) => {
                setActionStatus("APPROVED"); 
                if (error.data) {
                    Store.addNotification(addNotify('Transaction error', 'danger'));
                    return;
                }
                if (error) {
                    Store.addNotification(addNotify(error?.reason, 'danger'));
                    return;  
                }
            });
        }   
    };
    
    useEffect(() => {
        (async() => { 
                setLoadingPage(true);
                await getLiquidityInformation(PancakePairABI, tokenLP?.lpTokenAddress, library, account)
                .then((res:any) => { 
                    setTokenSC(res);  
                    (async() => {
                        await getTokenAllowance(pancakePairContract, account, lockAddress)
                            .then((res:any)=>{  
                                console.log("res", res) 
                                if(res > 0) {
                                    setActionStatus("APPROVED");
                                }
                                else{
                                    setActionStatus("");
                                }
                            });
                    })()   
                    form.setFieldsValue(res); 
                    setLoadingPage(false);  
                })
                .catch((err:any) => {   
                    form.resetFields();
                    setLoadingPage(false)
                })
            })()
    }, [pancakePairContract]);  
    

    const tokenApproval = async () => {
        setActionStatus("LOADING");
        await tokenApprove(pancakePairContract, lockAddress)
        .then((res:any) => {  
            let resWait:any = res.wait();
            resWait.then((resTransaction:any) => {
                setActionStatus("APPROVED");
                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    Store.addNotification(addNotify('Approved successfully', 'success')); 
                } else {
                    Store.addNotification(addNotify('Approved failed', 'danger'));
                }
            })
            .catch((error:any) => {
                setActionStatus("");
                if(error.data){
                    Store.addNotification(addNotify(error.data.message, 'warning'));
                }            
            });
        })
        .catch((error:any) => {
            setActionStatus("");
            if(error.data){
                Store.addNotification(addNotify(error.data.message, 'warning'));
            } 
        });
    } 

    const onChangeOwner = (e: CheckboxChangeEvent) => {
        setIsOwner(e.target.checked);
    };

    const onAmount = (e: any) => {
        setValueAmount(e.target.value);
    }

    const handleCancel = () => {
        dispatch(setTokenLP({}));
        navigate('/liquidity')
        localStorage.setItem("stepLock", "1");
    }

    const getActionButton = (status:string) => { 
        let actionButton:any = '';
        if(status === "LOADING") { 
            actionButton =   
             <ButtonComp isLoading={true} disabled={true} text='Approve' type="blue"></ButtonComp> 
           ;
        }
        if (status === "LOADINGLOCK") {
            actionButton =
            <ButtonComp isLoading={true} disabled={true} text='Lock' type="blue"></ButtonComp>
            ;
        }
        else if(status === "APPROVED") { 
            actionButton = <ButtonComp text='Lock' type="blue"></ButtonComp>
        }
        else if(status === "") { 
            actionButton = tokenSC ? <ButtonComp onClick={() => tokenApproval()} text='Approve' type="blue"/> :
            <ButtonComp disabled={true} text='Approve' type="blue"/>  
        } 
        return  (<div className="isWidth">{actionButton}</div>);
    }

    const handleLinkNetwork = () => {
        let link:any;
        if(chainId === 97) {
            link = 'https://testnet.bscscan.com/';
        } else if(chainId === 56) {
            link = 'https://bscscan.com/'
        } else if(chainId === 421613) {
            link ='https://goerli.arbiscan.io/';
        } else if(chainId === 42161) {
            link = 'https://arbiscan.io/';
        }
        return link;
    }

    const onChangeUseVesting = (e: CheckboxChangeEvent) => {
        setIsUseVesting(e.target.checked);
    };

    const handleValueMax = () => {
        setValueAmount(tokenSC?.balance);
        form.setFieldsValue({
            amount: tokenSC?.balance
        })
    }

    return (
        <>  {loadingPage ? <Loading/> :null} 
        <div className="p-tokenCreateLock formlock">
            <h3>Step 2</h3>
            <Form name="basic" form={form} onFinish={onFinish} initialValues={{ remember: false }} >
                <div className="p-tokenCreateLock__box">
                    <div className="p-tokenCreateLock__left">
                        <InputChange title='LP Token Address' value={tokenSC?.lpAddress} disabled={true}/>
                        <InputChange title='Pair' value={tokenSC?.pair} disabled={true}/>
                        <InputChange title='Dex' value={tokenSC?.dex} disabled={true}/>
                    </div>
                    <div className="p-tokenCreateLock__right">
                        <Form.Item
                            name="title"
                            rules={[{ required: true, message: 'Please input your title !' }]}
                        >
                            <InputChange title='Title' />
                        </Form.Item>
                        <Form.Item name="useAnotherOwner" >
                            <Checkbox onChange={onChangeOwner}>Use another owner?</Checkbox>
                        </Form.Item>
                        {isOwner ? <>
                            <Form.Item name="anotherOwner" >
                                <InputChange title='Another Owner' />
                            </Form.Item>
                        </> : ''}
                        <Form.Item name="amount" initialValue={valueAmount} rules={[
                                { required: true, message: 'Amount is required!' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (Number(value) < 0) {
                                            return Promise.reject(`Amount must larger than 0`)
                                        }  
                                        return Promise.resolve();
                                    }
                                })]}>
                            <div className="c-input">
                                <p className="c-input__title">Amount</p>
                                <div className="c-input__max">
                                    <input onWheel={event => event.currentTarget.blur()} type="text" value={valueAmount} onChange={(e) => onAmount(e)} />
                                    <span onClick={() => handleValueMax()}>Max</span>
                                </div>
                                <p className="c-txt is-blue">Balance: {formatNumberAmount(tokenSC?.balance,0,6)}</p>
                            </div>
                        </Form.Item>
                        
                        <Form.Item name="isUseVesting" >
                            <Checkbox onChange={onChangeUseVesting}>Use vesting?</Checkbox>
                        </Form.Item>
                        {isUseVesting ? <div className="p-tokenCreateLock__vetting">
                            <p className="c-ttl">TGE Date (UTC) </p>
                            <Form.Item name="tgeDate" >
                                <DatePicker className="c-dateEdit"
                                    showTime={true}
                                    format={'DD/MM/YYYY HH:mm:ss'}
                                    placeholder='Select time'
                                    disabledDate={(current) => {
                                        return moment().add(-1, 'days') >= current || moment().add(1, 'month') <= current;
                                    }}/>
                            </Form.Item>
                            <Form.Item name="tgePercent" rules={[{
                                required: true,
                                message: 'TGE Percent is required!'
                                }, 
                                ({ getFieldValue }) => ({
                                validator(_,value) {  
                                    if((Number(value) + Number(getFieldValue(['cycleReleasePercent']))) > 100) { 
                                        return Promise.reject('First release for presale and cycle release percent must be less than 100 percent')
                                    }   
                                    if(Number(value) < 0){ 
                                        return Promise.reject(`TGE Percent must larger than 0`)
                                    }   
                                    form.setFields([
                                        {
                                        name: ['cycleReleasePercent'],
                                        errors: []
                                        }
                                    ]);
                                return Promise.resolve();
                                }})
                                ]} >
                                <InputChange type='number' title='TGE Percent' placeholder='0' />
                            </Form.Item>
                                <Form.Item name="vestingCycle" rules={[
                                    { required: true, message: 'Vesting Cycle is required!' }
                                    , ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (Number(value) < 0) {
                                                return Promise.reject(`Vesting Cycle must larger than 0`)
                                            }
                                            form.setFields([
                                                {
                                                    name: ['cycleReleasePercent'],
                                                    errors: []
                                                }
                                            ]);
                                            return Promise.resolve();
                                        }
                                    })]}>
                                <InputChange type='number' title='Cycle (Minutes)' placeholder='0' />
                            </Form.Item>
                            <Form.Item name="cycleReleasePercent" rules={[{
                                required: true,
                                message: 'cycleReleasePercent is required!'
                                }, 
                                ({ getFieldValue }) => ({
                                validator(_,value) {  
                                    if((Number(value) + Number(getFieldValue(['tgePercent']))) > 100) { 
                                        return Promise.reject('First release for presale and cycle release percent must be less than 100 percent')
                                    }   
                                    if(Number(value) < 0){ 
                                        return Promise.reject(`cycleReleasePercent must larger than 0`)
                                    }   
                                    form.setFields([
                                        {
                                        name: ['tgePercent'],
                                        errors: []
                                        }
                                    ]);
                                return Promise.resolve();
                                }})
                                ]} > 
                                <InputChange type='number' title='Cycle Release Percent (%)' placeholder='0' />
                            </Form.Item>
                        </div> : 
                        <div className="p-tokenCreateLock__vetting">
                            <p className="c-ttl">Lock until (UTC) </p>
                            <Form.Item name="lockUntilDate" rules={[{ required: true, message: 'Please input your lock until Date!' }]} >
                                <DatePicker 
                                    className="c-dateEdit" 
                                    showTime={true} 
                                    format={'DD/MM/YYYY HH:mm:ss'} 
                                    placeholder='Select time' 
                                    disabledDate={(current) => {
                                        return moment().add(-1, 'days') >= current || moment().add(1, 'month') <= current;
                                    }}/>
                            </Form.Item>
                        </div>}
                    </div>
                </div>
                <div className="p-tokenCreateLock__footer">
                    {isNoti ? <p>
                        <span><img src="../images/icon-center-16px.svg" alt="" /></span>
                        <span onClick={() => setIsNoti(false)}><img src="../images/icon-close-12px.svg" alt="" /></span>
                        Please exclude BlueSale Lock's lockup address <a href={`${handleLinkNetwork()}address/${tokenLP?.lpTokenAddress}`} target="_blank">{tokenLP?.lpTokenAddress}</a> from fees,<br />rewards, max tx amount to start locking tokens.
                        We don't support rebase tokens.
                    </p> : ''}
                    <div className="p-tokenCreateLock__btn">
                        <div className="c-btn c-btn--greyBorder mr-20" onClick={() => handleCancel()}><span><span>Cancel</span></span></div>
                        {getActionButton(actionStatus)} 
                    </div>                
                </div>
            </Form>
        </div>
        </>
   
  )
}

export default FormLP;