import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import TokenInformService from "../services/TokenInformService";

// ================================================
// ==                  ACTION                    ==
// ================================================ 


// ================================================
// ==                REDUX STORE                 ==
// ================================================

type TokenObject = {
  [key: string]: void | string | number | any;
};

export interface TokenInformState { 
    tokenStorage: TokenObject;  
    loading: boolean;
    errors: string;
}

const initialState: TokenInformState = { 
    tokenStorage: {}, 
    loading: false,
    errors: "",
}

const tokenInformSlice = createSlice({
  name: "tokenInforms",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },  
    
    setTokenStorage: (state, { payload }: PayloadAction<TokenObject>) => {
      state.tokenStorage = payload
    },  
    
  },
})

export const { setLoading, setErrors, setTokenStorage } = tokenInformSlice.actions

export default tokenInformSlice.reducer

export const tokenStorageSelector = (state: { tokenStorageStore: TokenInformState }) => state.tokenStorageStore; 
 