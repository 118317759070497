import { useState, useRef, useEffect } from "react";  
import {Form, Input, Row, Col, Alert } from 'antd';  
import { InputChange } from '../../../../components/component';
import axios from 'axios';  

import { Tokens } from "../../../../constants";
interface IFormCreate{
    setActiveKey: any, 
  } 
 

const AirdropFinish = (props) => {

    const { form, initAirdropFeeOpj } = props;
    let storageAirdropInfo = window.localStorage.getItem('airdropInfo');
    let storageTokenInfo = window.localStorage.getItem('airdropTokenInfo');
    let storageAdditionalInfo = window.localStorage.getItem('airdropAdditionalInfo');

    let airdropInfo;
    let airdropTokenInfo;
    let airdropAdditionalInfo;
    if (storageAirdropInfo) {
        airdropInfo = JSON.parse(storageAirdropInfo);
    }
    if (storageTokenInfo) {
        airdropTokenInfo = JSON.parse(storageTokenInfo);
    }
    if (storageAdditionalInfo) {
        airdropAdditionalInfo = JSON.parse(storageAdditionalInfo);
    }
    const [checkUpdate, setCheckUpdate] = useState<boolean>(false);
    const [imgBanner, setImgBanner] = useState(airdropAdditionalInfo?.banner ?? "");

    useEffect(() => {
        if (!checkUpdate) {
            form.setFieldsValue({
                name: airdropAdditionalInfo ? airdropAdditionalInfo.name : '',
                info: airdropAdditionalInfo ? airdropAdditionalInfo.info : '',
                website: airdropAdditionalInfo ? airdropAdditionalInfo.website : '',
                twitter: airdropAdditionalInfo ? airdropAdditionalInfo.twitter : '',
                telegram: airdropAdditionalInfo ? airdropAdditionalInfo.telegram : '',
                medium: airdropAdditionalInfo ? airdropAdditionalInfo.medium : '',
                youtube: airdropAdditionalInfo ? airdropAdditionalInfo.youtube : '',
                discord: airdropAdditionalInfo ? airdropAdditionalInfo.discord : '',
                facebook: airdropAdditionalInfo ? airdropAdditionalInfo.website : '',
                banner: airdropAdditionalInfo ? airdropAdditionalInfo.banner : '',
                token_address: airdropTokenInfo ? airdropTokenInfo.token_address : '',
                token_name: airdropTokenInfo ? airdropTokenInfo.token_name : '',
                token_symbol: airdropTokenInfo ? airdropTokenInfo.token_symbol : '',
                token_decimal: airdropTokenInfo ? airdropTokenInfo.token_decimal : '',
            })
            setImgBanner(airdropAdditionalInfo?.banner);
            setCheckUpdate(true);
        }

    }, [airdropAdditionalInfo]);

    return (
    <div className='verifyToken'>
        <div className="airdrop-step-bg-title">Step 4</div>
      
            <div className="verifyToken-form p-0"> 
                <div className="verifyToken-left">
                    
                    <div className="c-social">
                        <p className="c-ttl">Channel Media</p>
                       
                        <div className="c-social__row">
                            <span><img src="../images/icon-editWebsite.svg" alt="" />Website</span>
                            <Form.Item name="website" rules={[{ required: true, message: 'Please input your website!' }]}> 
                                <Input readOnly className="inputForm" placeholder="Enter URL" />
                            </Form.Item>
                        </div>
                        <div className="c-social__row">
                            <span><img src="../images/icon-editTwitter.svg" alt="" />Twitter</span>
                            <Form.Item name="twitter"> 
                                <Input readOnly className="inputForm" placeholder="Enter URL" />
                            </Form.Item>
                        </div>
                        <div className="c-social__row">
                            <span><img src="../images/icon-editTelegram.svg" alt="" />Telegram</span>
                            <Form.Item name="telegram"> 
                                <Input readOnly className="inputForm" placeholder="Enter URL" />
                            </Form.Item>
                        </div>
                        <div className="c-social__row">
                            <span><img src="../images/icon-editMedium.svg" alt="" />Medium</span>
                            <Form.Item name="medium"> 
                                <Input readOnly className="inputForm" placeholder="Enter URL" />
                            </Form.Item>
                        </div>
                        <div className="c-social__row">
                            <span><img src="../images/icon-editYoutube.svg" alt="" />Youtube</span>
                            <Form.Item name="youtube"> 
                                <Input readOnly className="inputForm" placeholder="Enter URL" />
                            </Form.Item>
                        </div>
                        <div className="c-social__row">
                            <span><img src="../images/icon-editDiscord.svg" alt="" />Discord</span>
                            <Form.Item name="discord"> 
                                <Input readOnly className="inputForm" placeholder="Enter URL" />
                            </Form.Item>
                        </div>
                        <div className="c-social__row">
                            <span><img src="../images/icon-editFacebook.svg" alt="" />Facebook</span>
                            <Form.Item name="facebook"> 
                                <Input readOnly className="inputForm" placeholder="Enter URL" />
                            </Form.Item>
                        </div> 
                        <Alert className='w-auto m-auto mb-15' message={`Create airdrop fee: ${initAirdropFeeOpj ? initAirdropFeeOpj?.settingValue : 0} ${initAirdropFeeOpj ? initAirdropFeeOpj?.payToken : ''}`} type="info" showIcon />

                </div> 
            </div> 
            <div className="verifyToken-right">  
                <div className="verifyToken-left-item"> 
                    <p className="c-ttl">Token Infomation</p>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                        <Col className="gutter-row" xs={24}>
                            
                            <Form.Item
                                label="Token Address"
                                name="token_address"
                            >
                                <Input readOnly className="inputForm" placeholder="Ex: 0x3C39f28e368420e3dEE3838E8b1de7422EA633E4"/>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24}>
                            <Form.Item
                                label="Token Name"
                                name="token_name"
                            >
                                <Input readOnly className="inputForm" placeholder="Enter token name"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                        <Col className="gutter-row" xs={24}>
                            <Form.Item
                                label="Token Symbol"
                                name="token_symbol"
                            >
                                <Input readOnly className="inputForm" placeholder="Enter token symbol"/>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24}>
                            <Form.Item
                                label="Token Decimals"
                                name="token_decimal"
                            >
                                <Input readOnly className="inputForm" placeholder="Enter token decimal"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="c-upload">
                        <span className="c-upload__title">Upload Banner</span>
                        <div className={`c-upload__box is-add`}>
                            <div className="c-upBanner">
                                <img src={imgBanner} alt="" />
                                
                            </div>
                        </div>  
                    </div>
                </div> 
            
            
            </div> 
        </div> 

    </div>  
)}

export default AirdropFinish;