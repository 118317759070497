import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import TokenInformService from "../services/TokenInformService";

// ================================================
// ==                  ACTION                    ==
// ================================================
export const getTokenInformList = (payload:PayloadAction) => {
    return async dispatch => {
        dispatch(setLoading(true));
        
        await TokenInformService.getTokenInformList(payload)
        .then((res)=>{
            dispatch(setLoading(false));
            dispatch(setTokenInforms(res.data));
        })
        .catch((error:any)=>{
            // dispatch(setErrors(error));
            dispatch(setLoading(false));
        });
    }
}

export const getTokenInform = (id) => {
  return async dispatch => {
      dispatch(setLoading(true));
      
      await TokenInformService.getTokenInform(id)
      .then((res)=>{
          dispatch(setLoading(false));
          dispatch(setTokenInform(res.data));
      })
      .catch((error:any)=>{
          dispatch(setLoading(false));
      });
  }
}

export const getTokenInformListFull = () => {
  return async dispatch => {
      dispatch(setLoading(true)); 
      await TokenInformService.getTokenInformListFull()
      .then((res)=>{
          dispatch(setLoading(false)); 
          dispatch(setTokenInformsList(res.data))
      })
      .catch((error:any)=>{ 
          dispatch(setLoading(false));
      });
  }
}

export const createTokenInform = (payload:PayloadAction,  onCallback:Function) => {
  return async dispatch => {  
    dispatch(setLoading(true));
      await TokenInformService.createTokenInform(payload)
      .then((res)=>{ 
          dispatch(setLoading(false));  
          onCallback({result: "SUCCESS", data: res?.data});
      })
      .catch((error:any)=>{
          dispatch(setErrors(error));
          onCallback({result: "ERROR", error: error});
          dispatch(setLoading(false));  
      });
  }
}

export const createTokenLPInform = (payload:PayloadAction,  onCallback:Function) => {
  return async dispatch => {  
    dispatch(setLoading(true));
      await TokenInformService.createTokenLPInform(payload)
      .then((res)=>{ 
          dispatch(setLoading(false));  
          onCallback({result: "SUCCESS", data: res?.data});
      })
      .catch((error:any)=>{
          dispatch(setErrors(error));
          onCallback({result: "ERROR", error: error});
          dispatch(setLoading(false));  
      });
  }
}

export const getTokenInformMyToken = (payload:PayloadAction) => {
  return async dispatch => {
      dispatch(setLoading(true));
      
      await TokenInformService.getTokenInformMyToken(payload)
      .then((res)=>{
          dispatch(setLoading(false));
          dispatch(setTokenInformsMyToken(res.data));
          dispatch(setTokenInformsMyTokenCount(Number(res.headers['x-total-count'])));
      })
      .catch((error:any)=>{
          //dispatch(setErrors(error));
          dispatch(setLoading(false));
      });
  }
}

export const getTokenInformByAddress = (payload:any, onCallback:Function) => {
  return async dispatch => {
      dispatch(setLoading(true));
      
      await TokenInformService.getTokenInformByAddress(payload)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setTokenInformsByAddress(res.data));
        onCallback({"tokenInform" : res.data ?? null});
      })
      .catch((error:any)=>{
          dispatch(setErrors(error));
          dispatch(setLoading(false));
      });
  }
}


export const postCheckAllowLauchpad = (payload:PayloadAction, onCallback:Function) => {
  return async dispatch => {
      dispatch(setLoading(true));
      
      await TokenInformService.postCheckAllowLauchpad(payload)
      .then((res)=>{
          dispatch(setLoading(false));
          dispatch(setCheckAllowLauchpad(res.data));
          onCallback({"checkToken" : res.data ?? null});
      })
      .catch((error:any)=>{
          //dispatch(setErrors(error));
          dispatch(setLoading(false));
      });
  }
}



// ================================================
// ==                REDUX STORE                 ==
// ================================================

type TokenObject = {
  [key: string]: void | string | number | any;
};

export interface TokenInformState {
    tokenInforms: TokenObject[];
    tokenInform: TokenObject; 
    tokenInformsList:TokenObject[];
    tokenInformsMyToken:TokenObject[];
    tokenInformsByAddress:TokenObject;
    checkAllowLauchpad:TokenObject;
    loading: boolean;
    errors: string;
    tokenInformsMyTokenCount: number | string | undefined;
}

const initialState: TokenInformState = {
    tokenInforms: [],
    tokenInform: {},
    
    tokenInformsList:[],
    tokenInformsMyToken: [],
    tokenInformsByAddress:{},
    checkAllowLauchpad:{},
    loading: false,
    errors: "",
    tokenInformsMyTokenCount: 0,
}

const tokenInformSlice = createSlice({
  name: "tokenInforms",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setTokenInforms: (state, { payload }: PayloadAction<TokenObject[]>) => {
      state.tokenInforms = payload
    },

    setTokenInform: (state, { payload }: PayloadAction<TokenObject[]>) => {
      state.tokenInform = payload
    }, 
     
    setTokenInformsList: (state, { payload }: PayloadAction<TokenObject[]>) => {
      state.tokenInformsList = payload
    },

    setTokenInformsMyToken: (state, { payload }: PayloadAction<TokenObject[]>) => {
      state.tokenInformsMyToken = payload
    },

    setTokenInformsMyTokenCount: (state, { payload }: PayloadAction<number | string | undefined>) => {
      state.tokenInformsMyTokenCount = payload
    },

    setTokenInformsByAddress: (state, { payload }: PayloadAction<TokenObject[]>) => {
      state.tokenInformsByAddress = payload
    },

    setCheckAllowLauchpad: (state, { payload }: PayloadAction<TokenObject[]>) => {
      state.checkAllowLauchpad = payload
    }, 
  },
})

export const { setLoading, setErrors, setTokenInforms, setTokenInform, setTokenInformsList, setTokenInformsMyToken, setTokenInformsByAddress, setCheckAllowLauchpad, setTokenInformsMyTokenCount } = tokenInformSlice.actions

export default tokenInformSlice.reducer

export const tokenInformsSelector = (state: { tokenInformsStore: TokenInformState }) => state.tokenInformsStore; 
