import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { BscConnector } from './bsc';
import { WalletConnector } from './walletConnect';
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";

const NETWORK_URL = process.env.REACT_APP_BSC_NETWORK_URL;
const NETWORK_CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID ?? '56');

export const walletconnect = new WalletConnect({
	bridge: "https://bridge.walletconnect.org",
	qrcodeModal: QRCodeModal
});

export const walletconnect1 = new WalletConnector({});

export const injected = new InjectedConnector({
	supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 137, 42161, 421613, 80001]
});

export const bsc = new BscConnector({
	supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 137, 42161, 421613, 80001]
});
