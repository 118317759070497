import { CURRENCIES_ADDRESS, CURRENCIES_DECIMALS } from "../../constants";

export const getPresaleTokens = (swapRate:number, hardcap:number) => {
    return swapRate * hardcap;
}

export const getLiquidityTokens = (
    hardcap:number, 
    feeTokenRaised:number, 
    liquidityPercent:number, 
    listingRate:number
) => {
    return (((hardcap - (hardcap * feeTokenRaised) / 100) * liquidityPercent) / 100) * listingRate;
}

export const getCurrencyDecimals = (chainId:any, symbol:any) => {
	if (!chainId || !symbol) {
		return 18;
	}
	return CURRENCIES_DECIMALS[chainId][symbol];
};

export const getMainCurrencyDecimals = (chainId:any) => {
	if (!chainId) {
		return 18;
	}
	let _symbols:any = "";
	if(chainId === 56 || chainId === 97) {
		_symbols = "BNB";
	}
	else if(chainId === 1) {
		_symbols = "ETH";
	}
	if(chainId === 137) {
		_symbols = "MATIC";
	}

	return CURRENCIES_DECIMALS[chainId][_symbols];
};

export const getCurrencyAddress = (chainId:any, symbol:any) => {
	if (!chainId || !symbol) {
		return "0x0000000000000000000000000000000000000000"
	}
	return CURRENCIES_ADDRESS[chainId][symbol];
};