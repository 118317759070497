import { useState, useEffect } from "react";
import { Form, Input, Select, Switch, Checkbox, DatePicker, Button as ButtonAntd, Radio } from 'antd';
import { Button } from '../component';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { createPrivateSale, createPrivateSaleSelector, privateSaleInfo } from "../../redux/slicers/createPrivateSaleSlice";
import { CHAINID_CONVERT, CHAINID_FULLNAME, DEX_EXCHANGES, MAPPING_CHAINID_DECIMAL, SALE_CURRENCIES } from "../../constants";
import { useActiveWeb3React } from "../../hooks";
import type { RadioChangeEvent } from 'antd';
import { useNavigate } from "react-router-dom";
import { formatUTCDate } from "../../utils/formatDate";

interface IFormCreate {
    setActiveKey: any,
}


const PrivateSaleInfo: React.FC<IFormCreate> = (props: IFormCreate) => {
    const { privateSaleInfoStorage } = useAppSelector(createPrivateSaleSelector);
    const { chainId } = useActiveWeb3React();
    const [checkSwitch, setCheckSwitch] = useState<boolean>(privateSaleInfoStorage?.whiteListOnly ? privateSaleInfoStorage?.whiteListOnly : false);
    const [dexExchanges, setDexExchanges] = useState<any>([]);
    const [saleCurrencies, setSaleCurrencies] = useState<any>([]);
    const [startTime, setStartTime] = useState<any>("");
    const [endTime, setEndTime] = useState<any>("");
    const [valueCurrency, setValueCurrency] = useState(chainId === 421613 ? "AGOR" : chainId === 42161 ? "ETH" : "");
    const { RangePicker } = DatePicker;
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();


    let netName = '';
    switch (chainId) {
        case MAPPING_CHAINID_DECIMAL.bsc:
            netName = CHAINID_FULLNAME.bsc;
            break;

        case MAPPING_CHAINID_DECIMAL.poly:
            netName = CHAINID_FULLNAME.poly;
            break;

        case MAPPING_CHAINID_DECIMAL.eth:
            netName = CHAINID_FULLNAME.eth;
            break;

        case MAPPING_CHAINID_DECIMAL.bsctestnet:
            netName = CHAINID_FULLNAME.bsctestnet;
            break;

        case MAPPING_CHAINID_DECIMAL.arbtestnet:
            netName = CHAINID_FULLNAME.arbtestnet;
            break;

        case MAPPING_CHAINID_DECIMAL.arb:
            netName = CHAINID_FULLNAME.arb;
            break;
        default:
            netName = CHAINID_FULLNAME.bsc;
    }

    useEffect(() => {
        if (chainId) {
            setDexExchanges(DEX_EXCHANGES[chainId]);
            setSaleCurrencies(SALE_CURRENCIES[chainId]);
        }
    }, [chainId]);

    useEffect(() => {
        form.setFieldsValue({
            hardcap: privateSaleInfoStorage?.hardcap,
            minBuy: privateSaleInfoStorage?.minBuy,
            maxBuy: privateSaleInfoStorage?.maxBuy,
            startTime: privateSaleInfoStorage?.startTime ? formatUTCDate(privateSaleInfoStorage?.startTime) : null,
            endTime: privateSaleInfoStorage?.endTime ? formatUTCDate(privateSaleInfoStorage?.endTime) : null,
            // selecttime: [formatUTCDate(privateSaleInfoStorage?.startTime ?? new Date()), formatUTCDate(privateSaleInfoStorage?.endTime ?? new Date())],
            softcap: privateSaleInfoStorage?.softcap,
            whiteListOnly: privateSaleInfoStorage?.whiteListOnly,
            firstFundRelease: privateSaleInfoStorage?.firstFundRelease,
            fundVestingPeriodEachCycle: privateSaleInfoStorage?.fundVestingPeriodEachCycle,
            fundRelease: privateSaleInfoStorage?.fundRelease,
            title: privateSaleInfoStorage?.title,
            currency: privateSaleInfoStorage?.currency,
        });
        setStartTime(formatUTCDate(privateSaleInfoStorage?.startTime));
        setEndTime(formatUTCDate(privateSaleInfoStorage?.endTime));
    }, [privateSaleInfoStorage]);


    const onFinish = (values: any) => {
        const payload: any = {
            title: values.title,
            hardcap: values?.hardcap,
            minBuy: values?.minBuy,
            maxBuy: values?.maxBuy,
            softcap: values?.softcap,
            whiteListOnly: checkSwitch,
            startTime: startTime,
            endTime: endTime,
            networkChain: chainId && CHAINID_CONVERT[chainId].toUpperCase(),
            currency: valueCurrency,
            chainId: chainId,
            firstFundRelease: values.firstFundRelease,
            fundVestingPeriodEachCycle: values.fundVestingPeriodEachCycle,
            fundRelease: values.fundRelease
        }
        dispatch(privateSaleInfo(payload));
        props.setActiveKey("2");
        localStorage.setItem("stepPrivateSale", "2")
    };

    let navigate = useNavigate();


    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleChange = (e) => {

    }

    const onChangeCurrency = (e: RadioChangeEvent) => {
        setValueCurrency(e.target.value);
    }
    const handleBack = () => {
        props.setActiveKey("2");
        localStorage.setItem("stepPrivateSale", "2")
    }

    const onChangeSwitch = (checked: boolean) => {
        setCheckSwitch(checked);
    }

    const onRangeChange = (dates) => {
        // console.log("🚀 ~ file: launchpadInfo.tsx:247 ~ onRangeChange ~ dates:", dates)
        setStartTime(dates[0]?._d?.toISOString())
        setEndTime(dates[1]?._d?.toISOString())
    }

    const handleCancel = () => {  
        dispatch(privateSaleInfo({})); 
        navigate("/private-sale/list");
        form.resetFields();
        localStorage.setItem("stepPrivateSale", "1");
    }

    const onOkStartTime = (dates) => {
        console.log('setStartTime: ', dates?.toISOString());
        setStartTime(dates?.toISOString())
    }
    const onOkEndTime = (dates) => {
        console.log('setEndTime: ', dates?.toISOString());
        setEndTime(dates?.toISOString())
    }

    const defaultCurrencyChecker = () => {
        let currencyDefault = '';
        if (chainId === 421613) {
            currencyDefault = 'AGOR';
        } else if (chainId === 42161) {
            currencyDefault = 'ETH';
        }
        return currencyDefault;
    }

    return (
        <div className='verifyToken'>
            <div className='step'>Step 1</div>
            <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
            >
                <div className="verifyToken-form">
                    <div className="verifyToken-left">
                        <div>
                            <div className="verifyToken-left-item">
                                <span className="mb-10">Private Sale Title</span>
                                <Form.Item name="title" rules={[{ required: true, message: `Please input your title`, }]}>
                                    <Input onWheel={event => event.currentTarget.blur()} className="inputForm" name="title" onChange={(e) => handleChange(e)} />
                                </Form.Item>
                                <p className="swaprate"> The title should be your token name </p>
                            </div>
                            <div className="verifyToken-left-item">
                                <div className="verifyToken-right-text">
                                    <span className="mb-10">Softcap ({valueCurrency})</span>
                                    <div className="tooltip">
                                        <img src="../images/question-circle.png" alt="" />
                                        <span className="tooltiptext">Softcap must larger than 50% Hardcap</span>
                                    </div>
                                </div>

                                <Form.Item name="softcap"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Softcap is required!'
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (2 * Number(value) !== Number(getFieldValue(['hardcap']))) {
                                                    return Promise.reject('Softcap must be greater than or equal 50% of Hardcap')
                                                }
                                                if (Number(value) <= 0) {
                                                    return Promise.reject('Softcap must larger than 0')
                                                }
                                                form.setFields([
                                                    {
                                                        name: ['hardcap'],
                                                        errors: []
                                                    }
                                                ]);
                                                return Promise.resolve()

                                            }
                                        })
                                    ]}>
                                    <Input onWheel={event => event.currentTarget.blur()} name="softcap" className="inputForm" type="number" onChange={(e) => handleChange(e)} />
                                </Form.Item>
                            </div>

                            <div className="verifyToken-left-item">
                                <span className="mb-10">Hardcap ({valueCurrency})</span>

                                <Form.Item name="hardcap"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Hardcap is required!'
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (Number(value) !== Number(2 * getFieldValue(['softcap']))) {
                                                    return Promise.reject('Hardcap must be less than or equal 50% of Softcap')
                                                }
                                                if (Number(value) <= 0) {
                                                    return Promise.reject('Hardcap must larger than 0')
                                                }
                                                form.setFields([
                                                    {
                                                        name: ['softcap'],
                                                        errors: []
                                                    }
                                                ]);
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}>
                                    <Input onWheel={event => event.currentTarget.blur()} name="hardcap" className="inputForm" type="number" onChange={(e) => handleChange(e)} />
                                </Form.Item>
                            </div>

                            <div className="verifyToken-left-item">
                                <span className="mb-10">Min Buy ({valueCurrency})</span>
                                <Form.Item name="minBuy"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'MinBuy is required!'
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (Number(value) >= Number(getFieldValue(['maxBuy']))) {
                                                    return Promise.reject('Min buy must be less than max buy')
                                                }
                                                if (Number(value) <= 0) {
                                                    return Promise.reject('Min buy must larger than 0')
                                                }
                                                form.setFields([
                                                    {
                                                        name: ['maxBuy'],
                                                        errors: []
                                                    }
                                                ]);
                                                return Promise.resolve()

                                            }
                                        })
                                    ]}>
                                    <Input onWheel={event => event.currentTarget.blur()} name="minBuy" className="inputForm" type="number" onChange={(e) => handleChange(e)} />
                                </Form.Item>
                            </div>

                            <div className="verifyToken-left-item">
                                <span className="mb-10">Max Buy ({valueCurrency})</span>
                                <Form.Item name="maxBuy"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'MaxBuy is required!'
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (Number(value) <= Number(getFieldValue(['minBuy']))) {
                                                    return Promise.reject('Max buy must langer than min buy')
                                                }
                                                if (Number(value) <= 0) {
                                                    return Promise.reject('Max buy must larger than 0')
                                                }
                                                form.setFields([
                                                    {
                                                        name: ['minBuy'],
                                                        errors: []
                                                    }
                                                ]);
                                                return Promise.resolve()

                                            }
                                        })
                                    ]}>
                                    <Input onWheel={event => event.currentTarget.blur()} name="minBuy" className="inputForm" type="number" onChange={(e) => handleChange(e)} />
                                </Form.Item>
                            </div>
                            {/* <div className="verifyToken-left-item">
                    <span className="mb-10">Contribution Time (UTC)</span>
                    <Form.Item name="selecttime" rules={[{ required: true, message: `Please input your contribution time` }]}> 
                        <RangePicker showTime format={'DD/MM/YYYY HH:mm:ss'} onChange={onRangeChange}  />
                    </Form.Item>
                </div> */}

                            <div className="verifyToken-left-item">
                                <span className="mb-10">Start Time (UTC)</span>
                                <Form.Item name="startTime" rules={[
                                    { required: true, message: `Please input your contribution time` },
                                    {
                                        validator: (rule, value, cb: (msg?: string) => void) => {
                                            !value || new Date(value) <= new Date(Date.now())
                                                ? cb("Start time needs to be after now")
                                                : cb();
                                        }
                                    }
                                ]}>
                                    <DatePicker showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onOk={onOkStartTime} />
                                </Form.Item>
                            </div>
                            <div className="verifyToken-left-item">
                                <span className="mb-10">End Time (UTC)</span>
                                <Form.Item name="endTime" rules={[
                                    { required: true, message: `Please input your contribution time` },
                                    {
                                        validator: (rule, value, cb: (msg?: string) => void) => {

                                            if (!value || new Date(value) <= new Date(Date.now())) {
                                                cb("End time needs to be after now time");
                                            } else if (new Date(value) <= form.getFieldValue('startTime')) {
                                                cb("End time needs to be after start time");
                                            } else {
                                                cb();
                                            }
                                        }
                                    }
                                ]}>
                                    <DatePicker showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onOk={onOkEndTime} />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className="verifyToken-right">
                        <div className="currency">
                            <div className="verifyToken-right-text">
                                <span className="currency-text">Currency</span>
                                <div className="tooltip">
                                    <img src="../images/question-circle.png" alt="" />
                                    <span className="tooltiptext">BUSD, USDC, USDT: Do not use this currency for auto liquidity tokens, or tokens that depend on WETH pair. It will lead to error when finalizing the pool or transfering the tokens (for example Liquidity Generator Token, BabyToken, Buyback Baby Token).
                                        Contact BlueSale for more information. Do not use this currency for auto liquidity tokens, or tokens that depend on WETH pair. It will lead to error when finalizing the pool or transfering the tokens (for example Liquidity Generator Token, BabyToken, Buyback Baby Token). Contact BlueSale for more information. </span>
                                </div>
                            </div>
                            <div className="currency-select">
                                <Form.Item name="currency" rules={[{ required: true, message: 'Please choose currency' }]}>
                                    <Radio.Group onChange={onChangeCurrency} value={valueCurrency}>
                                        {saleCurrencies?.map((currency) => <Radio value={currency}>{currency}</Radio>)}
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="verifyToken-left-item">
                            <span className="mb-10">Whitelist</span>
                            <Form.Item name="whiteListOnly">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={onChangeSwitch}
                                    defaultChecked={checkSwitch}
                                />
                                <span className="switchText"> {checkSwitch ? "Enable" : "Disable"}</span>
                            </Form.Item>
                        </div>

                        <div className="verifyToken-left-item">
                            <span className="mb-10">First Fund Release For Project (%)</span>
                            <Form.Item name="firstFundRelease"
                                rules={[
                                    {
                                        required: true,
                                        message: 'First Fund Release For Project is required!'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (Number(value) <= 0) {
                                                return Promise.reject('First Fund Release For Project must larger than 0')
                                            }
                                            if ((Number(value) + Number(getFieldValue(['fundRelease']))) > 100) {
                                                return Promise.reject('First release for presale and Cycle Release Percent must be less than 100%')
                                            }
                                            return Promise.resolve()

                                        }
                                    })
                                ]}>
                                <Input onWheel={event => event.currentTarget.blur()} name="firstFundRelease" className="inputForm" type="number" onChange={(e) => handleChange(e)} />
                            </Form.Item>
                        </div>
                        <div className="verifyToken-left-item">
                            <span className="mb-10">Fund Vesting Period Each Cycle (Minutes)</span>
                            <Form.Item name="fundVestingPeriodEachCycle"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Fund Vesting Period Each Cycle (Minutes)'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (Number(value) <= 0) {
                                                return Promise.reject('First Fund Release For Project must larger than 0')
                                            }
                                            if (Number(value) >= 95) {
                                                return Promise.reject('First release fund can not greater than 95%')
                                            }
                                            return Promise.resolve()

                                        }
                                    })
                                ]}>
                                <Input onWheel={event => event.currentTarget.blur()} name="fundVestingPeriodEachCycle" className="inputForm" type="number" onChange={(e) => handleChange(e)} />
                            </Form.Item>
                        </div>
                        <div className="verifyToken-left-item">
                            <span className="mb-10">Fund Release Each Cycle (%)</span>
                            <Form.Item name="fundRelease"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Fund Release Each Cycle (%)'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (Number(value) <= 0) {
                                                return Promise.reject('Fund Release Each Cycle must larger than 0')
                                            }
                                            if ((Number(value) + Number(getFieldValue(['firstFundRelease']))) > 100) {
                                                return Promise.reject('First release for presale and Cycle Release Percent must be less than 100%')
                                            }
                                            return Promise.resolve()

                                        }
                                    })
                                ]}>
                                <Input onWheel={event => event.currentTarget.blur()} name="fundRelease" className="inputForm" type="number" onChange={(e) => handleChange(e)} />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="footer-tab">
                    <div className="fixed-bottom">
                        <div className='btn-verify'>
                            <div className="m-25 isWidth">
                                <Button text='Cancel' type='border' onClick={() => handleCancel()} />
                            </div>
                            <div className="isWidth">
                                <Button text='Next' type="blue" />
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}


export default PrivateSaleInfo;