import web3 from 'web3';
import { ethers } from 'ethers';
import { getContract } from '../../utils';
import tokenAbi from '../../contracts/abi/tokenAbi.json';

export const isAddress = async (address)=>{
	try {
		return await web3.utils.isAddress(address)
	} catch (error) {
		return false
	}
	
}


export const getSymbolToken = async (tokenSCAbi, tokenAddressVal) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		const symbol = await contract.symbol();
		return symbol;
	}
};
export const getTotalSupply = async (tokenSCAbi, tokenAddressVal) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		const totalSupply = await contract.totalSupply();
		return totalSupply;
	}
};
export const getBalance = async (tokenSCAbi, tokenAddressVal, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		const balanceOf = await contract.balanceOf(account);
		return balanceOf;
	}
};
export const getTokenName = async (tokenSCAbi, tokenAddressVal) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		const name = await contract.name();
		return name;
	}
};
export const getTokenDecimal = async (tokenSCAbi, tokenAddressVal) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		const decimal = await contract.decimals();
		return decimal;
	}
};

export const getTokenInformation = async (tokenSCAbi, tokenAddressVal) => {
	try {
		if (typeof window.ethereum !== 'undefined') {
			let result;
			let chainId = window.ethereum.chainId
			let tokenInfo = window.localStorage.getItem(`${chainId}/${tokenAddressVal.toLowerCase()}`);
			if (!tokenInfo) {
				let [token_symbol,
					token_name,
					token_decimal,
					total_supply,
				] = await Promise.all([
					getSymbolToken(tokenAbi, tokenAddressVal),
					getTokenName(tokenAbi, tokenAddressVal),
					getTokenDecimal(tokenAbi, tokenAddressVal),
					getTotalSupply(tokenAbi, tokenAddressVal),
					
				]);

				result = {
					name: token_name,
					symbol: token_symbol,
					decimals: token_decimal,
					supply: parseFloat(ethers.utils.formatUnits(total_supply, token_decimal))
				};
				window.localStorage.setItem(`${chainId}/${tokenAddressVal.toLowerCase()}`, JSON.stringify(result));
			} else {
				result = JSON.parse(tokenInfo);
			}
			return result;

		}
	} catch (e) {
		console.log(e);

	}
}

export const approveToken = async (tokenSCAbi, tokenAddressVal, library, account, spender) => {
	const args = [spender, web3.utils.toBN(2).pow(web3.utils.toBN(256)).sub(web3.utils.toBN(1)).toString()];
	const contract = getContract(tokenAddressVal, tokenSCAbi, library, account);

	const transaction = await contract.approve(...args);
	return transaction;
};

export const approveExactAmountToken = async (tokenSCAbi, tokenAddressVal, library, account, spender, amount) => {
	const args = [spender, amount];
	const contract = getContract(tokenAddressVal, tokenSCAbi, library, account);

	const transaction = await contract.approve(...args);
	return transaction;
};

export const getTokenAllowance = async (tokenSCAbi, tokenAddressVal, account, spender) => {
	if (typeof window.ethereum !== 'undefined') {
		const provider = new ethers.providers.Web3Provider(window.ethereum);
		const contract = new ethers.Contract(tokenAddressVal, tokenSCAbi, provider);
		const allowance = await contract.allowance(account, spender);
		return allowance;
	}
};



export const deployAirdrop = async (deployAirdropContract, token, fundPercent, amount) => {
	const transaction = await deployAirdropContract.deployAirDrop(token, fundPercent, amount, { value: amount });
	return transaction;
};


export const startAirdrop = async (airdropAbi, airdropAddress, library, account, startTime) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.startAirDrop(startTime);
		return transaction;
	}
};

export const cancelAirdrop = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.cancelAirdrop();
		return transaction;
	}
};

export const setAirdropEnableExactAmount = async (airdropAbi, airdropAddress, library, account, status) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		console.log(contract);
		const transaction = await contract.setEnableExactAmount(status);
		return transaction;
	}
};

export const removeAllAirdropAllocations = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.removeAllAllocations();
		return transaction;
	}
};

export const setAirdropAllocations = async (airdropAbi, airdropAddress, library, account, users, amounts) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.setAllocations(users, amounts);
		return transaction;
	}
};


export const setAirdropVesting = async (airdropAbi, airdropAddress, library, account, tgeBps, cycle, cycleBps) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.setVesting(tgeBps, cycle, cycleBps);
		return transaction;
	}
};


export const claimAirdrop = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.claimAirdrop();
		return transaction;
	}
};

export const getAirdropClaimable = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.withdrawableTokens(account);
		return transaction;
	}
};

export const getAllAirdropAllocationCount = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.allAllocationCount();
		return transaction;
	}
};

export const getAirdropAllocations = async (airdropAbi, airdropAddress, library, account, start, end) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.getAllocations(start, end);
		return transaction;
	}
};
export const getEnsureExactAmount = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.ensureExactAmount();
		return transaction;
	}
};

export const getAirdropIsWLAdmin = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.wlAdmins(account);
		return transaction;
	}
};


export const getAirdropTGEDate = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.tgeDate();
		return transaction;
	}
};

export const getAirdropTGEBps = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.tgeBps();
		return transaction;
	}
};

export const getAirdropCycle = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.cycle();
		return transaction;
	}
};

export const getAirdropCycleBps = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.cycleBps();
		return transaction;
	}
};

export const getAirdropState = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.airDropState();
		return transaction;
	}
};

export const getAirdropFundPercent = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.fundPercent();
		return transaction;
	}
};

export const getAirdropTotalAllocationTokens = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.totalAllocationTokens();
		return transaction;
	}
};

export const getAirdropUserClaimedTokens = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.userClaimedTokens();
		return transaction;
	}
};

export const getAirdropUserAllocationInfos = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.allocationInfos(account);
		return transaction;
	}
};


export const getAirdropOwner = async (airdropAbi, airdropAddress, library, account) => {
	if (typeof window.ethereum !== 'undefined') {
		const contract = getContract(airdropAddress, airdropAbi, library, account);
		const transaction = await contract.owner();
		return transaction;
	}
};



export const getVestingTimes = (listingTime, lockAfterListing, lockAfterTGE, tgePercent, cycleTime, cyclePercent, totalUserTokens) => {
	let totalClaimedTokens = 0;
	let tgeToken = (totalUserTokens * tgePercent) / 100;
	let cycleToken = (totalUserTokens * cyclePercent) / 100;

	let result = [];
	if (!listingTime) {
		return result;
	}
	let tgeTime = listingTime + lockAfterListing;
	result.push({
		time: tgeTime,
		amount: tgeToken
	});
	totalClaimedTokens += tgeToken;
	if (totalClaimedTokens >= totalUserTokens) {
		return result;
	}

	let nextTime = tgeTime + lockAfterTGE;
	if (lockAfterTGE === 0) {
		nextTime += cycleTime;
	}
	let remainedTokens = totalUserTokens - totalClaimedTokens;
	let time = Math.floor((remainedTokens) / cycleToken)
	if ((remainedTokens % cycleToken) !== 0) {
		time += 1;
	}


	for (let i = 0; i < time; i++) {
		remainedTokens = totalUserTokens - totalClaimedTokens;
		nextTime += cycleTime;
		totalClaimedTokens += cycleToken;

		result.push({
			time: nextTime,
			amount: remainedTokens >= cycleToken ? cycleToken : remainedTokens
		});
	}
	return result;
};


export const convertTimeByMint = (numberOfMin) => {
	let timeText = '';
	if (numberOfMin > 60) {
		var numberOfHours = numberOfMin / 60;
		var days = Math.floor(numberOfHours / 24);
		var Remainder = numberOfHours % 24;
		var hours = Math.floor(Remainder);
		var minutes = Math.floor(60 * (Remainder - hours));

		if (days > 0) {
			timeText += days + " days"
		}
		if (hours > 0) {
			timeText += hours + " hours"
		}
		if (minutes > 0) {
			timeText += minutes + " minutes"
		}
		// timeText = "days " + days + "hours " + hours + "minutes " + minutes
	} else {
		timeText = minutes + " minutes";
	}
	return timeText;
};
