import { useState, useRef, useEffect } from "react";  
import {Form, Input, Select, Button } from 'antd';  
import { InputChange } from '../../../../components/component';
import axios from 'axios';  

import { Tokens } from "../../../../constants";
interface IFormCreate{
    setActiveKey: any, 
  } 
 

const AirdropAdditionInfo = (props) => {
    let storageAirdropInfo = window.localStorage.getItem('airdropInfo');
    let storageTokenInfo = window.localStorage.getItem('airdropTokenInfo');
    let storageAdditionalInfo = window.localStorage.getItem('airdropAdditionalInfo');

    let airdropInfo;
    let airdropTokenInfo;
    let airdropAdditionalInfo;
    if (storageAirdropInfo) {
        airdropInfo = JSON.parse(storageAirdropInfo);
    }
    if (storageTokenInfo) {
        airdropTokenInfo = JSON.parse(storageTokenInfo);
    }
    if (storageAdditionalInfo) {
        airdropAdditionalInfo = JSON.parse(storageAdditionalInfo);
    }


    const { form } = props;
    const [imgBanner, setImgBanner] = useState(airdropAdditionalInfo?.banner ?? "");
    const [bannerThumbnail, setBannerThumbnail] = useState(airdropAdditionalInfo?.bannerThumbnail ?? ""); 
    const [imgLogo, setLogo] = useState(airdropAdditionalInfo?.logo ?? "");  
    const inputImage = useRef<HTMLInputElement>(null);
    const inputbannerThumbnail = useRef<HTMLInputElement>(null);
    const inputLogo = useRef<HTMLInputElement>(null); 
    const [checkUpdate, setCheckUpdate] = useState<boolean>(false);

    
    const onChangeImage = (e, value) => { 
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file); 
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/upload-to-s3`,
            headers: {
                'accept': '*/*',
                "Authorization": `Bearer ${localStorage.getItem(Tokens.AUTHUSER)}`,
                "Content-Type": "multipart/form-data"
            },
            data: formData,
        }).then(res => {
            if(value == "banner"){
               
                setImgBanner(res.data);
                form.setFieldsValue({
                    banner: res.data,
                })
            } else if(value == "bannerThumbnail"){
                setBannerThumbnail(res.data)
            } else{
                setLogo(res.data)
            }
        }).catch(err => {
            console.log(err);
        });
    } 

    useEffect(() => {
        if (!checkUpdate) {
            form.setFieldsValue({
                name: airdropAdditionalInfo ? airdropAdditionalInfo.name : '',
                info: airdropAdditionalInfo ? airdropAdditionalInfo.info : '',
                website: airdropAdditionalInfo ? airdropAdditionalInfo.website : '',
                twitter: airdropAdditionalInfo ? airdropAdditionalInfo.twitter : '',
                telegram: airdropAdditionalInfo ? airdropAdditionalInfo.telegram : '',
                medium: airdropAdditionalInfo ? airdropAdditionalInfo.medium : '',
                youtube: airdropAdditionalInfo ? airdropAdditionalInfo.youtube : '',
                discord: airdropAdditionalInfo ? airdropAdditionalInfo.discord : '',
                facebook: airdropAdditionalInfo ? airdropAdditionalInfo.website : '',
                banner: airdropAdditionalInfo ? airdropAdditionalInfo.banner : '',
            })
            // setImgBanner(airdropAdditionalInfo?.banner);
            setCheckUpdate(true);
        }

    }, [airdropAdditionalInfo]);

    return (
    <div className='verifyToken'>
        <div className="airdrop-step-bg-title">Step 3</div>
      
            <div className="verifyToken-form p-0"> 
                <div className="verifyToken-left">
                    <Form.Item name="step_additional_info" initialValue={3} hidden={true}>
                            <Input className="amount-buy" />
                    </Form.Item>
                    <Form.Item name="banner" hidden={true}>
                            <Input className="amount-buy" />
                    </Form.Item>
                    <Form.Item name="name" label='Airdrop’s Name' rules={[{ required: true, message: 'Please input your name!' }]}> 
                        <Input className="inputForm" type="text"  />
                    </Form.Item>                        
                    <div className="c-input">
                        <Form.Item name="info" label='Airdrop’s Info'> 
                            <Input className="inputForm" type="text" />
                        </Form.Item>
                    </div>
                    <div className="c-social">
                        <p className="c-ttl">Channel Media</p>
                        <div className="c-social__head">
                            <p>Channel name</p>
                            <p>URL <span>(Required)</span></p>
                        </div>
                        <div className="c-social__row">
                            <span><img src="../images/icon-editWebsite.svg" alt="" />Website</span>
                            <Form.Item name="website" rules={[{ required: true, message: 'Please input your website!' }]}> 
                                <InputChange placeholder="Enter URL" />
                            </Form.Item>
                        </div>
                        <div className="c-social__row">
                            <span><img src="../images/icon-editTwitter.svg" alt="" />Twitter</span>
                            <Form.Item name="twitter"> 
                                <InputChange placeholder="Enter URL" />
                            </Form.Item>
                        </div>
                        <div className="c-social__row">
                            <span><img src="../images/icon-editTelegram.svg" alt="" />Telegram</span>
                            <Form.Item name="telegram"> 
                                <InputChange placeholder="Enter URL" />
                            </Form.Item>
                        </div>
                        <div className="c-social__row">
                            <span><img src="../images/icon-editMedium.svg" alt="" />Medium</span>
                            <Form.Item name="medium"> 
                                <InputChange placeholder="Enter URL" />
                            </Form.Item>
                        </div>
                        <div className="c-social__row">
                            <span><img src="../images/icon-editYoutube.svg" alt="" />Youtube</span>
                            <Form.Item name="youtube"> 
                                <InputChange placeholder="Enter URL" />
                            </Form.Item>
                        </div>
                        <div className="c-social__row">
                            <span><img src="../images/icon-editDiscord.svg" alt="" />Discord</span>
                            <Form.Item name="discord"> 
                                <InputChange placeholder="Enter URL" />
                            </Form.Item>
                        </div>
                        <div className="c-social__row">
                            <span><img src="../images/icon-editFacebook.svg" alt="" />Facebook</span>
                            <Form.Item name="facebook"> 
                                <InputChange placeholder="Enter URL" />
                            </Form.Item>
                        </div> 
                </div> 
            </div> 
            <div className="verifyToken-right">  
                <div className="verifyToken-left-item"> 
                    <div className="c-upload">
                        <span className="c-upload__title">Upload Banner</span>
                        <div className={`c-upload__box${imgBanner === '' ? ' is-add' : ''}`}>
                            {imgBanner === '' ? <div className='btn-click' onClick={() => inputImage?.current?.click()}> 
                                <p className="title">Click or drag files to upload here</p>
                                <p className="subtitle">Only png, jpg can be uploaded, and the size does not exceed 3MB. Recommended size is 1920x985px</p>
                            </div> : <div className="c-upBanner">
                                <img src={imgBanner} alt="" />
                                <div className="c-upBanner__edit">
                                    <span onClick={()=>inputImage.current?.click()}><img src="../images/icon-editBanner.png" alt="" /></span>
                                </div>
                            </div>} 
                            <input ref={inputImage} onChange={(e) => onChangeImage(e, "banner")} type="file" />
                        </div>  
                    </div>
                </div> 
            
            
            </div> 
        </div> 

    </div>  
)}

export default AirdropAdditionInfo;