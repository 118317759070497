import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import AntiBotService from "../services/antiBotService";

// ================================================
// ==                  ACTION                    ==
// ================================================ 
 



// ================================================
// ==                REDUX STORE                 ==
// ================================================

type TokenObject = {
    [key: string]: void | string | number | any;
};

export interface AntibotState {
    antibotStorage: TokenObject; 
    loading: boolean;
    errors: string;
}

const initialState: AntibotState = {
    antibotStorage: {}, 
    loading: false,
    errors: "",
}

const antibotSlice = createSlice({
    name: "Antibots",
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload
        },

        setErrors: (state, { payload }: PayloadAction<string>) => {
            state.errors = payload
        },

        setAntibotStorage: (state, { payload }: PayloadAction<TokenObject>) => {
            state.antibotStorage = payload
        },
 
    },
})

export const { setLoading, setErrors, setAntibotStorage } = antibotSlice.actions

export default antibotSlice.reducer

export const antibotStorageSelector = (state: { antibotStorageStore: AntibotState }) => state.antibotStorageStore;
